import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Grid,
  TextField,
  Button,
  TableRow,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';
import { maskCpf, maskCnpj } from '../../../../utils';

import { useAuth } from '../../../../hooks/auth';
import {
  useUserManagement,
  ILoadUser,
} from '../../../user-management/hooks/user-management';

import { generateUserManagementReportPDF } from '../../templates/userManagementReport';
import XLSX from 'xlsx';

export const UserManagementReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { userManagementStatus, loadUserList } = useUserManagement();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [users, setUsers] = useState<ILoadUser[]>([]);

  const columnsTable: IBasicColumn[] = [
    { name: 'Nome' },
    { name: 'E-mail' },
    { name: 'Status' },
    { name: 'CPF / CNPJ', align: 'right' },
  ];

  const returnUserManagementStatus = useCallback(
    (status: string) => {
      const label = userManagementStatus.find(
        (uStatus) => uStatus.value === status
      )?.label;

      if (label) {
        return label;
      }

      return status;
    },
    [userManagementStatus]
  );

  const returnMask = useCallback((cpf: string) => {
    if (cpf.length === 11) return maskCpf(cpf);
    else return maskCnpj(cpf);
  }, []);

  useEffect(() => {
    async function loadUserReport(): Promise<void> {
      try {
        const response = await loadUserList(user.companyDocument);

        if (!response) return;

        const data: any = response.map((user) => {
          return {
            ...user,
            status: returnUserManagementStatus(user.status),
            cpf: returnMask(user.cpf),
          };
        });

        setUsers(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadUserReport();
  }, [
    loadUserList,
    returnMask,
    returnUserManagementStatus,
    user.companyDocument,
  ]);

  const userFilter = useMemo((): ILoadUser[] => {
    const fields = ['name', 'email', 'status', 'cpf'];
    const matchedSearches = users.filter((user: ILoadUser) => {
      const includesOneOf = !!fields.find((field) =>
        user[field as keyof ILoadUser]
          .toString()
          .toLowerCase()
          .includes(searchField.toLowerCase())
      );
      return includesOneOf;
    });

    return matchedSearches;
  }, [searchField, users]);

  const downloadExcel = useCallback((data: ILoadUser[]) => {
    const newData = data.map((user) => {
      return {
        name: user.name,
        email: user.email,
        status: user.status,
        cpf: user.cpf,
      };
    });

    const header = [['Nome', 'E-mail', 'Status', 'CPF / CNPJ']];

    const workSheetCols = [
      { width: 25 },
      { width: 33 },
      { width: 10 },
      { width: 17 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'USUÁRIOS');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Usuários.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Usuários">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={(event) => setSearchField(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => generateUserManagementReportPDF(userFilter)}
              >
                Exportar PDF
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => downloadExcel(userFilter)}
              >
                Exportar EXCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={userFilter.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {userFilter
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id} hover>
                  <TableCell component="th" scope="row">
                    {user.name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell align="right">{user.cpf}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
