import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  Paper,
  Container,
  Grid,
  Typography,
  Divider,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { companyLogo } from '../../../../utils/logoUtils';

import {
  hideInfoDocuments,
  hideInfoAddress,
  hideInfoPostalCode,
  hideInfoNeighborhood,
} from '../../../../utils';
import { useTracking, ILoadTracking, ITrack } from '../../hooks/tracking';

import { IconWrapper } from '../../components/IconWrapper';

import {
  FiCheck,
  MdAssignment,
  MdLocalShipping,
  MdWhereToVote,
} from 'react-icons/all';

import {
  useStyles,
  useColorLibStepIconStyles,
  ColorLibConnector,
} from './styles';

interface IParams {
  trackingId: string;
}

function ColorLibStepIcon(props: StepIconProps) {
  const classes = useColorLibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <IconWrapper icon={FiCheck} propsIcon={props} />,
    2: <IconWrapper icon={MdAssignment} propsIcon={props} />,
    3: <IconWrapper icon={MdLocalShipping} propsIcon={props} />,
    4: <IconWrapper icon={MdWhereToVote} propsIcon={props} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorLibStepIconWhiteCloud(props: StepIconProps) {
  const classes = useColorLibStepIconStyles();
  const { active, completed } = props;

  const iconsWhiteCloud: { [index: string]: React.ReactElement } = {
    1: <IconWrapper icon={FiCheck} propsIcon={props} />,
    2: <IconWrapper icon={MdAssignment} propsIcon={props} />,
    3: <IconWrapper icon={MdLocalShipping} propsIcon={props} />,
    4: <IconWrapper icon={MdWhereToVote} propsIcon={props} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {iconsWhiteCloud[String(props.icon)]}
    </div>
  );
}

export const Tracking = () => {
  const classes = useStyles();
  const { trackingId } = useParams<IParams>();
  const { loadTracking } = useTracking();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ILoadTracking>({} as ILoadTracking);
  const steps = ['Criado', 'Separado', 'Em entrega', 'Entregue'];
  const stepsWhiteCloud = ['Criado', 'Embalado', 'Enviado', 'Entregue'];
  const [tracks, setTracks] = useState<ITrack[]>([]);

  const companyAddress = data?.order?.company?.addresses.find(
    (address) => address.type === 'PRINCIPAL'
  );

  useEffect(() => {
    setActiveStep(0);

    async function loadTrackingInfo(): Promise<void> {
      try {
        const response = await loadTracking(trackingId);

        setData(response);

        setLoading(false);

        if (data && data.orderTag && data.orderTag.tracks) {
          setTracks(JSON.parse(data.orderTag.tracks));
        }

        if (
          data &&
          data.order.company &&
          data.order.company.documentNumber === '33841875807'
        ) {
          if (data.order.statusCode !== 'delivered') {
            switch (data.order.shippingStatus) {
              case 'unpacked':
                setActiveStep(0);
                break;
              case 'unshipped':
                setActiveStep(1);
                break;
              case 'shipped':
                setActiveStep(2);
                break;
              default:
                setActiveStep(0);
                break;
            }
          } else {
            setActiveStep(3);
          }
        } else {
          switch (data.order.statusCode) {
            case 'new':
            case 'occurrence':
            case 'undelivered':
            case 'not-executed':
              setActiveStep(0);
              break;
            case 'on-planned-route':
              setActiveStep(1);
              break;
            case 'on-route':
              setActiveStep(2);
              break;
            case 'delivered':
              setActiveStep(3);
              break;
            default:
              setActiveStep(0);
              break;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadTrackingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTracking, data]);

  return (
    <>
      <Container className={classes.header}>
        <Grid container justifyContent="center" alignItems="center">
          <img
            src={companyLogo().white}
            alt="4Innovation Tecnologia"
            className={classes.logo}
          />
        </Grid>
      </Container>

      <Container component={Paper} className={classes.wrapper}>
        {loading || !data.order.company ? (
          <Typography component="div" align="center">
            <CircularProgress />
          </Typography>
        ) : (
          <Grid
            container
            className={classes.root}
            direction="column"
            justifyContent="center"
          >
            <Grid container style={{ marginBottom: '30px' }}>
              <Grid item className={classes.orderNumber}>
                <Typography>
                  Pedido: <strong>{data.order.number}</strong>
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.stepper}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorLibConnector />}
                style={{ padding: '8px 0 0', width: '100%' }}
              >
                {data.order.company.documentNumber !== '33841875807'
                  ? steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={ColorLibStepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))
                  : stepsWhiteCloud.map((label) => (
                      <Step key={label}>
                        <StepLabel
                          StepIconComponent={ColorLibStepIconWhiteCloud}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
              </Stepper>
            </Grid>

            <Grid item container spacing={1}>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5" align="left">
                    <strong>Remetente</strong>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h5" align="left">
                    <strong>Destinatário</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" className={classes.text}>
                    <strong>
                      {data.order.company.documentNumber !== '33841875807'
                        ? data.order.company.tradingName
                        : 'MARKETPLACE'}
                    </strong>
                    <br />
                    {data.order.company.documentNumber !== '33841875807'
                      ? hideInfoDocuments(data.order.company.documentNumber)
                      : hideInfoDocuments('32782079000146')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" className={classes.text}>
                    <strong>{data.order.customer.name}</strong>
                    <br />
                    {hideInfoDocuments(data.order.customer.documentNumber)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  {data.order.company.documentNumber !== '33841875807' ? (
                    <Typography variant="body1">
                      {hideInfoAddress(`${companyAddress?.street}`)},{' '}
                      {companyAddress?.number}
                      <br />
                      {hideInfoNeighborhood(`${companyAddress?.neighborhood}`)}
                      <br />
                      {`${companyAddress?.city} - ${companyAddress?.state}`}
                      <br />
                      {hideInfoPostalCode(`${companyAddress?.postalcode}`)}
                      <br />
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      {hideInfoAddress(`CAIXA POSTAL`)}, {'0001'}
                      <br />
                      {hideInfoNeighborhood(`JARDIM JALAPAO`)}
                      <br />
                      {`SÃO PAULO - SP`}
                      <br />
                      {hideInfoPostalCode(`02124970`)}
                      <br />
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1">
                    {hideInfoAddress(data.order.shippingAddress.street)},{' '}
                    {data.order.shippingAddress.number},{' '}
                    {data.order.shippingAddress.complement}
                    <br />
                    {hideInfoNeighborhood(
                      data.order.shippingAddress.neighborhood
                    )}
                    <br />
                    {data.order.shippingAddress.city},{' '}
                    {data.order.shippingAddress.state}
                    <br />
                    {hideInfoPostalCode(data.order.shippingAddress.postalcode)}
                    <br />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Typography variant="h6" align="center" style={{ marginTop: 16 }}>
              <strong>PLATAFORMA 4LOG</strong>
            </Typography>

            <Divider className={classes.divider} />

            <Typography
              variant="h6"
              align="center"
              style={{ marginBottom: 16 }}
            >
              {data.orderTag && (
                <>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ marginTop: 16 }}
                  >
                    <strong>RASTREIO: {data.orderTag.tagCode}</strong>
                  </Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Hora</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {tracks.map((track, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {track.status} <br />
                            {track.origin && (
                              <>
                                <strong> Origem: </strong>
                                {track.origin} <br />
                                <strong> Destino: </strong> {track.destiny}
                              </>
                            )}
                          </TableCell>
                          <TableCell>{track.date}</TableCell>
                          <TableCell>{track.hour}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Typography>
          </Grid>
        )}
      </Container>
    </>
  );
};
