import { useMemo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import { useVehicleType, IVehicleTypeRequest } from '../../hooks/vehicle-type';

import { useStyles, Form } from './styles';

interface IParams {
  vehicleTypeId?: string;
}

export const VehicleTypeForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { vehicleTypeId } = useParams<IParams>();
  const {
    data: { user },
    setCompanyDefault,
    companyDefault,
  } = useAuth();
  const { loadVehicleTypeById, createVehicleType, updateVehicleType } =
    useVehicleType();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingVehicleType, setLoadingVehicleType] = useState(true);
  const [data, setData] = useState<IVehicleTypeRequest>({
    name: '',
    maxVolume: '',
    maxWeight: '',
    size: '',
    maxSitesNumber: '',
    companyDocument: user.companyDocument,
  });

  useEffect(() => {
    async function loadVehicleType(): Promise<void> {
      try {
        if (vehicleTypeId) {
          const response = await loadVehicleTypeById(parseInt(vehicleTypeId));

          if (!response) return;

          setData({
            ...response,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingVehicleType(false);
      }
    }

    loadVehicleType();
  }, [loadVehicleTypeById, vehicleTypeId]);

  const validationSchema = yup.object({
    name: yup.string().required('Insira o nome'),
    maxVolume: yup.number().required('Insira o volume máximo'),
    maxWeight: yup.number().required('Insira o peso máximo'),
    size: yup.number().required('Insira o tamanho'),
    maxSitesNumber: yup.number().required('Insira o maxSitesNumber'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        if (vehicleTypeId) {
          await updateVehicleType(parseInt(vehicleTypeId), values);
        } else {
          await createVehicleType(values);
        }

        let newCompanyDefault: any = {};

        newCompanyDefault = {
          ...companyDefault,
          hasVehicleType: true,
        };

        setCompanyDefault(newCompanyDefault);
        localStorage.setItem(
          '@4log:companyDefault',
          JSON.stringify(newCompanyDefault)
        );

        history.push(ROUTES.vehicleTypeList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const subtitle = useMemo(() => {
    if (vehicleTypeId) {
      return 'Alterar tipo de veículo';
    } else {
      return 'Cadastrar tipo de veículo';
    }
  }, [vehicleTypeId]);

  const submitButtonTitle = useMemo(() => {
    if (vehicleTypeId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [vehicleTypeId]);

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.vehicleTypeList}
    >
      <Grid container spacing={1}>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.form}
        >
          <Grid container spacing={3}>
            {vehicleTypeId && loadingVehicleType ? (
              <Grid item xs={12}>
                <Typography component="div" align="center">
                  <CircularProgress />
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    autoFocus
                    label="Nome"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Volume máximo"
                    id="maxVolume"
                    name="maxVolume"
                    value={formik.values.maxVolume}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.maxVolume &&
                      Boolean(formik.errors.maxVolume)
                    }
                    helperText={
                      formik.touched.maxVolume && formik.errors.maxVolume
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Peso máximo"
                    id="maxWeight"
                    name="maxWeight"
                    value={formik.values.maxWeight}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.maxWeight &&
                      Boolean(formik.errors.maxWeight)
                    }
                    helperText={
                      formik.touched.maxWeight && formik.errors.maxWeight
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Tamanho"
                    id="size"
                    name="size"
                    value={formik.values.size}
                    onChange={formik.handleChange}
                    error={formik.touched.size && Boolean(formik.errors.size)}
                    helperText={formik.touched.size && formik.errors.size}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Quantidade de entregas"
                    id="maxSitesNumber"
                    name="maxSitesNumber"
                    value={formik.values.maxSitesNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.maxSitesNumber &&
                      Boolean(formik.errors.maxSitesNumber)
                    }
                    helperText={
                      formik.touched.maxSitesNumber &&
                      formik.errors.maxSitesNumber
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  type="submit"
                  loading={loadingButton}
                >
                  {submitButtonTitle}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Main>
  );
};
