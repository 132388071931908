import React, { ComponentType } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { PERMISSIONS, ROUTES } from '../constants';
import { useAuth } from '../hooks/auth';
import { DefaultLayout } from '../layouts';

interface IProps extends RouteProps {
  permission?: PERMISSIONS;
  component: ComponentType;
}

const PrivateRoute: React.FC<IProps> = ({
  component: Component,
  permission,
  ...rest
}) => {
  const {
    data: { user, permissions },
  } = useAuth();

  const isValid = (location: any) => {
    if (
      !!user &&
      ((permission && permissions.includes(permission)) || !permission)
    ) {
      return (
        <DefaultLayout>
          <Component />
        </DefaultLayout>
      );
    }

    return (
      <Redirect
        to={{
          pathname: !!user
            ? rest.path?.toString() === ROUTES.dashboard
              ? ROUTES.home
              : ROUTES.accessDenied
            : ROUTES.auth,
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={({ location }) => isValid(location)} />;
};

export default PrivateRoute;
