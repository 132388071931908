import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface INuvemshopRequest {
  sendNotification: boolean;
  markAsPackaged: boolean;
  statusForIntegration: string;
  accessToken: string;
}

export interface ILoadNuvemShop {
  sendNotification: boolean;
  markAsPackaged: boolean;
  statusForIntegration: string;
  accessToken: string;
}

interface INuvemshopContext {
  loadNuvemshop(): Promise<ILoadNuvemShop | undefined>;
  saveNuvemshop(data: INuvemshopRequest): Promise<void>;
}

const NuvemshopContext = createContext<INuvemshopContext>(
  {} as INuvemshopContext
);

const NuvemshopProvider: React.FC = ({ children }) => {
  const loadNuvemshop = useCallback(async () => {
    const response = await api.get('/integrations/nuvemshop/config');

    return response.data;
  }, []);

  const saveNuvemshop = useCallback(async (data: INuvemshopRequest) => {
    await api.post('/integrations/nuvemshop/config', data);
  }, []);

  return (
    <NuvemshopContext.Provider
      value={{
        loadNuvemshop,
        saveNuvemshop,
      }}
    >
      {children}
    </NuvemshopContext.Provider>
  );
};

function useNuvemshop(): INuvemshopContext {
  const context = useContext(NuvemshopContext);

  if (!context) {
    throw new Error('useNuvemshop must be used within a NuvemshopProvider');
  }

  return context;
}

export { NuvemshopProvider, useNuvemshop };
