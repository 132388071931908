import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { CompanyForm } from '../pages/CompanyForm';

import { CompanyProvider } from '../hooks/company';

export const CompanyRoute = () => (
  <CompanyProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.company}
        exact
        permission={PERMISSIONS.COMPANY_VIEW}
        component={CompanyForm}
      />
    </Switch>
  </CompanyProvider>
);
