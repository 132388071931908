import React from 'react';
import { Switch } from 'react-router-dom';

import {
  // PERMISSIONS,
  ROUTES,
} from '../../../constants';
import PrivateRoute from '../../../routes/PrivateRoute';
import { FormList } from '../pages/FormList';
import { ChecklistForm } from '../pages/ChecklistForm';
import { FormProvider } from '../hooks/form';
import { ProductProvider } from '../../product/hooks/product';

export const FormRoutes: React.FC = () => (
  <ProductProvider>
    <FormProvider>
      <Switch>
        <PrivateRoute path={ROUTES.forms} exact component={FormList} />
        <PrivateRoute path={ROUTES.createForm} component={ChecklistForm} />
        <PrivateRoute path={ROUTES.updateForm} component={ChecklistForm} />
      </Switch>
    </FormProvider>
  </ProductProvider>
);
