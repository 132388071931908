import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface Disposal {
  id: number;
  companyDocument: string;
  userId: number;
  routeId: null | number;
  observation: string;
  images: {
    id: number;
    image: string;
  }[];
  route: {
    companyDocument: string;
    endRouteDate: string;
    id: number;
    indicators: null;
    mapLineColor: string;
    planningConfigId: number;
    responseDocument: string | null;
    responseSignature: string | null;
    responsibleName: string | null;
    returnToDistributionCenter: boolean;
    routeIdCopy: null;
    routerName: string;
    startRouteDate: string;
    status: string;
    totalDistance: number;
    totalTime: number;
  } | null;
  user: {
    id: number;
    companyDocument: string;
    name: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    postalcode: string;
    city: string;
    state: string;
    country: string;
    type: string;
    email: string;
    phone: string;
    cpf: string;
    token: null;
    lat: null;
    long: null;
    status: string;
    posLat: null;
    posLong: null;
    maps: null;
    hasContract: boolean;
    cnpj: null | string;
    kilometerRadius: null;
    erpCode: null;
  } | null;
}

export interface ILoadDisposal {
  total: number;
  disposals: Disposal[];
}

type ParamsType = {
  page: number;
  take: number;
  userId?: number;
  initialDate?: string;
  finalDate?: string;
};

interface IDisposalContext {
  loadDisposal(params: ParamsType): Promise<ILoadDisposal>;
}

const DisposalContext = createContext<IDisposalContext>({} as IDisposalContext);

const DisposalProvider: React.FC = ({ children }) => {
  const loadDisposal = useCallback(async (params: ParamsType) => {
    const response = await api.get('/disposal', { params });

    return response.data;
  }, []);

  return (
    <DisposalContext.Provider
      value={{
        loadDisposal,
      }}
    >
      {children}
    </DisposalContext.Provider>
  );
};

function useDisposal(): IDisposalContext {
  const context = useContext(DisposalContext);

  if (!context) {
    throw new Error('useDisposal must be used with in a DisposalProvider');
  }

  return context;
}

export { DisposalProvider, useDisposal };
