import React, { useContext, createContext, useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';
import { RegionStatus } from '../../../constants';
import {
  selectItem,
  selectAllItems,
  isCheckboxChecked,
  isCheckboxIndeterminate,
  isItemSelected,
} from '../../../utils';

type NumberFieldValue = number | '';

export interface ISelectedOrder {
  id: number;
  number: string;
  shippingAddress: {
    id: number;
    street: string;
    city: string;
    country: string;
    complement: string;
    neighborhood: string;
    number: string;
    state: string;
    postalcode: string;
    region: RegionStatus;
  };
}

export interface IOrder {
  id: number;
  activityId?: number;
}

export interface IPlanningRequest {
  vehicleId: NumberFieldValue;
  orders: IOrder[];
  planningConfigId: NumberFieldValue;
  useZoneRestriction: boolean;
  companyDocument: number;
  previsionDate: string;
}

export interface IPlanningLoad {
  id: number;
  name: string;
}

interface IPlanningContext {
  isModalOpen: boolean;
  selected: ISelectedOrder[];
  setSelected: React.Dispatch<React.SetStateAction<ISelectedOrder[]>>;
  handleSelectOrder(order: ISelectedOrder): void;
  handleSelectAllOrders(
    checked: boolean,
    availableOrders: ISelectedOrder[]
  ): void;
  isTableChecked(availableOrders: ISelectedOrder[]): boolean;
  isTableIndeterminate(availableOrders: ISelectedOrder[]): boolean;
  isOrderSelected(order: ISelectedOrder): boolean;
  handleToggleModal(): void;
  loadPlanningList(companyDocument: number): Promise<IPlanningLoad[]>;
  createManualPlanning(
    data: IPlanningRequest
  ): Promise<AxiosResponse | undefined>;
}

const PlanningContext = createContext<IPlanningContext>({} as IPlanningContext);

const PlanningProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState<ISelectedOrder[]>([]);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const handleSelectOrder = useCallback(
    (order: ISelectedOrder) => selectItem(order, selected, setSelected),
    [selected]
  );

  const handleSelectAllOrders = useCallback(
    (checked: boolean, availableOrders: ISelectedOrder[]) =>
      selectAllItems(checked, availableOrders, selected, setSelected),
    [selected]
  );

  const isTableChecked = useCallback(
    (availableOrders: ISelectedOrder[]) =>
      isCheckboxChecked(availableOrders, selected),
    [selected]
  );

  const isTableIndeterminate = useCallback(
    (availableOrders: ISelectedOrder[]) =>
      isCheckboxIndeterminate(availableOrders, selected),
    [selected]
  );

  const isOrderSelected = useCallback(
    (order: ISelectedOrder) => isItemSelected(order, selected),
    [selected]
  );

  const loadPlanningList = useCallback(async (companyDocument: number) => {
    const response = await api.get(
      `/planning-config?companyDocument=${companyDocument}`
    );

    return response.data;
  }, []);

  const createManualPlanning = useCallback(
    async (data: IPlanningRequest) => {
      try {
        const response = await api
          .post(`/gateway-maps/create-route-planning`, data)
          .then((response: AxiosResponse) => {
            return response;
          });

        return response;
      } catch (error: any) {
        enqueueSnackbar(error.errors[0], {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar]
  );

  return (
    <PlanningContext.Provider
      value={{
        isModalOpen,
        selected,
        setSelected,
        handleSelectOrder,
        handleSelectAllOrders,
        isTableChecked,
        isTableIndeterminate,
        isOrderSelected,
        handleToggleModal,
        loadPlanningList,
        createManualPlanning,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
};

function usePlanning(): IPlanningContext {
  const context = useContext(PlanningContext);

  if (!context) {
    throw new Error('usePlanning must be used within a PlanningProvider');
  }

  return context;
}

export { PlanningProvider, usePlanning };
