import { format, subDays } from 'date-fns';
import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import api from '../../../services/api';

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

export interface ILoadInventory {
  id: number;
  master: string;
  userName: string;
  import: {
    quantity: string;
  };
  quantity: number;
  items: {
    id: string;
    master: string;
    awb: string;
    scanDate: string;
  }[];
}

interface IFilterInventory {
  initialDate?: string;
  finalDate?: string;
  master?: string;
  user?: string;
}

interface IInventoryContext {
  loadInventory(
    companyDocument: number,
    options?: IFilterInventory
  ): Promise<ILoadInventory[]>;
  filter: IFilterInventory;
  setFilter: React.Dispatch<React.SetStateAction<IFilterInventory>>;
}

const InventoryContext = createContext<IInventoryContext>(
  {} as IInventoryContext
);

const InventoryProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<IFilterInventory>({
    initialDate: formatDate(subDays(new Date(), 6)),
    finalDate: formatDate(new Date()),
    master: '',
    user: '',
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: formatDate(subDays(new Date(), 6)),
      finalDate: formatDate(new Date()),
      master: '',
      user: '',
    }));
  }, []);

  const loadInventory = useCallback(
    async (companyDocument: number, options: IFilterInventory) => {
      let query = '';

      const { initialDate, finalDate, master, user } = options;

      query =
        `${initialDate && `?initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}` +
        `${master && `&master=${master}`}` +
        `${user && `&user=${user}`}`;

      const response = await api.get(
        `/inventory/by-filter/${companyDocument}${query}`
      );

      return response.data;
    },
    []
  );

  return (
    <InventoryContext.Provider
      value={{
        loadInventory,
        filter,
        setFilter,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

function useInventory(): IInventoryContext {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('useInventory must be used with in a InventoryProvider');
  }

  return context;
}

export { InventoryProvider, useInventory };
