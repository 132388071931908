import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  CircularProgress,
  Typography,
  InputAdornment,
} from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import { maskMeasure, maskWeight } from '../../../../utils';

import {
  IStoragePositionRequest,
  useStoragePosition,
} from '../../hooks/storage-position';

import { useStyles, Form } from './styles';

interface IParams {
  storagePositionId?: string;
}

export const StoragePositionForm = () => {
  const {
    data: { user },
  } = useAuth();
  const { storagePositionId } = useParams<IParams>();
  const {
    loadStoragePositionById,
    updateStoragePosition,
    createStoragePosition,
  } = useStoragePosition();
  const history = useHistory();
  const classes = useStyles();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingStoragePositionId, setLoadingStoragePositionId] =
    useState(true);
  const [data, setData] = useState<IStoragePositionRequest>({
    companyDocument: user.companyDocument.toString(),
    distributionCenterCode: '',
    street: '',
    column: '',
    level: '',
    apartment: '',
    code: '',
    depth: '',
    height: '',
    width: '',
    maxWeight: '',
    maxVolume: '',
  });

  const endAdornment = <InputAdornment position="end">m</InputAdornment>;

  useEffect(() => {
    async function loadStoragePosition(): Promise<void> {
      try {
        if (storagePositionId) {
          const response = await loadStoragePositionById(
            parseInt(storagePositionId)
          );

          if (!response) return;

          setData(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingStoragePositionId(false);
      }
    }

    loadStoragePosition();
  }, [loadStoragePositionById, storagePositionId]);

  const validationSchema = yup.object({
    distributionCenterCode: yup
      .string()
      .required('CD é obrigatório')
      .min(3, 'Mínimo de 3 caracteres'),
    street: yup
      .string()
      .required('Rua é obrigatório')
      .min(2, 'Mínimo de 2 caracteres'),
    column: yup
      .string()
      .required('Coluna é obrigatório')
      .min(3, 'Mínimo de 3 caracteres'),
    level: yup
      .string()
      .required('Nivel é obrigatório')
      .min(2, 'Mínimo de 2 caracteres'),
    apartment: yup
      .string()
      .required('Apartamento é obrigatório')
      .min(2, 'Mínimo de 2 caracteres'),
    depth: yup.number().required('Comprimento é obrigatório'),
    height: yup.number().required('Altura é obrigatório'),
    width: yup.number().required('Largura é obrigatório'),
    maxWeight: yup.number().required('Peso Máximo é obrigatório'),
    maxVolume: yup.number().required('Volume Máximo é obrigatório'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      let data: any = {};

      data = {
        ...values,
        code:
          values.distributionCenterCode +
          values.street +
          values.column +
          values.level +
          values.apartment,
      };

      try {
        if (storagePositionId) {
          await updateStoragePosition(parseInt(storagePositionId), data);
        } else {
          await createStoragePosition(data);
        }

        history.push(ROUTES.storagePositionList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      let valueLength: any;

      if (name === 'distributionCenterCode' || name === 'column') {
        valueLength = 3;
      } else {
        valueLength = 2;
      }

      function lengthValidate(data: string) {
        return data.length > valueLength
          ? data.split('', valueLength).join('')
          : data;
      }

      formik.setFieldValue(name, lengthValidate(value));
    },
    [formik]
  );

  const handleChangeWithMask = useCallback(
    (e) => {
      const { value, name } = e.target;
      const measures = ['depth', 'height', 'width'];

      if (measures.includes(name)) {
        formik.setFieldValue(name, maskMeasure(value));
      } else if (name === 'maxWeight') {
        formik.setFieldValue(name, maskWeight(value));
      }
    },
    [formik]
  );

  const subtitle = useMemo(() => {
    if (storagePositionId) {
      return 'Alterar posição de armazenagem';
    } else {
      return 'Cadastrar posição de armazenagem';
    }
  }, [storagePositionId]);

  const submitButtonTitle = useMemo(() => {
    if (storagePositionId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [storagePositionId]);

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.storagePositionList}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {storagePositionId && loadingStoragePositionId ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      autoFocus
                      label="CD"
                      id="distributionCenterCode"
                      name="distributionCenterCode"
                      value={formik.values.distributionCenterCode}
                      onChange={handleChangeValue}
                      error={
                        formik.touched.distributionCenterCode &&
                        Boolean(formik.errors.distributionCenterCode)
                      }
                      helperText={
                        formik.touched.distributionCenterCode &&
                        formik.errors.distributionCenterCode
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Rua"
                      id="street"
                      name="street"
                      value={formik.values.street}
                      onChange={handleChangeValue}
                      error={
                        formik.touched.street && Boolean(formik.errors.street)
                      }
                      helperText={formik.touched.street && formik.errors.street}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Coluna"
                      id="column"
                      name="column"
                      value={formik.values.column}
                      onChange={handleChangeValue}
                      error={
                        formik.touched.column && Boolean(formik.errors.column)
                      }
                      helperText={formik.touched.column && formik.errors.column}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Nivel"
                      id="level"
                      name="level"
                      value={formik.values.level}
                      onChange={handleChangeValue}
                      error={
                        formik.touched.level && Boolean(formik.errors.level)
                      }
                      helperText={formik.touched.level && formik.errors.level}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Apartamento"
                      id="apartment"
                      name="apartment"
                      value={formik.values.apartment}
                      onChange={handleChangeValue}
                      error={
                        formik.touched.apartment &&
                        Boolean(formik.errors.apartment)
                      }
                      helperText={
                        formik.touched.apartment && formik.errors.apartment
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Comprimento"
                      id="depth"
                      name="depth"
                      value={formik.values.depth}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.depth && Boolean(formik.errors.depth)
                      }
                      helperText={formik.touched.depth && formik.errors.depth}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Altura"
                      id="height"
                      name="height"
                      value={formik.values.height}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.height && Boolean(formik.errors.height)
                      }
                      helperText={formik.touched.height && formik.errors.height}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Largura"
                      id="width"
                      name="width"
                      value={formik.values.width}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.width && Boolean(formik.errors.width)
                      }
                      helperText={formik.touched.width && formik.errors.width}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Peso Máximo"
                      id="maxWeight"
                      name="maxWeight"
                      value={formik.values.maxWeight}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.maxWeight &&
                        Boolean(formik.errors.maxWeight)
                      }
                      helperText={
                        formik.touched.maxWeight && formik.errors.maxWeight
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Volume Máximo"
                      id="maxVolume"
                      name="maxVolume"
                      value={formik.values.maxVolume}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.maxVolume &&
                        Boolean(formik.errors.maxVolume)
                      }
                      helperText={
                        formik.touched.maxVolume && formik.errors.maxVolume
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Código da Posição"
                      id="code"
                      name="code"
                      value={
                        formik.values.distributionCenterCode +
                        formik.values.street +
                        formik.values.column +
                        formik.values.level +
                        formik.values.apartment
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    {submitButtonTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
