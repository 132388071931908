import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import styled from 'styled-components';
import LinearProgressMUI from '@material-ui/core/LinearProgress';

interface ILinearProgressProps {
  backgroundColor: string;
}

const itemAgentWidth = '150px';

export const useStyles = makeStyles({
  customMarker: {
    '& div.marker-pin': {
      width: 30,
      height: 30,
      border: '1px #686868 solid',
      borderRadius: '50% 50% 50% 0',
      background: '#0088d7',
      position: 'absolute',
      transform: 'rotate(-45deg)',
      left: '50%',
      top: '50%',
      margin: '-15px 0 0 -15px',
    },

    '& div.marker-pin::after': {
      content: '""',
      width: 24,
      height: 24,
      margin: '2px 0 0 2px',
      background: '#F5F5F5',
      border: '1px #686868 solid',
      position: 'absolute',
      borderRadius: '50%',
    },

    '& b': {
      position: 'absolute',
      width: 22,
      fontSize: 16,
      left: 0,
      right: 0,
      margin: '9px auto',
      textAlign: 'center',
    },

    '& i': {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: '5.5px auto',
      textAlign: 'center',
      '& svg': {
        width: 12,
        height: 'auto',
        transform: 'rotate(60deg)',
      },
    },
  },
});

const LinearProgressStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[300],
    },
    bar: {
      borderRadius: 5,
    },
  })
)(LinearProgressMUI);

export const LinearProgress = styled(LinearProgressStyled).withConfig({
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop),
})<ILinearProgressProps>`
  position: absolute;
  top: calc(50% - 5px);
  right: 0;
  left: 0;
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export const TimelineHeader = styled.header`
  position: relative;
  display: flex;
  height: 50px;
  padding-right: 20px;
  div.button-group {
    display: inline-flex;
    width: ${itemAgentWidth};
    height: inherit;
    align-items: center;
    padding: 0 0 0 6px;

    .MuiToggleButtonGroup-root {
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }

    .MuiToggleButton-root {
      padding: 4px 10px;
      border: none;
    }
  }

  div.timeline {
    position: relative;
    /* overflow: hidden; */
    flex: 1;
    height: 100%;
    min-height: 45px;
    min-width: 0;

    margin-left: 10px;
    color: #ffffffe6;

    span {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TimelineItem = styled.div<{ even: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 24px 4px 4px;
  background-color: ${({ even }) => (even ? '#fff' : '#f5f5f5')};
`;

export const ItemAgent = styled.div`
  display: flex;
  align-items: center;
  width: ${itemAgentWidth};
  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    & > span {
      line-height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const LineContainer = styled.div`
  position: relative;
  flex: 1;
  min-height: 45px;
  margin-left: 10px;
`;

export const Position = styled.div<{ backgroundColor: string }>`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};

  span {
    position: absolute;
    transform: translate(-20%, -90%);
    font-size: 12px;
  }
`;
