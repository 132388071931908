import axios from 'axios';

interface IAddress {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: number;
  gia: number;
}

export const getAddress = async (cep: string): Promise<IAddress> => {
  const response = await axios({
    method: 'get',
    url: `https://viacep.com.br/ws/${cep}/json`,
  });

  return response.data;
};
