import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { TableCell } from '../../../../components/Table/TableCell';
import { ConferenceStatus, ShippingStatus } from '../../../../constants';
import { useSideBar } from '../../../../hooks/sideBar';
import { conferenceStatusLabel, shippingStatusLabel } from '../../../../utils';
import { IItems } from '../../../order/hooks/order';
import { PaginatedSeparation, useSeparation } from '../../hooks/separation';
import { ItemCollapseRow } from './ItemCollapseRow';
import { Loading } from '../../../../components/Loading';
import { useAgco } from '../../hooks/agco';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

interface IProps {
  date: string;
}

export const OrderSeparationTable = ({ date }: IProps) => {
  const classes = useStyles();
  const {
    handleSelect,
    handleSelectAll,
    isSelected,
    isChecked,
    isIndeterminate,
  } = useAgco();
  const { isSideBarDesktopOpen } = useSideBar();
  const { separationFilter, loadSeparateOrders } = useSeparation();
  const [loading, setLoading] = useState(true);
  const [openCollapse, setOpenCollapse] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [separateOrders, setSeparateOrders] = useState<PaginatedSeparation>({
    orders: [],
    total: 0,
  });
  const [tableWidth, setTableWidth] = useState<number | undefined>();
  const tableRef = useRef<HTMLElement | null>();
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, separateOrders.total - page * rowsPerPage);

  const availableSeparateOrders = separateOrders.orders;

  window.onresize = handleTableWidth;

  function handleTableWidth() {
    setTableWidth(tableRef.current?.offsetWidth);
  }

  useEffect(() => {
    async function asyncLoadSeparateOrders(): Promise<void> {
      try {
        setLoading(true);
        const response = await loadSeparateOrders({
          ...separationFilter,
          initialDate: date,
          finalDate: date,
          take: rowsPerPage,
          page: page + 1,
        });

        if (!response) return;

        setSeparateOrders(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    asyncLoadSeparateOrders();
  }, [date, loadSeparateOrders, page, rowsPerPage, separationFilter]);

  useEffect(() => {
    const orderFirstPosition = separateOrders.orders[0];

    separateOrders.orders.length === 1 &&
      setOpenCollapse([orderFirstPosition.id]);
  }, [separateOrders.orders]);

  useEffect(() => {
    setTimeout(() => {
      setTableWidth(tableRef.current?.offsetWidth);
    }, 300);
  }, [isSideBarDesktopOpen]);

  const handleCollapse = useCallback((orderId) => {
    setOpenCollapse((prevState) => {
      const orderIdIndex = prevState.findIndex((prev) => prev === orderId);
      if (orderIdIndex > -1) prevState.splice(orderIdIndex, 1);

      const newArray =
        orderIdIndex > -1 ? [...prevState] : [...prevState, orderId];

      return newArray;
    });
  }, []);

  const handleShippingStatusChange = useCallback(
    (orderId: number, status: ShippingStatus) => {
      setSeparateOrders((prevState) => {
        const orders = [...prevState.orders].map((order) => {
          if (order.id === orderId) {
            order.shippingStatus = status;
          }
          return order;
        });

        return { ...prevState, orders: [...orders] };
      });
    },
    []
  );

  const handleConferenceChange = useCallback(
    (orderId: number, status: ConferenceStatus, items: IItems[]) => {
      setSeparateOrders((prevState) => {
        const orders = [...prevState.orders].map((order) => {
          if (order.id === orderId) {
            order.conferenceStatus = status;
            order.items = items;
          }
          return order;
        });

        return { ...prevState, orders: JSON.parse(JSON.stringify(orders)) };
      });
    },
    []
  );

  const handleReferencedItemsChange = useCallback(
    (orderId: number, itemsId: number[]) => {
      setSeparateOrders((prevState) => {
        const orders = [...prevState.orders].map((order) => {
          if (order.id === orderId) {
            order.items.map((item) => {
              if (itemsId.includes(item.id)) {
                item.wasReferenced = true;
              }

              return item;
            });
          }
          return order;
        });

        return { ...prevState, orders: JSON.parse(JSON.stringify(orders)) };
      });
    },
    []
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="order table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={isIndeterminate(availableSeparateOrders)}
                  checked={isChecked(availableSeparateOrders)}
                  onChange={(e, checked) =>
                    handleSelectAll(checked, availableSeparateOrders)
                  }
                  inputProps={{ 'aria-label': 'select all orders' }}
                />
              </TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Status de envio</TableCell>
              <TableCell>Status conferência</TableCell>
              <TableCell align="right">Quantidade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loading columns={7} rows={rowsPerPage} />
            ) : (
              separateOrders.orders.map((order) => {
                const isItemSelected = isSelected(order);

                return (
                  <React.Fragment key={order.id}>
                    <TableRow className={classes.root}>
                      <TableCell padding="checkbox">
                        <IconButton
                          size="small"
                          onClick={() => handleCollapse(order.id)}
                          aria-label="expand collapse row"
                        >
                          {openCollapse.includes(order.id) ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => handleSelect(order)}
                          inputProps={{
                            'aria-labelledby': order.id.toString(),
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <strong>
                          {order.referenceCode
                            ? order.identification
                            : order.number}
                        </strong>
                      </TableCell>
                      <TableCell>
                        {shippingStatusLabel(order.shippingStatus)}
                      </TableCell>
                      <TableCell>
                        {conferenceStatusLabel(order.conferenceStatus)}
                      </TableCell>
                      <TableCell align="right">{order.items.length}</TableCell>
                    </TableRow>

                    <ItemCollapseRow
                      order={order}
                      open={openCollapse.includes(order.id)}
                      colSpan={7}
                      maxTableWidth={tableWidth}
                      onShippingStatusChange={handleShippingStatusChange}
                      onConferenceChange={handleConferenceChange}
                      onReferencedItemsChange={handleReferencedItemsChange}
                    />
                  </React.Fragment>
                );
              })
            )}

            {!loading && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={separateOrders.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

// <BasicTable
//   columns={columns}
//   loading={loading}
//   total={separateOrders.total}
//   pagination={[page, setPage]}
//   rowsPerPageState={[rowsPerPage, setRowsPerPage]}
//   innerRef={tableRef}
// >
//   {separateOrders.orders.map((order) => (
//     <React.Fragment key={order.id}>
//       <TableRow className={classes.root}>
//         <TableCell padding="checkbox">
//           <IconButton
//             size="small"
//             onClick={() => handleCollapse(order.id)}
//             aria-label="expand collapse row"
//           >
//             {openCollapse.includes(order.id) ? (
//               <KeyboardArrowUpIcon />
//             ) : (
//               <KeyboardArrowDownIcon />
//             )}
//           </IconButton>
//         </TableCell>
//         <TableCell>
//           <strong>
//             {order.referenceCode ? order.identification : order.number}
//           </strong>
//         </TableCell>
//         <TableCell>{shippingStatusLabel(order.shippingStatus)}</TableCell>
//         <TableCell>
//           {conferenceStatusLabel(order.conferenceStatus)}
//         </TableCell>
//         <TableCell align="right">{order.items.length}</TableCell>
//       </TableRow>

//       <ItemCollapseRow
//         order={order}
//         open={openCollapse.includes(order.id)}
//         colSpan={columns.length}
//         maxTableWidth={tableWidth}
//         onShippingStatusChange={handleShippingStatusChange}
//         onConferenceChange={handleConferenceChange}
//         onReferencedItemsChange={handleReferencedItemsChange}
//       />
//     </React.Fragment>
//   ))}
// </BasicTable>
