import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { TableCell } from '../../../components/Table/TableCell';
import {
  ILoadVehicleType,
  useVehicleType,
} from '../../vehicle-type/hooks/vehicle-type';
import { useAuth } from '../../../hooks/auth';

interface AddVehicleTypeModalProps {
  open: boolean;
  addedVehicleTypes: string[];
  onClose: () => void;
  onSubmit: (vehicles: string[]) => void;
}

export const AddVehicleTypeModal = ({
  open,
  addedVehicleTypes,
  onClose,
  onSubmit,
}: AddVehicleTypeModalProps) => {
  const {
    data: { user },
  } = useAuth();
  const { loadVehicleTypeList } = useVehicleType();

  const [vehicleTypeList, setVehicleTypeList] = useState<ILoadVehicleType[]>(
    []
  );
  const [newAdded, setNewAdded] = useState<string[]>([...addedVehicleTypes]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    let active = true;

    async function getVehicleTypes() {
      try {
        const response = await loadVehicleTypeList(user.companyDocument);

        if (!response) return;

        if (active) setVehicleTypeList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getVehicleTypes();

    return () => {
      active = false;
    };
  }, [loadVehicleTypeList, user.companyDocument]);

  useEffect(() => {
    setNewAdded([...addedVehicleTypes]);
  }, [addedVehicleTypes, open]);

  const searchResult = useMemo(
    () =>
      vehicleTypeList.filter((type) =>
        type.name.toLowerCase().includes(searchField.toLocaleLowerCase())
      ),
    [searchField, vehicleTypeList]
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchField(value);
  };

  const handleSubmit = () => {
    onSubmit(newAdded);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Adicionar Produtos</DialogTitle>

      <DialogContent>
        <TextField
          variant="outlined"
          id="search"
          name="search"
          size="small"
          value={searchField}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: '0.5rem' }}
        />

        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo do Veículo</TableCell>
                <TableCell align="center">Adicionar</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <Loading columns={2} rows={rowsPerPage} />
              ) : (
                searchResult
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vehicle) => {
                    const isAdded = !!newAdded.find(
                      (added) => added === vehicle.name
                    );

                    return (
                      <TableRow key={vehicle.id} hover>
                        <TableCell component="th" scope="row">
                          {vehicle.name}
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <IconButton
                              color="primary"
                              disabled={isAdded}
                              onClick={() => {
                                setNewAdded((prevState) => [
                                  ...prevState,
                                  vehicle.name,
                                ]);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              disabled={!isAdded}
                              onClick={() => {
                                setNewAdded((prevState) => {
                                  const newState = [...prevState].filter(
                                    (state) => state !== vehicle.name
                                  );

                                  return newState;
                                });
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={vehicleTypeList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Fechar
        </Button>

        <Button onClick={handleSubmit} color="secondary">
          Concluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
