import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { MaterialPicker, ChromePicker } from 'react-color';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import {
  ILoadVehicleType,
  useVehicleType,
} from '../../../vehicle-type/hooks/vehicle-type';
import {
  ILoadUser,
  useUserManagement,
} from '../../../user-management/hooks/user-management';
import {
  ILoadDeliveryRegion,
  useDeliveryRegion,
} from '../../../delivery-region/hooks/delivery-region';
import { IVehicleRequest, useVehicle } from '../../hooks/vehicle';

import { useStyles, Form } from './styles';

interface IParams {
  vehicleId?: string;
}

export const VehicleForm = () => {
  const {
    data: { user },
    setCompanyDefault,
    companyDefault,
  } = useAuth();
  const { vehicleId } = useParams<IParams>();
  const { loadUserList } = useUserManagement();
  const { loadVehicleTypeList } = useVehicleType();
  const { loadDeliveryRegionList } = useDeliveryRegion();
  const { vehicleStatus, loadVehicleById, updateVehicle, createVehicle } =
    useVehicle();
  const history = useHistory();
  const classes = useStyles();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingVehicleId, setLoadingVehicleId] = useState(true);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [users, setUsers] = useState<ILoadUser[]>([]);
  const [deliveryRegion, setDeliveryRegion] = useState<ILoadDeliveryRegion[]>(
    []
  );
  const [vehicleTypes, setVehicleTypes] = useState<ILoadVehicleType[]>([]);
  const [data, setData] = useState<IVehicleRequest>({
    name: '',
    plate: '',
    status: 'active',
    driverId: '',
    vehicleTypeId: '',
    mapLineColor: '#fff',
    zoneDelivery: 'generica',
    companyDocument: user.companyDocument,
  });

  useEffect(() => {
    async function loadVehicle(): Promise<void> {
      try {
        if (vehicleId) {
          const response = await loadVehicleById(parseInt(vehicleId));

          if (!response) return;

          setData({
            ...response,
            vehicleTypeId: response.vehicleType.id,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingVehicleId(false);
      }
    }

    loadVehicle();
  }, [loadVehicleById, vehicleId]);

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const response = await loadUserList(user.companyDocument);

      if (!response) return;

      setUsers(response);
    }

    loadUser();
  }, [loadUserList, user.companyDocument]);

  useEffect(() => {
    async function loadVehicleType(): Promise<void> {
      const response = await loadVehicleTypeList(user.companyDocument);

      if (!response) return;

      setVehicleTypes(response);
    }

    loadVehicleType();
  }, [loadVehicleTypeList, user.companyDocument]);

  useEffect(() => {
    async function loadDeliveryRegion(): Promise<void> {
      try {
        const response = await loadDeliveryRegionList();

        if (!response) return;

        setDeliveryRegion(response);
      } catch (error) {}
    }

    loadDeliveryRegion();
  }, [loadDeliveryRegionList]);

  const validationSchema = yup.object({
    name: yup.string().required('Insira o nome'),
    plate: yup.string().required('Placa é obrigatória'),
    status: yup.string().required('Status é obrigatório'),
    driverId: yup.number().required('Motorista é obrigatório'),
    vehicleTypeId: yup.number().required('Tipo do veículo é obrigatório'),
    zoneDelivery: yup.string().required('Região é obrigatório'),
    mapLineColor: yup.string().required('Cor do motorista é obrigatório'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        if (vehicleId) {
          await updateVehicle(parseInt(vehicleId), values);
        } else {
          await createVehicle(values);
        }

        let newCompanyDefault: any = {};

        newCompanyDefault = {
          ...companyDefault,
          hasVehicle: true,
        };

        setCompanyDefault(newCompanyDefault);
        localStorage.setItem(
          '@4log:companyDefault',
          JSON.stringify(newCompanyDefault)
        );

        history.push(ROUTES.vehicleList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleChangeColor = useCallback(
    (color, event) => {
      formik.setFieldValue('mapLineColor', color.hex);
    },
    [formik]
  );

  const handleClick = useCallback(() => {
    setDisplayColorPicker((prevState) => !prevState);
  }, []);

  const subtitle = useMemo(() => {
    if (vehicleId) {
      return 'Alterar veículo';
    } else {
      return 'Cadastrar veículo';
    }
  }, [vehicleId]);

  const submitButtonTitle = useMemo(() => {
    if (vehicleId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [vehicleId]);

  return (
    <Main title={subtitle} maxWidth="md" button="back" to={ROUTES.vehicleList}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {vehicleId && loadingVehicleId ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      autoFocus
                      label="Nome"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Placa"
                      id="plate"
                      name="plate"
                      value={formik.values.plate}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.plate && Boolean(formik.errors.plate)
                      }
                      helperText={formik.touched.plate && formik.errors.plate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Status"
                      id="status"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      helperText={formik.touched.status && formik.errors.status}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {vehicleStatus.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Tipo do veículo"
                      id="vehicleTypeId"
                      name="vehicleTypeId"
                      value={formik.values.vehicleTypeId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.vehicleTypeId &&
                        Boolean(formik.errors.vehicleTypeId)
                      }
                      helperText={
                        formik.touched.vehicleTypeId &&
                        formik.errors.vehicleTypeId
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione um tipo</em>
                      </MenuItem>
                      {vehicleTypes.map((type) => (
                        <MenuItem
                          key={`vehicleType-${type.id}`}
                          value={type.id}
                        >
                          {type.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Motorista"
                      id="driverId"
                      name="driverId"
                      value={formik.values.driverId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.driverId &&
                        Boolean(formik.errors.driverId)
                      }
                      helperText={
                        formik.touched.driverId && formik.errors.driverId
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione o motorista</em>
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem key={`user-${user.id}`} value={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Região"
                      id="zoneDelivery"
                      name="zoneDelivery"
                      value={formik.values.zoneDelivery}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.zoneDelivery &&
                        Boolean(formik.errors.zoneDelivery)
                      }
                      helperText={
                        formik.touched.zoneDelivery &&
                        formik.errors.zoneDelivery
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione a região de entrega</em>
                      </MenuItem>
                      <MenuItem value="generica">Sem zoneamento</MenuItem>
                      {deliveryRegion.map((region) => (
                        <MenuItem
                          key={`region-${region.id}`}
                          value={region.name}
                        >
                          {region.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography component="span" align="center">
                        Selecione uma cor única para seu motorista
                      </Typography>

                      <div
                        style={{
                          marginTop: 16,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <div style={{ width: 98 }}>
                          <MaterialPicker
                            styles={{
                              default: {
                                material: {
                                  height: 'auto',
                                  fontFamily: `Maven Pro`,
                                },
                              },
                            }}
                            color={formik.values.mapLineColor}
                            onChangeComplete={handleChangeColor}
                          />
                        </div>
                        <div
                          onClick={handleClick}
                          style={{
                            content: '',
                            width: 98,
                            boxShadow:
                              'rgba(0, 0, 0, 0.12) 0px 2px 10px, rgba(0, 0, 0, 0.16) 0px 2px 5px',
                            borderRadius: 2,
                            backgroundColor: formik.values.mapLineColor,
                          }}
                        ></div>
                        {displayColorPicker && (
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 2,
                            }}
                          >
                            <div
                              style={{
                                position: 'fixed',
                                top: '0px',
                                right: '0px',
                                bottom: '0px',
                                left: '0px',
                              }}
                              onClick={handleClick}
                            />
                            <ChromePicker
                              color={formik.values.mapLineColor}
                              onChange={handleChangeColor}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ padding: 5 }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    {submitButtonTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
