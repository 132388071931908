import { Typography } from '@material-ui/core';
import img from '../../assets/access_denied.svg';
import { Main } from '../Main';

export const AccessDenied = () => {
  return (
    <Main>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <Typography variant="h2" color="error">
          ACESSO NEGADO
        </Typography>
        <Typography variant="body2" align="center">
          <strong>
            Oops, caso queira acessar esta página entre em contato com o
            responsável ou administrador do sistema.
          </strong>
        </Typography>

        <img
          style={{
            marginTop: 32,
            maxWidth: 400,
            width: '100%',
            height: 'auto',
          }}
          src={img}
          alt="Acesso Negado"
        />
      </div>
    </Main>
  );
};
