import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Grid,
  TextField,
  Button,
  TableRow,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';

import { useAuth } from '../../../../hooks/auth';
import { useVehicle } from '../../../vehicle/hooks/vehicle';

import { generateVehicleReportPDF } from '../../templates/vehicleReport';
import XLSX from 'xlsx';

export type TypeVehicleReport = {
  id: number;
  name: string;
  plate: string;
  status: string;
  vehicleType: string;
  driver: string;
};

export const VehicleReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { vehicleStatus, loadVehicleList } = useVehicle();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [vehicles, setVehicles] = useState<TypeVehicleReport[]>([]);

  const columnsTable: IBasicColumn[] = [
    { name: 'Nome' },
    { name: 'Placa' },
    { name: 'Status' },
    { name: 'Tipo do Veículo' },
    { name: 'Motorista' },
  ];

  const returnVehicleStatus = useCallback(
    (status: string) => {
      const label = vehicleStatus.find(
        (vStatus) => vStatus.value === status
      )?.label;

      if (label) {
        return label;
      }

      return status;
    },
    [vehicleStatus]
  );

  useEffect(() => {
    async function loadVehicleReport(): Promise<void> {
      try {
        const response = await loadVehicleList(user.companyDocument);

        if (!response) return;

        const data: any = response.map((vehicle) => {
          return {
            id: vehicle.id,
            name: vehicle.name,
            plate: vehicle.plate,
            status: returnVehicleStatus(vehicle.status),
            vehicleType: vehicle.vehicleType.name,
            driver: vehicle.user ? vehicle.user.name : '',
          };
        });

        setVehicles(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadVehicleReport();
  }, [loadVehicleList, returnVehicleStatus, user.companyDocument]);

  const vehicleFilter = useMemo((): TypeVehicleReport[] => {
    const fields = ['name', 'plate', 'status', 'vehicleType', 'driver'];
    const matchedSearches = vehicles.filter((vehicle: TypeVehicleReport) => {
      const includesOneOf = !!fields.find((field) =>
        vehicle[field as keyof TypeVehicleReport]
          .toString()
          .toLowerCase()
          .includes(searchField.toLowerCase())
      );
      return includesOneOf;
    });

    return matchedSearches;
  }, [searchField, vehicles]);

  const downloadExcel = useCallback((data: TypeVehicleReport[]) => {
    const newData = data.map((vehicle) => {
      return {
        name: vehicle.name,
        plate: vehicle.plate,
        status: vehicle.status,
        vehicleType: vehicle.vehicleType,
        driver: vehicle.driver,
      };
    });

    const header = [
      ['Nome', 'Placa', 'Status', 'Tipo de Veículo', 'Motorista'],
    ];

    const workSheetCols = [
      { width: 15 },
      { width: 10 },
      { width: 10 },
      { width: 14 },
      { width: 30 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'VEÍCULOS');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Veículos.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Veículos">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={(event) => setSearchField(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => generateVehicleReportPDF(vehicleFilter)}
              >
                Exportar PDF
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => downloadExcel(vehicleFilter)}
              >
                Exportar EXCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={vehicleFilter.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {vehicleFilter
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((vehicle) => (
                <TableRow key={vehicle.id} hover>
                  <TableCell component="th" scope="row">
                    {vehicle.name}
                  </TableCell>
                  <TableCell>{vehicle.plate}</TableCell>
                  <TableCell>{vehicle.status}</TableCell>
                  <TableCell>{vehicle.vehicleType}</TableCell>
                  <TableCell>{vehicle.driver}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
