import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../../../services/api';
import { ManualOrderModal } from '../components/ManualOrderModal';
import { ISplitOrderItems } from '../../separation/hooks/separation';

type OperationType = 'delivery' | 'collect';

interface IAddress {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  postalcode: string;
  city: string;
  state: string;
  country?: string;
  type?: string;
  phone?: string;
  lat?: string | null;
  long?: string | null;
  region?: string;
}

export interface ILoadSender {
  id: number;
  companyDocument: string;
  documentNumber: string;
  name: string;
  tradingName: string;
  email: string;
  phone: string;
  cellphone: string;
  addresses: IAddress[];
}

export interface ILoadCustomer {
  id: number;
  documentNumber: string;
  name: string;
  email: string;
  phone: string;
  cellphone: string;
  addresses: IAddress[];
}

interface IUser {
  name: string;
  documentNumber: string;
  email: string;
  cellphone: string;
  addresses: IAddress;
  type: string;
}

interface IImage {
  url?: string;
  base64?: string;
  position?: number;
}

export interface IItem {
  orderCode: string;
  description: string;
  price: number;
  productId: number;
  sku?: string;
  barcode?: string;
  quantity: number;
  freeShipping?: boolean;
  variantId?: number;
  depth: string;
  height: string;
  weight: string;
  width: string;
  images?: IImage[];
}

export interface IManualOrder {
  orderCode: string;
  number: string;
  serie: string;
  referenceCode: string;
  orderDate: string;
  deliveryPrevisionDate: string;
  collectPrevisionDate: string;
  operationType: OperationType;
  paymentStatus: string;
  shippingOption: string;
  distributionCenter: string;
  value: number;
  shippingCost: number;
  transferFreight: number;
  weight: string;
  observation: string;
  sender: IUser;
  customer: IUser;
  items: IItem[];
}

export interface IProduct {
  id: number;
  companyDocument: string;
  ownerDocument: string | undefined;
  sku: string;
  barcode: string;
  description: string;
  depth: string;
  height: string;
  weight: string;
  width: string;
  price: number;
  unit: string;
  active: true;
  images: [];
}

export interface IProductPaginated {
  products: IProduct[];
  total: number;
}

interface IManualOrderContext {
  steps: string[];
  shippingOption: string[];
  operationType: { value: string; label: string }[];
  paymentStatus: { value: string; label: string }[];
  order: IManualOrder;
  setOrder: React.Dispatch<React.SetStateAction<IManualOrder>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  option: number;
  setOption: React.Dispatch<React.SetStateAction<number>>;
  nextOrBack: number;
  setNextOrBack: React.Dispatch<React.SetStateAction<number>>;
  handleToggleManualOrder(): void;
  handleResetOrder(): void;
  handleNext(): void;
  handleBack(): void;
  loadProducts(query: string): Promise<IProductPaginated>;
  loadSenderList(filter: string): Promise<ILoadSender[]>;
  loadSenderById(id: number): Promise<ILoadSender>;
  loadCustomerList(filter: string): Promise<ILoadCustomer[]>;
  createOrder(order: IManualOrder): Promise<void>;
  getUniqueCode(): Promise<string>;
  createCollect(data: Omit<ISplitOrderItems, 'operation' >): Promise<void>;
}

const ManualOrderContext = createContext<IManualOrderContext>(
  {} as IManualOrderContext
);

const ManualOrderProvider: React.FC = ({ children }) => {
  const [openManualOrderModal, setOpenManualOrderModal] = useState(false);
  const [nextOrBack, setNextOrBack] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [option, setOption] = useState(0);
  const steps = [
    'Dados do Pedido',
    'Dados do Remetente',
    'Dados do Cliente',
    'Itens do Pedido',
  ];
  const [newOrder] = useState<IManualOrder>({
    orderCode: '',
    number: '',
    serie: '',
    referenceCode: '',
    orderDate: new Date().toJSON().slice(0, 10),
    deliveryPrevisionDate: '',
    collectPrevisionDate: '',
    operationType: 'delivery',
    paymentStatus: 'paid',
    shippingOption: '',
    distributionCenter: '',
    value: 0,
    shippingCost: 0,
    transferFreight: 0,
    weight: '',
    observation: '',
    sender: {
      name: '',
      documentNumber: '',
      email: '',
      cellphone: '',
      addresses: {
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        postalcode: '',
        city: '',
        state: '',
      },
      type: 'default',
    },
    customer: {
      name: '',
      documentNumber: '',
      email: '',
      cellphone: '',
      addresses: {
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        postalcode: '',
        city: '',
        state: '',
        lat: null,
        long: null
      },
      type: 'shipping_address',
    },
    items: [],
  });
  const [order, setOrder] = useState<IManualOrder>(newOrder);
  const shippingOption = [
    'Correios - SEDEX',
    'Correios - PAC',
    'Motoboy',
    'Transportadora',
  ];
  const operationType = [
    { value: 'delivery', label: 'Entrega' },
    { value: 'collect', label: 'Coleta' },
    { value: 'devolution', label: 'Devolução' },
    { value: 'cleaning', label: 'Limpeza' },
  ];
  const paymentStatus = [
    { value: 'paid', label: 'Pago' },
    { value: 'pending', label: 'Pendente' },
  ];

  const handleToggleManualOrder = useCallback(() => {
    setOpenManualOrderModal((prevState) => !prevState);
  }, []);

  const handleResetOrder = useCallback(() => {
    setTimeout(() => {
      setOrder(newOrder);
      setActiveStep(0);
    }, 500);
  }, [newOrder]);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const loadProducts = useCallback(async (query: string) => {
    const response = await api.get(`/product/paginated${query}`);

    return response.data;
  }, []);

  const loadSenderList = useCallback(async (filter: string) => {
    const response = await api.get(
      '/searchs/senders' +
        '?page=1' +
        // '&take=10' +
        `&filter${filter && `=${filter}`}`
    );

    return response.data;
  }, []);

  const loadSenderById = useCallback(async (id: number) => {
    const response = await api.get(`searchs/senders/by-id/${id}`);

    return response.data;
  }, []);

  const loadCustomerList = useCallback(async (filter: string) => {
    const response = await api.get(
      '/searchs/customers' +
        '?page=1' +
        // '&take=10' +
        `${filter && `&filter=${filter}`}`
    );

    return response.data;
  }, []);

  const createOrder = useCallback(async (order: IManualOrder) => {
    await api.post('/order/create/manual', order);
  }, []);

  const getUniqueCode = useCallback(async () => {
    const response = await api.get('/order/generate/unique-code');

    return response.data;
  }, []);

  const createCollect = useCallback(async (data: Omit<ISplitOrderItems, 'operation'>) => {
    await api.post('/order/split-order-items', {...data, operation: 'collect'});
  }, []);

  return (
    <ManualOrderContext.Provider
      value={{
        steps,
        operationType,
        paymentStatus,
        shippingOption,
        order,
        setOrder,
        activeStep,
        setActiveStep,
        option,
        setOption,
        nextOrBack,
        setNextOrBack,
        handleToggleManualOrder,
        handleResetOrder,
        handleNext,
        handleBack,
        loadProducts,
        loadSenderList,
        loadSenderById,
        loadCustomerList,
        createOrder,
        getUniqueCode,
        createCollect
      }}
    >
      <ManualOrderModal open={openManualOrderModal} />

      {children}
    </ManualOrderContext.Provider>
  );
};

function useManualOrder(): IManualOrderContext {
  const context = useContext(ManualOrderContext);

  if (!context) {
    throw new Error('useManualOrder must be used within a ManualOrderProvider');
  }

  return context;
}

export { ManualOrderProvider, useManualOrder };
