import { useCallback, useState } from 'react';
import {
  Button as MUIButton,
  Grid,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';

import {
  ModalActions,
  ModalContent,
  ModalTitle,
} from '../../../../../components/Modal';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../../components/Table/BasicTable';
import { TableCell } from '../../../../../components/Table/TableCell';
import { Button } from '../../../../../components/Button';
import { getAddress } from '../../../../../services/viaCep';
import { maskPostalcode, unmask } from '../../../../../utils';
import { IOrder, IShippingAddress } from '../../../../order/hooks/order';
import { SplitShippingAddress } from '../../../hooks/separation';
import schema from './schema';

import { format } from 'date-fns';

interface IShippingAddressModalProps {
  order: IOrder;
  fractionedItemsId: number[];
  currentShippingAddress: IShippingAddress;
  disablePrevisionDate?: boolean;
  title: string;
  close: () => void;
  onSubmit: (shipping: {
    address: SplitShippingAddress;
    previsionDate: string | null;
    observation: string | null;
  }) => Promise<void>;
}

export const ShippingAddressModal = ({
  order,
  fractionedItemsId,
  currentShippingAddress,
  disablePrevisionDate = false,
  title,
  ...props
}: IShippingAddressModalProps) => {
  const [loading, setLoading] = useState(false);
  const columns: IBasicColumn[] = [{ name: 'SKU' }, { name: 'Descrição' }];

  const formik = useFormik<
    SplitShippingAddress & { previsionDate: string; observation: string }
  >({
    initialValues: {
      street: currentShippingAddress.street,
      city: currentShippingAddress.city,
      complement: currentShippingAddress.complement,
      neighborhood: currentShippingAddress.neighborhood,
      number: currentShippingAddress.number,
      state: currentShippingAddress.state,
      postalcode: currentShippingAddress.postalcode,
      previsionDate: disablePrevisionDate
        ? ''
        : format(new Date(), 'yyyy-MM-dd'),
      observation: order.observation,
    },
    validationSchema: schema,
    onSubmit: async ({ previsionDate, observation, ...address }) => {
      setLoading(true);
      await props.onSubmit({
        previsionDate: previsionDate || null,
        observation: observation || null,
        address,
      });
      setLoading(false);
      props.close();
    },
  });

  const handleChangePostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);

      const postalcodeLength = 8;
      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf } = address;

          if (logradouro) {
            formik.setFieldValue('street', logradouro);
          }

          if (bairro) {
            formik.setFieldValue('neighborhood', bairro);
          }

          if (localidade) {
            formik.setFieldValue('city', localidade);
          }

          if (uf) {
            formik.setFieldValue('state', uf);
          }

          formik.setFieldValue('number', '');
          formik.setFieldValue('complement', '');
        }
      }
    },
    [formik]
  );

  return (
    <>
      <ModalTitle>{title || ''}</ModalTitle>

      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Produtos:</Typography>
          </Grid>

          <Grid item xs={12}>
            <BasicTable size="small" headerWithNoColor columns={columns}>
              {order.items.map((item) => {
                if (fractionedItemsId.includes(item.id)) {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        {item.sku ? item.sku : 'Não preenchido'}
                      </TableCell>
                      <TableCell>{item.description}</TableCell>
                    </TableRow>
                  );
                }

                return null;
              })}
            </BasicTable>
          </Grid>

          <Grid item xs={12}>
            <Typography>Endereço de entrega:</Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="CEP"
              id="postalcode"
              name="postalcode"
              value={maskPostalcode(formik.values.postalcode)}
              onChange={handleChangePostalcodeValue}
              error={
                formik.touched.postalcode && Boolean(formik.errors.postalcode)
              }
              helperText={formik.touched.postalcode && formik.errors.postalcode}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Rua"
              id="street"
              name="street"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Número"
              id="number"
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Bairro"
              id="neighborhood"
              name="neighborhood"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              error={
                formik.touched.neighborhood &&
                Boolean(formik.errors.neighborhood)
              }
              helperText={
                formik.touched.neighborhood && formik.errors.neighborhood
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Cidade"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Estado"
              id="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={true}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Complemeto"
              id="complement"
              name="complement"
              value={formik.values.complement}
              onChange={formik.handleChange}
              error={
                formik.touched.complement && Boolean(formik.errors.complement)
              }
              helperText={formik.touched.complement && formik.errors.complement}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid hidden={disablePrevisionDate} item xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Previsão de Execução"
              type="date"
              id="previsionDate"
              name="previsionDate"
              value={formik.values.previsionDate}
              onChange={formik.handleChange}
              error={
                formik.touched.previsionDate &&
                Boolean(formik.errors.previsionDate)
              }
              helperText={
                formik.touched.previsionDate && formik.errors.previsionDate
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              minRows={2}
              maxRows={4}
              label="Observação"
              id="observation"
              name="observation"
              value={formik.values.observation}
              onChange={formik.handleChange}
              error={
                formik.touched.observation && Boolean(formik.errors.observation)
              }
              helperText={
                formik.touched.observation && formik.errors.observation
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions>
        <MUIButton onClick={props.close} color="inherit">
          Fechar
        </MUIButton>

        <Button
          onClick={() => formik.handleSubmit()}
          loading={loading}
          colorLoading="inherit"
          type="button"
          color="secondary"
        >
          Confirmar
        </Button>
      </ModalActions>
    </>
  );
};
