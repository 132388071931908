import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useLocalStorage } from './localStorage';

interface ISideBarContext {
  isSideBarDesktopOpen: boolean;
  isSideBarMobileOpen: boolean;
  isCollapseRegistrationOpen: boolean;
  setIsCollapseRegistrationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapseReportsOpen: boolean;
  setIsCollapseReportsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapseSettingsOpen: boolean;
  setIsCollapseSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCollapseStorageOpen: boolean;
  setIsCollapseStorageOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSideBarToggleDesktop(): void;
  handleSideBarToggleMobile(): void;
  mediaUp600px: boolean;
}

const SideBarContext = createContext<ISideBarContext>({} as ISideBarContext);

const SideBarProvider: React.FC = ({ children }) => {
  const theme = useTheme();
  const mediaUp600px = useMediaQuery(theme.breakpoints.up('sm'));
  const [isSideBarDesktopOpen, setIsSideBarDesktopOpen] =
    useLocalStorage<boolean>('sideBar', true);
  const [isSideBarMobileOpen, setIsSideBarMobileOpen] = useState(false);
  const [isCollapseRegistrationOpen, setIsCollapseRegistrationOpen] =
    useState(false);
  const [isCollapseReportsOpen, setIsCollapseReportsOpen] = useState(false);
  const [isCollapseSettingsOpen, setIsCollapseSettingsOpen] = useState(false);
  const [isCollapseStorageOpen, setIsCollapseStorageOpen] = useState(false);

  useEffect(() => {
    if (mediaUp600px) {
      setIsSideBarMobileOpen(false);
    }
  }, [mediaUp600px]);

  const handleSideBarToggleDesktop = useCallback(() => {
    setIsSideBarDesktopOpen(!isSideBarDesktopOpen);
  }, [isSideBarDesktopOpen, setIsSideBarDesktopOpen]);

  const handleSideBarToggleMobile = useCallback(() => {
    setIsSideBarMobileOpen((prevState) => !prevState);
  }, []);

  return (
    <SideBarContext.Provider
      value={{
        isSideBarDesktopOpen,
        isSideBarMobileOpen,
        isCollapseRegistrationOpen,
        setIsCollapseRegistrationOpen,
        isCollapseReportsOpen,
        setIsCollapseReportsOpen,
        isCollapseSettingsOpen,
        setIsCollapseSettingsOpen,
        isCollapseStorageOpen,
        setIsCollapseStorageOpen,
        handleSideBarToggleDesktop,
        handleSideBarToggleMobile,
        mediaUp600px,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

function useSideBar(): ISideBarContext {
  const context = useContext(SideBarContext);

  if (!context) {
    throw new Error('useSideBar must be used within a SideBarProvider');
  }

  return context;
}

export { SideBarProvider, useSideBar };
