import React, { useCallback, useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import {
  TextField,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CardMedia,
  AppBar,
  Tabs,
  Link,
  Tab,
} from '@material-ui/core';

import { useAuth } from '../../../../hooks/auth';
import { IActivity as IActivityOrder, useOrder } from '../../hooks/order';
import { IActivity as IActivityRoute } from '../../../route/hooks/activity';
import { useRoute } from '../../../route/hooks/route';

import { useStyles } from './styles';

interface IProps {
  open: boolean;
  activity: IActivityOrder | IActivityRoute | null;
  routeId?: number;
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const Arrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <div
      id="arrow"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

type Image = {
  id: number;
  url: string;
  type: string;
};

export const ActivityInfo: React.FC<IProps> = ({
  open,
  activity,
  routeId,
  onClose,
}) => {
  const {
    data: { user },
  } = useAuth();
  const { loadPortalSavedImage, downloadPortalSavedImage } = useOrder();
  const { downloadAppSavedImage } = useRoute();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [images, setImages] = useState<Image[]>([]);
  const [index, setIndex] = useState<number>(0);
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: classes.slider,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  };

  useEffect(() => {
    async function loadPortalImages(): Promise<void> {
      if (activity) {
        if (activity.images.length > 0) {
          const newImages = activity.images.map((img) => ({
            id: img.id,
            url: img.image,
            type: !img.image.startsWith('http') ? 'base64' : 'link-app',
          }));

          setImages(newImages);
        } else {
          let options = {
            companyDocument: user.companyDocument.toString(),
            number: activity.number,
            orderCode: activity.orderCode,
          };
          try {
            const response = await loadPortalSavedImage(options);

            if (!response) return;

            if (response.length > 0) {
              const newImages = response.map((img) => ({
                id: img.id,
                url: img.signedUrl,
                type: 'link-portal',
              }));

              setImages(newImages);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    loadPortalImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPortalSavedImage, user.companyDocument, open]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      style={{ width: '100%' }}
    >
      <DialogTitle id="form-dialog-title">Informações </DialogTitle>
      <DialogContent style={{ minHeight: '509.1px', width: '100%' }}>
        <AppBar
          position="static"
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="tabs">
            <Tab label="Assinatura" />
            <Tab label="Imagens" />
          </Tabs>
        </AppBar>

        {activity && (
          <>
            <TabPanel value={value} index={0}>
              <DialogContentText style={{ marginBottom: 32 }}>
                Recebido por:
              </DialogContentText>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Responsável"
                    value={activity.responsibleName ?? ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Nº Documento"
                    value={activity.responseDocument}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    padding={1}
                    style={{ border: '1px solid #aaaaaa', borderRadius: '5px' }}
                  >
                    <Typography variant="body2" style={{ color: '#808080' }}>
                      Assinatura do responsável:
                    </Typography>
                    <CardMedia
                      component="img"
                      src={activity.responseSignature ?? ''}
                      alt="assinatura"
                    />
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {images.length > 0 ? (
                <>
                  {images[index].type === 'base64' ? (
                    <Link
                      color="inherit"
                      underline="none"
                      download={`Imagem ${index + 1}.jpg`}
                      href={images[index].url}
                      title={`Imagem ${index + 1}`}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: 10 }}
                      >
                        Download
                      </Button>
                    </Link>
                  ) : images[index].type === 'link-app' && routeId ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginBottom: 10 }}
                      title={`Imagem ${index + 1}`}
                      onClick={() =>
                        downloadAppSavedImage(
                          routeId,
                          activity.id,
                          images[index].id,
                          `Imagem ${index + 1}.jpeg`
                        )
                      }
                    >
                      Download
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginBottom: 10 }}
                      title={`Imagem ${index + 1}`}
                      onClick={() => {
                        downloadPortalSavedImage(
                          {
                            orderNumber: activity.number,
                            orderCode: activity.orderCode,
                            imageId: images[index].id,
                          },
                          `Imagem ${index + 1}.jpeg`
                        );
                      }}
                    >
                      Download
                    </Button>
                  )}

                  <Slider
                    {...settings}
                    beforeChange={(_oldIndex, newIndex) => {
                      setIndex(newIndex);
                    }}
                  >
                    {images.map((value, index) => {
                      const img = new Image();
                      img.src = value.url;
                      const imgIsVertical = img.height > img.width;
                      return (
                        <div key={value.id}>
                          <img
                            src={value.url}
                            alt={`imagem-${index}`}
                            className={
                              imgIsVertical
                                ? classes.imgVertical
                                : classes.imgHorizontal
                            }
                          />
                        </div>
                      );
                    })}
                  </Slider>

                  {images[index].type === 'base64' ? (
                    <Link
                      color="inherit"
                      underline="none"
                      download={`Imagem ${index + 1}.jpg`}
                      href={images[index].url}
                      title={`Imagem ${index + 1}`}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: 10 }}
                      >
                        Download
                      </Button>
                    </Link>
                  ) : images[index].type === 'link-app' && routeId ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginBottom: 10 }}
                      title={`Imagem ${index + 1}`}
                      onClick={() =>
                        downloadAppSavedImage(
                          routeId,
                          activity.id,
                          images[index].id,
                          `Imagem ${index + 1}.jpeg`
                        )
                      }
                    >
                      Download
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginBottom: 10 }}
                      title={`Imagem ${index + 1}`}
                      onClick={() => {
                        downloadPortalSavedImage(
                          {
                            orderNumber: activity.number,
                            orderCode: activity.orderCode,
                            imageId: images[index].id,
                          },
                          `Imagem ${index + 1}.jpeg`
                        );
                      }}
                    >
                      Download
                    </Button>
                  )}
                </>
              ) : (
                'Nenhuma imagem para visualização'
              )}
            </TabPanel>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIndex(0);
            onClose();
          }}
          color="inherit"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
