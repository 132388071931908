import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';

import { format, addMinutes } from 'date-fns';

import { Button } from '../../../../components/Button';
import { ROUTES } from '../../../../constants';
import { formatCurrency, maskPostalcode } from '../../../../utils';
import { useAuth } from '../../../../hooks/auth';
import {
  ICompanyParams,
  useCompanyParams,
} from '../../../company-params/hooks/company-params';
import {
  ScheduleComponentData,
  SCHEDULE_STATUS,
  useSchedule,
} from '../../../receipt/hooks/schedule';
import { useManualOrder } from '../../hooks/manual-order';
import { IFullOrder, useOrder } from '../../hooks/order';
import { AddressForm } from './AddressForm';
import { DownloadButton } from './DownloadButton';
import { OrderQuantityInput } from './OrderQuantityInput';
import { TagCodeInput } from './TagCodeInput';
import { UploadButton } from './UploadButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Check, Warning } from '@material-ui/icons';

interface IOrderProps {
  open: boolean;
  id?: number | null;
  orderCode?: string | null;
  number?: string | null;
  onClose: () => void;
}

export const OrderInfo = (props: IOrderProps) => {
  const {
    data: { user },
  } = useAuth();
  const { getScheduleEvents } = useSchedule();
  const { getCompanyParams } = useCompanyParams();
  const { operationType, paymentStatus } = useManualOrder();
  const { loadOrderById, loadOrderByOrderCodeAndNumber, reloadOrderInfo } =
    useOrder();

  const nfeCharacterLength = 44;
  const [companyParamsData, setCompanyParamsData] = useState<ICompanyParams>(
    {} as ICompanyParams
  );
  const [order, setOrder] = useState<IFullOrder>({} as IFullOrder);
  const [loading, setLoading] = useState(true);

  const [scheduleEvent, setScheduleEvent] =
    useState<ScheduleComponentData | null>(null);
  const [loadingScheduleEvent, setLoadingScheduleEvent] = useState(true);

  useEffect(() => {
    async function loadCompanyParams(): Promise<void> {
      try {
        const response = await getCompanyParams();

        if (!response) return;

        setCompanyParamsData(response);
      } catch (error) {
        console.log(error);
      }
    }
    loadCompanyParams();
  }, [getCompanyParams]);

  useEffect(() => {
    if (props.open) {
      setOrder({} as IFullOrder);
      setLoading(true);
      setScheduleEvent(null);
      setLoadingScheduleEvent(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadOrder(): Promise<void> {
      if (props.id || (props.orderCode && props.number)) {
        try {
          let response;

          if (props.id) {
            response = await loadOrderById(props.id);
          } else if (props.orderCode && props.number) {
            response = await loadOrderByOrderCodeAndNumber(
              props.orderCode,
              props.number
            );
          }

          if (!response) return;

          setOrder(response);

          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    }

    loadOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reloadOrderInfo,
    props.id,
    loadOrderById,
    props.orderCode,
    props.number,
    loadOrderByOrderCodeAndNumber,
  ]);

  useEffect(() => {
    async function loadScheduleEvent(): Promise<void> {
      try {
        const scheduleEventResponse = await getScheduleEvents({
          orderId: props.id ?? order.id,
        });

        if (!scheduleEventResponse) return;

        setScheduleEvent(scheduleEventResponse[0]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingScheduleEvent(false);
      }
    }

    if ((props.id || order.id) && props.open) loadScheduleEvent();
  }, [getScheduleEvents, order.id, props.id, props.open]);

  const returnColor = useCallback((operationType: string) => {
    switch (operationType) {
      case 'delivery':
        return 'rgb(2,189,2)';
      case 'collect':
        return '#ff6961';
      case 'devolution':
        return '#0099ad';
      case 'cleaning':
        return '#6e6e6e';
      case 'maintenance':
        return '#a38c31';
      default:
        break;
    }
  }, []);

  const returnOperation = useCallback((operationType: string) => {
    switch (operationType) {
      case 'delivery':
        return 'Entrega';
      case 'collect':
        return 'Coleta';
      case 'devolution':
        return 'Devolução';
      case 'cleaning':
        return 'Limpeza';
      default:
        break;
    }
  }, []);

  function hexToAscii(code: string) {
    try {
      let hex = code.toString();
      let str = '';
      for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
      }
      return str;
    } catch (error) {
      return code;
    }
  }

  function convertHexToText(code: string) {
    if (
      companyParamsData.convertRfidCodeHexToText &&
      code.split('2E').length === 3
    ) {
      return hexToAscii(code);
    }
    return code;
  }

  function minutesToTime(allMinutes: number) {
    const hours = Math.floor(allMinutes / 60);
    const minutes = allMinutes % 60;

    return `${hours ? hours + 'h ' : ''}${minutes + 'm'}`;
  }

  return (
    <Dialog
      maxWidth="md"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        style={{
          color: '#FFFFFF',
          backgroundColor: returnColor(order.operationType),
        }}
      >
        Resumo do Pedido
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>
                <strong>Número:</strong> {order.number}
              </Typography>
              <Typography
                component="div"
                gutterBottom
                style={{ overflowWrap: 'break-word' }}
              >
                <b>NF-e: </b>
                {order.hasDanfeNFe && (
                  <DownloadButton order={order} type="nfe" />
                )}

                {order.orderCode.length === nfeCharacterLength ? (
                  order.orderCode
                ) : (
                  <>
                    chave indisponível
                    <br />
                  </>
                )}

                {!order.hasDanfeNFe && (
                  <UploadButton order={order} type="nfe" />
                )}
              </Typography>
              <Typography
                component="div"
                gutterBottom
                style={{ overflowWrap: 'break-word' }}
              >
                <b>CT-e: </b>
                {order.hasDanfeCTe && (
                  <DownloadButton order={order} type="cte" />
                )}

                {order.chaveCTe ? (
                  order.chaveCTe
                ) : (
                  <>
                    chave indisponível
                    <br />
                  </>
                )}

                {!order.hasDanfeCTe && (
                  <UploadButton order={order} type="cte" />
                )}
              </Typography>
              <Typography gutterBottom>
                <b>Data de importação:</b>{' '}
                {format(new Date(order.createdAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
              <Typography gutterBottom>
                <b>Data do pedido:</b>{' '}
                {format(new Date(order.orderDate), 'dd/MM/yyyy')}
              </Typography>
              <Typography gutterBottom>
                <b>Previsão de entrega:</b>{' '}
                {format(
                  (() => {
                    const date = new Date(order.deliveryPrevisionDate);
                    return addMinutes(date, date.getTimezoneOffset());
                  })(),
                  'dd/MM/yyyy'
                )}
              </Typography>

              <Grid item xs={12} sm={8} style={{ margin: '8px 0' }}>
                <TagCodeInput order={order} />
              </Grid>

              <Typography gutterBottom>
                <Link
                  href={`/tracking/${order.tracking}`}
                  target="_blank"
                  color="secondary"
                >
                  <b>Rastrear Pedido</b>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>
                <b>Origem:</b> {order.marketplace}
              </Typography>
              <Typography gutterBottom>
                <b>Operação:</b>{' '}
                {
                  operationType.find(
                    (type) => type.value === order.operationType
                  )?.label
                }
              </Typography>
              <Typography gutterBottom>
                <b>Status do pagamento:</b>{' '}
                {paymentStatus.find(
                  (status) => status.value === order.paymentStatus
                )?.label ?? 'Nenhum status informado ainda'}
              </Typography>
              <Typography gutterBottom>
                <b>Opção de envio:</b> {order.shippingOption}
              </Typography>
              {order.riskArea && (
                <Typography gutterBottom>
                  <b>Está em Área de Risco</b>
                </Typography>
              )}
              <br />
              <Typography component="div" gutterBottom>
                <b>Agendamento:</b>{' '}
                {loadingScheduleEvent ? (
                  <CircularProgress size={15} style={{ color: 'inherit' }} />
                ) : scheduleEvent ? (
                  <>
                    <Link
                      component={RouterLink}
                      color="secondary"
                      to={`${ROUTES.receivement}?scheduleEventId=${scheduleEvent.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      Visualizar
                      <OpenInNewIcon fontSize="inherit" />
                    </Link>

                    <Typography gutterBottom style={{ paddingLeft: 16 }}>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <b>Status:</b>{' '}
                          {SCHEDULE_STATUS[scheduleEvent.status].label}
                        </Grid>
                        <Grid item xs={6}>
                          <b>Motorista:</b> {scheduleEvent.data?.userName}
                        </Grid>
                        <Grid item xs={6}>
                          <b>Doca:</b> {scheduleEvent.dock?.name}
                        </Grid>
                        <Grid item xs={6}>
                          <b>Data:</b>{' '}
                          {format(
                            new Date(`${scheduleEvent.dateEvent} 00:00:00`),
                            'dd/MM/yyyy'
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <b>Das:</b>{' '}
                          {format(new Date(scheduleEvent.startTime!), 'HH:mm')}
                        </Grid>
                        <Grid item xs={6}>
                          <b>Às:</b>{' '}
                          {format(new Date(scheduleEvent.endTime!), 'HH:mm')}
                        </Grid>
                      </Grid>
                    </Typography>
                  </>
                ) : (
                  <Link
                    component={RouterLink}
                    color="secondary"
                    to={`${ROUTES.receivement}?order=${order.id}|${order.number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    Novo
                    <OpenInNewIcon fontSize="inherit" />
                  </Link>
                )}
              </Typography>

              <Grid item xs={12} sm={8}>
                <OrderQuantityInput order={order} />
              </Grid>
            </Grid>

            {order.observation && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Observação
                </Typography>
                <Typography gutterBottom>{order.observation}</Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Remetente
              </Typography>
              <Typography gutterBottom>{order.sender.name}</Typography>
              {order.sender.addresses && order.sender.addresses.length > 0 ? (
                <Typography gutterBottom>
                  {order.sender.addresses[0].street}
                  {', '}
                  {order.sender.addresses[0].number}
                  {' - '}
                  {order.sender.addresses[0].neighborhood}
                  <br />
                  {maskPostalcode(order.sender.addresses[0].postalcode)}
                  {' - '}
                  {order.sender.addresses[0].city}
                  {' - '}
                  {order.sender.addresses[0].state}
                </Typography>
              ) : (
                <>Não informado</>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Cliente
              </Typography>
              <Typography gutterBottom>{order.customer.name}</Typography>
              <Typography>
                {order.customer.contact && order.customer.phone ? (
                  `Contato: ${order.customer.contact} - Tel.: ${order.customer.phone}`
                ) : order.customer.contact && !order.customer.phone ? (
                  `Contato: ${order.customer.contact}`
                ) : !order.customer.contact && order.customer.phone ? (
                  `Telefone: ${order.customer.phone}`
                ) : (
                  <></>
                )}
              </Typography>
              {order.customer.addresses &&
              order.customer.addresses.length > 0 ? (
                <Typography gutterBottom>
                  {order.customer.addresses[0].street}
                  {', '}
                  {order.customer.addresses[0].number}
                  {' - '}
                  {order.customer.addresses[0].neighborhood}
                  <br />
                  {maskPostalcode(order.customer.addresses[0].postalcode)}
                  {' - '}
                  {order.customer.addresses[0].city}
                  {' - '}
                  {order.customer.addresses[0].state}
                  <br />
                  {`REFERÊNCIA: ${
                    order.shippingAddress.landmark || 'SEM REFERÊNCIA'
                  }`}
                </Typography>
              ) : (
                <>Não informado</>
              )}
            </Grid>

            <Typography variant="h6" gutterBottom>
              Endereço de {returnOperation(order.operationType)}
            </Typography>
            <AddressForm order={order} />

            {order.changedToLastExecution && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Geolocalização Alterada
                </Typography>
                <Grid item style={{ display: 'flex' }} xs={12}>
                  <Grid item xs={10} sm={12}>
                    <Typography gutterBottom>
                      {order.changedToLastExecutionDescription}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {order.deliveredAddress && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Geolocalização da Operação
                </Typography>
                <Grid item style={{ display: 'flex' }} xs={12}>
                  <Grid item xs={10} sm={12}>
                    <Typography gutterBottom>
                      <a
                        href={order.deliveredMap || undefined}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LocationOnIcon />
                      </a>
                      {order.deliveredAddress.includes('undefined')
                        ? order.deliveredAddress.slice(-32)
                        : order.deliveredAddress}
                    </Typography>
                    {companyParamsData.geoFenceEnable && (
                      <Typography
                        component="span"
                        style={{ display: 'flex', alignItems: 'end' }}
                      >
                        {`Distância da cerca eletrônica (max ${
                          companyParamsData.geoFenceDistance >= 1000
                            ? `${companyParamsData.geoFenceDistance / 1000}km`
                            : `${companyParamsData.geoFenceDistance}m`
                        }):`}
                        <Typography
                          noWrap
                          style={{
                            color: order.deliveredDistance
                              ? order.deliveredDistance <
                                companyParamsData.geoFenceDistance
                                ? 'blue'
                                : 'red'
                              : '',
                            marginLeft: 5,
                          }}
                        >
                          {order.deliveredDistance
                            ? order.deliveredDistance > 1000
                              ? `${(order.deliveredDistance / 1000).toFixed(
                                  2
                                )} quilômetros`
                              : `${order.deliveredDistance} metros`
                            : 'Não informado'}
                        </Typography>
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    style={{ alignSelf: 'end', textAlign: 'center' }}
                    item
                    xs={2}
                  >
                    {order.deliveredDistance &&
                    companyParamsData.geoFenceEnable ? (
                      order.deliveredDistance <
                      companyParamsData.geoFenceDistance ? (
                        <Check fontSize="large" color="secondary" />
                      ) : (
                        <Warning fontSize="large" color="error" />
                      )
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {!companyParamsData.orderOperationTime ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Tempo de Operação
                </Typography>
                <Grid spacing={1} container>
                  <Grid
                    style={{ textAlign: 'start', fontWeight: 'bold' }}
                    item
                    xs={1}
                  >
                    Operação:
                  </Grid>
                  <Grid item xs={11}>
                    {minutesToTime(order.operationTime)}
                  </Grid>

                  <Grid
                    style={{ textAlign: 'start', fontWeight: 'bold' }}
                    item
                    xs={1}
                  >
                    Entrega:
                  </Grid>
                  <Grid item xs={11}>
                    {minutesToTime(order.deliveryTime)}
                  </Grid>

                  <Grid
                    style={{ textAlign: 'start', fontWeight: 'bold' }}
                    item
                    xs={1}
                  >
                    Total:
                  </Grid>
                  <Grid item xs={11}>
                    {minutesToTime(order.totalOperatingTime)}
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Itens
              </Typography>
              <List disablePadding>
                {order.items.map((item, index) => (
                  <ListItem key={`${index}-${item.sku}`}>
                    <ListItemText
                      primary={
                        <>
                          {item.isConfirmed ? (
                            <TapAndPlayIcon color="secondary" />
                          ) : (
                            ''
                          )}
                          {item.description}{' '}
                          <b style={{ fontWeight: 500 }}>x{item.quantity}</b>{' '}
                        </>
                      }
                      secondary={
                        item.code
                          ? item.sku +
                            ' - ' +
                            convertHexToText(item.barcode) +
                            ' - ' +
                            convertHexToText(item.code)
                          : item.sku + ' - ' + convertHexToText(item.barcode)
                      }
                    />
                    {user.type === 'ADMIN' && (
                      <Typography variant="body2" style={{ marginLeft: 16 }}>
                        {formatCurrency(parseFloat(item.price) * item.quantity)}
                      </Typography>
                    )}
                  </ListItem>
                ))}

                {user.type === 'ADMIN' && (
                  <>
                    <ListItem>
                      <ListItemText primary="Frete" />
                      <Typography variant="subtitle1">
                        {formatCurrency(order.shippingCost)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Desconto" />
                      <Typography variant="subtitle1">
                        - {formatCurrency(order.discount)}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Total" />
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 700 }}
                      >
                        {formatCurrency(order.value)}
                      </Typography>
                    </ListItem>
                  </>
                )}
              </List>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
