import React, { useState, useContext, createContext, useCallback } from 'react';
import api from '../services/api';

interface IUser {
  id: number;
  email: string;
  name: string;
  type: string;
  street: string;
  number: number;
  complement: string;
  neighborhood: string;
  postalcode: number;
  city: string;
  state: string;
  country: string;
  cpf: number;
  companyDocument: number;
}

interface IAuthState {
  access_token: string;
  user: IUser;
  permissions: string[];
}

interface ISignData {
  username: string;
  password: string;
}

export interface ICompanyDefault {
  hasDistributionCenter: boolean;
  hasVehicle: boolean;
  hasVehicleType: boolean;
  hasPlanningConfig: boolean;
}

interface IAuthContext {
  signIn(signInData: ISignData): Promise<{error: boolean, urlPlatform: string | null}>;
  signOut(): void;
  data: IAuthState;
  setCompanyDefault: React.Dispatch<React.SetStateAction<ICompanyDefault>>;
  companyDefault: ICompanyDefault;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const storedAccessToken = localStorage.getItem('@4Log:access_token');
    const storedUser = localStorage.getItem('@4Log:user');
    const storedPermissions = localStorage.getItem('@4Log:permissions');

    if (storedAccessToken && storedUser && storedPermissions) {
      api.defaults.headers.common = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedAccessToken}`,
      };

      return {
        access_token: storedAccessToken,
        user: JSON.parse(storedUser),
        permissions: [...JSON.parse(storedPermissions)],
      };
    }

    return {} as IAuthState;
  });

  const [companyDefault, setCompanyDefault] = useState<ICompanyDefault>(() => {
    const storedCompanyDefault = localStorage.getItem('@4log:companyDefault');

    if (storedCompanyDefault) {
      return {
        ...JSON.parse(storedCompanyDefault),
      };
    }

    return {} as ICompanyDefault;
  });

  const signIn = useCallback(async (signInData: ISignData): Promise<{error: boolean, urlPlatform: string | null}> => {
    const response = await api.post('/user/login', signInData);

    const { access_token, driver, permissions } = response.data;

    const user: IUser = driver;

    api.defaults.headers.Authorization = `Bearer ${access_token}`;

    const {data: {urlPlatform}} = await api.get('/company-params/by/document');

    if(urlPlatform && !window.location.href.includes(urlPlatform)){
      return {error: false, urlPlatform}
    }

    localStorage.setItem('@4Log:access_token', access_token);
    localStorage.setItem('@4Log:user', JSON.stringify(user));
    localStorage.setItem('@4Log:permissions', JSON.stringify(permissions));

    const userUnauthorized = 'DRIVER';
    if (user.type === userUnauthorized) {
      return {error: true, urlPlatform: null};
    }

    const responseCompany = await api.get('company/default');

    localStorage.setItem(
      '@4log:companyDefault',
      JSON.stringify(responseCompany.data)
    );

    setCompanyDefault(responseCompany.data);

    setData({ access_token, user, permissions });

    return {error: false, urlPlatform: null};
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@4Log:access_token');
    localStorage.removeItem('@4Log:user');
    localStorage.removeItem('@4Log:permissions');
    localStorage.removeItem('@4log:companyDefault');

    setCompanyDefault({} as ICompanyDefault);
    setData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, data, setCompanyDefault, companyDefault }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
