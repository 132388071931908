import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { DistributionCenterList } from '../pages/DistributionCenterList';
import { DistributionCenterForm } from '../pages/DistributionCenterForm';

import { DistributionCenterProvider } from '../hooks/distribution-center';

export const DistributionCenterRoute = () => (
  <DistributionCenterProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.distributionCenterList}
        exact
        permission={PERMISSIONS.DISTRIBUTION_CENTER_VIEW}
        component={DistributionCenterList}
      />
      <PrivateRoute
        path={ROUTES.createDistributionCenter}
        component={DistributionCenterForm}
      />
      <PrivateRoute
        path={ROUTES.updateDistributionCenter}
        component={DistributionCenterForm}
      />
    </Switch>
  </DistributionCenterProvider>
);
