import { endOfMonth, format, startOfMonth, subDays, subMonths } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '../services/api';
import { useAuth } from './auth';
import { useLocalStorage } from './localStorage';

export enum PeriodEnum {
  SEVEN = 'seven',
  THIRTY = 'thirty',
  CURRENT_MONTH = 'currentMonth',
  LAST_MONTH = 'lastMonth',
  CUSTOM = 'custom',
}

type DateType = {
  from: string;
  to: string;
};

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

interface ISettingsContext {
  selectedPeriod: PeriodEnum;
  cardsLoading: boolean;
  setCardsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  cardsInfo: ICardsInfo;
  setCardsInfo(value: ICardsInfo): void;
  getDashboardCardsInfo(query: {
    initialDate: string;
    finalDate: string;
  }): Promise<ICardsInfo>;
  changePeriod(period: PeriodEnum, customPeriod?: DateType): void;
  getDatePeriod(period: PeriodEnum): DateType;
}

const SettingsContext = createContext<ISettingsContext>({} as ISettingsContext);

const SettingsProvider: React.FC = ({ children }) => {
  const {
    data: { user },
  } = useAuth();
  const [cardsLoading, setCardsLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodEnum>(() => {
    window.localStorage.removeItem('dateParam'); // not used anymore since 09/11/2022
    const storedSelectedPeriod = window.localStorage.getItem(
      'selectedPeriod'
    ) as PeriodEnum | null;

    if (storedSelectedPeriod) {
      return storedSelectedPeriod;
    }

    return PeriodEnum.SEVEN;
  });
  const [cardsInfo, setCardsInfo] = useLocalStorage<ICardsInfo>('cardsInfo', {
    totalOrders: 0,
    totalOrdersPrevious: 0,
    totalPendingOrders: 0,
    totalPendingOrdersPrevious: 0,
    totalBackorders: 0,
    totalBackordersPrevious: 0,
    totalOrdersDelivered: 0,
    totalOrdersDeliveredPrevious: 0,
    totalOrdersWithOccurrence: 0,
    totalOrdersWithOccurrencePrevious: 0,
    totalRoutes: 0,
    totalRoutesPrevious: 0,
    percentageOrdersVariation: '',
    percentagePendingOrdersVariation: '',
    percentageBackordersVariation: '',
    percentageOrdersDeliveredVariation: '',
    percentageOrdersWithOccurrenceVariation: '',
    percentageRoutesVariation: '',
  });

  const getDashboardCardsInfo = useCallback(
    async (query: { initialDate: string; finalDate: string }) => {
      const { initialDate, finalDate } = query;
      const response = await api.get(
        `/summarizers/dashboard-counter?initialDate=${initialDate}&finalDate=${finalDate}`
      );

      return response.data;
    },
    []
  );

  const changePeriod = useCallback(
    (period: PeriodEnum, customPeriod?: DateType) => {
      window.localStorage.setItem('selectedPeriod', period);
      setSelectedPeriod(period);

      if (customPeriod && period === PeriodEnum.CUSTOM) {
        window.localStorage.setItem(
          'customSelectedPeriod',
          JSON.stringify(customPeriod)
        );
      }
    },
    []
  );

  const getDatePeriod = useCallback((period: PeriodEnum) => {
    switch (period) {
      case PeriodEnum.SEVEN: {
        return {
          from: formatDate(subDays(new Date(), 6)),
          to: formatDate(new Date()),
        };
      }
      case PeriodEnum.THIRTY: {
        return {
          from: formatDate(subDays(new Date(), 29)),
          to: formatDate(new Date()),
        };
      }
      case PeriodEnum.CURRENT_MONTH: {
        return {
          from: formatDate(startOfMonth(new Date())),
          to: formatDate(endOfMonth(new Date())),
        };
      }
      case PeriodEnum.LAST_MONTH: {
        return {
          from: formatDate(startOfMonth(subMonths(new Date(), 1))),
          to: formatDate(endOfMonth(subMonths(new Date(), 1))),
        };
      }
      case PeriodEnum.CUSTOM: {
        const storageCustomSelectedPeriod = window.localStorage.getItem(
          'customSelectedPeriod'
        );

        if (storageCustomSelectedPeriod) {
          return JSON.parse(storageCustomSelectedPeriod) as DateType;
        }

        return {
          from: formatDate(new Date()),
          to: formatDate(new Date()),
        };
      }
      default:
        throw new Error('Invalid period: ' + period);
    }
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          setCardsLoading(true);
          const { from, to } = getDatePeriod(selectedPeriod);
          const response = await getDashboardCardsInfo({
            initialDate: from,
            finalDate: to,
          });

          if (!response) return;

          setCardsInfo(response);
        } catch (error) {
          console.error(error);
        } finally {
          setCardsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getDashboardCardsInfo, getDatePeriod, selectedPeriod]);

  return (
    <SettingsContext.Provider
      value={{
        selectedPeriod,
        cardsLoading,
        setCardsLoading,
        cardsInfo,
        setCardsInfo,
        getDashboardCardsInfo,
        changePeriod,
        getDatePeriod,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

function useSettings(): ISettingsContext {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return context;
}

export { SettingsProvider, useSettings };

interface ICardsInfo {
  totalOrders: number;
  totalOrdersPrevious: number;
  totalPendingOrders: number;
  totalPendingOrdersPrevious: number;
  totalBackorders: number;
  totalBackordersPrevious: number;
  totalOrdersDelivered: number;
  totalOrdersDeliveredPrevious: number;
  totalOrdersWithOccurrence: number;
  totalOrdersWithOccurrencePrevious: number;
  totalRoutes: number;
  totalRoutesPrevious: number;
  percentageOrdersVariation: string;
  percentagePendingOrdersVariation: string;
  percentageBackordersVariation: string;
  percentageOrdersDeliveredVariation: string;
  percentageOrdersWithOccurrenceVariation: string;
  percentageRoutesVariation: string;
}
