import React from 'react';
import { Collapse, TableRow } from '@material-ui/core';

import { TableCell } from './TableCell';

interface IProps {
  open: boolean;
  colSpan: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
  noPadding?: boolean;
}

export const CollapseRow = ({
  open,
  colSpan,
  style,
  noPadding,
  children,
}: IProps) => {
  return (
    <TableRow style={style}>
      <TableCell
        style={{
          padding: noPadding ? 0 : 16,
          paddingBottom: noPadding ? 0 : 10,
          paddingTop: 0,
        }}
        colSpan={colSpan}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
