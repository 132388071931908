import { useCallback } from 'react';

import { useForgotPassword } from '../../hooks/forgotPassword';

import { CodeRequestForm } from '../CodeRequestForm';
import { CodeValidationForm } from '../CodeValidationForm';
import { ChangePasswordForm } from '../ChangePasswordForm';

import { Container } from './styles';

export const ForgotPassword = () => {
  const { activeStepForgotPassword } = useForgotPassword();

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return <CodeRequestForm />;
      case 1:
        return <CodeValidationForm />;
      case 2:
        return <ChangePasswordForm />;
      default:
        throw new Error('Desconhecido');
    }
  }, []);

  return <Container>{getStepContent(activeStepForgotPassword)}</Container>;
};
