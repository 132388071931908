import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { divIcon, LatLngExpression } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  Tooltip,
} from 'react-leaflet';

import { ReactComponent as Car } from '../../../../../assets/icons/car.svg';
import { ActivityStatus } from '../../../../../constants';
import { useAuth } from '../../../../../hooks/auth';
import { returnColor } from '../../../../../utils/statusColor';
import { IActivity } from '../../../hooks/activity';
import { IRoute, useRoute } from '../../../hooks/route';
import { MapPopupButtons } from '../MapPopupButtons';
import { CollapsibleOverview } from './CollapsibleOverview';

import { useStyles } from './styles';
import { OrderTip } from './OrderTip';

interface ILeg {
  averageSpeed: number;
  distance: number;
  nominalDuration: number;
  points: {
    latitude: number;
    longitude: number;
  }[];
  speedProfilePenalty: number;
}

interface IMapLink {
  id: string;
  clientId: string;
  legs: ILeg[];
  source: string;
  averageSpeed: number;
  totalDistance: number;
  totalNominalDuration: number;
  totalSpeedProfilePenalty: number;
}

export interface ITrip {
  visible: boolean;
  route: IRoute;
  map: Pick<IMapLink, 'totalDistance' | 'totalNominalDuration' | 'legs'>;
  mapLineColor: string;
  activities: IActivity[];
}

export const MainMap = ({ todaysDate }: { todaysDate: string }) => {
  const {
    data: { user },
  } = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const { todayRoutes, setTodayRoutes, loadRouteList } = useRoute();
  const [loading, setLoading] = useState(true);
  const [trips, setTrips] = useState<ITrip[]>([]);

  const markerIcon = (status: ActivityStatus, index: number) =>
    divIcon({
      className: classes.customMarker,
      html: `
        <div
          style="background-color:${returnColor(status)};"
          class="marker-pin"
        ></div>
        <b>${index}</b>
      `,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });

  const carIcon = () =>
    divIcon({
      className: classes.customMarker,
      html: `
        <div class="marker-pin"></div>
        <i>${renderToStaticMarkup(<Car />)}</i>
      `,
      iconSize: [30, 30],
      iconAnchor: [15, 38],
      tooltipAnchor: [1, 0],
    });

  const loadTodayRoutes = useCallback(async (): Promise<void> => {
    try {
      const query =
        `?companyDocument=${user.companyDocument}` +
        `&initialDate=${todaysDate}` +
        `&finalDate=${todaysDate}` +
        `&page=1`;

      const response = await loadRouteList(query);

      if (!response) return;

      const newRoutes: IRoute[] = [];

      response.routes.forEach((route) => {
        if (route.status === 'pending' || route.status === 'on-route') {
          newRoutes.push(route);
        }
      });

      setTodayRoutes(newRoutes);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [loadRouteList, setTodayRoutes, todaysDate, user.companyDocument]);

  useEffect(() => {
    if (todaysDate) {
      if (loading) loadTodayRoutes();

      // const thirtySeconds = 30000;
      // const interval = setInterval(() => {
      //   loadTodayRoutes();
      // }, thirtySeconds);

      // return () => clearInterval(interval);
    }
  }, [loadTodayRoutes, todaysDate, loading]);

  useEffect(() => {
    let newTrips: ITrip[] = [];

    todayRoutes.forEach((route) => {
      if (route.map && route.map.mapRoute) {
        const tripSolution: IMapLink = JSON.parse(route.map.mapRoute);

        const visible =
          trips.find((trip) => trip.route.id === route.id)?.visible ?? true;

        newTrips.push({
          visible,
          route,
          map: tripSolution,
          mapLineColor: route.mapLineColor,
          activities: route.activities
            ?.sort((a, b) => a.ordem - b.ordem)
            .map((activity) => activity),
        });
      }
    });

    setTrips(newTrips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayRoutes]);

  return (
    <>
      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress
            size={20}
            style={{ color: theme.palette.warning.main }}
          />
        </Typography>
      ) : (
        <>
          {trips.length > 0 && (
            <>
              <MapContainer
                center={[
                  trips[0].map.legs[0].points[0].latitude,
                  trips[0].map.legs[0].points[0].longitude,
                ]}
                zoom={13}
                scrollWheelZoom
                style={{ width: '100%', height: '500px' }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://4innovation.co/">4INNOVATION TECNOLOGIA</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {trips.map((trip) => {
                  const {
                    map: { legs },
                  } = trip;
                  const legsLastPosition = legs.length - 1;
                  const pointsLastPosition =
                    legs[legsLastPosition].points.length;

                  const lastPosition: LatLngExpression = [
                    legs[legsLastPosition].points[pointsLastPosition - 1]
                      .latitude,
                    legs[legsLastPosition].points[pointsLastPosition - 1]
                      .longitude,
                  ];

                  if (!trip.visible) return null;

                  return (
                    <React.Fragment key={trip.route.id}>
                      {trip.map.legs.map((leg, indexP) => {
                        const positions: LatLngExpression[] = leg.points.map(
                          (point) => [point.latitude, point.longitude]
                        );

                        return (
                          <React.Fragment key={`${trip.route.id}-${indexP}`}>
                            <Polyline
                              pathOptions={{ color: trip.mapLineColor }}
                              positions={positions}
                            />

                            {indexP === 0 ? (
                              <Marker position={positions[0]}>
                                <Tooltip
                                  direction="top"
                                  offset={[-15, -15]}
                                  opacity={1}
                                >
                                  <strong>Partida</strong>
                                </Tooltip>
                              </Marker>
                            ) : (
                              <>
                                {trip.activities &&
                                trip.activities[indexP - 1] ? (
                                  <Marker
                                    position={positions[0]}
                                    icon={markerIcon(
                                      trip.activities[indexP - 1].status,
                                      indexP
                                    )}
                                  >
                                    <Popup offset={[1, -27]}>
                                      <MapPopupButtons
                                        route={trip.route}
                                        activity={trip.activities[indexP - 1]}
                                      />
                                    </Popup>

                                    <Tooltip
                                      direction="top"
                                      offset={[1, -35]}
                                      opacity={1}
                                    >
                                      <OrderTip
                                        trip={trip}
                                        activityIndex={indexP - 1}
                                      />
                                    </Tooltip>
                                  </Marker>
                                ) : (
                                  <Marker position={positions[0]}>
                                    <Tooltip
                                      direction="bottom"
                                      offset={[-15, 23]}
                                      opacity={1}
                                    >
                                      <strong>Entrega: {indexP}</strong>
                                    </Tooltip>
                                  </Marker>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}

                      {trip.route.vehicle &&
                        trip.route.vehicle.lat &&
                        trip.route.vehicle.long && (
                          <Marker
                            icon={carIcon()}
                            position={[
                              parseFloat(trip.route.vehicle.lat),
                              parseFloat(trip.route.vehicle.long),
                            ]}
                          >
                            <Tooltip direction="bottom" opacity={1}>
                              <strong>{trip.route.vehicle.vehicleName}</strong>
                            </Tooltip>
                          </Marker>
                        )}

                      <Marker position={lastPosition}>
                        <Tooltip
                          direction="bottom"
                          offset={[-15, 23]}
                          opacity={1}
                        >
                          <strong>Chegada</strong>
                        </Tooltip>
                      </Marker>
                    </React.Fragment>
                  );
                })}
              </MapContainer>

              <CollapsibleOverview
                trips={trips}
                onVisibilityChanged={setTrips}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
