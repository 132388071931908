import { useSnackbar } from 'notistack';
import { Tooltip, Button as MUIButton, Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import { IFullOrder, useOrder } from '../../hooks/order';
import { useCallback, useState } from 'react';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import { PERMISSIONS } from '../../../../constants';

interface IProps {
  order: IFullOrder;
  type: 'nfe' | 'cte';
}

export const UploadButton = ({ order, type }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: {
      user: { companyDocument },
      permissions,
    },
  } = useAuth();
  const { uploadNFeOrCTe, setReloadOrderInfo } = useOrder();
  const [formData, setFormData] = useState<FormData | null>(null);
  const [fileName, setFileName] = useState('Nenhum arquivo selecionado');
  const [isUploading, setIsUploading] = useState(false);
  const labelType = type === 'nfe' ? 'NF-e' : 'CT-e';

  const uploadFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0]
      ) {
        const file = event.target.files[0];
        const newFormData = new FormData();

        newFormData.append('file', file, file.name);

        setFileName(file.name);
        setFormData(newFormData);
      } else {
        setFileName('Nenhum arquivo selecionado');
        setFormData(null);
      }
    },
    []
  );

  const handleUpload = useCallback(async () => {
    if (formData) {
      try {
        setIsUploading(true);

        await uploadNFeOrCTe(type, order.id, companyDocument, formData);

        setReloadOrderInfo((prevState) => !prevState);
      } catch (error) {
        const message = 'Erro ao efetuar upload do anexo ' + labelType;
        enqueueSnackbar(message, {
          variant: 'error',
        });
      } finally {
        setIsUploading(false);
      }
    }
  }, [
    setReloadOrderInfo,
    companyDocument,
    enqueueSnackbar,
    formData,
    labelType,
    order.id,
    type,
    uploadNFeOrCTe,
  ]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={`Upload ${labelType}`} placement="top">
          <label style={{ minWidth: 'fit-content' }}>
            <MUIButton
              component="span"
              color="inherit"
              variant="outlined"
              size="small"
              disabled={
                isUploading || type === 'nfe'
                  ? order.hasDanfeNFe
                  : order.hasDanfeCTe
              }
              startIcon={<PublishIcon fontSize="small" />}
            >
              Selecionar pdf {labelType}
            </MUIButton>
            <input
              disabled={
                isUploading || type === 'nfe'
                  ? order.hasDanfeNFe
                  : order.hasDanfeCTe
              }
              id="file"
              name="file"
              type="file"
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept=".pdf"
            />
          </label>
        </Tooltip>

        <Typography
          component="div"
          variant="subtitle2"
          noWrap
          style={{
            flex: '1 1',
            marginLeft: 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fileName}
        </Typography>

        {formData !== null && (
          <Button
            size="small"
            variant="contained"
            color="secondary"
            type="button"
            loading={isUploading}
            onClick={handleUpload}
            disabled={!permissions.includes(PERMISSIONS.ORDER_EDIT)}
          >
            Enviar
          </Button>
        )}
      </div>
    </>
  );
};
