import { createRef, useCallback } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider } from 'notistack';
// import {
//   L10n,
//   loadCldr,
//   registerLicense,
//   setCulture,
// } from '@syncfusion/ej2-base';
// import * as EJ2_LOCALE from '@syncfusion/ej2-locale/src/pt-BR.json';
// import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
// import gregorian from 'cldr-data/main/pt/ca-gregorian.json';
// import numbers from 'cldr-data/main/pt/numbers.json';
// import timeZoneNames from 'cldr-data/main/pt/timeZoneNames.json';
// import weekData from 'cldr-data/supplemental/weekData.json';
import ModalRoot from './components/Modal/ModalRoot';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { AppProvider } from './hooks';
import { Routes } from './routes';
import { AppThemeProvider } from './hooks/appTheme';
import './styles/styles.css';

// loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
// L10n.load({
//   pt: {
//     ...EJ2_LOCALE['pt-BR'],
//     schedule: {
//       ...EJ2_LOCALE['pt-BR'].schedule,
//       save: 'Salvar',
//       saveButton: 'Salvar',
//     },
//   },
// });
// setCulture('pt');

// registerLicense(
//   'ORg4AjUWIQA/Gnt2UVhiQlJPdUBAXHxLflFyVWRTel16cFBWESFaRnZdRl1hS35SdkVgXH9WdHxd'
// );

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

function App() {
  const notistackRef = createRef<any>();

  const handleCloseSnackbar = useCallback(
    (key: any) => () => {
      notistackRef.current.closeSnackbar(key);
    },
    [notistackRef]
  );

  return (
    <AppProvider>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        action={(key) => (
          <IconButton onClick={handleCloseSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        )}
        autoHideDuration={3000}
      >
        <AppThemeProvider>
          <CssBaseline />
          <ModalRoot />
          <Routes />
        </AppThemeProvider>
      </SnackbarProvider>
    </AppProvider>
  );
}

export default App;
