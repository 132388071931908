import { InputLabel } from '@material-ui/core';

import { IOrder } from '../../../hooks/order';

import { format } from 'date-fns';

interface IProps {
  order: IOrder;
}

export const RowLogInfo = ({ order }: IProps) => {
  return (
    <>
      {order.userMarkAsPackaged ? (
        <InputLabel>
          <strong>Separado por:</strong> {order.userMarkAsPackaged} (
          {format(new Date(order.dateMarkAsPackaged), 'dd/MM/yyyy HH:mm:ss')})
        </InputLabel>
      ) : null}
      <br />
      {order.userMarkAsSent ? (
        <InputLabel>
          <strong>Marcado como enviado:</strong> {order.userMarkAsSent} (
          {format(new Date(order.dateMarkAsSent), 'dd/MM/yyyy HH:mm:ss')})
        </InputLabel>
      ) : null}
      <br />
      {order.userMarkAsDelivered ? (
        <InputLabel>
          <strong>Concluído por:</strong> {order.userMarkAsDelivered} (
          {format(new Date(order.dateMarkAsDelivered), 'dd/MM/yyyy HH:mm:ss')})
        </InputLabel>
      ) : null}
      <br />
      {order.userMarkAsCancelled ? (
        <InputLabel>
          <strong>Cancelado por:</strong> {order.userMarkAsCancelled} (
          {format(new Date(order.dateMarkAsCancelled), 'dd/MM/yyyy HH:mm:ss')})
        </InputLabel>
      ) : null}
      <br />
      {order.observation ? (
        <InputLabel>
          <strong>Observação:</strong> {order.observation}
        </InputLabel>
      ) : null}
    </>
  );
};
