import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { GeneratedMap } from '../pages/GeneratedMap';
import { Route } from '../pages/Route';
import { RouteProvider } from '../hooks/route';
import { PlanningProvider } from '../../order/hooks/planning';
import { OrderProvider } from '../../order/hooks/order';
import { CompanyParamsProvider } from '../../company-params/hooks/company-params';
import { UserManagementProvider } from '../../user-management/hooks/user-management';

export const RouteRoute = () => (
  <UserManagementProvider>
    <CompanyParamsProvider>
      <OrderProvider>
        <RouteProvider>
          <PlanningProvider>
            <Switch>
              <PrivateRoute
                path={ROUTES.route}
                exact
                permission={PERMISSIONS.ROUTE_VIEW}
                component={Route}
              />
              <PrivateRoute
                path={ROUTES.generateMap}
                component={GeneratedMap}
              />
            </Switch>
          </PlanningProvider>
        </RouteProvider>
      </OrderProvider>
    </CompanyParamsProvider>
  </UserManagementProvider>
);
