import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { PERMISSIONS, ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { getAddress } from '../../../../services/viaCep';
import {
  maskCpf,
  maskCnpj,
  maskTelephone,
  maskPostalcode,
  unmask,
} from '../../../../utils';
import { useAuth } from '../../../../hooks/auth';
import { useCompany } from '../../hooks/company';

import { Form } from './styles';

export const CompanyForm = () => {
  const { getCompany, updateCompany } = useCompany();
  const {
    data: { user, permissions },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [companyId, setCompanyId] = useState<number>(0);
  const [data, setData] = useState({
    documentNumber: '',
    companyLegalName: '',
    tradingName: '',
    contactName: '',
    telephone: '',
    email: '',
    postalcode: '',
    street: '',
    neighborhood: '',
    number: '',
    city: '',
    state: '',
    complement: '',
    addressId: 0,
    telephoneId: 0,
    emailId: 0,
  });

  useEffect(() => {
    async function loadCompany(): Promise<void> {
      try {
        const response = await getCompany(user.companyDocument);

        if (!response) return;

        setCompanyId(response.id);

        const telephone = response.contacts.find(
          (contacts) => contacts.type === 'telephone'
        );
        const email = response.contacts.find(
          (contacts) => contacts.type === 'email'
        );

        setData({
          documentNumber: response.documentNumber,
          companyLegalName: response.companyLegalName,
          tradingName: response.tradingName,
          contactName: response.contacts[0].contactName,
          telephone: telephone ? telephone.contact : '',
          email: email ? email.contact : '',
          postalcode: response.addresses[0].postalcode,
          street: response.addresses[0].street,
          neighborhood: response.addresses[0].neighborhood,
          number: response.addresses[0].number,
          city: response.addresses[0].city,
          state: response.addresses[0].state,
          complement: response.addresses[0].complement,
          addressId: response.addresses[0].id,
          telephoneId: telephone ? telephone.id : 0,
          emailId: email ? email.id : 0,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadCompany();
  }, [getCompany, user.companyDocument]);

  const validationSchema = yup.object({
    telephone: yup.string().required('Informe o telefone'),
    email: yup.string().required('Informe o e-mail'),
    postalcode: yup.string().required('Por favor, insira um CEP'),
    street: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    neighborhood: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    number: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string().required('Insira um número'),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    city: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    state: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    complement: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      let data: any = {};

      data = {
        documentNumber: values.documentNumber,
        companyLegalName: values.companyLegalName,
        tradingName: values.tradingName,
        contacts: [
          {
            id: values.telephoneId,
            type: 'telephone',
            contact: values.telephone.split('/')[0].replace(/[^0-9]/g, ''),
            contactName: values.contactName,
          },
          {
            id: values.emailId,
            type: 'email',
            contact: values.email,
            contactName: values.contactName,
          },
        ],
        addresses: [
          {
            id: values.addressId,
            postalcode: values.postalcode,
            street: values.street,
            neighborhood: values.neighborhood,
            number: values.number,
            city: values.city,
            state: values.state,
            complement: values.complement,
          },
        ],
      };

      try {
        await updateCompany(companyId, data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingButton(false);
        setIsDisabled(true);
      }
    },
  });

  const handleChangepostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);
      formik.setFieldValue('street', '');
      formik.setFieldValue('neighborhood', '');
      formik.setFieldValue('number', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');
      formik.setFieldValue('complement', '');

      const postalcodeLength = 8;
      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf, complemento } = address;

          if (logradouro) {
            formik.setFieldValue('street', logradouro);
          }
          if (bairro) {
            formik.setFieldValue('neighborhood', bairro);
          }
          if (localidade) {
            formik.setFieldValue('city', localidade);
          }
          if (uf) {
            formik.setFieldValue('state', uf);
          }
          if (complemento) {
            formik.setFieldValue('complement', complemento);
          } else {
            formik.setFieldValue('complement', '');
          }
        }
      }
    },
    [formik]
  );

  const returnMask = useCallback((documentNumber: string) => {
    if (documentNumber.length === 11) {
      return maskCpf(documentNumber);
    } else return maskCnpj(documentNumber);
  }, []);

  return (
    <Main title="Companhia" maxWidth="md" to={ROUTES.company}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form noValidate>
            <Grid container spacing={3}>
              {loading ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="CPF / CNPJ"
                      id="documentNumber"
                      name="documentNumber"
                      autoFocus
                      value={returnMask(formik.values.documentNumber)}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.documentNumber &&
                        Boolean(formik.errors.documentNumber)
                      }
                      helperText={
                        formik.touched.documentNumber &&
                        formik.errors.documentNumber
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Nome da Empresa"
                      id="companyLegalName"
                      name="companyLegalName"
                      value={formik.values.companyLegalName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.companyLegalName &&
                        Boolean(formik.errors.companyLegalName)
                      }
                      helperText={
                        formik.touched.companyLegalName &&
                        formik.errors.companyLegalName
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Nome Fantasia"
                      id="tradingName"
                      name="tradingName"
                      value={formik.values.tradingName}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      error={
                        formik.touched.tradingName &&
                        Boolean(formik.errors.tradingName)
                      }
                      helperText={
                        formik.touched.tradingName && formik.errors.tradingName
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Nome do Contato"
                      id="contactName"
                      name="contactName"
                      value={formik.values.contactName}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      error={
                        formik.touched.contactName &&
                        Boolean(formik.errors.contactName)
                      }
                      helperText={
                        formik.touched.contactName && formik.errors.contactName
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Telefone"
                      id="telephone"
                      name="telephone"
                      value={maskTelephone(formik.values.telephone)}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      error={
                        formik.touched.telephone &&
                        Boolean(formik.errors.telephone)
                      }
                      helperText={
                        formik.touched.telephone && formik.errors.telephone
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="E-mail"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="CEP"
                      id="postalcode"
                      name="postalcode"
                      value={maskPostalcode(formik.values.postalcode)}
                      onChange={handleChangepostalcodeValue}
                      disabled={isDisabled}
                      error={
                        formik.touched.postalcode &&
                        Boolean(formik.errors.postalcode)
                      }
                      helperText={
                        formik.touched.postalcode && formik.errors.postalcode
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Rua"
                      id="street"
                      name="street"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.street && Boolean(formik.errors.street)
                      }
                      helperText={formik.touched.street && formik.errors.street}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Bairro"
                      id="neighborhood"
                      name="neighborhood"
                      value={formik.values.neighborhood}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.neighborhood &&
                        Boolean(formik.errors.neighborhood)
                      }
                      helperText={
                        formik.touched.neighborhood &&
                        formik.errors.neighborhood
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Número"
                      id="number"
                      name="number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Cidade"
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Estado"
                      id="state"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={formik.touched.state && formik.errors.state}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Complemento"
                      id="complement"
                      name="complement"
                      value={formik.values.complement}
                      onChange={formik.handleChange}
                      disabled={isDisabled}
                      error={
                        formik.touched.complement &&
                        Boolean(formik.errors.complement)
                      }
                      helperText={
                        formik.touched.complement && formik.errors.complement
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                {isDisabled ? (
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      type="button"
                      onClick={() => setIsDisabled(false)}
                      disabled={!permissions.includes(PERMISSIONS.COMPANY_EDIT)}
                    >
                      Editar
                    </Button>
                  </Grid>
                ) : (
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => formik.handleSubmit()}
                      loading={loadingButton}
                    >
                      Salvar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
