import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { PeriodEnum, useSettings } from '../../../../hooks/settings';
import {
  orderOperationsType,
  orderStatusCode,
  shippingStatus,
  orderNpsType,
} from '../../../../utils';
import {
  ILoadDeliveryRegion,
  useDeliveryRegion,
} from '../../../delivery-region/hooks/delivery-region';
import { IShippingOption, useOrder } from '../../hooks/order';

const validationSchema = yup.object({
  initialDate: yup.date(),
  finalDate: yup
    .date()
    .min(
      yup.ref('initialDate'),
      'Data fim não pode ser menor que a data início'
    ),
});

export const OrderFilter = ({ statusCode = '' }: { statusCode: string }) => {
  const { selectedPeriod, changePeriod, getDatePeriod } = useSettings();
  const { filter, setFilter, loadShippingOptions } = useOrder();
  const { loadDeliveryRegionList } = useDeliveryRegion();

  const [deliveryRegion, setDeliveryRegion] = useState<ILoadDeliveryRegion[]>(
    []
  );
  const [shippingOptions, setShippingOptions] = useState<IShippingOption[]>([]);

  const { values, errors, touched, handleChange, handleSubmit, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...filter,
        statusCode: statusCode === '' ? filter.statusCode : statusCode,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const { initialDate, finalDate } = values;
        const { from, to } = getDatePeriod(selectedPeriod);

        if (
          initialDate &&
          finalDate &&
          (initialDate !== from || finalDate !== to)
        ) {
          changePeriod(PeriodEnum.CUSTOM, { from: initialDate, to: finalDate });
        }

        setFilter({ ...values });
      },
    });

  useEffect(() => {
    async function loadDeliveryRegion(): Promise<void> {
      try {
        const response = await loadDeliveryRegionList();

        if (!response) return;

        setDeliveryRegion(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadDeliveryRegion();
  }, [loadDeliveryRegionList]);

  useEffect(() => {
    async function loadShippingOptionsList(): Promise<void> {
      try {
        const response = await loadShippingOptions();

        if (!response) return;

        setShippingOptions(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadShippingOptionsList();
  }, [loadShippingOptions]);

  const handleFormReset = useCallback(
    (e) => {
      e.preventDefault();

      const { from, to } = getDatePeriod(selectedPeriod);

      handleReset(e);
      setFilter({
        number: '',
        customerName: '',
        region: '',
        statusCode: statusCode,
        shippingStatus: '',
        initialDate: from,
        finalDate: to,
        shippingOption: '',
        operationType: '',
        product: '',
        npsType: '',
        isOccurrence: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDatePeriod, handleReset, selectedPeriod, statusCode]
  );

  return (
    <Grid
      container
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
      onReset={handleFormReset}
    >
      <Grid item xs={12}>
        <Typography variant="body1" id="filter">
          Filtros
        </Typography>
      </Grid>

      <Grid item xs={6} sm={2}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Nº do pedido"
          id="number"
          name="number"
          value={values.number}
          onChange={handleChange}
          error={touched.number && Boolean(errors.number)}
          helperText={touched.number && errors.number}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Cliente"
          id="customerName"
          name="customerName"
          value={values.customerName}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={6} sm={2}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Data início"
          type="date"
          id="initialDate"
          name="initialDate"
          value={values.initialDate}
          onChange={handleChange}
          error={touched.initialDate && Boolean(errors.initialDate)}
          helperText={touched.initialDate && errors.initialDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6} sm={2}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Data fim"
          type="date"
          id="finalDate"
          name="finalDate"
          value={values.finalDate}
          onChange={handleChange}
          error={touched.finalDate && Boolean(errors.finalDate)}
          helperText={touched.finalDate && errors.finalDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          select
          disabled={statusCode !== ''}
          variant="outlined"
          size="small"
          fullWidth
          label="Status"
          id="statusCode"
          name="statusCode"
          value={values.statusCode}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {orderStatusCode.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="SKU, Barcode ou Descrição"
          id="product"
          name="product"
          value={values.product}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={6} sm={2}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Status de Envio"
          id="shippingStatus"
          name="shippingStatus"
          value={values.shippingStatus}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {shippingStatus.map((shipping) => (
            <MenuItem key={shipping.value} value={shipping.value}>
              {shipping.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={2}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Opções de Envio"
          id="shippingOption"
          name="shippingOption"
          value={values.shippingOption}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {shippingOptions
            .sort((a, b) => {
              if (!!a.shippingOption && !!b.shippingOption) {
                return a.shippingOption.trim() > b.shippingOption.trim()
                  ? 1
                  : a.shippingOption.trim() < b.shippingOption.trim()
                  ? -1
                  : 0;
              }
              return -1;
            })
            .map(
              (option) =>
                option.shippingOption !== null &&
                option.shippingOption !== undefined &&
                option.shippingOption !== 'N/A' && (
                  <MenuItem
                    key={option.shippingOption}
                    value={option.shippingOption}
                  >
                    {option.shippingOption}
                  </MenuItem>
                )
            )}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Operação"
          id="operationType"
          name="operationType"
          value={values.operationType}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {orderOperationsType.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={2}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="NPS"
          id="npsType"
          name="npsType"
          value={values.npsType}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {orderNpsType.map((nps) => (
            <MenuItem key={nps.value} value={nps.value}>
              {nps.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} container spacing={1} justifyContent="flex-end">
        <Grid item>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  id="isOccurrence"
                  name="isOccurrence"
                  value={values.isOccurrence}
                  onChange={handleChange}
                  checked={values.isOccurrence}
                  color="primary"
                />
              }
              label={<Typography>Ocorrência</Typography>}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <Button variant="contained" color="secondary" type="submit">
            Filtrar
          </Button>
        </Grid>

        <Grid item>
          <Button type="reset">Limpar</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
