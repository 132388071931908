import React, { useCallback, useEffect, useState } from 'react';
import { ActionMeta } from 'react-select';
import * as yup from 'yup';
import { cnpjValidation, cpfValidation } from '../../../../utils';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { PERMISSIONS, ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';

import { useManualOrderParams } from '../../hooks/manual-order-params';

import { useManualOrder } from '../../../order/hooks/manual-order';
import { AsyncSelect, IOption } from '../../../../components/AsyncSelect';

import { useAuth } from '../../../../hooks/auth';

import { Form } from './styles';

export const ManualOrderParamsForm = () => {
  const {
    data: { user, permissions },
  } = useAuth();
  const { shippingOption, paymentStatus, loadSenderList, loadSenderById } =
    useManualOrder();
  const {
    createManualOrderParams,
    updateManualOrderParams,
    getManualOrderParams,
  } = useManualOrderParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [manualOrderParamsId, setManualOrderParamsId] = useState<number | null>(
    null
  );
  const [data, setData] = useState({
    hideSerie: false,
    serieDefault: '1',
    hideReferenceCode: false,
    deliveryPrevisionDays: 2,
    hidePaymentStatus: false,
    paymentStatusDefault: 'paid',
    shippingTypeDefault: 'Transportadora',
    hideShippingCost: false,
    shippingCostDefault: 0,
    hideTransferFreight: false,
    transferFreightDefault: 0,
    senderDefault: {
      id: '',
      documentNumber: '',
    },
    companyDocument: user.companyDocument,
  });

  useEffect(() => {
    async function loadManualOrderParams(): Promise<void> {
      try {
        const response = await getManualOrderParams();

        if (!response) return;

        setManualOrderParamsId(response.id);
        setData({
          ...response,
          senderDefault: {
            id: response.senderDefault ? response.senderDefault.toString() : '',
            documentNumber: '',
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadManualOrderParams();
  }, [getManualOrderParams]);

  useEffect(() => {
    async function loadSender(): Promise<void> {
      try {
        const response = await loadSenderById(parseInt(data.senderDefault.id));

        if (response) {
          setData((prevState) => ({
            ...prevState,
            senderDefault: {
              ...prevState.senderDefault,
              documentNumber: response.documentNumber,
            },
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadSender();
  }, [data.senderDefault.id, loadSenderById]);

  const validationSchema = yup.object().shape({
    hideSerie: yup.boolean(),
    serieDefault: yup.string(),
    hideReferenceCode: yup.boolean(),
    deliveryPrevisionDays: yup.number(),
    hidePaymentStatus: yup.boolean(),
    paymentStatusDefault: yup.string(),
    shippingTypeDefault: yup.string(),
    hideShippingCost: yup.boolean(),
    shippingCostDefault: yup.number(),
    hideTransferFreight: yup.boolean(),
    transferFreightDefault: yup.number(),
    senderDefault: yup.object().shape({
      id: yup.string(),
      documentNumber: yup
        .string()
        .test('validate-cpf-or-cnpj', 'Inválido', (value) =>
          value
            ? value.length > 11
              ? cnpjValidation(value)
              : cpfValidation(value)
            : true
        ),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      const newData = {
        ...values,
        senderDefault: values.senderDefault.id
          ? parseInt(values.senderDefault.id)
          : null,
      };

      try {
        if (manualOrderParamsId) {
          await updateManualOrderParams(manualOrderParamsId, newData);

          const message = 'Atualizado com sucesso!';

          enqueueSnackbar(message, {
            variant: 'success',
          });
        } else {
          await createManualOrderParams(newData);
          const message = 'Criado com sucesso!';

          enqueueSnackbar(message, {
            variant: 'success',
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const newValue = parseInt(value.replace(/[^0-9]/g, ''));

      formik.setFieldValue(name, newValue);
    },
    [formik]
  );

  const actionOnChange = (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (option) {
      const { value, otherProps } = option;

      if (otherProps) {
        if (actionMeta.action === 'select-option') {
          formik.setFieldValue('senderDefault.documentNumber', value);
          formik.setFieldValue('senderDefault.id', otherProps.id.toString());
        }
      } else if (actionMeta.action === 'create-option') {
        formik.setFieldValue('senderDefault.documentNumber', '');
        formik.setFieldValue('senderDefault.id', '');
      }
    } else {
      formik.setFieldValue('senderDefault.documentNumber', '');
      formik.setFieldValue('senderDefault.id', '');
    }
  };

  return (
    <Main
      title="Parâmetros de Pedido Manual"
      maxWidth="md"
      to={ROUTES.manualOrderParams}
    >
      <Form noValidate>
        <Grid container spacing={3}>
          {loading ? (
            <Grid item xs={12}>
              <Typography component="div" align="center">
                <CircularProgress />
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="hideSerie"
                        name="hideSerie"
                        value={formik.values.hideSerie}
                        onChange={formik.handleChange}
                        checked={formik.values.hideSerie}
                        color="primary"
                      />
                    }
                    label={<Typography>Ocultar Série</Typography>}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Valor padrão da Série"
                  id="serieDefault"
                  name="serieDefault"
                  value={formik.values.serieDefault}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.serieDefault &&
                    Boolean(formik.errors.serieDefault)
                  }
                  helperText={
                    formik.touched.serieDefault && formik.errors.serieDefault
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="hideReferenceCode"
                        name="hideReferenceCode"
                        value={formik.values.hideReferenceCode}
                        onChange={formik.handleChange}
                        checked={formik.values.hideReferenceCode}
                        color="primary"
                      />
                    }
                    label={
                      <Typography>Ocultar Código de Referência</Typography>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Dia(s) Previsão de Entrega"
                  id="deliveryPrevisionDays"
                  name="deliveryPrevisionDays"
                  value={
                    formik.values.deliveryPrevisionDays
                      ? formik.values.deliveryPrevisionDays
                      : 0
                  }
                  onChange={handleChangeValue}
                  error={
                    formik.touched.deliveryPrevisionDays &&
                    Boolean(formik.errors.deliveryPrevisionDays)
                  }
                  helperText={
                    formik.touched.deliveryPrevisionDays &&
                    formik.errors.deliveryPrevisionDays
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="hidePaymentStatus"
                        name="hidePaymentStatus"
                        value={formik.values.hidePaymentStatus}
                        onChange={formik.handleChange}
                        checked={formik.values.hidePaymentStatus}
                        color="primary"
                      />
                    }
                    label={<Typography>Ocultar Status Pagamento</Typography>}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Valor padrão Status Pagamento"
                  id="paymentStatusDefault"
                  name="paymentStatusDefault"
                  value={formik.values.paymentStatusDefault}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.paymentStatusDefault &&
                    Boolean(formik.errors.paymentStatusDefault)
                  }
                  helperText={
                    formik.touched.paymentStatusDefault &&
                    formik.errors.paymentStatusDefault
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {paymentStatus.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="hideShippingCost"
                        name="hideShippingCost"
                        value={formik.values.hideShippingCost}
                        onChange={formik.handleChange}
                        checked={formik.values.hideShippingCost}
                        color="primary"
                      />
                    }
                    label={<Typography>Ocultar Valor do Frete</Typography>}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Valor padrão Valor do Frete"
                  id="shippingCostDefault"
                  name="shippingCostDefault"
                  value={
                    formik.values.shippingCostDefault
                      ? formik.values.shippingCostDefault
                      : 0
                  }
                  onChange={handleChangeValue}
                  error={
                    formik.touched.shippingCostDefault &&
                    Boolean(formik.errors.shippingCostDefault)
                  }
                  helperText={
                    formik.touched.shippingCostDefault &&
                    formik.errors.shippingCostDefault
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="hideTransferFreight"
                        name="hideTransferFreight"
                        value={formik.values.hideTransferFreight}
                        onChange={formik.handleChange}
                        checked={formik.values.hideTransferFreight}
                        color="primary"
                      />
                    }
                    label={<Typography>Ocultar Repasse do Frete</Typography>}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Valor padrão Repasse do Frete"
                  id="transferFreightDefault"
                  name="transferFreightDefault"
                  value={
                    formik.values.transferFreightDefault
                      ? formik.values.transferFreightDefault
                      : 0
                  }
                  onChange={handleChangeValue}
                  error={
                    formik.touched.transferFreightDefault &&
                    Boolean(formik.errors.transferFreightDefault)
                  }
                  helperText={
                    formik.touched.transferFreightDefault &&
                    formik.errors.transferFreightDefault
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: 'inline-flex',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <AsyncSelect
                    searchFunction={loadSenderList}
                    name="senderDefault.documentNumber"
                    value={{
                      label: formik.values.senderDefault.documentNumber,
                      value: formik.values.senderDefault.documentNumber,
                    }}
                    actionOnChange={actionOnChange}
                    setValue={formik.setFieldValue}
                    error={formik.errors.senderDefault?.documentNumber}
                  />
                </div>

                <label
                  style={{
                    display: 'block',
                    position: 'absolute',
                    top: 7,
                    left: 8,
                    padding: 6,
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '1rem',
                    fontFamily: 'sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    transform: 'translate(14px, -6px) scale(0.75)',
                    transformOrigin: 'top left',
                    background: 'white',
                  }}
                >
                  Remetente padrão
                </label>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Valor padrão Opção de Envio"
                  id="shippingTypeDefault"
                  name="shippingTypeDefault"
                  value={formik.values.shippingTypeDefault}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.shippingTypeDefault &&
                    Boolean(formik.errors.shippingTypeDefault)
                  }
                  helperText={
                    formik.touched.shippingTypeDefault &&
                    formik.errors.shippingTypeDefault
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {shippingOption.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => formik.handleSubmit()}
                loading={loadingButton}
                disabled={
                  !permissions.includes(PERMISSIONS.MANUAL_ORDER_PARAMS_EDIT)
                }
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Main>
  );
};
