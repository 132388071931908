import * as yup from 'yup';
import { PROFILE_TYPE } from '../../../../constants';
import { cnpjValidation, cpfValidation } from '../../../../utils';

export function getValidationSchema(type: 'create' | 'update') {
  switch (type) {
    case 'create':
      return yup.object({
        cpf: yup
          .string()
          .required('Insira seu documento')
          .when('type', {
            is: (value: PROFILE_TYPE) => value !== 'FINAL_USER',
            then: yup
              .string()
              .test(
                'documentNumber-is-validate',
                'Número de documento inválido!',
                (value) => {
                  if (value) {
                    if (value.length <= 11) {
                      return cpfValidation(value);
                    } else return cnpjValidation(value);
                  }
                  return false;
                }
              ),
            otherwise: yup
              .string()
              .test('documentNumber-is-validate', 'CNPJ inválido!', (value) => {
                if (value) {
                  return cnpjValidation(value);
                }
                return false;
              }),
          }),
        name: yup.string().required('Insira seu nome completo'),
        type: yup.string().required('Tipo é obrigatório'),
        phone: yup
          .string()
          .min(11, 'Ex: (99) 9 9999-9999')
          .required('Celular é obrigatório'),
        postalcode: yup.string().required('Por favor, insira um CEP'),
        street: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        neighborhood: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        number: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string().required('Insira um número'),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        city: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        state: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        email: yup.string().required('Email é obrigatório'),
        emailConfirmation: yup
          .string()
          .oneOf([yup.ref('email'), null], 'Emails diferentes')
          .required('Confirmação obrigatória'),
        password: yup
          .string()
          .required('Senha é obrigatória')
          .min(6, 'Senha mínima de 6 caracteres'),
        passwordConfirmation: yup
          .string()
          .oneOf([yup.ref('password'), null], 'Senhas diferentes')
          .required('Confirmação obrigatória'),
      });
    case 'update':
      return yup.object({
        cpf: yup
          .string()
          .required('Insira seu documento')
          .when('type', {
            is: (value: PROFILE_TYPE) => value !== 'FINAL_USER',
            then: yup
              .string()
              .test(
                'documentNumber-is-validate',
                'Número de documento inválido!',
                (value) => {
                  if (value) {
                    if (value.length <= 11) {
                      return cpfValidation(value);
                    } else return cnpjValidation(value);
                  }
                  return false;
                }
              ),
            otherwise: yup
              .string()
              .test('documentNumber-is-validate', 'CNPJ inválido!', (value) => {
                if (value) {
                  return cnpjValidation(value);
                }
                return false;
              }),
          }),
        name: yup.string().required('Insira seu nome completo'),
        type: yup.string().required('Tipo é obrigatório'),
        phone: yup
          .string()
          .min(11, 'Ex: (99) 9 9999-9999')
          .required('Celular é obrigatório'),
        postalcode: yup.string().required('Por favor, insira um CEP'),
        street: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        neighborhood: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        number: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string().required('Insira um número'),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        city: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        state: yup.string().when('postalcode', {
          is: (val: any) => !!val,
          then: yup.string(),
          otherwise: yup.string().required('Insira o CEP'),
        }),
        email: yup.string().required('Email é obrigatório'),
        emailConfirmation: yup
          .string()
          .oneOf([yup.ref('email'), null], 'Emails diferentes')
          .required('Confirmação obrigatória'),
      });
    default:
      throw new Error(`Invalid type ${type}`);
  }
}
