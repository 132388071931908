import React, { useContext, createContext, useCallback, useState } from 'react';
import { PROFILE_TYPE } from '../../../constants';
import api from '../../../services/api';

type NumberFieldValue = number | '';

export interface IPermission {
  code: string;
  description?: string;
}

export interface IPermissionProfile {
  type: PROFILE_TYPE;
  permissions: IPermission[];
}

export interface IUserPermission {
  permission: string;
}

export interface IUserRequest {
  name: string;
  email: string;
  cpf: string;
  type: PROFILE_TYPE;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  postalcode: string;
  city: string;
  state: string;
  country: string;
  companyDocument: NumberFieldValue;
  phone: string;
  password?: string;
  status: string;
  isNotified: boolean;
  permissions: IUserPermission[];
}

export interface ILoadUser {
  id: number;
  name: string;
  email: string;
  city: string;
  type: PROFILE_TYPE;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  postalcode: string;
  state: string;
  country: string;
  cpf: string;
  companyDocument: NumberFieldValue;
  phone: string;
  status: string;
  isNotified: boolean;
  permissions: IUserPermission[];
}

interface IUserManagementContext {
  userManagementStatus: Array<{ label: string; value: string }>;
  loadUserById(id: number): Promise<ILoadUser | undefined>;
  loadPermissions(): Promise<IPermission[]>;
  loadPermissionsProfile(): Promise<IPermissionProfile[]>;
  loadUserList(companyDocument: number): Promise<ILoadUser[]>;
  updateUser(id: number, data: IUserRequest): Promise<void>;
  createUser(data: IUserRequest): Promise<void>;
  deleteUser(id: number): Promise<void>;
}

const UserManagementContext = createContext<IUserManagementContext>(
  {} as IUserManagementContext
);

const UserManagementProvider: React.FC = ({ children }) => {
  const [userManagementStatus] = useState([
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
    { label: 'Pendente', value: 'pending' },
  ]);

  const loadUserById = useCallback(async (id: number) => {
    const response = await api.get(`/user/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadUserList = useCallback(async (companyDocument: number) => {
    const response = await api.get(`/user?companyDocument=${companyDocument}`);

    return response.data;
  }, []);

  const loadPermissions = useCallback(async () => {
    const response = await api.get('/user/list/permissions');

    return response.data;
  }, []);

  const loadPermissionsProfile = useCallback(async () => {
    const response = await api.get('/user/profile/permissions');

    return response.data;
  }, []);

  const createUser = useCallback(async (data: IUserRequest) => {
    await api.post('/user', data);
  }, []);

  const updateUser = useCallback(async (id: number, data: IUserRequest) => {
    await api.put(`/user/${id}`, data);
  }, []);

  const deleteUser = useCallback(async (id: number) => {
    await api.delete(`/user/${id}`);
  }, []);

  return (
    <UserManagementContext.Provider
      value={{
        userManagementStatus,
        loadUserById,
        loadUserList,
        createUser,
        updateUser,
        deleteUser,
        loadPermissions,
        loadPermissionsProfile,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};

function useUserManagement(): IUserManagementContext {
  const context = useContext(UserManagementContext);

  if (!context) {
    throw new Error(
      'useUserManagement must be used within a UserManagementProvider'
    );
  }

  return context;
}

export { UserManagementProvider, useUserManagement };
