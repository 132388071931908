import { useCallback } from 'react';

import { useSignUp } from '../../hooks/signUp';

import { ProfileForm } from '../ProfileForm';
import { SelectProfileType } from '../SelectProfileType';
import { WorkCompanyForm } from '../WorkCompanyForm';
import { Completed } from '../Completed';

import { Container } from './styles';

export const SignUp = () => {
  const { activeStep } = useSignUp();

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return <SelectProfileType />;
      case 1:
        return <WorkCompanyForm />;
      case 2:
        return <ProfileForm />;
      case 3:
        return <Completed />;
      default:
        throw new Error('Desconhecido');
    }
  }, []);

  return <Container>{getStepContent(activeStep)}</Container>;
};
