import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { TypeDistributionCenterReport } from '../pages/DistributionCenter';

const docDefinition = (
  img: string,
  data: TypeDistributionCenterReport[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Centros de Distribuição',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Centros de Distribuição',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    {
      margin: [0, 10, 0, 0],
      table: {
        widths: ['auto', 'auto', 'auto', '*'],
        body: [
          [
            {
              text: 'Código',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Nome',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Principal',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Endereço',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
          ],
          ...generateTable(data),
        ],
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: TypeDistributionCenterReport[]) {
  const rows = data.map((distributionCenter) => {
    return [
      {
        text: distributionCenter.id,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: distributionCenter.name,
        alignment: 'left',
        fontSize: 8,
      },
      {
        text: distributionCenter.isPrincipal,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: distributionCenter.address,
        alignment: 'left',
        fontSize: 8,
      },
    ];
  });

  return rows;
}

export async function generateDistributionCenterReportPDF(
  data: TypeDistributionCenterReport[]
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
