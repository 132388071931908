import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  slider: {
    margin: '20px',
    '& #arrow:before': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  imgVertical: {
    width: '260px',
    height: 'auto',
    margin: 'auto',
  },
  imgHorizontal: {
    width: 'auto',
    height: '500px',
    margin: 'auto',
  },
}));
