import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useForgotPassword } from '../../hooks/forgotPassword';
import { Button } from '../../../../components/Button';
import { Form } from './styles';

export const CodeRequestForm = () => {
  const {
    setIsForgotPassword,
    setActiveStepForgotPassword,
    userForgotPassword,
    setData,
  } = useForgotPassword();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingButton, setLoadingButton] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string()
      .required('E-mail é obrigatório')
      .email('E-mail Inválido!'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        await userForgotPassword(values);
        setData(values);
        setActiveStepForgotPassword(1);
      } catch (error: any) {
        if (error.status === 500) {
          const message = 'Cadastro de usuário não encontrado!';

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } finally {
        setLoadingButton(false);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} noValidate>
      <Typography variant="h6" align="left">
        <strong>Esqueci minha senha</strong>
      </Typography>

      <Typography variant="subtitle2" align="left">
        Informe seu e-mail para confirmação.
      </Typography>

      <TextField
        style={{ marginTop: '36px', marginBottom: '36px' }}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="E-mail"
        id="email"
        name="email"
        autoFocus
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Grid container justifyContent="space-between">
        <Button color="inherit" onClick={() => setIsForgotPassword(false)}>
          Voltar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          loading={loadingButton}
        >
          Enviar Código
        </Button>
      </Grid>
    </Form>
  );
};
