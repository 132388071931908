import * as yup from 'yup';
import { cnpjValidation, cpfValidation } from '../../../../../utils';

export default yup.object().shape({
  customer: yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    documentNumber: yup
      .string()
      .required('Campo obrigatório')
      .test('validate-cpf-or-cnpj', 'Inválido', (value) =>
        value && value.length > 11
          ? cnpjValidation(value)
          : cpfValidation(value)
      ),
    email: yup
      .string()
      .email('Insira um e-mail válido')
      .required('Campo obrigatório'),
    cellphone: yup.string().required('Campo obrigatório'),
    addresses: yup.object().shape({
      postalcode: yup
        .string()
        .min(8, 'Insira um CEP válido')
        .required('CEP obrigatório'),
      street: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      neighborhood: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      number: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string().required('Campo obrigatório'),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      city: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      state: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      complement: yup.string(),
      lat: yup
        .string()
        .test(
          'is-full-coordinate',
          'Coordenada inválida: Latitude',
          (lat, context) => {
            const long = context.parent.long;
            if (!!lat?.trim() === !!long) {
              return true;
            }
            return !!lat?.trim();
          }
        ),
      long: yup
        .string()
        .test(
          'is-full-coordinate',
          'Coordenada inválida: Longitude',
          (long, context) => {
            const lat = context.parent.lat;
            if (!!long?.trim() === !!lat) {
              return true;
            }
            return !!long?.trim();
          }
        ),
    }),
  }),
});
