import React, { useContext, createContext, useCallback, useState } from 'react';
import { RegionStatus } from '../../../constants';
import api from '../../../services/api';
import { ILoadUser } from '../../user-management/hooks/user-management';
import { ILoadVehicleType } from '../../vehicle-type/hooks/vehicle-type';

type NumberFieldValue = number | '';

export interface IVehicleRequest {
  name: string;
  plate: string;
  status: string;
  driverId: NumberFieldValue;
  vehicleTypeId: NumberFieldValue;
  companyDocument: number;
  mapLineColor: string;
  zoneDelivery: RegionStatus;
}

export interface ILoadVehicle {
  id: number;
  name: string;
  plate: string;
  status: string;
  driverId: number;
  user: ILoadUser;
  vehicleType: ILoadVehicleType;
  companyDocument: number;
  mapLineColor: string;
  zoneDelivery: RegionStatus;
}

interface IVehicleContext {
  vehicleStatus: Array<{ label: string; value: string }>;
  loadVehicleById(id: number): Promise<ILoadVehicle | undefined>;
  loadVehicleList(companyDocument: number): Promise<ILoadVehicle[]>;
  updateVehicle(id: number, data: IVehicleRequest): Promise<void>;
  createVehicle(data: IVehicleRequest): Promise<void>;
  deleteVehicle(id: number): Promise<void>;
}

const VehicleContext = createContext<IVehicleContext>({} as IVehicleContext);

const VehicleProvider: React.FC = ({ children }) => {
  const [vehicleStatus] = useState([
    { label: 'Pendente', value: 'pending' },
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
    { label: 'Em manutenção', value: 'under-maintenance' },
  ]);

  const loadVehicleById = useCallback(async (id: number) => {
    const response = await api.get(`/vehicle/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadVehicleList = useCallback(async (companyDocument: number) => {
    const response = await api.get(
      `/vehicle?companyDocument=${companyDocument}`
    );

    return response.data;
  }, []);

  const createVehicle = useCallback(async (data: IVehicleRequest) => {
    await api.post('/vehicle', data);
  }, []);

  const updateVehicle = useCallback(
    async (id: number, data: IVehicleRequest) => {
      await api.put(`/vehicle/${id}`, data);
    },
    []
  );

  const deleteVehicle = useCallback(async (id: number) => {
    await api.delete(`/vehicle/${id}`);
  }, []);

  return (
    <VehicleContext.Provider
      value={{
        vehicleStatus,
        loadVehicleById,
        loadVehicleList,
        createVehicle,
        updateVehicle,
        deleteVehicle,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};

function useVehicle(): IVehicleContext {
  const context = useContext(VehicleContext);

  if (!context) {
    throw new Error('useVehicle must be used within a VehicleProvider');
  }

  return context;
}

export { VehicleProvider, useVehicle };
