import React, { useContext, createContext, useCallback } from "react";
import api from "../../../services/api";

interface IContact {
  id: number;
  type: string;
  contact: string;
  contactName: string;
}

interface IAddress {
  id: number;
  postalcode: string;
  street: string;
  neighborhood: string;
  number: string;
  city: string;
  state: string;
  complement: string;
}

export interface ICompany {
  id: number;
  documentNumber: string;
  companyLegalName: string;
  tradingName: string;
  contacts: IContact[];
  addresses: IAddress[];
}

interface ICompanyContext {
  getCompany(documentNumber: number): Promise<ICompany>;
  updateCompany(id: number, data: ICompany): Promise<void>;
}

const CompanyContext = createContext<ICompanyContext>({} as ICompanyContext);

const CompanyProvider: React.FC = ({ children }) => {
  const getCompany = useCallback(async (documentNumber: number) => {
    const response = await api.get(`/company/by-document/${documentNumber}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const updateCompany = useCallback(async (id: number, data: ICompany) => {
    await api.patch(`/company/${id}`, data);
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        getCompany,
        updateCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

function useCompany(): ICompanyContext {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }

  return context;
}

export { CompanyProvider, useCompany };
