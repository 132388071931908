import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { OrderProvider } from '../../order/hooks/order';
import { PlanningProvider } from '../../order/hooks/planning';

import { Separation } from '../pages/Separation';
import { SeparationProvider } from '../hooks/separation';
import { DistributionCenterProvider } from '../../distribution-center/hooks/distribution-center';
import { AgcoProvider } from '../hooks/agco';

export const SeparationRoute: React.FC = () => (
  <SeparationProvider>
    <AgcoProvider>
      <OrderProvider>
        <PlanningProvider>
          <DistributionCenterProvider>
            <Switch>
              <PrivateRoute
                path={ROUTES.separation}
                permission={PERMISSIONS.SEPARATION_VIEW}
                component={Separation}
              />
            </Switch>
          </DistributionCenterProvider>
        </PlanningProvider>
      </OrderProvider>
    </AgcoProvider>
  </SeparationProvider>
);
