import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';

import {
  getPermissionsProfileLabel,
  maskCnpj,
  maskCpf,
  maskPostalcode,
  maskTelephone,
} from '../../../../utils';
import { ILoadUser, useUserManagement } from '../../hooks/user-management';

interface IProps {
  open: boolean;
  userManagementId: number | null;
  onClose: () => void;
}

export const UserManagementInfo: React.FC<IProps> = (props) => {
  const { loadUserById } = useUserManagement();
  const [userManagement, setUserManagement] = useState<ILoadUser>(
    {} as ILoadUser
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setUserManagement({} as ILoadUser);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadUserManagement(): Promise<void> {
      if (props.userManagementId) {
        try {
          const response = await loadUserById(props.userManagementId);

          if (!response) return;

          setUserManagement({
            ...response,
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadUserManagement();
  }, [loadUserById, props.userManagementId]);

  const returnMask = useCallback((cpf: string) => {
    if (cpf.length === 11) return maskCpf(cpf);
    else return maskCnpj(cpf);
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Usuário cadastrado:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nome completo"
                value={userManagement.name ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Tipo"
                value={getPermissionsProfileLabel(userManagement.type) ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                label="Celular"
                value={maskTelephone(userManagement.phone) ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="CPF / CNPJ"
                value={returnMask(userManagement.cpf) ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="CEP"
                value={maskPostalcode(userManagement.postalcode) ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Rua"
                value={userManagement.street ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Bairro"
                value={userManagement.neighborhood ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Número"
                value={userManagement.number ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Cidade"
                value={userManagement.city ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Estado"
                value={userManagement.state ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Complemento"
                value={userManagement.complement ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Email"
                value={userManagement.email ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
