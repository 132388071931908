import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MapIcon from '@material-ui/icons/Map';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCommentIcon from '@material-ui/icons/AddComment';

import { ROUTES, PERMISSIONS } from '../../../../../constants';
import { useAuth } from '../../../../../hooks/auth';
import { routeStatusLabel } from '../../../../../utils';
import { IRouteLight, useRoute } from '../../../hooks/route';
import { MapLeaflet } from '../MapLeaflet';
import { ActivitiesTable } from '../ActivitiesTable';
import { RowActionButtons } from '../RowActionButtons';
import { generateDriverRoutePDF } from '../../../../report/templates/driverRouteReport';
import DescriptionIcon from '@material-ui/icons/Description';
import { useStyles } from './styles';
import { useSnackbar } from 'notistack';
import { useCompanyParams } from '../../../../company-params/hooks/company-params';
import { Autorenew } from '@material-ui/icons';

interface IProps {
  route: IRouteLight;
  onAddObservationClick: (id: number, observation: string) => void;
  onReprocessRouteMapClick: (routeId: number) => Promise<void>;
}

export const RouteRow = ({
  route,
  onAddObservationClick,
  onReprocessRouteMapClick,
}: IProps) => {
  const {
    data: { permissions },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyParams } = useCompanyParams();
  const history = useHistory();
  const { updateRouteList, getDriverRouteReportById } = useRoute();
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadRoute(): Promise<void> {
      try {
        await updateRouteList(route.id);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (openInfo && loading) loadRoute();
  }, [updateRouteList, route.id, openInfo, loading]);

  const isNotDisabled = useCallback(() => {
    if (permissions.includes(PERMISSIONS.ROUTE_VIEW_MAP)) {
      return !route.map || !route.map.mapRoute;
    }

    return true;
  }, [permissions, route.map]);

  async function onPrintRouteReportClick(routeId: number) {
    const report = await getDriverRouteReportById(routeId);
    const { geoFenceDistance } = await getCompanyParams();

    generateDriverRoutePDF(report, geoFenceDistance);
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpenInfo(!openInfo)}
            aria-label="expand row"
          >
            {openInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {route.id}
        </TableCell>
        <TableCell>
          <strong>
            <Chip
              label={route.routerName}
              style={{
                color: '#fff',
                backgroundColor: route.mapLineColor,
              }}
            />
          </strong>
        </TableCell>
        <TableCell>{route.driver.driverName}</TableCell>
        <TableCell>{routeStatusLabel(route.status)}</TableCell>
        <TableCell align="right">
          {route.totalTime ? `${route.totalTime} min` : '0 min'}
        </TableCell>
        <TableCell align="right">
          {route.totalDistance ? `${route.totalDistance} km` : '0 km'}
        </TableCell>
        <TableCell>
          <Tooltip title="Abrir Mapa" placement="top">
            <IconButton
              color="inherit"
              aria-label="open map"
              size="small"
              onClick={() => history.push(`${ROUTES.map}/${route.id}`)}
              disabled={isNotDisabled()}
            >
              <MapIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Reprocessar Mapa" placement="top">
            <IconButton
              color="inherit"
              aria-label="open map"
              size="small"
              onClick={() => {
                onReprocessRouteMapClick(route.id);
              }}
            >
              <Autorenew />
            </IconButton>
          </Tooltip>

          <Tooltip title="Adicionar Observação" placement="top">
            <IconButton
              color="inherit"
              aria-label="add observation"
              size="small"
              onClick={() => onAddObservationClick(route.id, route.observation)}
            >
              <AddCommentIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Imprimir relatório de Rotas" placement="top">
            <IconButton
              color="inherit"
              aria-label="print"
              size="small"
              onClick={() => {
                enqueueSnackbar(
                  `Imprimindo relatório de Rota ${route.id}! Aguarde, você será redirecionado.`,
                  {
                    variant: 'info',
                  }
                );
                onPrintRouteReportClick(route.id);
              }}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openInfo} timeout="auto" unmountOnExit>
            {loading ? (
              <Typography component="div" align="center">
                <CircularProgress color="secondary" />
              </Typography>
            ) : (
              <>
                {!!route.map && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.heading}>
                        Mapa da Rota
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MapLeaflet route={route} />
                    </AccordionDetails>
                  </Accordion>
                )}

                {!!route.activities && (
                  <>
                    <ActivitiesTable
                      routeId={route.id}
                      routeName={route.routerName}
                      activities={route.activities}
                    />

                    <RowActionButtons route={route} />
                  </>
                )}
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
