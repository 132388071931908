import {
  OrderStatusCode,
  RouteStatus,
  ActivityStatus,
  RegionStatus,
  ShippingStatus,
  PaymentStatus,
  ConferenceStatus,
  PROFILE_TYPE,
  OperationType,
} from '../constants';
import { NpsType } from '../modules/order/hooks/order';

export const orderStatusCode: Array<{ label: string; value: OrderStatusCode }> =
  [
    { label: 'Novo', value: 'new' },
    { label: 'Na rota planejada', value: 'on-planned-route' },
    { label: 'Na rota', value: 'on-route' },
    { label: 'Concluído', value: 'delivered' },
    { label: 'Não concluído', value: 'undelivered' },
    { label: 'Ocorrência', value: 'occurrence' },
    { label: 'Cancelado', value: 'cancelled' },
    { label: 'Pendente', value: 'pending' },
    { label: 'Atrasado', value: 'backorders' },
    { label: 'Fracionado parcialmente', value: 'partially-fractionated' },
    { label: 'Não executado', value: 'not-executed' },
  ];

export const orderStatusCodeLabel = (value: OrderStatusCode) => {
  const status = orderStatusCode.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const orderOperationsType: Array<{
  label: string;
  value: OperationType;
}> = [
  { label: 'Coleta/Retirada', value: 'collect' },
  { label: 'Entrega', value: 'delivery' },
  { label: 'Devolução ', value: 'devolution' },
  { label: 'Higienização ', value: 'cleaning' },
  { label: 'Reparo', value: 'maintenance' },
];

export const orderNpsType: Array<{
  label: string;
  value: NpsType;
}> = [
  { label: 'Promotor', value: 'promoter' },
  { label: 'Neutro', value: 'neutral' },
  { label: 'Detrator ', value: 'detrator' },
];

export const orderOperationTypeLabel = (value: OperationType) => {
  const operationType = orderOperationsType.find(
    (type) => type.value === value
  );

  if (operationType) return operationType.label;

  return value;
};

export const routeStatus: Array<{ label: string; value: RouteStatus }> = [
  { label: 'Pendente', value: 'pending' },
  { label: 'Em rota', value: 'on-route' },
  { label: 'Não executada', value: 'not-executed' },
  { label: 'Executada', value: 'executed' },
  { label: 'Parcialmente executada', value: 'partial-executed' },
  { label: 'Reprocessando', value: 'rescheduling' },
];

export const routeStatusLabel = (value: RouteStatus) => {
  const status = routeStatus.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const activityStatus: Array<{ label: string; value: ActivityStatus }> = [
  { label: 'Pendente', value: 'pending' },
  { label: 'Em rota', value: 'on-route' },
  { label: 'Não executada', value: 'undelivered' },
  { label: 'Concluído', value: 'delivered' },
  { label: 'Cancelado', value: 'canceled' },
  { label: 'Ocorrência', value: 'occurrence' },
  { label: 'Movido', value: 'moved' },
];

export const activityStatusLabel = (value: ActivityStatus) => {
  const status = activityStatus.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const regionStatus: Array<{ label: string; value: RegionStatus }> = [
  { label: 'Zona Sul', value: 'zona-sul' },
  { label: 'Zona Norte', value: 'zona-norte' },
  { label: 'Zona Leste', value: 'zona-leste' },
  { label: 'Zona Oeste', value: 'zona-oeste' },
  { label: 'Centro', value: 'centro' },
  { label: 'Sem zoneamento', value: 'generica' },
];

export const regionStatusLabel = (value: RegionStatus) => {
  const status = regionStatus.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const shippingStatus: Array<{ label: string; value: ShippingStatus }> = [
  { label: 'Disponível para separação', value: 'unpacked' },
  { label: 'Disponível para envio', value: 'conferred' },
  { label: 'Disponível para envio', value: 'unshipped' },
  { label: 'Enviado', value: 'shipped' },
];

export const shippingStatusLabel = (value: ShippingStatus) => {
  const status = shippingStatus.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const paymentStatus: Array<{ label: string; value: PaymentStatus }> = [
  { label: 'Aguardando Pagamento', value: 'pending' },
  { label: 'Pago', value: 'paid' },
];

export const orderPaymentStatus = (value: PaymentStatus) => {
  const status = paymentStatus.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const conferenceStatus: Array<{
  label: string;
  value: ConferenceStatus;
}> = [
  { label: 'Não conferido', value: 'unchecked' },
  { label: 'Conferido', value: 'conferred' },
  { label: 'Parcialmente conferido', value: 'partially-conferred' },
];

export const conferenceStatusLabel = (value: ConferenceStatus) => {
  const status = conferenceStatus.find((status) => status.value === value);

  if (status) return status.label;

  return value;
};

export const permissionsProfileTypesLabel: Array<{
  label: string;
  value: PROFILE_TYPE;
}> = [
  { label: 'Administrador', value: 'ADMIN' },
  { label: 'Autônomo', value: 'AUTONOMO' },
  { label: 'Demonstração', value: 'DEMO' },
  { label: 'Motorista', value: 'DRIVER' },
  { label: 'Usuário', value: 'USER' },
  { label: 'Cliente', value: 'FINAL_USER' },
  { label: 'Atendente', value: 'ATTENDANT' },
];

export const getPermissionsProfileLabel = (value: PROFILE_TYPE) => {
  const status = permissionsProfileTypesLabel.find(
    (status) => status.value === value
  );

  if (status) return status.label;

  return value;
};
