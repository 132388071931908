import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select as MuiSelect,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddCommentIcon from '@material-ui/icons/AddComment';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ShortTextIcon from '@material-ui/icons/ShortText';
import { FaImage, FaSignature } from 'react-icons/fa';

import { IErrors } from '../../../utils';
import { QuestionType, QuestionWithAutoFocus } from '../hooks/form';
import { Paper } from '../pages/ChecklistForm/styles';

const Select = withStyles({
  outlined: {
    padding: '8px 2px',
  },
})(MuiSelect);

const QUESTION_TYPES_OPTIONS = [
  {
    icon: CheckBoxIcon,
    label: 'Caixa de Seleção',
    value: QuestionType.CHECKBOX,
    optionIcon: CheckBoxOutlineBlankIcon,
  },
  {
    icon: RadioButtonCheckedIcon,
    label: 'Múltipla Escolha',
    value: QuestionType.RADIO,
    optionIcon: RadioButtonUncheckedIcon,
  },
  {
    icon: ArrowDropDownCircleIcon,
    label: 'Lista de Suspensa',
    value: QuestionType.SELECT,
  },
  {
    icon: ShortTextIcon,
    label: 'Texto',
    value: QuestionType.TEXT,
    optionIcon: ShortTextIcon,
  },
  {
    icon: FaSignature,
    label: 'Assinatura',
    value: QuestionType.SIGNATURE,
    optionIcon: FaSignature,
  },
  {
    icon: FaImage,
    label: 'Imagem',
    value: QuestionType.IMAGE,
    optionIcon: FaImage,
  },
];

interface IQuestionProps {
  errors: IErrors;
  question: QuestionWithAutoFocus;
  onClearError(name: string): void;
  onChange(question: QuestionWithAutoFocus): void;
  onAddNewOption(questionOrder: number): void;
  onDeleteOption(questionOrder: number, optionIndex: number): void;
  onDuplicate(questionOrder: number): void;
  onDelete(deletedQuestion: QuestionWithAutoFocus): void;
}

export const QuestionForm = ({
  errors,
  question,
  onClearError,
  onChange,
  onAddNewOption,
  onDeleteOption,
  onDuplicate,
  onDelete,
}: IQuestionProps) => {
  const [optionFocus, setOptionFocus] = useState(-1);

  const questionIndex = question.order - 1;
  const nextOptionIndex = question.options.length + 1;

  const optionType = QUESTION_TYPES_OPTIONS.find(
    (type) => type.value === question.type
  );

  const OptionIcon =
    optionType && optionType.optionIcon ? optionType.optionIcon : null;

  function renderSelectValue(value: unknown) {
    const { icon: Icon, label } = QUESTION_TYPES_OPTIONS.find(
      (type) => type.value === value
    )!;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          marginLeft: 14,
        }}
      >
        <Icon size={24} />
        {label}
      </div>
    );
  }

  function handleSwitchChange(checked: boolean) {
    onChange({ ...question, required: checked });
  }

  function handleSubjectChange(value: string) {
    onClearError(`questions[${questionIndex}].subject`);
    onChange({ ...question, subject: value });
  }

  function handleSelectChange(value: QuestionType) {
    let options = [...question.options];
    let hasObservation = question.hasObservation;

    if (
      [QuestionType.TEXT, QuestionType.SIGNATURE, QuestionType.IMAGE].includes(
        value
      )
    ) {
      options = [];
      if (hasObservation) {
        hasObservation = false;
      }

      setOptionFocus(-1);
    } else if (options.length === 0) {
      options = [''];
    }

    onChange({ ...question, type: value, options, hasObservation });
  }

  function handleAddNewOption() {
    setOptionFocus(nextOptionIndex);
    onAddNewOption(question.order);
  }

  function handleObservationSwitch() {
    onChange({ ...question, hasObservation: !question.hasObservation });
  }

  const handleOptionChange = useCallback(
    (value: string, optionIndex: number) => {
      const options = [...question.options];

      options[optionIndex] = value;
      onClearError(`questions[${questionIndex}].options[${optionIndex}]`);
      onChange({ ...question, options });
    },
    [onChange, onClearError, question, questionIndex]
  );

  return (
    <Paper>
      <FormControlLabel
        label="Obrigatória"
        labelPlacement="start"
        control={
          <Switch
            color="primary"
            checked={question.required}
            onChange={(_event, checked) => handleSwitchChange(checked)}
          />
        }
        style={{ margin: 0 }}
      />

      <Box style={{ display: 'flex', gap: 16, marginBottom: 16 }}>
        <TextField
          fullWidth
          autoFocus={question.autoFocus}
          name="subject"
          placeholder="Pergunta"
          value={question.subject}
          onChange={(event) => handleSubjectChange(event.target.value)}
          error={Boolean(errors[`questions[${questionIndex}].subject`])}
          helperText={errors[`questions[${questionIndex}].subject`]}
        />

        <Select
          variant="outlined"
          value={question.type}
          onChange={(event) =>
            handleSelectChange(event.target.value as QuestionType)
          }
          renderValue={renderSelectValue}
          inputProps={{ style: { padding: '4px 2px' } }}
          style={{ width: '70%' }}
        >
          {QUESTION_TYPES_OPTIONS.map(({ icon: Icon, ...type }, index) => (
            <MenuItem
              key={`${index}-${type.value}`}
              value={type.value}
              style={{
                display: 'flex',
                gap: 8,
              }}
            >
              <Icon size={24} /> {type.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 16,
          marginBottom: 40,
        }}
      >
        {![QuestionType.TEXT, QuestionType.SIGNATURE].includes(
          question.type
        ) ? (
          <>
            {![QuestionType.IMAGE].includes(question.type) ? (
              <>
                {question.options.map((option, index) => {
                  const optionNumber = index + 1;

                  return (
                    <div
                      key={`option-${index}`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      {OptionIcon ? <OptionIcon /> : `${optionNumber}.`}

                      <TextField
                        autoFocus={optionFocus === optionNumber}
                        placeholder={`Opção ${optionNumber}`}
                        value={option}
                        onChange={(event) =>
                          handleOptionChange(event.target.value, index)
                        }
                        error={Boolean(
                          errors[
                            `questions[${questionIndex}].options[${index}]`
                          ]
                        )}
                        helperText={
                          errors[
                            `questions[${questionIndex}].options[${index}]`
                          ]
                        }
                      />

                      {question.options.length > 1 && (
                        <Tooltip title="Excluir" placement="top">
                          <IconButton
                            onClick={() =>
                              onDeleteOption(question.order, index)
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}

                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  {OptionIcon ? <OptionIcon /> : `${nextOptionIndex}.`}

                  <Button
                    variant="text"
                    type="button"
                    onClick={handleAddNewOption}
                  >
                    Adicionar opção
                  </Button>
                </div>
              </>
            ) : (
              <Box
                sx={{
                  border: '3px dashed #ddd',
                  padding: '2.5rem',
                  lineHeight: 0,
                }}
              >
                <FaImage size="3rem" />
              </Box>
            )}

            {question.hasObservation ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <TextField
                  placeholder="Observação"
                  disabled
                  // style={{ maxWidth: '40%' }}
                />

                <Tooltip title="Remover observação" placement="top">
                  <IconButton size="small" onClick={handleObservationSwitch}>
                    <SpeakerNotesOffIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <Button
                color="secondary"
                variant="text"
                type="button"
                onClick={handleObservationSwitch}
                startIcon={<AddCommentIcon />}
              >
                Adicionar campo observação
              </Button>
            )}
          </>
        ) : (
          <TextField
            fullWidth
            placeholder={
              question.type === QuestionType.TEXT
                ? 'Texto de resposta'
                : 'Assinatura'
            }
            disabled
            style={{ maxWidth: '40%' }}
          />
        )}
      </Box>

      <Box
        style={{
          position: 'absolute',
          right: 8,
          bottom: 8,
        }}
      >
        <Tooltip title="Duplicar" placement="top">
          <IconButton onClick={() => onDuplicate(question.order)}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Excluir" placement="top">
          <IconButton onClick={() => onDelete(question)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  );
};
