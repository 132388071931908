import React, { useCallback, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@material-ui/core';
import { Main } from '../../../../components/Main';

import logoNuvemShop from '../../../../assets/NuvemShop.png';
import logoML from '../../../../assets/ML.png';
import logoViaVarejo from '../../../../assets/ViaVarejo.png';
import logoB2W from '../../../../assets/B2W.png';
import logoMaplink from '../../../../assets/MAPLINK.png';
import logoOpenMaps from '../../../../assets/OPENMAPS.png';
import logoGoogleMaps from '../../../../assets/GOOGLEMAPS.png';
import logoTomtom from '../../../../assets/TOMTOM.png';
import logoGeoapify from '../../../../assets/GEOAPIFY.png';
import logoRastreMais from '../../../../assets/RASTREMAIS.png';
import logoSascar from '../../../../assets/SASCAR.png';
import logo4innovation from '../../../../assets/logo-black.png';
import logoScandit from '../../../../assets/SCANDIT.png';
import logoVipp from '../../../../assets/VIPP.png';
import logoBsoft from '../../../../assets/BSOFT.png';
import logoTotvs from '../../../../assets/TOTVS.png';

import { useStyles } from './styles';

import { Nuvemshop } from '../../components/Nuvemshop';
import { Maplink } from '../../components/Maplink';
import { OpenMaps } from '../../components/OpenMaps';
import { GoogleMaps } from '../../components/GoogleMaps';
import { Tomtom } from '../../components/Tomtom';
import { Geoapify } from '../../components/Geoapify';
import { RastreMais } from '../../components/RastreMais';
import { Sascar } from '../../components/Sascar';
import { Scandit } from '../../components/Scandit';
import { Vipp } from '../../components/Vipp';
import { Bsoft } from '../../components/Bsoft';
import { Totvs } from '../../components/Totvs';

export const Integration = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openNuvemshop, setOpenNuvemshop] = useState(false);
  const [openMaplink, setOpenMaplink] = useState(false);
  const [openOpenMaps, setOpenOpenMaps] = useState(false);
  const [openGoogleMaps, setOpenGoogleMaps] = useState(false);
  const [openTomtom, setOpenTomtom] = useState(false);
  const [openGeoapify, setOpenGeoapify] = useState(false);
  const [openRastreMais, setOpenRastreMais] = useState(false);
  const [openSascar, setOpenSascar] = useState(false);
  const [openScandit, setOpenScandit] = useState(false);
  const [openVipp, setOpenVipp] = useState(false);
  const [openBsoft, setOpenBsoft] = useState(false);
  const [openTotvs, setOpenTotvs] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleOpenNuvemshop = useCallback(() => {
    setOpenNuvemshop(true);
  }, []);

  const handleCloseNuvemshop = useCallback(() => {
    setOpenNuvemshop(false);
  }, []);

  const handleOpenMaplink = useCallback(() => {
    setOpenMaplink(true);
  }, []);

  const handleCloseMaplink = useCallback(() => {
    setOpenMaplink(false);
  }, []);

  const handleOpenOpenMaps = useCallback(() => {
    setOpenOpenMaps(true);
  }, []);

  const handleCloseOpenMaps = useCallback(() => {
    setOpenOpenMaps(false);
  }, []);

  const handleOpenGoogleMaps = useCallback(() => {
    setOpenGoogleMaps(true);
  }, []);

  const handleCloseGoogleMaps = useCallback(() => {
    setOpenGoogleMaps(false);
  }, []);

  const handleOpenTomtom = useCallback(() => {
    setOpenTomtom(true);
  }, []);

  const handleCloseTomtom = useCallback(() => {
    setOpenTomtom(false);
  }, []);

  const handleOpenGeoapify = useCallback(() => {
    setOpenGeoapify(true);
  }, []);

  const handleCloseGeoapify = useCallback(() => {
    setOpenGeoapify(false);
  }, []);

  const handleOpenRastreMais = useCallback(() => {
    setOpenRastreMais(true);
  }, []);

  const handleCloseRastreMais = useCallback(() => {
    setOpenRastreMais(false);
  }, []);

  const handleOpenSascar = useCallback(() => {
    setOpenSascar(true);
  }, []);

  const handleCloseSascar = useCallback(() => {
    setOpenSascar(false);
  }, []);

  const handleOpenScandit = useCallback(() => {
    setOpenScandit(true);
  }, []);

  const handleCloseScandit = useCallback(() => {
    setOpenScandit(false);
  }, []);

  const handleOpenVipp = useCallback(() => {
    setOpenVipp(true);
  }, []);

  const handleCloseVipp = useCallback(() => {
    setOpenVipp(false);
  }, []);

  const handleOpenBsoft = useCallback(() => {
    setOpenBsoft(true);
  }, []);

  const handleOpenTotvs = useCallback(() => {
    setOpenTotvs(true);
  }, []);

  const handleCloseBsoft = useCallback(() => {
    setOpenBsoft(false);
  }, []);

  const handleCloseTotvs = useCallback(() => {
    setOpenTotvs(false);
  }, []);

  return (
    <Main>
      <Nuvemshop open={openNuvemshop} onClose={handleCloseNuvemshop} />

      <Maplink open={openMaplink} onClose={handleCloseMaplink} />

      <OpenMaps open={openOpenMaps} onClose={handleCloseOpenMaps} />

      <GoogleMaps open={openGoogleMaps} onClose={handleCloseGoogleMaps} />

      <Tomtom open={openTomtom} onClose={handleCloseTomtom} />

      <Geoapify open={openGeoapify} onClose={handleCloseGeoapify} />

      <RastreMais open={openRastreMais} onClose={handleCloseRastreMais} />

      <Sascar open={openSascar} onClose={handleCloseSascar} />

      <Scandit open={openScandit} onClose={handleCloseScandit} />

      <Vipp open={openVipp} onClose={handleCloseVipp} />

      <Bsoft open={openBsoft} onClose={handleCloseBsoft} />

      <Totvs open={openTotvs} onClose={handleCloseTotvs} />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">Não habilitado</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h6">Loja Virtual</Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoNuvemShop}
            alt="NUVEMSHOP"
            className={classes.img}
            onClick={handleOpenNuvemshop}
          />
        </Grid>
      </Grid>

      <Typography variant="h6">Marketplace</Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoML}
            alt="MERCADO LIVRE"
            className={classes.img}
            onClick={handleOpenDialog}
          />
        </Grid>

        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoViaVarejo}
            alt="VIAVAREJO"
            className={classes.img}
            onClick={handleOpenDialog}
          />
        </Grid>

        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoB2W}
            alt="B2W"
            className={classes.img}
            onClick={handleOpenDialog}
          />
        </Grid>
      </Grid>

      <Typography variant="h6">Broker Geocode / Roteirização</Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoMaplink}
            alt="MAPLINK"
            className={classes.img}
            onClick={handleOpenMaplink}
          />
        </Grid>

        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoOpenMaps}
            alt="OPENMAPS"
            className={classes.img}
            onClick={handleOpenOpenMaps}
          />
        </Grid>

        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoGoogleMaps}
            alt="GOOGLEMAPS"
            className={classes.img}
            onClick={handleOpenGoogleMaps}
          />
        </Grid>

        <Grid item xs={3} className={classes.grid}>
          <img
            src={logoTomtom}
            alt="TOMTOM"
            className={classes.img}
            onClick={handleOpenTomtom}
          />
        </Grid>

        <Grid item xs={3} className={classes.grid}>
          <img
            src={logoGeoapify}
            alt="GEOAPIFY"
            className={classes.img}
            onClick={handleOpenGeoapify}
          />
        </Grid>
      </Grid>

      <Typography variant="h6">Rastreadores</Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={3} className={classes.grid}>
          <img
            src={logoRastreMais}
            alt="RASTREMAIS"
            className={classes.img}
            onClick={handleOpenRastreMais}
          />
        </Grid>

        <Grid item xs={3} className={classes.grid}>
          <img
            src={logoSascar}
            alt="SASCAR"
            className={classes.img}
            onClick={handleOpenSascar}
          />
        </Grid>

        <Grid item xs={3} className={classes.grid}>
          <img
            src={logo4innovation}
            alt="4INNOVATION"
            className={classes.img}
            onClick={handleOpenDialog}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" style={{ font: 'bold' }}>
        Realidade Aumentada
      </Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={3} className={classes.grid}>
          <img
            src={logoScandit}
            alt="SCANDIT"
            className={classes.img}
            onClick={handleOpenScandit}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" style={{ font: 'bold' }}>
        Correios
      </Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={4} className={classes.grid}>
          <img
            src={logoVipp}
            alt="VIPP"
            className={classes.img}
            onClick={handleOpenVipp}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" style={{ font: 'bold' }}>
        ERP
      </Typography>

      <Divider />

      <Grid container alignContent="center" justifyContent="flex-start">
        <Grid item xs={3} className={classes.grid}>
          <img
            src={logoBsoft}
            alt="BSOFT"
            className={classes.img}
            onClick={handleOpenBsoft}
          />
        </Grid>

        <Grid item xs={2} className={classes.grid}>
          <img
            src={logoTotvs}
            alt="TOTVS"
            className={classes.img}
            onClick={handleOpenTotvs}
          />
        </Grid>
      </Grid>
    </Main>
  );
};
