import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format, subDays } from 'date-fns';
import {
  Button,
  Grid,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';

import { maskPostalcode, orderStatusCodeLabel } from '../../../../utils';

import { useOrderRFID, IOrderRFID } from '../../hooks/orderRFID';

import { generateOrderRfidPDF } from '../../templates/orderRfidReport';
import XLSX from 'xlsx';

export interface IOrderRfidReport extends Omit<IOrderRFID, 'statusCode'> {
  statusCode: string;
}

export const OrderRFIDReport = () => {
  const { filter, setFilter, loadOrderRFID } = useOrderRFID();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderRFID, setOrderRFID] = useState<IOrderRfidReport[]>([]);

  const columnsTable: IBasicColumn[] = [
    { name: 'Dt. Pedido' },
    { name: 'Núm. Pedido' },
    { name: 'Remetente' },
    { name: 'Dt. Prev. Entrega' },
    { name: 'Nome Cliente' },
    { name: 'Município entrega' },
    { name: 'Status Ped.' },
  ];

  const validationSchema = yup.object({
    initialDate: yup.date().required('Campo obrigatório!'),
    finalDate: yup
      .date()
      .required('Campo obrigatório!')
      .min(
        yup.ref('initialDate'),
        'Data fim não pode ser menor que a data início'
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filter,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  useEffect(() => {
    async function loadOrderRFIDReport(): Promise<void> {
      setLoading(true);
      try {
        const response = await loadOrderRFID(filter);

        if (!response) return;

        const newData = response.map((data) => {
          return {
            ...data,
            orderDate: format(new Date(data.orderDate), 'dd/MM/yyyy'),
            deliveryPrevisionDate: format(
              new Date(data.deliveryPrevisionDate),
              'dd/MM/yyyy'
            ),
            datePortalRelease: data.datePortalRelease
              ? format(new Date(data.datePortalRelease), 'dd/MM/yyyy HH:mm')
              : '',
            dateMarkAsDelivered: data.dateMarkAsDelivered
              ? format(new Date(data.dateMarkAsDelivered), 'dd/MM/yyyy HH:mm')
              : '',
            statusCode: orderStatusCodeLabel(data.statusCode),
            shippingAddress: {
              ...data.shippingAddress,
              postalcode: maskPostalcode(data.shippingAddress.postalcode),
            },
          };
        });

        setOrderRFID(newData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadOrderRFIDReport();
  }, [filter, loadOrderRFID]);

  const handleReset = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleReset(e);
      setFilter({
        initialDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
        finalDate: format(new Date(), 'yyyy-MM-dd'),
        orderNumber: '',
      });
    },
    [formik, setFilter]
  );

  const downloadExcel = useCallback((orderRFID: IOrderRfidReport[]) => {
    // eslint-disable-next-line array-callback-return
    const newData = orderRFID.flatMap((data) =>
      data.items.map((item) => {
        return {
          orderDate: data.orderDate,
          number: data.number,
          senderName: data.sender.name,
          deliveryPrevisionDate: data.deliveryPrevisionDate,
          itemsDescription: item.description,
          itemsCode: item.code,
          itemsCodeTranslate: item.codeTranslate,
          customerName: data.customer.name,
          shippingAddressStreet: data.shippingAddress.street,
          shippingAddressNeighborhood: data.shippingAddress.neighborhood,
          shippingAddressCity: data.shippingAddress.city,
          shippingAddressState: data.shippingAddress.state,
          shippingAddressPostalcode: data.shippingAddress.postalcode,
          datePortalRelease: data.datePortalRelease,
          dateMarkAsDelivered: data.dateMarkAsDelivered,
          statusCode: data.statusCode,
        };
      })
    );

    const header = [
      [
        'Dt. Pedido',
        'Núm. Pedido',
        'Remetente',
        'Dt. Prev. Entrega',
        'Desc. Itens do pedido',
        'Núm. TAG',
        'Núm. TAG Traduzido',
        'Nome Cliente',
        'Endereço de entrega',
        'Bairro entrega',
        'Município entrega',
        'UF entrega',
        'CEP',
        'Dt. Hora lib. Antena',
        'Dt. concl. pedido',
        'Status Ped.',
      ],
    ];

    const workSheetCols = [
      { width: 10 },
      { width: 12 },
      { width: 20 },
      { width: 15 },
      { width: 26 },
      { width: 25 },
      { width: 25 },
      { width: 57 },
      { width: 31 },
      { width: 20 },
      { width: 26 },
      { width: 10 },
      { width: 10 },
      { width: 17 },
      { width: 15 },
      { width: 10 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PEDIDOS RFID');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Pedidos RFID.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Pedidos RFID">
      <Grid container spacing={1}>
        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => generateOrderRfidPDF(orderRFID)}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => downloadExcel(orderRFID)}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1" id="filter">
              Filtros
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data início"
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={formik.values.initialDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.initialDate &&
                    Boolean(formik.errors.initialDate)
                  }
                  helperText={
                    formik.touched.initialDate && formik.errors.initialDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data fim"
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={formik.values.finalDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.finalDate && Boolean(formik.errors.finalDate)
                  }
                  helperText={
                    formik.touched.finalDate && formik.errors.finalDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Número do pedido"
                  id="orderNumber"
                  name="orderNumber"
                  value={formik.values.orderNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.orderNumber &&
                    Boolean(formik.errors.orderNumber)
                  }
                  helperText={
                    formik.touched.orderNumber && formik.errors.orderNumber
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => formik.handleSubmit()}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleReset}>Limpar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={orderRFID.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {orderRFID
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data) => (
                <TableRow key={data.id}>
                  <TableCell>{data.orderDate}</TableCell>
                  <TableCell>{data.number}</TableCell>
                  <TableCell>{data.sender.name}</TableCell>
                  <TableCell>{data.deliveryPrevisionDate}</TableCell>
                  <TableCell>{data.customer.name}</TableCell>
                  <TableCell>{data.shippingAddress.city}</TableCell>
                  <TableCell>{data.statusCode}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
