import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IVippRequest {
  userViPP: string;
  passwordViPP: string;
  idPerfil: string;
  codigoPac: string;
  codigoSedex: string;
}

export interface ILoadVipp {
  userViPP: string;
  passwordViPP: string;
  idPerfil: string;
  codigoPac: string;
  codigoSedex: string;
}

interface IVippContext {
  loadVipp(): Promise<ILoadVipp | undefined>;
  saveVipp(data: IVippRequest): Promise<void>;
}

const VippContext = createContext<IVippContext>({} as IVippContext);

const VippProvider: React.FC = ({ children }) => {
  const loadVipp = useCallback(async () => {
    const response = await api.get('/integrations/vipp/config');

    return response.data;
  }, []);

  const saveVipp = useCallback(async (data: IVippRequest) => {
    await api.post('/integrations/vipp/config', data);
  }, []);

  return (
    <VippContext.Provider
      value={{
        loadVipp,
        saveVipp,
      }}
    >
      {children}
    </VippContext.Provider>
  );
};

function useVipp(): IVippContext {
  const context = useContext(VippContext);

  if (!context) {
    throw new Error('useVipp must be used within a VippProvider');
  }

  return context;
}

export { VippProvider, useVipp };
