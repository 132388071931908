import { useCallback, useState } from 'react';
import { lighten } from 'polished';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  components,
  GroupBase,
  ActionMeta,
  InputProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import AsyncCreatableSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable';

import { unmask, maskCnpj, maskCpf } from '../../utils';

export interface IOption {
  value: string;
  label: string;
  inputValue?: string;
  otherProps?: {
    [key: string]: any;
  };
}

function maskedDocumentNumber(documentNumber: string): string {
  const maskedDocumentNumber =
    unmask(documentNumber).length > 11
      ? maskCnpj(documentNumber)
      : maskCpf(documentNumber);

  return maskedDocumentNumber;
}

function onlyNumbers(value: string): boolean {
  return unmask(value).length === value.replace(/\D/g, '').length;
}

function SingleValue({ ...props }: SingleValueProps<IOption, false>) {
  const value = props.data.value;

  return (
    <components.SingleValue {...props}>
      {onlyNumbers(value) ? maskedDocumentNumber(value) : value}
    </components.SingleValue>
  );
}

function Input({ value, ...props }: InputProps<IOption, false>) {
  const newValue = value ? value.toString() : '';

  return (
    <components.Input
      {...props}
      value={onlyNumbers(newValue) ? maskedDocumentNumber(newValue) : newValue}
    />
  );
}

interface IProps
  extends AsyncCreatableProps<IOption, false, GroupBase<IOption>> {
  name: string;
  startValue?: string;
  error?: string;
  searchFunction(search: string): Promise<any[]>;
  setValue: (field: string, value: any) => void;
  actionOnChange?: (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => void;
}

export const AsyncSelect = ({
  startValue,
  error,
  searchFunction,
  setValue,
  actionOnChange,
  ...props
}: IProps) => {
  const theme = useTheme();
  const [hasDelay, setHasDelay] = useState<NodeJS.Timeout | null>(null);

  // function instanceOfSingleValueType(object: any):
  //   object is SingleValueType<IOption> {
  //   return 'value' in object;
  // }

  const customStyles: StylesConfig<IOption, false, GroupBase<IOption>> = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderColor: error ? theme.palette.error.main : styles.borderColor,
      boxShadow:
        isFocused && error
          ? `0 0 0 1px ${theme.palette.error.main}`
          : styles.boxShadow,
      ':active': {
        ...styles[':active'],
        boxShadow: error
          ? `0 0 0 1px ${theme.palette.error.main}`
          : styles[':active']?.boxShadow,
      },
      ':hover': {
        ...styles[':hover'],
        borderColor: error
          ? theme.palette.error.main
          : styles[':hover']?.borderColor,
      },
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
  };

  const filter = useCallback(
    async (inputValue: string) => {
      let newOptions: IOption[] = [];
      try {
        const response = await searchFunction(
          inputValue ? unmask(inputValue) : ''
        );

        response.forEach((value) => {
          newOptions.push({
            value: value.documentNumber,
            label: `${maskedDocumentNumber(value.documentNumber)} - ${
              value.name
            }`,
            otherProps: value,
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        return newOptions.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
    },
    [searchFunction]
  );

  const promiseOptions = useCallback(
    (inputValue: string) =>
      new Promise<IOption[]>((resolve) => {
        if (hasDelay) clearTimeout(hasDelay);

        const newTimeout = setTimeout(() => {
          resolve(filter(inputValue));
        }, 1000);
        setHasDelay(newTimeout);
      }),
    [hasDelay, filter]
  );

  const onChange = (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    // if (option) { if (actionMeta.action === 'select-option') {} else if (actionMeta.action === 'create-option') {} } else {};
    if (actionOnChange) actionOnChange(option, actionMeta);

    setValue(
      props.name,
      option
        ? actionMeta.action === 'select-option'
          ? option.value
          : unmask(option.value)
        : ''
    );
  };

  return (
    <div>
      <AsyncCreatableSelect
        styles={customStyles}
        theme={(defaultTheme) => ({
          ...defaultTheme,
          colors: {
            ...defaultTheme.colors,
            primary: theme.palette.primary.main,
            primary25: lighten(0.7, theme.palette.primary.main),
            primary50: lighten(0.7, theme.palette.primary.main),
            primary75: lighten(0.7, theme.palette.primary.main),
          },
        })}
        isClearable
        defaultValue={
          startValue ? { label: startValue, value: startValue } : undefined
        }
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
        onChange={onChange}
        placeholder="Selecione..."
        loadingMessage={() => 'Carregando ...'}
        noOptionsMessage={() => 'Nenhuma opção'}
        formatCreateLabel={(inputValue: string) => `Novo '${inputValue}'`}
        components={{ SingleValue, Input }}
        {...props}
      />

      {error && (
        <FormControl error>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
};
