import { useHistory } from 'react-router-dom';

import {
  Dialog,
  List,
  ListItem,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import { ICompanyDefault } from '../../hooks/auth';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { ROUTES } from '../../constants';

interface IProps {
  open: boolean;
  companyDefault: ICompanyDefault;
  onClose: () => void;
}

export const CompanyDefault: React.FC<IProps> = ({
  open,
  companyDefault,
  onClose,
}) => {
  const history = useHistory();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      aria-describedby="dialog-description"
    >
      <List>
        <ListItem>
          <Grid container alignItems="center">
            {companyDefault.hasVehicle ? (
              <CheckCircleIcon
                style={{ color: 'green', marginRight: '10px' }}
              />
            ) : (
              <CancelIcon style={{ color: 'red', marginRight: '10px' }} />
            )}

            <Typography variant="h6" style={{ font: 'bold' }}>
              Veículo
            </Typography>
          </Grid>

          {companyDefault.hasVehicle === false && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(ROUTES.createVehicle);
                onClose();
              }}
            >
              Cadastrar
            </Button>
          )}
        </ListItem>

        <ListItem>
          <Grid container alignItems="center">
            {companyDefault.hasVehicleType ? (
              <CheckCircleIcon
                style={{ color: 'green', marginRight: '10px' }}
              />
            ) : (
              <CancelIcon style={{ color: 'red', marginRight: '10px' }} />
            )}

            <Typography variant="h6" style={{ font: 'bold' }}>
              Tipos de Veículo
            </Typography>
          </Grid>

          {companyDefault.hasVehicleType === false && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(ROUTES.createVehicleType);
                onClose();
              }}
            >
              Cadastrar
            </Button>
          )}
        </ListItem>

        <ListItem>
          <Grid container alignItems="center">
            {companyDefault.hasDistributionCenter ? (
              <CheckCircleIcon
                style={{ color: 'green', marginRight: '10px' }}
              />
            ) : (
              <CancelIcon style={{ color: 'red', marginRight: '10px' }} />
            )}

            <Typography variant="h6" style={{ font: 'bold' }}>
              Centro de Distribuição
            </Typography>
          </Grid>

          {companyDefault.hasDistributionCenter === false && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(ROUTES.createDistributionCenter);
                onClose();
              }}
            >
              Cadastrar
            </Button>
          )}
        </ListItem>

        <ListItem>
          <Grid container alignItems="center">
            {companyDefault.hasPlanningConfig ? (
              <CheckCircleIcon
                style={{ color: 'green', marginRight: '10px' }}
              />
            ) : (
              <CancelIcon style={{ color: 'red', marginRight: '10px' }} />
            )}

            <Typography variant="h6" style={{ font: 'bold' }}>
              Configuração de Planejamento
            </Typography>
          </Grid>

          {companyDefault.hasPlanningConfig === false && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                history.push(ROUTES.createRoutePlanning);
                onClose();
              }}
            >
              Cadastrar
            </Button>
          )}
        </ListItem>
      </List>
    </Dialog>
  );
};
