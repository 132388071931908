import { Button } from '@material-ui/core';
import { ModalActions, ModalTitle } from '../../../../components/Modal';

interface IConfirmationModal {
  title: string;
  close: () => void;
  onSubmit: () => void;
}

export const ConfirmationModal = (props: IConfirmationModal) => {
  const handleSubmit = () => {
    props.onSubmit();
    props.close();
  };

  return (
    <>
      <ModalTitle>{props.title}</ModalTitle>

      <ModalActions>
        <Button onClick={props.close} color="inherit">
          Fechar
        </Button>

        <Button onClick={handleSubmit} color="secondary">
          Confirmar
        </Button>
      </ModalActions>
    </>
  );
};
