import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import api from '../../../services/api';
import {
  OrderStatusCode,
  RouteStatus,
  ActivityStatus,
  RegionStatus,
  ShippingStatus,
  PaymentStatus,
  ConferenceStatus,
} from '../../../constants';
import { useSettings } from '../../../hooks/settings';
import { IRouteActivityReport } from '../../route/hooks/activity';
import { INps } from '../../nps/hooks/nps';

export enum OrderImageTypeEnum {
  DELIVERED = 'delivered',
  OCCURRENCE = 'occurrence',
  CANCELLED = 'cancelled-delivery',
  CHECKING_COPY = 'checking-copy',
  GENERIC = 'generic',
}

export interface IFullOrder {
  id: number;
  operationType: 'delivery' | 'collect';
  identification: string;
  orderCode: string;
  orderDate: string;
  deliveredDistance: number | null;
  deliveredAddress: string | null;
  deliveredMap: string | null;
  deliveryPrevisionDate: string;
  companyDocument: string;
  cancelReason: string;
  currency: string;
  gateway: string;
  name: string;
  number: string;
  paymentStatus: string;
  status: string;
  shippingCost: number;
  transferFreight: number;
  subtotal: number;
  discount: number;
  value: number;
  weight: string;
  shippedAt: Date;
  shippingStatus: string;
  statusCode: string;
  marketplace: string;
  shippingType: string;
  shippingOption: string;
  tracking: string;
  userMarkAsPackaged: boolean;
  dateMarkAsPackaged: boolean;
  userMarkAsSent: boolean;
  dateMarkAsSent: boolean;
  userMarkAsDelivered: boolean;
  dateMarkAsDelivered: boolean;
  observation: string;
  distributionCenterId: number;
  xmlNFe: string;
  chaveCTe: string;
  hasDanfeNFe: boolean;
  hasDanfeCTe: boolean;
  quantityVolume: number;
  riskArea: boolean;
  changedToLastExecution: boolean;
  changedToLastExecutionDescription: string;
  operationTime: number;
  deliveryTime: number;
  totalOperatingTime: number;
  customer: {
    id: number;
    documentNumber: string;
    name: string;
    email: string;
    phone: string;
    contact: string;
    cellphone: string;
    addresses: {
      id: number;
      street: string;
      city: string;
      country: string;
      complement: string;
      neighborhood: string;
      number: string;
      phone: string;
      state: string;
      postalcode: string;
      type: string;
      lat: string;
      long: string;
      region: string;
    }[];
  };
  shippingAddress: {
    id: number;
    street: string;
    city: string;
    country: string;
    complement: string;
    neighborhood: string;
    number: string;
    state: string;
    postalcode: string;
    lat: string | null;
    long: string | null;
    region: string;
    landmark: string | null;
  };
  items: {
    id: number;
    description: string;
    depth: string;
    height: string;
    price: string;
    productId: number;
    sku: string;
    barcode: string;
    quantity: number;
    freeShipping: true;
    variantId: number;
    weight: string;
    width: string;
    code: string;
    isConfirmed: boolean;
  }[];
  sender: {
    id: number;
    documentNumber: string;
    name: string;
    email: string;
    phone: string;
    cellphone: string;
    addresses: {
      id: number;
      street: string;
      city: string;
      country: string;
      complement: string;
      neighborhood: string;
      number: string;
      phone: string;
      state: string;
      postalcode: string;
      type: string;
      lat: string;
      long: string;
      region: string;
    }[];
  };
  createdAt: string;
}

export type OperationType = 'delivery' | 'collect';
export type NpsType = 'promoter' | 'neutral' | 'detrator';

export interface ICustomer {
  id: number;
  documentNumber: string;
  name: string;
  email: string;
  phone: string;
  cellphone: string;
}

export interface IShippingAddress {
  id: number;
  street: string;
  city: string;
  country: string;
  complement: string;
  neighborhood: string;
  number: string;
  state: string;
  postalcode: string;
  lat: string;
  long: string;
  region: RegionStatus;
}

export interface IItems {
  id: number;
  description: string;
  depth: string;
  height: string;
  price: string;
  productId: number;
  sku: string;
  code: string;
  barcode: string;
  quantity: number;
  freeShipping: boolean;
  variantId: number;
  weight: string;
  width: string;
  isChecked: boolean;
  wasReferenced: boolean;
  isConfirmed: boolean;
  images?: {
    id: number;
    url: string;
    base64: string;
    position: number;
  }[];
}

interface IDriver {
  id: number;
  driverId: number;
  driverName: string;
  driverDoc: string;
  driverEmail: string;
  driverCellphone: string;
}

interface IRoute {
  id: number;
  companyDocument: string;
  routerName: string;
  startRouteDate: string;
  endRouteDate: string;
  status: RouteStatus;
  responsibleName: string;
  responseDocument: string;
  responseSignature: string;
  returnToDistributionCenter: boolean;
  mapLineColor: string;
  planningConfigId: number;
  totalTime: number;
  totalDistance: number;
  indicators: string;
  driver: IDriver;
}

export interface IActivity {
  id: number;
  orderCode: string;
  number: string;
  ordem: number;
  open: boolean;
  type: number;
  infoType: number;
  quantityBips: number;
  quantityItens: number;
  prevArriveDate: string;
  prevArriveHour: string;
  prevDepartureDate: string;
  prevDepartureHour: string;
  status: ActivityStatus;
  responsibleName: string;
  responseDocument: string;
  responseSignature: string;
  reason: string;
  latFinishing: string;
  longFinishing: string;
  route: IRoute;
  images: Array<{
    id: number;
    image: string;
  }>;
}

export interface IOrder {
  id: number;
  identification: string;
  orderCode: string;
  orderDate: string;
  referenceCode: string | null;
  companyDocument: string;
  cancelReason: string;
  currency: string;
  gateway: string;
  name: string;
  number: string;
  paymentStatus: PaymentStatus;
  status: string;
  shippingCost: number;
  subtotal: number;
  discount: number;
  value: number;
  weight: string;
  shippedAt: string;
  shippingStatus: ShippingStatus;
  statusCode: OrderStatusCode;
  marketplace: string;
  conferenceStatus: ConferenceStatus;
  customer: ICustomer;
  shippingAddress: IShippingAddress;
  items: IItems[];
  activity: IActivity[];
  shippingType: string;
  userMarkAsPackaged: string;
  dateMarkAsPackaged: Date;
  userMarkAsSent: string;
  dateMarkAsSent: Date;
  userMarkAsDelivered: string;
  dateMarkAsDelivered: Date;
  userMarkAsCancelled: string;
  dateMarkAsCancelled: Date;
  observation: string;
  operationType: OperationType;
  occurrence: boolean;
  occurrenceDescription: string | null;
  deliveredDistance: number | null;
  nps?: INps;
}

export interface IOrderPaginatedLoad {
  orders: IOrder[];
  total: number;
}

export interface IOrderDatesLoad {
  total: number;
  result: {
    date: string;
    quantity: string;
  }[];
}

export interface IDistributionCenterLoad {
  result: Array<{
    distributionCenterId: number;
    name: string;
    quantity: string;
  }>;
}

export interface IImportOrder {
  orderCode: string;
  companyDocument: number;
}

export type ImportXML = { xmlsNFe: string[] };

export interface IShippingOption {
  shippingOption: string;
}

export interface IOrderTag {
  orderNumber: string;
  tagJson: string;
  tagCode: string;
  contractNumber: string;
}

interface IOrderFilter {
  number: string;
  customerName: string;
  region: string;
  statusCode: string;
  shippingStatus: string;
  initialDate: string;
  finalDate: string;
  shippingOption: string;
  operationType: string;
  product: string;
  isOccurrence: boolean;
  npsType: string;
}

export interface IOrderImage {
  filename: string;
  mimeType: string;
  base64: string;
  type: OrderImageTypeEnum;
}

export interface IPortalImage {
  id: number;
  signedUrl: string;
}

interface IPortalImageFilter {
  orderId?: string;
  orderCode?: string;
  number?: string;
  companyDocument?: string;
}

export type CreateOccurrence = {
  observation: '';
  images: IOrderImage[];
};

interface IOrderContext {
  orderList: IOrderPaginatedLoad;
  setOrderList: React.Dispatch<React.SetStateAction<IOrderPaginatedLoad>>;
  filter: IOrderFilter;
  setFilter: React.Dispatch<React.SetStateAction<IOrderFilter>>;
  reloadOrderInfo: boolean;
  setReloadOrderInfo: React.Dispatch<React.SetStateAction<boolean>>;
  loadOrderById(id: number): Promise<IFullOrder | undefined>;
  loadOrderByOrderCodeAndNumber(
    orderCode: string,
    number: string
  ): Promise<IFullOrder | undefined>;
  loadDaysWithOrders(
    companyDocument: number,
    options?: IOrderFilter
  ): Promise<IOrderDatesLoad>;
  loadDistributionCenter(query?: string): Promise<IDistributionCenterLoad>;
  loadOrderList(
    companyDocument: number,
    date: string,
    page: number,
    take: number,
    query?: string
  ): Promise<IOrderPaginatedLoad>;
  loadOrdersOfMonth(
    companyDocument: number,
    initialDate: string,
    finalDate: string,
    statusCode?: string
  ): Promise<IOrderPaginatedLoad>;
  loadSeparateOrdersOfMonth(
    companyDocument: number,
    initialDate: string,
    finalDate: string,
    shippingStatus: string
  ): Promise<IOrderPaginatedLoad>;
  importXML(data: ImportXML): Promise<void>;
  importSpreadsheet(data: any[]): Promise<void>;
  importOrder(data: IImportOrder): Promise<void>;
  loadShippingOptions(): Promise<IShippingOption[]>;
  loadOrderTag(orderId: number): Promise<IOrderTag>;
  updateOrderTag(orderId: number, data: any): Promise<void>;
  generatePostCode(orderId: number): Promise<boolean>;
  printMailingLabel(orderId: number): Promise<boolean>;
  updateShippingStatus(
    orderId: number,
    status: ShippingStatus,
    data?: IItems[]
  ): Promise<boolean>;
  updateStatusCode(data: {
    orderId: number;
    images: IOrderImage[];
  }): Promise<boolean>;
  changeOperation(orderId: number): Promise<void>;
  changeShippingAddress(id: number, data: any): Promise<void>;
  downloadNFeOrCTe(
    type: 'nfe' | 'cte',
    id: number,
    number: string
  ): Promise<void>;
  uploadNFeOrCTe(
    type: 'nfe' | 'cte',
    id: number,
    companyDocument: number,
    data: FormData
  ): Promise<void>;
  createOccurrence(orderId: number, data: CreateOccurrence): Promise<void>;
  addImages(orderId: string, data: IOrderImage[]): Promise<void>;
  resendOrder(orderId: number): Promise<void>;
  regenerateOrderNFe(orderId: number): Promise<void>;
  cancelOrder(orderId: number): Promise<void>;
  loadPortalSavedImage(options: IPortalImageFilter): Promise<IPortalImage[]>;
  downloadPortalSavedImage(
    query: {
      orderNumber: string;
      orderCode: string;
      imageId: number;
    },
    filename: string
  ): Promise<void>;
  printOrderVolumeLabel(orderId: number, volumes: number): Promise<boolean>;
  getOrderActivityReportById(
    routeId: number,
    orderCode: string,
    orderNumber: string
  ): Promise<IRouteActivityReport>;
}

const OrderContext = createContext<IOrderContext>({} as IOrderContext);

const OrderProvider: React.FC = ({ children }) => {
  const { selectedPeriod, getDatePeriod } = useSettings();
  const { from, to } = getDatePeriod(selectedPeriod);

  const [reloadOrderInfo, setReloadOrderInfo] = useState(false);
  const [filter, setFilter] = useState<IOrderFilter>({
    number: '',
    customerName: '',
    region: '',
    statusCode: '',
    shippingStatus: '',
    initialDate: from,
    finalDate: to,
    shippingOption: '',
    operationType: '',
    product: '',
    npsType: '',
    isOccurrence: false,
  });
  const [orderList, setOrderList] = useState<IOrderPaginatedLoad>({
    orders: [],
    total: 0,
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: from,
      finalDate: to,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  const loadOrderById = useCallback(async (id: number) => {
    const response = await api.get(`/order/by-id/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadOrderByOrderCodeAndNumber = useCallback(
    async (orderCode: string, number: string) => {
      const response = await api.get(
        `/order/by/orderCode/number/${orderCode}/${number}`
      );

      if (response.data) {
        return response.data;
      }

      return undefined;
    },
    []
  );

  const loadDaysWithOrders = useCallback(
    async (companyDocument: number, options?: IOrderFilter) => {
      let query = '';

      if (options) {
        const {
          number,
          customerName,
          region,
          shippingStatus,
          shippingOption,
          statusCode,
          initialDate,
          finalDate,
          operationType,
          product,
          isOccurrence,
          npsType,
        } = options;

        query =
          `?orderNumber${number && `=${number}`}` +
          `${customerName && `&customerName=${customerName}`}` +
          `${region && `&region=${region}`}` +
          `${statusCode && `&statusCode=${statusCode}`}` +
          `${shippingStatus && `&shippingStatus=${shippingStatus}`}` +
          `${shippingOption && `&shippingOption=${shippingOption}`}` +
          `${operationType && `&operationType=${operationType}`}` +
          `${product && `&product=${product}`}` +
          `${initialDate && `&initialDate=${initialDate}`}` +
          `${finalDate && `&finalDate=${finalDate}`}` +
          `${isOccurrence ? `&isOccurrence=1` : ''}` +
          `${npsType ? `&npsType=${npsType}` : ''}`;
      }

      const response = await api.get(
        `/order/orderDates/${companyDocument}${query}`
      );

      return response.data;
    },
    []
  );

  const loadDistributionCenter = useCallback(async (query?: string) => {
    const response = await api.get(
      `/order/distribution-center/quantity${query ? query : ''}`
    );

    return response.data;
  }, []);

  const loadOrderList = useCallback(
    async (
      companyDocument: number,
      date: string,
      page: number,
      take: number,
      query?: string
    ) => {
      const response = await api.get(
        '/order/paginated' +
          `?companyDocument=${companyDocument}` +
          `&initialDate=${date}` +
          `&finalDate=${date}` +
          `&page=${page + 1}` +
          `&take=${take}` +
          `${query ? query : ''}`
      );

      return response.data;
    },
    []
  );

  const loadOrdersOfMonth = useCallback(
    async (
      companyDocument: number,
      initialDate: string,
      finalDate: string,
      statusCode?: string
    ) => {
      let response;

      if (statusCode) {
        response = await api.get(
          '/order/paginated' +
            `?companyDocument=${companyDocument}` +
            `&initialDate=${initialDate}` +
            `&finalDate=${finalDate}` +
            '&page=1' +
            '&take=1' +
            `&statusCode=${statusCode}`
        );
      } else {
        response = await api.get(
          '/order/paginated' +
            `?companyDocument=${companyDocument}` +
            `&initialDate=${initialDate}` +
            `&finalDate=${finalDate}` +
            '&page=1' +
            '&take=1'
        );
      }

      return response.data;
    },
    []
  );

  const importXML = useCallback(async (data: ImportXML) => {
    await api.post('/order/import/xml-nfe', data);
  }, []);

  const importSpreadsheet = useCallback(async (data: any[]) => {
    await api.post('/order/import/spreadsheet', data);
  }, []);

  const importOrder = useCallback(async (data: IImportOrder) => {
    await api.post('/order/nuvemshop/import-order', data);
  }, []);

  const loadShippingOptions = useCallback(async () => {
    const response = await api.get('/order/shipping-options');

    return response.data;
  }, []);

  const loadOrderTag = useCallback(async (orderId: number) => {
    const response = await api.get(`/order/order-tag/${orderId}`);

    return response.data;
  }, []);

  const updateOrderTag = useCallback(async (orderId: number, data: any) => {
    await api.post(`/order/order-tag/${orderId}`, data);
  }, []);

  const generatePostCode = useCallback(async (orderId: number) => {
    const response = await api.put(`/order/generate/post-code/${orderId}`);

    return response.data;
  }, []);

  const printMailingLabel = useCallback(async (orderId: number) => {
    const response = await api.post(
      `/order/print/mailing-label/${orderId}/correios`
    );

    return response.data;
  }, []);

  const updateShippingStatus = useCallback(
    async (orderId: number, status: ShippingStatus, data?: IItems[]) => {
      const response = await api.put(
        `/order/update/shipping-status/${orderId}/${status}`,
        { items: data, vehiclePlate: null, origin: 'portal' }
      );

      return response.data;
    },
    []
  );

  const updateStatusCode = useCallback(
    async ({ orderId, images }: { orderId: number; images: IOrderImage[] }) => {
      const response = await api.put(`/order/update/to-delivered/${orderId}`, {
        images,
      });

      return response.data;
    },
    []
  );

  const changeOperation = useCallback(async (orderId: number) => {
    const response = await api.patch(
      `/order/change/order/operation/${orderId}`
    );

    return response.data;
  }, []);

  const loadSeparateOrdersOfMonth = useCallback(
    async (
      companyDocument: number,
      initialDate: string,
      finalDate: string,
      shippingStatus: string
    ) => {
      const response = await api.get(
        '/order/paginated' +
          `?companyDocument=${companyDocument}` +
          `&initialDate=${initialDate}` +
          `&finalDate=${finalDate}` +
          '&page=1' +
          '&take=1' +
          `&shippingStatus=${shippingStatus}`
      );

      return response.data;
    },
    []
  );

  const changeShippingAddress = useCallback(async (id: number, data: any) => {
    await api.patch(`/shipping-address/${id}`, data);
  }, []);

  const downloadNFeOrCTe = useCallback(
    async (type: 'nfe' | 'cte', id: number, number: string) => {
      const response = await api.get(
        `/order-attachment/download/danfe/${type}/${id}`,
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = `Pedido ${number} - ${type.toUpperCase()}`;
      a.click();
      document.body.removeChild(a);
    },
    []
  );

  const uploadNFeOrCTe = useCallback(
    async (
      type: 'nfe' | 'cte',
      id: number,
      companyDocument: number,
      data: FormData
    ) => {
      await api.post(
        `/order-attachment/upload/danfe/${type}/${companyDocument}/${id}`,
        data
      );
    },
    []
  );

  const createOccurrence = useCallback(
    async (orderId: number, data: CreateOccurrence) => {
      await api.put(`/order/set/occurrence/${orderId}`, data);
    },
    []
  );

  const addImages = useCallback(
    async (orderId: string, data: IOrderImage[]) => {
      await api.post(`/order/added/images/${orderId}`, data);
    },
    []
  );

  const resendOrder = useCallback(async (orderId: number) => {
    await api.patch(`/order/update/status-code/by/order-id/${orderId}/new`);
  }, []);

  const regenerateOrderNFe = useCallback(async (orderId: number) => {
    await api.patch(`/order/force-nf/${orderId}`);
  }, []);

  const cancelOrder = useCallback(async (orderId: number) => {
    await api.patch(
      `/order/update/status-code/by/order-id/${orderId}/cancelled`
    );
  }, []);

  const loadPortalSavedImage = useCallback(
    async (options: IPortalImageFilter) => {
      let query = '';

      const { orderId, companyDocument, number, orderCode } = options;

      query =
        `${companyDocument && `?companyDocument=${companyDocument}`}` +
        `${number && `&number=${number}`}` +
        `${orderCode && `&orderCode=${orderCode}`}`;

      const response = await api.get(
        `/order/get/images${orderId ? orderId : query}`
      );

      return response.data;
    },
    []
  );

  const downloadPortalSavedImage = useCallback(
    async (
      query: { orderNumber: string; orderCode: string; imageId: number },
      filename: string
    ) => {
      const { orderNumber, orderCode, imageId } = query;

      const response = await api.get(
        `/order-attachment/download/image?orderNumber=${orderNumber}&orderCode=${orderCode}&imageId=${imageId}`,
        { responseType: 'blob' }
      );

      const file = new Blob([response.data]);
      const fileURL = URL.createObjectURL(file);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = filename;
      a.click();
      document.body.removeChild(a);
    },
    []
  );

  const printOrderVolumeLabel = useCallback(
    async (orderId: number, volumes: number) => {
      const response = await api.post(
        `/print/order-label/${orderId}/${volumes}`
      );

      return response.data;
    },
    []
  );

  const getOrderActivityReportById = useCallback(
    async (routeId: number, orderCode: string, orderNumber: string) => {
      const response = await api.get(`/reports/route/activity`, {
        params: { routeId, orderCode, orderNumber },
      });

      return response.data;
    },
    []
  );

  return (
    <OrderContext.Provider
      value={{
        orderList,
        setOrderList,
        filter,
        setFilter,
        reloadOrderInfo,
        setReloadOrderInfo,
        loadOrderById,
        loadOrderByOrderCodeAndNumber,
        loadDaysWithOrders,
        loadDistributionCenter,
        loadOrderList,
        loadOrdersOfMonth,
        importXML,
        importSpreadsheet,
        importOrder,
        loadShippingOptions,
        loadOrderTag,
        updateOrderTag,
        generatePostCode,
        printMailingLabel,
        updateShippingStatus,
        updateStatusCode,
        loadSeparateOrdersOfMonth,
        changeOperation,
        changeShippingAddress,
        downloadNFeOrCTe,
        uploadNFeOrCTe,
        createOccurrence,
        addImages,
        resendOrder,
        regenerateOrderNFe,
        cancelOrder,
        loadPortalSavedImage,
        downloadPortalSavedImage,
        printOrderVolumeLabel,
        getOrderActivityReportById,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

function useOrder(): IOrderContext {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within a OrderProvider');
  }

  return context;
}

export { OrderProvider, useOrder };
