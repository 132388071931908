import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  MenuItem,
  Grid,
  Button as MUIButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Collapse,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { regionStatusLabel } from '../../utils';
import { Button } from '../Button';
import { useAuth } from '../../hooks/auth';
import { useVehicle, ILoadVehicle } from '../../modules/vehicle/hooks/vehicle';
import {
  usePlanning,
  IPlanningLoad,
  IPlanningRequest,
} from '../../modules/order/hooks/planning';
import {
  useRoute,
  IMoveActivityRequest,
  IRouteListByStatus,
} from '../../modules/route/hooks/route';
import { CustomRecurrenceForm, IRecurrenceData } from './CustomRecurrenceForm';

import { format, subDays } from 'date-fns';

type NumberFieldValue = number | '';

interface IData {
  vehicleId: NumberFieldValue;
  orders: false;
  planningConfigId: NumberFieldValue;
  useZoneRestriction: false;
  companyDocument: number;
  useExistingRoute: false;
  routeIdDestiny: NumberFieldValue;
  previsionDate: string;
}

export const PlanningForm = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadVehicleList } = useVehicle();
  const { moveActivity, loadRoutesByStatus } = useRoute();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isModalOpen,
    selected,
    setSelected,
    handleSelectOrder,
    handleToggleModal,
    loadPlanningList,
    createManualPlanning,
  } = usePlanning();
  const [plans, setPlans] = useState<IPlanningLoad[]>([]);
  const [vehicles, setVehicles] = useState<ILoadVehicle[]>([]);
  const [routes, setRoutes] = useState<IRouteListByStatus[]>([]);
  const [isExistingRoute, setIsExistingRoute] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data] = useState<IData>({
    vehicleId: '',
    orders: false,
    planningConfigId: '',
    useZoneRestriction: false,
    companyDocument: user.companyDocument,
    useExistingRoute: false,
    routeIdDestiny: '',
    previsionDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [recurrenceData, setRecurrenceData] = useState<IRecurrenceData>({
    recurrenceNumber: 1,
    measureOfTime: 'day',
    endDate: '',
    deadlineToFinish: 'never',
  });

  const validationCreateRoute = yup.object({
    previsionDate: yup
      .date()
      .min(
        subDays(new Date(), 1),
        'A data de previsão não pode anteceder a data atual!'
      ),
    orders: yup
      .string()
      .test(
        'orders',
        'Selecione pelo menos um pedido',
        () => selected.length > 0
      ),
    vehicleId: yup.number().required('Você deve selecionar um motorista'),
    planningConfigId: yup
      .number()
      .required('Você deve selecionar um planejamento'),
  });

  const validationAddToRoute = yup.object({
    orders: yup
      .string()
      .test(
        'orders',
        'Selecione pelo menos um pedido',
        () => selected.length > 0
      ),
    routeIdDestiny: yup.number().required('Você deve selecionar uma rota'),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      recurrence: 'notRepeat',
    },
    validationSchema: isExistingRoute
      ? validationAddToRoute
      : validationCreateRoute,
    onSubmit: async (values, formik) => {
      setLoading(true);

      try {
        if (values.useExistingRoute) {
          const form: IMoveActivityRequest = {
            companyDocument: values.companyDocument,
            routeIdDestiny: values.routeIdDestiny,
            orders: selected.map((order) => {
              return { number: order.id.toString() };
            }),
            routeIdOrigin: undefined,
          };

          await moveActivity(form).then(() => {
            const message =
              'Pedido(s) adicionados com sucesso! Aguarde o replanejamento da rota';

            enqueueSnackbar(message, {
              variant: 'success',
            });

            handleToggleModal();
            formik.resetForm();
            setSelected([]);
          });
        } else {
          const form: IPlanningRequest = {
            companyDocument: values.companyDocument,
            planningConfigId: values.planningConfigId,
            useZoneRestriction: values.useZoneRestriction,
            vehicleId: values.vehicleId,
            previsionDate: values.previsionDate,
            orders: selected.map((order) => {
              return { id: order.id };
            }),
          };

          await createManualPlanning(form).then((response) => {
            if (response !== undefined) {
              const message =
                'Rota criada com sucesso! Aguarde a roterização da mesma.';

              enqueueSnackbar(message, {
                variant: 'success',
              });

              handleClose();
              setSelected([]);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    async function loadPlanning() {
      try {
        const response = await loadPlanningList(user.companyDocument);

        if (!response) return;

        setPlans(response);
      } catch (error) {
        console.log(error);
      } finally {
      }
    }

    if (isModalOpen) loadPlanning();
  }, [isModalOpen, loadPlanningList, user.companyDocument]);

  useEffect(() => {
    async function loadVehicle() {
      try {
        const response = await loadVehicleList(user.companyDocument);

        if (!response) return;

        setVehicles(response.filter((vehicle) => vehicle.driverId !== null));
      } catch (error) {
        console.log(error);
      } finally {
      }
    }

    if (isModalOpen) loadVehicle();
  }, [isModalOpen, loadVehicleList, user.companyDocument]);

  useEffect(() => {
    async function loadRoutes(): Promise<void> {
      try {
        const STATUS = 'pending|on-route';

        const response = await loadRoutesByStatus(user.companyDocument, STATUS);

        if (!response) return;

        setRoutes(response);
      } catch (error) {
        console.log(error);
      }
    }

    if (isModalOpen) loadRoutes();
  }, [isModalOpen, loadRoutesByStatus, user.companyDocument]);

  const handleClose = useCallback(() => {
    handleToggleModal();
    formik.resetForm();
  }, [formik, handleToggleModal]);

  const handleChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;

      formik.setFieldValue(name, checked ? true : false);

      name === 'useExistingRoute' && setIsExistingRoute(checked ? true : false);
    },
    [formik]
  );

  const buttonText = useMemo(() => {
    if (formik.values.useExistingRoute) {
      return 'Adicionar';
    } else {
      return 'Criar';
    }
  }, [formik.values.useExistingRoute]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isModalOpen}
      onClose={handleToggleModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Criar Rota</DialogTitle>
      <DialogContent>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="orders table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Número</TableCell>
                      <TableCell>Zona</TableCell>
                      <TableCell>Endereço</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selected.map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            onClick={() => handleSelectOrder(row)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number}
                        </TableCell>
                        <TableCell>
                          {regionStatusLabel(row.shippingAddress.region)}
                        </TableCell>
                        <TableCell>
                          {`
                            ${row.shippingAddress.street},
                            ${row.shippingAddress.number} -
                            ${row.shippingAddress.city}
                          `}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {formik.touched.orders && (
                <FormHelperText error>{formik.errors.orders}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="useExistingRoute"
                      color="secondary"
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Adicionar em rota existente"
                />
              </FormControl>
            </Grid>

            {!formik.values.useExistingRoute ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Motorista"
                    id="vehicleId"
                    name="vehicleId"
                    value={formik.values.vehicleId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.vehicleId &&
                      Boolean(formik.errors.vehicleId)
                    }
                    helperText={
                      formik.touched.vehicleId && formik.errors.vehicleId
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Selecione um motorista</em>
                    </MenuItem>
                    {vehicles.map((vehicle) => {
                      if (vehicle.status === 'active') {
                        return (
                          <MenuItem key={vehicle.id} value={vehicle.id}>
                            {`${vehicle.name} - ${
                              vehicle.user ? vehicle.user.name : ''
                            }`}
                          </MenuItem>
                        );
                      }

                      return null;
                    })}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Planejamento"
                    id="planningConfigId"
                    name="planningConfigId"
                    value={formik.values.planningConfigId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.planningConfigId &&
                      Boolean(formik.errors.planningConfigId)
                    }
                    helperText={
                      formik.touched.planningConfigId &&
                      formik.errors.planningConfigId
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Selecione um planejamento</em>
                    </MenuItem>
                    {plans.map((planning) => (
                      <MenuItem key={planning.id} value={planning.id}>
                        {planning.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Recorrência"
                    id="recurrence"
                    name="recurrence"
                    value={formik.values.recurrence}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.recurrence &&
                      Boolean(formik.errors.recurrence)
                    }
                    helperText={
                      formik.touched.recurrence && formik.errors.recurrence
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value={'notRepeat'}>Não se repete</MenuItem>
                    <MenuItem value={'everyDay'}>Todos os dias</MenuItem>
                    <MenuItem value={'weekly'}>Semanal</MenuItem>
                    <MenuItem value={'monthly'}>Mensal</MenuItem>
                    <MenuItem value={'yearly'}>Anual</MenuItem>
                    <MenuItem value={'everyDayOfTheWeek'}>
                      Todos os dias da semana
                    </MenuItem>
                    <MenuItem value={'custom'}>Personalizar...</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Previsão de Execução"
                    type="date"
                    id="previsionDate"
                    name="previsionDate"
                    value={formik.values.previsionDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.previsionDate &&
                      Boolean(formik.errors.previsionDate)
                    }
                    helperText={
                      formik.touched.previsionDate &&
                      formik.errors.previsionDate
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="useZoneRestriction"
                          color="secondary"
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label="Usar zona de restrição"
                    />
                  </FormControl>
                </Grid>

                <Collapse
                  in={formik.values.recurrence === 'custom'}
                  timeout="auto"
                  unmountOnExit
                  style={{ marginTop: 10 }}
                >
                  <Toolbar>
                    <CustomRecurrenceForm
                      data={recurrenceData}
                      onChange={setRecurrenceData}
                    />
                  </Toolbar>
                </Collapse>
              </>
            ) : (
              <Grid item xs={6}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  label="Rota Existente"
                  id="routeIdDestiny"
                  name="routeIdDestiny"
                  value={formik.values.routeIdDestiny}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.routeIdDestiny &&
                    Boolean(formik.errors.routeIdDestiny)
                  }
                  helperText={
                    formik.touched.routeIdDestiny &&
                    formik.errors.routeIdDestiny
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Selecione uma rota</em>
                  </MenuItem>
                  {routes.map((route) => (
                    <MenuItem key={route.id} value={route.id}>
                      {`${route.routerName} - ${route.driver.driverName}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <MUIButton color="inherit" onClick={handleClose}>
          Fechar
        </MUIButton>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
