import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { Loading } from '../../../../../components/Loading';
import { useAuth } from '../../../../../hooks/auth';
import { useOrder, IOrderPaginatedLoad } from '../../../hooks/order';
import { DeliveredRow } from '../Row';

interface IProps {
  date: string;
}

export const DeliveredTable: React.FC<IProps> = ({ date }) => {
  const {
    data: { user },
  } = useAuth();
  const { loadOrderList, filter } = useOrder();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deliverys, setDeliverys] = useState<IOrderPaginatedLoad>({
    orders: [],
    total: 0,
  });
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, deliverys.total - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function loadDeliverys() {
      setLoading(true);

      const {
        number,
        customerName,
        region,
        shippingStatus,
        shippingOption,
        operationType,
      } = filter;

      const query =
        `${number && `&orderNumber=${number}`}` +
        `${customerName && `&customerName=${customerName}`}` +
        `${region && `&region=${region}`}` +
        `&statusCode=delivered` +
        `${shippingStatus && `&shippingStatus=${shippingStatus}`}` +
        `${shippingOption && `&shippingOption=${shippingOption}`}` +
        `${operationType && `&operationType=${operationType}`}`;

      try {
        const splitdate = date.split('/');

        const DAY = splitdate[0];
        const MONTH = splitdate[1];
        const YEAR = splitdate[2];
        const dateRefactored = `${YEAR}-${MONTH}-${DAY}`;

        const response = await loadOrderList(
          user.companyDocument,
          dateRefactored,
          page,
          rowsPerPage,
          query
        );

        if (!response) return;

        setDeliverys(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDeliverys();
  }, [date, filter, loadOrderList, page, rowsPerPage, user.companyDocument]);

  return (
    <>
      <TableContainer>
        <Table aria-label="delivery table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Pedido</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Qtde. Itens</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loading columns={8} rows={rowsPerPage} />
            ) : (
              deliverys.orders.map((order, index) => (
                <DeliveredRow key={order.id} delivery={order} />
              ))
            )}
            {!loading && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={deliverys.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
