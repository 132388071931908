import React from 'react';
import { Switch } from 'react-router-dom';

import { ReasonOccurrenceList } from '../pages/reason-occurrence-list';
import { ReasonOccurrenceForm } from '../pages/reason-occurrence-form';
import PrivateRoute from '../../../routes/PrivateRoute';
import { ReasonOccurrenceProvider } from '../hooks/reason-occurrence';

const ReasonOccurrenceRoute: React.FC = () => (
  <ReasonOccurrenceProvider>
    <Switch>
      <PrivateRoute
        exact
        path="/reason-occurrence"
        component={ReasonOccurrenceList}
      />
      <PrivateRoute
        path="/reason-occurrence/create"
        component={ReasonOccurrenceForm}
      />
      <PrivateRoute
        path="/reason-occurrence/:occurrenceId"
        component={ReasonOccurrenceForm}
      />
    </Switch>
  </ReasonOccurrenceProvider>
);

export default ReasonOccurrenceRoute;
