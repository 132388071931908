import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
  InputAdornment,
} from '@material-ui/core';
import {
  IStoragePosition,
  useStoragePosition,
} from '../../hooks/storage-position';

interface IProps {
  open: boolean;
  storagePositionId: number | null;
  onClose: () => void;
}

export const StoragePositionInfo: React.FC<IProps> = (props) => {
  const { loadStoragePositionById } = useStoragePosition();
  const [storagePosition, setStoragePosition] = useState<IStoragePosition>(
    {} as IStoragePosition
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setStoragePosition({} as IStoragePosition);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadStoragePosition(): Promise<void> {
      if (props.storagePositionId) {
        try {
          const response = await loadStoragePositionById(
            props.storagePositionId
          );

          if (!response) return;

          setStoragePosition(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadStoragePosition();
  }, [loadStoragePositionById, props.storagePositionId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Posição de Armazenagem cadastrada:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="CD"
                value={storagePosition.distributionCenterCode}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Rua"
                value={storagePosition.street}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Coluna"
                value={storagePosition.column}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nivel"
                value={storagePosition.level}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Apartamento"
                value={storagePosition.apartment}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Comprimento"
                value={storagePosition.depth}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Altura"
                value={storagePosition.height}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Largura"
                value={storagePosition.width}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Peso Máximo"
                value={storagePosition.maxWeight}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Kg</InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Volume Máximo"
                value={storagePosition.maxVolume}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Código da Posição"
                value={storagePosition.code}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
