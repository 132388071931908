import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { imageToBase64 } from '../../utils';
import { Container } from './styles';

interface IDragAndDropImage {
  onSave(images: string[], files: File[]): void;
}

export const DragAndDropImage = ({ onSave }: IDragAndDropImage) => {
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({ accept: 'image/*' });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      Promise.all(acceptedFiles.map(imageToBase64)).then((results) => {
        onSave(results, acceptedFiles);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  return (
    <Container
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
    >
      <input {...getInputProps()} />
      <p>Arraste as images ou clique para selecioná-las</p>
    </Container>
  );
};
