import { Fab, Zoom, Badge } from '@material-ui/core';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import AddLocationIcon from '@material-ui/icons/AddLocation';

import { usePlanning } from '../../modules/order/hooks/planning';
import { useAuth } from '../../hooks/auth';
import { PERMISSIONS } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    fabOrange: {
      color: theme.palette.common.white,
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[600],
      },
    },
  })
);

export interface IFabButtonProps {
  isEnabled: boolean;
}

export const FabButton = ({ isEnabled }: IFabButtonProps) => {
  const {
    data: { permissions },
  } = useAuth();
  const classes = useStyles();
  const { selected, handleToggleModal } = usePlanning();
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <>
      <Zoom
        in={isEnabled}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${isEnabled ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          aria-label="create route"
          className={clsx(classes.fab, classes.fabOrange)}
          color="inherit"
          onClick={handleToggleModal}
          disabled={!permissions.includes(PERMISSIONS.ROUTE_CREATE)}
        >
          <Badge badgeContent={selected.length} color="secondary">
            <AddLocationIcon />
          </Badge>
        </Fab>
      </Zoom>
    </>
  );
};
