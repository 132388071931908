import axios from 'axios';
import { ROUTES } from '../constants';

const api = axios.create({
  baseURL: process.env.REACT_APP_4LOG_API_URL,
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  },
});

const requestHandler = (request: any) => {
  const token = localStorage.getItem('@4Log:access_token');

  if (token) {
    request.headers['Authorization'] = 'Bearer ' + token;
  }

  return request;
};

const responseSuccessHandler = (response: any) => {
  return response;
};

const responseErrorHandler = (error: any) => {
  var errors = ['Something went wrong, please try again!'];

  if (error.response) {
    const originalConfig = error.config;

    if (error.response.data.errors) errors = error.response.data.errors;
    if (error.response.data.error) errors = [error.response.data.error];

    if (
      originalConfig.url !== '/user/login' &&
      !originalConfig.url.includes('/user/change-password')
    ) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        localStorage.removeItem('@4Log:access_token');
        localStorage.removeItem('@4Log:user');
        window.location.replace(ROUTES.auth);
      }
    }
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }

  return Promise.reject({
    status: error.response.status,
    errors: errors,
  });
};

api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  async (error) => responseErrorHandler(error)
);

export default api;
