import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import * as yup from 'yup';

import PrintRoundedIcon from '@material-ui/icons/PrintRounded';

import { useFormik } from 'formik';
import { IFullOrder, useOrder } from '../../hooks/order';

export const OrderQuantityInput = (props: { order: IFullOrder }) => {
  const { printOrderVolumeLabel } = useOrder();

  const [loadingQuantityVolume, setLoadingQuantityVolume] = useState(false);

  const validationSchemaQuantityVolume = yup.object({
    quantityVolume: yup
      .number()
      .test(
        'quantityVolume-is-validate',
        'Quantidade de Volume não pode ser vazio ou inferior a 1',
        (value) => {
          if (value) {
            let validate = true;

            if (isNaN(value) || value < 1) validate = false;

            return validate;
          }
          return false;
        }
      ),
  });

  const formikQuantityVolume = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaQuantityVolume,
    initialValues: {
      quantityVolume: props.order.quantityVolume,
    },
    onSubmit: async (values) => {
      setLoadingQuantityVolume(true);

      try {
        await printOrderVolumeLabel(props.order.id, values.quantityVolume);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingQuantityVolume(false);
      }
    },
  });

  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      label="Volume"
      id="quantityVolume"
      name="quantityVolume"
      type="number"
      value={formikQuantityVolume.values.quantityVolume}
      onChange={formikQuantityVolume.handleChange}
      error={
        formikQuantityVolume.touched.quantityVolume &&
        Boolean(formikQuantityVolume.errors.quantityVolume)
      }
      helperText={
        formikQuantityVolume.touched.quantityVolume &&
        formikQuantityVolume.errors.quantityVolume
      }
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {loadingQuantityVolume ? (
              <CircularProgress size={25} style={{ color: 'inherit' }} />
            ) : (
              <Tooltip title="Imprimir Etiqueta de Volumes" placement="top">
                <span>
                  <IconButton
                    aria-label="Imprimir Etiqueta de Volumes"
                    edge="end"
                    color="inherit"
                    onClick={() => formikQuantityVolume.handleSubmit()}
                  >
                    <PrintRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
      autoComplete="off"
    />
  );
};
