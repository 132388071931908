import styled from 'styled-components';

export const DivImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fafafa;

  padding: 20px;
  overflow: auto;
  white-space: nowrap;

  .container {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 10px;
    &:first-child {
      margin-left: 0px;
    }

    img {
      width: auto;
      height: auto;
      max-height: 150px;
      max-width: 150px;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-color: #000;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
    }

    .icon {
      color: white;
      font-size: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      &:hover {
        color: #eee;
        cursor: pointer;
      }
    }
  }
`;
