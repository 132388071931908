import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { TableCell } from '../../../components/Table/TableCell';
import { ILoadProduct, useProduct } from '../../product/hooks/product';

interface AddProductModalProps {
  open: boolean;
  addedProducts: string[];
  onClose: () => void;
  onSubmit: (products: string[]) => void;
}

export const AddProductModal = ({
  open,
  addedProducts,
  onClose,
  onSubmit,
}: AddProductModalProps) => {
  const { loadProductList } = useProduct();

  const [productList, setProductList] = useState<ILoadProduct>({
    total: 0,
    products: [],
  });
  const [newAdded, setNewAdded] = useState<string[]>([...addedProducts]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [hasDelay, setHasDelay] = useState<NodeJS.Timeout | null>(null);

  const loadOrders = useCallback(
    async (pageValue: number, take: number, searchValue?: string) => {
      setLoading(true);

      try {
        const query =
          `?page=${pageValue + 1}&take=${take}` +
          // + `&ownerDocument=${}`
          `${searchValue ? `&sku=${searchValue}` : ''}` +
          `${searchValue ? `&description=${searchValue}` : ''} `;
        // + `&barcode=${}`
        // + `&active=${}`;

        const response = await loadProductList(query);

        if (!response) return;

        setProductList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [loadProductList]
  );

  useEffect(() => {
    loadOrders(page, rowsPerPage);
  }, [loadOrders, page, rowsPerPage]);

  useEffect(() => {
    setNewAdded([...addedProducts]);
  }, [addedProducts, open]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchField(value);

    if (hasDelay) clearTimeout(hasDelay);
    const newTimeout = setTimeout(() => {
      loadOrders(page, rowsPerPage, value);
    }, 500);
    setHasDelay(newTimeout);
  };

  const handleSubmit = () => {
    onSubmit(newAdded);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Adicionar Produtos</DialogTitle>

      <DialogContent>
        <TextField
          variant="outlined"
          id="search"
          name="search"
          size="small"
          value={searchField}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: '0.5rem' }}
        />

        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell align="center">Adicionar</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <Loading columns={5} rows={rowsPerPage} />
              ) : (
                productList.products.map((product) => {
                  const isAdded = !!newAdded.find(
                    (added) => added === product.sku
                  );

                  return (
                    <TableRow key={product.id} hover>
                      <TableCell component="th" scope="row">
                        {product.sku}
                      </TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell align="center">
                        <Typography style={{ display: 'flex' }}>
                          <IconButton
                            color="primary"
                            disabled={isAdded}
                            onClick={() => {
                              setNewAdded((prevState) => [
                                ...prevState,
                                product.sku,
                              ]);
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            disabled={!isAdded}
                            onClick={() => {
                              setNewAdded((prevState) => {
                                const newState = [...prevState].filter(
                                  (state) => state !== product.sku
                                );

                                return newState;
                              });
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={productList.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Fechar
        </Button>

        <Button onClick={handleSubmit} color="secondary">
          Concluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
