import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { IDriverRouteSummarized } from '../pages/DriverRouteSummarized';
import { format, parseISO } from 'date-fns';

const docDefinition = (
  img: string,
  data: IDriverRouteSummarized[],
  date: { initial: string; final: string }
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Rotas sumarizado.xlsx',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              border: [false, false, false, false],
              columns: [
                [
                  {
                    text: 'Relatório de Rotas sumarizado',
                    bold: true,
                    alignment: 'right',
                    fontSize: 14,
                    margin: [0, 17, 0, 0],
                  },
                  {
                    text: `Total: ${data.length}`,
                    bold: true,
                    alignment: 'right',
                    fontSize: 14,
                    margin: [0, 8, 0, 8],
                  },
                ],
              ],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },
    {
      text: `De ${format(parseISO(date.initial), 'dd/MM/yyyy')} até ${format(parseISO(date.final), 'dd/MM/yyyy')}`, bold: true,
    },
    {
      margin: [0, 10, 0, 0],
      table: {
        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            {
              text: 'Motorista',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Concluído',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Pendente',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Ocorrência',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Cancelada',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Não Executada',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Rotas',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Distância Total',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
          ],
          ...generateTable(data),
        ],
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: IDriverRouteSummarized[]) {
  const rows = data.map((driver) => {
    return [
      {
        text: driver.driverName,
        alignment: 'left',
        fontSize: 8,
      },
      {
        text: driver.delivered,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: driver.pending,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: driver.occurrence,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: driver.cancelled,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: driver.notExecuted,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: driver.routes,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: `${driver.totalDistance} Km`,
        alignment: 'left',
        fontSize: 8,
      },
    ];
  });

  return rows;
}

export async function generateDriverRouteSummarizedReportPDF(
  data: IDriverRouteSummarized[],
  date: { initial: string; final: string }
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data, date)).open();
  }
}
