import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { AxiosResponse } from 'axios';

import api from '../../../services/api';
import { RegionStatus, RouteStatus } from '../../../constants';
import { useSettings } from '../../../hooks/settings';
import { IActivity } from './activity';
import { IDriverRouteReport } from '../../report/pages/DriverRoute';
import { IDriverRouteSummarized } from '../../report/pages/DriverRouteSummarized';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type NumberFieldValue = number | '';

export interface IMap {
  id: number;
  mapId: string;
  mapRoute: string;
}

interface IDriver {
  id: number;
  driverId: number;
  driverName: string;
  driverDoc: string;
  driverEmail: string;
  driverCellphone: string;
}

export interface IRoute {
  id: number;
  companyDocument: string;
  routerName: string;
  startRouteDate: string;
  endRouteDate: string;
  status: RouteStatus;
  responsibleName: string;
  responseDocument: string;
  responseSignature: string;
  mapLineColor: string;
  observation: string;
  driver: IDriver;
  totalTime: number;
  totalDistance: number;
  vehicle: {
    id: number;
    vehicleId: number;
    vehicleName: string;
    vehicleCapacity: number;
    lat: string;
    long: string;
  };
  activities: IActivity[];
  map: IMap;
}

interface IRouteFilter {
  initialDate: string;
  finalDate: string;
  status: string;
  driverId: number | '';
  orderNumber: string;
  routerName: string;
}

export type IRouteListByStatus = Omit<IRoute, 'vehicle' | 'activities' | 'map'>;

export type IRouteLight = Optional<IRoute, 'vehicle' | 'activities'>;

export interface IRoutePaginatedLoad {
  routes: IRoute[];
  total: number;
}

export interface IRoutePaginatedLoadLight {
  routes: IRouteLight[];
  total: number;
}

export interface IResult {
  date: string;
  quantity: string;
}

export interface IRouteDatesLoad {
  total: number;
  result: IResult[];
}

export interface IRouteUpdateDriver extends Omit<IDriver, 'id'> {}

export interface IMoveActivityRequest {
  companyDocument: NumberFieldValue;
  orders: {
    number: string;
    activityId?: number;
  }[];
  routeIdOrigin?: NumberFieldValue;
  routeIdDestiny: NumberFieldValue;
}

interface IRouteCompleted {
  driverName: string;
  driverDocument: string;
}

interface IRoutePlanningHistory {
  id: number;
  companyDocument: string;
  batch: string;
  planningDate: string;
  quantityOrders: number;
  quantityVehicles: number;
  maximumDeliveries: number;
  maximumVolumes: number;
  planningName: string;
  maximumWeight: number;
  deliveryStartTime: string;
  deliveryEndTime: string;
  mapsService: string;
  isProcessing: boolean;
}

export interface IRoutePlanningHistoryPaginated {
  routePlanningHistories: IRoutePlanningHistory[];
  total: number;
}

export interface IBatch {
  id: number;
  companyDocument: string;
  batch: string;
  planningDate: string;
  orderId: number;
  orderNumber: string;
  sequence: number;
  mapsService: string;
  driverId: number;
  driverName: string;
  routeId: number;
  routeName: string;
  reason: string | null;
  shippingAddress: {
    id: number;
    street: string;
    city: string;
    country: string;
    complement: string;
    neighborhood: string;
    number: string;
    state: string;
    postalcode: string;
    lat: string;
    long: string;
    region: RegionStatus;
  };
}

type DateFilter = {
  initialDate: string;
  finalDate: string;
};

type Pagination = {
  take: number;
  page: number;
};

type FilterDaysWithBatches = DateFilter;
type FilterPaginatedRoutePlanningHistory = DateFilter & Pagination;

export interface IDriverRoute {
  id: number;
  name: string;
  cpf: string;
  phone: string;
  email: string;
  vehicle: {
    name: string;
    plate: string;
    status: string;
    type: {
      name: string;
    };
  };
  routes: Omit<IRoute, 'vehicle' | 'map'>[];
}

interface IRouteContext {
  routeList: IRoutePaginatedLoadLight;
  setRouteList: React.Dispatch<React.SetStateAction<IRoutePaginatedLoadLight>>;
  filter: IRouteFilter;
  setFilter: React.Dispatch<React.SetStateAction<IRouteFilter>>;
  todayRoutes: IRoute[];
  setTodayRoutes: React.Dispatch<React.SetStateAction<IRoute[]>>;
  loadDaysWithRoutes(
    companyDocument: number,
    options?: IRouteFilter
  ): Promise<IRouteDatesLoad>;
  loadRouteById(id: number): Promise<IRoute | undefined>;
  updateRouteList(id: number): Promise<void>;
  loadRouteList(query: string): Promise<IRoutePaginatedLoad>;
  loadRouteListLight(
    companyDocument: number,
    date: string,
    page: number,
    take: number,
    query?: string
  ): Promise<IRoutePaginatedLoadLight>;
  loadRoutesByStatus(
    companyDocument: number,
    status: string
  ): Promise<IRouteListByStatus[]>;
  loadRoutesOfMonth(query: string): Promise<IRoutePaginatedLoadLight>;
  loadRouteMap(routeId: number): Promise<IMap>;
  moveActivity(data: IMoveActivityRequest): Promise<AxiosResponse>;
  updateDriver(
    routeId: number,
    driver: IRouteUpdateDriver
  ): Promise<AxiosResponse>;
  routeCompleted(
    routeId: number,
    driver: IRouteCompleted
  ): Promise<AxiosResponse>;
  routeReleased(routeId: number): Promise<AxiosResponse>;
  routeCanceled(routeId: number): Promise<AxiosResponse>;
  routeObservation(
    routeId: number,
    observation: string
  ): Promise<AxiosResponse>;
  reprocessRouteMap(routeId: number): Promise<void>;
  importSpreadsheet(data: any[]): Promise<void>;
  deleteRoute(id: number): Promise<void>;
  loadDaysWithBatches(filters?: FilterDaysWithBatches): Promise<IResult[]>;
  loadRoutePlanningHistory(
    filters: FilterPaginatedRoutePlanningHistory
  ): Promise<IRoutePlanningHistoryPaginated>;
  loadBatch(batch: string): Promise<IBatch[]>;
  downloadAppSavedImage(
    routeId: number,
    activityId: number,
    imageId: number,
    filename: string
  ): Promise<void>;
  loadDriverRoute(query: string): Promise<IDriverRoute[]>;
  loadDriverRouteSummarized(query: string): Promise<IDriverRouteSummarized[]>;
  getDriverRouteReportById(routeId: number): Promise<IDriverRouteReport[]>;
  hasToConfirm: boolean;
  setHasToConfirm: (val: boolean) => void;
}

const RouteContext = createContext<IRouteContext>({} as IRouteContext);

const RouteProvider: React.FC = ({ children }) => {
  const { selectedPeriod, getDatePeriod } = useSettings();
  const { from, to } = getDatePeriod(selectedPeriod);

  const [hasToConfirmRoute, setHasToConfirmRoute] = useState(false);
  const [todayRoutes, setTodayRoutes] = useState<IRoute[]>([]);
  const [filter, setFilter] = useState<IRouteFilter>({
    status: '',
    driverId: '',
    initialDate: from,
    finalDate: to,
    orderNumber: '',
    routerName: '',
  });
  const [routeList, setRouteList] = useState<IRoutePaginatedLoadLight>({
    routes: [],
    total: 0,
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: from,
      finalDate: to,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  const loadRouteById = useCallback(async (id: number) => {
    const response = await api.get(`/route/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const updateRouteList = useCallback(
    async (id: number) => {
      const response = await loadRouteById(id);

      if (response) {
        const indexRouteList = routeList.routes.findIndex((r) => r.id === id);
        if (indexRouteList > -1) {
          let newRouteList = routeList.routes;
          newRouteList[indexRouteList] = response;
          setRouteList((prevState) => ({
            total: prevState.total,
            routes: newRouteList,
          }));
        }

        const indexTodayRoutes = todayRoutes.findIndex((r) => r.id === id);
        if (indexTodayRoutes > -1) {
          let newTodayRoutes = todayRoutes;
          newTodayRoutes[indexTodayRoutes] = response;
          setTodayRoutes([...newTodayRoutes]);
        }
      }
    },
    [loadRouteById, routeList.routes, todayRoutes]
  );

  const updateToMoved = useCallback(
    (id: number) => {
      const routes = routeList.routes.map((route) => {
        if (route.id === id) {
          route.status = 'not-executed';

          if (route.activities) {
            route.activities.map((activity) => {
              activity.status = 'moved';
              return activity;
            });
          }
        }

        return route;
      });
      setRouteList((prevState) => ({ total: prevState.total, routes }));

      const indexTodayRoutes = todayRoutes.findIndex((r) => r.id === id);
      if (indexTodayRoutes > -1) {
        let newTodayRoutes = todayRoutes;
        newTodayRoutes[indexTodayRoutes].status = 'not-executed';
        newTodayRoutes[indexTodayRoutes].activities.map((activity) => {
          activity.status = 'moved';
          return activity;
        });
        setTodayRoutes([...newTodayRoutes]);
      }
    },
    [routeList.routes, todayRoutes]
  );

  const loadDaysWithRoutes = useCallback(
    async (companyDocument: number, options?: IRouteFilter) => {
      let query = '';
      if (options) {
        const {
          status,
          driverId,
          initialDate,
          finalDate,
          orderNumber,
          routerName,
        } = options;

        query =
          `?initialDate${initialDate && `=${initialDate}`}` +
          `${finalDate && `&finalDate=${finalDate}`}` +
          `${status && `&status=${status}`}` +
          `${orderNumber && `&orderNumber=${orderNumber}`}` +
          `${driverId && `&driverId=${driverId}`}` +
          `${routerName && `&routerName=${routerName}`}`;
      }

      const response = await api.get(
        `/route/routeDates/${companyDocument}${query}`
      );

      return response.data;
    },
    []
  );

  const loadRouteList = useCallback(async (query: string) => {
    const response = await api.get(`/route/paginated${query ? query : ''}`);

    return response.data;
  }, []);

  const loadRouteListLight = useCallback(
    async (
      companyDocument: number,
      date: string,
      page: number,
      take: number,
      query?: string
    ) => {
      const response = await api.get(
        '/route/paginated/light' +
          `?companyDocument=${companyDocument}` +
          `&initialDate=${date}` +
          `&finalDate=${date}` +
          `&page=${page + 1}` +
          `&take=${take}` +
          `${query ? query : ''}`
      );

      return response.data;
    },
    []
  );

  const loadRoutesByStatus = useCallback(
    async (companyDocument: number, status: string) => {
      const response = await api.get(
        `/route/list-by-status/${companyDocument}/${status}`
      );

      return response.data;
    },
    []
  );

  const loadRoutesOfMonth = useCallback(async (query: string) => {
    const response = await api.get(
      `/route/paginated/light${query ? query : ''}`
    );

    return response.data;
  }, []);

  const loadRouteMap = useCallback(async (routeId: number) => {
    const response = await api.get(`/route/map/${routeId}`);

    return response.data;
  }, []);

  const moveActivity = useCallback(
    async (data: IMoveActivityRequest) => {
      const response = await api
        .patch('/gateway-maps/move-activity-route/', data)
        .then((response) => {
          data.routeIdOrigin && updateToMoved(data.routeIdOrigin);
          updateToMoved(parseInt(data.routeIdDestiny.toString()));

          return response;
        });

      return response;
    },
    [updateToMoved]
  );

  const updateDriver = useCallback(
    async (routeId: number, driver: IRouteUpdateDriver) => {
      const response = await api
        .put(`/route/change-driver/${routeId}`, driver)
        .then(async (response) => {
          await updateRouteList(routeId);

          return response;
        });

      return response;
    },
    [updateRouteList]
  );

  const routeCompleted = useCallback(
    async (routeId: number, driver: IRouteCompleted) => {
      const response = await api
        .patch(`/collector/roteiro/finished/${routeId}`, driver)
        .then(async (response) => {
          await updateRouteList(routeId);

          return response;
        });

      return response;
    },
    [updateRouteList]
  );

  const routeCanceled = useCallback(
    async (routeId: number) => {
      const response = await api
        .patch(`/route/cancel/${routeId}`)
        .then(async (response) => {
          await updateRouteList(routeId);

          return response;
        });

      return response;
    },
    [updateRouteList]
  );

  const routeReleased = useCallback(
    async (routeId: number) => {
      const response = await api
        .put(`/route/${routeId}`)
        .then(async (response) => {
          await updateRouteList(routeId);

          return response;
        });

      return response;
    },
    [updateRouteList]
  );

  const routeObservation = useCallback(
    async (routeId: number, observation: string) => {
      const response = await api
        .put(`/route/${routeId}`, { observation })
        .then(async (response) => {
          await updateRouteList(routeId);

          return response;
        });

      return response;
    },
    [updateRouteList]
  );

  const importSpreadsheet = useCallback(async (data: any[]) => {
    await api.post('/route/import-spreadsheet', data);
  }, []);

  const deleteRoute = useCallback(async (id: number) => {
    await api.delete(`/route/${id}`);
  }, []);

  const loadDaysWithBatches = useCallback(
    async (filters?: FilterDaysWithBatches) => {
      let query = '';

      if (filters) {
        query =
          `?initialDate=${filters.initialDate}` +
          `&finalDate=${filters.finalDate}`;
      }

      const response = await api.get(
        `/route-planning-history/dates/by-batches${query}`
      );

      return response.data;
    },
    []
  );

  const loadRoutePlanningHistory = useCallback(
    async (filters: FilterPaginatedRoutePlanningHistory) => {
      let query = '';

      if (filters) {
        query =
          `?page=${filters.page}` +
          `&take=${filters.take}` +
          `&initialDate=${filters.initialDate}` +
          `&finalDate=${filters.finalDate}`;
      }

      const response = await api.get(`/route-planning-history${query}`);

      return response.data;
    },
    []
  );

  const loadBatch = useCallback(async (batch: string) => {
    const response = await api.get(
      `/route-planning-history/list-orders/${batch}`
    );

    return response.data;
  }, []);

  const downloadAppSavedImage = useCallback(
    async (
      routeId: number,
      activityId: number,
      imageId: number,
      filename: string
    ) => {
      const response = await api.get(
        `/route/download/image/${routeId}/${activityId}/${imageId}`,
        { responseType: 'blob' }
      );

      const file = new Blob([response.data]);
      const fileURL = URL.createObjectURL(file);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = filename;
      a.click();
      document.body.removeChild(a);
    },
    []
  );

  const loadDriverRoute = useCallback(async (query: string) => {
    const response = await api.get(
      `/reports/driver/routes${query ? query : ''}`
    );

    return response.data;
  }, []);

  const getDriverRouteReportById = useCallback(async (routeId: number) => {
    const response = await api.get(`/reports/driver/routes`, {
      params: { routeId },
    });

    return response.data;
  }, []);

  const loadDriverRouteSummarized = useCallback(async (query: string) => {
    const response = await api.get(
      `/reports/route/activity/summary${query ? query : ''}`
    );

    return response.data;
  }, []);

  const reprocessRouteMap = useCallback(async (routeId: number) => {
    await api.patch(`/route/reprocess-map/${routeId}`);
  }, []);

  return (
    <RouteContext.Provider
      value={{
        todayRoutes,
        setTodayRoutes,
        routeList,
        setRouteList,
        filter,
        setFilter,
        loadDaysWithRoutes,
        loadRouteById,
        updateRouteList,
        loadRouteList,
        loadRouteListLight,
        loadRoutesByStatus,
        loadRoutesOfMonth,
        loadRouteMap,
        moveActivity,
        updateDriver,
        routeCompleted,
        routeReleased,
        routeCanceled,
        routeObservation,
        reprocessRouteMap,
        importSpreadsheet,
        deleteRoute,
        loadDaysWithBatches,
        loadRoutePlanningHistory,
        loadBatch,
        downloadAppSavedImage,
        loadDriverRoute,
        loadDriverRouteSummarized,
        getDriverRouteReportById,
        hasToConfirm: hasToConfirmRoute,
        setHasToConfirm: (val) => setHasToConfirmRoute(val),
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

function useRoute(): IRouteContext {
  const context = useContext(RouteContext);

  if (!context) {
    throw new Error('useRoute must be used within a RouteProvider');
  }

  return context;
}

export { RouteProvider, useRoute };
