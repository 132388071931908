import { Hidden, Drawer } from '@material-ui/core';
import clsx from 'clsx';

import { useSideBar } from '../../hooks/sideBar';
import { DrawerContent } from './DrawerContent';

import { useStyles } from './styles';

export const SideBar = () => {
  const {
    isSideBarDesktopOpen,
    isSideBarMobileOpen,
    handleSideBarToggleMobile,
  } = useSideBar();
  const classes = useStyles();

  return (
    <>
      <Hidden smUp implementation="css">
        <nav
          id="side-bar-temporary"
          className={classes.drawer}
          aria-label="sidebar menu"
        >
          <Drawer
            id="side-bar-temporary"
            variant="temporary"
            classes={{
              paper: classes.drawerPaperMobile,
            }}
            open={isSideBarMobileOpen}
            onClose={handleSideBarToggleMobile}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <DrawerContent />
          </Drawer>
        </nav>
      </Hidden>

      <Hidden xsDown implementation="css">
        <nav
          id="side-bar-permanent"
          className={clsx(
            classes.drawerPaperDesktop,
            !isSideBarDesktopOpen && classes.drawerPaperClose
          )}
          aria-label="sidebar menu"
        >
          <Drawer
            id="side-bar-permanent"
            variant="permanent"
            className={classes.drawerPaperDesktop}
            classes={{
              paper: clsx(
                classes.drawer,
                !isSideBarDesktopOpen && classes.drawerPaperClose
              ),
            }}
            open={isSideBarDesktopOpen}
          >
            <DrawerContent />
          </Drawer>
        </nav>
      </Hidden>
    </>
  );
};
