import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { DeliveryRegionList } from '../pages/DeliveryRegionList';
import { DeliveryRegionForm } from '../pages/DeliveryRegionForm';

import { UserManagementProvider } from '../../user-management/hooks/user-management';
import { DeliveryRegionProvider } from '../hooks/delivery-region';

export const DeliveryRegionRoute: React.FC = () => (
  <UserManagementProvider>
    <DeliveryRegionProvider>
      <Switch>
        <PrivateRoute
          path={ROUTES.deliveryRegionList}
          exact
          permission={PERMISSIONS.DELIVERY_REGION_VIEW}
          component={DeliveryRegionList}
        />
        <PrivateRoute
          path={ROUTES.createDeliveryRegion}
          component={DeliveryRegionForm}
        />
        <PrivateRoute
          path={ROUTES.updateDeliveryRegion}
          component={DeliveryRegionForm}
        />
      </Switch>
    </DeliveryRegionProvider>
  </UserManagementProvider>
);
