import { useState, useEffect, useCallback } from 'react';
import { Grid, Chip } from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { IColumn, Table } from '../../../../components/Table';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { useAuth } from '../../../../hooks/auth';
import { VehicleInfo } from '../../components/VehicleInfo';
import { useVehicle, ILoadVehicle } from '../../hooks/vehicle';

export const VehicleList = () => {
  const {
    data: { user },
  } = useAuth();
  const { vehicleStatus, loadVehicleList, deleteVehicle } = useVehicle();
  const [vehicles, setVehicles] = useState<ILoadVehicle[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [vehicleId, setVehicleId] = useState<number | null>(null);

  const columns: IColumn<ILoadVehicle>[] = [
    { hide: true, field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Nome',
      mask: (name) => returnDriverColor(name),
    },
    { field: 'plate', headerName: 'Placa' },
    {
      field: 'status',
      headerName: 'Status',
      mask: (status) => returnVehicleStatus(status),
    },
    { field: 'vehicleType.name', headerName: 'Tipo do Veículo' },
    { field: 'user.name', headerName: 'Motorista' },
    {
      headerName: 'Ações',
      align: 'center',
      actions: {
        sendTo: 'vehicleList',
        actionOpen: handleOpenView,
        actionDelete: handleOpenDialogDelete,
      },
    },
  ];

  useEffect(() => {
    async function loadVehicle(): Promise<void> {
      try {
        const response = await loadVehicleList(user.companyDocument);

        if (!response) return;

        setVehicles(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadVehicle();
  }, [loadVehicleList, user.companyDocument]);

  function handleOpenView(id: number) {
    setOpenView(true);
    setVehicleId(id);
  }

  function handleOpenDialogDelete(id: number) {
    setOpenDialogDelete(true);
    setVehicleId(id);
  }

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setVehicleId(null);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setVehicleId(null);
  }, []);

  const handleDeleteVehicle = useCallback(async () => {
    if (vehicleId) {
      await deleteVehicle(vehicleId);
      setVehicles((prevState) =>
        prevState.filter((rest) => rest.id !== vehicleId)
      );
      setVehicleId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteVehicle, vehicleId]);

  const returnVehicleStatus = useCallback(
    (status: string) => {
      const label = vehicleStatus.find(
        (vStatus) => vStatus.value === status
      )?.label;

      if (label) {
        return label;
      }

      return status;
    },
    [vehicleStatus]
  );

  const returnDriverColor = useCallback(
    (name: string) => {
      const response = vehicles.find((vehicles) => vehicles.name === name);

      if (!response) return;

      return (
        <strong>
          <Chip
            label={response.name}
            style={{
              color: '#fff',
              backgroundColor: response.mapLineColor,
            }}
          />
        </strong>
      );
    },
    [vehicles]
  );

  return (
    <Main
      title="Veículos"
      button="add"
      buttonName="Novo Veículo"
      to={ROUTES.createVehicle}
      hasPermission="VEHICLE_CREATE"
    >
      <Grid container spacing={1}>
        <VehicleInfo
          open={openView}
          vehicleId={vehicleId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar veículo"
          description="Deseja realmente deletar este veículo?"
          onSubmit={handleDeleteVehicle}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Table
            columns={columns}
            rows={vehicles}
            totalOfRows={vehicles.length}
            currentPage={page}
            handlePageChange={handlePageChange}
            loading={loading}
            hasEditPermission="VEHICLE_EDIT"
            hasRemovePermission="VEHICLE_REMOVE"
          />
        </Grid>
      </Grid>
    </Main>
  );
};
