import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  MenuItem,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { useStyles, Form } from './styles';
import {
  IScandit,
  useScandit,
  IMobileApplication,
  IBarcode,
} from '../../hooks/scandit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { Button } from '../../../../components/Button';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const Scandit: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { loadMobileApplication, loadBarcode, loadScandit, saveScandit } =
    useScandit();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingScandit, setLoadingScandit] = useState(true);
  const [data, setData] = useState<IScandit[]>([]);
  const [idsRemove, setIdsRemove] = useState<number[]>([]);
  const [barcodes, setBarcodes] = useState<IBarcode[]>([]);
  const [mobileApplication, setMobileApplication] = useState<
    IMobileApplication[]
  >([]);

  useEffect(() => {
    async function loadIntegration(): Promise<void> {
      try {
        const responseOptionsApplication = await loadMobileApplication();

        const responseOptionsBarcode = await loadBarcode();

        const response = await loadScandit();

        if (!response) return;

        setMobileApplication(responseOptionsApplication);

        setBarcodes(responseOptionsBarcode);

        setData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingScandit(false);
      }
    }
    if (props.open) loadIntegration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadScandit, props.open]);

  const validationSchema = yup.object().shape({
    applicationId: yup.string().required('Campo não pode ser vazio'),
    key: yup.string().required('Campo não pode ser vazio'),
    barcodeSymbologies: yup
      .array()
      .min(1, 'Campo não pode ser vazio')
      .required('Campo não pode ser vazio'),
    playBeep: yup.boolean(),
    vibrate: yup.boolean(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicationId: '',
      key: '',
      barcodeSymbologies: [],
      playBeep: false,
      vibrate: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, formik) => {
      setData((prevState) => [...prevState, values]);
      formik.resetForm();
    },
  });

  const handleInputChange = useCallback(
    (e, index) => {
      const { value, name } = e.target;

      const list = [...data];
      if (name === 'applicationId') {
        list[index].applicationId = value;
      } else if (name === 'key') {
        list[index].key = value;
      } else if (name === 'barcodeSymbologies') {
        list[index].barcodeSymbologies = value;
      } else if (name === 'playBeep') {
        list[index].playBeep = !list[index].playBeep;
      } else if (name === 'vibrate') {
        list[index].vibrate = !list[index].vibrate;
      }
      setData(list);
    },
    [data]
  );

  const handleRemoveClick = useCallback(
    (index, id: number = 0) => {
      const list = [...data];
      const listRemove = [...idsRemove, id];
      list.splice(index, 1);
      setData(list);
      setIdsRemove(listRemove);
    },
    [data, idsRemove]
  );

  const handleClose = useCallback(() => {
    props.onClose();
    formik.resetForm();
    setLoadingScandit(true);
  }, [props, formik]);

  const save = useCallback(async () => {
    setLoadingButton(true);

    try {
      await saveScandit(data, idsRemove);
      props.onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIdsRemove([]);
    }

    setLoadingButton(false);
  }, [data, idsRemove, props, saveScandit]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">SCANDIT</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.form}
        >
          <Grid container spacing={3}>
            {loadingScandit ? (
              <Grid item xs={12}>
                <Typography component="div" align="center">
                  <CircularProgress />
                </Typography>
              </Grid>
            ) : (
              <>
                {data.map((data, index) => (
                  <>
                    <Grid item xs={12} sm={2} style={{ display: 'none' }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="ID"
                        id="ID"
                        name="ID"
                        value={data.id}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="Aplicativo"
                        id="applicationId"
                        name="applicationId"
                        value={data.applicationId}
                        onChange={(e) => handleInputChange(e, index)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {mobileApplication.map((application) => (
                          <MenuItem
                            key={application.id}
                            value={application.applicationId}
                          >
                            {application.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="key"
                        id="key"
                        name="key"
                        value={data.key}
                        onChange={(e) => handleInputChange(e, index)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        select
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="Código de barras"
                        id="barcodeSymbologies"
                        name="barcodeSymbologies"
                        value={
                          data.barcodeSymbologies ? data.barcodeSymbologies : []
                        }
                        onChange={(e) => handleInputChange(e, index)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                          multiple: true,
                          renderValue: (selected) => (
                            <div className={classes.chips}>
                              {(selected as string[]).map((value) => {
                                const barcode = barcodes.find(
                                  (barcode) => barcode.value === value
                                );

                                return (
                                  <Chip
                                    label={barcode?.description}
                                    className={classes.chip}
                                  />
                                );
                              })}
                            </div>
                          ),
                        }}
                      >
                        {barcodes.map((barcode) => (
                          <MenuItem key={barcode.id} value={barcode.value}>
                            {barcode.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="playBeep"
                              name="playBeep"
                              value={data.playBeep}
                              onChange={(e) => handleInputChange(e, index)}
                              checked={data.playBeep}
                              color="primary"
                            />
                          }
                          label={<Typography>Tocar beep</Typography>}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="vibrate"
                              name="vibrate"
                              value={data.vibrate}
                              onChange={(e) => handleInputChange(e, index)}
                              checked={data.vibrate}
                              color="primary"
                            />
                          }
                          label={<Typography>Vibrar</Typography>}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <IconButton
                        color="inherit"
                        aria-label="delete"
                        onClick={() => handleRemoveClick(index, data.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ))}

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Aplicativo"
                    id="applicationId"
                    name="applicationId"
                    value={formik.values.applicationId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.applicationId &&
                      Boolean(formik.errors.applicationId)
                    }
                    helperText={
                      formik.touched.applicationId &&
                      formik.errors.applicationId
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Selecione o aplicativo</em>
                    </MenuItem>
                    {mobileApplication.map((application) => (
                      <MenuItem
                        key={application.id}
                        value={application.applicationId}
                      >
                        {application.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="key"
                    id="key"
                    name="key"
                    value={formik.values.key}
                    onChange={formik.handleChange}
                    error={formik.touched.key && Boolean(formik.errors.key)}
                    helperText={formik.touched.key && formik.errors.key}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Código de barras"
                    id="barcodeSymbologies"
                    name="barcodeSymbologies"
                    value={formik.values.barcodeSymbologies}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.barcodeSymbologies &&
                      Boolean(formik.errors.barcodeSymbologies)
                    }
                    helperText={
                      formik.touched.barcodeSymbologies &&
                      formik.errors.barcodeSymbologies
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                      multiple: true,
                      renderValue: (selected) => (
                        <div className={classes.chips}>
                          {(selected as string[]).map((value) => {
                            const barcode = barcodes.find(
                              (barcode) => barcode.value === value
                            );

                            return (
                              <Chip
                                label={barcode?.description}
                                className={classes.chip}
                              />
                            );
                          })}
                        </div>
                      ),
                    }}
                  >
                    {barcodes.map((barcode) => (
                      <MenuItem key={barcode.id} value={barcode.value}>
                        {barcode.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="playBeep"
                          name="playBeep"
                          value={formik.values.playBeep}
                          onChange={formik.handleChange}
                          checked={formik.values.playBeep}
                          color="primary"
                        />
                      }
                      label={<Typography>Tocar beep</Typography>}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="vibrate"
                          name="vibrate"
                          value={formik.values.vibrate}
                          onChange={formik.handleChange}
                          checked={formik.values.vibrate}
                          color="primary"
                        />
                      }
                      label={<Typography>Vibrar</Typography>}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button variant="contained" color="inherit" type="submit">
                    <AddIcon />
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Fechar
        </Button>

        <Button
          onClick={save}
          color="secondary"
          variant="contained"
          loading={loadingButton}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
