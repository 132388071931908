import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F1F3F4',
    color: '#80868B',
    cursor: 'pointer',
    marginRight: '10px',
  },
  active: {
    background: '#1A73E8',
    color: '#FFFFFF',
    cursor: 'pointer',
    marginRight: '10px',
  },
}));
