import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Grid,
  InputAdornment,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import XLSX from 'xlsx';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';
import {
  IStoragePosition,
  useStoragePosition,
} from '../../../storage-position/hooks/storage-position';
import { generateStoragePositionReportPDF } from '../../templates/storagePositionReport';

export const StoragePositionReport = () => {
  const { loadStoragePositionList } = useStoragePosition();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [storagePositions, setStoragePositions] = useState<IStoragePosition[]>(
    []
  );

  useEffect(() => {
    async function loadStoragePositionReport(): Promise<void> {
      try {
        const response = await loadStoragePositionList();

        if (!response) return;

        const data: any = response.storagePositions.map((position) => {
          return {
            ...position,
          };
        });

        setStoragePositions(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadStoragePositionReport();
  }, [loadStoragePositionList]);

  const storagePositionFilter = useMemo((): IStoragePosition[] => {
    const fields = [
      'distributionCenterCode',
      'street',
      'column',
      'level',
      'apartment',
      'code',
    ];
    const matchedSearches = storagePositions.filter(
      (position: IStoragePosition) => {
        const includesOneOf = !!fields.find((field) =>
          position[field as keyof IStoragePosition]
            .toString()
            .toLowerCase()
            .includes(searchField.toLowerCase())
        );
        return includesOneOf;
      }
    );

    return matchedSearches;
  }, [searchField, storagePositions]);

  const columnsTable: IBasicColumn[] = [
    { name: 'CD' },
    { name: 'Rua' },
    { name: 'Coluna' },
    { name: 'Nível' },
    { name: 'Apartamento' },
    { name: 'Código da posição' },
  ];

  const downloadExcel = useCallback((data: IStoragePosition[]) => {
    const newData = data.map((position) => {
      return {
        cd: position.distributionCenterCode,
        street: position.street,
        column: position.column,
        level: position.level,
        apartment: position.apartment,
        code: position.code,
      };
    });

    const header = [
      ['CD', 'Rua', 'Coluna', 'Nível', 'Apartamento', 'Código da posição'],
    ];

    const workSheetCols = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'POSIÇÕES');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Posições de Armazenagem.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Posições de Armazenagem">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={(event) => setSearchField(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() =>
                  generateStoragePositionReportPDF(storagePositionFilter)
                }
              >
                Exportar PDF
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => downloadExcel(storagePositionFilter)}
              >
                Exportar EXCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={storagePositionFilter.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {storagePositionFilter
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((position) => (
                <TableRow key={position.id} hover>
                  <TableCell component="th" scope="row">
                    {position.distributionCenterCode}
                  </TableCell>
                  <TableCell>{position.street}</TableCell>
                  <TableCell>{position.column}</TableCell>
                  <TableCell>{position.level}</TableCell>
                  <TableCell>{position.apartment}</TableCell>
                  <TableCell>{position.code}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
