import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogTitle,
  DialogContent,
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Button } from '../../../../components/Button';
import { XML } from './XML';
import { Excel } from './Excel';
import { Manual } from './Manual';

interface IProps extends DialogProps {
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingBottom: 8 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const ImportOrder = ({ onClose, ...props }: IProps) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const a11yProps = useCallback((index: any) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      {...props}
      aria-labelledby="import-dialog-title"
    >
      <DialogTitle id="import-dialog-title">Importar Pedido</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <AppBar position="static" color="transparent">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="import tabs"
          >
            <Tab label="XML" {...a11yProps(0)} />
            <Tab label="Excel" {...a11yProps(1)} />
            <Tab label="Manual" {...a11yProps(2)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <XML />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <Excel />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Manual />
        </TabPanel>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
