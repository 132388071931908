import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { lighten } from 'polished';
import { useEffect, useState } from 'react';
import {
  BsFillCartCheckFill,
  BsFillCartDashFill,
  BsFillCartFill,
} from 'react-icons/bs';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
// import ReportIcon from '@material-ui/icons/Report';
// import SendIcon from '@material-ui/icons/Send';

import { Card } from '../../../../components/Card';
import { Main } from '../../../../components/Main';
import { ConferenceStatus } from '../../../../constants';
import { VehicleProvider } from '../../../vehicle/hooks/vehicle';
import { AgcoModalForm } from '../../components/AgcoModalForm';
import { FabButton } from '../../components/FabButton';
import { OrderSeparation } from '../../components/OrderSeparation';
import { ILoadConferenceStatus, useSeparation } from '../../hooks/separation';

export const Separation = () => {
  const theme = useTheme();
  const { separationFilter, setSeparationFilter, loadConferenceStatus } =
    useSeparation();
  const [conferenceStatus, setConferenceStatus] =
    useState<ILoadConferenceStatus>({
      countUnchecked: 0,
      countConferred: 0,
      countPartiallyConferred: 0,
    });
  const [loadingStatus, setLoadingStatus] = useState(true);

  useEffect(() => {
    async function asyncLoadConferenceStatus() {
      try {
        const response = await loadConferenceStatus({
          initialDate: separationFilter.initialDate,
          finalDate: separationFilter.finalDate,
        });

        if (!response) return;

        setConferenceStatus(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingStatus(false);
      }
    }

    asyncLoadConferenceStatus();
  }, [
    loadConferenceStatus,
    separationFilter.finalDate,
    separationFilter.initialDate,
  ]);

  const handleChangeConferenceStatusFilter = (status: ConferenceStatus) => {
    setSeparationFilter((prevState) => ({
      ...prevState,
      conferenceStatus: status,
    }));
  };

  return (
    <Main>
      <Grid container spacing={3}>
        <Grid item xs={6} md={4}>
          <Card
            title="Não conferido"
            content={conferenceStatus.countUnchecked}
            icon={<BsFillCartFill />}
            cardColor={lighten(0.325, theme.palette.info.main)}
            loading={loadingStatus}
            onClick={() => handleChangeConferenceStatusFilter('unchecked')}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Card
            title="Conferido"
            content={conferenceStatus.countConferred}
            icon={<BsFillCartCheckFill />}
            cardColor={lighten(0.325, theme.palette.success.main)}
            loading={loadingStatus}
            onClick={() => handleChangeConferenceStatusFilter('conferred')}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Card
            title="Parcialmente conferido"
            content={conferenceStatus.countPartiallyConferred}
            icon={<BsFillCartDashFill />}
            cardColor={lighten(0.325, theme.palette.warning.main)}
            loading={loadingStatus}
            onClick={() =>
              handleChangeConferenceStatusFilter('partially-conferred')
            }
          />
        </Grid>

        <Grid item xs={12}>
          <OrderSeparation />
        </Grid>

        <FabButton isEnabled />

        <VehicleProvider>
          <AgcoModalForm />
        </VehicleProvider>
      </Grid>
    </Main>
  );
};
