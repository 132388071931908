import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ReportIcon from '@material-ui/icons/Report';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExploreIcon from '@material-ui/icons/Explore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonPinCircleOutlinedIcon from '@material-ui/icons/PersonPinCircleOutlined';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import PeopleIcon from '@material-ui/icons/People';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PinDropIconOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RssFeedOutlinedIcon from '@material-ui/icons/RssFeedOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import StorageIcon from '@material-ui/icons/Storage';
import StoreIcon from '@material-ui/icons/Store';
import TuneIcon from '@material-ui/icons/Tune';
import WidgetsIcon from '@material-ui/icons/Widgets';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WhereToVote from '@material-ui/icons/WhereToVoteOutlined';

import { companyLogo } from '../../utils/logoUtils';

import { PERMISSIONS, ROUTES } from '../../constants';
import { useAuth } from '../../hooks/auth';
import { useSideBar } from '../../hooks/sideBar';
import { useStyles } from './styles';

export const DrawerContent = () => {
  const {
    data: { permissions },
  } = useAuth();
  const {
    isCollapseRegistrationOpen,
    setIsCollapseRegistrationOpen,
    isCollapseReportsOpen,
    setIsCollapseReportsOpen,
    isCollapseSettingsOpen,
    setIsCollapseSettingsOpen,
    isCollapseStorageOpen,
    setIsCollapseStorageOpen,
  } = useSideBar();
  const location = useLocation();
  const classes = useStyles();
  const [routeSelected, setRouteSelected] = useState('');

  useEffect(() => {
    setRouteSelected(`/${window.location.pathname.split('/')[1]}`);
  }, [location.pathname]);

  const handleClickRegistration = useCallback(() => {
    setIsCollapseRegistrationOpen((prevState) => !prevState);
  }, [setIsCollapseRegistrationOpen]);

  const handleClickReports = useCallback(() => {
    setIsCollapseReportsOpen((prevState) => !prevState);
  }, [setIsCollapseReportsOpen]);

  const handleClickSettings = useCallback(() => {
    setIsCollapseSettingsOpen((prevState) => !prevState);
  }, [setIsCollapseSettingsOpen]);

  const handleClickStorage = useCallback(() => {
    setIsCollapseStorageOpen((prevState) => !prevState);
  }, [setIsCollapseStorageOpen]);

  return (
    <>
      <div className={classes.drawerHeader}>
        <img
          src={companyLogo().black}
          alt="Logo 4Innovation"
          className={classes.logo}
        />
      </div>

      <Divider />

      <List>
        <ListItem
          button
          component={Link}
          to={ROUTES.dashboard}
          selected={routeSelected === ROUTES.dashboard}
          disabled={!permissions.includes(PERMISSIONS.DASHBOARD_VIEW)}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={ROUTES.receivement}
          selected={routeSelected === ROUTES.receivement}
          disabled
        >
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Recebimento" />
        </ListItem>

        <ListItem
          button
          onClick={handleClickStorage}
          selected={routeSelected === ROUTES.storagePositionList}
          disabled={!permissions.includes(PERMISSIONS.STORAGE_VIEW)}
        >
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="Armazenagem" />
          {isCollapseStorageOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isCollapseStorageOpen} timeout="auto" unmountOnExit>
          <List component="div" dense disablePadding>
            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.storagePositionList}
              selected={routeSelected === ROUTES.storagePositionList}
              disabled={!permissions.includes(PERMISSIONS.STORE_POSITION_VIEW)}
            >
              <ListItemIcon>
                <PinDropIcon />
              </ListItemIcon>
              <ListItemText primary="Posições" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          component={Link}
          to={ROUTES.order}
          selected={routeSelected === ROUTES.order}
          disabled={!permissions.includes(PERMISSIONS.ORDER_VIEW)}
        >
          <ListItemIcon>
            <WidgetsIcon />
          </ListItemIcon>
          <ListItemText primary="Pedidos" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={ROUTES.separation}
          selected={routeSelected === ROUTES.separation}
          disabled={!permissions.includes(PERMISSIONS.SEPARATION_VIEW)}
        >
          <ListItemIcon>
            <CallSplitIcon />
          </ListItemIcon>
          <ListItemText primary="Separação" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={ROUTES.route}
          selected={routeSelected === ROUTES.route}
          disabled={!permissions.includes(PERMISSIONS.ROUTE_VIEW)}
        >
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Rotas" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={ROUTES.npsList}
          selected={routeSelected === ROUTES.npsList}
        >
          <ListItemIcon>
            <ThumbsUpDownIcon />
          </ListItemIcon>
          <ListItemText primary="NPS" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={ROUTES.deliveryMaterials}
          selected={routeSelected === ROUTES.deliveryMaterials}
          disabled
        >
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary="Entrega de Materiais" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to={ROUTES.inventory}
          selected={routeSelected === ROUTES.inventory}
          disabled
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Inventário" />
        </ListItem>

        <ListItem
          button
          onClick={handleClickReports}
          selected={
            routeSelected === ROUTES.reportInventory ||
            routeSelected === ROUTES.ocoren ||
            routeSelected === ROUTES.rfid ||
            routeSelected === ROUTES.reportOrder ||
            routeSelected === ROUTES.reportOrderRFID ||
            routeSelected === ROUTES.reportRoute ||
            routeSelected === ROUTES.reportDriverRoute ||
            routeSelected === ROUTES.reportUserManagement ||
            routeSelected === ROUTES.reportDistributionCenter ||
            routeSelected === ROUTES.reportVehicle ||
            routeSelected === ROUTES.reportProduct ||
            routeSelected === ROUTES.reportStoragePosition ||
            routeSelected === ROUTES.reportDisposal
          }
          disabled={!permissions.includes(PERMISSIONS.REPORTS_VIEW)}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
          {isCollapseReportsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isCollapseReportsOpen} timeout="auto" unmountOnExit>
          <List component="div" dense disablePadding>
            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportInventory}
              selected={routeSelected === ROUTES.reportInventory}
              disabled={!permissions.includes(PERMISSIONS.REPORTS_INVENTORY)}
            >
              <ListItemIcon>
                <ListAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Inventário" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.ocoren}
              selected={routeSelected === ROUTES.ocoren}
              disabled={
                !permissions.includes(PERMISSIONS.REPORTS_PROCEDA_OCOREN)
              }
            >
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Proceda Ocoren" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.rfid}
              selected={routeSelected === ROUTES.rfid}
              disabled={
                !permissions.includes(PERMISSIONS.REPORTS_RFID_CONFERENCE)
              }
            >
              <ListItemIcon>
                <RssFeedOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Conferência Portal RFID" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportOrder}
              selected={routeSelected === ROUTES.reportOrder}
              // disabled={
              //   !permissions.includes(PERMISSIONS.REPORTS_ORDER)
              // }
            >
              <ListItemIcon>
                <WidgetsIcon />
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportOrderRFID}
              selected={routeSelected === ROUTES.reportOrderRFID}
              // disabled={
              //   !permissions.includes(PERMISSIONS.REPORTS_RFID_ORDER)
              // }
            >
              <ListItemIcon>
                <WidgetsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Pedidos RFID" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportRoute}
              selected={routeSelected === ROUTES.reportRoute}
              // disabled={!permissions.includes(PERMISSIONS.REPORTS_ROUTE)}
            >
              <ListItemIcon>
                <LocationOnOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Rotas" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportDriverRoute}
              selected={routeSelected === ROUTES.reportDriverRoute}
              // disabled={!permissions.includes(PERMISSIONS.REPORTS_ROUTE)}
            >
              <ListItemIcon>
                <PersonPinCircleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Rotas por Motorista" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportDriverRouteSummarized}
              selected={routeSelected === ROUTES.reportDriverRouteSummarized}
              // disabled={!permissions.includes(PERMISSIONS.REPORTS_ROUTE)}
            >
              <ListItemIcon>
                <WhereToVote />
              </ListItemIcon>
              <ListItemText primary="Rotas Sumarizadas" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportUserManagement}
              selected={routeSelected === ROUTES.reportUserManagement}
              disabled={!permissions.includes(PERMISSIONS.REPORTS_USER)}
            >
              <ListItemIcon>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportDistributionCenter}
              selected={routeSelected === ROUTES.reportDistributionCenter}
              //disabled={!permissions.includes(PERMISSIONS.REPORTS_DISTRIBUTION_CENTER)}
            >
              <ListItemIcon>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Centros de Distribuição" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportVehicle}
              selected={routeSelected === ROUTES.reportVehicle}
              disabled={!permissions.includes(PERMISSIONS.REPORTS_VEHICLE)}
            >
              <ListItemIcon>
                <DriveEtaOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Veículos" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportProduct}
              selected={routeSelected === ROUTES.reportProduct}
              //disabled={!permissions.includes(PERMISSIONS.REPORTS_PRODUCT)}
            >
              <ListItemIcon>
                <ShoppingCartOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Produtos" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportStoragePosition}
              selected={routeSelected === ROUTES.reportStoragePosition}
              //disabled={!permissions.includes(PERMISSIONS.REPORTS_STORE_POSITION)}
            >
              <ListItemIcon>
                <PinDropIconOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Posições" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportDisposal}
              selected={routeSelected === ROUTES.reportDisposal}
              //disabled={!permissions.includes(PERMISSIONS.REPORTS_STORE_POSITION)}
            >
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
              <ListItemText primary="Descarte" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reportForms}
              selected={routeSelected === ROUTES.reportForms}
              disabled={!permissions.includes(PERMISSIONS.REPORTS_FORMS)}
            >
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Formulários" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={handleClickRegistration}
          selected={
            routeSelected === ROUTES.userManagementList ||
            routeSelected === ROUTES.distributionCenterList ||
            routeSelected === ROUTES.vehicleList ||
            routeSelected === ROUTES.productList ||
            routeSelected === ROUTES.company ||
            routeSelected === ROUTES.forms
          }
          disabled={!permissions.includes(PERMISSIONS.REGISTER_VIEW)}
        >
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastros" />
          {isCollapseRegistrationOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isCollapseRegistrationOpen} timeout="auto" unmountOnExit>
          <List component="div" dense disablePadding>
            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.userManagementList}
              selected={routeSelected === ROUTES.userManagementList}
              disabled={!permissions.includes(PERMISSIONS.USER_VIEW)}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.distributionCenterList}
              selected={routeSelected === ROUTES.distributionCenterList}
              disabled={
                !permissions.includes(PERMISSIONS.DISTRIBUTION_CENTER_VIEW)
              }
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Centros de Distribuição" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.vehicleList}
              selected={routeSelected === ROUTES.vehicleList}
              disabled={!permissions.includes(PERMISSIONS.VEHICLE_VIEW)}
            >
              <ListItemIcon>
                <DriveEtaIcon />
              </ListItemIcon>
              <ListItemText primary="Veículos" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.productList}
              selected={routeSelected === ROUTES.productList}
              disabled={!permissions.includes(PERMISSIONS.PRODUCT_VIEW)}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Produtos" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.company}
              selected={routeSelected === ROUTES.company}
              disabled={!permissions.includes(PERMISSIONS.COMPANY_VIEW)}
            >
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Companhia" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.forms}
              selected={routeSelected === ROUTES.forms}
              disabled={!permissions.includes(PERMISSIONS.FORMS_VIEW)}
            >
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Formulários" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.reasonOccurrenceList}
              selected={routeSelected === ROUTES.reasonOccurrenceList}
              //disabled={!permissions.includes(PERMISSIONS.FORMS_VIEW)}
            >
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Motivos de Ocorrência" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={handleClickSettings}
          selected={
            routeSelected === ROUTES.companyParams ||
            routeSelected === ROUTES.manualOrderParams ||
            routeSelected === ROUTES.deliveryRegionList ||
            routeSelected === ROUTES.vehicleTypeList ||
            routeSelected === ROUTES.routePlanningList ||
            routeSelected === ROUTES.integration
          }
          disabled={!permissions.includes(PERMISSIONS.CONFIGURATION_VIEW)}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
          {isCollapseSettingsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isCollapseSettingsOpen} timeout="auto" unmountOnExit>
          <List component="div" dense disablePadding>
            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.companyParams}
              selected={routeSelected === ROUTES.companyParams}
              disabled={!permissions.includes(PERMISSIONS.COMPANY_PARAMS_VIEW)}
            >
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary="Parâmetros" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.manualOrderParams}
              selected={routeSelected === ROUTES.manualOrderParams}
              disabled={
                !permissions.includes(PERMISSIONS.MANUAL_ORDER_PARAMS_VIEW)
              }
            >
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary="Parâmetros Pedido Manual" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.deliveryRegionList}
              selected={routeSelected === ROUTES.deliveryRegionList}
              disabled={!permissions.includes(PERMISSIONS.DELIVERY_REGION_VIEW)}
            >
              <ListItemIcon>
                <MyLocationIcon />
              </ListItemIcon>
              <ListItemText primary="Regiões de Entregas" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.vehicleTypeList}
              selected={routeSelected === ROUTES.vehicleTypeList}
              disabled={!permissions.includes(PERMISSIONS.VEHICLE_TYPE_VIEW)}
            >
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Tipos de Veículos" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.routePlanningList}
              selected={routeSelected === ROUTES.routePlanningList}
              disabled={!permissions.includes(PERMISSIONS.PLANNING_VIEW)}
            >
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary="Planejamento" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              component={Link}
              to={ROUTES.integration}
              selected={routeSelected === ROUTES.integration}
              disabled={!permissions.includes(PERMISSIONS.INTEGRATION_VIEW)}
            >
              <ListItemIcon>
                <DeviceHubIcon />
              </ListItemIcon>
              <ListItemText primary="Integrações" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );
};
