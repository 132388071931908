import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Checkbox,
  List,
  ListItem,
  Card,
  ListItemIcon,
  Divider,
  CardHeader,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
} from '@material-ui/core';

import Select, { ActionMeta } from 'react-select';

import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';

import { useAuth } from '../../../../hooks/auth';

import {
  useDeliveryRegion,
  IStates,
  ICities,
  IDeliveryRegionNeighborhood,
  IDeliveryRegion,
} from '../../hooks/delivery-region';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { TableCell } from '../../../../components/Table/TableCell';
import { Loading } from '../../../../components/Loading';

import { Form, useStyles } from './styles';
import { regionStatus } from '../../../../utils';
import { DeleteNeighborhood } from '../../components/DeleteNeighborhood';

interface IParams {
  deliveryRegionId?: string;
}

interface IOption {
  label: string;
  value: string;
}

export const DeliveryRegionForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { deliveryRegionId } = useParams<IParams>();
  const {
    data: { user },
  } = useAuth();
  const {
    loadDeliveryRegionById,
    loadStatesList,
    loadCitiesList,
    loadNeighborhoodsList,
    createDeliveryRegion,
    updateDeliveryRegion,
  } = useDeliveryRegion();
  const [statesList, setStatesList] = useState<IStates[]>([]);
  const [citiesList, setCitiesList] = useState<ICities[]>([]);
  const [right, setRight] = useState<IDeliveryRegionNeighborhood[]>([]);
  const [left, setLeft] = useState<IDeliveryRegionNeighborhood[]>([]);
  const [checked, setChecked] = useState<IDeliveryRegionNeighborhood[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchNeighborhoods, setSearchNeighborhoods] = useState('');
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [nameNeighborhood, setNameNeighborhood] = useState<string | null>(null);
  const [indexNeighborhood, setIndexNeighborhood] = useState<number>(-1);
  const [enableCity, setEnableCity] = useState(true);
  const [cityValue, setCityValue] = useState<IOption | null>(null);
  const [search, setSearch] = useState({
    right: '',
    left: '',
  });
  const [data, setData] = useState<IDeliveryRegion>({
    name: '',
    companyDocument: user.companyDocument,
    neighborhoods: [],
  });

  const neighborhoodsFilter = useMemo(() => {
    const lowerSearch = searchNeighborhoods.toLowerCase();

    return right.filter((neighborhoods) => {
      const region = regionStatus.find(
        (rStatus) => rStatus.value === neighborhoods.region
      );

      return (
        neighborhoods.name.toLowerCase().includes(lowerSearch) ||
        region?.label.toLowerCase().includes(lowerSearch) ||
        neighborhoods.city.toLowerCase().includes(lowerSearch) ||
        neighborhoods.state.toLowerCase().includes(lowerSearch)
      );
    });
  }, [right, searchNeighborhoods]);

  const validationSchema = yup.object({
    name: yup.string().required('Informe um nome para a região'),
    state: yup.string().required('Selecione um estado'),
    city: yup.string().required('Selecione uma cidade'),
  });

  const validationSchemaUpdate = yup.object({
    name: yup.string().required('Informe um nome para a região'),
    state: yup.string(),
    city: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
      state: '',
      city: '',
    },
    validationSchema: deliveryRegionId
      ? validationSchemaUpdate
      : validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      let data: any = {};

      data = {
        name: values.name,
        companyDocument: values.companyDocument,
        neighborhoods: right,
      };

      try {
        if (deliveryRegionId) {
          await updateDeliveryRegion(parseInt(deliveryRegionId), { ...data });
          window.location.reload();
        } else {
          await createDeliveryRegion(data);
          history.push(ROUTES.deliveryRegionList);
        }
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  useEffect(() => {
    async function loadDeliveryRegion(): Promise<void> {
      try {
        if (deliveryRegionId) {
          const response = await loadDeliveryRegionById(
            parseInt(deliveryRegionId)
          );

          if (!response) return;

          setData({
            ...response,
          });

          setRight(response.neighborhoods.map((neighborhood) => neighborhood));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDeliveryRegion();
  }, [deliveryRegionId, loadDeliveryRegionById]);

  useEffect(() => {
    async function loadListOfStates(): Promise<void> {
      try {
        const response = await loadStatesList();

        if (!response) return;

        setStatesList(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadListOfStates();
  }, [loadStatesList]);

  const handleChangeState = async (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (option && actionMeta.name) {
      setEnableCity(false);

      setCityValue(null);

      setLeft([]);

      formik.setFieldValue(actionMeta.name, option.value);

      formik.setFieldValue('city', '');

      try {
        const response = await loadCitiesList(option.value);

        if (!response) return;

        setCitiesList(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeCity = async (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (option && actionMeta.name) {
      formik.setFieldValue(actionMeta.name, option.value);

      setCityValue(option);

      try {
        const response = await loadNeighborhoodsList(
          formik.values.state,
          option.value
        );

        if (!response) return;

        const lefts = response.filter(
          (l) =>
            !right.some(
              (r) =>
                l.name === r.name && l.city === r.city && l.state === r.state
            )
        );
        setLeft(lefts);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleOpenDialogDelete = useCallback((index, name: string) => {
    setIndexNeighborhood(index);
    setNameNeighborhood(name);
    setOpenDialogDelete(true);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setNameNeighborhood(null);
    setIndexNeighborhood(-1);
  }, []);

  const handleDeleteRegionNeighborhood = useCallback(async () => {
    if (indexNeighborhood >= 0) {
      const list = [...right];
      list.splice(indexNeighborhood, 1);
      setRight(list);

      setIndexNeighborhood(-1);
      setOpenDialogDelete(false);
    }
  }, [indexNeighborhood, right]);

  const subtitle = useMemo(() => {
    if (deliveryRegionId) {
      return 'Alterar região';
    } else {
      return 'Cadastrar região';
    }
  }, [deliveryRegionId]);

  const not = useCallback(
    (a: IDeliveryRegionNeighborhood[], b: IDeliveryRegionNeighborhood[]) => {
      return a.filter((value) => b.indexOf(value) === -1);
    },
    []
  );

  const intersection = useCallback(
    (a: IDeliveryRegionNeighborhood[], b: IDeliveryRegionNeighborhood[]) => {
      return a.filter((value) => b.indexOf(value) !== -1);
    },
    []
  );

  const union = useCallback(
    (a: IDeliveryRegionNeighborhood[], b: IDeliveryRegionNeighborhood[]) => {
      return [...a, ...not(b, a)];
    },
    [not]
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = useCallback(
    (value: IDeliveryRegionNeighborhood) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked]
  );

  const NeighborhoodOfChecked = useCallback(
    (items: IDeliveryRegionNeighborhood[]) =>
      intersection(checked, items).length,
    [intersection, checked]
  );

  const handleToggleAll = useCallback(
    (items: IDeliveryRegionNeighborhood[]) => () => {
      if (NeighborhoodOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    },
    [checked, not, union, NeighborhoodOfChecked]
  );

  const handleCheckedRight = useCallback(() => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  }, [right, leftChecked, not, left, checked]);

  const handleCheckedLeft = useCallback(() => {
    const checkCityNeighborhoods: IDeliveryRegionNeighborhood[] = [];

    rightChecked.forEach((r) => {
      if (r.city === formik.values.city) {
        checkCityNeighborhoods.push(r);
      }
    });
    setLeft(left.concat(checkCityNeighborhoods));

    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  }, [rightChecked, formik.values.city, left, not, right, checked]);

  const customList = useCallback(
    (
      title: React.ReactNode,
      items: IDeliveryRegionNeighborhood[],
      side: string
    ) => {
      const lowerSearch =
        side === 'right'
          ? search.right.toLowerCase()
          : search.left.toLowerCase();
      const filterItems = items.filter((item) => {
        const region = regionStatus.find(
          (rStatus) => rStatus.value === item.region
        );

        return (
          item.name.toLowerCase().includes(lowerSearch) ||
          region?.label.toLowerCase().includes(lowerSearch)
        );
      });

      return (
        <Card>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Checkbox
                onClick={handleToggleAll(filterItems)}
                checked={
                  NeighborhoodOfChecked(filterItems) === filterItems.length &&
                  filterItems.length !== 0
                }
                indeterminate={
                  NeighborhoodOfChecked(filterItems) !== filterItems.length &&
                  NeighborhoodOfChecked(filterItems) !== 0
                }
                disabled={filterItems.length === 0}
                inputProps={{ 'aria-label': 'all items selected' }}
                color="secondary"
              />
            }
            title={title}
            subheader={`${NeighborhoodOfChecked(filterItems)}/${
              filterItems.length
            } selected`}
          />
          <Divider />

          <Grid item>
            <TextField
              fullWidth
              variant="outlined"
              id={side}
              name={side}
              size="small"
              value={side === 'right' ? search.right : search.left}
              onChange={(ev) =>
                setSearch((prevState) => ({
                  ...prevState,
                  [side]: ev.target.value,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <List className={classes.list} dense component="div" role="list">
            {filterItems.map((value: IDeliveryRegionNeighborhood) => {
              const labelId = `transfer-list-all-item-${value.id}-label`;
              const region = regionStatus.find(
                (rStatus) => rStatus.value === value.region
              );

              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      value.region && value.region !== 'null'
                        ? ` ${value.name + ' (' + region?.label + ')'}`
                        : ` ${value.name}`
                    }
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      );
    },
    [
      NeighborhoodOfChecked,
      checked,
      classes.cardHeader,
      classes.list,
      handleToggle,
      handleToggleAll,
      search.left,
      search.right,
    ]
  );

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.deliveryRegionList}
    >
      <DeleteNeighborhood
        open={openDialogDelete}
        title={`Excluir ${nameNeighborhood}`}
        description="Deseja realmente excluir este bairro?"
        onSubmit={handleDeleteRegionNeighborhood}
        onClose={handleCloseDialogDelete}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  autoFocus
                  label="Nome da Região"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Grid item xs={12} sm={5}>
                  <Select
                    autoFocus
                    isSearchable
                    id="state"
                    name="state"
                    onChange={handleChangeState}
                    placeholder="Selecione o estado"
                    options={statesList.map((state) => ({
                      label: state.name,
                      value: state.ufCode,
                    }))}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <Select
                    autoFocus
                    isSearchable
                    isDisabled={enableCity}
                    id="city"
                    name="city"
                    onChange={handleChangeCity}
                    value={cityValue}
                    placeholder="Selecione a cidade"
                    noOptionsMessage={() => 'Nenhuma opção'}
                    options={citiesList.map((city) => ({
                      label: city.name,
                      value: city.name,
                    }))}
                    components={{}}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: 20,
                    fontSize: 20,
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                  }}
                >
                  Seus Bairros
                </Typography>
                <Grid
                  container
                  spacing={5}
                  justifyContent="center"
                  alignItems="center"
                  className={classes.root}
                >
                  <Grid item>{customList('Bairros', left, 'left')}</Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                        color="inherit"
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                        color="inherit"
                      >
                        &lt;
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {customList(
                      'Bairros Selecionados',
                      right.filter(
                        (sideRight) => sideRight.city === formik.values.city
                      ),
                      'right'
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Box mb={1}>
                <Grid item>
                  <TextField
                    variant="outlined"
                    id="searchNeighborhoods"
                    name="searchNeighborhoods"
                    size="small"
                    value={searchNeighborhoods}
                    onChange={(ev) => setSearchNeighborhoods(ev.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Box>

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Região</TableCell>
                      <TableCell>Cidade</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>Ação</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <Loading columns={5} />
                    ) : (
                      neighborhoodsFilter.map((neighborhood, index) => {
                        const region = regionStatus.find(
                          (rStatus) => rStatus.value === neighborhood.region
                        );

                        return (
                          <TableRow key={neighborhood.id} hover>
                            <TableCell component="th" scope="row">
                              {neighborhood.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {neighborhood.region ? region?.label : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {neighborhood.city}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {neighborhood.state}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <IconButton
                                color="inherit"
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenDialogDelete(
                                    index,
                                    neighborhood.name
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
