import React, { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TableRow } from '@material-ui/core';
import { TableCell } from '../Table/TableCell';

interface IProps {
  rows?: number;
  columns: number;
  height?: number;
}

export const Loading: React.FC<IProps> = ({
  rows = 5,
  columns,
  height = 20,
}) => {
  const render = useCallback(() => {
    const c = [];

    for (let i = 0; i < columns; i++) {
      c.push(
        <TableCell key={i} height={height}>
          <Skeleton />
        </TableCell>
      );
    }

    const r = [];

    for (let i = 0; i < rows; i++) {
      r.push(<TableRow key={i}>{c}</TableRow>);
    }

    return r;
  }, [columns, rows, height]);

  return <>{render()}</>;
};
