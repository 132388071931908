import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useAuth } from '../../../../hooks/auth';
import { PeriodEnum, useSettings } from '../../../../hooks/settings';
import { routeStatus } from '../../../../utils';
import {
  ILoadUser,
  useUserManagement,
} from '../../../user-management/hooks/user-management';
import { useRoute } from '../../hooks/route';

const validationSchema = yup.object({
  initialDate: yup.date(),
  finalDate: yup
    .date()
    .min(
      yup.ref('initialDate'),
      'Data fim não pode ser menor que a data início'
    ),
});

export const RouteFilter = ({hiddenFields}: {hiddenFields?: {initialDate?: 'none', finalDate?: 'none', status?: 'none',  driver?: 'none', order?: 'none', routerName?: 'none'}}) => {
  const {
    data: { user },
  } = useAuth();
  const { selectedPeriod, changePeriod, getDatePeriod } = useSettings();
  const { filter, setFilter } = useRoute();
  const { loadUserList } = useUserManagement();

  const [users, setUsers] = useState<ILoadUser[]>([]);

  const { values, errors, touched, handleChange, handleSubmit, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...filter,
      },
      validationSchema,
      onSubmit: (values) => {
        const { initialDate, finalDate } = values;
        const { from, to } = getDatePeriod(selectedPeriod);

        if (
          initialDate &&
          finalDate &&
          (initialDate !== from || finalDate !== to)
        ) {
          changePeriod(PeriodEnum.CUSTOM, { from: initialDate, to: finalDate });
        }

        setFilter(values);
      },
    });

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const response = await loadUserList(user.companyDocument);

      if (!response) return;

      setUsers(response);
    }

    loadUser();
  }, [loadUserList, user.companyDocument]);

  const handleFormReset = useCallback(
    (e) => {
      e.preventDefault();
      const { from, to } = getDatePeriod(selectedPeriod);

      handleReset(e);
      setFilter({
        status: '',
        driverId: '',
        initialDate: from,
        finalDate: to,
        orderNumber: '',
        routerName: '',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDatePeriod, handleReset, selectedPeriod]
  );

  return (
    <Grid
      container
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
      onReset={handleFormReset}
    >
      <Grid item xs={12}>
        <Typography variant="body1" id="filter">
          Filtros
        </Typography>
      </Grid>

      <Grid item xs={6} sm={4} style={{display: hiddenFields?.initialDate}}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Data início"
          type="date"
          id="initialDate"
          name="initialDate"
          value={values.initialDate}
          onChange={handleChange}
          error={touched.initialDate && Boolean(errors.initialDate)}
          helperText={touched.initialDate && errors.initialDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6} sm={4} style={{display: hiddenFields?.finalDate}}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Data fim"
          type="date"
          id="finalDate"
          name="finalDate"
          value={values.finalDate}
          onChange={handleChange}
          error={touched.finalDate && Boolean(errors.finalDate)}
          helperText={touched.finalDate && errors.finalDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6} sm={4} style={{display: hiddenFields?.status}}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Status"
          id="status"
          name="status"
          value={values.status}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {routeStatus.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={4} style={{display: hiddenFields?.driver}}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Motorista"
          id="driverId"
          name="driverId"
          value={values.driverId}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {users.map((user) => (
            <MenuItem key={`user-${user.id}`} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={4} style={{display: hiddenFields?.order}}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Pedido"
          id="orderNumber"
          name="orderNumber"
          value={values.orderNumber}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} sm={4} style={{display: hiddenFields?.routerName}}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Nome"
          id="routerName"
          name="routerName"
          value={values.routerName}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="secondary" type="submit">
            Filtrar
          </Button>
        </Grid>

        <Grid item>
          <Button type="reset">Limpar</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
