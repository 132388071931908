import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import React from 'react';

export const ModalContent = ({ children, ...props }: DialogContentProps) => {
  return (
    <DialogContent id="modal-description" {...props}>
      {children}
    </DialogContent>
  );
};
