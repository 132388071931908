import { useEffect, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { IColumn, Table } from '../../../../components/Table';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { useAuth } from '../../../../hooks/auth';
import { UserManagementInfo } from '../../components/UserManagementInfo';
import { useUserManagement, ILoadUser } from '../../hooks/user-management';
import { maskCnpj, maskCpf } from '../../../../utils';

export const UserManagementList = () => {
  const {
    data: { user },
  } = useAuth();
  const { userManagementStatus, loadUserList, deleteUser } =
    useUserManagement();
  const [users, setUsers] = useState<ILoadUser[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);

  const columns: IColumn<ILoadUser>[] = [
    { hide: true, field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome' },
    { field: 'email', headerName: 'Email' },
    {
      field: 'status',
      headerName: 'Status',
      mask: (status) => returnUserManagementStatus(status),
    },
    {
      field: 'cpf',
      headerName: 'CPF / CNPJ',
      mask: (cpf) => returnMask(cpf),
      align: 'right',
    },
    {
      headerName: 'Ações',
      align: 'center',
      actions: {
        sendTo: 'userManagementList',
        actionOpen: handleOpenView,
        actionDelete: handleOpenDialogDelete,
      },
    },
  ];

  useEffect(() => {
    async function loadUserManagement(): Promise<void> {
      try {
        const response = await loadUserList(user.companyDocument);

        if (!response) return;

        setUsers(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadUserManagement();
  }, [loadUserList, user.companyDocument]);

  function handleOpenView(id: number) {
    setOpenView(true);
    setUserId(id);
  }

  function handleOpenDialogDelete(id: number) {
    setOpenDialogDelete(true);
    setUserId(id);
  }

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setUserId(null);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setUserId(null);
  }, []);

  const handleDeleteUser = useCallback(async () => {
    if (userId) {
      await deleteUser(userId);
      setUsers((prevState) => prevState.filter((rest) => rest.id !== userId));
      setUserId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteUser, userId]);

  const returnUserManagementStatus = useCallback(
    (status: string) => {
      const label = userManagementStatus.find(
        (uStatus) => uStatus.value === status
      )?.label;

      if (label) {
        return label;
      }

      return status;
    },
    [userManagementStatus]
  );

  const returnMask = useCallback((cpf: string) => {
    if (cpf.length === 11) return maskCpf(cpf);
    else return maskCnpj(cpf);
  }, []);

  return (
    <Main
      title="Usuários"
      button="add"
      buttonName="Novo acesso"
      to={ROUTES.createUser}
      hasPermission="USER_CREATE"
    >
      <Grid container spacing={1}>
        <UserManagementInfo
          open={openView}
          userManagementId={userId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar usuário"
          description="Deseja realmente deletar este usuário?"
          onSubmit={handleDeleteUser}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Table
            columns={columns}
            rows={users}
            totalOfRows={users.length}
            currentPage={page}
            handlePageChange={handlePageChange}
            loading={loading}
            hasEditPermission="USER_EDIT"
            hasRemovePermission="USER_REMOVE"
          />
        </Grid>
      </Grid>
    </Main>
  );
};
