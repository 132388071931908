import { Grid, TextField } from '@material-ui/core';
import { useCallback, useState } from 'react';
import * as yup from 'yup';

import { useFormik } from 'formik';
import { Button } from '../../../../components/Button';
import { PERMISSIONS } from '../../../../constants';
import { useAuth } from '../../../../hooks/auth';
import { getAddress } from '../../../../services/viaCep';
import { maskCoordinate, maskPostalcode, unmask } from '../../../../utils';
import { IFullOrder, useOrder } from '../../hooks/order';
import { Form } from './styles';

export const AddressForm = (props: { order: IFullOrder }) => {
  const {
    data: { permissions },
  } = useAuth();

  const { changeShippingAddress } = useOrder();

  const [loadingButton, setLoadingButton] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = yup.object({
    postalcode: yup.string().required('Por favor, insira um CEP'),
    street: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    neighborhood: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    number: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string().required('Insira um número'),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    city: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    state: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    complement: yup.string(),
    lat: yup
      .string()
      .test(
        'is-full-coordinate',
        'Coordenada inválida: Latitude',
        (lat, context) => {
          const long = context.parent.long;
          if (!!lat?.trim() === !!long) {
            return true;
          }
          return !!lat?.trim();
        }
      ),
    long: yup
      .string()
      .test(
        'is-full-coordinate',
        'Coordenada inválida: Longitude',
        (long, context) => {
          const lat = context.parent.lat;
          if (!!long?.trim() === !!lat) {
            return true;
          }
          return !!long?.trim();
        }
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      city: props.order.shippingAddress.city,
      complement: props.order.shippingAddress.complement,
      country: props.order.shippingAddress.country,
      neighborhood: props.order.shippingAddress.neighborhood,
      number: props.order.shippingAddress.number,
      postalcode: props.order.shippingAddress.postalcode,
      state: props.order.shippingAddress.state,
      street: props.order.shippingAddress.street,
      lat: props.order.shippingAddress.lat || '',
      long: props.order.shippingAddress.long || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        await changeShippingAddress(props.order.shippingAddress.id, {
          ...values,
          lat: values.lat ? values.lat.trim() : null,
          long: values.long ? values.long.trim() : null,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsDisabled(true);
      }
      setLoadingButton(false);
    },
  });

  const handleChangePostalCodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);
      formik.setFieldValue('street', '');
      formik.setFieldValue('neighborhood', '');
      formik.setFieldValue('number', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');
      formik.setFieldValue('complement', '');
      formik.setFieldValue('long', '');
      formik.setFieldValue('lat', '');

      const postalcodeLength = 8;
      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf, complemento } = address;

          if (logradouro) {
            formik.setFieldValue('street', logradouro);
          }
          if (bairro) {
            formik.setFieldValue('neighborhood', bairro);
          }
          if (localidade) {
            formik.setFieldValue('city', localidade);
          }
          if (uf) {
            formik.setFieldValue('state', uf);
          }
          if (complemento) {
            formik.setFieldValue('complement', complemento);
          } else {
            formik.setFieldValue('complement', '');
          }
        }
      }
    },
    [formik]
  );

  function dividePastedLatLong(event: React.ClipboardEvent<HTMLDivElement>) {
    const [newLat, ...rest] = event.clipboardData.getData('Text').split(',');
    const newLong = rest.join('.');

    if (newLat.trim() && rest.join('').trim()) {
      formik.setFieldValue('lat', newLat);
      formik.setFieldValue('long', newLong);
      formik.setFieldTouched('lat', false, false);
      event.preventDefault();
    }
  }

  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    const replacedCoordinate = event.target.value.replaceAll(',', '.');
    formik.setFieldValue(event.target.name, replacedCoordinate);
  }

  const isNotDisabled = useCallback(() => {
    if (permissions.includes(PERMISSIONS.ORDER_EDIT)) return false;

    return true;
  }, [permissions]);

  return (
    <Grid item xs={12}>
      <Form noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="CEP"
              id="postalcode"
              name="postalcode"
              value={maskPostalcode(formik.values.postalcode)}
              onChange={handleChangePostalCodeValue}
              disabled={isDisabled}
              error={
                formik.touched.postalcode && Boolean(formik.errors.postalcode)
              }
              helperText={formik.touched.postalcode && formik.errors.postalcode}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Rua"
              id="street"
              name="street"
              value={formik.values.street}
              onChange={formik.handleChange}
              disabled={isDisabled}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Bairro"
              id="neighborhood"
              name="neighborhood"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              disabled={isDisabled}
              error={
                formik.touched.neighborhood &&
                Boolean(formik.errors.neighborhood)
              }
              helperText={
                formik.touched.neighborhood && formik.errors.neighborhood
              }
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Número"
              id="number"
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              disabled={isDisabled}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Cidade"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              disabled={isDisabled}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Estado"
              id="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              disabled={isDisabled}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Complemento"
              id="complement"
              name="complement"
              value={formik.values.complement}
              onChange={formik.handleChange}
              disabled={isDisabled}
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              label="Latitude"
              id="lat"
              name="lat"
              autoComplete="off"
              value={maskCoordinate(formik.values.lat)}
              onChange={handleChangeValue}
              onPaste={dividePastedLatLong}
              disabled={isDisabled}
              error={formik.touched.lat && Boolean(formik.errors.lat)}
              helperText={formik.touched.lat && formik.errors.lat}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              label="Longitude"
              id="long"
              name="long"
              autoComplete="off"
              value={maskCoordinate(formik.values.long)}
              onChange={handleChangeValue}
              disabled={isDisabled}
              error={formik.touched.long && Boolean(formik.errors.long)}
              helperText={formik.touched.long && formik.errors.long}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            {/* <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Referência"
              id="landmark"
              name="landmark"
              value={props.order.shippingAddress.landmark || 'SEM REFERÊNCIA'}
              disabled={true}
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
          </Grid>

          {isDisabled ? (
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                type="button"
                fullWidth
                style={{ height: 40 }}
                onClick={() => setIsDisabled(false)}
                disabled={isNotDisabled()}
              >
                Editar
              </Button>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                style={{ height: 40 }}
                onClick={() => formik.handleSubmit()}
                loading={loadingButton}
              >
                Salvar
              </Button>
            </Grid>
          )}
        </Grid>
      </Form>
    </Grid>
  );
};
