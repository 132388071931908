import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../../../services/api';
import {
  selectItem,
  selectAllItems,
  isCheckboxChecked,
  isCheckboxIndeterminate,
  isItemSelected,
} from '../../../utils';
import { IOrder } from '../../order/hooks/order';

interface IOutboundControlData {
  load: string;
  driverId: number;
  driverName: string;
  vehicleId: number;
  vehicleName: string;
  orders: number[];
}

interface IAgcoContext {
  isAgcoModalFormOpen: boolean;
  selected: IOrder[];
  setSelected: React.Dispatch<React.SetStateAction<IOrder[]>>;
  handleSelect(order: IOrder): void;
  handleSelectAll(checked: boolean, availableOrders: IOrder[]): void;
  clearSelected: () => void;
  isChecked(availableOrders: IOrder[]): boolean;
  isIndeterminate(availableOrders: IOrder[]): boolean;
  isSelected(order: IOrder): boolean;
  handleToggleAgcoModalForm: () => void;
  createOutboundControl: (data: IOutboundControlData) => Promise<void>;
}

const AgcoContext = createContext<IAgcoContext>({} as IAgcoContext);

const AgcoProvider: React.FC = ({ children }) => {
  const [isAgcoModalFormOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState<IOrder[]>([]);

  const handleToggleAgcoModalForm = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const handleSelect = useCallback(
    (order: IOrder) => selectItem(order, selected, setSelected),
    [selected]
  );

  const handleSelectAll = useCallback(
    (checked: boolean, availableOrders: IOrder[]) =>
      selectAllItems(checked, availableOrders, selected, setSelected),
    [selected]
  );

  const isChecked = useCallback(
    (availableOrders: IOrder[]) => isCheckboxChecked(availableOrders, selected),
    [selected]
  );

  const isIndeterminate = useCallback(
    (availableOrders: IOrder[]) =>
      isCheckboxIndeterminate(availableOrders, selected),
    [selected]
  );

  const isSelected = useCallback(
    (order: IOrder) => isItemSelected(order, selected),
    [selected]
  );

  const clearSelected = useCallback(() => setSelected([]), []);

  const createOutboundControl = useCallback(
    async (data: IOutboundControlData) => {
      await api.post('/outbound-control', data);
    },
    []
  );

  return (
    <AgcoContext.Provider
      value={{
        isAgcoModalFormOpen,
        selected,
        setSelected,
        handleSelect,
        handleSelectAll,
        clearSelected,
        isSelected,
        isChecked,
        isIndeterminate,
        handleToggleAgcoModalForm,
        createOutboundControl,
      }}
    >
      {children}
    </AgcoContext.Provider>
  );
};

function useAgco() {
  const context = useContext(AgcoContext);

  if (!context) {
    throw new Error('useAgco must be used within a AgcoProvider');
  }

  return context;
}

export { AgcoProvider, useAgco };
