import axios from 'axios';

interface ILoadCNPJ {
  nome: string;
  fantasia: string;
  cep: string;
  logradouro: string;
  bairro: string;
  numero: string;
  municipio: string;
  uf: string;
  situacao: string;
  abertura: string;
  complemento: string;
  telefone: string;
  email: string;
}

export const getCnpj = async (cnpj: string): Promise<ILoadCNPJ> => {
  const response = await axios({
    method: 'get',
    url: `https://coletaexpress.com.br/api.php/getcnpj/${cnpj}`,
  });

  return response.data;
};
