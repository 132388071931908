import { useCallback, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  formatCurrency,
  orderStatusCodeLabel,
  routeStatusLabel,
} from '../../../../../utils';
import { IActivity, IOrder } from '../../../hooks/order';
import { ActivityInfo } from '../../ActivityInfo';

import { useStyles } from './styles';

export const DeliveredRow = (props: { delivery: IOrder }) => {
  const { delivery } = props;
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [activity, setActivity] = useState<IActivity | null>(null);

  const handleOpenView = useCallback((activity: IActivity) => {
    setActivity(activity);
    setOpenView(true);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setTimeout(() => setActivity(null), 1500);
  }, []);

  return (
    <>
      <ActivityInfo
        activity={activity}
        open={openView}
        onClose={handleCloseView}
      />

      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenInfo(!openInfo)}
          >
            {openInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {delivery.number}
        </TableCell>
        <TableCell component="th" scope="row">
          {delivery.customer.name}
        </TableCell>
        <TableCell>{delivery.shippingAddress.city}</TableCell>
        <TableCell>{orderStatusCodeLabel(delivery.statusCode)}</TableCell>
        <TableCell align="right">{delivery.items.length}</TableCell>
        <TableCell align="right">{formatCurrency(delivery.value)}</TableCell>
        <TableCell>
          {delivery.activity.map((activityRow, index) => {
            return (
              activityRow.responseSignature && (
                <IconButton
                  key={activityRow.id}
                  color="inherit"
                  aria-label="activity view"
                  onClick={() => handleOpenView(activityRow)}
                  disabled={activityRow.status !== 'delivered'}
                >
                  <VisibilityIcon />
                </IconButton>
              )
            );
          })}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openInfo} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Produtos:
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Preço</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {delivery.items.map((itemRow) => (
                    <TableRow key={itemRow.id}>
                      <TableCell>{itemRow.description}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(parseFloat(itemRow.price))}
                      </TableCell>
                      <TableCell align="right">{itemRow.quantity}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(
                          Math.round(
                            parseFloat(itemRow.price) * itemRow.quantity * 100
                          ) / 100
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box margin={1} mb={2}>
              <Typography variant="h6" gutterBottom component="div">
                Rotas:
              </Typography>
              {delivery.activity.length > 0 && (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Inicío</TableCell>
                      <TableCell>Fim</TableCell>
                      <TableCell>Entrega inicío</TableCell>
                      <TableCell>Entrega fim</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {delivery.activity.map((activityRow) => (
                      <TableRow key={activityRow.id}>
                        <TableCell>{activityRow.route.id}</TableCell>
                        <TableCell>{activityRow.route.routerName}</TableCell>
                        <TableCell>
                          {new Date(
                            activityRow.route.startRouteDate
                          ).toLocaleTimeString()}
                        </TableCell>
                        <TableCell>
                          {new Date(
                            activityRow.route.endRouteDate
                          ).toLocaleTimeString()}
                        </TableCell>
                        <TableCell>{activityRow.prevArriveHour}</TableCell>
                        <TableCell>{activityRow.prevDepartureHour}</TableCell>
                        <TableCell>
                          {routeStatusLabel(activityRow.route.status)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="inherit"
                            aria-label="activity view"
                            onClick={() => handleOpenView(activityRow)}
                            disabled={activityRow.status !== 'delivered'}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
