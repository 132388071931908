import {
  Button,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useCallback, useEffect, useState } from 'react';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { CollapseRow } from '../../../../components/Table/CollapseRow';
import {
  activityStatusLabel,
  maskCpf,
  maskTelephone,
  routeStatusLabel,
} from '../../../../utils';
import { RouteFilter } from '../../../route/components/RouteFilter';

import { useAuth } from '../../../../hooks/auth';
import { ActivityProvider } from '../../../route/hooks/activity';
import { IDriverRoute, useRoute } from '../../../route/hooks/route';
import { useVehicle } from '../../../vehicle/hooks/vehicle';

import XLSX from 'xlsx';
import { generateDriverRoutePDF } from '../../templates/driverRouteReport';
import { useSnackbar } from 'notistack';
import { IForm, QuestionType } from '../../../form/hooks/form';
import type { ICompanyParams } from '../../../company-params/hooks/company-params';
import api from '../../../../services/api';

export interface IDriverRouteReport extends Omit<IDriverRoute, 'routes'> {
  routes: {
    id: number;
    routerName: string;
    startRouteDate: string;
    totalTime: number;
    totalDistance: number;
    status: string;
    activities: {
      id: number;
      ordem: number;
      number: string;
      status: string;
      startHour: string | number;
      endHour: string | number;
      responseSignature: string;
      filledForm?: {
        id: number;
        dateFilled: string;
        companyDocument: string;
        formConfigId: number;
        formConfig: IForm;
        data: {
          id: number;
          order: number;
          required: boolean;
          subject: string;
          type: QuestionType;
          options: string[];
          value?: string;
          observation?: string;
          valueCheckbox?: string[];
        }[];
      };
      images: { id: number; image: string }[];
      customer: {
        id: number;
        name: string;
        address: {
          city: string;
          complement: string;
          country: string;
          id: number;
          lat: string;
          long: string;
          neighborhood: string;
          number: string;
          postalcode: string;
          region: string;
          state: string;
          street: string;
        };
      };
    }[];
  }[];
}

export const DriverRouteReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadDriverRoute, filter } = useRoute();
  const { enqueueSnackbar } = useSnackbar();
  const { vehicleStatus } = useVehicle();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenDriver, setIsOpenDriver] = useState<number[]>([]);
  const [isOpenRoute, setIsOpenRoute] = useState<number[]>([]);
  const [driverRoutes, setDriverRoutes] = useState<IDriverRouteReport[]>([]);

  const dataColumns: IBasicColumn[] = [
    { name: 'Motorista' },
    { name: 'Veículo' },
    { name: '', padding: 'checkbox' },
  ];

  const itemsColumns: IBasicColumn[] = [
    { name: '', padding: 'checkbox' },
    { name: 'Rota' },
    { name: 'Data' },
    { name: 'Tempo', align: 'right' },
    { name: 'Distância', align: 'right' },
    { name: 'Status' },
  ];

  const subItemsColumns: IBasicColumn[] = [
    { name: 'Ordem', align: 'right' },
    { name: 'Pedido', align: 'right' },
    { name: 'Cliente' },
    { name: 'Status' },
  ];

  const formatDate = useCallback((date: string) => {
    return date.split('T')[0].split('-').reverse().join('/');
  }, []);

  const returnVehicleStatus = useCallback(
    (status: string) => {
      const label = vehicleStatus.find(
        (vStatus) => vStatus.value === status
      )?.label;

      if (label) {
        return label;
      }

      return status;
    },
    [vehicleStatus]
  );

  useEffect(() => {
    async function loadDriverRouteReport(): Promise<void> {
      setLoading(true);

      const { initialDate, finalDate, status, driverId } = filter;

      const query =
        `?companyDocument=${user.companyDocument}` +
        `&page=${page + 1}` +
        `${initialDate && `&initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}` +
        `${status && `&status=${status}`}` +
        `${driverId && `&driverId=${driverId}`}`;

      try {
        const response = await loadDriverRoute(query);

        if (!response) return;

        const newData = response.map((data) => {
          return {
            ...data,
            cpf: maskCpf(data.cpf),
            phone: maskTelephone(data.phone),
            vehicle: {
              ...data.vehicle,
              status: returnVehicleStatus(data.vehicle?.status),
            },
            routes: data.routes.map((route) => {
              return {
                ...route,
                startRouteDate: formatDate(route.startRouteDate),
                status: routeStatusLabel(route.status),
                activities: route.activities.map((activity) => {
                  return {
                    ...activity,
                    status: activityStatusLabel(activity.status),
                  };
                }),
              };
            }),
          };
        });

        setDriverRoutes(newData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDriverRouteReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleCollapse = useCallback(
    (id: number, dataOf: 'driver' | 'route') => {
      if (dataOf === 'driver') {
        setIsOpenDriver((prevState) => {
          const idIndex = prevState.findIndex((prev) => prev === id);
          if (idIndex > -1) prevState.splice(idIndex, 1);

          const newArray = idIndex > -1 ? [...prevState] : [...prevState, id];

          return newArray;
        });
      } else {
        setIsOpenRoute((prevState) => {
          const idIndex = prevState.findIndex((prev) => prev === id);
          if (idIndex > -1) prevState.splice(idIndex, 1);

          const newArray = idIndex > -1 ? [...prevState] : [...prevState, id];

          return newArray;
        });
      }
    },
    []
  );

  const downloadExcel = useCallback(() => {
    let tableHeader: any;

    const tableBody = driverRoutes.flatMap((driver, index) => {
      const driverData: any = [
        `MOTORISTA: ${driver.name}`,
        `DOCUMENTO: ${driver.cpf}`,
        `TELEFONE: ${driver.phone}`,
        `E-MAIL: ${driver.email}`,
      ];

      const vehicleData: any = [
        `VEÍCULO: ${driver.vehicle.name}`,
        `PLACA: ${driver.vehicle.plate}`,
        `TIPO: ${driver.vehicle.name}`,
        `STATUS: ${driver.vehicle.status}`,
      ];

      const routeData: any = driver.routes.flatMap((route) => {
        const routes: any = [
          `ROTA: ${route.routerName}  DATA: ${route.startRouteDate} `,
          `TEMPO: ${route.totalTime}`,
          `DISTÂNCIA: ${route.totalDistance}`,
          `STATUS: ${route.status}`,
        ];

        const activityHeader = ['ORDEM', 'PEDIDO', 'CLIENTE', 'STATUS'];

        if (
          route.status !== 'executed' &&
          route.activities &&
          route.activities.length > 0
        ) {
          const activityData = route.activities.map((activity) => {
            return [
              activity.ordem,
              activity.number,
              activity.customer.name,
              activity.status,
            ];
          });

          return [routes, activityHeader, ...activityData];
        } else {
          return [routes];
        }
      });

      if (index === 0) {
        tableHeader = [driverData];
        return [vehicleData, ...routeData];
      } else {
        return [driverData, vehicleData, ...routeData];
      }
    });

    const workSheetCols = [
      { width: 40 },
      { width: 26 },
      { width: 60 },
      { width: 30 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(tableBody);
    XLSX.utils.sheet_add_aoa(workSheet, tableHeader);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'ROTAS POR MOTORISTA');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Rotas por Motorista.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverRoutes]);

  return (
    <Main title="Relatório de Rotas por Motorista">
      <Grid container spacing={1}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                enqueueSnackbar(
                  `Imprimindo relatório de Rotas por Motorista! Aguarde, você será redirecionado.`,
                  {
                    variant: 'info',
                  }
                );

                const {
                  data: { geoFenceDistance },
                } = await api.get<ICompanyParams>(
                  '/company-params/by/document'
                );
                generateDriverRoutePDF(driverRoutes, geoFenceDistance);
              }}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={downloadExcel}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <RouteFilter />

        <Grid item xs={12}>
          <BasicTable
            columns={dataColumns}
            loading={loading}
            total={driverRoutes.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {driverRoutes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((driver) => {
                const isCollapseRowOpenDriver = isOpenDriver.includes(
                  driver.id
                );

                return (
                  <ActivityProvider key={driver.id}>
                    <React.Fragment>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {driver.name}
                        </TableCell>
                        <TableCell>{driver.vehicle.name}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => handleCollapse(driver.id, 'driver')}
                            aria-label="expand collapse row"
                          >
                            {isCollapseRowOpenDriver ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      <CollapseRow
                        open={isCollapseRowOpenDriver}
                        colSpan={dataColumns.length}
                        noPadding
                      >
                        {!!driver.routes && driver.routes.length > 0 ? (
                          <>
                            <BasicTable
                              size="small"
                              headerWithNoColor
                              columns={itemsColumns}
                            >
                              {driver.routes.map((route) => {
                                const isCollapseRowOpenRoute =
                                  isOpenRoute.includes(route.id);

                                return (
                                  <React.Fragment>
                                    <TableRow key={route.id}>
                                      <TableCell>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleCollapse(route.id, 'route')
                                          }
                                          aria-label="expand collapse row"
                                        >
                                          {isCollapseRowOpenRoute ? (
                                            <KeyboardArrowUpIcon />
                                          ) : (
                                            <KeyboardArrowDownIcon />
                                          )}
                                        </IconButton>
                                      </TableCell>
                                      <TableCell>{route.routerName}</TableCell>
                                      <TableCell>
                                        {route.startRouteDate}
                                      </TableCell>
                                      <TableCell align="right">
                                        {route.totalTime
                                          ? `${route.totalTime} min`
                                          : '0 min'}
                                      </TableCell>
                                      <TableCell align="right">
                                        {route.totalDistance
                                          ? `${route.totalDistance} km`
                                          : '0 km'}
                                      </TableCell>
                                      <TableCell>{route.status}</TableCell>
                                    </TableRow>

                                    <CollapseRow
                                      open={isCollapseRowOpenRoute}
                                      colSpan={itemsColumns.length}
                                      noPadding
                                    >
                                      {!!route.activities &&
                                      route.activities.length > 0 ? (
                                        <>
                                          <BasicTable
                                            size="small"
                                            headerWithNoColor
                                            columns={subItemsColumns}
                                          >
                                            {route.activities.map(
                                              (activity) => {
                                                return (
                                                  <TableRow key={activity.id}>
                                                    <TableCell align="right">
                                                      {activity.ordem}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      {activity.number}
                                                    </TableCell>
                                                    <TableCell>
                                                      {activity.customer.name}
                                                    </TableCell>
                                                    <TableCell>
                                                      {activity.status}
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              }
                                            )}
                                          </BasicTable>
                                        </>
                                      ) : (
                                        <Grid
                                          item
                                          container
                                          xs={12}
                                          justifyContent="center"
                                          alignItems="center"
                                        >
                                          <Typography variant="h6" gutterBottom>
                                            Não há itens a serem exibidos
                                          </Typography>
                                        </Grid>
                                      )}
                                    </CollapseRow>
                                  </React.Fragment>
                                );
                              })}
                            </BasicTable>
                          </>
                        ) : (
                          <Grid
                            item
                            container
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="h6" gutterBottom>
                              Não há rotas a serem exibidos
                            </Typography>
                          </Grid>
                        )}
                      </CollapseRow>
                    </React.Fragment>
                  </ActivityProvider>
                );
              })}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
