import { format, subDays } from 'date-fns';
import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { OrderStatusCode } from '../../../constants';

import api from '../../../services/api';

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

export interface IOrderRFID {
  id: number;
  orderDate: string;
  number: string;
  sender: {
    name: string;
  };
  deliveryPrevisionDate: string;
  items: {
    description: string;
    code: string;
    codeTranslate: string;
  }[];
  customer: {
    name: string;
  };
  shippingAddress: {
    street: string;
    neighborhood: string;
    city: string;
    state: string;
    postalcode: string;
  };
  datePortalRelease: string;
  dateMarkAsDelivered: string;
  statusCode: OrderStatusCode;
}

interface IOrderRFIDFilter {
  initialDate: string;
  finalDate: string;
  orderNumber?: string;
}

interface IOrderRFIDContext {
  loadOrderRFID(options?: IOrderRFIDFilter): Promise<IOrderRFID[]>;
  filter: IOrderRFIDFilter;
  setFilter: React.Dispatch<React.SetStateAction<IOrderRFIDFilter>>;
}

const OrderRFIDContext = createContext<IOrderRFIDContext>(
  {} as IOrderRFIDContext
);

const OrderRFIDProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<IOrderRFIDFilter>({
    initialDate: formatDate(subDays(new Date(), 6)),
    finalDate: formatDate(new Date()),
    orderNumber: '',
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: formatDate(subDays(new Date(), 6)),
      finalDate: formatDate(new Date()),
      orderNumber: '',
    }));
  }, []);

  const loadOrderRFID = useCallback(async (options: IOrderRFIDFilter) => {
    let query = '';

    const { initialDate, finalDate, orderNumber } = options;

    query = orderNumber
      ? `?orderNumber=${orderNumber}`
      : `${initialDate && `?initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}`;

    const response = await api.get(`/reports/list/orders/portal-rfid${query}`);

    return response.data;
  }, []);

  return (
    <OrderRFIDContext.Provider
      value={{
        loadOrderRFID,
        filter,
        setFilter,
      }}
    >
      {children}
    </OrderRFIDContext.Provider>
  );
};

function useOrderRFID(): IOrderRFIDContext {
  const context = useContext(OrderRFIDContext);

  if (!context) {
    throw new Error('useOrderRFID must be used with in a OrderRFIDProvider');
  }

  return context;
}

export { OrderRFIDProvider, useOrderRFID };
