import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IReasonOccurrence {
  id: number;
  reason: string;
  description: string;
}

export interface ILoadReasonOccurrence {
  total: number;
  reasonsOccurrence: IReasonOccurrence[];
}

export type IReasonOccurrenceRequest = Omit<IReasonOccurrence, 'id'>;

interface IReasonOccurrenceContext {
  loadReasonOccurrenceById(id: number): Promise<IReasonOccurrence | undefined>;
  loadReasonOccurrenceList(query?: string): Promise<ILoadReasonOccurrence>;
  createReasonOccurrence(data: IReasonOccurrenceRequest): Promise<void>;
  updateReasonOccurrence(
    id: number,
    data: IReasonOccurrenceRequest
  ): Promise<void>;
  deleteReasonOccurrence(id: number): Promise<void>;
}

const ReasonOccurrenceContext = createContext<IReasonOccurrenceContext>(
  {} as IReasonOccurrenceContext
);

const ReasonOccurrenceProvider: React.FC = ({ children }) => {
  const loadReasonOccurrenceById = useCallback(async (id: number) => {
    const response = await api.get(`/reason-occurrence/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadReasonOccurrenceList = useCallback(async (query?: string) => {
    const response = await api.get(`/reason-occurrence${query ? query : ''}`);

    return response.data;
  }, []);

  const createReasonOccurrence = useCallback(
    async (data: IReasonOccurrenceRequest) => {
      await api.post('/reason-occurrence', data);
    },
    []
  );

  const updateReasonOccurrence = useCallback(
    async (id: number, data: IReasonOccurrenceRequest) => {
      await api.patch(`/reason-occurrence/${id}`, data);
    },
    []
  );

  const deleteReasonOccurrence = useCallback(async (id: number) => {
    await api.delete(`/reason-occurrence/${id}`);
  }, []);

  return (
    <ReasonOccurrenceContext.Provider
      value={{
        loadReasonOccurrenceById,
        loadReasonOccurrenceList,
        createReasonOccurrence,
        updateReasonOccurrence,
        deleteReasonOccurrence,
      }}
    >
      {children}
    </ReasonOccurrenceContext.Provider>
  );
};

function useReasonOccurrence(): IReasonOccurrenceContext {
  const context = useContext(ReasonOccurrenceContext);

  if (!context) {
    throw new Error(
      'useReasonOccurrence must be used within a ReasonOccurrenceProvider'
    );
  }

  return context;
}

export { ReasonOccurrenceProvider, useReasonOccurrence };
