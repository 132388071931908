import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { Button } from '../../../../../components/Button';
import { useRoute } from '../../../hooks/route';
import { useAuth } from '../../../../../hooks/auth';

interface IProps extends DialogProps {
  routeId: number;
  routeName: string;
  onClose: () => void;
}

export const FinishRoute = ({
  routeId,
  routeName,
  onClose,
  ...props
}: IProps) => {
  const { routeCompleted } = useRoute();
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { user },
  } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      await routeCompleted(routeId, {
        driverName: user.name,
        driverDocument: user.companyDocument.toString(),
      }).then(() => {
        enqueueSnackbar('Rota finalizada com sucesso!', {
          variant: 'success',
        });

        onClose();
      });
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }, [
    enqueueSnackbar,
    onClose,
    routeCompleted,
    routeId,
    user.companyDocument,
    user.name,
  ]);

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      {...props}
    >
      <DialogTitle id="confirmation-dialog-title">
        Finalizar {routeName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          Deseja realmente finalizar essa rota?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Fechar
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          loading={loading}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
