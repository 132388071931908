import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';

import { Button } from '../../../../components/Button';
import { DragAndDropImage } from '../../../../components/DragAndDropImage';
import { ImageList } from '../../../../components/ImageList';
import {
  CreateOccurrence,
  useOrder,
  IOrderImage,
  OrderImageTypeEnum,
} from '../../hooks/order';
import { Form } from './styles';

interface IProps {
  open: boolean;
  id: number | null;
  onClose: () => void;
}

export const OccurrenceDialog = (props: IProps) => {
  const { createOccurrence } = useOrder();
  const [loadingButton, setLoadingButton] = useState(false);

  const validationSchema = yup.object({
    observation: yup.string(),
  });

  const formik = useFormik<CreateOccurrence>({
    initialValues: {
      observation: '',
      images: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      if (props.id) {
        setLoadingButton(true);
        try {
          await createOccurrence(props.id, { ...values });
          props.onClose();
        } catch (error) {
          console.log(error);
        }

        setLoadingButton(false);
        formikHelpers.resetForm();
      }
    },
  });

  const handleSaveImage = useCallback(
    (newImagesInBase64: string[], files: File[]) => {
      const newImages: IOrderImage[] = files.map((file, index) => ({
        base64: newImagesInBase64[index],
        filename: file.name,
        mimeType: file.type,
        type: OrderImageTypeEnum.OCCURRENCE,
      }));

      formik.setFieldValue('images', [...formik.values.images, ...newImages]);
    },
    [formik]
  );

  const handleDeleteImage = useCallback(
    (removedImage: string) => {
      const newImages = [...formik.values.images];
      const imageIndex = newImages.findIndex(
        (image) => image.base64 === removedImage
      );
      newImages.splice(imageIndex, 1);

      formik.setFieldValue('images', newImages);
    },
    [formik]
  );

  const handleClose = useCallback(() => {
    props.onClose();
    formik.resetForm();
  }, [props, formik]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Ocorrência</DialogTitle>

      <DialogContent>
        <Form onSubmit={formik.handleSubmit} noValidate>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              minRows={2}
              maxRows={5}
              label="Observação"
              id="observation"
              name="observation"
              value={formik.values.observation}
              onChange={formik.handleChange}
              error={
                formik.touched.observation && Boolean(formik.errors.observation)
              }
              helperText={
                formik.touched.observation && formik.errors.observation
              }
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginBottom: 24,
              }}
            />
          </Grid>

          <DragAndDropImage onSave={handleSaveImage} />

          <ImageList
            images={formik.values.images.map((image) => image.base64)}
            onDelete={handleDeleteImage}
          />
        </Form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancelar
        </Button>

        <Button
          onClick={() => formik.handleSubmit()}
          variant="contained"
          color="secondary"
          loading={loadingButton}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
