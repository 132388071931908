import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CustomAccordion, AccordionSummary, AccordionDetails } from './styles';

interface IProps {
  index: number;
  summary: string;
  expanded: string | false;
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>;
  children: React.ReactNode;
}

export const Accordion = ({
  index,
  summary,
  expanded,
  setExpanded,
  children,
}: IProps) => {
  const [panel] = useState(`panel${index}`);
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | false>(false);
  const milliseconds = 500;
  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (panel === expanded) {
      if (timeoutId) clearTimeout(timeoutId);
      setTimeoutId(false);
      setIsOpen(true);
    } else if (isOpen) {
      setTimeoutId(setTimeout(() => setIsOpen(false), milliseconds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, panel]);

  return (
    <CustomAccordion
      expanded={expanded === panel}
      onChange={handleChange(panel)}
    >
      <AccordionSummary
        id={`${panel}-header`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panel}-content`}
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{isOpen ? children : null}</AccordionDetails>
    </CustomAccordion>
  );
};
