import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { Integration } from '../pages/Integration';
import { NuvemshopProvider } from '../hooks/nuvemshop';
import { MaplinkProvider } from '../hooks/maplink';
import { VippProvider } from '../hooks/vipp';
import { ScanditProvider } from '../hooks/scandit';
import { BsoftProvider } from '../hooks/bsoft';
import { TomtomProvider } from '../hooks/tomtom';
import { OpenMapsProvider } from '../hooks/openmaps';
import { GoogleMapsProvider } from '../hooks/googlemaps';
import { GeoapifyProvider } from '../hooks/geoapify';
import { RastreMaisProvider } from '../hooks/rastremais';
import { SascarProvider } from '../hooks/sascar';
import { TotvsProvider } from '../hooks/totvs';

export const IntegrationRoute: React.FC = () => (
  <TotvsProvider>
    <SascarProvider>
      <RastreMaisProvider>
        <GeoapifyProvider>
          <GoogleMapsProvider>
            <OpenMapsProvider>
              <TomtomProvider>
                <BsoftProvider>
                  <ScanditProvider>
                    <VippProvider>
                      <MaplinkProvider>
                        <NuvemshopProvider>
                          <Switch>
                            <PrivateRoute
                              path={ROUTES.integration}
                              permission={PERMISSIONS.INTEGRATION_VIEW}
                              component={Integration}
                            />
                          </Switch>
                        </NuvemshopProvider>
                      </MaplinkProvider>
                    </VippProvider>
                  </ScanditProvider>
                </BsoftProvider>
              </TomtomProvider>
            </OpenMapsProvider>
          </GoogleMapsProvider>
        </GeoapifyProvider>
      </RastreMaisProvider>
    </SascarProvider>
  </TotvsProvider>
);
