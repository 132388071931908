// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth: '/',

  home: '/home',
  accessDenied: '/access-denied',

  dashboard: '/dashboard',
  map: '/generated-map',

  order: '/order',

  route: '/route',
  generateMap: '/generated-map/:routeId',

  userManagementList: '/user-management',
  createUser: '/user-management/create',
  updateUser: '/user-management/:userId',

  distributionCenterList: '/distribution-center',
  createDistributionCenter: '/distribution-center/create',
  updateDistributionCenter: '/distribution-center/:distributionCenterId',

  vehicleList: '/vehicle',
  createVehicle: '/vehicle/create',
  updateVehicle: '/vehicle/:vehicleId',

  vehicleTypeList: '/vehicle-type',
  createVehicleType: '/vehicle-type/create',
  updateVehicleType: '/vehicle-type/:vehicleTypeId',

  receivement: '/receivement',

  storagePositionList: '/storage-position',
  createStoragePosition: '/storage-position/create',
  updateStoragePosition: '/storage-position/:storagePositionId',

  separation: '/separation',

  deliveryMaterials: '/delivery-materials',

  inventory: '/inventory',

  ocoren: '/ocoren',
  rfid: '/rfid',
  reportOrder: '/report-order',
  reportOrderRFID: '/report-order-rfid',
  reportInventory: '/report-inventory',
  reportRoute: '/report-route',
  reportDriverRoute: '/report-driver-route',
  reportDriverRouteSummarized: '/report-driver-route-summarized',
  reportUserManagement: '/report-user-management',
  reportDistributionCenter: '/report-distribution-center',
  reportVehicle: '/report-vehicle',
  reportProduct: '/report-product',
  reportStoragePosition: '/report-storage-position',
  reportDisposal: '/report-disposal',
  reportForms: '/report-forms',

  deliveryRegionList: '/delivery-region',
  createDeliveryRegion: '/delivery-region/create',
  updateDeliveryRegion: '/delivery-region/:deliveryRegionId',

  routePlanningList: '/route-planning',
  createRoutePlanning: '/route-planning/create',
  updateRoutePlanning: '/route-planning/:routePlanningId',

  integration: '/integration',

  tracking: '/tracking/:trackingId',

  productList: '/product',
  createProduct: '/product/create',
  updateProduct: '/product/:productId',

  company: '/company',

  companyParams: '/company-params',

  manualOrderParams: '/manual-order-params',

  forms: '/forms',
  createForm: '/forms/new',
  updateForm: '/forms/:formId',

  npsList: '/nps',
  survey: '/survey/:code/:rate',

  reasonOccurrenceList: '/reason-occurrence',
  createReasonOccurrence: '/reason-occurrence/create',
  updateReasonsOccurrence: '/reason-occurrence/:id',
};
