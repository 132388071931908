import React, { useContext, createContext, useCallback, useState } from 'react';
import api from '../../../services/api';

type ProductUnit = 'kg' | 'lt' | 'un' | 'mt' | 'm2' | 'm3' | 'pr' | 'kw';

export interface IProductImage {
  id?: number;
  base64: string;
}

export interface IProduct {
  id: number;
  companyDocument: string;
  ownerDocument: string | undefined;
  sku: string;
  barcode: string;
  description: string;
  depth: string;
  height: string;
  weight: string;
  width: string;
  price: number;
  quantity: number;
  unit: ProductUnit;
  images: IProductImage[];
  active: boolean;
  codes: string[];
}

export interface ILoadProduct {
  total: number;
  products: IProduct[];
}

export type IProductRequest = Omit<IProduct, 'id'>;

interface IProductContext {
  productUnit: Array<{ label: string; value: string }>;
  loadProductById(id: number): Promise<IProduct | undefined>;
  loadProductList(query?: string): Promise<ILoadProduct>;
  updateProduct(id: number, data: IProductRequest): Promise<void>;
  createProduct(data: IProductRequest): Promise<void>;
  deleteProduct(id: number): Promise<void>;
}

const ProductContext = createContext<IProductContext>({} as IProductContext);

const ProductProvider: React.FC = ({ children }) => {
  const [productUnit] = useState([
    { label: 'KG – Quilograma', value: 'kg' },
    { label: 'LT – Litro', value: 'lt' },
    { label: 'UN – Unidade', value: 'un' },
    { label: 'MT – Metro Linear', value: 'mt' },
    { label: 'M2 – Metro Quadrado', value: 'm2' },
    { label: 'M3 – Metro Cúbico', value: 'm3' },
    { label: 'PR - Par', value: 'pr' },
    { label: 'KW – Quilowatt Hora', value: 'kw' },
  ]);

  const loadProductById = useCallback(async (id: number) => {
    const response = await api.get(`/product/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadProductList = useCallback(async (query?: string) => {
    const response = await api.get(`/product/paginated${query ? query : ''}`);

    return response.data;
  }, []);

  const createProduct = useCallback(async (data: IProductRequest) => {
    await api.post('/product', data);
  }, []);

  const updateProduct = useCallback(
    async (id: number, data: IProductRequest) => {
      await api.put(`/product/${id}`, data);
    },
    []
  );

  const deleteProduct = useCallback(async (id: number) => {
    await api.delete(`/product/${id}`);
  }, []);

  return (
    <ProductContext.Provider
      value={{
        productUnit,
        loadProductById,
        loadProductList,
        createProduct,
        updateProduct,
        deleteProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

function useProduct(): IProductContext {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
}

export { ProductProvider, useProduct };
