import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IOcoren {
  id: number;
  senderName: string;
  date: string;
  filename: string;
  url: string;
  senderDocument: string;
}

export interface IGenerateOcoren {
  senderDocument: string;
  dateFilter: string;
  emailsTo: string;
}

interface IOcorenContext {
  loadOcoren(): Promise<IOcoren[]>;
  generateOcoren(companyDocument: number, data: IGenerateOcoren): Promise<void>;
  downloadOcoren(senderDocument: string, filename: string): Promise<void>;
}

const OcorenContext = createContext<IOcorenContext>({} as IOcorenContext);

const OcorenProvider: React.FC = ({ children }) => {
  const loadOcoren = useCallback(async () => {
    const response = await api.get('/reports/list/proceda-ocoren');

    return response.data;
  }, []);

  const generateOcoren = useCallback(
    async (companyDocument: number, data: IGenerateOcoren) => {
      await api.post(
        `/reports/generate-proceda/ocoren/${companyDocument}`,
        data
      );
    },
    []
  );

  const downloadOcoren = useCallback(
    async (senderDocument: string, filename: string) => {
      const response = await api.get(
        `/reports/download/ocoren/${senderDocument}/${filename}`,
        { responseType: 'blob' }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = filename;
      a.click();
      document.body.removeChild(a);
    },
    []
  );

  return (
    <OcorenContext.Provider
      value={{
        loadOcoren,
        generateOcoren,
        downloadOcoren,
      }}
    >
      {children}
    </OcorenContext.Provider>
  );
};

function useOcoren(): IOcorenContext {
  const context = useContext(OcorenContext);

  if (!context) {
    throw new Error('useOcoren must be used with in a OcorenProvider');
  }

  return context;
}

export { OcorenProvider, useOcoren };
