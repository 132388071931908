import * as yup from 'yup';

export default yup.object().shape({
  orderCode: yup.string().required('Campo obrigatório'),
  number: yup.string().required('Campo obrigatório'),
  serie: yup.string(),
  referenceCode: yup.string(),
  orderDate: yup
    .date()
    .required('Campo obrigatório')
    .min(
      new Date(new Date().toDateString()),
      'A data informada deve ser posterior ou igual à data de hoje'
    ),
  deliveryPrevisionDate: yup.date().required('Campo obrigatório'),
  operationType: yup.string().required('Campo obrigatório'),
  paymentStatus: yup.string().required('Campo obrigatório'),
  shippingOption: yup.string().required('Campo obrigatório'),
  distributionCenter: yup.string().required('Campo obrigatório'),
  // value: yup.number().required("Campo obrigatório"),
  shippingCost: yup.number(),
  transferFreight: yup.number(),
  // weight: yup.string().required("Campo obrigatório"),
  observation: yup.string(),
});
