import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { Loading } from '../../../../components/Loading';
import { Main } from '../../../../components/Main';
import { CellActions } from '../../../../components/Table/CellActions';
import { TableCell } from '../../../../components/Table/TableCell';
import { ROUTES } from '../../../../constants';
import { IForms, RECURRENCE_OPTIONS, useForm } from '../../hooks/form';
import { useStyles } from './styles';

export const FormList = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getForms, deleteForm } = useForm();

  const [formList, setFormList] = useState<IForms>({
    total: 0,
    formsConfigs: [],
  });
  const [loading, setLoading] = useState(true);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [formId, setFormId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, formList.total - page * rowsPerPage);

  useEffect(() => {
    async function loadForms(): Promise<void> {
      setLoading(true);

      try {
        const response = await getForms({ page: page + 1, take: rowsPerPage });

        if (!response) return;

        setFormList(response);
      } catch (error) {
        enqueueSnackbar('Houve um erro para carregar os formulários', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    loadForms();
  }, [enqueueSnackbar, getForms, page, rowsPerPage]);

  const handleOpenDialogDelete = useCallback((id: number) => {
    setOpenDialogDelete(true);
    setFormId(id);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setFormId(null);
  }, []);

  const handleDeleteProduct = useCallback(async () => {
    if (formId) {
      await deleteForm(formId);

      setFormList((prevState) => ({
        ...prevState,
        formsConfigs: prevState.formsConfigs.filter(
          (form) => form.id !== formId
        ),
      }));

      setFormId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteForm, formId]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Main
      title="Lista de Formulários"
      button="add"
      buttonName="Novo formulário"
      to={ROUTES.createForm}
      hasPermission="FORMS_CREATE"
    >
      <Grid container spacing={1}>
        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar formulário"
          description="Deseja realmente deletar este formulário?"
          onSubmit={handleDeleteProduct}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Recorrência</TableCell>
                  <TableCell align="right">Qtd. Perguntas</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <Loading columns={4} rows={rowsPerPage} />
                ) : (
                  formList.formsConfigs.map((form) => (
                    <TableRow key={form.id} hover>
                      <TableCell component="th" scope="row">
                        {form.title}
                      </TableCell>
                      <TableCell>
                        {
                          RECURRENCE_OPTIONS.find(
                            (opts) => opts.value === form.recurrence.frequency
                          )?.label
                        }
                      </TableCell>
                      <TableCell align="right">
                        {form.questions.length}
                      </TableCell>
                      <TableCell align="center">
                        <CellActions
                          id={form.id}
                          sendTo="forms"
                          handleDelete={() => handleOpenDialogDelete(form.id)}
                          onEditPermission="FORMS_EDIT"
                          onRemovePermission="FORMS_REMOVE"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {!loading && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={formList.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Main>
  );
};
