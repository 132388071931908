import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { formatCurrency } from '../../../../../utils';
import { IManualOrder, useManualOrder } from '../../../hooks/manual-order';
import { ItemModal } from '../ItemModal';
import { ModalActions } from '../ModalActions';
import { ModalContent, Title } from '../';
import schema from './schema';

export type IItems = Pick<IManualOrder, 'items'>;

export const ItemsTable = () => {
  const { order, nextOrBack, setOrder, handleNext, handleBack } =
    useManualOrder();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<IItems>(order);

  const totalAmount =
    data.items.reduce(
      (acc: number, cur) => acc + cur.price * cur.quantity,
      0
    ) || 0;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: schema,
    onSubmit: () => {
      setOrder((prevState) => {
        let value =
          data.items.reduce(
            (acc: number, cur) => acc + cur.price * cur.quantity,
            0
          ) || 0;

        value = value + prevState.shippingCost;

        const weight =
          prevState.items
            .reduce((acc: number, cur) => acc + parseFloat(cur.weight), 0)
            .toString() || '0';

        return { ...prevState, value, weight, items: [...data.items] };
      });

      nextOrBack === 1 ? handleNext() : handleBack();
    },
  });

  const handleDelete = useCallback(
    (index: number) => {
      let newState = data.items;

      newState.splice(index, 1);
      setData({ items: [...newState] });
    },
    [data]
  );

  const handleChangeBarcode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const index = parseInt(name.split('-')[1]);

      let newState = data.items;

      newState[index].barcode = value;

      setData({ items: [...newState] });
    },
    [data]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const index = parseInt(name.split('-')[1]);

      let newState = data.items;

      newState[index].quantity = parseInt(value, 10);

      setData({ items: [...newState] });
    },
    [data]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ItemModal
        open={open}
        items={data.items}
        setItem={setData}
        onClose={handleClose}
      />

      <ModalContent>
        <Title />

        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: -66,
            marginBottom: 8,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}
          >
            Novo item
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="Itens table">
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell align="right">Codigo</TableCell>
                <TableCell align="right">Quantidade</TableCell>
                <TableCell align="right">Valor Unitário</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Remover</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.items.length > 0 &&
                data.items.map((row, index) => (
                  <TableRow key={row.sku}>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        style={{ width: 45 }}
                        type="text"
                        name={`barcode-${index}`}
                        id={`barcode-${index}`}
                        value={row.barcode}
                        onChange={handleChangeBarcode}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        style={{ width: 45 }}
                        type="number"
                        name={`quantity-${index}`}
                        id={`quantity-${index}`}
                        value={row.quantity}
                        onChange={handleChange}
                        inputProps={{
                          min: 1,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(row.price)}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        row.price * (isNaN(row.quantity) ? 0 : row.quantity)
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        tabIndex="-1"
                        color="inherit"
                        aria-label="delete"
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell align="right" colSpan={5}>
                  <Typography style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                    Valor total dos itens:{' '}
                    <strong>{formatCurrency(totalAmount)}</strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <FormControl error>
          {formik.touched.items && (
            <FormHelperText>{formik.errors.items}</FormHelperText>
          )}
        </FormControl>
      </ModalContent>

      <ModalActions validate={formik.handleSubmit} />
    </>
  );
};
