import React from 'react';
import {
  Button as MaterialUiButton,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';

interface IProps extends ButtonProps {
  loading?: boolean;
  colorLoading?: 'inherit' | 'primary' | 'secondary' | undefined;
}

export const Button: React.FC<IProps> = ({
  loading,
  color = 'secondary',
  colorLoading = undefined,
  children,
  ...props
}) => {
  return (
    <MaterialUiButton color={color} disabled={loading} {...props}>
      {loading ? (
        <CircularProgress
          size={25}
          color={colorLoading}
          style={{ color: colorLoading === undefined ? '#fff' : '' }}
        />
      ) : (
        children
      )}
    </MaterialUiButton>
  );
};
