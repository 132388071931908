import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IOpenMaps {
  apiKey: string;
}

interface IOpenMapsContext {
  loadOpenMaps(companyDocument: number): Promise<IOpenMaps | undefined>;
  saveOpenMaps(data: IOpenMaps): Promise<void>;
}

const OpenMapsContext = createContext<IOpenMapsContext>({} as IOpenMapsContext);

const OpenMapsProvider: React.FC = ({ children }) => {
  const loadOpenMaps = useCallback(async (companyDocument: number) => {
    const response = await api
      .get(`/integrations/openmaps/config/${companyDocument}`)
      .then((response) => response.data)
      .catch(() => {
        return null;
      });

    return response;
  }, []);

  const saveOpenMaps = useCallback(async (data: IOpenMaps) => {
    await api.post('/integrations/openmaps/config', data);
  }, []);

  return (
    <OpenMapsContext.Provider
      value={{
        loadOpenMaps,
        saveOpenMaps,
      }}
    >
      {children}
    </OpenMapsContext.Provider>
  );
};

function useOpenMaps(): IOpenMapsContext {
  const context = useContext(OpenMapsContext);

  if (!context) {
    throw new Error('useOpenMaps must be used within a OpenMapsProvider');
  }

  return context;
}

export { OpenMapsProvider, useOpenMaps };
