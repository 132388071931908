import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { TypeVehicleReport } from '../pages/Vehicle';

const docDefinition = (
  img: string,
  data: TypeVehicleReport[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Veículos',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Veículos',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    {
      margin: [0, 10, 0, 0],
      table: {
        widths: ['*', 'auto', 'auto', '*', '*'],
        body: [
          [
            {
              text: 'Nome',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Placa',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Status',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Tipo de Veículo',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Motorista',
              alignment: 'left',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
          ],
          ...generateTable(data),
        ],
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: TypeVehicleReport[]) {
  const rows = data.map((vehicle) => {
    return [
      {
        text: vehicle.name,
        alignment: 'left',
        fontSize: 8,
      },
      {
        text: vehicle.plate,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: vehicle.status,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: vehicle.vehicleType,
        alignment: 'left',
        fontSize: 8,
      },
      {
        text: vehicle.driver,
        alignment: 'left',
        fontSize: 8,
      },
    ];
  });

  return rows;
}

export async function generateVehicleReportPDF(data: TypeVehicleReport[]) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
