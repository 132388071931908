import { Dialog, DialogTitle } from '@material-ui/core';

import { IItem, useManualOrder } from '../../../hooks/manual-order';
import { IItems } from '../ItemsTable';
import ItemForm from './ItemForm';
import ItemList from './ItemList';

interface IProps {
  open: boolean;
  items: IItem[];
  setItem: React.Dispatch<React.SetStateAction<IItems>>;
  onClose: () => void;
}

export const ItemModal: React.FC<IProps> = (props) => {
  const { option } = useManualOrder();

  return (
    <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.onClose}>
      <DialogTitle>Novo Item</DialogTitle>

      {option === 0 ? (
        <ItemList
          setItem={props.setItem}
          selectedItems={props.items}
          onClose={props.onClose}
        />
      ) : (
        <ItemForm setItem={props.setItem} onClose={props.onClose} />
      )}
    </Dialog>
  );
};
