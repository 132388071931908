import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { FormHelperText, Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button } from '../../../../../components/Button';
import { useOrder } from '../../../hooks/order';

import { Container } from './styles';
import { useCallback, useEffect, useState } from 'react';

export const XML = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { importXML } = useOrder();
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({ accept: '.xml' });
  const [files, setFiles] = useState<{ name: string; XML: string }[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      (async () => {
        for await (const file of acceptedFiles) {
          const reader = new FileReader();

          const text: string = await new Promise((resolve, reject) => {
            reader.onerror = () => {
              reader.abort();

              reject(new DOMException('Problem parsing input file.'));
            };
            reader.onload = (e) => {
              const data = e.target?.result;

              if (typeof data === 'string') resolve(data);
            };
            reader.readAsText(file);
          });

          if (text) {
            setError('');
            setFiles((prevState) => {
              const newState = prevState.filter(
                (value) => value.name !== file.name
              );

              return [...newState, { name: file.name, XML: text }];
            });
          }
        }
      })();
    }
  }, [acceptedFiles]);

  const handleDelete = useCallback((index) => {
    setFiles((prevState) => {
      prevState.splice(index, 1);
      return [...prevState];
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    if (files.length > 0) {
      try {
        setLoading(true);
        await importXML({ xmlsNFe: [...files.map((file) => file.XML)] }).then(
          () => {
            setFiles([]);
            enqueueSnackbar('XMLs enviado com sucesso', { variant: 'success' });
          }
        );
      } catch (error) {
        enqueueSnackbar('Erro ao enviar os XMLs', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    } else {
      setError('Selecione pelo menos um arquivo XML para enviar');
    }
  }, [enqueueSnackbar, files, importXML]);

  const XMLList = files.map((file, index) => {
    return (
      <li key={file.name}>
        {file.name}
        <IconButton
          color="inherit"
          size="small"
          onClick={() => handleDelete(index)}
        >
          <DeleteIcon />
        </IconButton>
      </li>
    );
  });

  return (
    <Grid container spacing={1} style={{ minHeight: 125 }}>
      <Grid item xs={12}>
        <section>
          <Container
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <p>Arraste ou clique para selecionar os XMLs</p>
          </Container>

          <aside>
            <h4>Arquivos XML</h4>
            <ul>{XMLList}</ul>
          </aside>
        </section>
      </Grid>

      {error && (
        <Grid item xs={12}>
          <FormHelperText error style={{ textAlign: 'center' }}>
            {error}
          </FormHelperText>
        </Grid>
      )}

      <Grid container item xs={12} justifyContent="center">
        <Button
          variant="contained"
          color="secondary"
          type="button"
          loading={loading}
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </Grid>
    </Grid>
  );
};
