import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
}));
