import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { RoutePlanningForm } from '../pages/RoutePlanningForm';
import { RoutePlanningList } from '../pages/RoutePlanningList';
import { RoutePlanningProvider } from '../../route-planning/hooks/route-planning';

import { UserManagementProvider } from '../../user-management/hooks/user-management';
import { DistributionCenterProvider } from '../../distribution-center/hooks/distribution-center';

export const RoutePlanningRoute: React.FC = () => (
  <DistributionCenterProvider>
    <UserManagementProvider>
      <RoutePlanningProvider>
        <Switch>
          <PrivateRoute
            path={ROUTES.routePlanningList}
            exact
            permission={PERMISSIONS.PLANNING_VIEW}
            component={RoutePlanningList}
          />
          <PrivateRoute
            path={ROUTES.createRoutePlanning}
            component={RoutePlanningForm}
          />
          <PrivateRoute
            path={ROUTES.updateRoutePlanning}
            component={RoutePlanningForm}
          />
        </Switch>
      </RoutePlanningProvider>
    </UserManagementProvider>
  </DistributionCenterProvider>
);
