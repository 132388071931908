import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';

const docDefinition = (img: string): TDocumentDefinitions => ({
  pageOrientation: 'landscape',
  info: {
    title: 'Relatório de Separação',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      table: {
        widths: [150, '*', 150],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, true, false, true],
            },
            {
              text: 'Relatório de Separação - Shark Tratores',
              bold: true,
              alignment: 'center',
              fontSize: 18,
              margin: [0, 15, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: '',
              border: [false, true, false, true],
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: 'NOME_TRANSPORTADORA',
              bold: true,
              alignment: 'center',
              fillColor: '#CCC',
              fontSize: 8,
              border: [false, false, false, false],
            },
          ],
        ],
      },
      margin: [0, 5, 0, 0],
    },
    {
      table: {
        widths: ['*'],
        body: [
          [
            {
              text: 'RODONAVES TRANSPORTES E ENC LTDA.',
              bold: true,
              alignment: 'center',
              fontSize: 12,
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      table: {
        widths: [200, '*', 200],
        body: [
          [
            {
              table: {
                widths: ['auto', '*'],
                body: [
                  [
                    { text: 'VOLUMES', bold: true },
                    { text: 'LOAD', bold: true, alignment: 'center' },
                  ],
                  [
                    {
                      text: '19',
                      bold: true,
                      alignment: 'center',
                      fontSize: 12,
                    },
                    {
                      text: '0002597472',
                      bold: true,
                      alignment: 'center',
                      fontSize: 12,
                    },
                  ],
                ],
              },
              border: [false, false, false, false],
              style: {
                bold: true,
                alignment: 'center',
                fontSize: 10,
              },
              layout: {
                fillColor: function (rowIndex, node, columnIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null;
                },
              },
            },
            {
              text: 'QRCODE',
              alignment: 'center',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            {
              text: 'REGULAR',
              bold: true,
              alignment: 'center',
              fontSize: 32,
              border: [false, false, false, false],
            },
          ],
        ],
      },
      margin: [0, 5, 0, 0],
    },
    {
      style: 'itemsTable',
      table: {
        widths: ['auto', 'auto', 'auto', 'auto', '*', 'auto'],
        body: [
          [
            { text: 'DELIVERY', bold: true },
            { text: 'CARTON', bold: true },
            { text: 'POSIÇÃO ATUAL', bold: true },
            { text: 'LOAD', bold: true },
            { text: 'TSP', bold: true },
            { text: 'UF', bold: true },
          ],
          ...generateTable(),
        ],
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          if (rowIndex === 0) {
            return '#CCCCCC';
          }
          // return [0, 6].includes(columnIndex) ? '#CCCCCC' : null;
        },
        paddingTop: function (i, node) {
          return 2;
        },
        paddingBottom: function (i, node) {
          return 2;
        },
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
    table: {
      margin: [0, 10, 0, 0],
    },
    itemsTable: {
      margin: [0, 5, 0, 0],
      fontSize: 10,
      alignment: 'center',
    },
  },
});

function generateTable() {
  const rows = [];
  const mockData = [
    {
      delivery: '24075975Q4',
      carton: '00009999990029411308',
      position: 'OUT-AUD-6A',
      load: '0002597472',
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'SP',
    },
    {
      delivery: '24076839Q4',
      carton: '00009999990029411308',
      position: 'OUT-AUD-6A',
      load: '0002597472',
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'MG',
    },
    {
      delivery: '24076851Q4',
      carton: '00009999990029411308',
      position: 'OUT-AUD-6A',
      load: '0002597472',
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'SP',
    },
    {
      delivery: '24080731Q4',
      carton: '00009999990029411308',
      position: 'OUT-AUD-6A',
      load: '0002597472',
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'SP',
    },
  ];
  // ['DELIVERY', 'CARTON', 'POSIÇÃO ATUA', 'LOAD', 'TSP', 'UF']
  for (let i = 0; i < mockData.length; i++) {
    rows.push([
      mockData[i].delivery,
      mockData[i].carton,
      mockData[i].position,
      mockData[i].load,
      mockData[i].tsp,
      mockData[i].state,
    ]);
  }

  return rows;
}

export async function generateSeparationPdf() {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64)).open();
  }
}
