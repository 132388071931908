import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { format } from 'date-fns';

import { TableCell } from '../../../../components/Table/TableCell';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { IRoutePlanningHistoryPaginated, useRoute } from '../../hooks/route';
import { BatchCollapseRow } from './BatchCollapseRow';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

interface IProps {
  date: string;
}

export const RouteHistoryTable = ({ date }: IProps) => {
  const { loadRoutePlanningHistory } = useRoute();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openCollapse, setOpenCollapse] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [routeHistory, setRouteHistory] =
    useState<IRoutePlanningHistoryPaginated>({
      routePlanningHistories: [],
      total: 0,
    });

  const columns: IBasicColumn[] = [
    { name: '', padding: 'checkbox' },
    { name: 'Lote' },
    { name: 'Nome do planejamento' },
    { name: 'Data do planejamento', numeric: true },
    { name: 'Qtde. pedidos', numeric: true },
    { name: 'Qtde. veículos', numeric: true },
    { name: 'Limite de entregas', numeric: true },
    { name: 'Limite de volumes', numeric: true },
  ];

  useEffect(() => {
    async function asyncLoadRoutePlanningHistory(): Promise<void> {
      try {
        const response = await loadRoutePlanningHistory({
          initialDate: date,
          finalDate: date,
          take: rowsPerPage,
          page: page + 1,
        });

        if (!response) return;

        setRouteHistory(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    asyncLoadRoutePlanningHistory();
  }, [date, loadRoutePlanningHistory, page, rowsPerPage]);

  const handleCollapse = useCallback((batch) => {
    setOpenCollapse((prevState) => {
      const batchIndex = prevState.findIndex((prev) => prev === batch);
      if (batchIndex > -1) prevState.splice(batchIndex, 1);

      const newArray = batchIndex > -1 ? [...prevState] : [...prevState, batch];

      return newArray;
    });
  }, []);

  return (
    <BasicTable
      columns={columns}
      loading={loading}
      total={routeHistory.total}
      pagination={[page, setPage]}
      rowsPerPageState={[rowsPerPage, setRowsPerPage]}
    >
      {routeHistory.routePlanningHistories.map((rh) => (
        <React.Fragment key={rh.batch}>
          <TableRow className={classes.root}>
            <TableCell padding="checkbox">
              <IconButton
                size="small"
                onClick={() => handleCollapse(rh.batch)}
                aria-label="expand collapse row"
              >
                {openCollapse.includes(rh.batch) ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell>{rh.batch}</TableCell>
            <TableCell>{rh.planningName}</TableCell>
            <TableCell align="right">
              {format(new Date(rh.planningDate), 'dd/MM/yyyy HH:mm:ss')}
            </TableCell>
            <TableCell align="right">{rh.quantityOrders}</TableCell>
            <TableCell align="right">{rh.quantityVehicles}</TableCell>
            <TableCell align="right">{rh.maximumDeliveries}</TableCell>
            <TableCell align="right">{rh.maximumVolumes}</TableCell>
          </TableRow>

          <BatchCollapseRow
            batch={rh.batch}
            open={openCollapse.includes(rh.batch)}
            colSpan={columns.length}
          />
        </React.Fragment>
      ))}
    </BasicTable>
  );
};
