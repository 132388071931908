import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IGoogleMaps {
  apiKey: string;
}

interface IGoogleMapsContext {
  loadGoogleMaps(companyDocument: number): Promise<IGoogleMaps | undefined>;
  saveGoogleMaps(data: IGoogleMaps): Promise<void>;
}

const GoogleMapsContext = createContext<IGoogleMapsContext>(
  {} as IGoogleMapsContext
);

const GoogleMapsProvider: React.FC = ({ children }) => {
  const loadGoogleMaps = useCallback(async (companyDocument: number) => {
    const response = await api
      .get(`/integrations/google-maps/config/${companyDocument}`)
      .then((response) => response.data)
      .catch(() => {
        return null;
      });

    return response;
  }, []);

  const saveGoogleMaps = useCallback(async (data: IGoogleMaps) => {
    await api.post('/integrations/google-maps/config', data);
  }, []);

  return (
    <GoogleMapsContext.Provider
      value={{
        loadGoogleMaps,
        saveGoogleMaps,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};

function useGoogleMaps(): IGoogleMapsContext {
  const context = useContext(GoogleMapsContext);

  if (!context) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider');
  }

  return context;
}

export { GoogleMapsProvider, useGoogleMaps };
