import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { DeliveryMaterials } from '../pages/DeliveryMaterials';
import { UserManagementProvider } from '../../user-management/hooks/user-management';

export const DeliveryMaterialsRoute: React.FC = () => (
  <UserManagementProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.deliveryMaterials}
        permission={PERMISSIONS.DELIVERY_MATERIALS_VIEW}
        component={DeliveryMaterials}
      />
    </Switch>
  </UserManagementProvider>
);
