import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';

import { CompanyProvider } from '../../../company/hooks/company';
import { useManualOrder } from '../../hooks/manual-order';
import { CustomerForm } from './CustomerForm';
import { ItemsTable } from './ItemsTable';
import { OrderForm } from './OrderForm';
import Review from './Review';
import { SenderForm } from './SenderForm';

export const ModalContent: React.FC<{ maxWidth?: number }> = ({
  maxWidth,
  children,
}) => {
  return (
    <DialogContent
      style={{
        maxWidth: maxWidth ? maxWidth : 1000,
        margin: 'auto',
      }}
    >
      {children}
    </DialogContent>
  );
};

export const Title = () => {
  const { steps, activeStep } = useManualOrder();

  return (
    <Typography variant="h6" gutterBottom style={{ marginBottom: 32 }}>
      {steps[activeStep]}
    </Typography>
  );
};

function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    case 0:
      return <OrderForm />;
    case 1:
      return <SenderForm />;
    case 2:
      return <CustomerForm />;
    case 3:
      return <ItemsTable />;
    default:
      return 'Unknown stepIndex';
  }
}

interface IProps {
  open: boolean;
}

export const ManualOrderModal: React.FC<IProps> = ({ open }) => {
  const { steps, activeStep, handleToggleManualOrder, handleResetOrder } =
    useManualOrder();

  const styleContent = { padding: '8px 24px', flex: '0 0 auto' };

  useEffect(() => {
    if (!open) handleResetOrder();
  }, [handleResetOrder, open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleToggleManualOrder}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Novo Pedido</DialogTitle>

      <div style={styleContent}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {activeStep === steps.length ? (
        <CompanyProvider>
          <Review />
        </CompanyProvider>
      ) : (
        getStepContent(activeStep)
      )}
    </Dialog>
  );
};
