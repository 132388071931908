import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import { ActionMeta } from 'react-select';

import {
  maskCnpj,
  maskCpf,
  maskPostalcode,
  maskTelephone,
  maskCoordinate,
  unmask,
} from '../../../../../utils';
import { getAddress } from '../../../../../services/viaCep';
import { AsyncSelect, IOption } from '../../../../../components/AsyncSelect';
import { IManualOrder, useManualOrder } from '../../../hooks/manual-order';
import { ModalActions } from '../ModalActions';
import { ModalContent, Title } from '../';
import schema from './schema';

type ICustomer = Pick<IManualOrder, 'customer'>;

export const CustomerForm = () => {
  const {
    order,
    nextOrBack,
    setOrder,
    handleNext,
    handleBack,
    loadCustomerList,
  } = useManualOrder();
  const [data] = useState<ICustomer>(order);

  const formik = useFormik({
    initialValues: {
      customer: {
        ...data.customer,
        addresses: {
          ...data.customer.addresses,
          lat: data.customer.addresses.lat || '',
          long: data.customer.addresses.long || '',
        },
      },
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setOrder((prevState) => ({
        ...prevState,
        customer: {
          ...values.customer,
          addresses: {
            ...values.customer.addresses,
            lat: values.customer.addresses.lat || null,
            long: values.customer.addresses.long || null,
          },
        },
      }));
      nextOrBack === 1 ? handleNext() : handleBack();
    },
  });

  const handleChangePostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);

      const postalcodeLength = 8;
      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf } = address;

          if (logradouro) {
            formik.setFieldValue('customer.addresses.street', logradouro);
          }

          if (bairro) {
            formik.setFieldValue('customer.addresses.neighborhood', bairro);
          }

          if (localidade) {
            formik.setFieldValue('customer.addresses.city', localidade);
          }

          if (uf) {
            formik.setFieldValue('customer.addresses.state', uf);
          }
        }
      }
    },
    [formik]
  );

  const handleChangeWithMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (name === 'customer.documentNumber' && value.length > 11) {
        formik.setFieldValue(name, unmask(maskCnpj(value)));
      } else if (name === 'customer.documentNumber') {
        formik.setFieldValue(name, unmask(maskCpf(value)));
      }

      if (name === 'customer.cellphone') {
        formik.setFieldValue(name, unmask(maskTelephone(value)));
      }

      if (name === 'customer.addresses.postalcode') {
        formik.setFieldValue(name, unmask(maskPostalcode(value)));
      }
    },
    [formik]
  );

  const actionOnChange = (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (option) {
      const { value, otherProps } = option;
      if (otherProps) {
        if (actionMeta.action === 'select-option') {
          formik.setFieldValue('customer.documentNumber', value);
          formik.setFieldValue('customer.name', otherProps.name);
          formik.setFieldValue('customer.email', otherProps.email);
          formik.setFieldValue('customer.cellphone', otherProps.cellphone);
          formik.setFieldValue(
            'customer.addresses.postalcode',
            otherProps.addresses[0]?.postalcode || ''
          );
          formik.setFieldValue(
            'customer.addresses.street',
            otherProps.addresses[0]?.street || ''
          );
          formik.setFieldValue(
            'customer.addresses.number',
            otherProps.addresses[0]?.number || ''
          );
          formik.setFieldValue(
            'customer.addresses.neighborhood',
            otherProps.addresses[0]?.neighborhood || ''
          );
          formik.setFieldValue(
            'customer.addresses.city',
            otherProps.addresses[0]?.city || ''
          );
          formik.setFieldValue(
            'customer.addresses.state',
            otherProps.addresses[0]?.state || ''
          );
          formik.setFieldValue(
            'customer.addresses.complement',
            otherProps.addresses[0]?.complement || ''
          );
          formik.setFieldValue(
            'customer.addresses.lat',
            otherProps.addresses[0]?.lat || ''
          );
          formik.setFieldValue(
            'customer.addresses.long',
            otherProps.addresses[0]?.long || ''
          );
        }
      } else if (actionMeta.action === 'create-option') {
        formik.resetForm();
      }
    } else {
      formik.resetForm();
    }
  };

  function dividePastedLatLong(event: React.ClipboardEvent<HTMLDivElement>) {
    const [newLat, ...rest] = event.clipboardData.getData('Text').split(',');
    const newLong = rest.join('.');

    if (newLat.trim() && rest.join('').trim()) {
      formik.setFieldValue('customer.addresses.lat', newLat);
      formik.setFieldValue('customer.addresses.long', newLong);
      formik.setFieldTouched('customer.addresses.lat', false, false);
      event.preventDefault();
    }
  }

  return (
    <>
      <ModalContent>
        <Title />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ zIndex: 2 }}>
            <AsyncSelect
              searchFunction={loadCustomerList}
              name="customer.documentNumber"
              actionOnChange={actionOnChange}
              setValue={formik.setFieldValue}
              error={formik.errors.customer?.documentNumber}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Nome do Cliente"
              id="customer.name"
              name="customer.name"
              value={formik.values.customer.name}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.name &&
                Boolean(formik.errors.customer?.name)
              }
              helperText={
                formik.touched.customer?.name && formik.errors.customer?.name
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Email"
              id="customer.email"
              name="customer.email"
              value={formik.values.customer.email}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.email &&
                Boolean(formik.errors.customer?.email)
              }
              helperText={
                formik.touched.customer?.email && formik.errors.customer?.email
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Celular"
              id="customer.cellphone"
              name="customer.cellphone"
              value={maskTelephone(formik.values.customer.cellphone)}
              onChange={handleChangeWithMask}
              error={
                formik.touched.customer?.cellphone &&
                Boolean(formik.errors.customer?.cellphone)
              }
              helperText={
                formik.touched.customer?.cellphone &&
                formik.errors.customer?.cellphone
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="CEP"
              id="customer.addresses.postalcode"
              name="customer.addresses.postalcode"
              value={maskPostalcode(
                formik.values.customer.addresses.postalcode
              )}
              onChange={handleChangePostalcodeValue}
              error={
                formik.touched.customer?.addresses?.postalcode &&
                Boolean(formik.errors.customer?.addresses?.postalcode)
              }
              helperText={
                formik.touched.customer?.addresses?.postalcode &&
                formik.errors.customer?.addresses?.postalcode
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Rua"
              id="customer.addresses.street"
              name="customer.addresses.street"
              value={formik.values.customer.addresses.street}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.street &&
                Boolean(formik.errors.customer?.addresses?.street)
              }
              helperText={
                formik.touched.customer?.addresses?.street &&
                formik.errors.customer?.addresses?.street
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Número"
              id="customer.addresses.number"
              name="customer.addresses.number"
              value={formik.values.customer.addresses.number}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.number &&
                Boolean(formik.errors.customer?.addresses?.number)
              }
              helperText={
                formik.touched.customer?.addresses?.number &&
                formik.errors.customer?.addresses?.number
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Bairro"
              id="customer.addresses.neighborhood"
              name="customer.addresses.neighborhood"
              value={formik.values.customer.addresses.neighborhood}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.neighborhood &&
                Boolean(formik.errors.customer?.addresses?.neighborhood)
              }
              helperText={
                formik.touched.customer?.addresses?.neighborhood &&
                formik.errors.customer?.addresses?.neighborhood
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Cidade"
              id="customer.addresses.city"
              name="customer.addresses.city"
              value={formik.values.customer.addresses.city}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.city &&
                Boolean(formik.errors.customer?.addresses?.city)
              }
              helperText={
                formik.touched.customer?.addresses?.city &&
                formik.errors.customer?.addresses?.city
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Estado"
              id="customer.addresses.state"
              name="customer.addresses.state"
              value={formik.values.customer.addresses.state}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.state &&
                Boolean(formik.errors.customer?.addresses?.state)
              }
              helperText={
                formik.touched.customer?.addresses?.state &&
                formik.errors.customer?.addresses?.state
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Complemeto"
              id="customer.addresses.complement"
              name="customer.addresses.complement"
              value={formik.values.customer.addresses.complement}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.complement &&
                Boolean(formik.errors.customer?.addresses?.complement)
              }
              helperText={
                formik.touched.customer?.addresses?.complement &&
                formik.errors.customer?.addresses?.complement
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              label="Latitude"
              id="customer.addresses.lat"
              name="customer.addresses.lat"
              autoComplete="off"
              value={maskCoordinate(formik.values.customer.addresses.lat)}
              onChange={formik.handleChange}
              onPaste={dividePastedLatLong}
              error={
                formik.touched.customer?.addresses?.lat &&
                Boolean(formik.errors.customer?.addresses?.lat)
              }
              helperText={
                formik.touched.customer?.addresses?.lat &&
                formik.errors.customer?.addresses?.lat
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <TextField
              variant="outlined"
              size="small"
              label="Longitude"
              id="customer.addresses.long"
              name="customer.addresses.long"
              autoComplete="off"
              value={maskCoordinate(formik.values.customer.addresses.long)}
              onChange={formik.handleChange}
              error={
                formik.touched.customer?.addresses?.long &&
                Boolean(formik.errors.customer?.addresses?.long)
              }
              helperText={
                formik.touched.customer?.addresses?.long &&
                formik.errors.customer?.addresses?.long
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions validate={formik.handleSubmit} />
    </>
  );
};
