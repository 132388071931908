import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';

import { formatCurrency, unmaskPrice } from '../../../../../utils';
import { useAuth } from '../../../../../hooks/auth';
import {
  IManualOrderParams,
  useManualOrderParams,
} from '../../../../manual-order-params/hooks/manual-order-params';
import {
  ILoadDistributionCenter,
  useDistributionCenter,
} from '../../../../distribution-center/hooks/distribution-center';
import { IManualOrder, useManualOrder } from '../../../hooks/manual-order';
import { ModalActions } from '../ModalActions';
import { ModalContent, Title } from '../';
import schema from './schema';
import {
  ICompanyParams,
  useCompanyParams,
} from '../../../../company-params/hooks/company-params';
import {
  add,
  isFuture,
  parseISO,
} from 'date-fns';

type IOrder = Omit<IManualOrder, 'sender' | 'customer' | 'items'>;

export const OrderForm = () => {
  const {
    data: {
      user: { companyDocument },
    },
  } = useAuth();
  const { getCompanyParams } = useCompanyParams();
  const { getManualOrderParams } = useManualOrderParams();
  const { loadDistributionCenterList } = useDistributionCenter();
  const {
    order,
    nextOrBack,
    shippingOption,
    operationType,
    paymentStatus,
    setOrder,
    handleNext,
    handleBack,
    getUniqueCode,
    loadSenderById,
  } = useManualOrder();

  const [manualOrderParams, setManualOrderParams] =
    useState<IManualOrderParams>();
  const [companyParams, setCompanyParams] = useState<ICompanyParams>();
  const [distributionCenter, setDistributionCenter] = useState<
    ILoadDistributionCenter[]
  >([]);
  const [loadingDistributionCenter, setLoadingDistributionCenter] =
    useState(false);
  const [loadingOrderCode, setLoadingOrderCode] = useState(false);
  const [data, setData] = useState<IOrder>(order);

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      try {
        setLoadingDistributionCenter(true);

        const response = await loadDistributionCenterList(companyDocument);

        if (!response) return;

        setDistributionCenter(response);
        setData((prevState) => ({
          ...prevState,
          distributionCenter:
            response.find((cd) => cd.isPrincipal)?.id.toString() ??
            response[0].id.toString(),
        }));
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDistributionCenter(false);
      }
    }

    loadDistributionCenter();
  }, [loadDistributionCenterList, companyDocument]);

  useEffect(() => {
    async function loadUniqueCode(): Promise<void> {
      try {
        setLoadingOrderCode(true);

        const response = await getUniqueCode();

        if (!response) return;

        setData((prevState) => ({ ...prevState, orderCode: response }));
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingOrderCode(false);
      }
    }

    loadUniqueCode();
  }, [getUniqueCode]);

  useEffect(() => {
    async function loadManualOrderParams(): Promise<void> {
      try {
        const response = await getManualOrderParams();

        if (!response) return;

        setManualOrderParams(response);
        setData((prevState) => {
          const newDate = new Date(data.orderDate);
          newDate.setDate(newDate.getDate() + response.deliveryPrevisionDays);

          return {
            ...prevState,
            serie: response.serieDefault,
            paymentStatus: response.paymentStatusDefault,
            shippingOption: response.shippingTypeDefault,
            shippingCost: response.shippingCostDefault,
            transferFreight: response.transferFreightDefault,
            deliveryPrevisionDate: newDate.toJSON().slice(0, 10),
          };
        });
      } catch (error) {
        console.log(error);
      }
    }

    loadManualOrderParams();
  }, [data.orderDate, getManualOrderParams]);

  useEffect(() => {
    async function loadCompanyParams(): Promise<void> {
      try {
        const response = await getCompanyParams();

        if (!response) return;

        setCompanyParams(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadCompanyParams();
  }, [data.orderDate, getCompanyParams]);

  useEffect(() => {
    async function loadSender(): Promise<void> {
      try {
        if (manualOrderParams && manualOrderParams.senderDefault) {
          const response = await loadSenderById(
            manualOrderParams.senderDefault
          );

          if (response) {
            setData((prevState) => ({
              ...prevState,
              sender: {
                documentNumber: response.documentNumber,
                name: response.name,
                email: response.email,
                cellphone: response.cellphone,
                addresses: {
                  postalcode: response.addresses[0].postalcode,
                  street: response.addresses[0].street,
                  number: response.addresses[0].number,
                  neighborhood: response.addresses[0].neighborhood,
                  city: response.addresses[0].city,
                  state: response.addresses[0].state,
                  complement: response.addresses[0].complement,
                },
                type: 'default',
              },
            }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadSender();
  }, [loadSenderById, manualOrderParams]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
      collectPrevisionDate: data.deliveryPrevisionDate,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setOrder((prevState) => ({
        ...prevState,
        ...values,
        number: values.number !== '' ? values.number : values.orderCode,
        serie: values.serie !== '' ? values.serie : '1',
      }));
      nextOrBack === 1 ? handleNext() : handleBack();
    },
  });

  const handleChangeCurrency = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;

      formik.setFieldValue(name, unmaskPrice(value));
    },
    [formik]
  );

  return (
    <>
      <ModalContent>
        <Title />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Número"
              id="orderCode"
              name="orderCode"
              value={formik.values.orderCode}
              onChange={formik.handleChange}
              error={
                formik.touched.orderCode && Boolean(formik.errors.orderCode)
              }
              helperText={formik.touched.orderCode && formik.errors.orderCode}
              InputProps={{
                readOnly: true,
                endAdornment: loadingOrderCode ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} color="inherit" />
                  </InputAdornment>
                ) : null,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              autoFocus
              fullWidth
              label="Número NF"
              id="number"
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={formik.touched.number && formik.errors.number}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {!manualOrderParams?.hideSerie && (
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Série"
                id="serie"
                name="serie"
                value={formik.values.serie}
                onChange={formik.handleChange}
                error={formik.touched.serie && Boolean(formik.errors.serie)}
                helperText={formik.touched.serie && formik.errors.serie}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          {!manualOrderParams?.hideReferenceCode && (
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Código Referência"
                id="referenceCode"
                name="referenceCode"
                value={formik.values.referenceCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.referenceCode &&
                  Boolean(formik.errors.referenceCode)
                }
                helperText={
                  formik.touched.referenceCode && formik.errors.referenceCode
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              type="date"
              label="Data do Pedido"
              id="orderDate"
              name="orderDate"
              value={formik.values.orderDate}
              onChange={(e) => {
                if (isFuture(add(parseISO(e.target.value), { days: 1 }))) {
                  formik.handleChange(e);
                }
              }}
              error={
                formik.touched.orderDate && Boolean(formik.errors.orderDate)
              }
              helperText={formik.touched.orderDate && formik.errors.orderDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              type="date"
              label="Previsão de Execução"
              id="deliveryPrevisionDate"
              name="deliveryPrevisionDate"
              value={formik.values.deliveryPrevisionDate}
              onChange={formik.handleChange}
              error={
                formik.touched.deliveryPrevisionDate &&
                Boolean(formik.errors.deliveryPrevisionDate)
              }
              helperText={
                formik.touched.deliveryPrevisionDate &&
                formik.errors.deliveryPrevisionDate
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {companyParams?.createCollectionOrderFromManualDeliveryOrder &&
            formik.values.operationType === 'delivery' && (
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="date"
                  label="Previsão de Coleta/Retirada"
                  id="collectPrevisionDate"
                  name="collectPrevisionDate"
                  value={formik.values.collectPrevisionDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.collectPrevisionDate &&
                    Boolean(formik.errors.collectPrevisionDate)
                  }
                  helperText={
                    formik.touched.collectPrevisionDate &&
                    formik.errors.collectPrevisionDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}

          <Grid item xs={12} sm={3}>
            <TextField
              select
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Operação"
              id="operationType"
              name="operationType"
              value={formik.values.operationType}
              onChange={formik.handleChange}
              error={
                formik.touched.operationType &&
                Boolean(formik.errors.operationType)
              }
              helperText={
                formik.touched.operationType && formik.errors.operationType
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {operationType.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {!manualOrderParams?.hidePaymentStatus && (
            <Grid item xs={12} sm={3}>
              <TextField
                select
                variant="outlined"
                size="small"
                required
                fullWidth
                label="Status Pagamento"
                id="paymentStatus"
                name="paymentStatus"
                value={formik.values.paymentStatus}
                onChange={formik.handleChange}
                error={
                  formik.touched.paymentStatus &&
                  Boolean(formik.errors.paymentStatus)
                }
                helperText={
                  formik.touched.paymentStatus && formik.errors.paymentStatus
                }
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {paymentStatus.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item xs={12} sm={3}>
            <TextField
              select
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Centro de Distribuição"
              id="distributionCenter"
              name="distributionCenter"
              value={formik.values.distributionCenter}
              onChange={formik.handleChange}
              error={
                formik.touched.distributionCenter &&
                Boolean(formik.errors.distributionCenter)
              }
              helperText={
                formik.touched.distributionCenter &&
                formik.errors.distributionCenter
              }
              InputProps={{
                endAdornment: loadingDistributionCenter ? (
                  <InputAdornment style={{ marginRight: 24 }} position="end">
                    <CircularProgress size={20} color="inherit" />
                  </InputAdornment>
                ) : null,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value="" disabled>
                <em>Selecione o centro</em>
              </MenuItem>
              {distributionCenter.map((cd) => (
                <MenuItem key={cd.id} value={cd.id}>
                  {cd.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              select
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Opção de Envio"
              id="shippingOption"
              name="shippingOption"
              value={formik.values.shippingOption}
              onChange={formik.handleChange}
              error={
                formik.touched.shippingOption &&
                Boolean(formik.errors.shippingOption)
              }
              helperText={
                formik.touched.shippingOption && formik.errors.shippingOption
              }
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value="" disabled>
                <em>Vazio</em>
              </MenuItem>
              {shippingOption.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {!manualOrderParams?.hideShippingCost && (
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Valor do Frete"
                id="shippingCost"
                name="shippingCost"
                value={formatCurrency(formik.values.shippingCost)}
                onChange={handleChangeCurrency}
                error={
                  formik.touched.shippingCost &&
                  Boolean(formik.errors.shippingCost)
                }
                helperText={
                  formik.touched.shippingCost && formik.errors.shippingCost
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          {!manualOrderParams?.hideTransferFreight && (
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Repasse do Frete"
                id="transferFreight"
                name="transferFreight"
                value={formatCurrency(formik.values.transferFreight)}
                onChange={handleChangeCurrency}
                error={
                  formik.touched.transferFreight &&
                  Boolean(formik.errors.transferFreight)
                }
                helperText={
                  formik.touched.transferFreight &&
                  formik.errors.transferFreight
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              minRows={2}
              maxRows={4}
              label="Observação"
              id="observation"
              name="observation"
              value={formik.values.observation}
              onChange={formik.handleChange}
              error={
                formik.touched.observation && Boolean(formik.errors.observation)
              }
              helperText={
                formik.touched.observation && formik.errors.observation
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions validate={formik.handleSubmit} />
    </>
  );
};
