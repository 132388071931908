import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Grid,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';

import {
  formatCurrency,
  maskWeight,
  unmask,
  unmaskPrice,
} from '../../../../../utils';
import { IItem, useManualOrder } from '../../../hooks/manual-order';
import { IItems } from '../ItemsTable';
import OptionButtons from './OptionButtons';
import ModalButtons from './ModalButtons';
import schema from './schema';

interface IProps {
  setItem: React.Dispatch<React.SetStateAction<IItems>>;
  onClose(): void;
}

const ItemForm = ({ setItem, onClose }: IProps) => {
  const {
    order: { orderCode },
  } = useManualOrder();
  const [data] = useState<IItem>({
    orderCode,
    productId: 0,
    sku: '',
    barcode: '',
    description: '',
    quantity: 0,
    price: 0,
    depth: '',
    width: '',
    height: '',
    weight: '',
  });

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setItem((prevState) => ({ items: [...prevState.items, values] }));
      onClose();
    },
  });

  const handleChangeCurrency = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;

      formik.setFieldValue(name, unmaskPrice(value));
    },
    [formik]
  );

  const handleChangeWeight = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;

      formik.setFieldValue(name, unmask(maskWeight(value)));
    },
    [formik]
  );

  return (
    <>
      <DialogContent>
        <OptionButtons />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              autoFocus
              label="SKU"
              id="sku"
              name="sku"
              value={formik.values.sku}
              onChange={formik.handleChange}
              error={formik.touched.sku && Boolean(formik.errors.sku)}
              helperText={formik.touched.sku && formik.errors.sku}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Código de Barras"
              id="barcode"
              name="barcode"
              value={formik.values.barcode}
              onChange={formik.handleChange}
              error={formik.touched.barcode && Boolean(formik.errors.barcode)}
              helperText={formik.touched.barcode && formik.errors.barcode}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Descrição"
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              required
              fullWidth
              label="Quantidade"
              id="quantity"
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Valor"
              id="price"
              name="price"
              value={formatCurrency(formik.values.price)}
              onChange={handleChangeCurrency}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Comprimento"
              id="depth"
              name="depth"
              value={maskWeight(formik.values.depth)}
              onChange={handleChangeWeight}
              error={formik.touched.depth && Boolean(formik.errors.depth)}
              helperText={formik.touched.depth && formik.errors.depth}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Largura"
              id="width"
              name="width"
              value={maskWeight(formik.values.width)}
              onChange={handleChangeWeight}
              error={formik.touched.width && Boolean(formik.errors.width)}
              helperText={formik.touched.width && formik.errors.width}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Altura"
              id="height"
              name="height"
              value={maskWeight(formik.values.height)}
              onChange={handleChangeWeight}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={formik.touched.height && formik.errors.height}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Peso"
              id="weight"
              name="weight"
              value={maskWeight(formik.values.weight)}
              onChange={handleChangeWeight}
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <ModalButtons validate={formik.handleSubmit} onClose={onClose} />
    </>
  );
};

export default ItemForm;
