import { useEffect, useState } from 'react';
import { CircularProgress, Toolbar, Typography } from '@material-ui/core';

import { formatDateToDayMonthYear } from '../../../../utils';
import { Accordion } from '../../../../components/Accordion';
import { IResult, useRoute } from '../../hooks/route';
import { RouteHistoryTable } from './RouteHistoryTable';

export const RouteHistory = () => {
  const { loadDaysWithBatches } = useRoute();
  const [loading, setLoading] = useState(true);
  const [routeHistoryDates, setRouteHistoryDates] = useState<IResult[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    async function asyncLoadDaysWithBatchs(): Promise<void> {
      try {
        const response = await loadDaysWithBatches();

        if (!response) return;

        setRouteHistoryDates(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    asyncLoadDaysWithBatchs();
  }, [loadDaysWithBatches]);

  return (
    <>
      <Toolbar style={{ padding: '0 8px 0 16px' }}>
        <Typography
          style={{ flex: '1 1 100%' }}
          variant="h6"
          id="accordionTitle"
          component="div"
        >
          Histórico de rotas
        </Typography>
      </Toolbar>

      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress color="inherit" />
        </Typography>
      ) : (
        routeHistoryDates.map((rh, index) => (
          <Accordion
            key={index}
            index={index}
            summary={`${formatDateToDayMonthYear(
              rh.date
            )} - Rotas planejadas: ${rh.quantity}`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <RouteHistoryTable date={rh.date} />
          </Accordion>
        ))
      )}
    </>
  );
};
