import { useTheme } from '@material-ui/core/styles';
import { lighten } from 'polished';

import { Card } from '../../../components/Card';

import { createContext, useContext, useEffect, useState } from 'react';
import { INpsSummary, useNps } from '../hooks/nps';
import { Grid } from '@material-ui/core';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import {
  BsClipboardData,
  BsEmojiNeutral,
  BsQuestionCircle,
} from 'react-icons/bs';

interface INpsCardContext {
  loading: boolean;
  summary: INpsSummary | null;
}

const NpsCardContext = createContext<INpsCardContext>({} as INpsCardContext);

const NpsCardProvider: React.FC = ({ children }) => {
  const { loadNpsSummary, filter } = useNps();

  const [loading, setLoading] = useState(true);

  const [summary, setSummary] = useState<INpsSummary | null>(null);

  useEffect(() => {
    let active = true;

    async function getNpsSummary() {
      try {
        const response = await loadNpsSummary(filter);

        if (!response) return;

        if (active) setSummary(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getNpsSummary();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.initialDate, filter.finalDate]);

  return (
    <NpsCardContext.Provider value={{ loading, summary }}>
      <Grid container spacing={3} justifyContent="center">
        {children}
      </Grid>
    </NpsCardContext.Provider>
  );
};

function useNpsCard(): INpsCardContext {
  const context = useContext(NpsCardContext);

  if (!context) {
    throw new Error('useNpsCard must be used within a NpsCardProvider');
  }

  return context;
}

const NpsCard = ({ name }: { name: keyof INpsSummary }) => {
  const { palette } = useTheme();
  const { summary, loading } = useNpsCard();

  const cardType = {
    notAnswered: {
      title: 'Não Respondido',
      icon: <BsQuestionCircle />,
      color: palette.grey[600],
    },
    promoter: {
      title: 'Promotor',
      icon: <FaThumbsUp />,
      color: palette.success.main,
    },
    neutral: { title: 'Neutro', icon: <BsEmojiNeutral />, color: '#FFFF00' },
    detrator: {
      title: 'detrator',
      icon: <FaThumbsDown />,
      color: palette.error.main,
    },
    average: {
      title: 'Média',
      icon: <BsClipboardData />,
      color:
        Number(summary?.average) >= 8
          ? '#00FF00'
          : Number(summary?.average) >= 7
          ? '#FFFF00'
          : '#FF0000',
    },
  }[name];

  return (
    <Grid item xs={12} sm={4} md={2}>
      <Card
        title={cardType.title}
        icon={cardType.icon}
        cardColor={lighten(0.325, cardType.color)}
        content={Math.round(Number(summary?.[name]) * 100) / 100 || 0}
        loading={loading}
      />
    </Grid>
  );
};

NpsCard.Provider = NpsCardProvider;

export { NpsCard };
