import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

import { ModalContent } from '.';
import { useAuth } from '../../../../hooks/auth';
import { formatCurrency, maskPostalcode } from '../../../../utils';
import { useCompanyParams } from '../../../company-params/hooks/company-params';
import { useCompany } from '../../../company/hooks/company';
import { ISplitOrderItems } from '../../../separation/hooks/separation';
import { useManualOrder } from '../../hooks/manual-order';
import { useOrder } from '../../hooks/order';
import { ModalActions } from './ModalActions';
import { useStyles } from './styles';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

const Review = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompany } = useCompany();
  const { getCompanyParams } = useCompanyParams();
  const {
    data: { user },
  } = useAuth();
  const { loadOrderByOrderCodeAndNumber } = useOrder();
  const { order, operationType, paymentStatus, createOrder, createCollect } =
    useManualOrder();

  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');

  async function createCollectProcess() {
    const { orderCode, number: orderNumber } = order;

    const orderResponse = await loadOrderByOrderCodeAndNumber(
      orderCode,
      orderNumber
    );
    if (!orderResponse) {
      return;
    }

    const { country, id, lat, long, region, ...shippingAddress } =
      orderResponse.shippingAddress;

    const collectData: ISplitOrderItems = {
      itemsId: orderResponse.items.map((item) => item.id),
      orderId: orderResponse.id,
      previsionDate: format(
        (() => {
          const date = new Date(order.collectPrevisionDate);
          const dateWithoutTimezone = new Date(
            date.valueOf() + date.getTimezoneOffset() * 60 * 1000
          );
          return dateWithoutTimezone;
        })(),
        'yyyy-MM-dd'
      ),
      shippingAddress,
      observation: orderResponse.observation,
    };

    createCollect(collectData)
      .then(() => {
        enqueueSnackbar('Processo de Coleta/Retirada criado com sucesso!', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Erro ao criar o Processo de Coleta/Retirada!', {
          variant: 'error',
        });
      });
  }

  useEffect(() => {
    async function sendOrderAndChooseIfCollect(): Promise<void> {
      const {
        createCollectionOrderFromManualDeliveryOrder: createCollectParam,
      } = await getCompanyParams();

      await createOrder(order);
      if (createCollectParam && order.operationType === 'delivery') {
        createCollectProcess();
      }
    }

    sendOrderAndChooseIfCollect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder, order]);

  useEffect(() => {
    async function loadCompany(): Promise<void> {
      try {
        const response = await getCompany(user.companyDocument);

        if (!response) return;

        setCompanyName(response.companyLegalName);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    loadCompany();
  }, [getCompany, user.companyDocument]);

  const formatDate = useCallback((date: string) => {
    const day = date.split('-')[2];
    const month = date.split('-')[1];
    const year = date.split('-')[0];

    return `${day}/${month}/${year}`;
  }, []);

  return (
    <>
      <ModalContent maxWidth={700}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          <strong>Resumo do Pedido</strong>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
              Código: <strong>{order.orderCode}</strong>
            </Typography>
            <Typography gutterBottom>
              Data do pedido: {formatDate(order.orderDate)}
            </Typography>
            <Typography gutterBottom>
              Previsão de entrega: {formatDate(order.deliveryPrevisionDate)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
              Operação:{' '}
              {
                operationType.find((type) => type.value === order.operationType)
                  ?.label
              }
            </Typography>
            <Typography gutterBottom>
              Status do pagamento:{' '}
              {
                paymentStatus.find(
                  (status) => status.value === order.paymentStatus
                )?.label
              }
            </Typography>
            <Typography gutterBottom>
              Opção de envio: {order.shippingOption}
            </Typography>
            <Typography component="div" gutterBottom>
              Transportadora:{' '}
              {loading ? (
                <>
                  <CircularProgress size={20} />
                </>
              ) : (
                companyName
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Remetente
            </Typography>
            <Typography gutterBottom>{order.sender.name}</Typography>
            <Typography gutterBottom>
              {order.sender.addresses.street}
              {', '}
              {order.sender.addresses.number}
              {' - '}
              {order.sender.addresses.neighborhood}
              <br />
              {maskPostalcode(order.sender.addresses.postalcode)}
              {' - '}
              {order.sender.addresses.city}
              {' - '}
              {order.sender.addresses.state}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Cliente
            </Typography>
            <Typography gutterBottom>{order.customer.name}</Typography>
            <Typography gutterBottom>
              {order.customer.addresses.street}
              {', '}
              {order.customer.addresses.number}
              {' - '}
              {order.customer.addresses.neighborhood}
              <br />
              {maskPostalcode(order.sender.addresses.postalcode)}
              {' - '}
              {order.customer.addresses.city}
              {' - '}
              {order.customer.addresses.state}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Items
            </Typography>
            <List disablePadding>
              {order.items.map((item) => (
                <ListItem className={classes.listItem} key={item.sku}>
                  <ListItemText
                    primary={item.description}
                    secondary={item.sku}
                  />
                  <Typography variant="body2" style={{ marginLeft: 16 }}>
                    {formatCurrency(item.price)}
                  </Typography>
                </ListItem>
              ))}
              <ListItem className={classes.listItem}>
                <ListItemText primary="Frete" />
                <Typography variant="subtitle1">
                  {formatCurrency(order.shippingCost)}
                </Typography>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary="Total" />
                <Typography variant="subtitle1" className={classes.total}>
                  {formatCurrency(order.value)}
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions />
    </>
  );
};

export default Review;
