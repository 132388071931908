import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useState } from 'react';

import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { Loading } from '../../../../components/Loading';
import { Main } from '../../../../components/Main';
import { CellActions } from '../../../../components/Table/CellActions';
import { TableCell } from '../../../../components/Table/TableCell';
import { ROUTES } from '../../../../constants';
import { formatCurrency } from '../../../../utils';
import { ProductInfo } from '../../components/ProductInfo';
import { useAuth } from '../../../../hooks/auth';
import { ILoadProduct, useProduct } from '../../hooks/product';
import { useStyles } from './styles';

export const ProductList = () => {
  const classes = useStyles();
  const {
    data: { user },
  } = useAuth();
  const { loadProductList, deleteProduct } = useProduct();

  const [productList, setProductList] = useState<ILoadProduct>({
    total: 0,
    products: [],
  });
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [productId, setProductId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [hasDelay, setHasDelay] = useState<NodeJS.Timeout | null>(null);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, productList.total - page * rowsPerPage);

  const loadOrders = useCallback(
    async (searchValue?: string) => {
      setLoading(true);

      try {
        const query =
          `?page=${page + 1}&take=${rowsPerPage}` +
          // + `&ownerDocument=${}`
          `${searchValue ? `&sku=${searchValue}` : ''}` +
          `${searchValue ? `&description=${searchValue}` : ''} `;
        // + `&barcode=${}`
        // + `&active=${}`;

        const response = await loadProductList(query);

        if (!response) return;

        setProductList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [loadProductList, page, rowsPerPage]
  );

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setProductId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setProductId(null);
  }, []);

  const handleOpenDialogDelete = useCallback((id: number) => {
    setOpenDialogDelete(true);
    setProductId(id);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setProductId(null);
  }, []);

  const handleDeleteProduct = useCallback(async () => {
    if (productId) {
      await deleteProduct(productId);
      setProductList((prevState) => ({
        ...prevState,
        products: prevState.products.filter((rest) => rest.id !== productId),
      }));
      setProductId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteProduct, productId]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchField(value);

    if (hasDelay) clearTimeout(hasDelay);
    const newTimeout = setTimeout(() => {
      loadOrders(value);
    }, 500);
    setHasDelay(newTimeout);
  };

  return (
    <Main
      title="Produtos"
      button="add"
      buttonName="Novo Produto"
      to={ROUTES.createProduct}
      hasPermission="PRODUCT_CREATE"
    >
      <Grid container spacing={1}>
        <ProductInfo
          open={openView}
          productId={productId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar o produto"
          description="Deseja realmente deletar este produto?"
          onSubmit={handleDeleteProduct}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Tipo</TableCell>
                  {user.type === 'ADMIN' && (
                    <TableCell align="right">Preço</TableCell>
                  )}
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <Loading columns={5} rows={rowsPerPage} />
                ) : (
                  productList.products.map((product) => (
                    <TableRow key={product.id} hover>
                      <TableCell component="th" scope="row">
                        {product.sku}
                      </TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell>{product.unit}</TableCell>
                      {user.type === 'ADMIN' && (
                        <TableCell align="right">
                          {formatCurrency(product.price)}
                        </TableCell>
                      )}

                      <TableCell align="right">{product.quantity}</TableCell>
                      <TableCell align="center">
                        <CellActions
                          id={product.id}
                          sendTo="productList"
                          handleOpen={() => handleOpenView(product.id)}
                          handleDelete={() =>
                            handleOpenDialogDelete(product.id)
                          }
                          onEditPermission="PRODUCT_EDIT"
                          onRemovePermission="PRODUCT_REMOVE"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {!loading && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={user.type === 'ADMIN' ? 6 : 5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={productList.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Main>
  );
};
