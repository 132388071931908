import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';

import { Button } from '../../../../components/Button';

import { useSignUp } from '../../hooks/signUp';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const CheckExistsAnotherCompanyDialog: React.FC<IProps> = ({
  open,
  onClose,
}) => {
  const {setIsRegistering} = useSignUp();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      aria-describedby="dialog-description"
    >

      <DialogContent>
        <Typography variant="h6" style={{ font: 'bold' }}>
          Usuário já cadastrado para outra empresa com este documento! Deseja mudar para nova empresa?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
        color="inherit"
        onClick={() => {
          setIsRegistering(false);
          onClose()
        }}
        >
          Não
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};
