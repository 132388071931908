import styled from 'styled-components';
import { shade } from 'polished';

import Button from '@material-ui/core/Button';

interface IButtonProps {
  bgcolor: string;
}

export const Container = styled.div`
  max-width: 496px;
  width: 100%;
`;

export const StyledButton = styled(Button)<IButtonProps>`
  justify-content: left;
  margin: 8px 0;

  color: #fff;
  background: ${({ bgcolor }) => bgcolor};

  &:hover {
    background: ${({ bgcolor }) => shade(0.1, bgcolor)};
  }
`;
