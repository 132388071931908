export enum PERMISSIONS {
  DASHBOARD_VIEW = 'dashboard/view',

  ORDER_VIEW = 'orders/view',
  ORDER_CREATE = 'orders/create',
  ORDER_EDIT = 'orders/edit',
  ORDER_NOTIFY_SHIPPING = 'orders/notify-shipping',
  ORDER_MARK_DELIVERED = 'orders/mark-delivered',
  ORDER_MARK_SEPARATE = 'orders/mark-separate',

  USER_VIEW = 'users/view',
  USER_CREATE = 'users/create',
  USER_EDIT = 'users/edit',
  USER_REMOVE = 'users/remove',

  DISTRIBUTION_CENTER_VIEW = 'distribution-center/view',
  DISTRIBUTION_CENTER_CREATE = 'distribution-center/create',
  DISTRIBUTION_CENTER_EDIT = 'distribution-center/edit',
  DISTRIBUTION_CENTER_REMOVE = 'distribution-center/remove',

  VEHICLE_VIEW = 'vehicle/view',
  VEHICLE_CREATE = 'vehicle/create',
  VEHICLE_EDIT = 'vehicle/edit',
  VEHICLE_REMOVE = 'vehicle/remove',

  VEHICLE_TYPE_VIEW = 'vehicle-type/view',
  VEHICLE_TYPE_CREATE = 'vehicle-type/create',
  VEHICLE_TYPE_EDIT = 'vehicle-type/edit',
  VEHICLE_TYPE_REMOVE = 'vehicle-type/remove',

  ROUTE_VIEW = 'route/view',
  ROUTE_CREATE = 'route/create',
  ROUTE_CANCEL = 'route/cancel',
  ROUTE_FINISH = 'route/finish',
  ROUTE_VIEW_MAP = 'route/view-map',
  ROUTE_CHANGE_DRIVER = 'route/change-driver',
  ROUTE_MOVE_ORDERS = 'route/move-orders',
  ROUTE_VIEW_SIGNATURE = 'route/view-signature',

  ROUTE_ACTIVITY_CANCEL = 'route-activity/cancel',
  ROUTE_ACTIVITY_MARK_AS_DELIVERED = 'route-activity/mark-as-delivered',
  ROUTE_ACTIVITY_VIEW_SIGNATURE = 'route-activity/view-signature',
  ROUTE_ACTIVITY_NOTIFY_CUSTOMER = 'route-activity/notify-customer', // default disabled

  REGISTER_VIEW = 'register/view',
  REPORTS_VIEW = 'reports/view',
  SEPARATION_VIEW = 'separation/view',
  CONFIGURATION_VIEW = 'configuration/view',

  RECEIPT_VIEW = 'receipt/view',
  DELIVERY_MATERIALS_VIEW = 'delivery-materials/view',
  INVENTORY_VIEW = 'inventory/view',

  DELIVERY_REGION_VIEW = 'delivery-region/view',
  DELIVERY_REGION_CREATE = 'delivery-region/create',
  DELIVERY_REGION_EDIT = 'delivery-region/edit',
  DELIVERY_REGION_REMOVE = 'delivery-region/remove',

  PLANNING_VIEW = 'planning/view',
  PLANNING_CREATE = 'planning/create',
  PLANNING_EDIT = 'planning/edit',
  PLANNING_REMOVE = 'planning/remove',

  INTEGRATION_VIEW = 'integration/view',

  PRODUCT_VIEW = 'product/view',
  PRODUCT_CREATE = 'product/create',
  PRODUCT_EDIT = 'product/edit',
  PRODUCT_REMOVE = 'product/remove',

  STORAGE_VIEW = 'storage/view',

  STORE_POSITION_VIEW = 'store-position/view',
  STORE_POSITION_CREATE = 'store-position/create',
  STORE_POSITION_EDIT = 'store-position/edit',
  STORE_POSITION_REMOVE = 'store-position/remove',

  COMPANY_VIEW = 'company/view',
  COMPANY_EDIT = 'company/edit',

  COMPANY_PARAMS_VIEW = 'company-params/view',
  COMPANY_PARAMS_EDIT = 'company-params/edit',

  MANUAL_ORDER_PARAMS_VIEW = 'company-params/manual-order/view',
  MANUAL_ORDER_PARAMS_EDIT = 'company-params/manual-order/edit',

  REPORTS_INVENTORY = 'reports/inventory',
  REPORTS_PROCEDA_OCOREN = 'reports/proceda-ocoren',
  REPORTS_RFID_CONFERENCE = 'reports/rfid-conference',
  REPORTS_RFID_ORDER = 'reports/rfid-order',
  REPORTS_USER = 'reports/users',
  REPORTS_VEHICLE = 'reports/vehicle',

  FORMS_VIEW = 'forms-config/view',
  FORMS_EDIT = 'forms-config/edit',
  FORMS_CREATE = 'forms-config/create',
  FORMS_REMOVE = 'forms-config/remove',
  REPORTS_FORMS = 'reports/forms',
}

export type PROFILE_TYPE =
  | 'ADMIN'
  | 'AUTONOMO'
  | 'DEMO'
  | 'DRIVER'
  | 'USER'
  | 'FINAL_USER'
  | 'ATTENDANT';
