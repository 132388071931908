import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress } from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { MapLeaflet } from '../../components/Route/MapLeaflet';
import { useRoute, IRoute } from '../../hooks/route';

interface IParams {
  routeId?: string;
}

export const GeneratedMap = () => {
  const { routeId } = useParams<IParams>();
  const { loadRouteById } = useRoute();
  const [route, setRoute] = useState<IRoute>({} as IRoute);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadRoute() {
      if (routeId) {
        try {
          const response = await loadRouteById(parseInt(routeId));

          if (!response) return;

          setRoute(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadRoute();
  }, [loadRouteById, routeId]);

  return (
    <Main title="Mapa da rota" button="back" to={ROUTES.dashboard}>
      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress color="secondary" />
        </Typography>
      ) : (
        <MapLeaflet route={route} />
      )}
    </Main>
  );
};
