import { useCallback, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  formatCurrency,
  orderStatusCodeLabel,
  routeStatusLabel,
} from '../../../../../utils';
import { IActivity, IOrder } from '../../../hooks/order';
import { ImageList } from '../ImageList';

import { useStyles } from './styles';

export const OccurrenceRow = (props: { occurrence: IOrder }) => {
  const { occurrence } = props;
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [activityInfo, setActivityInfo] = useState<IActivity | null>(null);

  const lastOcurrenceActivity = occurrence.activity.sort(
    (a, b) => b.route.id - a.route.id
  )[0];

  const formatedHour = useCallback((timeStamp: string) => {
    const splitedTimeStamp = timeStamp.split('T');

    const HOUR = splitedTimeStamp[1].split('.')[0];

    return HOUR;
  }, []);

  const handleOpenView = useCallback((activity: IActivity) => {
    setActivityInfo(activity);
    setOpenView(true);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setTimeout(() => setActivityInfo(null), 450);
  }, []);

  return (
    <>
      <ImageList
        activity={activityInfo}
        open={openView}
        onClose={handleCloseView}
      />

      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenInfo(!openInfo)}
          >
            {openInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {occurrence.number}
        </TableCell>
        <TableCell>{occurrence.customer.name}</TableCell>
        <TableCell>{occurrence.shippingAddress.city}</TableCell>
        <TableCell>{orderStatusCodeLabel(occurrence.statusCode)}</TableCell>
        <TableCell align="right">{occurrence.items.length}</TableCell>
        <TableCell align="right">{formatCurrency(occurrence.value)}</TableCell>
        <TableCell>
          <IconButton
            color="inherit"
            aria-label="activity view"
            onClick={() => handleOpenView(lastOcurrenceActivity)}
            disabled={
              lastOcurrenceActivity === undefined ||
              (lastOcurrenceActivity?.images.length === 0 &&
                !lastOcurrenceActivity?.reason)
            }
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openInfo} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Produtos:
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Preço</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {occurrence.items.map((itemRow) => (
                    <TableRow key={itemRow.id}>
                      <TableCell>{itemRow.description}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(parseFloat(itemRow.price))}
                      </TableCell>
                      <TableCell align="right">{itemRow.quantity}</TableCell>
                      <TableCell align="right">
                        {formatCurrency(
                          Math.round(
                            parseFloat(itemRow.price) * itemRow.quantity * 100
                          ) / 100
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box margin={1} mb={2}>
              <Typography variant="h6" gutterBottom component="div">
                Rotas:
              </Typography>
              {occurrence.activity.length > 0 && (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Inicío</TableCell>
                      <TableCell>Fim</TableCell>
                      <TableCell>Entrega inicío</TableCell>
                      <TableCell>Entrega fim</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {occurrence.activity
                      .sort((a, b) => b.route.id - a.route.id)
                      .map((activityRow) => (
                        <TableRow key={activityRow.id}>
                          <TableCell>{activityRow.route.id}</TableCell>
                          <TableCell>{activityRow.route.routerName}</TableCell>
                          <TableCell>
                            {formatedHour(activityRow.route.startRouteDate)}
                          </TableCell>
                          <TableCell>
                            {formatedHour(activityRow.route.endRouteDate)}
                          </TableCell>
                          <TableCell>{activityRow.prevArriveHour}</TableCell>
                          <TableCell>{activityRow.prevDepartureHour}</TableCell>
                          <TableCell>
                            {routeStatusLabel(activityRow.route.status)}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="inherit"
                              aria-label="activity view"
                              onClick={() => handleOpenView(activityRow)}
                              disabled={
                                activityRow === undefined ||
                                (activityRow?.images.length === 0 &&
                                  !activityRow?.reason)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
