import React, { createContext, useCallback, useContext } from 'react';
import api from '../../../services/api';
import { ICustomer, IShippingAddress } from '../../order/hooks/order';

export interface ITracking {
  id: number;
  orderCode: string;
  orderDate: string;
  companyDocument: string;
  cancelReason: string;
  currency: string;
  gateway: string;
  name: string;
  number: string;
  paymentStatus: string;
  status: string;
  shippingCost: number;
  subtotal: number;
  discount: number;
  value: number;
  weight: string;
  shippedAt: string;
  shippingStatus: string;
  statusCode: string;
  marketplace: string;
  shippingType: string;
  shippingOption: string;
  tracking: string;
  distributionCenterId: number;
  customer: ICustomer;
  shippingAddress: IShippingAddress;
  company?: ICompany;
}

export interface ITrack {
  status: string;
  local: string;
  origin : string;
  destiny: string;
  date: string;
  hour: string
}

export interface IOrderTag {
  tagCode: string;
  tracks: string;
}

export interface ILoadTracking {
  order: ITracking;
  orderTag?: IOrderTag;
}

export interface ICompany {
  id: number;
  documentNumber: string;
  status: string;
  documentType: string;
  type: string;
  companyLegalName: string;
  tradingName: string;
  addresses: Array<{
    id: number;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    postalcode: string;
    city: string;
    state: string;
    country: string;
    type: string;
  }>;
  contacts: Array<{
    id: number;
    type: string;
    contact: string;
    contactName: string;
  }>;
}

interface ITrackingContext {
  loadTracking(id: string): Promise<ILoadTracking>;
}

const TrackingContext = createContext<ITrackingContext>({} as ITrackingContext);

const TrackingProvider: React.FC = ({ children }) => {
  const loadCompanyByDocument = useCallback(
    async (id: string): Promise<ICompany> => {
      const response = await api.get<ICompany>(`/company/by-document/${id}`);

      return response.data;
    },
    []
  );

  const loadTracking = useCallback(
    async (id: string) => {
      const response = await api.get<ILoadTracking>(`/tracking/${id}`);

      const { order, orderTag } = response.data;

      const company = await loadCompanyByDocument(order.companyDocument);

      order.company = company;

      return { order, orderTag };
    },
    [loadCompanyByDocument]
  );

  return (
    <TrackingContext.Provider
      value={{
        loadTracking,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking(): ITrackingContext {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }

  return context;
}

export { TrackingProvider, useTracking };
