import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { Button } from '../../../../components/Button';
import { useForgotPassword } from '../../hooks/forgotPassword';
import { Form } from './styles';

export const ChangePasswordForm = () => {
  const { data, setIsForgotPassword, setCode, userChangePassword } =
    useForgotPassword();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingButton, setLoadingButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Senha é obrigatória')
      .min(6, 'Senha mínima de 6 caracteres'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas diferentes')
      .required('Confirmação obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        await userChangePassword(data.email, { newPassword: values.password });

        const message = 'Senha alterada com sucesso!';

        enqueueSnackbar(message, {
          variant: 'success',
        });

        setIsForgotPassword(false);
        setCode('');
      } catch (error: any) {
        if (error && error.errors) {
          error.errors.map((e: string) =>
            enqueueSnackbar(e, {
              variant: 'error',
            })
          );
        } else {
          enqueueSnackbar('Houve um erro ao alterar a senha, tente novamente', {
            variant: 'error',
          });
        }
      } finally {
        setLoadingButton(false);
      }
    },
  });

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleClickShowPasswordConfirmation = useCallback(() => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  }, [showPasswordConfirmation]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
      noValidate
    >
      <Typography variant="h6" align="left">
        <strong>Esqueci minha senha</strong>
      </Typography>

      <Typography variant="subtitle2" align="left">
        Digite sua nova senha abaixo e confirme.
      </Typography>

      <TextField
        style={{ marginTop: '36px' }}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Senha"
        id="password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        style={{ marginBottom: '36px' }}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        autoComplete="off"
        label="Confirmação da Senha"
        id="passwordConfirmation"
        name="passwordConfirmation"
        type={showPasswordConfirmation ? 'text' : 'password'}
        value={formik.values.passwordConfirmation}
        onChange={formik.handleChange}
        error={
          formik.touched.passwordConfirmation &&
          Boolean(formik.errors.passwordConfirmation)
        }
        helperText={
          formik.touched.passwordConfirmation &&
          formik.errors.passwordConfirmation
        }
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordConfirmation}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Grid container justifyContent="space-between">
        <Button
          color="inherit"
          onClick={() => {
            setIsForgotPassword(false);
            setCode('');
          }}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          loading={loadingButton}
        >
          Confirmar
        </Button>
      </Grid>
    </Form>
  );
};
