import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { CollapseRow } from '../../../../components/Table/CollapseRow';
import { TableCell } from '../../../../components/Table/TableCell';
import { ConferenceStatus, ShippingStatus } from '../../../../constants';
import { RowActionButtons } from '../../../order/components/Order/RowActionButtons';
import { IItems, IOrder } from '../../../order/hooks/order';
import { useSeparation } from '../../hooks/separation';

interface IProps {
  open: boolean;
  order: IOrder;
  colSpan: number;
  maxTableWidth?: number;
  onShippingStatusChange: (orderId: number, status: ShippingStatus) => void;
  onConferenceChange: (
    orderId: number,
    status: ConferenceStatus,
    items: IItems[]
  ) => void;
  onReferencedItemsChange: (orderId: number, itemsId: number[]) => void;
}

export const ItemCollapseRow = ({
  order,
  open,
  colSpan,
  maxTableWidth,
  onShippingStatusChange,
  onConferenceChange,
  onReferencedItemsChange,
}: IProps) => {
  const { loadItemsFractioned } = useSeparation();
  const [searchForFractionedItems, setSearchForFractionedItems] =
    useState(false);
  const [items, setItems] = useState<IItems[]>(
    JSON.parse(JSON.stringify(order.items))
  );
  const [fractionedItems, setFractionedItems] = useState<IOrder[]>([]);
  const [loadingFractionedItems, setLoadingFractionedItems] = useState(true);
  const [selectedItemsId, setSelectedItemsId] = useState<number[]>([]);

  const itemsColumns: IBasicColumn[] = [
    { name: '', padding: 'checkbox' },
    { name: 'SKU' },
    { name: 'Barcode' },
    { name: 'Descrição' },
    { name: 'Quantidade' },
    { name: 'Conferido', padding: 'checkbox', align: 'center' },
  ];

  const fractionedItemsColumns: IBasicColumn[] = [
    { name: 'SKU' },
    { name: 'Barcode' },
    { name: 'Descrição' },
  ];

  useEffect(() => {
    setItems(JSON.parse(JSON.stringify(order.items)));
  }, [order.items]);

  useEffect(() => {
    async function getItemsFractioned(): Promise<void> {
      try {
        setLoadingFractionedItems(true);

        const response = await loadItemsFractioned(order.orderCode);

        if (!response) return;

        setFractionedItems(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingFractionedItems(false);
      }
    }

    if (!order.referenceCode) getItemsFractioned();
  }, [
    loadItemsFractioned,
    order.orderCode,
    order.referenceCode,
    searchForFractionedItems,
  ]);

  const isCheckboxDisabled = useCallback(
    (itemIndex: number) => {
      if (
        order.shippingStatus === 'unpacked' &&
        (order.conferenceStatus === 'unchecked' ||
          order.conferenceStatus === 'partially-conferred') &&
        !order.items[itemIndex].isChecked
      )
        return false;

      return true;
    },
    [order.conferenceStatus, order.items, order.shippingStatus]
  );

  const handleConferenceCheckboxChange = useCallback(
    (itemId: number, isChecked: boolean) => {
      setItems((prevState) => {
        const newState = prevState.map((item) => {
          if (item.id === itemId) {
            item.isChecked = isChecked;
          }

          return item;
        });

        return newState;
      });
    },
    []
  );

  const handleSelectCheckboxChange = useCallback((itemId: number) => {
    setSelectedItemsId((prevState) => {
      let newState = [...prevState];

      const itemIndex = prevState.findIndex((id) => id === itemId);

      if (itemIndex !== -1) {
        newState.splice(itemIndex, 1);
      } else {
        newState.push(itemId);
      }

      return newState;
    });
  }, []);

  return (
    <CollapseRow open={open} colSpan={colSpan} noPadding>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        style={{ marginLeft: 16 }}
      >
        Produtos:
      </Typography>
      <BasicTable
        size="small"
        headerWithNoColor
        columns={itemsColumns}
        style={{ width: '100%', maxWidth: maxTableWidth }}
      >
        {items.map((item, index) => (
          <TableRow key={item.id}>
            <TableCell align="center" padding="checkbox">
              <Checkbox
                checked={
                  selectedItemsId.find(
                    (selectedItem) => selectedItem === item.id
                  )
                    ? true
                    : false
                }
                onChange={() => handleSelectCheckboxChange(item.id)}
                disabled={item.wasReferenced || !!order.referenceCode || items.length <= 1}
              />
            </TableCell>
            <TableCell>{item.sku ? item.sku : 'Não preenchido'}</TableCell>
            <TableCell>
              {item.barcode ? item.barcode : 'Não preenchido'}
            </TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell align="right">{item.quantity}</TableCell>
            <TableCell align="center" padding="checkbox">
              <Checkbox
                checked={item.isChecked}
                disabled={isCheckboxDisabled(index)}
                onChange={(_, checked) =>
                  handleConferenceCheckboxChange(item.id, checked)
                }
              />
            </TableCell>
          </TableRow>
        ))}
      </BasicTable>

      <Typography
        variant="h6"
        gutterBottom
        component="div"
        style={{ marginLeft: 16, marginTop: 16 }}
      >
        Pedidos filhos:
      </Typography>
      {loadingFractionedItems ? (
        <Typography component="div" align="center">
          <CircularProgress size={25} />
        </Typography>
      ) : (
        <>
          {fractionedItems.length > 0 ? (
            fractionedItems.map((order) => (
              <BasicTable
                key={order.id}
                title={`Pedido: ${order.identification}`}
                size="small"
                headerWithNoColor
                columns={fractionedItemsColumns}
                style={{
                  width: '100%',
                  maxWidth: maxTableWidth,
                  marginBottom: 16,
                }}
              >
                {order.items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.sku ? item.sku : 'Não preenchido'}
                    </TableCell>
                    <TableCell>
                      {item.barcode ? item.barcode : 'Não preenchido'}
                    </TableCell>
                    <TableCell>{item.description}</TableCell>
                  </TableRow>
                ))}
              </BasicTable>
            ))
          ) : (
            <Typography style={{ marginLeft: 8 }}>
              Nenhum pedido encontrado
            </Typography>
          )}
        </>
      )}

      <Box margin={1} mb={2}>
        <RowActionButtons
          order={order}
          onShippingStatusChange={onShippingStatusChange}
          type="separation"
          onConferenceChange={onConferenceChange}
          separationItems={items}
          fractionedItemsId={selectedItemsId}
          clearFractionedItemsId={() => setSelectedItemsId([])}
          onReferencedItemsChange={onReferencedItemsChange}
          refreshFractionedItems={() =>
            setSearchForFractionedItems((prevState) => !prevState)
          }
        />
      </Box>
    </CollapseRow>
  );
};
