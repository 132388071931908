import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { ILoadVehicleType, useVehicleType } from '../../hooks/vehicle-type';

interface IProps {
  open: boolean;
  vehicleTypeId: number | null;
  onClose: () => void;
}

export const VehicleTypeInfo: React.FC<IProps> = (props) => {
  const { loadVehicleTypeById } = useVehicleType();
  const [vehicleType, setVehicleType] = useState<ILoadVehicleType>(
    {} as ILoadVehicleType
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setVehicleType({} as ILoadVehicleType);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadVehicleType(): Promise<void> {
      if (props.vehicleTypeId) {
        try {
          const response = await loadVehicleTypeById(props.vehicleTypeId);

          if (!response) return;

          setVehicleType(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadVehicleType();
  }, [loadVehicleTypeById, props.vehicleTypeId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Tipo do veículo cadastrado:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nome"
                value={vehicleType.name ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Volume máximo"
                value={vehicleType.maxVolume ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                label="Peso máximo"
                value={vehicleType.maxWeight ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Tamanho"
                value={vehicleType.size ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Quantidade de entregas"
                value={vehicleType.maxSitesNumber ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
