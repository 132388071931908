import React, { useState, useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export enum TypeEnum {
  AUTONOMOUS = 'AUTONOMO',
  MOTOBOY = 'DRIVER',
  DEMO = 'DEMO',
  SHIPPER = 'shipper', // embarcador
  SHIPPING_COMPANY = 'shipping-company', // transportadora
}

export interface ILoadCompany {
  documentNumber: string;
  companyLegalName: string;
}

interface IAddress {
  postalcode: string;
  street: string;
  neighborhood: string;
  number: string;
  city: string;
  state: string;
  complement: string;
}

export interface ITypeVehicle {
  type: string;
}

export interface IVehicle {
  name: string;
  plate: string;
  typeName: string;
  maxVolume: number;
  maxWeight: number;
}

export interface IContact {
  type: string;
  contact: string;
  contactName: string;
}

export interface IUser {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  postalcode: string;
  street: string;
  neighborhood: string;
  number: string;
  city: string;
  state: string;
  country: string;
  complement: string;
  password: string;
  type: TypeEnum;
  status: string;
  userVehicle?: IVehicle;
  companyDocument?: string;
}

export interface ICompany {
  documentNumber: string;
  companyLegalName: string;
  tradingName: string;
  contacts: IContact[];
  addresses: IAddress[];
  type: TypeEnum;
  login: IUser;
}

export interface IDataProfile {
  company: ICompany;
  user: IUser;
}

interface ISignUpContext {
  profileType: TypeEnum;
  setProfileType: React.Dispatch<React.SetStateAction<TypeEnum>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formActive: number;
  setFormActive: React.Dispatch<React.SetStateAction<number>>;
  documentType: string;
  setDocumentType: React.Dispatch<React.SetStateAction<string>>;
  dataProfile: IDataProfile;
  setDataProfile: React.Dispatch<React.SetStateAction<IDataProfile>>;
  isRegistering: boolean;
  setIsRegistering: React.Dispatch<React.SetStateAction<boolean>>;
  getCompany(document: number): Promise<ILoadCompany>;
  createUser(data: IDataProfile): Promise<void>;
  createUserAutonomo(data: IDataProfile): Promise<void>;
  createCompany(data: IDataProfile): Promise<void>;
  getCheckExistsAnotherCompany(companyDocument: string, userDocument: string): Promise<any>;
}

const SignUpContext = createContext<ISignUpContext>({} as ISignUpContext);

const SignUpProvider: React.FC = ({ children }) => {
  const [profileType, setProfileType] = useState<TypeEnum>(TypeEnum.DEMO);
  const [activeStep, setActiveStep] = useState(0);
  const [formActive, setFormActive] = useState(0);
  const [documentType, setDocumentType] = useState('');
  const [dataProfile, setDataProfile] = useState<IDataProfile>(
    {} as IDataProfile
  );
  const [isRegistering, setIsRegistering] = useState(false);

  const getCompany = useCallback(async (document: number) => {
      const response = await api.get(`/company/by-document/${document}`);

      if (response.data) {
        return response.data;
      }
        return undefined;
  }, []);

  const createUser = useCallback(async (data: IDataProfile) => {
    await api.post('/user', data.user);
  }, []);

  const createUserAutonomo = useCallback(async (data: IDataProfile) => {
    await api.post('/user/autonomo', data.user);
  }, []);

  const createCompany = useCallback(async (data: IDataProfile) => {
    await api.post('/company', data.company);
  }, []);

  const getCheckExistsAnotherCompany = useCallback(async (companyDocument: string, userDocument: string) => {
    const response = await api.get(`/user/check/exists-in/another-company/${companyDocument}/${userDocument}`)

    return response.data
  }, []);

  return (
    <SignUpContext.Provider
      value={{
        profileType,
        setProfileType,
        activeStep,
        setActiveStep,
        formActive,
        setFormActive,
        documentType,
        setDocumentType,
        dataProfile,
        setDataProfile,
        isRegistering,
        setIsRegistering,
        getCompany,
        createUser,
        createUserAutonomo,
        createCompany,
        getCheckExistsAnotherCompany
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

function useSignUp(): ISignUpContext {
  const context = useContext(SignUpContext);

  if (!context) {
    throw new Error('useSignUp must be used within a SignUpProvider');
  }

  return context;
}

export { SignUpProvider, useSignUp };
