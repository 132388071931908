import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import api from '../../../services/api';
import { IOrder } from '../../order/hooks/order';

import { format, subDays } from 'date-fns';

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

export interface INps {
  id: number;
  companyDocument: string;
  order: IOrder | null;
  code: string;
  rating: number | null;
  reason: string | null;
  answered: boolean;
  dateAnswer: Date | null;
  treated: boolean;
  resolution: string | null;
  dateResolution: Date | null;
  createdAt: Date | null;
}

export interface INpsResolution {
  resolution: string;
}

export interface ILoadNps {
  total: number;
  nps: INps[];
}

interface INpsFilter {
  initialDate: string;
  finalDate: string;
  isReport?: boolean;
}

export interface INpsSummary {
  promoter: number;
  neutral: number;
  detrator: number;
  notAnswered: number;
  average: number;
}

export type INpsRequest = Omit<INps, 'id'>;

interface INpsContext {
  filter: INpsFilter;
  setFilter: React.Dispatch<React.SetStateAction<INpsFilter>>;
  loadNpsById(id: number): Promise<INps | undefined>;
  loadNpsList(query?: {
    initialDate: string;
    finalDate: string;
    page?: number;
    take?: number;
    isReport?: boolean;
  }): Promise<ILoadNps>;
  loadNpsSummary(query: {
    initialDate: string;
    finalDate: string;
  }): Promise<INpsSummary>;
  updateResolutionNps(id: number, data: INpsResolution): Promise<void>;
  createNps(data: INpsRequest): Promise<void>;
}

const NpsContext = createContext<INpsContext>({} as INpsContext);

const NpsProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<INpsFilter>({
    initialDate: formatDate(subDays(new Date(), 6)),
    finalDate: formatDate(new Date()),
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: formatDate(subDays(new Date(), 6)),
      finalDate: formatDate(new Date()),
      orderNumber: '',
      isReport: false,
    }));
  }, []);

  const loadNpsById = useCallback(async (id: number) => {
    const response = await api.get(`/nps/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadNpsList = useCallback(
    async (query?: {
      initialDate: string;
      finalDate: string;
      page?: number;
      take?: number;
      isReport?: boolean;
    }) => {
      const response = await api.get(
        `/nps${
          query
            ? `?initialDate=${query.initialDate}&finalDate=${query.finalDate}
                ${query.page ? `&page=${query.page}` : ''}
                ${query.take ? `&take=${query.take}` : ''}
                ${query.isReport ? `&isReport=${query.isReport}` : ''}`
            : ''
        }`
      );

      return response.data;
    },
    []
  );

  const loadNpsSummary = useCallback(
    async (query: { initialDate: string; finalDate: string }) => {
      const response = await api.get(
        `/nps/summary?initialDate=${query.initialDate}&finalDate=${query.finalDate}`
      );

      return response.data;
    },
    []
  );

  const createNps = useCallback(async (data: INpsRequest) => {
    await api.post('/nps', data);
  }, []);

  const updateResolutionNps = useCallback(
    async (id: number, data: INpsResolution) => {
      await api.patch(`/nps/resolution/${id}`, data);
    },
    []
  );

  return (
    <NpsContext.Provider
      value={{
        filter,
        setFilter,
        loadNpsById,
        loadNpsList,
        loadNpsSummary,
        createNps,
        updateResolutionNps,
      }}
    >
      {children}
    </NpsContext.Provider>
  );
};

function useNps(): INpsContext {
  const context = useContext(NpsContext);

  if (!context) {
    throw new Error('useNps must be used within a NpsProvider');
  }

  return context;
}

export { NpsProvider, useNps };
