import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Grid,
} from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { Button } from '../../../../../components/Button';
import { useAuth } from '../../../../../hooks/auth';
import { useVehicle, ILoadVehicle } from '../../../../vehicle/hooks/vehicle';
import { useRoute, IRouteUpdateDriver } from '../../../hooks/route';

interface IProps extends DialogProps {
  routeId: number;
  routeName: string;
  driver: {
    id: number;
    driverId: number;
    driverName: string;
    driverDoc: string;
    driverEmail: string;
    driverCellphone: string;
  };
  onClose: () => void;
}

export const ChangeDriver = ({
  routeId,
  routeName,
  driver,
  onClose,
  ...props
}: IProps) => {
  const {
    data: { user },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { updateDriver } = useRoute();
  const { loadVehicleList } = useVehicle();
  const [vehicles, setVehicles] = useState<ILoadVehicle[]>([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    vehicleId: yup.number().required('Você deve selecionar um motorista'),
  });

  const formik = useFormik({
    initialValues: {
      vehicleId: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ vehicleId }, formik) => {
      setLoading(true);

      const index = parseInt(vehicleId);

      const driver: IRouteUpdateDriver = {
        driverName: vehicles[index].user.name,
        driverDoc: vehicles[index].user.cpf,
        driverId: vehicles[index].user.id,
        driverCellphone: vehicles[index].user.phone,
        driverEmail: vehicles[index].user.email,
      };

      try {
        await updateDriver(routeId, driver).then(() => {
          enqueueSnackbar('Motorista trocado com sucesso!', {
            variant: 'success',
          });

          onClose();
          formik.resetForm();
        });
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    async function loadVehicle(): Promise<void> {
      try {
        const response = await loadVehicleList(user.companyDocument);

        if (!response) return;

        setVehicles(response.filter((vehicle) => vehicle.driverId !== null));
      } catch (error) {
        console.log(error);
      }
    }

    loadVehicle();
  }, [loadVehicleList, user.companyDocument]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...props}
    >
      <DialogTitle id="form-dialog-title">
        Trocar motorista: <strong>{routeName}</strong>
      </DialogTitle>

      <DialogContent>
        <form noValidate>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="De"
                value={driver.driverName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Grid container alignItems="center" justifyContent="center">
                <SwapHorizIcon fontSize="large" />
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <TextField
                select
                variant="outlined"
                size="small"
                required
                fullWidth
                label="Para"
                id="vehicleId"
                name="vehicleId"
                value={formik.values.vehicleId}
                onChange={formik.handleChange}
                error={
                  formik.touched.vehicleId && Boolean(formik.errors.vehicleId)
                }
                helperText={formik.touched.vehicleId && formik.errors.vehicleId}
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" disabled>
                  <em>Selecione um motorista</em>
                </MenuItem>
                {vehicles.map(
                  (vehicle, index) =>
                    vehicle.user.id !== driver.driverId && (
                      <MenuItem key={vehicle.id} value={index}>
                        {vehicle.user.name}
                      </MenuItem>
                    )
                )}
              </TextField>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Fechar
        </Button>

        <Button
          variant="contained"
          color="secondary"
          type="submit"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          Trocar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
