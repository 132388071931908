import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';

const docDefinition = (img: string): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Ordem',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Ordem',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },
    {
      style: 'table',
      table: {
        widths: ['auto', '*', 'auto', 'auto'],
        body: [
          [
            { text: 'Transportadora', fillColor: '#CCC', fontSize: 8 },
            {
              text: 'RODONAVES TRANSPORTADORA',
              bold: true,
              alignment: 'center',
              fontSize: 10,
            },
            { text: 'Criação', fillColor: '#CCC', fontSize: 8 },
            { text: '28/07/2022 16:30', bold: true, fontSize: 10 },
          ],
        ],
      },
    },
    {
      style: 'table',
      table: {
        widths: [30, 50, '*', 30, 50],
        body: [
          [
            {
              text: 'NFs',
              alignment: 'center',
              fillColor: '#CCC',
              fontSize: 8,
            },
            {
              text: '4',
              alignment: 'center',
              fontSize: 10,
            },
            {
              text: 'REGULAR',
              alignment: 'center',
              bold: true,
              fontSize: 12,
              border: [false, false, false, false],
            },
            {
              text: 'Volume',
              alignment: 'center',
              fillColor: '#CCC',
              fontSize: 8,
            },
            { text: '19', alignment: 'center', fontSize: 10 },
          ],
        ],
      },
      layout: {
        paddingTop: function (i, node) {
          return 5;
        },
        paddingBottom: function (i, node) {
          return 2;
        },
      },
    },
    {
      style: 'table',
      table: {
        widths: ['auto', '*', 'auto', '*', 'auto', '*'],
        body: [
          [
            { text: 'Data', fillColor: '#CCC', fontSize: 8 },
            {
              text: '23/08/2022',
              alignment: 'center',
              fontSize: 10,
            },
            { text: 'Hora', fillColor: '#CCC', fontSize: 8 },
            { text: '16:30', alignment: 'center', fontSize: 10 },
            { text: 'OTS', fillColor: '#CCC', fontSize: 8 },
            { text: '23/08/2022', alignment: 'center', fontSize: 10 },
          ],
        ],
      },
      layout: {
        paddingTop: function (i, node) {
          return 5;
        },
        paddingBottom: function (i, node) {
          return 4;
        },
      },
    },
    {
      style: 'table',
      table: {
        widths: [40, 100, '*', 40, 100],
        body: [
          [
            {
              text: 'Load',
              alignment: 'center',
              fillColor: '#CCC',
              fontSize: 8,
            },
            {
              text: '0002597472',
              alignment: 'center',
              fontSize: 10,
            },
            {
              text: 'QRCODE',
              alignment: 'center',
              border: [false, false, false, false],
            },
            {
              text: 'Expedição',
              alignment: 'center',
              fillColor: '#CCC',
              fontSize: 8,
            },
            { text: 'ROTA 2', alignment: 'center', fontSize: 10 },
          ],
        ],
      },
      layout: {
        paddingTop: function (i, node) {
          return 5;
        },
        paddingBottom: function (i, node) {
          return 2;
        },
      },
    },
    {
      text: 'Declaramos haver recebido da DHL SUPPLY CHAIN, as mercadorias, Danfes, laudos e suas respectivas FICHAS DE EMERGÊNCIA para os produtos inflamáveis e em perfeitas condições.',
      alignment: 'justify',
      fontSize: 8,
      margin: [0, 5, 0, 5],
    },
    {
      style: 'itemsTable',
      table: {
        widths: [
          'auto',
          'auto',
          'auto',
          'auto',
          '*',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          '*',
        ],
        body: [
          [
            { text: 'Item', bold: true },
            { text: 'Pedido', bold: true },
            { text: 'DANFE', bold: true },
            { text: 'Vol', bold: true },
            { text: 'TSP', bold: true },
            { text: 'UF', bold: true },
            { text: 'Item', bold: true },
            { text: 'Pedido', bold: true },
            { text: 'DANFE', bold: true },
            { text: 'Vol', bold: true },
            { text: 'ESTORNO', bold: true },
          ],
          ...generateTable(),
        ],
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          if (rowIndex === 0) {
            return '#CCCCCC';
          }
          return [0, 6].includes(columnIndex) ? '#CCCCCC' : null;
        },
        paddingTop: function (i, node) {
          return 2;
        },
        paddingBottom: function (i, node) {
          return 2;
        },
      },
    },
    {
      style: 'table',
      table: {
        widths: [70, 5, '*', 20, 100],
        body: [
          [
            {
              text: 'Conferente',
              bold: true,
              alignment: 'center',
              fontSize: 10,
              margin: [0, 10, 0, 0],
              border: [false, false, false, false],
            },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, true] },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [true, true, true, false] },
          ],
          [
            {
              text: 'Motorista\nNOME LEGÍVEL',
              bold: true,
              alignment: 'center',
              fontSize: 10,
              margin: [0, 10, 0, 0],
              border: [false, false, false, false],
            },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, true] },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [true, false, true, true] },
          ],
        ],
        layout: 'noBorders',
      },
    },
    {
      style: 'table',
      table: {
        widths: [70, 5, '*'],
        body: [
          [
            {
              text: 'Observações gerais',
              alignment: 'center',
              fontSize: 10,
              margin: [0, 10, 0, 0],
              border: [false, false, false, false],
            },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [true, true, true, true] },
          ],
        ],
        layout: 'noBorders',
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
    table: {
      margin: [0, 10, 0, 0],
    },
    itemsTable: {
      fontSize: 6,
      alignment: 'center',
    },
  },
});

function generateTable() {
  const rows = [];
  const mockData = [
    {
      order: '24075975Q4',
      danfe: 842933,
      vol: 3,
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'SP',
    },
    {
      order: '24076839Q4',
      danfe: 842961,
      vol: 1,
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'MG',
    },
    {
      order: '24076851Q4',
      danfe: 842970,
      vol: 10,
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'SP',
    },
    {
      order: '24080731Q4',
      danfe: 842975,
      vol: 5,
      tsp: 'RODONAVES TRANSPORTES E ENC. LTDA',
      state: 'SP',
    },
  ];
  // ['Item', 'Pedido', 'DANFE', 'Vol', 'TSP', 'UF', 'Item', 'Pedido', 'DANFE', 'Vol', 'ESTORNO']
  for (let i = 0; i < 30; i++) {
    if (i < mockData.length) {
      rows.push([
        i + 1,
        mockData[i].order ?? '',
        mockData[i].danfe ?? '',
        mockData[i].vol ?? '',
        mockData[i].tsp ?? '',
        mockData[i].state ?? '',
        i + 31,
        '',
        '',
        '',
        '',
      ]);
    } else {
      rows.push([i + 1, '', '', '', '-', '', i + 31, '', '', '', '']);
    }
  }

  return rows;
}

export async function generateRequestPdf() {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64)).open();
  }
}
