import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface ITotvs {
  user: string;
  password: string;
  url: string;
  codCompany: string;
  codFilial: string;
}

interface ITotvsRequest {
  integrationName: string;
  params: ITotvs[];
}

interface ITotvsContext {
  loadTotvs(
    companyDocument: number,
    integrationName: string
  ): Promise<ITotvs | undefined>;
  saveTotvs(data: ITotvsRequest): Promise<void>;
}

const TotvsContext = createContext<ITotvsContext>({} as ITotvsContext);

const TotvsProvider: React.FC = ({ children }) => {
  const loadTotvs = useCallback(
    async (companyDocument: number, integrationName: string) => {
      const response = await api.get(
        `/integrations/config/${companyDocument}/${integrationName}`
      );

      return response.data;
    },
    []
  );

  const saveTotvs = useCallback(async (data: ITotvsRequest) => {
    await api.post('/integrations/config', data);
  }, []);

  return (
    <TotvsContext.Provider
      value={{
        loadTotvs,
        saveTotvs,
      }}
    >
      {children}
    </TotvsContext.Provider>
  );
};

function useTotvs(): ITotvsContext {
  const context = useContext(TotvsContext);

  if (!context) {
    throw new Error('useTotvs must be used within a TotvsProvider');
  }

  return context;
}

export { TotvsProvider, useTotvs };
