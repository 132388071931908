import { format, isValid, parseISO } from 'date-fns';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { activityStatusLabel, routeStatusLabel } from '../../../utils';
import { IDriverRouteReport } from '../pages/DriverRoute';
import { IForm, QuestionType } from '../../form/hooks/form';

const docDefinition = (
  img: string,
  data: IDriverRouteReport[],
  geoFenceMaxDistance?: number
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Rotas por Motorista',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Rotas por Motorista',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },
    data.map((driver, dataIndex) => {
      return [
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: `Motorista: ${driver.name}`,
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
              ],
            ],
          },
        },

        {
          table: {
            widths: ['auto', 64, 'auto', 64, 'auto', '*'],
            body: [
              [
                {
                  text: 'Documento',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: driver.cpf,
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: 'Telefone',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: driver.phone,
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: 'E-mail',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: driver.email,
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
              ],
            ],
          },
        },

        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: `Veículo: ${driver.vehicle.name || 'n/a'}`,
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
              ],
            ],
          },
        },

        {
          table: {
            widths: ['auto', '*', 'auto', '*', 'auto', '*'],
            body: [
              [
                {
                  text: 'Placa',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: driver.vehicle.plate,
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: 'Tipo',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: driver.vehicle.type?.name,
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: 'Status',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
                {
                  text: driver.vehicle.status,
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, false, true, true],
                },
              ],
            ],
          },
        },

        !!driver.routes && driver.routes.length > 0
          ? [
              {
                margin: [0, 10, 0, 0],
                table: {
                  widths: ['*'],
                  body: [
                    [
                      {
                        text: 'Rotas',
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                      },
                    ],
                  ],
                },
              },

              driver.routes.map((route, index) => {
                const activitiesData =
                  route.status !== 'Executed' &&
                  route.activities
                    .sort((a, b) => a.ordem - b.ordem)
                    .map((activity) => {
                      return [
                        {
                          margin: [0, 10, 0, 0],
                          text: activity.ordem,
                          bold: true,
                          fontSize: 8,
                          alignment: 'center',
                          border: [true, false, true, true],
                        },
                        {
                          margin: [-5, -5, -5, -3],
                          table: {
                            widths: ['*'],
                            body: [
                              [
                                {
                                  border: [false, false, false, false],
                                  margin: [-5, 0, -5, -2],
                                  table: {
                                    widths: [75, 75, '*', 100],
                                    body: [
                                      [
                                        {
                                          text: activity.number,
                                          fontSize: 8,
                                          alignment: 'center',
                                          border: [true, false, true, false],
                                        },
                                        {
                                          text: activity.customer.name,
                                          fontSize: 8,
                                          alignment: 'center',
                                          border: [true, false, true, false],
                                        },
                                        {
                                          text: `${activity.customer.address.city} - ${activity.customer.address.state}`,
                                          fontSize: 8,
                                          alignment: 'center',
                                          border: [true, false, true, false],
                                        },
                                        {
                                          text: activityStatusLabel(
                                            activity.status as any
                                          ),
                                          fontSize: 8,
                                          alignment: 'center',
                                          border: [true, false, true, false],
                                        },
                                      ],
                                    ],
                                  },
                                },
                              ],
                              [
                                [
                                  {
                                    text: (({ customer: { address } }) =>
                                      `${address.street}, ${address.number}, ${address.complement} - ${address.neighborhood} - CEP: ${address.postalcode}`.toUpperCase())(
                                      activity
                                    ),
                                    fontSize: 8,
                                    alignment: 'left',
                                    border: [false, true, false, false],
                                  },
                                  {
                                    text: `Início: ${
                                      activity.startHour || 'n/a'
                                    } Término: ${activity.endHour || 'n/a'}`,
                                    fontSize: 8,
                                    alignment: 'left',
                                    border: [false, true, false, false],
                                  },
                                ],
                              ],
                              // [
                              //   [
                              //     {
                              //       text: `Distância da cerca eletrônica ${
                              //         geoFenceMaxDistance
                              //           ? `(max ${geoFenceMaxDistance}m)`
                              //           : ''
                              //       }: ${
                              //          activity.deliveredDistance
                              //         ? activity.deliveredDistance > 1000
                              //           ? `${(activity.deliveredDistance / 1000).toFixed(
                              //               2
                              //             )} quilômetros`
                              //           : `${activity.deliveredDistance} metros`
                              //         : 'Não informado'
                              //       }`,
                              //       fontSize: 8,
                              //       alignment: 'left',
                              //       border: [false, true, false, false],
                              //     },
                              //   ],
                              // ],

                              ...(activity.filledForm
                                ? [questionsTable(activity.filledForm) as any]
                                : []),

                              [
                                [
                                  {
                                    margin: [-5, -3],
                                    table: {
                                      widths: ['*', '*'],
                                      body: [
                                        [
                                          {
                                            margin: [-5, -3],
                                            table: {
                                              widths: ['*'],
                                              body: [
                                                [
                                                  {
                                                    text: 'Imagens',
                                                    fontSize: 9,
                                                    alignment: 'center',
                                                    fillColor: '#f1f0f3',
                                                  },
                                                ],
                                                ...(activity.images.length
                                                  ? activity.images.map(
                                                      (
                                                        { image, id },
                                                        index
                                                      ) => {
                                                        return [
                                                          {
                                                            text: `Imagem ${
                                                              index + 1
                                                            }`,
                                                            link: image,
                                                            color: 'blue',
                                                            decoration: [
                                                              'underline',
                                                            ],
                                                            style: [
                                                              'html-a',
                                                              'html-p',
                                                              'html-div',
                                                            ],
                                                            nodeName: 'A',
                                                            fontSize: 9,
                                                            alignment: 'center',
                                                          },
                                                        ];
                                                      }
                                                    )
                                                  : [
                                                      [
                                                        {
                                                          text: 'Sem Imagens',
                                                          fontSize: 10,
                                                          alignment: 'center',
                                                        },
                                                      ],
                                                    ]),
                                              ],
                                            },
                                          },
                                          {
                                            margin: [-5, -3],
                                            table: {
                                              widths: ['*'],
                                              body: [
                                                [
                                                  {
                                                    text: 'Assinatura',
                                                    fontSize: 9,
                                                    alignment: 'center',
                                                    fillColor: '#f1f0f3',
                                                  },
                                                ],
                                                [
                                                  {
                                                    alignment: 'center',
                                                    ...(activity.responseSignature
                                                      ? {
                                                          image:
                                                            activity.responseSignature,
                                                          width: 135,
                                                          height: 50,
                                                        }
                                                      : {
                                                          text: 'Não Assinado',
                                                          fontSize: 10,
                                                        }),
                                                    border: [false],
                                                  },
                                                ],
                                              ],
                                            },
                                          },
                                        ],
                                      ],
                                    },
                                  },
                                ],
                              ],
                            ],
                          },
                        },
                      ];
                    });

                return [
                  {
                    margin:
                      index === 0 || !activitiesData
                        ? [0, 0, 0, 0]
                        : [0, 5, 0, 0],
                    table: {
                      widths: [
                        24,
                        '*',
                        'auto',
                        '*',
                        'auto',
                        '*',
                        'auto',
                        '*',
                        'auto',
                        '*',
                      ],
                      body: [
                        [
                          {
                            text: 'Nome',
                            fillColor: '#CCC',
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: route.routerName,
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: 'Data',
                            fillColor: '#CCC',
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: isValid(parseISO(route.startRouteDate))
                              ? format(
                                  parseISO(route.startRouteDate),
                                  'HH:mm dd/MM/yyyy'
                                )
                              : route.startRouteDate,
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: 'Tempo',
                            fillColor: '#CCC',
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: `${
                              route.totalTime ? route.totalTime : 0
                            } min`,
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: 'Distância',
                            fillColor: '#CCC',
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: `${
                              route.totalDistance ? route.totalDistance : 0
                            } km`,
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: 'Status',
                            fillColor: '#CCC',
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                          {
                            text: routeStatusLabel(route.status as any),
                            fontSize: 8,
                            alignment: 'center',
                            border:
                              index === 0 || !activitiesData
                                ? [true, false, true, true]
                                : [true, true, true, true],
                          },
                        ],
                      ],
                    },
                  },

                  !!activitiesData && activitiesData.length > 0
                    ? [
                        {
                          margin: [0, -1, 0, 0],
                          table: {
                            dontBreakRows: true,
                            widths: ['auto', '*'],
                            body: [
                              [
                                {
                                  margin: [5, 0],
                                  text: 'Ordem',
                                  fillColor: '#f1f0f3',
                                  fontSize: 8,
                                  alignment: 'center',
                                  border: [true, true, true, true],
                                },
                                {
                                  margin: [-5, -5],

                                  table: {
                                    widths: ['*'],
                                    body: [
                                      [
                                        {
                                          border: [false, false, false, false],
                                          margin: [-5, 0],
                                          table: {
                                            widths: [75, 75, '*', 100],
                                            margin: [0, 0, 0, 0],
                                            border: [
                                              false,
                                              false,
                                              false,
                                              false,
                                            ],
                                            body: [
                                              [
                                                {
                                                  text: 'Pedido',
                                                  fillColor: '#f1f0f3',
                                                  fontSize: 8,
                                                  alignment: 'center',
                                                  border: [
                                                    false,
                                                    false,
                                                    false,
                                                    false,
                                                  ],
                                                },
                                                {
                                                  text: 'Cliente',
                                                  fillColor: '#f1f0f3',
                                                  fontSize: 8,
                                                  alignment: 'center',
                                                  border: [
                                                    true,
                                                    false,
                                                    false,
                                                    false,
                                                  ],
                                                },
                                                {
                                                  text: 'Cidade',
                                                  fillColor: '#f1f0f3',
                                                  fontSize: 8,
                                                  alignment: 'center',
                                                  border: [
                                                    true,
                                                    false,
                                                    false,
                                                    false,
                                                  ],
                                                },
                                                {
                                                  text: 'Status',
                                                  fillColor: '#f1f0f3',
                                                  fontSize: 8,
                                                  alignment: 'center',
                                                  border: [
                                                    true,
                                                    false,
                                                    false,
                                                    false,
                                                  ],
                                                },
                                              ],
                                            ],
                                          },
                                        },
                                      ],
                                    ],
                                  },
                                },
                              ],
                              ...activitiesData,
                            ],
                          },
                        },
                      ]
                    : [],
                ];
              }),
            ]
          : [],
      ];
    }),
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
    tableRoutes: {},
  },
});

function questionsTable(form: {
  id: number;
  dateFilled: string;
  companyDocument: string;
  formConfigId: number;
  formConfig: IForm;
  data: {
    id: number;
    order: number;
    required: boolean;
    subject: string;
    type: QuestionType;
    options: string[];
    value?: string;
    observation?: string;
    valueCheckbox?: string[];
  }[];
}) {
  return [
    {
      margin: [-5, -3],
      table: {
        widths: [20, '*', '*'],
        body: [
          [
            {
              text: '',
              alignment: 'center',
              fillColor: '#f1f0f3',
            },
            {
              text: 'Perguntas',
              alignment: 'center',
              fontSize: 9,
              fillColor: '#f1f0f3',
            },
            {
              text: 'Respostas',
              alignment: 'center',
              fontSize: 9,
              fillColor: '#f1f0f3',
            },
          ],
          ...form.data.map((question, index) => {
            return [
              {
                text: (++index).toString().padStart(2, '0'),
                alignment: 'center',
                fontSize: 9,
                bold: true,
              },
              {
                text: question.subject,
                alignment: 'justify',
                fontSize: 9,
              },
              [QuestionType.SIGNATURE, QuestionType.IMAGE].includes(
                question.type
              ) && question.value
                ? {
                    image: 'data:image/gif;base64,' + question.value,
                    fit: [100, 100],
                  }
                : {
                    text:
                      question.type === QuestionType.CHECKBOX
                        ? question.valueCheckbox?.join(', ')
                        : question.value ?? '',
                    fontSize: 9,
                  },
            ];
          }),
        ],
      },
    },
  ];
}

export async function generateDriverRoutePDF(
  data: IDriverRouteReport[],
  geoFenceMaxDistance?: number
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data, geoFenceMaxDistance)).open();
  }
}
