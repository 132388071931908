import { Switch, Route } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { TrackingProvider } from '../hooks/tracking';

import { Tracking } from '../pages/Tracking';

export const TrackingRoute = () => (
  <TrackingProvider>
    <Switch>
      <Route path={ROUTES.tracking} exact component={Tracking} />
    </Switch>
  </TrackingProvider>
);
