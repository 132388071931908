import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import {
  IOrderImage,
  OrderImageTypeEnum,
} from '../../modules/order/hooks/order';
import { Button } from '../Button';
import { DragAndDropImage } from '../DragAndDropImage';
import { ImageList } from '../ImageList';
import { ModalActions, ModalContent, ModalTitle } from '../Modal';

interface IConfirmationWithImageModal {
  title: string;
  type: OrderImageTypeEnum;
  close: () => void;
  onSubmit: (images: IOrderImage[]) => Promise<void>;
}

export const ConfirmationWithImageModal = (
  props: IConfirmationWithImageModal
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<IOrderImage[]>([]);

  const handleSaveImage = useCallback(
    (newImagesInBase64: string[], files: File[]) => {
      const newImages: IOrderImage[] = files.map((file, index) => ({
        base64: newImagesInBase64[index],
        filename: file.name,
        mimeType: file.type,
        type: props.type,
      }));

      setImages((prevState) => [...prevState, ...newImages]);
    },
    [props.type]
  );

  const handleDeleteImage = useCallback((removedImage: string) => {
    setImages((prevState) => {
      const newImages = [...prevState];
      const imageIndex = newImages.findIndex(
        (image) => image.base64 === removedImage
      );
      newImages.splice(imageIndex, 1);

      return newImages;
    });
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      await props.onSubmit(
        images.map((img) => ({
          ...img,
          base64: img.base64.split(',')[1],
        }))
      );

      props.close();
    } catch (error) {
      enqueueSnackbar('Por favor, tente novamente!', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalTitle>{props.title}</ModalTitle>

      <ModalContent>
        <DragAndDropImage onSave={handleSaveImage} />

        <ImageList
          images={images.map((image) => image.base64)}
          onDelete={handleDeleteImage}
        />
      </ModalContent>

      <ModalActions>
        <Button onClick={props.close} color="inherit">
          Fechar
        </Button>

        <Button onClick={handleSubmit} color="secondary" loading={loading}>
          Confirmar
        </Button>
      </ModalActions>
    </>
  );
};
