import React, { useContext, createContext, useCallback, useState } from 'react';
import api from '../../../services/api';

import { ILoadDistributionCenter } from '../../distribution-center/hooks/distribution-center';

type NumberFieldValue = number | '';

export interface IRoutePlanningRequest {
  name: string;
  distributionCenterId: NumberFieldValue;
  planningStartTime: string;
  deliveryStartTime: string;
  deliveryEndTime: string;
  deliveryTime: NumberFieldValue;
  loadingTime: NumberFieldValue;
  returnToDistributionCenter: boolean;
  useRestrictionZone: boolean;
  isDefault: boolean;
  mapsService: string;
  shippingStatus: string;
  companyDocument: number;
}

export interface ILoadRoutePlanning {
  id: number;
  name: string;
  distributionCenterId: number;
  planningStartTime: string;
  deliveryStartTime: string;
  deliveryEndTime: string;
  deliveryTime: number;
  loadingTime: number;
  returnToDistributionCenter: boolean;
  useRestrictionZone: boolean;
  isDefault: boolean;
  mapsService?: string;
  shippingStatus: string;
  distributionCenter: ILoadDistributionCenter;
  companyDocument: number;
}

interface IRoutePlanningContext {
  routeStatus: Array<{ label: string; value: string }>;
  loadRoutePlanningById(id: number): Promise<ILoadRoutePlanning | undefined>;
  loadRoutePlanningList(companyDocument: number): Promise<ILoadRoutePlanning[]>;
  updateRoutePlanning(id: number, data: IRoutePlanningRequest): Promise<void>;
  createRoutePlanning(data: IRoutePlanningRequest): Promise<void>;
  deleteRoutePlanning(id: number): Promise<void>;
}

const RoutePlanningContext = createContext<IRoutePlanningContext>(
  {} as IRoutePlanningContext
);

const RoutePlanningProvider: React.FC = ({ children }) => {
  const [routeStatus] = useState([
    { label: 'UNPACKED', value: 'unpacked' },
    { label: 'UNSHIPPED', value: 'unshipped' },
    { label: 'SHIPPED', value: 'shipped' },
  ]);

  const loadRoutePlanningById = useCallback(async (id: number) => {
    const response = await api.get(`/planning-config/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadRoutePlanningList = useCallback(async (companyDocument: number) => {
    const response = await api.get(
      `/planning-config?companyDocument=${companyDocument}`
    );

    return response.data;
  }, []);

  const createRoutePlanning = useCallback(
    async (data: IRoutePlanningRequest) => {
      await api.post('/planning-config', data);
    },
    []
  );

  const updateRoutePlanning = useCallback(
    async (id: number, data: IRoutePlanningRequest) => {
      await api.put(`/planning-config/${id}`, data);
    },
    []
  );

  const deleteRoutePlanning = useCallback(async (id: number) => {
    await api.delete(`/planning-config/${id}`);
  }, []);

  return (
    <RoutePlanningContext.Provider
      value={{
        routeStatus,
        loadRoutePlanningById,
        loadRoutePlanningList,
        createRoutePlanning,
        updateRoutePlanning,
        deleteRoutePlanning,
      }}
    >
      {children}
    </RoutePlanningContext.Provider>
  );
};

function useRoutePlanning(): IRoutePlanningContext {
  const context = useContext(RoutePlanningContext);

  if (!context) {
    throw new Error(
      'usePlanningConfigDtoType must be used within a PlanningConfigDtoProvider'
    );
  }

  return context;
}

export { RoutePlanningProvider, useRoutePlanning };
