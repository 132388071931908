import { Button } from '@material-ui/core';
import { ModalActions, ModalTitle } from '../../../../components/Modal';

interface IRedirectModal {
  url: string;
  close: () => void;
}

export const RedirectModal = (props: IRedirectModal) => {
  return (
    <>
      <ModalTitle>Você deve acessar pelo novo link da Plataforma!</ModalTitle>

      <ModalActions>
        <Button
          onClick={() => window.open(props.url, '_self')}
          color="secondary"
        >
          Clique aqui para Acessar
        </Button>
      </ModalActions>
    </>
  );
};
