import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { IProduct } from '../../product/hooks/product';
import { formatCurrency } from '../../../utils';

const docDefinition = (
  img: string,
  data: IProduct[],
  userIsAdmin: boolean
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Produtos',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Produtos',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    {
      margin: [0, 10, 0, 0],
      table: {
        widths: userIsAdmin
          ? ['auto', '*', 'auto', 'auto', 'auto']
          : ['auto', '*', 'auto', 'auto'],
        body: [
          userIsAdmin
            ? [
                {
                  text: 'SKU',
                  alignment: 'left',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Descrição',
                  alignment: 'left',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Tipo',
                  alignment: 'center',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Preço',
                  alignment: 'center',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Quantidade',
                  alignment: 'center',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
              ]
            : [
                {
                  text: 'SKU',
                  alignment: 'left',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Descrição',
                  alignment: 'left',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Tipo',
                  alignment: 'center',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
                {
                  text: 'Quantidade',
                  alignment: 'center',
                  fontSize: 10,
                  bold: true,
                  fillColor: '#CCC',
                },
              ],
          ...generateTable(data, userIsAdmin),
        ],
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: IProduct[], userIsAdmin: boolean) {
  const rows = data.map((product) => {
    return userIsAdmin
      ? [
          {
            text: product.sku,
            alignment: 'left',
            fontSize: 8,
          },
          {
            text: product.description,
            alignment: 'left',
            fontSize: 8,
          },
          {
            text: product.unit,
            alignment: 'center',
            fontSize: 8,
          },
          {
            text: formatCurrency(product.price),
            alignment: 'center',
            fontSize: 8,
          },
          {
            text: product.quantity,
            alignment: 'center',
            fontSize: 8,
          },
        ]
      : [
          {
            text: product.sku,
            alignment: 'left',
            fontSize: 8,
          },
          {
            text: product.description,
            alignment: 'left',
            fontSize: 8,
          },
          {
            text: product.unit,
            alignment: 'center',
            fontSize: 8,
          },
          {
            text: product.quantity,
            alignment: 'center',
            fontSize: 8,
          },
        ];
  });

  return rows;
}

export async function generateProductReportPDF(
  data: IProduct[],
  userIsAdmin: boolean
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data, userIsAdmin)).open();
  }
}
