import { useState, useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  Link,
  IconButton,
  MenuItem,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { getAddress } from '../../../../services/viaCep';
import {
  cpfValidation,
  maskCpf,
  maskTelephone,
  maskPostalcode,
  unmask,
} from '../../../../utils';
import { useSignUp, TypeEnum, ITypeVehicle } from '../../hooks/signUp';

import { Buttons } from '../Buttons';

import { Form } from './styles';
import { CheckExistsAnotherCompanyDialog } from '../CheckExistsAnotherCompanyDialog';

export const UserForm = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [showUser, setShowUser] = useState(false);
  const {
    profileType,
    dataProfile,
    documentType,
    createCompany,
    createUserAutonomo,
    createUser,
    setActiveStep,
    getCheckExistsAnotherCompany,
  } = useSignUp();
  const [data] = useState({
    name: '',
    email: '',
    phone: '',
    cpf: '',
    postalcode: '',
    street: '',
    neighborhood: '',
    number: '',
    city: '',
    state: '',
    country: 'BR',
    complement: '',
    userVehicle: {
      name: '',
      plate: '',
      typeName: '',
      maxVolume: 0,
      maxWeight: 0,
    },
    password: '',
    type: TypeEnum.DEMO,
    status: 'pending',
  });

  const [typeVehicle] = useState<ITypeVehicle[]>([
    { type: 'CARRO' },
    { type: 'MOTO' },
    { type: 'CAMINHÃO' },
  ]);

  useEffect(() => {
    if (profileType === TypeEnum.AUTONOMOUS && documentType === 'cnpj') {
      setShowUser(true);
    }
  }, [documentType, profileType]);

  const validationSchema = yup.object({
    cpf: yup
      .string()
      .required('Insira seu CPF')
      .test(
        'cpf-is-validate',
        'CPF inválido',
        (value) => cpfValidation(value) === true
      ),
    name: yup.string().required('Insira seu nome completo'),
    phone: yup.string().required('Insira um telefone'),
    postalcode: yup.string().required('Por favor, insira um CEP'),
    street: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    neighborhood: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    number: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string().required('Insira um número'),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    city: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    state: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    emailConfirmation: yup
      .string()
      .oneOf([yup.ref('email'), null], 'Emails diferentes')
      .required('Confirmação obrigatória'),
    password: yup
      .string()
      .required('Senha é obrigatória')
      .min(6, 'Senha mínima de 6 caracteres'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas diferentes')
      .required('Confirmação obrigatória'),
    terms: yup.string().oneOf(['Sim'], 'Você deve aceitar os termos de uso'),
  });

  const validationSchemaAutonomo = yup.object({
    cpf: yup
      .string()
      .required('Insira seu CPF')
      .test(
        'cpf-is-validate',
        'CPF inválido',
        (value) => cpfValidation(value) === true
      ),
    name: yup.string().required('Insira seu nome completo'),
    phone: yup.string().required('Insira um telefone'),
    postalcode: yup.string().required('Por favor, insira um CEP'),
    street: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    neighborhood: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    number: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string().required('Insira um número'),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    city: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    state: yup.string().when('postalcode', {
      is: (val: any) => !!val,
      then: yup.string(),
      otherwise: yup.string().required('Insira o CEP'),
    }),
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    emailConfirmation: yup
      .string()
      .oneOf([yup.ref('email'), null], 'Emails diferentes')
      .required('Confirmação obrigatória'),
    password: yup
      .string()
      .required('Senha é obrigatória')
      .min(6, 'Senha mínima de 6 caracteres'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas diferentes')
      .required('Confirmação obrigatória'),
    terms: yup.string().oneOf(['Sim'], 'Você deve aceitar os termos de uso'),
    userVehicle: yup.object().shape({
      name: yup.string().required('Insira o modelo do veículo'),
      plate: yup.string().required('Insira a placa do veículo'),
      typeName: yup.string().required('Insira o tipo do veículo'),
      maxVolume: yup.number().required('Insira o volume'),
      maxWeight: yup.number().required('Insira o peso'),
    }),
  });

  const validationSchemaAutonomoCNPJ = yup.object({
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    emailConfirmation: yup
      .string()
      .oneOf([yup.ref('email'), null], 'Emails diferentes')
      .required('Confirmação obrigatória'),
    password: yup
      .string()
      .required('Senha é obrigatória')
      .min(6, 'Senha mínima de 6 caracteres'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas diferentes')
      .required('Confirmação obrigatória'),
    terms: yup.string().oneOf(['Sim'], 'Você deve aceitar os termos de uso'),
    userVehicle: yup.object().shape({
      name: yup.string().required('Insira o modelo do veículo'),
      plate: yup.string().required('Insira a placa do veículo'),
      typeName: yup.string().required('Insira o tipo do veículo'),
      maxVolume: yup.number().required('Insira o volume'),
      maxWeight: yup.number().required('Insira o peso'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      emailConfirmation: '',
      passwordConfirmation: '',
      terms: 'Não',
    },
    validationSchema:
      profileType === TypeEnum.AUTONOMOUS
        ? documentType === 'cnpj'
          ? validationSchemaAutonomoCNPJ
          : validationSchemaAutonomo
        : validationSchema,
    onSubmit: async (values) => {
      let data: any = {};

      documentType === 'cnpj'
        ? profileType === TypeEnum.AUTONOMOUS
          ? (data = {
              ...dataProfile,
              company: {
                contacts: [
                  ...dataProfile.company.contacts,
                  {
                    ...dataProfile.company.contacts[0],
                    contactName: values.name,
                  },
                  {
                    ...dataProfile.company.contacts[1],
                    contactName: values.name,
                  },
                ],
              },
              user: {
                name: dataProfile.company.companyLegalName,
                cpf: dataProfile.company.documentNumber,
                phone: dataProfile.company.contacts[0].contact,
                postalcode: dataProfile.company.addresses[0].postalcode,
                street: dataProfile.company.addresses[0].street,
                neighborhood: dataProfile.company.addresses[0].neighborhood,
                number: dataProfile.company.addresses[0].number,
                city: dataProfile.company.addresses[0].city,
                state: dataProfile.company.addresses[0].state,
                country: 'BR',
                complement: dataProfile.company.addresses[0].complement,
                companyDocument: dataProfile.user.companyDocument,
                type: values.type,
                status: values.status,
                email: values.email,
                password: values.password,
                userVehicle: {
                  name: values.userVehicle.name,
                  plate: values.userVehicle.plate,
                  typeName: values.userVehicle.typeName,
                  maxVolume: values.userVehicle.maxVolume,
                  maxWeight: values.userVehicle.maxWeight,
                },
              },
            })
          : (data = {
              ...dataProfile,
              company: {
                ...dataProfile.company,
                login: {
                  ...values,
                  companyDocument: dataProfile.company.documentNumber,
                  userVehicle: null,
                },
              },
            })
        : profileType === TypeEnum.AUTONOMOUS
        ? (data = {
            ...dataProfile,
            user: {
              ...dataProfile.user,
              ...values,
            },
          })
        : (data = {
            ...dataProfile,
            user: {
              ...dataProfile.user,
              ...values,
              userVehicle: null,
            },
          });

      try {
        if (
          profileType === TypeEnum.SHIPPER ||
          profileType === TypeEnum.SHIPPING_COMPANY
        ) {
          await createCompany(data);
        } else if (profileType === TypeEnum.AUTONOMOUS) {
          await createUserAutonomo(data);
        } else {
          await createUser(data);
        }
      } catch (error) {
        console.log(error);
      }

      setActiveStep(3);
    },
  });

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleClickShowPasswordConfirmation = useCallback(() => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  }, [showPasswordConfirmation]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChangepostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);

      const postalcodeLength = 8;

      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf } = address;

          if (logradouro) {
            formik.setFieldValue('street', logradouro);
          }

          if (bairro) {
            formik.setFieldValue('neighborhood', bairro);
          }

          if (localidade) {
            formik.setFieldValue('city', localidade);
          }

          if (uf) {
            formik.setFieldValue('state', uf);
          }
        }
      }
    },
    [formik]
  );

  const handleChangeValueWithMask = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const cpfLenght = 11;
      let unmaskedValue = '';

      if (name === 'cpf') {
        unmaskedValue = unmask(maskCpf(value));

        formik.setFieldValue('name', '');
        formik.setFieldValue('phone', '');
        formik.setFieldValue('postalcode', '');
        formik.setFieldValue('street', '');
        formik.setFieldValue('neighborhood', '');
        formik.setFieldValue('number', '');
        formik.setFieldValue('city', '');
        formik.setFieldValue('state', '');
        formik.setFieldValue('complement', '');
        formik.setFieldValue('email', '');
        formik.setFieldValue('emailConfirmation', '');

        if (
          profileType === TypeEnum.SHIPPER ||
          profileType === TypeEnum.SHIPPING_COMPANY
        ) {
          if (unmaskedValue.length === cpfLenght) {
            const userInfo = await getCheckExistsAnotherCompany(
              dataProfile.company.documentNumber,
              unmaskedValue
            );

            if (userInfo.userExists) {
              setOpenDialog(true);
              formik.setFieldValue('name', userInfo.user.name);
              formik.setFieldValue('phone', userInfo.user.phone);
              formik.setFieldValue('postalcode', userInfo.user.postalcode);
              formik.setFieldValue('street', userInfo.user.street);
              formik.setFieldValue('neighborhood', userInfo.user.neighborhood);
              formik.setFieldValue('number', userInfo.user.number);
              formik.setFieldValue('city', userInfo.user.city);
              formik.setFieldValue('state', userInfo.user.state);
              formik.setFieldValue('complement', userInfo.user.complement);
              formik.setFieldValue('email', userInfo.user.email);
              formik.setFieldValue('emailConfirmation', userInfo.user.email);
            }
          }
        }
      } else if (name === 'phone') unmaskedValue = unmask(maskTelephone(value));

      formik.setFieldValue(name, unmaskedValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, getCheckExistsAnotherCompany]
  );

  const handleChangeTermsValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;

      formik.setFieldValue(name, checked ? 'Sim' : 'Não');
    },
    [formik]
  );

  return (
    <>
      <CheckExistsAnotherCompanyDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />

      <Form onSubmit={formik.handleSubmit} noValidate>
        <Typography variant="body1" align="left">
          <strong>Dados do Usuário</strong>
        </Typography>

        <Typography
          variant="subtitle2"
          align="left"
          style={{ marginBottom: '8px' }}
        >
          Campos marcados com * são necessários para cadastro na aplicação.
        </Typography>

        <Grid container spacing={1}>
          {!showUser && (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="CPF"
                  id="cpf"
                  name="cpf"
                  autoFocus
                  value={maskCpf(formik.values.cpf)}
                  onChange={handleChangeValueWithMask}
                  error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                  helperText={formik.touched.cpf && formik.errors.cpf}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Nome completo"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Telefone"
                  id="phone"
                  name="phone"
                  value={maskTelephone(formik.values.phone)}
                  onChange={handleChangeValueWithMask}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="CEP"
                  id="postalcode"
                  name="postalcode"
                  value={maskPostalcode(formik.values.postalcode)}
                  onChange={handleChangepostalcodeValue}
                  error={
                    formik.touched.postalcode &&
                    Boolean(formik.errors.postalcode)
                  }
                  helperText={
                    formik.touched.postalcode && formik.errors.postalcode
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Rua"
                  id="street"
                  name="street"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  error={formik.touched.street && Boolean(formik.errors.street)}
                  helperText={formik.touched.street && formik.errors.street}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Bairro"
                  id="neighborhood"
                  name="neighborhood"
                  value={formik.values.neighborhood}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.neighborhood &&
                    Boolean(formik.errors.neighborhood)
                  }
                  helperText={
                    formik.touched.neighborhood && formik.errors.neighborhood
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Número"
                  id="number"
                  name="number"
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  helperText={formik.touched.number && formik.errors.number}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Cidade"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Estado"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Complemento"
                  id="complement"
                  name="complement"
                  value={formik.values.complement}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.complement &&
                    Boolean(formik.errors.complement)
                  }
                  helperText={
                    formik.touched.complement && formik.errors.complement
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoComplete="off"
              label="Confirmação do Email"
              id="emailConfirmation"
              name="emailConfirmation"
              value={formik.values.emailConfirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.emailConfirmation &&
                Boolean(formik.errors.emailConfirmation)
              }
              helperText={
                formik.touched.emailConfirmation &&
                formik.errors.emailConfirmation
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Senha"
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                label="Confirmação da Senha"
                id="passwordConfirmation"
                name="passwordConfirmation"
                type={showPasswordConfirmation ? 'text' : 'password'}
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirmation}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordConfirmation ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {profileType === TypeEnum.AUTONOMOUS && (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Modelo do Veículo"
                  id="userVehicle.name"
                  name="userVehicle.name"
                  autoFocus
                  value={formik.values.userVehicle?.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userVehicle?.name &&
                    Boolean(formik.errors.userVehicle?.name)
                  }
                  helperText={
                    formik.touched.userVehicle?.name &&
                    formik.errors.userVehicle?.name
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Placa do Veículo"
                  id="userVehicle.plate"
                  name="userVehicle.plate"
                  value={formik.values.userVehicle?.plate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userVehicle?.plate &&
                    Boolean(formik.errors.userVehicle?.plate)
                  }
                  helperText={
                    formik.touched.userVehicle?.plate &&
                    formik.errors.userVehicle?.plate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  variant="outlined"
                  margin="normal"
                  size="medium"
                  required
                  fullWidth
                  label="Tipo do Veículo"
                  id="userVehicle.typeName"
                  name="userVehicle.typeName"
                  value={formik.values.userVehicle?.typeName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userVehicle?.typeName &&
                    Boolean(formik.errors.userVehicle?.typeName)
                  }
                  helperText={
                    formik.touched.userVehicle?.typeName &&
                    formik.errors.userVehicle?.typeName
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Selecione o tipo</em>
                  </MenuItem>
                  {typeVehicle.map((typeVehicle) => (
                    <MenuItem key={typeVehicle.type} value={typeVehicle.type}>
                      {typeVehicle.type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  required
                  fullWidth
                  label="Volume Máximo"
                  id="userVehicle.maxVolume"
                  name="userVehicle.maxVolume"
                  value={formik.values.userVehicle?.maxVolume}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userVehicle?.maxVolume &&
                    Boolean(formik.errors.userVehicle?.maxVolume)
                  }
                  helperText={
                    formik.touched.userVehicle?.maxVolume &&
                    formik.errors.userVehicle?.maxVolume
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  required
                  fullWidth
                  label="Peso Máximo"
                  id="userVehicle.maxWeight"
                  name="userVehicle.maxWeight"
                  value={formik.values.userVehicle?.maxWeight}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userVehicle?.maxWeight &&
                    Boolean(formik.errors.userVehicle?.maxWeight)
                  }
                  helperText={
                    formik.touched.userVehicle?.maxWeight &&
                    formik.errors.userVehicle?.maxWeight
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12}>
            <FormControl required error={Boolean(formik.errors.terms)}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="terms"
                    color="primary"
                    onChange={handleChangeTermsValue}
                  />
                }
                label={
                  <Typography>
                    {'Estou ciente e aceito os '}
                    <Link href="#">termos de uso.</Link>
                  </Typography>
                }
              />

              {formik.touched.terms && (
                <FormHelperText>{formik.errors.terms}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Buttons />
      </Form>
    </>
  );
};
