import { useCallback, useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';

import { CreatePlanning } from '../../../../components/CreatePlanning';
import { Main } from '../../../../components/Main';
import { useLocalStorage } from '../../../../hooks/localStorage';
import { useSettings } from '../../../../hooks/settings';
import { LateOrdersCard } from '../../../dashboard/components/Cards/LateOrdersCard';
import { OrdersDeliveredCard } from '../../../dashboard/components/Cards/OrdersDeliveredCard';
import { OrdersWithOccurrenceCard } from '../../../dashboard/components/Cards/OrdersWithOccurrenceCard';
import { PendingOrdersCard } from '../../../dashboard/components/Cards/PendingOrdersCard';
import { TotalOrdersCard } from '../../../dashboard/components/Cards/TotalOrdersCard';
import { OrderCardClick } from '../../../dashboard/pages/Dashboard';
import { OrderAccordion } from '../../components/Order/Accordion';
import { useOrder } from '../../hooks/order';

type Status = 'order' | 'delivered' | 'occurrence';

export const Order = () => {
  const { selectedPeriod, getDatePeriod } = useSettings();
  const { cardsInfo, getDashboardCardsInfo } = useSettings();
  const { filter, setFilter } = useOrder();

  const [orderCardClick, setOrderCardClick] = useLocalStorage<OrderCardClick>(
    'orderCardClick',
    'order'
  );

  const [statusSelected, setStatusSelected] = useState<Status>('order');
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState({
    totalOrders: cardsInfo.totalOrders,
    orderPercentage: cardsInfo.percentageOrdersVariation,
    totalPending: cardsInfo.totalPendingOrders,
    pendingPercentage: cardsInfo.percentagePendingOrdersVariation,
    totalDelivered: cardsInfo.totalOrdersDelivered,
    deliveredPercentage: cardsInfo.percentageOrdersDeliveredVariation,
    totalLate: cardsInfo.totalBackorders,
    latePercentage: cardsInfo.percentageBackordersVariation,
    totalOccurrence: cardsInfo.totalOrdersWithOccurrence,
    occurrencePercentage: cardsInfo.percentageOrdersWithOccurrenceVariation,
  });

  const isFirstRender = useRef(true);

  const getNewStatus = useCallback((status: string): Status => {
    let newStatus: Status;
    if (['delivered', 'occurrence'].includes(status)) {
      newStatus = status as Status;
    } else {
      newStatus = 'order';
    }

    return newStatus;
  }, []);

  const resetFilter = useCallback(
    (status: string) => {
      const { from, to } = getDatePeriod(selectedPeriod);
      setStatusSelected(getNewStatus(status));

      setFilter({
        number: '',
        customerName: '',
        region: '',
        statusCode: status === 'order' ? '' : status,
        shippingStatus: '',
        initialDate: from,
        finalDate: to,
        shippingOption: '',
        operationType: '',
        product: '',
        npsType: '',
        isOccurrence: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDatePeriod, getNewStatus, selectedPeriod]
  );

  useEffect(() => {
    async function loadDashboardCardsInfo() {
      try {
        setLoading(true);
        const response = await getDashboardCardsInfo({
          initialDate: filter.initialDate,
          finalDate: filter.finalDate,
        });

        if (!response) return;

        setCards({
          totalOrders: response.totalOrders,
          orderPercentage: response.percentageOrdersVariation,
          totalPending: response.totalPendingOrders,
          pendingPercentage: response.percentagePendingOrdersVariation,
          totalDelivered: response.totalOrdersDelivered,
          deliveredPercentage: response.percentageOrdersDeliveredVariation,
          totalLate: response.totalBackorders,
          latePercentage: response.percentageBackordersVariation,
          totalOccurrence: response.totalOrdersWithOccurrence,
          occurrencePercentage:
            response.percentageOrdersWithOccurrenceVariation,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    } else {
      loadDashboardCardsInfo();
    }
  }, [filter.initialDate, filter.finalDate, getDashboardCardsInfo]);

  useEffect(() => {
    resetFilter(orderCardClick);
    setOrderCardClick('order');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilter]);

  return (
    <Main>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={4} md={2}>
          <TotalOrdersCard
            content={cards.totalOrders}
            percentage={cards.orderPercentage}
            loading={loading}
            onClick={() => resetFilter('order')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <PendingOrdersCard
            content={cards.totalPending}
            percentage={cards.pendingPercentage}
            loading={loading}
            onClick={() => resetFilter('pending')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <OrdersDeliveredCard
            content={cards.totalDelivered}
            percentage={cards.deliveredPercentage}
            loading={loading}
            onClick={() => resetFilter('delivered')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <LateOrdersCard
            content={cards.totalLate}
            percentage={cards.latePercentage}
            loading={loading}
            onClick={() => resetFilter('backorders')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <OrdersWithOccurrenceCard
            content={cards.totalOccurrence}
            percentage={cards.occurrencePercentage}
            loading={loading}
            onClick={() => resetFilter('occurrence')}
          />
        </Grid>

        <Grid item xs={12}>
          {statusSelected === 'order' && <OrderAccordion />}

          {statusSelected === 'delivered' && (
            <OrderAccordion statusCode="delivered" />
          )}

          {statusSelected === 'occurrence' && (
            <OrderAccordion statusCode="occurrence" />
          )}

          <CreatePlanning isEnabled={statusSelected === 'order'} />
        </Grid>
      </Grid>
    </Main>
  );
};
