import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';

import {
  ILoadRoutePlanning,
  useRoutePlanning,
} from '../../hooks/route-planning';

interface IProps {
  open: boolean;
  routePlanningId: number | null;
  onClose: () => void;
}

export const RoutePlanningInfo: React.FC<IProps> = (props) => {
  const { loadRoutePlanningById } = useRoutePlanning();
  const [routePlanning, setRoutePlanning] = useState<ILoadRoutePlanning>(
    {} as ILoadRoutePlanning
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setRoutePlanning({} as ILoadRoutePlanning);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadRouteplanning(): Promise<void> {
      if (props.routePlanningId) {
        try {
          const response = await loadRoutePlanningById(props.routePlanningId);

          if (!response) return;

          setRoutePlanning(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadRouteplanning();
  }, [loadRoutePlanningById, props.routePlanningId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Plano de rota cadastrado:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nome"
                value={routePlanning.name ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Centro de Distribuição"
                value={routePlanning.distributionCenter.name ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Hora de Início"
                value={routePlanning.planningStartTime ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Início da Rota"
                value={routePlanning.deliveryStartTime ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Término da Rota"
                value={routePlanning.deliveryEndTime ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Tempo para entrega"
                value={routePlanning.deliveryTime ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Tempo de carregamento"
                value={routePlanning.loadingTime ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Serviço de Mapas"
                value={
                  routePlanning.mapsService === 'openmaps'
                    ? 'Open Maps'
                    : routePlanning.mapsService === 'maplink'
                    ? 'Maplink'
                    : 'Não informado'
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Status do envio"
                value={
                  routePlanning.shippingStatus === 'unpacked'
                    ? 'EMBALADO'
                    : routePlanning.shippingStatus === 'unshipped'
                    ? 'NÃO ENVIADO'
                    : routePlanning.shippingStatus === 'shipped'
                    ? 'ENVIADO'
                    : 'Não informado'
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Retornar ao CD"
                value={routePlanning.returnToDistributionCenter ? 'Sim' : 'Não'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Usar região de entrega"
                value={routePlanning.useRestrictionZone ? 'Sim' : 'Não'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Configuração Padrão"
                value={routePlanning.isDefault ? 'Sim' : 'Não'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
