import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export enum DefaultOperationEnum {
  PICK_UP = 'pick-up',
  DROP_OFF = 'drop-off',
}

export interface ICompanyParams {
  id: number;
  companyDocument: string;
  defaultOperation: DefaultOperationEnum;
  geolocationUpdateTime: number;
  hasToConfirmRoute: boolean;
  skipToEndOfActivity: boolean;
  skipSignature: boolean;
  convertRfidCodeHexToText: boolean;
  splitItemByUnit: boolean;
  requiredToBeepCorrectItem: boolean;
  geoFenceEnable: boolean;
  geoFenceDistance: number;
  appDriverFollowTheOrderOfActivities: boolean;
  appCompareWithSkuCodes: boolean;
  filterAndDisplayByDeliveryDate: boolean;
  createCollectionOrderFromManualDeliveryOrder: boolean;
  useStatusNotExecutedInOrder: boolean;
  doNotLinkCodeWith: boolean;
  codeNotAccepted: string;
  appSynchronizeActivitiesManually: boolean;
  useGeolocationLastExecution: boolean;
  useManualRouteSequencing: boolean;
  appRequiredCompletionChecklist: boolean;
  urlApi: string;
  urlPlatform: string;
  orderOperationTime: number;
  sendNps: boolean;
}

interface ICompanyParamsContext {
  getCompanyParams(): Promise<ICompanyParams>;
  updateCompanyParams(id: number, data: ICompanyParams): Promise<void>;
}

const CompanyParamsContext = createContext<ICompanyParamsContext>(
  {} as ICompanyParamsContext
);

const CompanyParamsProvider: React.FC = ({ children }) => {
  const getCompanyParams = useCallback(async () => {
    const response = await api.get('/company-params/by/document');

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const updateCompanyParams = useCallback(
    async (id: number, data: ICompanyParams) => {
      await api.patch(`/company-params/${id}`, data);
    },
    []
  );

  return (
    <CompanyParamsContext.Provider
      value={{
        getCompanyParams,
        updateCompanyParams,
      }}
    >
      {children}
    </CompanyParamsContext.Provider>
  );
};

function useCompanyParams(): ICompanyParamsContext {
  const context = useContext(CompanyParamsContext);

  if (!context) {
    throw new Error(
      'useCompanyParams must be used within a CompanyParamsProvider'
    );
  }

  return context;
}

export { CompanyParamsProvider, useCompanyParams };
