import { Button, DialogActions } from '@material-ui/core';
import { useManualOrder } from '../../../hooks/manual-order';

interface IProps {
  onClose(): void;
  validate?: () => void;
}

const ModalButtons = ({ onClose, validate }: IProps) => {
  const { option } = useManualOrder();

  return (
    <DialogActions>
      <Button onClick={onClose} color="inherit">
        Fechar
      </Button>

      {option === 1 && (
        <Button variant="contained" color="secondary" onClick={validate}>
          Adicionar
        </Button>
      )}
    </DialogActions>
  );
};

export default ModalButtons;
