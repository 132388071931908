import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IScandit {
  id?: number;
  applicationId: string;
  key: string;
  barcodeSymbologies: string[];
  playBeep: boolean;
  vibrate: boolean;
}

export interface IMobileApplication {
  id: number;
  name: string;
  applicationId: string;
}

export interface IBarcode {
  id: number;
  description: string;
  type: string;
  value: string;
}

interface IScanditContext {
  loadMobileApplication(): Promise<IMobileApplication[]>;
  loadBarcode(): Promise<IBarcode[]>;
  loadScandit(): Promise<IScandit[] | undefined>;
  saveScandit(data: IScandit[], idsRemove: number[]): Promise<void>;
}

const ScanditContext = createContext<IScanditContext>({} as IScanditContext);

const ScanditProvider: React.FC = ({ children }) => {
  const loadMobileApplication = useCallback(async () => {
    const response = await api.get('/mobile-application');

    return response.data;
  }, []);

  const loadBarcode = useCallback(async () => {
    const response = await api.get('/integrations/scandit/barcode/symbologies');

    return response.data;
  }, []);

  const loadScandit = useCallback(async () => {
    const response = await api.get('/integrations/scandit/configs');

    return response.data;
  }, []);

  const saveScandit = useCallback(
    async (data: IScandit[], idsRemove: number[]) => {
      await api.post('/integrations/scandit/configs', { data, idsRemove });
    },
    []
  );

  return (
    <ScanditContext.Provider
      value={{
        loadMobileApplication,
        loadBarcode,
        loadScandit,
        saveScandit,
      }}
    >
      {children}
    </ScanditContext.Provider>
  );
};

function useScandit(): IScanditContext {
  const context = useContext(ScanditContext);

  if (!context) {
    throw new Error('useScandit must be used within a ScanditProvider');
  }

  return context;
}

export { ScanditProvider, useScandit };
