const companies = {
  ativa: [
    '02580316000125',
    '02580316000800',
    '02580316000710',
    '02580316000630',
    '02580316000206',
    '02580316000397',
    '02580316000478',
  ],
};

export function whichClientCompanyIsTheDocument(
  companyDocument?: string | null
): keyof typeof companies | 'default' {
  const companyNameIndex = 0;

  type CompaniesEntries = {
    [K in keyof typeof companies]: [K, typeof companies[K]];
  }[keyof typeof companies][];

  let storedCompanyDocument = '';

  if (!companyDocument) {
    const storedUser = localStorage.getItem('@4Log:user') || '';
    try {
      storedCompanyDocument = JSON.parse(storedUser)?.companyDocument;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    (Object.entries(companies) as CompaniesEntries).find(([, documents]) =>
      documents.includes(companyDocument || storedCompanyDocument)
    )?.[companyNameIndex] || 'default'
  );
}
