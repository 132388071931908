import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

import { activityStatusLabel, regionStatusLabel } from '../../../../../utils';
import { returnColor } from '../../../../../utils/statusColor';
import { OrderInfo } from '../../../../order/components/OrderInfo';
import { ManualOrderProvider } from '../../../../order/hooks/manual-order';
import { OrderProvider } from '../../../../order/hooks/order';
import {
  IActivity,
  ISelectedActivity,
  useActivity,
} from '../../../hooks/activity';
import { CellActionButtons } from '../CellActionButtons';
import { ScheduleProvider } from '../../../../receipt/hooks/schedule';
import { MdEdit, MdEditOff } from 'react-icons/md';
import { BiCaretDown, BiCaretUp, BiCheckCircle } from 'react-icons/bi';
import { useSnackbar } from 'notistack';

interface IProps {
  routeId: number;
  routeName: string;
  activities: IActivity[];
}

export const ActivitiesTable = ({ routeId, routeName, activities }: IProps) => {
  const { selectedActivity, handleSelect, activitySequence } = useActivity();
  const { enqueueSnackbar } = useSnackbar();
  const [openView, setOpenView] = useState(false);
  const [order, setOrder] = useState<{
    orderCode: string | null;
    number: string | null;
  }>({ orderCode: null, number: null });

  const [isSorting, setIsSorting] = useState(false);
  const [orderedRouteActivities, setOrderedRouteActivities] = useState<
    IActivity[]
  >([]);
  const [SortedActivitiesIds, setSortedActivitiesIds] = useState<
    number[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSortedActivitiesIds(null);
  }, [isSorting]);

  const disableCheckbox = useCallback((statusCode: string) => {
    const status = [
      'undelivered',
      'canceled',
      'moved',
      'delivered',
      'occurrence',
    ];

    return status.includes(statusCode);
  }, []);

  const isSelected = useCallback(
    (activity: ISelectedActivity) =>
      selectedActivity
        .map((e) => {
          return e.id;
        })
        .indexOf(activity.id) !== -1,
    [selectedActivity]
  );

  const returnColorTypeRoute = useCallback((type: number) => {
    if (type === 0) {
      return '#ff6961';
    } else if (type === 1) {
      return '#0099ad';
    } else if (type === 2) {
      return 'rgb(2,189,2)';
    } else if (type === 4) {
      return '#6e6e6e';
    } else if (type === 5) {
      return '#a38c31';
    }
  }, []);

  const handleToggleView = useCallback(
    (order?: { orderCode: string | null; number: string | null }) => {
      if (order?.orderCode && order.number) {
        setOpenView(true);
        setOrder(order);
      } else {
        setOpenView(false);
        setOrder({ orderCode: null, number: null });
      }
    },
    []
  );

  function changeOrderSequence(index: number, direction: 1 | -1) {
    const newOrderedOrders = orderedRouteActivities;

    const orderMoved = newOrderedOrders[index];

    setSortedActivitiesIds((prev) => {
      const sortedActivities = new Set([...(prev || [])]);
      sortedActivities.add(orderMoved.id);

      return Array.from(sortedActivities);
    });

    newOrderedOrders.splice(index, 1);
    newOrderedOrders.splice(Math.max(0, index - direction), 0, orderMoved);

    setOrderedRouteActivities([...newOrderedOrders]);
  }

  return (
    <Box margin={1}>
      <OrderProvider>
        <ManualOrderProvider>
          <ScheduleProvider>
            <OrderInfo {...order} open={openView} onClose={handleToggleView} />
          </ScheduleProvider>
        </ManualOrderProvider>
      </OrderProvider>

      <Grid container justifyContent="space-between">
        <Typography variant="h6" gutterBottom component="div">
          Roteiros:
        </Typography>

        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <StopRoundedIcon style={{ color: 'rgb(2,189,2)' }} />
          <strong> Entrega |</strong>
          <StopRoundedIcon style={{ color: '#ff6961' }} />
          <strong> Coleta/Retirada |</strong>
          <StopRoundedIcon style={{ color: '#0099ad' }} />
          <strong> Devolução |</strong>
          <StopRoundedIcon style={{ color: '#6e6e6e' }} />
          <strong>Higienização |</strong>
          <StopRoundedIcon style={{ color: '#a38c31' }} />
          <strong>Reparo</strong>
        </Grid>
      </Grid>

      <Table size="small" aria-label="pu rchases">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Box display="flex" alignItems="center">
                Ordem
                <IconButton
                  disabled={
                    !activities.find(({ ordem }) => ordem !== -1) ||
                    activities.length <= 1
                  }
                  title="Ordenar"
                  onClick={() => {
                    setIsSorting((val) => !val);
                    setOrderedRouteActivities([...activities]);
                  }}
                >
                  {isSorting ? <MdEditOff size={20} /> : <MdEdit size={20} />}
                </IconButton>
                {isSorting && (
                  <IconButton
                    disabled={!SortedActivitiesIds}
                    onClick={async () => {
                      setIsLoading(true);

                      await activitySequence(
                        routeId,
                        orderedRouteActivities!.map(
                          ({ id: activityId, ordem }, activityIndex) => {
                            return {
                              id: activityId,
                              ordem: activityIndex + 1,
                              ordemOrigin: ordem,
                            };
                          }
                        )
                      ).then(() =>
                        enqueueSnackbar(
                          'Rota Atualizada com Sucesso! Aguarde o reprocessamento da mesma',
                          {
                            variant: 'success',
                          }
                        )
                      );

                      setIsLoading(false);
                      setIsSorting(false);
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress style={{ color: 'lime' }} size={24} />
                    ) : (
                      <BiCheckCircle
                        color={SortedActivitiesIds ? 'lime' : 'grey'}
                        size={24}
                      />
                    )}
                  </IconButton>
                )}
              </Box>
            </TableCell>
            <TableCell>Pedido</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Zona</TableCell>
            <TableCell>Recebido por</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {(isSorting
            ? orderedRouteActivities
            : activities.sort((a, b) => a.ordem - b.ordem)
          ).map((activity, index) => {
            const isItemSelected = isSelected(activity);

            return (
              <TableRow
                key={`activity-${activity.id}`}
                hover
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
                tabIndex={-1}
              >
                <TableCell padding="checkbox">
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      color: '#CCC',
                    }}
                  >
                    {isSorting ? (
                      `${index + 1}º`
                    ) : (
                      <Checkbox
                        checked={isItemSelected}
                        onClick={() => handleSelect(activity)}
                        disabled={disableCheckbox(activity.status)}
                        inputProps={{
                          'aria-labelledby': activity.id.toString(),
                        }}
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center">
                    <Chip
                      color={
                        SortedActivitiesIds?.includes(activity.id)
                          ? 'secondary'
                          : 'default'
                      }
                      label={activity.ordem}
                    />

                    {isSorting && (
                      <Box display="flex" flexDirection="column" ml={2}>
                        <IconButton
                          onClick={() => {
                            changeOrderSequence(index, 1);
                          }}
                        >
                          <BiCaretUp size={15} color="black" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            changeOrderSequence(index, -1);
                          }}
                        >
                          <BiCaretDown size={15} color="black" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <strong
                    onClick={() =>
                      handleToggleView({
                        orderCode: activity.orderCode,
                        number: activity.number,
                      })
                    }
                  >
                    <Chip
                      label={activity.number}
                      style={{
                        color: '#fff',
                        cursor: 'pointer',
                        backgroundColor: returnColorTypeRoute(activity.type),
                      }}
                    />
                  </strong>
                </TableCell>
                <TableCell>{activity.customer.name}</TableCell>
                <TableCell>
                  {regionStatusLabel(activity.customer.address.region)}
                </TableCell>
                <TableCell>
                  {activity.responsibleName ??
                    activityStatusLabel(activity.status)}
                </TableCell>
                <TableCell>
                  <strong>
                    <Chip
                      label={activityStatusLabel(activity.status)}
                      style={{
                        color: '#fff',
                        backgroundColor: returnColor(activity.status),
                      }}
                    />
                  </strong>
                </TableCell>
                <TableCell align="center">
                  <CellActionButtons
                    routeId={routeId}
                    routeName={routeName}
                    activity={activity}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
