import React, { createContext, useContext, useCallback } from 'react';

import api from '../../../services/api';

export enum QuestionType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  TEXT = 'text',
  SIGNATURE = 'signature',
  IMAGE = 'image',
}

export interface Question {
  order: number;
  required: boolean;
  subject: string;
  type: QuestionType;
  options: string[];
  hasObservation: boolean;
}

export type QuestionWithAutoFocus = Question & {
  autoFocus: boolean;
};

export enum FrequencyEnum {
  NO = 'no',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  CUSTOM = 'custom',
}

export interface Recurrence {
  frequency: FrequencyEnum;
  expireIn: string | null;
  config: {
    repeatTime: number | null;
    day: number | null;
    month: number | null;
    monthWeek: number | null;
    weekDays: string[] | null;
  } | null;
}

export interface IForm {
  id: number;
  title: string;
  application: string;
  process: string | null;
  products: string[] | null;
  vehicleTypes: string[] | null;
  force: boolean;
  recurrence: Recurrence;
  questions: Question[];
}

export type FormData = Omit<IForm, 'id' | 'questions'> & {
  questions: QuestionWithAutoFocus[];
};

export interface IForms {
  total: number;
  formsConfigs: IForm[];
}

export interface MobileApplication {
  id: number;
  name: string;
  applicationId: string;
}

interface GetFormsParams {
  page: number;
  take: number;
}

export const RECURRENCE_OPTIONS = [
  { value: FrequencyEnum.NO, label: 'Sem recorrência' },
  { value: FrequencyEnum.DAILY, label: 'Diário' },
  { value: FrequencyEnum.WEEKLY, label: 'Semanal' },
  { value: FrequencyEnum.MONTHLY, label: 'Mensal' },
  { value: FrequencyEnum.YEARLY, label: 'Anual' },
  { value: FrequencyEnum.CUSTOM, label: 'Customizado' },
];

interface IFormContext {
  getMobileApplications(): Promise<MobileApplication[] | undefined>;
  getForms(params?: GetFormsParams): Promise<IForms | undefined>;
  getFormById(formId: number): Promise<FormData | undefined>;
  createForm(data: Omit<IForm, 'id'>): Promise<void>;
  updateForm(data: IForm): Promise<void>;
  deleteForm(formId: number): Promise<void>;
}

const FormContext = createContext<IFormContext>({} as IFormContext);

const FormProvider: React.FC = ({ children }) => {
  const getMobileApplications = useCallback(async () => {
    const response = await api.get<MobileApplication[]>('/mobile-application');

    if (response) {
      return response.data;
    }

    return undefined;
  }, []);

  const getForms = useCallback(async (params?: GetFormsParams) => {
    const response = await api.get<IForms>('/forms-config', { params });

    if (response) {
      return response.data;
    }

    return undefined;
  }, []);

  const getFormById = useCallback(
    async (formId: number): Promise<FormData | undefined> => {
      const response = await api.get<IForm>(`/forms-config/${formId}`);

      if (response) {
        const { data } = response;

        const form: FormData = {
          ...data,
          questions: data.questions
            .sort((a, b) => a.order - b.order)
            .map((question) => ({
              ...question,
              autoFocus: false,
            })),
        };

        return form;
      }

      return undefined;
    },
    []
  );

  const createForm = useCallback(async (data: Omit<IForm, 'id'>) => {
    await api.post('/forms-config', data);
  }, []);

  const updateForm = useCallback(async (data: IForm) => {
    const { id, ...form } = data;

    await api.patch(`/forms-config/${id}`, form);
  }, []);

  const deleteForm = useCallback(async (formId: number) => {
    await api.delete(`/forms-config/${formId}`);
  }, []);

  return (
    <FormContext.Provider
      value={{
        getMobileApplications,
        getForms,
        getFormById,
        createForm,
        updateForm,
        deleteForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

function useForm() {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }

  return context;
}

export { FormProvider, useForm };
