import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;

  @media only screen and (min-width: 690px) {
    width: 500px;
  }
`;
