import { Switch, Route } from 'react-router-dom';
import { ROUTES } from '../../../constants';

import { SignUpProvider } from '../hooks/signUp';
import { ForgotPasswordProvider } from '../hooks/forgotPassword';

import { Auth } from '../pages/Auth';

export const AuthRoute = () => (
  <ForgotPasswordProvider>
    <SignUpProvider>
      <Switch>
        <Route path={ROUTES.auth} exact component={Auth} />
      </Switch>
    </SignUpProvider>
  </ForgotPasswordProvider>
);
