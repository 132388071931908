import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  Divider,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SendIcon from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ConfirmationWithImageModal } from '../../../../../components/ConfirmationWithImageModal';
import { PERMISSIONS } from '../../../../../constants';
import { useAuth } from '../../../../../hooks/auth';
import { useModal } from '../../../../../hooks/modal';
import { ActivityInfo } from '../../../../order/components/ActivityInfo';
import { IOrderImage, OrderImageTypeEnum } from '../../../../order/hooks/order';
import { IActivity, useActivity } from '../../../hooks/activity';
import { ImageList as OccurrenceView } from '../../../../order/components/Occurrence/ImageList';
import { BiDotsVerticalRounded, BiPrinter } from 'react-icons/bi';
import { generateRouteActivityReportPDF } from '../../../../report/templates/routeActivityReport';
import { useCompanyParams } from '../../../../company-params/hooks/company-params';

interface IProps {
  routeId: number;
  routeName: string;
  activity: IActivity;
}

export const CellActionButtons = ({ routeId, routeName, activity }: IProps) => {
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { getCompanyParams } = useCompanyParams();
  const { activityDelivered, activityCanceled, getRouteActivityReportById } =
    useActivity();
  const {
    data: { permissions },
  } = useAuth();
  const [openActivityInfo, setOpenActivityInfo] = useState(false);
  const [openOccurrenceView, setOpenOccurrenceView] = useState(false);

  const [dropDownMenuAnchorEl, setDropdownMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const dropDownMenuOpen = Boolean(dropDownMenuAnchorEl);
  const handleDropDownMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDropdownMenuAnchorEl(event.currentTarget);
  };
  const handleCloseDropDownMenu = () => {
    setDropdownMenuAnchorEl(null);
  };

  const handleToggleActivityInfo = useCallback(() => {
    setOpenActivityInfo((prevState) => !prevState);
  }, []);

  const handleToggleOccurrenceView = useCallback(() => {
    setOpenOccurrenceView((prevState) => !prevState);
  }, []);

  const finishActivity = useCallback(
    async (images: IOrderImage[]) => {
      try {
        await activityDelivered(routeId, activity.id, {
          images,
        }).then((response) => {
          if (response.status === 200) {
            enqueueSnackbar('Entrega finalizada com sucesso!', {
              variant: 'success',
            });
          }
        });
      } catch (error) {
        enqueueSnackbar('Por favor, tente novamente!', {
          variant: 'error',
        });
      }
    },
    [activity.id, activityDelivered, enqueueSnackbar, routeId]
  );

  const cancelActivity = useCallback(
    async (images: IOrderImage[]) => {
      try {
        await activityCanceled(routeId, activity.id, { images }).then(
          (response) => {
            if (response.status === 200) {
              enqueueSnackbar('Entrega cancelada com sucesso!', {
                variant: 'success',
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar('Por favor, tente novamente!', {
          variant: 'error',
        });
      }
    },
    [activity.id, activityCanceled, enqueueSnackbar, routeId]
  );

  const isNotDisabledViewButton = useCallback(() => {
    if (
      (permissions.includes(PERMISSIONS.ROUTE_ACTIVITY_VIEW_SIGNATURE) &&
        activity.status === 'delivered') ||
      activity.status === 'occurrence'
    )
      return false;

    return true;
  }, [activity.status, permissions]);

  const isNotDisabledMarkAsDelivered = useCallback(() => {
    if (
      (permissions.includes(PERMISSIONS.ROUTE_ACTIVITY_MARK_AS_DELIVERED) &&
        activity.status === 'pending') ||
      activity.status === 'on-route'
    )
      return false;

    return true;
  }, [activity.status, permissions]);

  const isNotDisabledCancelOrder = useCallback(() => {
    if (
      (permissions.includes(PERMISSIONS.ROUTE_ACTIVITY_CANCEL) &&
        activity.status === 'pending') ||
      activity.status === 'on-route'
    )
      return false;

    return true;
  }, [activity.status, permissions]);

  async function onPrintRouteReportClick(routeId: number, activityId: number) {
    const report = await getRouteActivityReportById(routeId, activityId);
    const { geoFenceDistance } = await getCompanyParams();

    generateRouteActivityReportPDF(report, geoFenceDistance);
  }

  return (
    <>
      <ActivityInfo
        open={openActivityInfo}
        onClose={handleToggleActivityInfo}
        activity={activity}
        routeId={routeId}
      />

      <OccurrenceView
        open={openOccurrenceView}
        onClose={handleToggleOccurrenceView}
        activity={{ ...activity, route: { routerName: routeName } }}
      />

      <div
        style={{
          minWidth: '200px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={
            activity.status === 'occurrence'
              ? 'Visualizar Ocorrência'
              : 'Visualizar Assinatura'
          }
          placement="top"
        >
          <span>
            <IconButton
              color="inherit"
              aria-label="view"
              onClick={
                activity.status === 'occurrence'
                  ? handleToggleOccurrenceView
                  : handleToggleActivityInfo
              }
              disabled={isNotDisabledViewButton()}
            >
              <VisibilityIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IconButton
            id="basic-button"
            aria-controls={dropDownMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={dropDownMenuOpen ? 'true' : undefined}
            onClick={handleDropDownMenuClick}
          >
            <BiDotsVerticalRounded size={28} />
          </IconButton>

          <Menu
            id="dropdown-menu"
            open={dropDownMenuOpen}
            anchorEl={dropDownMenuAnchorEl}
            elevation={2}
            onClose={handleCloseDropDownMenu}
            transitionDuration={100}
            TransitionComponent={Fade}
            MenuListProps={{
              'aria-labelledby': 'dropdown-button',
            }}
          >
            <div>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  open(ConfirmationWithImageModal, {
                    title: `Deseja marcar o pedido ${activity.number} como concluído?`,
                    type: OrderImageTypeEnum.DELIVERED,
                    onSubmit: (images: IOrderImage[]) => finishActivity(images),
                  });
                  handleCloseDropDownMenu();
                }}
                disableRipple
                disabled={isNotDisabledMarkAsDelivered()}
              >
                <ListItemIcon>
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText>Marcar como Concluído</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  open(ConfirmationWithImageModal, {
                    title: `Deseja marcar o pedido ${activity.number} como cancelado?`,
                    type: OrderImageTypeEnum.CANCELLED,
                    onSubmit: (images: IOrderImage[]) => cancelActivity(images),
                  });
                  handleCloseDropDownMenu();
                }}
                disableRipple
                disabled={isNotDisabledCancelOrder()}
              >
                <ListItemIcon>
                  <CancelIcon />
                </ListItemIcon>
                <ListItemText>Cancelar Pedido</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseDropDownMenu();
                }}
                disableRipple
                disabled
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText>Notificar Cliente</ListItemText>
              </MenuItem>

              <Divider style={{ marginTop: 0.5, marginBottom: 0.5 }} />

              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  if (activity && routeId) {
                    enqueueSnackbar(
                      `Imprimindo relatório da Atividade ${activity.number}! Aguarde, você será redirecionado.`,
                      {
                        variant: 'info',
                      }
                    );
                    onPrintRouteReportClick(routeId, activity.id);
                  }
                  handleCloseDropDownMenu();
                }}
                disableRipple
                disabled={
                  !['delivered', 'occurrence'].includes(activity.status)
                }
              >
                <ListItemIcon>
                  <BiPrinter size={24} />
                </ListItemIcon>
                <ListItemText>Imprimir</ListItemText>
              </MenuItem>
            </div>
          </Menu>
        </Box>
      </div>
    </>
  );
};
