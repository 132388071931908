import { useSignUp } from '../../hooks/signUp';

import confirmationCheck from '../../../../assets/confirmation_check.gif';

import { Typography, Button } from '@material-ui/core';

import { Container } from './styles';

export const Completed = () => {
  const { setIsRegistering } = useSignUp();

  return (
    <Container>
      <img src={confirmationCheck} alt="Cadastro Concluído Com Sucesso" />

      <Typography variant="h6" align="center" className="Typography">
        <strong>CADASTRO REALIZADO COM SUCESSO</strong>
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsRegistering(false)}
      >
        Voltar ao Login
      </Button>
    </Container>
  );
};
