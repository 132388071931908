import { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import {
  useRoutePlanning,
  IRoutePlanningRequest,
} from '../../hooks/route-planning';
import {
  ILoadDistributionCenter,
  useDistributionCenter,
} from '../../../distribution-center/hooks/distribution-center';

import { useStyles, Form } from './styles';

import { isBefore } from 'date-fns';

interface IParams {
  routePlanningId?: string;
}

export const RoutePlanningForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { routePlanningId } = useParams<IParams>();
  const {
    data: { user },
    setCompanyDefault,
    companyDefault,
  } = useAuth();
  const { loadRoutePlanningById, createRoutePlanning, updateRoutePlanning } =
    useRoutePlanning();
  const { loadDistributionCenterList } = useDistributionCenter();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingRoutePlanning, setLoadingRoutePlanning] = useState(true);
  const [distributionCenters, setDistributionCenters] = useState<
    ILoadDistributionCenter[]
  >([]);
  const [data, setData] = useState<IRoutePlanningRequest>({
    name: '',
    distributionCenterId: '',
    planningStartTime: '',
    deliveryStartTime: '',
    deliveryEndTime: '',
    deliveryTime: '',
    loadingTime: '',
    returnToDistributionCenter: false,
    useRestrictionZone: false,
    isDefault: false,
    mapsService: '',
    shippingStatus: '',
    companyDocument: user.companyDocument,
  });

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      const response = await loadDistributionCenterList(user.companyDocument);

      setDistributionCenters(response);
    }

    loadDistributionCenter();
  }, [loadDistributionCenterList, user.companyDocument]);

  useEffect(() => {
    async function loadRoutePlanning(): Promise<void> {
      try {
        if (routePlanningId) {
          const response = await loadRoutePlanningById(
            parseInt(routePlanningId)
          );

          if (!response) return;

          setData({
            ...response,
            distributionCenterId: response.distributionCenter.id,
            mapsService: response.mapsService ?? '',
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingRoutePlanning(false);
      }
    }

    loadRoutePlanning();
  }, [loadRoutePlanningById, routePlanningId]);

  const validationSchema = yup.object({
    name: yup.string().required('Insira o nome'),
    distributionCenterId: yup
      .number()
      .required('Insira o Centro de Distribuição'),
    planningStartTime: yup.string().required('Insira a hora de início'),
    deliveryStartTime: yup
      .string()
      .required('Insira o horário de início da rota')
      .test(
        'validateHour',
        'Deve ser maior que hora de início',
        (value: any, testContext) => {
          if (testContext.parent.planningStartTime && value) {
            return isBefore(
              onlyDigits(testContext.parent.planningStartTime),
              onlyDigits(value)
            );
          }

          return false;
        }
      ),
    deliveryEndTime: yup
      .string()
      .required('Insira o horário de término da rota')
      .test(
        'validateHour',
        'Deve ser maior que início da rota',
        (value: any, testContext) => {
          if (testContext.parent.deliveryStartTime && value) {
            return isBefore(
              onlyDigits(testContext.parent.deliveryStartTime),
              onlyDigits(value)
            );
          }

          return false;
        }
      ),
    deliveryTime: yup.number().required('Insira o tempo para entrega'),
    loadingTime: yup.number().required('Insira o tempo de carregamento'),
    returnToDistributionCenter: yup
      .boolean()
      .required('Houve a devolução para o Centro de distribuição?'),
    useRestrictionZone: yup.boolean().required('Zona de restrição de entrega?'),
    isDefault: yup.boolean().required('Houve problema na entrega?'),
    mapsService: yup.string().required('Insira o serviço de mapa'),
    shippingStatus: yup.string().required('Insira o status de envio'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        if (routePlanningId) {
          await updateRoutePlanning(parseInt(routePlanningId), values);
        } else {
          await createRoutePlanning(values);
        }

        let newCompanyDefault: any = {};

        newCompanyDefault = {
          ...companyDefault,
          hasPlanningConfig: true,
        };

        setCompanyDefault(newCompanyDefault);
        localStorage.setItem(
          '@4log:companyDefault',
          JSON.stringify(newCompanyDefault)
        );

        history.push(ROUTES.routePlanningList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const onlyDigits = useCallback((hour: string) => {
    return parseInt(hour.replace(/[^\d]+/g, ''));
  }, []);

  const subtitle = useMemo(() => {
    if (routePlanningId) {
      return 'Alterar plano de rota';
    } else {
      return 'Cadastrar planejamento';
    }
  }, [routePlanningId]);

  const submitButtonTitle = useMemo(() => {
    if (routePlanningId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [routePlanningId]);

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.routePlanningList}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {routePlanningId && loadingRoutePlanning ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoFocus
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Nome"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Centro de Distribuição"
                      id="distributionCenterId"
                      name="distributionCenterId"
                      value={formik.values.distributionCenterId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.distributionCenterId &&
                        Boolean(formik.errors.distributionCenterId)
                      }
                      helperText={
                        formik.touched.distributionCenterId &&
                        formik.errors.distributionCenterId
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione o centro de distribuição</em>
                      </MenuItem>
                      {distributionCenters.map((dcenter) => (
                        <MenuItem
                          key={`distributionCenter-${dcenter.id}`}
                          value={dcenter.id}
                        >
                          {dcenter.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Hora de ínicio"
                      id="planningStartTime"
                      name="planningStartTime"
                      type="time"
                      value={formik.values.planningStartTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.planningStartTime &&
                        Boolean(formik.errors.planningStartTime)
                      }
                      helperText={
                        formik.touched.planningStartTime &&
                        formik.errors.planningStartTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Ínicio da rota"
                      id="deliveryStartTime"
                      name="deliveryStartTime"
                      type="time"
                      value={formik.values.deliveryStartTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.deliveryStartTime &&
                        Boolean(formik.errors.deliveryStartTime)
                      }
                      helperText={
                        formik.touched.deliveryStartTime &&
                        formik.errors.deliveryStartTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Término da rota"
                      id="deliveryEndTime"
                      name="deliveryEndTime"
                      type="time"
                      value={formik.values.deliveryEndTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.deliveryEndTime &&
                        Boolean(formik.errors.deliveryEndTime)
                      }
                      helperText={
                        formik.touched.deliveryEndTime &&
                        formik.errors.deliveryEndTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Tempo para entrega"
                      id="deliveryTime"
                      name="deliveryTime"
                      type="number"
                      value={formik.values.deliveryTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.deliveryTime &&
                        Boolean(formik.errors.deliveryTime)
                      }
                      helperText={
                        formik.touched.deliveryTime &&
                        formik.errors.deliveryTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Tempo de carregamento"
                      id="loadingTime"
                      name="loadingTime"
                      type="number"
                      value={formik.values.loadingTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.loadingTime &&
                        Boolean(formik.errors.loadingTime)
                      }
                      helperText={
                        formik.touched.loadingTime && formik.errors.loadingTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Serviço de Mapas"
                      id="mapsService"
                      name="mapsService"
                      value={formik.values.mapsService}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mapsService &&
                        Boolean(formik.errors.mapsService)
                      }
                      helperText={
                        formik.touched.mapsService && formik.errors.mapsService
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione o serviço</em>
                      </MenuItem>
                      <MenuItem value="openmaps">Open Maps</MenuItem>
                      <MenuItem value="maplink">Maplink</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Status do envio"
                      id="shippingStatus"
                      name="shippingStatus"
                      value={formik.values.shippingStatus}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.shippingStatus &&
                        Boolean(formik.errors.shippingStatus)
                      }
                      helperText={
                        formik.touched.shippingStatus &&
                        formik.errors.shippingStatus
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione o status</em>
                      </MenuItem>
                      <MenuItem value="unpacked">EMBALADO</MenuItem>
                      <MenuItem value="unshipped">NÃO ENVIADO</MenuItem>
                      <MenuItem value="shipped">ENVIADO</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="returnToDistributionCenter"
                            name="returnToDistributionCenter"
                            value={formik.values.returnToDistributionCenter}
                            onChange={formik.handleChange}
                            checked={formik.values.returnToDistributionCenter}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Retornar ao centro de distribuição
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="useRestrictionZone"
                            name="useRestrictionZone"
                            value={formik.values.useRestrictionZone}
                            onChange={formik.handleChange}
                            checked={formik.values.useRestrictionZone}
                            color="primary"
                          />
                        }
                        label={<Typography>Usar região de entrega</Typography>}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="isDefault"
                            name="isDefault"
                            value={formik.values.isDefault}
                            onChange={formik.handleChange}
                            checked={formik.values.isDefault}
                            color="primary"
                          />
                        }
                        label={<Typography>Configuração Padrão</Typography>}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    {submitButtonTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
