import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  margin: 12px;

  button {
    margin-inline: 50px;
  }
`;
