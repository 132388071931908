import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';

import { IProduct, useProduct } from '../../hooks/product';

interface IProps {
  open: boolean;
  productId: number | null;
  onClose: () => void;
}

export const ProductInfo: React.FC<IProps> = (props) => {
  const { loadProductById } = useProduct();
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setProduct({} as IProduct);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadProduct(): Promise<void> {
      if (props.productId) {
        try {
          const response = await loadProductById(props.productId);

          if (!response) return;

          setProduct(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadProduct();
  }, [loadProductById, props.productId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Produto cadastrado:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="sku"
                value={product.sku ?? 'Não informado'}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Unidade de Produto"
                value={product.unit}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
