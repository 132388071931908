import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { CellActions } from '../../../../components/Table/CellActions';
import { TableCell } from '../../../../components/Table/TableCell';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { Loading } from '../../../../components/Loading';
import { DeliveryRegionInfo } from '../../components/DeliveryRegionInfo';
import {
  useDeliveryRegion,
  ILoadDeliveryRegion,
} from '../../hooks/delivery-region';

import { useStyles } from './styles';

export const DeliveryRegionList = () => {
  const { loadDeliveryRegionList, deleteDeliveryRegion } = useDeliveryRegion();
  const classes = useStyles();
  const [deliveryRegions, setDeliveryRegions] = useState<ILoadDeliveryRegion[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [deliveryRegionId, setDeliveryRegionId] = useState<number | null>(null);
  const [search, setSearch] = useState('');

  const deliveryRegionFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return deliveryRegions.filter(
      (deliveryRegion) =>
        deliveryRegion.name.toLowerCase().includes(lowerSearch) ||
        deliveryRegion.neighborhoods.length
          .toString()
          .toLowerCase()
          .includes(lowerSearch)
    );
  }, [search, deliveryRegions]);

  useEffect(() => {
    async function loadDeliveryRegion(): Promise<void> {
      try {
        const response = await loadDeliveryRegionList();

        setDeliveryRegions(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDeliveryRegion();
  }, [loadDeliveryRegionList]);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setDeliveryRegionId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setDeliveryRegionId(null);
  }, []);

  const handleOpenDialogDelete = useCallback((id: number) => {
    setOpenDialogDelete(true);
    setDeliveryRegionId(id);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setDeliveryRegionId(null);
  }, []);

  const handleDeleteDeliveryRegion = useCallback(async () => {
    if (deliveryRegionId) {
      await deleteDeliveryRegion(deliveryRegionId);
      setDeliveryRegions((prevState) =>
        prevState.filter((rest) => rest.id !== deliveryRegionId)
      );
      setDeliveryRegionId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteDeliveryRegion, deliveryRegionId]);

  return (
    <Main
      title="Regiões de Entregas"
      button="add"
      buttonName="Nova Região"
      to={ROUTES.createDeliveryRegion}
      hasPermission="DELIVERY_REGION_CREATE"
    >
      <Grid container spacing={1}>
        <DeliveryRegionInfo
          open={openView}
          deliveryRegionId={deliveryRegionId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar região de entrega"
          description="Deseja realmente deletar esta região de entrega?"
          onSubmit={handleDeleteDeliveryRegion}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Quantidade de bairros</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <Loading columns={3} />
                ) : (
                  deliveryRegionFilter.map((dRegion) => (
                    <TableRow key={dRegion.id} hover>
                      <TableCell component="th" scope="row">
                        {dRegion.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {dRegion.neighborhoods.length}
                      </TableCell>
                      <TableCell align="center">
                        <CellActions
                          id={dRegion.id}
                          sendTo="deliveryRegionList"
                          handleOpen={() => handleOpenView(dRegion.id)}
                          handleDelete={() =>
                            handleOpenDialogDelete(dRegion.id)
                          }
                          onEditPermission="DELIVERY_REGION_EDIT"
                          onRemovePermission="DELIVERY_REGION_REMOVE"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Main>
  );
};
