import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
  List,
  Typography,
  ListItem,
  ListItemText,
  Card,
  Divider,
} from '@material-ui/core';
import {
  ILoadDeliveryRegion,
  useDeliveryRegion,
} from '../../hooks/delivery-region';
import { regionStatus } from '../../../../utils';

interface IProps {
  open: boolean;
  deliveryRegionId: number | null;
  onClose: () => void;
}

export const DeliveryRegionInfo: React.FC<IProps> = (props) => {
  const { loadDeliveryRegionById } = useDeliveryRegion();
  const [deliveryRegion, setDeliveryRegion] = useState<ILoadDeliveryRegion>(
    {} as ILoadDeliveryRegion
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setDeliveryRegion({} as ILoadDeliveryRegion);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadDeliveryRegion(): Promise<void> {
      if (props.deliveryRegionId) {
        try {
          const response = await loadDeliveryRegionById(props.deliveryRegionId);

          if (!response) return;

          setDeliveryRegion(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadDeliveryRegion();
  }, [loadDeliveryRegionById, props.deliveryRegionId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Região de entrega cadastrada:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Código"
                value={deliveryRegion.id ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={10}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nome"
                value={deliveryRegion.name ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Card>
                <Typography
                  variant="h6"
                  component="h4"
                  style={{ marginLeft: 10 }}
                >
                  Bairros
                </Typography>

                <Divider />

                <List
                  dense
                  component="div"
                  role="list"
                  style={{ width: '100%' }}
                >
                  {deliveryRegion.neighborhoods.map((dRegion) => {
                    const region = regionStatus.find(
                      (rStatus) => rStatus.value === dRegion.region
                    );

                    return (
                      <ListItem role="listitem">
                        <ListItemText
                          primary={
                            dRegion.region && dRegion.region !== 'null'
                              ? ` ${
                                  dRegion.name +
                                  ' (' +
                                  region?.label +
                                  ') - ' +
                                  dRegion.city +
                                  '/' +
                                  dRegion.state
                                }`
                              : ` ${
                                  dRegion.name +
                                  ' - ' +
                                  dRegion.city +
                                  '/' +
                                  dRegion.state
                                }`
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
