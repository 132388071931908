import { TableCell as MUITableCell } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

export const TableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#E9ECEF',
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  })
)(MUITableCell);
