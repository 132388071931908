import { StepConnector } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    maxWidth: 700,
    maxHeight: 83,
    height: '100%',
    background:
      'transparent linear-gradient(90deg, #01579BBF 0%, #3B2276CC 49%, #570864CC 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
  },
  logo: {
    width: '100%',
    maxWidth: 200,
    height: 'auto',
  },
  wrapper: {
    maxWidth: 700,
    maxHeight: '100%',
    height: '100%',
  },
  root: {
    flexGrow: 1,
  },
  orderNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #707070B3',
    borderRadius: '9px',
    opacity: 1,
    margin: '10px 0px',
    padding: '0px 10px',
  },
  stepper: {
    [theme.breakpoints.down(400)]: {
      overflowX: 'scroll',
    },
    marginBottom: '30px',
  },
  text: {
    fontSize: 16,
    textAlign: 'left',
  },
  divider: {
    margin: '8px 0',
  },
}));

export const useColorLibStepIconStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '2px solid #773B84',
  },
  active: {
    color: '#F8F8F8',
    background:
      'transparent linear-gradient(90deg, #4181B4 0%, #783B84 100%) 0% 0% no-repeat padding-box',
    boxShadow: '3px 5px 6px #00000029',
    border: 'none',
  },
  completed: {
    color: '#F8F8F8',
    background:
      'transparent linear-gradient(90deg, #4181B4 0%, #783B84 100%) 0% 0% no-repeat padding-box',
    boxShadow: '3px 5px 6px #00000029',
    border: 'none',
  },
}));

export const ColorLibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#437EB2',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#437EB2',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#783B84',
    borderRadius: 1,
  },
}))(StepConnector);
