import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button } from '../../../../components/Button';
import { RecurrenceModal } from '../../../../components/RecurrenceModal';
import { ROUTES } from '../../../../constants';
import { getValidationErrors, IErrors } from '../../../../utils';
import { QuestionForm } from '../../components/QuestionForm';
import {
  FormData,
  FrequencyEnum,
  MobileApplication,
  QuestionType,
  QuestionWithAutoFocus,
  Recurrence,
  RECURRENCE_OPTIONS,
  useForm,
} from '../../hooks/form';
import { AddButton, Container, Paper, useStyles } from './styles';
import { AddProductModal } from '../../components/AddProductModal';
import { ProductProvider } from '../../../product/hooks/product';
import { VehicleTypeProvider } from '../../../vehicle-type/hooks/vehicle-type';
import { AddVehicleTypeModal } from '../../components/AddVehicleTypeModal';

export const ChecklistForm = () => {
  const history = useHistory();
  const { formId } = useParams<{ formId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { getMobileApplications, createForm, updateForm, getFormById } =
    useForm();
  const classes = useStyles();

  const [products, setProducts] = useState<string[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<string[]>([]);
  const [applications, setApplications] = useState<MobileApplication[]>([]);
  const [loadingApplications, setLoadingApplications] = useState(true);
  const [loadingFormData, setLoadingFormData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openVehicleTypeModal, setOpenVehicleTypeModal] = useState(false);
  const [form, setForm] = useState<FormData>({
    title: '',
    application: '',
    process: '',
    products: null,
    vehicleTypes: null,
    force: false,
    recurrence: {
      frequency: FrequencyEnum.NO,
      expireIn: null,
      config: null,
    },
    questions: [
      {
        order: 1,
        required: false,
        subject: '',
        type: QuestionType.RADIO,
        options: [''],
        hasObservation: false,
        autoFocus: false,
      },
    ],
  });
  const [frequency, setFrequency] = useState<FrequencyEnum | null>(
    FrequencyEnum.NO
  );
  const [formErrors, setFormErrors] = useState<IErrors>({} as IErrors);

  const { application, process, title, questions } = form;

  const isProcessEqualToActivityItem = process === 'activity-item';
  const isProcessEqualToVehicle = process === 'vehicle';

  useEffect(() => {
    let active = true;

    async function loadApplications(): Promise<void> {
      try {
        const response = await getMobileApplications();

        if (!response) return;

        if (active) {
          setApplications(
            response.sort((a, b) =>
              a.name
                .toLocaleLowerCase()
                .localeCompare(b.name.toLocaleLowerCase())
            )
          );
        }
      } catch (error) {
        enqueueSnackbar('Tivemos um problema para carregar as aplicações', {
          variant: 'error',
        });
      } finally {
        setLoadingApplications(false);
      }
    }

    loadApplications();

    return () => {
      active = false;
    };
  }, [enqueueSnackbar, getMobileApplications]);

  useEffect(() => {
    let active = true;

    async function loadFormById(): Promise<void> {
      try {
        setLoadingFormData(true);
        const response = await getFormById(parseInt(formId, 10));

        if (!response) return;

        if (active) {
          if (response.recurrence.config?.repeatTime !== null) {
            setFrequency(FrequencyEnum.CUSTOM);
          }
          setProducts(response.products ?? []);
          setVehicleTypes(response.vehicleTypes ?? []);
          setForm(response);
        }
      } catch (error) {
        enqueueSnackbar(
          'Tivemos um problema para carregar os dados do formulário',
          {
            variant: 'error',
          }
        );
      } finally {
        setLoadingFormData(false);
      }
    }

    if (formId) {
      loadFormById();
    }

    return () => {
      active = false;
    };
  }, [enqueueSnackbar, formId, getFormById]);

  function handleTitleChange(title: string) {
    setFormErrors((prevState) => ({
      ...prevState,
      title: '',
    }));

    setForm((prevState) => ({ ...prevState, title }));
  }

  function handleAddNewQuestion() {
    setForm((prevState) => {
      const questions = [...prevState.questions];
      const totalQuestions = questions.length;

      questions.push({
        order: totalQuestions > 0 ? questions[totalQuestions - 1].order + 1 : 1,
        required: false,
        subject: '',
        type: QuestionType.RADIO,
        options: [''],
        hasObservation: false,
        autoFocus: true,
      });

      return {
        ...prevState,
        questions,
      };
    });
  }

  function handleAddNewOption(questionOrder: number) {
    setForm((prevState) => {
      const questions = [...prevState.questions];

      const questionIndex = questions.findIndex(
        (question) => question.order === questionOrder
      );

      if (questionIndex > -1) {
        questions[questionIndex].options.push('');

        return {
          ...prevState,
          questions,
        };
      }

      return prevState;
    });
  }

  function handleDeleteOption(questionOrder: number, optionIndex: number) {
    setForm((prevState) => {
      const questions = [...prevState.questions];

      const questionIndex = questions.findIndex(
        (question) => question.order === questionOrder
      );

      if (questionIndex > -1) {
        questions[questionIndex].options.splice(optionIndex, 1);

        return {
          ...prevState,
          questions,
        };
      }

      return prevState;
    });
  }

  function handleQuestionChange(updatedQuestion: QuestionWithAutoFocus) {
    setForm((prevState) => {
      const questions = [...prevState.questions];

      const questionIndex = questions.findIndex(
        (question) => question.order === updatedQuestion.order
      );

      if (questionIndex > -1) {
        questions[questionIndex] = { ...updatedQuestion };

        return {
          ...prevState,
          questions,
        };
      }

      return prevState;
    });
  }

  function handleDuplicateQuestion(questionOrder: number) {
    setForm((prevState) => {
      const questions = [...prevState.questions];

      const questionIndex = questions.findIndex(
        (question) => question.order === questionOrder
      );

      if (questionIndex > -1) {
        questions.push({
          ...questions[questionIndex],
          order: questions.length + 1,
        });

        return {
          ...prevState,
          questions,
        };
      }

      return prevState;
    });
  }

  function handleDeleteQuestion(deletedQuestion: QuestionWithAutoFocus) {
    setForm((prevState) => {
      const questions = [...prevState.questions];

      const questionWithoutTheDeleted = questions
        .filter((question) => question.order !== deletedQuestion.order)
        .map((question, index) => {
          question.order = index + 1;

          return question;
        });

      return {
        ...prevState,
        questions: questionWithoutTheDeleted,
      };
    });
  }

  function handleSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    if (name === 'recurrence') {
      const newFrequency = value as FrequencyEnum;

      setFrequency(newFrequency);

      if ([FrequencyEnum.NO, FrequencyEnum.DAILY].includes(newFrequency)) {
        setForm((prevState) => ({
          ...prevState,
          recurrence: {
            ...prevState.recurrence,
            frequency: newFrequency,
          },
        }));
      }
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        [name]: '',
      }));

      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  const handleClearError = useCallback((name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  }, []);

  const handleRecurrenceModalSave = useCallback((recurrence: Recurrence) => {
    setForm((prevState) => ({
      ...prevState,
      recurrence,
    }));
  }, []);

  async function handleSubmitForm() {
    try {
      setIsSubmitting(true);

      const questions = form.questions.map(
        ({ autoFocus, ...question }) => question
      );

      const validationSchema = yup.object().shape({
        title: yup.string().required('Campo obrigatório'),
        application: yup.string().required('Campo obrigatório'),
        process: yup.string().nullable().required('Campo obrigatório'),
        products: yup.array().nullable(),
        force: yup.boolean(),
        recurrence: yup.object().shape({
          frequency: yup.string(),
        }),
        questions: yup.array().of(
          yup.object().shape({
            order: yup.number(),
            required: yup.boolean(),
            subject: yup.string().required('Campo obrigatório'),
            type: yup.string(),
            options: yup.array().when('type', {
              is: (value: QuestionType) => value === QuestionType.TEXT,
              then: (schema) => schema,
              otherwise: (schema) =>
                schema
                  .of(yup.string().required('Campo obrigatório'))
                  .unique((a) => a, 'Opção duplicada'),
            }),
          })
        ),
      });

      await validationSchema.validate(form, { abortEarly: false });

      if (formId) {
        await updateForm({
          ...form,
          questions,
          id: parseInt(formId, 10),
          products: products.length > 0 ? products : null,
          vehicleTypes: vehicleTypes.length > 0 ? vehicleTypes : null,
        });
      } else {
        await createForm({
          ...form,
          questions,
          products: products.length > 0 ? products : null,
          vehicleTypes: vehicleTypes.length > 0 ? vehicleTypes : null,
        });
      }

      history.push(ROUTES.forms);
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        const errors = getValidationErrors(error);

        setFormErrors(errors);

        return;
      } else {
        console.log(error);

        enqueueSnackbar(
          `Houve um erro ao ${formId ? 'atualizar' : 'salvar'} seu formulário`,
          {
            variant: 'error',
          }
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleCancel() {
    history.push(ROUTES.forms);
  }

  const handleDeleteProduct = (deletedProduct: string) => () => {
    setProducts((products) =>
      products.filter((product) => product !== deletedProduct)
    );
  };

  const handleDeleteVehicleTypeId = (deletedId: string) => () => {
    setVehicleTypes((ids) => ids.filter((id) => id !== deletedId));
  };

  const handleAddProductModalToggle = () =>
    setOpenProductModal((prevState) => !prevState);

  const handleAddVehicleTypeModalToggle = () =>
    setOpenVehicleTypeModal((prevState) => !prevState);

  return (
    <Container maxWidth="md">
      {loadingFormData ? (
        <Typography component="div" align="center">
          <CircularProgress />
        </Typography>
      ) : (
        <>
          <RecurrenceModal
            frequency={frequency}
            onClose={() => setFrequency((prevState) => prevState)}
            onSave={handleRecurrenceModalSave}
          />

          <ProductProvider>
            <AddProductModal
              open={openProductModal}
              addedProducts={products}
              onSubmit={(newProduct) => setProducts([...newProduct])}
              onClose={handleAddProductModalToggle}
            />
          </ProductProvider>

          <VehicleTypeProvider>
            <AddVehicleTypeModal
              open={openVehicleTypeModal}
              addedVehicleTypes={vehicleTypes}
              onSubmit={(newVehicleTypes) =>
                setVehicleTypes([...newVehicleTypes])
              }
              onClose={handleAddVehicleTypeModalToggle}
            />
          </VehicleTypeProvider>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <TextField
                  fullWidth
                  required
                  select
                  label="Aplicação"
                  id="application"
                  name="application"
                  value={application}
                  onChange={handleSelectChange}
                  error={Boolean(formErrors.application)}
                  helperText={formErrors.application}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: loadingApplications && (
                      <div style={{ marginRight: 28 }}>
                        <CircularProgress size={20} />
                      </div>
                    ),
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: {
                      disableScrollLock: true,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Selecione
                  </MenuItem>

                  {applications.map((application) => (
                    <MenuItem
                      key={application.id}
                      value={application.applicationId}
                    >
                      {application.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper>
                <TextField
                  fullWidth
                  required
                  select
                  label="Processo"
                  id="process"
                  name="process"
                  value={process ?? ''}
                  onChange={handleSelectChange}
                  error={Boolean(formErrors.process)}
                  helperText={formErrors.process}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: { disableScrollLock: true },
                  }}
                >
                  <MenuItem value="" disabled>
                    Selecione
                  </MenuItem>
                  <MenuItem value="vehicle">Login Motorista (Veículo)</MenuItem>
                  <MenuItem value="activity">Atividade</MenuItem>
                  <MenuItem value="activity-item">
                    Atividade Item (Produto)
                  </MenuItem>
                  <MenuItem value="order">Separação</MenuItem>
                  <MenuItem value="conference">Conferência</MenuItem>
                </TextField>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper>
                <TextField
                  fullWidth
                  select
                  label="Recorrência"
                  id="recurrence"
                  name="recurrence"
                  value={frequency}
                  onChange={handleSelectChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                  }}
                >
                  {RECURRENCE_OPTIONS.map((frequency) => (
                    <MenuItem key={frequency.value} value={frequency.value}>
                      {frequency.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Paper>
            </Grid>
          </Grid>

          {isProcessEqualToActivityItem && (
            <Paper component="ul" className={classes.root}>
              <li>
                <Chip
                  icon={<AddCircleIcon />}
                  label="Adicionar Produtos"
                  onClick={handleAddProductModalToggle}
                  className={classes.chip}
                />
              </li>

              {products.map((product) => (
                <li key={product}>
                  <Chip
                    label={product}
                    onDelete={handleDeleteProduct(product)}
                    className={classes.chip}
                  />
                </li>
              ))}
            </Paper>
          )}

          {isProcessEqualToVehicle && (
            <Paper component="ul" className={classes.root}>
              <li>
                <Chip
                  icon={<AddCircleIcon />}
                  label="Adicionar Tipos do Veículo"
                  onClick={handleAddVehicleTypeModalToggle}
                  className={classes.chip}
                />
              </li>

              {vehicleTypes.map((vehicleTypeId) => (
                <li key={vehicleTypeId}>
                  <Chip
                    label={vehicleTypeId}
                    onDelete={handleDeleteVehicleTypeId(vehicleTypeId)}
                    className={classes.chip}
                  />
                </li>
              ))}
            </Paper>
          )}

          <Paper>
            <TextField
              required
              fullWidth
              id="title"
              name="title"
              label="Título"
              value={title}
              onChange={(event) => handleTitleChange(event.target.value)}
              error={Boolean(formErrors.title)}
              helperText={formErrors.title}
            />
          </Paper>

          {questions.map((question, index) => (
            <QuestionForm
              key={`question-${question.order}-${index}`}
              errors={formErrors}
              question={question}
              onClearError={handleClearError}
              onChange={handleQuestionChange}
              onAddNewOption={handleAddNewOption}
              onDeleteOption={handleDeleteOption}
              onDuplicate={handleDuplicateQuestion}
              onDelete={handleDeleteQuestion}
            />
          ))}

          <Tooltip title="Adicionar pergunta" placement="top">
            <Paper style={{ padding: 0 }}>
              <AddButton type="button" onClick={handleAddNewQuestion}>
                <AddCircleOutlineIcon />
              </AddButton>
            </Paper>
          </Tooltip>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="inherit"
              type="button"
              onClick={handleCancel}
              style={{ minWidth: '200px' }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleSubmitForm}
              loading={isSubmitting}
              style={{ minWidth: '200px' }}
            >
              {formId ? 'Alterar' : 'Criar'}
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};
