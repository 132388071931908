import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { Main } from '../../../../components/Main';
import { CellActions } from '../../../../components/Table/CellActions';
import { TableCell } from '../../../../components/Table/TableCell';
import { ROUTES } from '../../../../constants';
import { useStyles } from './style';
import {
  ILoadReasonOccurrence,
  useReasonOccurrence,
} from '../../hooks/reason-occurrence';
import { ReasonOccurrenceInfo } from '../../components/reason-occurrence-info';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';

export const ReasonOccurrenceList = () => {
  const classes = useStyles();

  const { loadReasonOccurrenceList, deleteReasonOccurrence } =
    useReasonOccurrence();

  const [occurrenceList, setOccurrenceList] = useState<ILoadReasonOccurrence>({
    total: 0,
    reasonsOccurrence: [],
  });
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [occurrenceId, setOccurrenceId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [hasDelay, setHasDelay] = useState<NodeJS.Timeout | null>(null);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, occurrenceList.total - page * rowsPerPage);

  const loadOccurrences = useCallback(
    async (searchValue?: string) => {
      setLoading(true);

      try {
        const query =
          `?page=${page + 1}&take=${rowsPerPage}` +
          `${searchValue ? `&reason=${searchValue}` : ''}`;
        const response = await loadReasonOccurrenceList(query);

        if (!response) return;

        setOccurrenceList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [loadReasonOccurrenceList, page, rowsPerPage]
  );

  useEffect(() => {
    loadOccurrences();
  }, [page, rowsPerPage]);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setOccurrenceId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setOccurrenceId(null);
  }, []);

  const handleOpenDialogDelete = useCallback((id: number) => {
    setOpenDialogDelete(true);
    setOccurrenceId(id);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setOccurrenceId(null);
  }, []);

  const handleDeleteOccurrence = useCallback(async () => {
    if (occurrenceId) {
      await deleteReasonOccurrence(occurrenceId);
      setOccurrenceList((prevState) => ({
        ...prevState,
        reasonsOccurrence: prevState.reasonsOccurrence.filter(
          (o) => o.id !== occurrenceId
        ),
      }));
      setOccurrenceId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteReasonOccurrence, occurrenceId]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchField(value);

    if (hasDelay) clearTimeout(hasDelay);
    const newTimeout = setTimeout(() => {
      loadOccurrences(value);
    }, 500);
    setHasDelay(newTimeout);
  };

  return (
    <Main
      title="Motivos de Ocorrências"
      button="add"
      buttonName="Nova Ocorrência"
      to={ROUTES.createReasonOccurrence}
      //hasPermission="OCCURRENCE_CREATE"
    >
      <Grid container spacing={1}>
        <ReasonOccurrenceInfo
          open={openView}
          occurrenceId={occurrenceId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar motivo de ocorrência"
          description="Deseja realmente deletar este motivo de ocorrência?"
          onSubmit={handleDeleteOccurrence}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Motivo</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <Loading columns={4} rows={rowsPerPage} />
                ) : (
                  occurrenceList.reasonsOccurrence.map((occurrence) => (
                    <TableRow key={occurrence.id} hover>
                      <TableCell component="th" scope="row">
                        {occurrence.id}
                      </TableCell>
                      <TableCell>{occurrence.reason}</TableCell>
                      <TableCell>{occurrence.description}</TableCell>
                      <TableCell align="right">
                        <CellActions
                          id={occurrence.id}
                          sendTo="reasonOccurrenceList"
                          handleOpen={() => handleOpenView(occurrence.id)}
                          handleDelete={() =>
                            handleOpenDialogDelete(occurrence.id)
                          }
                          //onEditPermission="OCCURRENCE_EDIT"
                          //onRemovePermission="OCCURRENCE_REMOVE"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {!loading && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={occurrenceList.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Main>
  );
};
