import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IDistributionCenterRequest {
  name: string;
  isPrincipal: boolean;
  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    postalcode: string;
    city: string;
    state: string;
    country: string;
    lat: string;
    long: string;
  };
  companyDocument: number;
}

export interface ILoadDistributionCenter {
  id: number;
  name: string;
  isPrincipal: boolean;
  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    postalcode: string;
    city: string;
    state: string;
    country: string;
    lat: string;
    long: string;
  };
  companyDocument: number;
}

interface IDistributionCenterContext {
  loadDistributionCenterById(
    id: number
  ): Promise<ILoadDistributionCenter | undefined>;
  loadDistributionCenterList(
    companyDocument: number
  ): Promise<ILoadDistributionCenter[]>;
  updateDistributionCenter(
    id: number,
    data: IDistributionCenterRequest
  ): Promise<void>;
  createDistributionCenter(data: IDistributionCenterRequest): Promise<void>;
  deleteDistributionCenter(id: number): Promise<void>;
}

const DistributionCenterContext = createContext<IDistributionCenterContext>(
  {} as IDistributionCenterContext
);

const DistributionCenterProvider: React.FC = ({ children }) => {
  const loadDistributionCenterById = useCallback(async (id: number) => {
    const response = await api.get(`/distribution-center/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadDistributionCenterList = useCallback(
    async (companyDocument: number) => {
      const response = await api.get(
        `/distribution-center?companyDocument=${companyDocument}`
      );

      return response.data;
    },
    []
  );

  const createDistributionCenter = useCallback(
    async (data: IDistributionCenterRequest) => {
      await api.post('/distribution-center', data);
    },
    []
  );

  const updateDistributionCenter = useCallback(
    async (id: number, data: IDistributionCenterRequest) => {
      await api.put(`/distribution-center/${id}`, data);
    },
    []
  );

  const deleteDistributionCenter = useCallback(async (id: number) => {
    await api.delete(`/distribution-center/${id}`);
  }, []);

  return (
    <DistributionCenterContext.Provider
      value={{
        loadDistributionCenterById,
        loadDistributionCenterList,
        updateDistributionCenter,
        createDistributionCenter,
        deleteDistributionCenter,
      }}
    >
      {children}
    </DistributionCenterContext.Provider>
  );
};

function useDistributionCenter(): IDistributionCenterContext {
  const context = useContext(DistributionCenterContext);

  if (!context) {
    throw new Error(
      'useDistributionCenter must be used within a DistributionCenterProvider'
    );
  }

  return context;
}

export { DistributionCenterProvider, useDistributionCenter };
