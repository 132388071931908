import Delete from '@material-ui/icons/Delete';

import { DivImages } from './styles';

interface IImageListProps {
  images: string[];
  onDelete(image: string): void;
}

export const ImageList = ({ images, onDelete }: IImageListProps) => (
  <div>
    <h4>Imagens</h4>

    <DivImages>
      {images.length > 0 &&
        images.map((file, index) => (
          <div key={index} className="container">
            <img src={file} alt={`Imagem ${index}`} />
            <div className="overlay">
              <Delete className="icon" onClick={() => onDelete(file)} />
            </div>
          </div>
        ))}
    </DivImages>
  </div>
);
