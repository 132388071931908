import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Grid,
  Button as MUIButton,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { useFormik } from 'formik';

import exampleSpreadsheet from '../../../../../assets/docs/example-spreadsheet-route.xlsx';
import { sheetToJson } from '../../../../../utils';
import { Button } from '../../../../../components/Button';
import { useRoute } from '../../../hooks/route';

interface IProps extends DialogProps {
  onClose: () => void;
}

export const ImportRoute = ({ onClose, ...props }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { importSpreadsheet } = useRoute();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('Nenhum arquivo selecionado');
  const [spreadsheetProperties] = useState([
    { value: 'route', label: 'Rota' },
    { value: 'driverName', label: 'Motorista' },
    { value: 'romanian', label: 'Romaneio' },
    { value: 'orderCode', label: 'Código' },
    { value: 'name', label: 'Razão social' },
    { value: 'postalCode', label: 'CEP' },
    { value: 'addressNumber', label: 'Número' },
    { value: 'sequence', label: 'Ordem de atendimento' },
    { value: 'volume', label: 'Volumes' },
    { value: 'code', label: 'AWB' },
    { value: 'distributionCenter', label: 'Hub' },
    { value: 'service', label: 'Serviço' },
    { value: 'vehicle', label: 'Veículo' },
  ]);

  const validationSchema = yup.object({
    file: yup
      .array()
      .min(1, 'Faça upload de uma planilha')
      .test(
        'validateSpreadsheetColumns',
        'Insira uma planilha válida',
        (value: any) => {
          if (value && value.length > 0) {
            const firstObjectToValidate = value[0];

            return validateSpreadsheetColumns(firstObjectToValidate);
          } else {
            return true;
          }
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      file: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const newJsonArr = values.file.map((x: any) => {
          var obj: { [k: string]: any } = {};

          spreadsheetProperties.map(
            (property) => (obj[property.value] = x[property.label])
          );

          return obj;
        });

        await importSpreadsheet(newJsonArr);

        enqueueSnackbar('Planilha de rota importada!', {
          variant: 'success',
        });
      } catch (err: any) {
        const { errors } = err;

        Object.keys(errors[0]).length !== 0
          ? enqueueSnackbar(errors[0], {
              variant: 'error',
            })
          : enqueueSnackbar('Não foi possível importar planilha de rota', {
              variant: 'error',
            });

        console.log(err);
      } finally {
        setLoading(false);
      }
    },
  });

  const validateSpreadsheetColumns = useCallback(
    (obj) => {
      let isValid = true;

      const keys = Object.keys(obj);

      spreadsheetProperties.forEach((property) => {
        if (isValid && !keys.includes(property.label)) {
          isValid = false;
          console.log(property.label);
        }
      });

      return isValid;
    },
    [spreadsheetProperties]
  );

  const uploadFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target;

      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0]
      ) {
        const file = event.target.files[0];
        const spreadsheet = await sheetToJson(file);

        setFileName(file.name);
        formik.setFieldValue(name, spreadsheet);
      } else {
        setFileName('Nenhum arquivo selecionado');
        formik.setFieldValue(name, []);
      }
    },
    [formik]
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      {...props}
      aria-labelledby="import-dialog-title"
    >
      <DialogTitle id="import-dialog-title">Importar Rota</DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <Grid container direction="column" spacing={2}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item>
                <input
                  id="file"
                  name="file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={uploadFile}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <label htmlFor="file">
                  <MUIButton
                    variant="outlined"
                    component="span"
                    color="default"
                    size="small"
                  >
                    Upload do arquivo
                  </MUIButton>
                </label>
              </Grid>

              <Grid item>
                <Typography component="span">{fileName}</Typography>
              </Grid>

              <Grid item xs={12}>
                {formik.touched.file && (
                  <FormHelperText error style={{ textAlign: 'center' }}>
                    {formik.errors.file}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item xs>
              <Box mt={1} textAlign="center">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                >
                  Enviar
                </Button>
              </Box>
            </Grid>

            <Grid item xs>
              <Link
                color="secondary"
                href={exampleSpreadsheet}
                target="_blank"
                download="planilha_exemplo_rota"
              >
                Baixar planilha modelo
              </Link>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
