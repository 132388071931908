import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Main } from '../../../../components/Main';
import { IBasicColumn } from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';
import { LoadForm, useForm } from '../../hooks/form';
import { Button } from '../../../../components/Button';
import { generateFormReportPDF } from '../../templates/formReport';
import { format, parseISO, subDays } from 'date-fns';
import { MdPrint } from 'react-icons/md';
import { Loading } from '../../../../components/Loading';
import { useFormik } from 'formik';
import {
  ILoadUser,
  useUserManagement,
} from '../../../user-management/hooks/user-management';
import { useAuth } from '../../../../hooks/auth';
import { IForms } from '../../../form/hooks/form';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

const columnsTable: IBasicColumn[] = [
  { name: 'Código' },
  { name: 'Usuário' },
  { name: 'Fomulário' },
  { name: 'Total Questões' },
  { name: 'Preenchido em', align: 'center' },
  { name: 'Imprimir', align: 'center' },
];

const FORM_CONFIG_FILTER_TAKE = 5;

export const FormsReport = () => {
  const {
    data: { user },
  } = useAuth();

  const { loadForm, filter, setFilter, loadFormsConfig } = useForm();
  const { loadUserList } = useUserManagement();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formList, setFormList] = useState<LoadForm>({
    total: 0,
    filledForms: [],
  });
  const [formConfigFilterPage, setFormConfigFilterPage] = useState(0);
  const [formsConfigs, setFormsConfigs] = useState<IForms>({
    total: 0,
    formsConfigs: [],
  });
  const [users, setUsers] = useState<ILoadUser[]>([]);

  const validationSchema = yup.object({
    initialDate: yup.date(),
    finalDate: yup
      .date()
      .min(
        yup.ref('initialDate'),
        'Data fim não pode ser menor que a data início'
      ),
    process: yup.string(),
    userId: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filter,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, formList.total - page * rowsPerPage);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function loadFormList(): Promise<void> {
      setLoading(true);

      try {
        const response = await loadForm(page, rowsPerPage, filter);

        if (!response) return;

        setFormList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadFormList();
  }, [filter, loadForm, page, rowsPerPage]);

  useEffect(() => {
    async function loadFormConfigList(): Promise<void> {
      try {
        const response = await loadFormsConfig(
          formConfigFilterPage,
          FORM_CONFIG_FILTER_TAKE
        );

        if (!response) return;

        setFormsConfigs(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadFormConfigList();
  }, [loadFormsConfig, formConfigFilterPage]);

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const response = await loadUserList(user.companyDocument);

      if (!response) return;

      setUsers(response);
    }

    loadUser();
  }, [loadUserList, user.companyDocument]);

  const handleReset = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleReset(e);
      setFilter({
        initialDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
        finalDate: format(new Date(), 'yyyy-MM-dd'),
        userId: '',
        formConfigIdTitle: ['', ''],
        process: '',
      });
    },
    [formik, setFilter]
  );

  return (
    <Main title="Formulários">
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => generateFormReportPDF(formList.filledForms)}
            >
              Exportar PDF
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1" id="filter">
              Filtros
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Data início"
              type="date"
              id="initialDate"
              name="initialDate"
              value={formik.values.initialDate}
              onChange={formik.handleChange}
              error={
                formik.touched.initialDate && Boolean(formik.errors.initialDate)
              }
              helperText={
                formik.touched.initialDate && formik.errors.initialDate
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Data fim"
              type="date"
              id="finalDate"
              name="finalDate"
              value={formik.values.finalDate}
              onChange={formik.handleChange}
              error={
                formik.touched.finalDate && Boolean(formik.errors.finalDate)
              }
              helperText={formik.touched.finalDate && formik.errors.finalDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              select
              variant="outlined"
              size="small"
              fullWidth
              label="Usuário"
              id="userId"
              name="userId"
              value={formik.values.userId}
              onChange={formik.handleChange}
              error={formik.touched.userId && Boolean(formik.errors.userId)}
              helperText={formik.touched.userId && formik.errors.userId}
            >
              <MenuItem value="">
                <em>Vazio</em>
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={`user-${user.id}`} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              select
              SelectProps={{ onClose: () => setFormConfigFilterPage(0) }}
              variant="outlined"
              size="small"
              fullWidth
              label="Formulário"
              id="formConfigIdTitle"
              name="formConfigIdTitle"
              onChange={formik.handleChange}
              value={formik.values.formConfigIdTitle?.[0]}
              error={
                formik.touched.formConfigIdTitle &&
                Boolean(formik.errors.formConfigIdTitle)
              }
              helperText={
                formik.touched.formConfigIdTitle &&
                formik.errors.formConfigIdTitle
              }
            >
              <MenuItem
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}
                value={formik.values.formConfigIdTitle}
              >
                <Button
                  disabled={formConfigFilterPage === 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setFormConfigFilterPage((prev) => prev - 1);
                  }}
                >
                  <ArrowLeft fontSize="large" />
                </Button>

                <Typography>{`Pág: ${formConfigFilterPage + 1}/${Math.ceil(
                  formsConfigs.total / FORM_CONFIG_FILTER_TAKE
                )}`}</Typography>

                <Button
                  disabled={
                    formConfigFilterPage + 1 >=
                    Math.ceil(formsConfigs.total / FORM_CONFIG_FILTER_TAKE)
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setFormConfigFilterPage((prev) => prev + 1);
                  }}
                >
                  <ArrowRight fontSize="large" />
                </Button>
              </MenuItem>

              <MenuItem value={['', '']}>
                <em>Vazio</em>
              </MenuItem>

              <MenuItem
                style={{ display: 'none' }}
                value={formik.values.formConfigIdTitle?.[0]}
              >
                <em>{formik.values.formConfigIdTitle?.[1]}</em>
              </MenuItem>

              {formsConfigs.formsConfigs.map(({ title, id }) => (
                <MenuItem key={`form-config-${id}`} value={[id + '', title]}>
                  {title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              required
              select
              variant="outlined"
              size="small"
              label="Processo"
              id="process"
              name="process"
              value={formik.values.process}
              onChange={formik.handleChange}
              error={formik.touched.process && Boolean(formik.errors.process)}
              helperText={formik.touched.process && formik.errors.process}
            >
              <MenuItem value="" disabled>
                Selecione
              </MenuItem>
              <MenuItem value="">Vazio</MenuItem>
              <MenuItem value="vehicle">Login Motorista (Veículo)</MenuItem>
              <MenuItem value="activity">Atividade</MenuItem>
              <MenuItem value="activity-item">
                Atividade Item (Produto)
              </MenuItem>
              <MenuItem value="order">Separação</MenuItem>
              <MenuItem value="conference">Conferência</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => formik.handleSubmit()}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleReset}>Limpar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Fomulário</TableCell>
                  <TableCell>Total Questões</TableCell>
                  <TableCell align="center">Preenchido em</TableCell>
                  <TableCell align="center">Imprimir</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <Loading columns={7} rows={rowsPerPage} />
                ) : (
                  formList.filledForms.map((form) => (
                    <TableRow key={form.id} hover>
                      <TableCell component="th" scope="row">
                        <strong>{form.id}</strong>
                      </TableCell>
                      <TableCell>{form.user.name}</TableCell>
                      <TableCell>{form.formConfig.title}</TableCell>
                      <TableCell>{form.data.length}</TableCell>
                      <TableCell align="center">
                        {format(parseISO(form.dateFilled), 'dd/MM/yyyy HH:mm')}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Imprimir" placement="top">
                          <span>
                            <IconButton
                              onClick={() => generateFormReportPDF([form])}
                            >
                              <MdPrint />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {!loading && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={formList.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Main>
  );
};
