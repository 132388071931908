import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table as MUITable,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Box,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Loading } from '../Loading';
import { TableCell } from './TableCell';
import { CellActions, ICellActions } from './CellActions';
import { PERMISSIONS } from '../../constants';

interface IActions extends Pick<ICellActions, 'sendTo'> {
  actionOpen: (id: any) => void;
  actionDelete: (id: any) => void;
}
// type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
//   11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

// type Join<K, P> = K extends string | number
//   ? P extends string | number
//   ? `${K}${"" extends P ? "" : "."}${P}`
//   : never
//   : never;

// type Leaves<T, D extends number = 5> = [D] extends [never]
//   ? never
//   : T extends object ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";

// type Paths<T, D extends number = 4> = [D] extends [never] ? never : T extends object ?
//   { [K in keyof T]-?: K extends string | number ?
//     `${K}` | Join<K, Paths<T[K], Prev[D]>>
//     : never
//   }[keyof T] : ""

// type ColumnField<T> = Paths<T>; // NestedObjectPaths

// type ColumnField<T> = Extract<keyof T, string>;

export interface IColumn<T> {
  hide?: boolean;
  field?: Extract<keyof T, string> | string;
  headerName: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  actions?: IActions;
  mask?: (field: any) => any;
}

interface ITableProps {
  columns: IColumn<any>[];
  rows: any[];
  totalOfRows: number;
  currentPage: number;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  loading?: boolean;
  filter?: any[];
  hasEditPermission?: keyof typeof PERMISSIONS;
  hasRemovePermission?: keyof typeof PERMISSIONS;
}

export const Table = ({
  columns,
  rows,
  totalOfRows,
  currentPage,
  handlePageChange,
  loading,
  hasEditPermission,
  hasRemovePermission,
}: ITableProps) => {
  const [maskedRows, setMaskedRows] = useState<any[]>([]);
  const [input, setInput] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];

  useEffect(() => {
    let newRows = rows;
    columns.map((column) =>
      newRows.map((row) => {
        if (
          column.field &&
          column.mask &&
          row[column.field] !== null &&
          row[column.field] !== undefined
        )
          row[column.field] = column.mask(row[column.field]);
        return row;
      })
    );

    setMaskedRows(newRows);
  }, [columns, rows]);

  useEffect(() => {
    if (currentPage !== 0) handlePageChange(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const checkValue = useCallback((value: any, filterValue: any): any => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value.toString().toLowerCase().includes(filterValue.toLowerCase());
    } else if (
      typeof value === 'object' &&
      value !== null &&
      Object.keys(value).length > 0
    ) {
      if (Array.isArray(value)) {
        return value.some((v) => checkValue(v, filterValue));
      } else {
        return Object.values(value).some((v) => checkValue(v, filterValue));
      }
    } else {
      return false;
    }
  }, []);

  const filterByValue = useCallback(
    (array: any[], input: string) => {
      return array.filter((row) =>
        // Object.keys(o).map(k => {
        //   if (k === 'id') return null;
        //   return checkMask(o, k).toLowerCase().includes(input.toLowerCase());
        // })
        input
          .trim()
          .split(', ')
          .every((filterValue) => checkValue(row, filterValue))
      );
    },
    [checkValue]
  );

  const filteredRow = useMemo(
    () => filterByValue(maskedRows, input),
    [filterByValue, input, maskedRows]
  );
  const slicedRow = useMemo(
    () =>
      filteredRow.slice(
        currentPage * rowsPerPage,
        currentPage * rowsPerPage + rowsPerPage
      ),
    [currentPage, filteredRow, rowsPerPage]
  );
  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (input ? filteredRow.length : maskedRows.length) -
        currentPage * rowsPerPage
    );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      handlePageChange(null, 0);
    },
    [handlePageChange]
  );

  const getObjectValue = useCallback((obj: any, field: string[]): any => {
    const firstPosition = field[0];
    if (typeof obj[firstPosition] === 'object' && obj[firstPosition] !== null) {
      field.shift();

      return getObjectValue(obj[firstPosition], field);
    }

    return obj[firstPosition] ?? null;
  }, []);

  return (
    <>
      <Box mb={1}>
        <TextField
          variant="outlined"
          id="input"
          name="input"
          size="small"
          value={input}
          onChange={(ev) => setInput(ev.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <MUITable>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                if (column.hide) return null;

                return (
                  <TableCell
                    key={`c-${index}`}
                    align={column.align ? column.align : 'left'}
                  >
                    {column.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <Loading
                columns={columns.filter((c) => !c.hide).length}
                rows={rowsPerPage}
                height={columns.filter((c) => c.actions) ? 81 : undefined}
              />
            ) : (
              <>
                {slicedRow.map((row, indexR) => (
                  <TableRow key={`r-${indexR}`}>
                    {columns.map((column, indexC) => {
                      let cell = null;
                      if (column.field) {
                        cell = row[column.field];

                        if (column.field.includes('.')) {
                          const splittedFields = column.field.split('.');
                          cell = getObjectValue(row, splittedFields);
                        }
                      }

                      const cellContent = column.actions ? (
                        <CellActions
                          id={parseInt(row.id ? row.id.toString() : '')}
                          sendTo={column.actions.sendTo}
                          handleOpen={() => column.actions?.actionOpen(row.id)}
                          handleDelete={() =>
                            column.actions?.actionDelete(row.id)
                          }
                          onEditPermission={hasEditPermission}
                          onRemovePermission={hasRemovePermission}
                        />
                      ) : (
                        cell
                      );

                      if (column.hide) return null;

                      return (
                        <TableCell
                          key={`c-${indexR}-${indexC}`}
                          align={column.align ? column.align : 'left'}
                        >
                          {cellContent}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height:
                        (columns.filter((c) => c.actions) ? 81 : 53) *
                        emptyRows,
                      backgroundColor: '#e9e9e9',
                    }}
                  >
                    <TableCell colSpan={columns.length} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        count={input ? filteredRow.length : totalOfRows}
        page={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
