import { Switch } from 'react-router-dom';

import { PERMISSIONS, ROUTES } from '../../../constants';
import PrivateRoute from '../../../routes/PrivateRoute';
import { DeliveryRegionProvider } from '../../delivery-region/hooks/delivery-region';
import { DistributionCenterProvider } from '../../distribution-center/hooks/distribution-center';
import { RouteProvider } from '../../route/hooks/route';
import { ManualOrderProvider } from '../hooks/manual-order';
import { OrderProvider } from '../hooks/order';
import { PlanningProvider } from '../hooks/planning';
import { CompanyParamsProvider } from '../../company-params/hooks/company-params';
import { Order } from '../pages/Order';
import { ManualOrderParamsProvider } from '../../manual-order-params/hooks/manual-order-params';

export const OrderRoute = () => (
  <ManualOrderParamsProvider>
    <CompanyParamsProvider>
      <OrderProvider>
        <RouteProvider>
          <PlanningProvider>
            <DistributionCenterProvider>
              <ManualOrderProvider>
                <DeliveryRegionProvider>
                  <Switch>
                    <PrivateRoute
                      path={ROUTES.order}
                      exact
                      permission={PERMISSIONS.ORDER_VIEW}
                      component={Order}
                    />
                  </Switch>
                </DeliveryRegionProvider>
              </ManualOrderProvider>
            </DistributionCenterProvider>
          </PlanningProvider>
        </RouteProvider>
      </OrderProvider>
    </CompanyParamsProvider>
  </ManualOrderParamsProvider>
);
