import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableRowProps,
  Checkbox,
  Avatar,
  Paper,
  Chip,
  Tooltip,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';

import { ReactComponent as BoxIcon } from '../../../../../assets/icons/box.svg';
// import boxIcon from '../../../../../assets/icons/box.svg';

import { RowActionButtons } from '../RowActionButtons';
import { RowLogInfo } from '../RowLogInfo';

import {
  formatCurrency,
  orderStatusCodeLabel,
  regionStatusLabel,
  routeStatusLabel,
  shippingStatusLabel,
  orderPaymentStatus,
} from '../../../../../utils';
import { useAuth } from '../../../../../hooks/auth';
import { IOrder, useOrder } from '../../../hooks/order';

import { useStyles } from './styles';
import { OrderInfo } from '../../OrderInfo';
import { ScheduleProvider } from '../../../../receipt/hooks/schedule';
import { SeparationProvider } from '../../../../separation/hooks/separation';
import { MdPrint } from 'react-icons/md';
import { useSnackbar } from 'notistack';
import {
  AccessTime,
  Receipt,
  Warning,
  Favorite,
  ThumbDown,
} from '@material-ui/icons';
import { format, isValid, parseISO } from 'date-fns';
import { generateRouteActivityReportPDF } from '../../../../report/templates/routeActivityReport';
import { useCompanyParams } from '../../../../company-params/hooks/company-params';

interface IProps extends TableRowProps {
  order: IOrder;
  onCheck: () => void;
  shouldDisplayMarketplace?: boolean;
  sendNps: boolean;
}

export const OrderRow = ({
  order,
  selected,
  onCheck,
  shouldDisplayMarketplace = false,
  sendNps,
  ...props
}: IProps) => {
  const {
    data: { user },
  } = useAuth();
  const { getOrderActivityReportById } = useOrder();
  const { getCompanyParams } = useCompanyParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openInfo, setOpenInfo] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [selectedItemsId, setSelectedItemsId] = useState<number[]>([]);

  const ratingIcon = (rating: number | null) => {
    if (!rating) return <Favorite fontSize="small" style={{ color: 'grey' }} />;

    if (rating < 7) return <ThumbDown fontSize="small" />;

    if (rating === 7 || rating === 8)
      return <Favorite fontSize="small" style={{ color: 'orange' }} />;

    if (rating > 8)
      return <Favorite fontSize="small" style={{ color: 'red' }} />;
  };

  const plannedStatus = useCallback((statusCode: string) => {
    if (statusCode === 'new') {
      return 'Não';
    } else if (statusCode === 'cancelled') {
      return 'Cancelado';
    } else {
      return 'Sim';
    }
  }, []);

  const disableCheckbox = useCallback(() => {
    const status = ['on-planned-route', 'on-route', 'cancelled', 'delivered'];

    return status.includes(order.statusCode);
  }, [order.statusCode]);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setOrderId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setOrderId(null);
  }, []);

  const handleSelectItem = useCallback((itemId: number) => {
    setSelectedItemsId((prevState) => {
      let newState = [...prevState];

      const itemIndex = prevState.findIndex((id) => id === itemId);

      if (itemIndex !== -1) {
        newState.splice(itemIndex, 1);
      } else {
        newState.push(itemId);
      }

      return newState;
    });
  }, []);

  const returnColor = useCallback((operationType: string) => {
    switch (operationType) {
      case 'delivery':
        return 'rgb(2,189,2)';
      case 'collect':
        return '#ff6961';
      case 'devolution':
        return '#0099ad';
      case 'cleaning':
        return '#6e6e6e';
      case 'maintenance':
        return '#a38c31';
      default:
        break;
    }
  }, []);

  function hexToAscii(code: string) {
    try {
      let hex = code.toString();
      let str = '';
      for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
      }
      return str;
    } catch (error) {
      return code;
    }
  }

  return (
    <>
      <ScheduleProvider>
        <OrderInfo id={orderId} open={openView} onClose={handleCloseView} />
      </ScheduleProvider>

      <TableRow className={classes.root} selected={selected} {...props}>
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenInfo(!openInfo)}
          >
            {openInfo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onClick={onCheck}
            disabled={disableCheckbox()}
            inputProps={{
              'aria-labelledby': order.id.toString(),
            }}
          />
        </TableCell>
        <TableCell component="th" id={order.id.toString()} scope="row">
          <strong onClick={() => handleOpenView(order.id)}>
            <Chip
              icon={
                order.occurrence ? (
                  <Tooltip
                    title={order.occurrenceDescription || ''}
                    placement="top"
                  >
                    {(() => {
                      if (
                        order?.occurrenceDescription?.includes(
                          'Tempo de operação'
                        )
                      ) {
                        return (
                          <AccessTime
                            fontSize="medium"
                            style={{ color: 'orange' }}
                          />
                        );
                      }

                      if (
                        order?.occurrenceDescription?.includes('Erro gerar NF')
                      ) {
                        return (
                          <Receipt fontSize="medium" style={{ color: 'red' }} />
                        );
                      }

                      return (
                        <Warning
                          fontSize="medium"
                          style={{ color: 'orange' }}
                        />
                      );
                    })()}
                  </Tooltip>
                ) : undefined
              }
              label={order.number}
              style={{
                color: '#fff',
                cursor: 'pointer',
                backgroundColor: returnColor(order.operationType),
              }}
            />
          </strong>
        </TableCell>
        <TableCell>{order.customer.name}</TableCell>
        <TableCell>{order.shippingAddress.city}</TableCell>
        {shouldDisplayMarketplace ? (
          <TableCell align="center">{order.marketplace}</TableCell>
        ) : (
          <TableCell>
            {regionStatusLabel(order.shippingAddress.region)}
          </TableCell>
        )}
        <TableCell>
          {orderStatusCodeLabel(order.statusCode)}
          <br />
          <strong>{orderPaymentStatus(order.paymentStatus)}</strong>
        </TableCell>
        <TableCell>
          {order.statusCode === 'cancelled'
            ? 'Cancelado'
            : shippingStatusLabel(order.shippingStatus)}
        </TableCell>
        <TableCell>{plannedStatus(order.statusCode)}</TableCell>
        <TableCell align="right">
          <strong>
            {order.items.reduce((acc, item) => {
              return acc + item.quantity;
            }, 0)}
          </strong>
        </TableCell>

        {sendNps ? (
          <TableCell>{ratingIcon(order?.nps?.rating || null)}</TableCell>
        ) : (
          user.type === 'ADMIN' && (
            <TableCell align="right">{formatCurrency(order.value)}</TableCell>
          )
        )}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={openInfo} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography align="right" variant="body1">
                Imprimir Atividade:
                <IconButton
                  disabled={
                    !['delivered', 'occurrence'].includes(order.statusCode)
                  }
                  onClick={async () => {
                    if (!order?.activity[0]?.route) {
                      enqueueSnackbar(
                        'Não foi possível Imprimir o relatório de Atividade porque o Pedido ainda não foi Roteirizado.',
                        {
                          variant: 'warning',
                        }
                      );
                      return;
                    }

                    enqueueSnackbar(
                      'Imprimindo relatório de Atividade! Aguarde, você será redirecionado.',
                      {
                        variant: 'info',
                      }
                    );

                    const { geoFenceDistance } = await getCompanyParams();
                    const report = await getOrderActivityReportById(
                      order.activity[0].route.id,
                      order.orderCode,
                      order.number
                    );

                    generateRouteActivityReportPDF(report, geoFenceDistance);
                  }}
                >
                  <MdPrint size={32} />
                </IconButton>
              </Typography>
              <Typography variant="h6" gutterBottom component="div">
                Produtos:
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>TAG</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Descrição</TableCell>
                    {user.type === 'ADMIN' && (
                      <TableCell align="right">Preço</TableCell>
                    )}
                    <TableCell align="right">Quantidade</TableCell>
                    {user.type === 'ADMIN' && (
                      <TableCell align="right">Total</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items.map((itemRow) => (
                    <TableRow key={itemRow.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            !!selectedItemsId.find(
                              (selectedItem) => selectedItem === itemRow.id
                            )
                          }
                          onClick={() => handleSelectItem(itemRow.id)}
                          disabled={order.statusCode !== 'delivered'}
                        />
                      </TableCell>
                      <TableCell padding="checkbox">
                        <Box
                          m={1}
                          component={Paper}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 40,
                            height: 40,
                          }}
                        >
                          {itemRow.isConfirmed ? (
                            <TapAndPlayIcon color="secondary" />
                          ) : itemRow.images && itemRow.images.length > 0 ? (
                            <Avatar
                              variant="square"
                              src={itemRow.images[0].url}
                              alt={itemRow.description}
                            />
                          ) : (
                            <BoxIcon width={25} height="100%" />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {hexToAscii(itemRow.code) || 'Não informado'}
                        <br />
                        {itemRow.code ? (
                          <span
                            style={{ fontSize: 10 }}
                          >{`(${itemRow.code})`}</span>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell>{itemRow.sku}</TableCell>
                      <TableCell>
                        <strong>{itemRow.description}</strong>
                      </TableCell>
                      {user.type === 'ADMIN' && (
                        <TableCell align="right">
                          {formatCurrency(parseFloat(itemRow.price))}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <strong>{itemRow.quantity}</strong>
                      </TableCell>
                      {user.type === 'ADMIN' && (
                        <TableCell align="right">
                          {formatCurrency(
                            Math.round(
                              parseFloat(itemRow.price) * itemRow.quantity * 100
                            ) / 100
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box margin={1} mb={2}>
              <SeparationProvider>
                <RowActionButtons
                  order={order}
                  type="order"
                  fractionedItemsId={selectedItemsId}
                  clearFractionedItemsId={() => setSelectedItemsId([])}
                />
              </SeparationProvider>
            </Box>
            {!order.userMarkAsSent &&
            !order.userMarkAsPackaged &&
            !order.userMarkAsDelivered &&
            !order.userMarkAsCancelled &&
            !order.observation ? null : (
              <Box margin={1} mb={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Histórico:
                </Typography>
                <RowLogInfo order={order} />
              </Box>
            )}
            <Box margin={1} mb={2}>
              <Typography variant="h6" gutterBottom component="div">
                Rotas:
              </Typography>
              {order.activity.length > 0 ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Motorista</TableCell>
                      <TableCell>Execução</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.activity.map((activityRow) => (
                      <TableRow key={activityRow.id}>
                        <TableCell>{activityRow.route.id}</TableCell>
                        <TableCell>{activityRow.route.routerName}</TableCell>
                        <TableCell>
                          {isValid(parseISO(activityRow.route.startRouteDate))
                            ? format(
                                parseISO(activityRow.route.startRouteDate),
                                'dd/MM/yyyy'
                              )
                            : activityRow.route.startRouteDate}
                        </TableCell>
                        <TableCell>
                          {activityRow.route.driver.driverName}
                        </TableCell>
                        <TableCell>{`${activityRow.prevArriveHour} - ${activityRow.prevDepartureHour}`}</TableCell>
                        <TableCell>
                          {routeStatusLabel(activityRow.route.status)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="body1" gutterBottom component="div">
                  Pedido ainda não foi roteirizado.
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
