import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { ILoadVehicle, useVehicle } from '../../hooks/vehicle';

interface IProps {
  open: boolean;
  vehicleId: number | null;
  onClose: () => void;
}

export const VehicleInfo: React.FC<IProps> = (props) => {
  const { loadVehicleById } = useVehicle();
  const [vehicle, setVehicle] = useState<ILoadVehicle>({} as ILoadVehicle);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setVehicle({} as ILoadVehicle);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadVehicle(): Promise<void> {
      if (props.vehicleId) {
        try {
          const response = await loadVehicleById(props.vehicleId);

          if (!response) return;

          setVehicle(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadVehicle();
  }, [loadVehicleById, props.vehicleId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Veículo cadastrado:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nome"
                value={vehicle.name ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Placa"
                value={vehicle.plate ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Tipo do veículo"
                value={vehicle.vehicleType.name ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Motorista"
                value={vehicle.user?.name ?? ''}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
