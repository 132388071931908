import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import logoImg from '../../../assets/logo-black.png';
import { IInventoryData } from '../pages/Inventory';

const docDefinition = (
  img: string,
  data: IInventoryData[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Inventário',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Inventário',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    data.map((inventory) => {
      const itemsData = inventory.items.map((item) => {
        return [
          {
            text: item.awb,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: item.scanDate,
            fontSize: 8,
            alignment: 'center',
          },
        ];
      });

      return [
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ['auto', '*', 'auto', '*', 'auto', '*'],
            body: [
              [
                {
                  text: 'Master',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: inventory.master,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Quantidade',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: inventory.quantity,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Conferente',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: inventory.userName,
                  fontSize: 8,
                  alignment: 'center',
                },
              ],
            ],
          },
        },

        !!itemsData && itemsData.length > 0
          ? [
              {
                table: {
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: 'Itens',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Data/Hora Scan',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                    ],
                    ...itemsData,
                  ],
                },
              },

              {
                table: {
                  widths: ['*', '*', '*', '*'],
                  body: [
                    [
                      {
                        text: 'Total Itens Lidos',
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: inventory.items.length,
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Tempo',
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: inventory.scanTime,
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                    ],
                  ],
                },
              },
            ]
          : [],
      ];
    }),
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

export async function generateInventoryReportPDF(data: IInventoryData[]) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
