import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import backgroundSignIn from '../../../../assets/background-sign-in.png';

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
`;

export const BoxLogo = styled(Box)`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;

  background: url(${backgroundSignIn});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: auto;
    height: 5rem;
  }
`;
