import * as yup from 'yup';
import { subDays } from 'date-fns';

export default yup.object().shape({
  postalcode: yup
    .string()
    .min(8, 'Insira um CEP válido')
    .required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  neighborhood: yup.string().required('Campo obrigatório'),
  number: yup.string().required('Campo obrigatório'),
  city: yup.string().required('Campo obrigatório'),
  state: yup
    .string()
    .required('Campo obrigatório')
    .test('len', 'Ex: SP', (val: string | undefined) => {
      if (val) return val.length === 2;

      return false;
    }),
  complement: yup.string(),
  previsionDate: yup
    .date()
    .min(
      subDays(new Date(), 1),
      'A data de previsão não pode anteceder a data atual!'
    ),
});
