import { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Avatar,
  Tooltip,
  RadioGroup,
  Radio,
  Collapse,
  Toolbar,
} from '@material-ui/core';

import { format, addDays } from 'date-fns';

import { useStyles } from './styles';

export interface IRecurrenceData {
  recurrenceNumber: number;
  measureOfTime: string;
  endDate: string;
  deadlineToFinish: string;
}

interface IProps {
  data: IRecurrenceData;
  onChange: (data: IRecurrenceData) => void;
}

export const CustomRecurrenceForm = ({ data, onChange }: IProps) => {
  const classes = useStyles();

  const [active, setActive] = useState<boolean[]>(new Array(7).fill(false));
  const daysOfWeek = [
    { initial: 'D', name: 'Domingo', value: 'Sunday' },
    { initial: 'S', name: 'Segunda-Feira', value: 'Monday' },
    { initial: 'T', name: 'Terça-Feira', value: 'Tuesday' },
    { initial: 'Q', name: 'Quarta-Feira', value: 'Wednesday' },
    { initial: 'Q', name: 'Quinta-Feira', value: 'Thursday' },
    { initial: 'S', name: 'Sexta-Feira', value: 'Friday' },
    { initial: 'S', name: 'Sábado', value: 'Saturday' },
  ];

  useEffect(() => {
    selectCurrentDayWeek();

    handleEndDateChange(format(addDays(new Date(), 7), 'yyyy-MM-dd'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectCurrentDayWeek() {
    const currentDate = format(new Date(), 'EEEE');

    const position = daysOfWeek.findIndex((day) => day.value === currentDate);

    const newActive: boolean[] = [...active];

    newActive[position] = true;

    setActive(newActive);
  }

  function handleRecurrenceNumberChange(value: number) {
    onChange({ ...data, recurrenceNumber: value });
  }

  function handleMeasureOfTimeChange(value: string) {
    onChange({ ...data, measureOfTime: value });
  }

  function handleEndDateChange(value: string) {
    onChange({ ...data, endDate: value });
  }

  function handleDeadlineToFinishrChange(value: string) {
    onChange({ ...data, deadlineToFinish: value });
  }

  return (
    <form noValidate>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography>
            <strong> Recorrência Personalizada</strong>
          </Typography>
        </Grid>

        <Grid item>
          <Typography>Repetir a cada: </Typography>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="recurrenceNumber"
            name="recurrenceNumber"
            type="number"
            value={data.recurrenceNumber}
            onChange={(event) =>
              handleRecurrenceNumberChange(parseInt(event.target.value))
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            select
            variant="outlined"
            size="small"
            fullWidth
            id="measureOfTime"
            name="measureOfTime"
            value={data.measureOfTime}
            onChange={(event) => handleMeasureOfTimeChange(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              displayEmpty: true,
            }}
          >
            <MenuItem value={'day'}>Dia</MenuItem>
            <MenuItem value={'week'}>Semana</MenuItem>
            <MenuItem value={'month'}>Mês</MenuItem>
            <MenuItem value={'year'}>Ano</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Collapse
            in={data.measureOfTime === 'week'}
            timeout="auto"
            unmountOnExit
          >
            <Toolbar>
              <Grid item>
                <Typography>Repetir:</Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {daysOfWeek.map((day, index) => (
                  <Tooltip
                    title={day.name}
                    placement="top"
                    onClick={() => {
                      const newActive: boolean[] = [...active];

                      newActive[index] = !active[index];

                      setActive(newActive);
                    }}
                  >
                    <Avatar
                      className={active[index] ? classes.active : classes.root}
                    >
                      {day.initial}
                    </Avatar>
                  </Tooltip>
                ))}
              </Grid>
            </Toolbar>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <Typography>Termina:</Typography>
        </Grid>

        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            aria-label="gender"
            id="deadlineToFinish"
            name="deadlineToFinish"
            value={data.deadlineToFinish}
            onChange={(event) =>
              handleDeadlineToFinishrChange(event.target.value)
            }
          >
            <FormControlLabel value="never" control={<Radio />} label="Nunca" />

            <Grid container>
              <Grid item xs={12} sm={2}>
                <FormControlLabel value="in" control={<Radio />} label="Em" />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={data.endDate}
                  onChange={(event) => handleEndDateChange(event.target.value)}
                  disabled={data.deadlineToFinish === 'never'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
    </form>
  );
};
