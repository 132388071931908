import React, { useContext, createContext, useCallback, useState } from 'react';
import api from '../../../services/api';

interface IUserData {
  email: string;
}

interface IChangePassword {
  newPassword: string;
}

interface IForgotPasswordContext {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  data: IUserData;
  setData: React.Dispatch<React.SetStateAction<IUserData>>;
  isForgotPassword: boolean;
  setIsForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  activeStepForgotPassword: number;
  setActiveStepForgotPassword: React.Dispatch<React.SetStateAction<number>>;
  userForgotPassword(data: IUserData): Promise<any>;
  userChangePassword(email: string, data: IChangePassword): Promise<void>;
}

const ForgotPasswordContext = createContext<IForgotPasswordContext>(
  {} as IForgotPasswordContext
);

const ForgotPasswordProvider: React.FC = ({ children }) => {
  const [code, setCode] = useState<string>('');
  const [data, setData] = useState<IUserData>({
    email: '',
  });
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [activeStepForgotPassword, setActiveStepForgotPassword] = useState(0);

  const userForgotPassword = useCallback(async (data: IUserData) => {
    const response = await api.post('/user/forgot-password', data);

    setCode(response.data.code);
  }, []);

  const userChangePassword = useCallback(
    async (email: string, data: IChangePassword) => {
      await api.put(`/user/change-password/${email}`, data);
    },
    []
  );

  return (
    <ForgotPasswordContext.Provider
      value={{
        code,
        setCode,
        data,
        setData,
        isForgotPassword,
        setIsForgotPassword,
        activeStepForgotPassword,
        setActiveStepForgotPassword,
        userForgotPassword,
        userChangePassword,
      }}
    >
      {children}
    </ForgotPasswordContext.Provider>
  );
};

function useForgotPassword(): IForgotPasswordContext {
  const context = useContext(ForgotPasswordContext);

  if (!context) {
    throw new Error(
      'useForgotPassword must be used within a ForgotPasswordProvider'
    );
  }

  return context;
}

export { ForgotPasswordProvider, useForgotPassword };
