import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IRastreMais {
  apiKey: string;
  user: string;
  password: string;
}

interface IRastreMaisContext {
  loadRastreMais(companyDocument: number): Promise<IRastreMais | undefined>;
  saveRastreMais(data: IRastreMais): Promise<void>;
}

const RastreMaisContext = createContext<IRastreMaisContext>(
  {} as IRastreMaisContext
);

const RastreMaisProvider: React.FC = ({ children }) => {
  const loadRastreMais = useCallback(async (companyDocument: number) => {
    const response = await api.get(
      `/integrations/rastre-mais/config/${companyDocument}`
    );

    return response.data;
  }, []);

  const saveRastreMais = useCallback(async (data: IRastreMais) => {
    await api.post('/integrations/rastre-mais/config', data);
  }, []);

  return (
    <RastreMaisContext.Provider
      value={{
        loadRastreMais,
        saveRastreMais,
      }}
    >
      {children}
    </RastreMaisContext.Provider>
  );
};

function useRastreMais(): IRastreMaisContext {
  const context = useContext(RastreMaisContext);

  if (!context) {
    throw new Error('useRastreMais must be used within a RastreMaisProvider');
  }

  return context;
}

export { RastreMaisProvider, useRastreMais };
