import {
  lightGreen,
  yellow,
  red,
  blue,
  orange,
  grey,
} from '@material-ui/core/colors';
import { ActivityStatus } from '../constants';

export const returnColor = (status: ActivityStatus) => {
  if (status === 'pending') return yellow[600];
  else if (status === 'on-route') return blue[500];
  else if (status === 'undelivered') return orange[500];
  else if (status === 'delivered') return lightGreen[500];
  else if (status === 'moved') return grey[500];
  else return red[500];
};
