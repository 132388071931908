import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { AsyncSelect } from '../../../../components/AsyncSelect';
import { Button } from '../../../../components/Button';
import { DragAndDropImage } from '../../../../components/DragAndDropImage';
import { ImageList } from '../../../../components/ImageList';
import { Main } from '../../../../components/Main';
import { ROUTES } from '../../../../constants';
import { useAuth } from '../../../../hooks/auth';
import {
  formatCurrency,
  maskMeasure,
  maskWeight,
  unmaskPrice,
} from '../../../../utils';
import { useManualOrder } from '../../../order/hooks/manual-order';
import { IProductRequest, useProduct } from '../../hooks/product';
import { Form, useStyles } from './styles';

interface IParams {
  productId?: string;
}

export const ProductForm = () => {
  const history = useHistory();
  const {
    data: { user },
  } = useAuth();
  const classes = useStyles();
  const { productId } = useParams<IParams>();
  const { loadSenderList } = useManualOrder();
  const { productUnit, loadProductById, createProduct, updateProduct } =
    useProduct();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [images, setImages] = useState<string[]>([]);
  const [data, setData] = useState<IProductRequest>({
    companyDocument: user.companyDocument.toString(),
    ownerDocument: '',
    sku: '',
    description: '',
    depth: '',
    height: '',
    weight: '',
    width: '',
    price: 0,
    quantity: 0,
    barcode: '',
    unit: 'un',
    images: [],
    active: true,
    codes: [],
  });

  const endAdornment = <InputAdornment position="end">m</InputAdornment>;

  useEffect(() => {
    async function loadProduct(): Promise<void> {
      try {
        if (productId) {
          const response = await loadProductById(parseInt(productId));

          if (!response) return;

          setData({
            ...response,
            images: [],
          });
          setImages(response.images.map((img) => img.base64));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingProduct(false);
      }
    }

    loadProduct();
  }, [loadProductById, productId]);

  const validationSchema = yup.object({
    sku: yup.string().required('Código do produto'),
    ownerDocument: yup.string().nullable(),
    description: yup.string().required('Insira a descrição do produto'),
    depth: yup.string().required('Profundidade do produto'),
    height: yup.string().required('Altura do produto'),
    width: yup.string().required('Largura do produto'),
    weight: yup.string().required('Peso do produto'),
    price: yup.string().required('Preço do produto'),
    barcode: yup.string().required('Código de barras'),
    unit: yup.string().required('Unidade de Medida do Produto'),
    quantity: yup
      .number()
      .required('Quantidade do produto')
      .test(
        'quantity-is-validate',
        'Quantidade do produto não pode ser negativa',
        (value) => (value && value < 0 ? false : true)
      ),
    active: yup.boolean().required('Está Ativo'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        values.images = images.map((img) => {
          return { base64: img };
        });

        if (productId) {
          await updateProduct(parseInt(productId), values);
        } else {
          await createProduct(values);
        }
        history.push(ROUTES.productList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleChangeWithMask = useCallback(
    (e) => {
      const { value, name } = e.target;
      const measures = ['depth', 'height', 'width'];

      if (measures.includes(name)) {
        formik.setFieldValue(name, maskMeasure(value));
      } else if (name === 'price') {
        formik.setFieldValue(name, unmaskPrice(value));
      } else if (name === 'weight') {
        formik.setFieldValue(name, maskWeight(value));
      }
    },
    [formik]
  );

  const handleSaveImage = useCallback((newImages: string[]) => {
    setImages((prevState) => [...prevState, ...newImages]);
  }, []);

  const handleDeleteImage = useCallback((removedImage: string) => {
    setImages((prevState) => {
      const newImages = [...prevState];

      const imageIndex = newImages.findIndex((image) => image === removedImage);

      newImages.splice(imageIndex, 1);

      return [...newImages];
    });
  }, []);

  const subtitle = useMemo(() => {
    if (productId) {
      return 'Alterar produto';
    } else {
      return 'Cadastrar produto';
    }
  }, [productId]);

  const submitButtonTitle = useMemo(() => {
    if (productId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [productId]);

  return (
    <Main title={subtitle} maxWidth="md" button="back" to={ROUTES.productList}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {productId && loadingProduct ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="SKU"
                      id="sku"
                      name="sku"
                      value={formik.values.sku}
                      onChange={formik.handleChange}
                      error={formik.touched.sku && Boolean(formik.errors.sku)}
                      helperText={formik.touched.sku && formik.errors.sku}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={7} style={{ zIndex: 2 }}>
                    <AsyncSelect
                      searchFunction={loadSenderList}
                      startValue={formik.values.ownerDocument}
                      name="ownerDocument"
                      setValue={formik.setFieldValue}
                      error={formik.errors.ownerDocument}
                    />
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="GTIN"
                      id="barcode"
                      name="barcode"
                      value={formik.values.barcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.barcode && Boolean(formik.errors.barcode)
                      }
                      helperText={
                        formik.touched.barcode && formik.errors.barcode
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Descrição"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Comprimento"
                      id="depth"
                      name="depth"
                      value={formik.values.depth}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.depth && Boolean(formik.errors.depth)
                      }
                      helperText={formik.touched.depth && formik.errors.depth}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ endAdornment }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Altura"
                      id="height"
                      name="height"
                      value={formik.values.height}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.height && Boolean(formik.errors.height)
                      }
                      helperText={formik.touched.height && formik.errors.height}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ endAdornment }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Largura"
                      id="width"
                      name="width"
                      value={formik.values.width}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.width && Boolean(formik.errors.width)
                      }
                      helperText={formik.touched.width && formik.errors.width}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{ endAdornment }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Peso"
                      id="weight"
                      name="weight"
                      value={formik.values.weight}
                      onChange={handleChangeWithMask}
                      error={
                        formik.touched.weight && Boolean(formik.errors.weight)
                      }
                      helperText={formik.touched.weight && formik.errors.weight}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Kg</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {user.type === 'ADMIN' && (
                    <Grid item xs={12} sm={3}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="Preço"
                        id="price"
                        name="price"
                        value={formatCurrency(formik.values.price)}
                        onChange={handleChangeWithMask}
                        error={
                          formik.touched.price && Boolean(formik.errors.price)
                        }
                        helperText={formik.touched.price && formik.errors.price}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={3}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Unidade"
                      id="unit"
                      name="unit"
                      value={formik.values.unit}
                      onChange={formik.handleChange}
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione a unidade de medida</em>
                      </MenuItem>
                      {productUnit.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Quantidade"
                      id="quantity"
                      name="quantity"
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.quantity &&
                        Boolean(formik.errors.quantity)
                      }
                      helperText={
                        formik.touched.quantity && formik.errors.quantity
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="active"
                            name="active"
                            value={formik.values.active}
                            onChange={formik.handleChange}
                            checked={formik.values.active}
                            color="primary"
                          />
                        }
                        label={<Typography> Ativo</Typography>}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <section>
                      <DragAndDropImage onSave={handleSaveImage} />

                      <ImageList images={images} onDelete={handleDeleteImage} />
                    </section>
                  </Grid>

                  {data.codes && data.codes.length > 0 && (
                    <Grid item xs={12}>
                      <Typography>
                        <b>Códigos: </b>
                      </Typography>
                      <Typography noWrap={false}>
                        {data.codes.join(' - ')}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    {submitButtonTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
