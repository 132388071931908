import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import logoImg from '../../../assets/logo-black.png';
import { IRouteLight } from '../../route/hooks/route';

const docDefinition = (
  img: string,
  data: IRouteLight[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Rotas',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Rotas',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    data.map((route) => {
      const activitiesData = route.activities?.map((activity) => {
        return [
          {
            text: activity.ordem,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: activity.number,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: activity.customer.name,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: activity.status,
            fontSize: 8,
            alignment: 'center',
          },
        ];
      });

      return [
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: `Rota: ${route.routerName}`,
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, true, true, false],
                },
              ],
            ],
          },
        },

        {
          table: {
            widths: ['auto', 'auto', 'auto', '*', 'auto', 'auto'],
            body: [
              [
                {
                  text: 'Data',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: route.startRouteDate,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Motorista',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: route.driver.driverName,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Status',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: route.status,
                  fontSize: 8,
                  alignment: 'center',
                },
              ],
            ],
          },
        },

        !!activitiesData && activitiesData.length > 0
          ? [
              {
                table: {
                  widths: ['auto', 'auto', '*', 'auto'],
                  body: [
                    [
                      {
                        text: 'Ordem',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Pedido',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Cliente',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Status',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                    ],
                    ...activitiesData,
                  ],
                },
              },
            ]
          : [],
      ];
    }),
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

export async function generateRouteReportPDF(data: IRouteLight[]) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
