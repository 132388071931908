import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IManualOrderParams {
  id: number;
  hideSerie: boolean;
  serieDefault: string;
  hideReferenceCode: boolean;
  deliveryPrevisionDays: number;
  hidePaymentStatus: boolean;
  paymentStatusDefault: string;
  shippingTypeDefault: string;
  hideShippingCost: boolean;
  shippingCostDefault: number;
  hideTransferFreight: boolean;
  transferFreightDefault: number;
  senderDefault: number | null;
  companyDocument: number;
}

type IManualOrderParamsRequest = Omit<IManualOrderParams, 'id'>;

interface IManualOrderParamsContext {
  getManualOrderParams(): Promise<IManualOrderParams>;
  createManualOrderParams(data: IManualOrderParamsRequest): Promise<void>;
  updateManualOrderParams(
    id: number,
    data: IManualOrderParamsRequest
  ): Promise<void>;
}

const ManualOrderParamsContext = createContext<IManualOrderParamsContext>(
  {} as IManualOrderParamsContext
);

const ManualOrderParamsProvider: React.FC = ({ children }) => {
  const getManualOrderParams = useCallback(async () => {
    const response = await api.get(
      '/company-params-manual-order/find/by-company'
    );

    return response.data;
  }, []);

  const createManualOrderParams = useCallback(
    async (data: IManualOrderParams) => {
      await api.post('/company-params-manual-order', data);
    },
    []
  );

  const updateManualOrderParams = useCallback(
    async (id: number, data: IManualOrderParams) => {
      await api.patch(`/company-params-manual-order/${id}`, data);
    },
    []
  );

  return (
    <ManualOrderParamsContext.Provider
      value={{
        getManualOrderParams,
        createManualOrderParams,
        updateManualOrderParams,
      }}
    >
      {children}
    </ManualOrderParamsContext.Provider>
  );
};

function useManualOrderParams(): IManualOrderParamsContext {
  const context = useContext(ManualOrderParamsContext);

  if (!context) {
    throw new Error(
      'useManualOrderParams must be used within a ManualOrderParamsProvider'
    );
  }

  return context;
}

export { ManualOrderParamsProvider, useManualOrderParams };
