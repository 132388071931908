import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Link,
  Grid,
  Typography,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useForgotPassword } from '../../hooks/forgotPassword';
import { Button } from '../../../../components/Button';
import { Form } from './styles';

export const CodeValidationForm = () => {
  const {
    code,
    data,
    setActiveStepForgotPassword,
    setCode,
    userForgotPassword,
  } = useForgotPassword();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingButton, setLoadingButton] = useState(false);
  const valueLength = 6;
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const validationSchema = yup.object({
    code: yup
      .string()
      .required('O código é obrigatório!')
      .min(6, 'Preencha todos os campos!'),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoadingButton(true);

      if (code === values.code) {
        setLoadingButton(false);
        setActiveStepForgotPassword(2);
      } else {
        setLoadingButton(false);
        const message = 'Código inválido! Verifique novamente em seu e-mail';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    },
  });

  async function resendCode() {
    try {
      formik.resetForm();
      await userForgotPassword(data);
      const message = 'Novo código enviado para seu e-mail';

      enqueueSnackbar(message, {
        variant: 'info',
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleOnChange = (
    { target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    const { value } = target;

    const valueReplaced = value.replace(/[^0-9]/g, '');

    const newOTP: string[] = [...otp];

    if (valueReplaced.length === valueLength) {
      const lastField = 5;
      const valueArray = valueReplaced.split('');

      setOtp(valueArray);
      setActiveOTPIndex(lastField);

      formik.setFieldValue('code', valueReplaced);
    } else {
      newOTP[index] = valueReplaced.substring(valueReplaced.length - 1);

      if (!valueReplaced) setActiveOTPIndex(index - 1);
      else setActiveOTPIndex(index + 1);

      setOtp(newOTP);

      let code = newOTP.toString().replace(/[^0-9]/g, '');

      formik.setFieldValue('code', code);
    }
  };

  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
    index: number
  ) => {
    if (key === 'Backspace' && otp[index] === '') setActiveOTPIndex(index - 1);
  };

  useEffect(() => {
    if (activeOTPIndex < 6) {
      inputRef.current?.focus();
    } else inputRef.current?.blur();
  }, [activeOTPIndex]);

  return (
    <Form onSubmit={formik.handleSubmit} noValidate>
      <Typography variant="h6" align="left">
        <strong>Digite o código enviado no seu e-mail</strong>
      </Typography>

      <Typography variant="subtitle2" align="left">
        Código enviado para o e-mail <strong>{data.email}</strong>
      </Typography>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        style={{
          marginTop: '36px',
          marginBottom: '36px',
        }}
      >
        {otp.map((digit, index) => {
          return (
            <Grid item xs={12} sm={2} style={{ maxWidth: 50 }}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                id="code"
                name="code"
                value={digit}
                onChange={(e) => handleOnChange(e, index)}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
                error={formik.touched.code && Boolean(formik.errors.code)}
                inputProps={{
                  style: { textAlign: 'center' },
                  ref: index === activeOTPIndex ? inputRef : null,
                }}
              />
            </Grid>
          );
        })}

        {formik.touched.code && (
          <Grid container justifyContent="center" alignItems="center">
            <FormHelperText style={{ color: 'red' }}>
              {formik.errors.code}
            </FormHelperText>
          </Grid>
        )}
      </Grid>

      <Typography
        variant="body2"
        align="center"
        style={{ marginBottom: '36px' }}
      >
        {'Deseja receber o código novamente? '}
        <Link
          component="button"
          type="button"
          variant="body2"
          onClick={resendCode}
        >
          Reenviar!
        </Link>
      </Typography>

      <Grid container justifyContent="space-between">
        <Button
          color="inherit"
          onClick={() => {
            setActiveStepForgotPassword(0);
            setCode('');
          }}
        >
          Voltar
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          loading={loadingButton}
        >
          Próximo
        </Button>
      </Grid>
    </Form>
  );
};
