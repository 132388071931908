import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { CompanyParamsForm } from '../pages/CompanyParamsForm';

import { CompanyParamsProvider } from '../hooks/company-params';

export const CompanyParamsRoute = () => (
  <CompanyParamsProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.companyParams}
        exact
        permission={PERMISSIONS.COMPANY_PARAMS_VIEW}
        component={CompanyParamsForm}
      />
    </Switch>
  </CompanyParamsProvider>
);
