import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TableRow,
  TextField,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import { useFormik } from 'formik';
import { ActionMeta } from 'react-select';
import * as yup from 'yup';

import { AsyncSelect, IOption } from '../../../../components/AsyncSelect';
import { Button } from '../../../../components/Button';
import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';
import { useAuth } from '../../../../hooks/auth';
import {
  cnpjValidation,
  cpfValidation,
  formatDateToDayMonthYear,
} from '../../../../utils';
import { useManualOrder } from '../../../order/hooks/manual-order';
import { IGenerateOcoren, IOcoren, useOcoren } from '../../hooks/ocoren';

export const Ocoren = () => {
  const { loadOcoren, generateOcoren, downloadOcoren } = useOcoren();
  const {
    data: { user },
  } = useAuth();
  const { loadSenderList } = useManualOrder();
  const [ocorens, setOcorens] = useState<IOcoren[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns: IBasicColumn[] = [
    { name: 'Remetente' },
    { name: 'Data' },
    { name: 'Nome do Arquivo' },
    { name: 'Ações', align: 'center' },
  ];

  const ocorensFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return ocorens.filter(
      (item) =>
        item.senderName.toLowerCase().includes(lowerSearch) ||
        item.date.toString().toLowerCase().includes(lowerSearch) ||
        item.filename.toLowerCase().includes(lowerSearch)
    );
  }, [search, ocorens]);

  const asyncLoadOcoren = useCallback(async () => {
    setLoading(true);
    try {
      const response = await loadOcoren();

      if (!response) return;

      setOcorens(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [loadOcoren]);

  useEffect(() => {
    asyncLoadOcoren();
  }, [asyncLoadOcoren]);

  const validationSchema = yup.object().shape({
    senderDocument: yup
      .string()
      .required('Campo obrigatório!')
      .test('validate-cpf-or-cnpj', 'Inválido', (value) =>
        value && value.length > 11
          ? cnpjValidation(value)
          : cpfValidation(value)
      ),
    dateFilter: yup.string().required('Campo obrigatório!'),
    emailsTo: yup
      .string()
      .email('Email inválido!')
      .required('Campo obrigatório!'),
  });

  const formik = useFormik<IGenerateOcoren>({
    enableReinitialize: true,
    initialValues: {
      senderDocument: user.type === 'FINAL_USER' ? user.cpf.toString() : '',
      dateFilter: '',
      emailsTo: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data: any = {
        senderDocument: values.senderDocument,
        dateFilter: values.dateFilter,
        emailsTo: [values.emailsTo],
      };

      setLoadingButton(true);

      try {
        await generateOcoren(user.companyDocument, data);
      } catch (error) {
        console.log(error);
      } finally {
        asyncLoadOcoren();
        formik.resetForm();
      }

      setLoadingButton(false);
    },
  });

  const actionOnChange = (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (option) {
      const { value, otherProps } = option;
      if (otherProps) {
        if (actionMeta.action === 'select-option') {
          formik.setFieldValue('senderDocument', value);
        }
      } else if (actionMeta.action === 'create-option') {
        formik.resetForm();
      }
    } else {
      formik.resetForm();
    }
  };

  return (
    <Main title="Proceda Ocoren">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={8} style={{ display: 'flex' }}>
              <Grid item xs={12} sm={5} style={{ zIndex: 2, marginRight: 10 }}>
                <AsyncSelect
                  searchFunction={loadSenderList}
                  name="senderDocument"
                  value={{
                    label: formik.values.senderDocument,
                    value: formik.values.senderDocument,
                  }}
                  actionOnChange={actionOnChange}
                  setValue={formik.setFieldValue}
                  error={formik.errors.senderDocument}
                  isDisabled={user.type === 'FINAL_USER' ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={3} style={{ marginRight: 10 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  label="Data"
                  id="dateFilter"
                  name="dateFilter"
                  type="date"
                  value={formik.values.dateFilter}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.dateFilter &&
                    Boolean(formik.errors.dateFilter)
                  }
                  helperText={
                    formik.touched.dateFilter && formik.errors.dateFilter
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  label="Email"
                  id="emailsTo"
                  name="emailsTo"
                  value={formik.values.emailsTo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.emailsTo && Boolean(formik.errors.emailsTo)
                  }
                  helperText={formik.touched.emailsTo && formik.errors.emailsTo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                loading={loadingButton}
                onClick={() => formik.handleSubmit()}
              >
                Gerar Ocoren
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <BasicTable
            columns={columns}
            loading={loading}
            total={ocorensFilter.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {ocorensFilter
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ocoren) => (
                <TableRow key={ocoren.id} hover>
                  <TableCell component="th" scope="row">
                    {ocoren.senderName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatDateToDayMonthYear(ocoren.date)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {ocoren.filename}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="inherit"
                      aria-label="download object"
                      onClick={() =>
                        downloadOcoren(ocoren.senderDocument, ocoren.filename)
                      }
                    >
                      <GetAppIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
