import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AccessDenied } from '../components/AccessDenied';
import { ROUTES } from '../constants';
import { AuthRoute } from '../modules/auth/routes/auth.routes';
import { CompanyParamsRoute } from '../modules/company-params/routes/company-params.routes';
import { CompanyRoute } from '../modules/company/routes/company.routes';
import { DashboardRoute } from '../modules/dashboard/routes/dashboard.routes';
import { DeliveryMaterialsRoute } from '../modules/delivery-materials/routes/deliveryMaterials.routes';
import { DeliveryRegionRoute } from '../modules/delivery-region/routes/deliveryRegion.routes';
import { DistributionCenterRoute } from '../modules/distribution-center/routes/distribution-center.routes';
import { FormRoutes } from '../modules/form/routes/form.routes';
import { HomeRoute } from '../modules/home/routes/home.routes';
import { IntegrationRoute } from '../modules/integration/routes/integration.routes';
import { InventoryRoute } from '../modules/inventory/routes/inventory.routes';
import { ManualOrderParamsRoute } from '../modules/manual-order-params/routes/manual-order-params.routes';
import { OrderRoute } from '../modules/order/routes/order.routes';
import { ProductRoute } from '../modules/product/routes/product.routes';
// import { ReceiptRoute } from '../modules/receipt/routes/receipt.routes';
import { ReportRoute } from '../modules/report/routes/report.routes';
import { RoutePlanningRoute } from '../modules/route-planning/routes/routePlanning.routes';
import { RouteRoute } from '../modules/route/routes/route.routes';
import { SeparationRoute } from '../modules/separation/routes/separation.routes';
import { StoragePositionRoute } from '../modules/storage-position/routes/storage-position.routes';
import { TrackingRoute } from '../modules/tracking/routes/tracking.routes';
import { UserManagementRoute } from '../modules/user-management/routes/user-management.routes';
import { VehicleTypeRoute } from '../modules/vehicle-type/routes/vehicle-type.routes';
import { VehicleRoute } from '../modules/vehicle/routes/vehicle.routes';
import PrivateRoute from './PrivateRoute';
import { NpsRoute } from '../modules/nps/routes/nps.route';
import { SurveyRoute } from '../modules/survey/routes/survey.routes';
import ReasonOccurrenceRoute from '../modules/reason-occurrence/routes/reason-occurrence.routes';

export const Routes: React.FC = () => (
  <BrowserRouter>
    <AuthRoute />
    <HomeRoute />
    <DashboardRoute />
    <OrderRoute />
    <RouteRoute />
    <UserManagementRoute />
    <DistributionCenterRoute />
    <VehicleRoute />
    <VehicleTypeRoute />
    <ReportRoute />
    <IntegrationRoute />
    {/* <ReceiptRoute /> */}
    <StoragePositionRoute />
    <SeparationRoute />
    <DeliveryMaterialsRoute />
    <InventoryRoute />
    <RoutePlanningRoute />
    <DeliveryRegionRoute />
    <TrackingRoute />
    <PrivateRoute path={ROUTES.accessDenied} component={AccessDenied} />
    <ProductRoute />
    <CompanyRoute />
    <CompanyParamsRoute />
    <ManualOrderParamsRoute />
    <FormRoutes />
    <NpsRoute />
    <SurveyRoute />
    <ReasonOccurrenceRoute />
  </BrowserRouter>
);
