import { whichClientCompanyIsTheDocument } from './customerCompanies';

import logo4innovation from '../assets/logo.png';
import logo4innovationWhite from '../assets/logo-white.png';
import logo4innovationBlack from '../assets/logo-black.png';

const logoAtiva =
  'https://storage.googleapis.com/log-317817.appspot.com/ativa/logo-horizontal-ativa.png';
const logoAtivaWhite =
  'https://storage.googleapis.com/log-317817.appspot.com/ativa/logo-ativa-white.png';
const logoAtivaBlack =
  'https://storage.googleapis.com/log-317817.appspot.com/ativa/logo-horizontal-ativa.png';

const companiesLogo = {
  default: {
    color: logo4innovation,
    white: logo4innovationWhite,
    black: logo4innovationBlack,
  },

  ativa: {
    color: logoAtiva,
    white: logoAtivaWhite,
    black: logoAtivaBlack,
  },
};

const companyLogo = (companyDocument?: string) =>
  companiesLogo[whichClientCompanyIsTheDocument(companyDocument)];

export { companyLogo };
