import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';

import { INps } from '../../nps/hooks/nps';
import { format } from 'date-fns';

const docDefinition = (img: string, data: INps[]): TDocumentDefinitions => ({
  info: {
    title: 'Nps',
    author: '4Innovation',
    creationDate: new Date(),
  },
  pageOrientation: 'landscape',
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório Nps',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    {
      margin: [0, 10, 0, 0],
      table: {
        widths: [
          'auto',
          'auto',
          'auto',
          '*',
          50,
          '*',
          'auto',
          'auto',
          '*',
          'auto',
        ],
        body: [
          [
            {
              text: 'Criado Em',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Respondido',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Pedido',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Cliente',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Nota',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Descrição',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Data',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Tratamento',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Resolução',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Data Resolução',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
          ],
          ...generateTable(data),
        ],
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: INps[]) {
  const rows = data.map((nps) => {
    return [
      {
        text: nps?.createdAt
          ? format(new Date(nps.createdAt), 'dd/MM/yyyy HH:mm:ss')
          : '',
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps.answered ? 'Sim' : 'Não',
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps.order?.number,
        alignment: 'center',
        fontSize: 7,
      },
      {
        text: nps.order?.customer.name,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps.rating,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps.reason,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps.dateAnswer
          ? format(new Date(nps.dateAnswer), 'dd/MM/yyyy HH:mm:ss')
          : '',
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps?.treated ? 'Sim' : 'Não',
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps?.resolution,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: nps?.dateResolution
          ? format(new Date(nps?.dateResolution), 'dd/MM/yyyy HH:mm:ss')
          : '',
        alignment: 'center',
        fontSize: 8,
      },
    ];
  });

  return rows;
}

export async function generateNpsReportPDF(data: INps[]) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
