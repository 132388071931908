import { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  DialogContent,
  TablePagination,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import {
  IItem,
  IProductPaginated,
  useManualOrder,
} from '../../../hooks/manual-order';
import { IItems } from '../ItemsTable';
import { formatCurrency } from '../../../../../utils';
import OptionButtons from './OptionButtons';
import ModalButtons from './ModalButtons';
import { Loading } from '../../../../../components/Loading';

interface IProps {
  selectedItems: IItem[];
  setItem: React.Dispatch<React.SetStateAction<IItems>>;
  onClose(): void;
}

const ItemList = ({ selectedItems, setItem, onClose }: IProps) => {
  const {
    order: { orderCode },
    loadProducts,
  } = useManualOrder();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [itemsList, setItemsList] = useState<IProductPaginated>({
    products: [],
    total: 0,
  });
  const [hasDelay, setHasDelay] = useState<NodeJS.Timeout | null>(null);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, itemsList.total - page * rowsPerPage);

  const loadOrders = useCallback(
    async (searchValue?: string) => {
      setLoading(true);

      try {
        const query =
          `?page=${page + 1}&take=${rowsPerPage}` +
          // + `&ownerDocument=${}`
          `${searchValue ? `&sku=${searchValue}` : ''}` +
          `${searchValue ? `&description=${searchValue}` : ''} `;
        // + `&barcode=${}`
        // + `&active=${}`;

        const response = await loadProducts(query);

        if (!response) return;

        setItemsList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [loadProducts, page, rowsPerPage]
  );

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchField(value);

    if (hasDelay) clearTimeout(hasDelay);
    const newTimeout = setTimeout(() => {
      loadOrders(value);
    }, 500);
    setHasDelay(newTimeout);
  };

  return (
    <>
      <DialogContent>
        <OptionButtons />

        <TextField
          variant="outlined"
          id="search"
          name="search"
          size="small"
          value={searchField}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 10 }}
        />

        <TableContainer component={Paper}>
          <Table size={loading ? 'medium' : 'small'} aria-label="Itens table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell align="right">Valor Unitário</TableCell>
                <TableCell align="right">Adicionar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <Loading columns={3} rows={rowsPerPage} />
              ) : (
                itemsList.products.length > 0 &&
                itemsList.products.map((row) => {
                  if (
                    selectedItems.filter((i) => i.sku === row.sku).length === 0
                  )
                    return (
                      <TableRow key={row.sku}>
                        <TableCell component="th" scope="row">
                          {row.sku}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(row.price)}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            tabIndex="-1"
                            color="inherit"
                            aria-label="add"
                            onClick={() =>
                              setItem((prevState) => ({
                                items: [
                                  ...prevState.items,
                                  {
                                    orderCode,
                                    productId: row.id,
                                    sku: row.sku,
                                    barcode: row.barcode,
                                    description: row.description,
                                    quantity: 1,
                                    price: row.price,
                                    depth: row.depth,
                                    width: row.width,
                                    height: row.height,
                                    weight: row.weight,
                                  },
                                ],
                              }))
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  else return null;
                })
              )}
              {!loading && emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={itemsList.total}
          rowsPerPage={rowsPerPage}
          page={itemsList.products.length > 0 ? page : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>

      <ModalButtons onClose={onClose} />
    </>
  );
};

export default ItemList;
