import { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';
import { Disposal, ILoadDisposal, useDisposal } from '../../hooks/disposal';
import { useModal } from '../../../../hooks/modal';
import { DisposalModal } from '../../components/DisposalModal';

const columnsTable: IBasicColumn[] = [
  { name: 'Código' },
  { name: 'Rota' },
  { name: 'Usuário' },
  { name: 'Imagens' },
  { name: 'Ação', align: 'center' },
];

export const DisposalReport = () => {
  const { loadDisposal } = useDisposal();
  const { open } = useModal();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [disposalList, setDisposalList] = useState<ILoadDisposal>({
    total: 0,
    disposals: [],
  });

  useEffect(() => {
    async function loadDisposalList(): Promise<void> {
      setLoading(true);

      try {
        const response = await loadDisposal({
          page: page + 1,
          take: rowsPerPage,
        });

        if (!response) return;

        setDisposalList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDisposalList();
  }, [loadDisposal, page, rowsPerPage, searchField]);

  function handleVisualizationClick(disposal: Disposal) {
    open(DisposalModal, {
      disposal,
      maxWidth: 'md',
    });
  }

  return (
    <Main title="Descarte">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8} md={5}>
          <TextField
            fullWidth
            variant="outlined"
            id="search"
            name="search"
            size="small"
            // placeholder="Digite aqui o Código do Descarte"
            value={searchField}
            onChange={(event) => setSearchField(event.target.value.trim())}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={disposalList.total}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {disposalList.disposals?.map((disposal) => (
              <TableRow key={disposal.id} hover>
                <TableCell component="th" scope="row">
                  <strong>{disposal.id}</strong>
                </TableCell>
                <TableCell>
                  {disposal.route ? disposal.route.routerName : 'Não'}
                </TableCell>
                <TableCell>
                  {disposal.user ? disposal.user.name : 'Sem Usuário'}
                </TableCell>
                <TableCell>{disposal.images.length}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Visualizar" placement="top">
                    <span>
                      <IconButton
                        onClick={() => handleVisualizationClick(disposal)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
