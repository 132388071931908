import React, { useMemo, useState } from 'react';
import { Box, Button, IconButton, Tooltip } from '@material-ui/core';
import { Theme, useTheme, withStyles } from '@material-ui/core/styles';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ShutterSpeedIcon from '@material-ui/icons/ShutterSpeed';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { Accordion } from '../../../../../components/Accordion';
import {
  calcKilometers,
  calcProgressPercent,
  calcSeconds,
  calcWidthPercent,
  getHoursAndMinutesBySeconds,
  ViewMode,
} from '../../../../../utils';
import { ITrip } from './';
import { ItemPosition } from './ItemPosition';

import {
  ItemAgent,
  LinearProgress,
  LineContainer,
  TimelineHeader,
  TimelineItem,
} from './styles';
import { OrderTip } from './OrderTip';

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

interface ICollapsibleOverviewProps {
  trips: ITrip[];
  onVisibilityChanged(trips: ITrip[]): void;
}

export const CollapsibleOverview = ({
  trips,
  onVisibilityChanged,
}: ICollapsibleOverviewProps) => {
  const theme = useTheme();
  const [showOverview, setShowOverview] = useState<string | false>(false);
  const [isVisibilityEnabled, setIsVisibilityEnabled] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<ViewMode>('kilometers');
  const greaterDistance = [...trips].sort(
    (a, b) => b.route.totalDistance - a.route.totalDistance
  )[0].route.totalDistance;
  const longestTime = [...trips].sort(
    (a, b) => b.map.totalNominalDuration - a.map.totalNominalDuration
  )[0].map.totalNominalDuration;

  const handleViewModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newViewMode: ViewMode
  ) => {
    if (newViewMode !== null) setViewMode(newViewMode);
  };

  function handleVisibilityChange(tripIndex?: number) {
    const newTrips = [...trips];

    if (typeof tripIndex === 'number') {
      newTrips[tripIndex].visible = !newTrips[tripIndex].visible;

      const hasSomeTripVisible = newTrips.reduce((value, trip) => {
        if (trip.visible) return true;
        return value;
      }, false);
      setIsVisibilityEnabled(hasSomeTripVisible);
    } else {
      newTrips.map((trip) => {
        trip.visible = !isVisibilityEnabled;

        return trip;
      });

      setIsVisibilityEnabled(!isVisibilityEnabled);
    }

    onVisibilityChanged(newTrips);
  }

  const timelineHeader = useMemo(() => {
    let headerValues = null;

    if (viewMode === 'kilometers') {
      headerValues = calcKilometers(greaterDistance).map((value) => (
        <span
          key={value}
          style={{ left: calcWidthPercent(+value, greaterDistance) }}
        >
          {value}km
        </span>
      ));
    } else {
      headerValues = calcSeconds(longestTime).map((seconds) => {
        const { fullTime } = getHoursAndMinutesBySeconds(seconds);

        return (
          <span
            key={seconds}
            style={{ left: calcWidthPercent(seconds, longestTime) }}
          >
            {fullTime}
          </span>
        );
      });
    }

    return headerValues;
  }, [greaterDistance, longestTime, viewMode]);

  return (
    <Accordion
      index={1}
      expanded={showOverview}
      setExpanded={setShowOverview}
      summary="Visão detalhada"
    >
      <Box display="flex" flexDirection="column">
        <TimelineHeader
          style={{ backgroundColor: theme.palette.secondary.main }}
        >
          <div className="button-group">
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: '#fff', minWidth: 40, marginRight: 8 }}
              onClick={() => handleVisibilityChange()}
            >
              {isVisibilityEnabled ? (
                <VisibilityIcon color="action" />
              ) : (
                <VisibilityOffIcon color="action" />
              )}
            </Button>

            <ToggleButtonGroup
              size="medium"
              style={{ backgroundColor: '#fff' }}
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
            >
              <ToggleButton value="hours">
                <ShutterSpeedIcon color="action" />
              </ToggleButton>
              <ToggleButton value="kilometers">
                <LinearScaleIcon color="action" />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="timeline">{timelineHeader}</div>
        </TimelineHeader>

        {trips
          .sort((a, b) => {
            if (viewMode === 'kilometers') {
              return b.map.totalDistance - a.map.totalDistance;
            } else {
              return b.map.totalNominalDuration - a.map.totalNominalDuration;
            }
          })
          .map((trip, tripIndex) => {
            const progressPercentage = calcProgressPercent(trip, viewMode);
            const divWidthPercentage =
              viewMode === 'kilometers'
                ? calcWidthPercent(trip.route.totalDistance, greaterDistance)
                : calcWidthPercent(trip.map.totalNominalDuration, longestTime);

            return (
              <TimelineItem key={tripIndex} even={tripIndex % 2 === 0}>
                <ItemAgent>
                  <IconButton onClick={() => handleVisibilityChange(tripIndex)}>
                    {trip.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>

                  <div>
                    <span>{trip.route.driver.driverName}</span>
                    <span style={{ fontSize: 13 }}>
                      {trip.route.vehicle.vehicleName.toLocaleUpperCase()}
                    </span>
                  </div>
                </ItemAgent>

                <LineContainer>
                  <div
                    style={{
                      position: 'relative',
                      minHeight: 'inherit',
                      width: divWidthPercentage,
                    }}
                  >
                    <LinearProgress
                      backgroundColor={trip.mapLineColor}
                      variant="determinate"
                      value={progressPercentage}
                    />

                    <ItemPosition
                      showPercentage
                      currentPosition={progressPercentage + '%'}
                      backgroundColor={trip.mapLineColor}
                    />

                    {trip.map.legs.map((_, legIndex, legsArray) => {
                      let greaterNumber = undefined;
                      let number = undefined;

                      if (viewMode === 'kilometers') {
                        greaterNumber = trip.map.totalDistance / 1000;
                        number =
                          legsArray.reduce((acc, leg, currentIndex) => {
                            if (currentIndex < legIndex) {
                              return acc + leg.distance;
                            }
                            return acc;
                          }, 0) / 1000;
                      } else {
                        greaterNumber = trip.map.totalNominalDuration;
                        number = legsArray.reduce((acc, leg, currentIndex) => {
                          if (currentIndex < legIndex) {
                            return acc + leg.nominalDuration;
                          }
                          return acc;
                        }, 0);
                      }

                      if (legIndex === 0) return null;

                      return (
                        <ItemPosition
                          key={`leg${tripIndex}-${legIndex}`}
                          currentPosition={calcWidthPercent(
                            number,
                            greaterNumber
                          )}
                          backgroundColor={trip.mapLineColor}
                        >
                          <LightTooltip
                            title={
                              <OrderTip
                                trip={trip}
                                activityIndex={legIndex - 1}
                              />
                            }
                            placement="top"
                            arrow
                          >
                            <div style={{ width: '100%', height: '100%' }} />
                          </LightTooltip>
                        </ItemPosition>
                      );
                    })}
                  </div>
                </LineContainer>
              </TimelineItem>
            );
          })}
      </Box>
    </Accordion>
  );
};
