import { Button, makeStyles, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  ModalActions,
  ModalContent,
  ModalTitle,
} from '../../../components/Modal';
import { Disposal } from '../hooks/disposal';

export const useStyles = makeStyles((theme) => ({
  slider: {
    width: '550px',
    '& #arrow:before': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
}));

const Arrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <div
      id="arrow"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

interface DisposeModalProps {
  disposal: Disposal;
  close: () => void;
}

export const DisposalModal = ({ disposal, close }: DisposeModalProps) => {
  const classes = useStyles();

  return (
    <>
      <ModalTitle>Descarte #{disposal.id}</ModalTitle>
      <ModalContent
        style={{
          width: '100%',
          height: 500,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 32,
        }}
      >
        <Slider
          className={classes.slider}
          dots
          nextArrow={<Arrow />}
          prevArrow={<Arrow />}
        >
          {disposal.images.map((value, index) => {
            const img = new Image();
            img.src = value.image;
            const isImgVertical = img.height > img.width;

            return (
              <img
                key={value.id}
                src={value.image}
                alt={`imagem-${index}`}
                style={
                  isImgVertical
                    ? {
                        width: '100%',
                        height: 'auto',
                      }
                    : {
                        width: 'auto',
                        height: '100%',
                      }
                }
              />
            );
          })}
        </Slider>

        <Typography style={{ width: '100%' }}>
          <strong>Observação:</strong> {disposal.observation ?? 'Nenhuma'}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={close} color="inherit">
          Fechar
        </Button>
      </ModalActions>
    </>
  );
};
