import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Accordion } from '../../../../components/Accordion';
import {
  IDistributionCenterResult,
  useSeparation,
} from '../../hooks/separation';
import { OrderSeparationTable } from './OrderSeparationTable';

interface IProps {
  date: string;
}

export const DistributionCenterAccordion = ({ date }: IProps) => {
  const { separationFilter, loadDistributionCenterWithSeparation } =
    useSeparation();
  const [loading, setLoading] = useState(true);
  const [distributionCenters, setDistributionCenters] =
    useState<IDistributionCenterResult>({
      result: [],
    });
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      try {
        const response = await loadDistributionCenterWithSeparation({
          ...separationFilter,
          initialDate: date,
          finalDate: date,
        });

        if (!response) return;

        setDistributionCenters(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDistributionCenter();
  }, [date, loadDistributionCenterWithSeparation, separationFilter]);

  useEffect(() => {
    if (distributionCenters.result.length === 1) setExpanded('panel0');
  }, [distributionCenters.result]);

  return (
    <Box pb={2} pt={2}>
      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress color="inherit" />
        </Typography>
      ) : (
        <>
          {distributionCenters.result.length > 0 ? (
            distributionCenters.result.map((dC, index) => (
              <Accordion
                key={index}
                index={index}
                summary={`${dC.name ?? 'Sem centro'} - Pedidos: ${dC.quantity}`}
                expanded={expanded}
                setExpanded={setExpanded}
              >
                <OrderSeparationTable date={date} />
              </Accordion>
            ))
          ) : (
            <Typography variant="body1" component="div" align="center">
              Nenhuma informação encontrada.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
