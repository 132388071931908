import { Button, DialogActions } from '@material-ui/core';
import { useManualOrder } from '../../../hooks/manual-order';

export const ModalActions = ({ validate }: { validate?: () => void }) => {
  const { steps, activeStep, setNextOrBack, handleToggleManualOrder } =
    useManualOrder();

  return (
    <DialogActions>
      <Button onClick={handleToggleManualOrder} color="inherit">
        Fechar
      </Button>

      {activeStep !== steps.length && validate && (
        <>
          <Button
            disabled={activeStep === 0}
            onClick={() => {
              validate();
              setNextOrBack(-1);
            }}
          >
            Voltar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              validate();
              setNextOrBack(1);
            }}
          >
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
          </Button>
        </>
      )}
    </DialogActions>
  );
};
