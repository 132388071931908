import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { Survey } from '../pages';
import { SurveyProvider } from '../hooks/survey';

export const SurveyRoute: React.FC = () => (
  <SurveyProvider>
    <Switch>
      <Route path={ROUTES.survey} exact component={Survey} />
    </Switch>
  </SurveyProvider>
);
