import React, { useContext, createContext, useCallback } from 'react';

interface IModalContext {
  on(event: 'open', callback: (props: ICustomEventProps) => void): void;
  open(component: React.FC<any>, props?: any): void;
}

const ModalContext = createContext<IModalContext>({} as IModalContext);

const ModalProvider: React.FC = ({ children }) => {
  const on = useCallback(
    (event: 'open', callback: (props: ICustomEventProps) => void) => {
      document.addEventListener(event, ((e: CustomEvent<ICustomEventProps>) => {
        callback(e.detail);
      }) as EventListener);
    },
    []
  );

  const open = useCallback((component: React.FC<any>, props: any) => {
    document.dispatchEvent(
      new CustomEvent('open', { detail: { component, props } })
    );
  }, []);

  return (
    <ModalContext.Provider
      value={{
        on,
        open,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };

export interface ICustomEventProps {
  component: React.FC<any>;
  props?: any;
}
