import { useEffect, useState, useCallback } from 'react';
import {
  Grid,
  TextField,
  Button,
  TableRow,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';
import { formatCurrency } from '../../../../utils';

import { useAuth } from '../../../../hooks/auth';
import {
  useProduct,
  ILoadProduct,
  IProduct,
} from '../../../product/hooks/product';

import { generateProductReportPDF } from '../../templates/productReport';
import XLSX from 'xlsx';

export const ProductReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadProductList } = useProduct();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [productList, setProductList] = useState<ILoadProduct>({
    total: 0,
    products: [],
  });

  const columnsTable: IBasicColumn[] =
    user.type === 'ADMIN'
      ? [
          { name: 'SKU' },
          { name: 'Descrição' },
          { name: 'Tipo' },
          { name: 'Preço' },
          { name: 'Quantidade' },
        ]
      : [
          { name: 'SKU' },
          { name: 'Descrição' },
          { name: 'Tipo' },
          { name: 'Quantidade' },
        ];

  useEffect(() => {
    async function loadProductReport(): Promise<void> {
      setLoading(true);

      try {
        const query =
          `?page=${page + 1}&take=${rowsPerPage}` +
          // + `&ownerDocument=${}`
          `${searchField && `&sku=${searchField}`}` +
          `${searchField && `&description=${searchField}`}`;
        // + `&barcode=${}`
        // + `&active=${}`;

        const response = await loadProductList(query);

        if (!response) return;

        setProductList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadProductReport();
  }, [loadProductList, page, rowsPerPage, searchField]);

  const downloadExcel = useCallback((data: IProduct[]) => {
    const newData = data.map((product) => {
      return user.type === 'ADMIN'
        ? {
            sku: product.sku,
            description: product.description,
            unit: product.unit,
            price: formatCurrency(product.price),
            quantity: product.quantity,
          }
        : {
            sku: product.sku,
            description: product.description,
            unit: product.unit,
            quantity: product.quantity,
          };
    });

    const header =
      user.type === 'ADMIN'
        ? [['SKU', 'Descrição', 'Tipo', 'Preço', 'Quantidade']]
        : [['SKU', 'Descrição', 'Tipo', 'Quantidade']];

    const workSheetCols =
      user.type === 'ADMIN'
        ? [
            { width: 25 },
            { width: 34 },
            { width: 4 },
            { width: 7 },
            { width: 11 },
          ]
        : [{ width: 25 }, { width: 34 }, { width: 4 }, { width: 11 }];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PRODUTOS');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Produtos.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Produtos">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={(event) => setSearchField(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() =>
                  generateProductReportPDF(
                    productList.products,
                    user.type === 'ADMIN'
                  )
                }
              >
                Exportar PDF
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => downloadExcel(productList.products)}
              >
                Exportar EXCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={productList.total}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {productList.products?.map((product) => (
              <TableRow key={product.id} hover>
                <TableCell component="th" scope="row">
                  {product.sku}
                </TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.unit}</TableCell>
                {user.type === 'ADMIN' && (
                  <TableCell>{formatCurrency(product.price)}</TableCell>
                )}
                <TableCell>{product.quantity}</TableCell>
              </TableRow>
            ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
