import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useStyles, Form } from './styles';
import { useAuth } from '../../../../hooks/auth';
import { ITotvs, useTotvs } from '../../hooks/totvs';

import { Button } from '../../../../components/Button';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const Totvs: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const {
    data: { user },
  } = useAuth();
  const { loadTotvs, saveTotvs } = useTotvs();
  const [loadingButton, setLoadingButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingTotvs, setLoadingTotvs] = useState(true);
  const [data, setData] = useState<ITotvs>({
    user: '',
    password: '',
    url: '',
    codCompany: '',
    codFilial: '',
  });

  useEffect(() => {
    async function loadIntegration(): Promise<void> {
      try {
        const response = await loadTotvs(user.companyDocument, 'totvs-ativa');

        if (!response) return;

        setData({
          ...response,
        });
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoadingTotvs(false);
      }
    }
    if (props.open) loadIntegration();
  }, [loadTotvs, props.open, user.companyDocument]);

  const validationSchema = yup.object().shape({
    user: yup.string().required('campo obrigatório!'),
    password: yup.string().required('campo obrigatório!'),
    url: yup.string().required('campo obrigatório!'),
    codCompany: yup.string().required('campo obrigatório!'),
    codFilial: yup.string().required('campo obrigatório!'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      let data: any = {};

      data = {
        integrationName: 'totvs-ativa',
        params: [
          {
            ...values,
          },
        ],
      };

      try {
        await saveTotvs(data);
        props.onClose();
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleClose = useCallback(() => {
    props.onClose();
    formik.resetForm();
    setLoadingTotvs(true);
  }, [props, formik]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">TOTVS</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.form}
        >
          <Grid container spacing={3}>
            {loadingTotvs ? (
              <Grid item xs={12}>
                <Typography component="div" align="center">
                  <CircularProgress />
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Usuário"
                    id="user"
                    name="user"
                    value={formik.values.user}
                    onChange={formik.handleChange}
                    error={formik.touched.user && Boolean(formik.errors.user)}
                    helperText={formik.touched.user && formik.errors.user}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Senha"
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="URL"
                    id="url"
                    name="url"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Código da Empresa"
                    id="codCompany"
                    name="codCompany"
                    value={formik.values.codCompany}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.codCompany &&
                      Boolean(formik.errors.codCompany)
                    }
                    helperText={
                      formik.touched.codCompany && formik.errors.codCompany
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Código da Filial"
                    id="codFilial"
                    name="codFilial"
                    value={formik.values.codFilial}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.codFilial &&
                      Boolean(formik.errors.codFilial)
                    }
                    helperText={
                      formik.touched.codFilial && formik.errors.codFilial
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Fechar
        </Button>

        <Button
          onClick={() => formik.handleSubmit()}
          color="secondary"
          variant="contained"
          loading={loadingButton}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
