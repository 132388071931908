import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { CreatePlanning } from '../../../../components/CreatePlanning';
import { Main } from '../../../../components/Main';
import { useSettings } from '../../../../hooks/settings';
import { TotalRoutesCard } from '../../../dashboard/components/Cards/TotalRoutesCard';
import { RouteAccordion } from '../../components/Route/Accordion';
import { RouteHistory } from '../../components/RouteHistory';
import { RouteHistoryCard } from '../../components/RouteHistoryCard';

export const Route = () => {
  const { cardsInfo } = useSettings();
  const [isEnabled, setIsEnabled] = useState<'route' | 'history'>('route');

  return (
    <Main>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={6} md={4}>
          <TotalRoutesCard
            content={cardsInfo.totalRoutes}
            percentage={cardsInfo.percentageRoutesVariation}
            onClick={() => setIsEnabled('route')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <RouteHistoryCard onClick={() => setIsEnabled('history')} />
        </Grid>

        <Grid item xs={12}>
          {isEnabled === 'route' ? <RouteAccordion /> : <RouteHistory />}

          <CreatePlanning isEnabled={isEnabled === 'history'} />
        </Grid>
      </Grid>
    </Main>
  );
};
