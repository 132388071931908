import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { NpsForm } from '../pages/NpsForm';
import { NpsList } from '../pages/NpsList';
import { NpsProvider } from '../hooks/nps';

export const NpsRoute: React.FC = () => (
    <NpsProvider>
    <Switch>
    <PrivateRoute
          path={ROUTES.npsList}
          exact
          //permission={PERMISSIONS.NPS_VIEW}
          component={NpsList}
        />
    </Switch>
    </NpsProvider>
)
  
        