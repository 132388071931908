import { Button, Grid, TableCell, TableRow } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { RouteFilter } from '../../../route/components/RouteFilter';

import { ActivityProvider } from '../../../route/hooks/activity';
import { useRoute } from '../../../route/hooks/route';

import XLSX from 'xlsx';
import { useSnackbar } from 'notistack';
import { generateDriverRouteSummarizedReportPDF } from '../../templates/driverRouteSummarizedReport';

export interface IDriverRouteSummarized {
  driverId: number;
  driverName: string;
  pending: number;
  delivered: number;
  occurrence: number;
  cancelled: number;
  notExecuted: number;
  totalDistance: number;
  routes: number;
}

export const DriverRouteSummaryReport = () => {
  const { loadDriverRouteSummarized, filter } = useRoute();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [driverRoutesSummarized, setDriverRoutesSummarized] = useState<
    IDriverRouteSummarized[]
  >([]);

  const dataColumns: IBasicColumn[] = [
    { name: 'Motorista' },
    { name: 'Concluído(s)' },
    { name: 'Pendente(s)' },
    { name: 'Ocorrência(s)' },
    { name: 'Cancelada(s)' },
    { name: 'Não Executada(s)' },
    { name: 'Rotas' },
    { name: 'Distância Total' },
  ];

  useEffect(() => {
    async function loadDriverRouteReport(): Promise<void> {
      setLoading(true);

      const { initialDate, finalDate, driverId } = filter;

      const query =
        `${initialDate && `?initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}` +
        `${driverId && `&driverId=${driverId}`}`;

      try {
        const response = await loadDriverRouteSummarized(query);

        if (!response) return;

        const newData = response;

        setDriverRoutesSummarized(newData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDriverRouteReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const downloadExcel = useCallback(() => {
    // eslint-disable-next-line array-callback-return
    const newData = driverRoutesSummarized.map(
      ({
        driverName,
        delivered,
        pending,
        occurrence,
        cancelled,
        notExecuted,
        routes,
        totalDistance,
      }) => {
        return {
          driverName,
          delivered,
          pending,
          occurrence,
          cancelled,
          notExecuted,
          routes,
          totalDistance: `${totalDistance} Km`,
        };
      }
    );
    const header = [
      [
        'Motorista',
        'Concluído(s)',
        'Pendente(s)',
        'Ocorrência(s)',
        'Cancelada(s)',
        'Não Executada(s)',
        'Rotas',
        'Distância Total',
      ],
    ];

    const workSheetCols = [
      { width: 36 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 14 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'ROTAS SUMARIZADAS');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Rotas sumarizado.xlsx.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverRoutesSummarized]);

  return (
    <Main title="Relatório de Rotas por Motorista">
      <Grid container spacing={1}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                enqueueSnackbar(
                  `Imprimindo relatório de Rotas Sumarizadas! Aguarde, você será redirecionado.`,
                  {
                    variant: 'info',
                  }
                );
                generateDriverRouteSummarizedReportPDF(driverRoutesSummarized, {
                  initial: filter.initialDate,
                  final: filter.finalDate,
                });
              }}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={downloadExcel}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <RouteFilter
          hiddenFields={{ status: 'none', order: 'none', routerName: 'none' }}
        />

        <Grid item xs={12}>
          <BasicTable
            columns={dataColumns}
            loading={loading}
            total={driverRoutesSummarized.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {driverRoutesSummarized
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((driver) => {
                return (
                  <ActivityProvider key={driver.driverId}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {driver.driverName}
                      </TableCell>
                      <TableCell>{driver.delivered}</TableCell>
                      <TableCell>{driver.pending}</TableCell>
                      <TableCell>{driver.occurrence}</TableCell>
                      <TableCell>{driver.cancelled}</TableCell>
                      <TableCell>{driver.notExecuted}</TableCell>
                      <TableCell>{driver.routes}</TableCell>
                      <TableCell>{`${driver.totalDistance} Km`}</TableCell>
                    </TableRow>
                  </ActivityProvider>
                );
              })}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
