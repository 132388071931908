import { useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@material-ui/core';

import { PeriodEnum, useSettings } from '../../../hooks/settings';

const PERIOD_LABEL = {
  [PeriodEnum.SEVEN]: 'Últimos 7 dias',
  [PeriodEnum.THIRTY]: 'Últimos 30 dias',
  [PeriodEnum.CURRENT_MONTH]: 'Mês atual',
  [PeriodEnum.LAST_MONTH]: 'Mês anterior',
  [PeriodEnum.CUSTOM]: 'Customizado',
};

export const PopoverListButton = () => {
  const { selectedPeriod, changePeriod } = useSettings();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'days-popover' : undefined;

  const periodList = (
    Object.keys(PERIOD_LABEL) as Array<keyof typeof PERIOD_LABEL>
  ).reduce((accumulator, current) => {
    accumulator.push({ label: PERIOD_LABEL[current], period: current });
    return accumulator;
  }, [] as { label: string; period: PeriodEnum }[]);

  const handleListItemClick = (newPeriod: PeriodEnum) => {
    changePeriod(newPeriod);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {PERIOD_LABEL[selectedPeriod]}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List aria-label="date list">
          {periodList.map(({ period, label }) => {
            if (period === PeriodEnum.CUSTOM) return null;

            return (
              <ListItem
                key={period}
                button
                selected={period === selectedPeriod}
                onClick={() => handleListItemClick(period)}
              >
                <ListItemText primary={label} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
