import { useState, useCallback } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import OpenWithIcon from '@material-ui/icons/OpenWith';

import { ActivityProvider, IActivity } from '../../../hooks/activity';
import { CancelActivity } from '../CancelActivity';
import { MoveActivity } from '../MoveActivity';
import { IRoute } from '../../../hooks/route';

interface IProps {
  route: IRoute;
  activity: IActivity;
}

export const MapPopupButtons = ({ route, activity }: IProps) => {
  const [openMoveActivity, setOpenMoveActivity] = useState(false);
  const [openCancelActivity, setOpenCancelActivity] = useState(false);

  const handleToggleMoveActivity = useCallback(() => {
    setOpenMoveActivity((prevState) => !prevState);
  }, []);

  const handleToggleCancelActivity = useCallback(() => {
    setOpenCancelActivity((prevState) => !prevState);
  }, []);

  const isNotDisabled = useCallback(() => {
    if (activity.status === 'pending' || activity.status === 'on-route')
      return false;

    return true;
  }, [activity.status]);

  return (
    <>
      <ActivityProvider>
        <MoveActivity
          open={openMoveActivity}
          onClose={handleToggleMoveActivity}
          route={route}
          activity={activity}
        />

        <CancelActivity
          open={openCancelActivity}
          onClose={handleToggleCancelActivity}
          routeId={route.id}
          routeName={route.routerName}
          activity={activity}
        />
      </ActivityProvider>

      <Tooltip title="Mover Pedidos" placement="top">
        <span>
          <IconButton
            color="inherit"
            aria-label="Mover Pedidos"
            onClick={handleToggleMoveActivity}
            disabled={isNotDisabled()}
          >
            <OpenWithIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Cancelar Pedido" placement="top">
        <span>
          <IconButton
            color="inherit"
            aria-label="cancel"
            onClick={handleToggleCancelActivity}
            disabled={isNotDisabled()}
          >
            <CancelIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
