import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  root: {
    margin: 'none',
  },
  cardHeader: {
    padding: theme.spacing(1, 0),
  },
  list: {
    width: 350,
    height: 270,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(1.0, 0),
  },
  table: {
    minWidth: 700,
  },
}));
