import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { useAuth } from '../../../../hooks/auth';
import { useSettings } from '../../../../hooks/settings';
import { conferenceStatus, shippingStatus } from '../../../../utils';
import {
  ILoadDistributionCenter,
  useDistributionCenter,
} from '../../../distribution-center/hooks/distribution-center';
import { useSeparation } from '../../hooks/separation';

const validationSchema = yup.object({
  initialDate: yup.date(),
  finalDate: yup
    .date()
    .min(
      yup.ref('initialDate'),
      'Data fim não pode ser menor que a data início'
    ),
});

export const SeparationFilter = () => {
  const {
    data: { user },
  } = useAuth();
  const { selectedPeriod, getDatePeriod } = useSettings();
  const { loadDistributionCenterList } = useDistributionCenter();
  const { separationFilter, setSeparationFilter } = useSeparation();

  const [distributionCenterList, setDistributionCenterList] = useState<
    ILoadDistributionCenter[]
  >([]);

  useEffect(() => {
    async function asyncLoadDistributionCenterList(): Promise<void> {
      try {
        const response = await loadDistributionCenterList(user.companyDocument);
        if (!response) return;

        setDistributionCenterList(response);
      } catch (error) {
        console.log(error);
      }
    }

    asyncLoadDistributionCenterList();
  }, [loadDistributionCenterList, user.companyDocument]);

  const { values, errors, touched, handleChange, handleSubmit, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...separationFilter,
      },
      validationSchema,
      onSubmit: (values) => {
        setSeparationFilter({ ...values });
      },
    });

  const handleFormReset = useCallback(
    (e) => {
      e.preventDefault();

      const { from, to } = getDatePeriod(selectedPeriod);

      handleReset(e);
      setSeparationFilter((prevState) => ({
        orderNumber: '',
        distributionCenterId: '',
        conferenceStatus: prevState.conferenceStatus,
        shippingStatus: '',
        initialDate: from,
        finalDate: to,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getDatePeriod, handleReset, selectedPeriod]
  );

  return (
    <Grid
      container
      spacing={1}
      component="form"
      onSubmit={handleSubmit}
      onReset={handleFormReset}
    >
      <Grid item xs={12}>
        <Typography variant="body1" id="filter">
          Filtros
        </Typography>
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Nº do pedido"
          id="orderNumber"
          name="orderNumber"
          value={values.orderNumber}
          onChange={handleChange}
          error={touched.orderNumber && Boolean(errors.orderNumber)}
          helperText={touched.orderNumber && errors.orderNumber}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Centro de Distribuição"
          id="distributionCenterId"
          name="distributionCenterId"
          value={values.distributionCenterId}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {distributionCenterList.map((dC) => (
            <MenuItem key={`dC-${dC.id}`} value={dC.id}>
              {dC.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Data início"
          type="date"
          id="initialDate"
          name="initialDate"
          value={values.initialDate}
          onChange={handleChange}
          error={touched.initialDate && Boolean(errors.initialDate)}
          helperText={touched.initialDate && errors.initialDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Data fim"
          type="date"
          id="finalDate"
          name="finalDate"
          value={values.finalDate}
          onChange={handleChange}
          error={touched.finalDate && Boolean(errors.finalDate)}
          helperText={touched.finalDate && errors.finalDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Status de Conferência"
          id="conferenceStatus"
          name="conferenceStatus"
          value={values.conferenceStatus}
          onChange={handleChange}
        >
          {conferenceStatus.map((status) => (
            <MenuItem
              key={`conferenceStatus-${status.value}`}
              value={status.value}
            >
              {status.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6} sm={3}>
        <TextField
          select
          variant="outlined"
          size="small"
          fullWidth
          label="Status de Envio"
          id="shippingStatus"
          name="shippingStatus"
          value={values.shippingStatus}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>Vazio</em>
          </MenuItem>
          {shippingStatus.map((shipping) => (
            <MenuItem key={shipping.value} value={shipping.value}>
              {shipping.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="secondary" type="submit">
            Filtrar
          </Button>
        </Grid>

        <Grid item>
          <Button type="reset">Limpar</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
