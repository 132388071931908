/* eslint-disable no-plusplus */
export const unmask = (str: string): string => str.replace(/[-)./(+ ]/g, '');

export const unmaskPrice = (value: string): number =>
  value ? Number(value.replace(/\D/g, '')) / 100 : 0;

export const mask = (str: string, ...masks: any[]): string => {
  const auxMask =
    masks.find((it) => unmask(it).length >= unmask(str).length) ||
    masks.sort((a, b) => unmask(a).length - unmask(b).length).pop();

  if (!auxMask) return str;

  let auxStr = unmask(str);
  const maskLength = unmask(auxMask).length;
  if (auxStr.length > maskLength) {
    auxStr = str.substring(0, maskLength);
  }
  let maskedStr = '';
  let j = 0;
  for (let i = 0; i < auxStr.length; i++) {
    try {
      while (auxMask[j] && auxMask[j] !== '#') maskedStr += auxMask[j++];
      maskedStr += auxStr[i];
      j++;
    } catch {
      break;
    }
  }

  return maskedStr;
};

export const maskTelephone = (cellPhone: string): string => {
  return mask(
    cellPhone.replace(/\D/g, ''),
    '(##) ####-####',
    '(##) # ####-####'
  );
};

export const maskCoordinate = (coordinate: string): string => {
  return coordinate.replace(/[^0-9-.]/g, '');
};

export const maskPostalcode = (postalcode: string): string => {
  return mask(postalcode.replace(/\D/g, ''), '##.###-###');
};

export const maskCpf = (document: string): string => {
  return mask(document.replace(/\D/g, ''), '###.###.###-##');
};

export const maskCnpj = (document: string): string => {
  return mask(document.replace(/\D/g, ''), '##.###.###/####-##');
};

export const maskWeight = (weight: string): string => {
  return mask(
    weight.replace(/\D/g, ''),
    '#.###',
    '##.###',
    '###.###',
    '####.###',
    '#####.###'
  );
};

export const maskMeasure = (measure: string): string => {
  return mask(measure.replace(/\D/g, ''), '#.###', '##.###', '###.###');
};
export const hideInfoDocuments = (document: string): string => {
  if (document.length === 11) {
    return `${document.substring(0, 3)}.***.***-${document.substring(
      document.length - 2
    )}`;
  } else {
    return `${document.substring(0, 2)}.***.***/****-${document.substring(
      document.length - 2
    )}`;
  }
};

export const hideInfoAddress = (address: string): string => {
  const splitAddress = address.split(' ');
  return `${splitAddress[0]} ***** ${address.substring(address.length - 2)}`;
};

export const hideInfoPostalCode = (postalCode: string): any => {
  if (postalCode.length === 8) {
    return `${postalCode.substring(0, 2)}***-${postalCode.substring(
      postalCode.length - 3
    )}`;
  }
};

export const hideInfoNeighborhood = (neighborhood: string): any => {
  if (neighborhood.length <= 20) {
    return `${neighborhood.substring(0, 1)} ****** ${neighborhood.substring(
      neighborhood.length - 1
    )}`;
  }
};
