import React, { useContext, createContext, useCallback } from 'react';

import api from '../../../services/api';

type NumberFieldValue = number | '';

export interface IStoragePosition {
  companyDocument: string;
  id: number;
  distributionCenterCode: string;
  street: string;
  column: string;
  level: string;
  apartment: string;
  code: string;
  depth: NumberFieldValue;
  height: NumberFieldValue;
  width: NumberFieldValue;
  maxWeight: NumberFieldValue;
  maxVolume: NumberFieldValue;
}

export interface ILoadStoragePosition {
  total: number;
  storagePositions: IStoragePosition[];
}

export type IStoragePositionRequest = Omit<IStoragePosition, 'id'>;

interface IStoragePositionContext {
  loadStoragePositionById(id: number): Promise<IStoragePosition>;
  loadStoragePositionList(): Promise<ILoadStoragePosition>;
  updateStoragePosition(
    id: number,
    data: IStoragePositionRequest
  ): Promise<void>;
  createStoragePosition(data: IStoragePositionRequest): Promise<void>;
  deleteStoragePosition(id: number): Promise<void>;
}

const StoragePositionContext = createContext<IStoragePositionContext>(
  {} as IStoragePositionContext
);

const StoragePositionProvider: React.FC = ({ children }) => {
  const loadStoragePositionById = useCallback(async (id: number) => {
    const response = await api.get(`/storage-position/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadStoragePositionList = useCallback(async () => {
    const response = await api.get('/storage-position');

    return response.data;
  }, []);

  const createStoragePosition = useCallback(
    async (data: IStoragePositionRequest) => {
      await api.post('/storage-position', data);
    },
    []
  );

  const updateStoragePosition = useCallback(
    async (id: number, data: IStoragePositionRequest) => {
      await api.patch(`/storage-position/${id}`, data);
    },
    []
  );

  const deleteStoragePosition = useCallback(async (id: number) => {
    await api.delete(`/storage-position/${id}`);
  }, []);

  return (
    <StoragePositionContext.Provider
      value={{
        loadStoragePositionById,
        loadStoragePositionList,
        createStoragePosition,
        updateStoragePosition,
        deleteStoragePosition,
      }}
    >
      {children}
    </StoragePositionContext.Provider>
  );
};

function useStoragePosition(): IStoragePositionContext {
  const context = useContext(StoragePositionContext);

  if (!context) {
    throw new Error(
      'useStoragePosition must be used within a StoragePositionProvider'
    );
  }

  return context;
}

export { StoragePositionProvider, useStoragePosition };
