import { useEffect, useState } from 'react';
import { INps, useNps } from '../../hooks/nps';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { maskPostalcode } from '../../../../utils';
import { format } from 'date-fns';
import { Button as ButtonComponent } from '../../../../components/Button';

interface IProps {
  open: boolean;
  npsId: number | null;
  onClose: () => void;
}

export const NpsForm: React.FC<IProps> = (props) => {
  const { loadNpsById, updateResolutionNps } = useNps();
  const [nps, setNps] = useState<INps>({} as INps);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (props.open) {
      setNps({} as INps);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadNps(): Promise<void> {
      if (props.npsId) {
        try {
          const response = await loadNpsById(props.npsId);

          if (!response) return;

          setNps(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadNps();
  }, [loadNpsById, props.npsId]);

  const handleResolution = async () => {
    if (!nps.resolution || nps.resolution.length === 0) {
      return;
    }
    setLoadingButton(true);

    await updateResolutionNps(nps.id, { resolution: `${nps.resolution}` });

    const response = await loadNpsById(nps.id);

    if (!response) return;

    setNps(response);

    setLoadingButton(false);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Dados do NPS</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Pedido: {nps.order?.number}
          <Typography gutterBottom>{nps.order?.customer.name}</Typography>
          {nps.order?.shippingAddress && (
            <Typography gutterBottom>
              {nps.order.shippingAddress.street}
              {', '}
              {nps.order.shippingAddress.number}
              {' - '}
              {nps.order.shippingAddress.neighborhood}
              <br />
              {maskPostalcode(nps.order.shippingAddress.postalcode)}
              {' - '}
              {nps.order.shippingAddress.city}
              {' - '}
              {nps.order.shippingAddress.state}
            </Typography>
          )}
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Nota"
                  value={nps.rating ?? 'Não informado'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data"
                  value={
                    nps.dateAnswer
                      ? format(new Date(nps.dateAnswer), 'dd/MM/yyyy HH:mm:ss')
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  label="Comentário"
                  value={nps.reason}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            {nps.rating && nps.rating < 7 && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Tratativa
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                    label="Resolução"
                    value={nps.resolution}
                    onChange={(e) => (nps.resolution = e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: !nps.treated ? false : true,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  <>
                    <ButtonComponent
                      variant="contained"
                      color="secondary"
                      fullWidth
                      style={{ height: 40 }}
                      onClick={handleResolution}
                      loading={loadingButton}
                      disabled={!nps.treated ? false : true}
                    >
                      Salvar
                    </ButtonComponent>
                  </>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
