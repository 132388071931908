import * as yup from 'yup';

export default yup.object().shape({
  orderCode: yup.string().required('Campo obrigatório'),
  sku: yup.string().required('Campo obrigatório'),
  barcode: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  quantity: yup.number().required('Campo obrigatório'),
  price: yup.number().required('Campo obrigatório'),
  depth: yup.string().required('Campo obrigatório'),
  width: yup.string().required('Campo obrigatório'),
  height: yup.string().required('Campo obrigatório'),
  weight: yup.string().required('Campo obrigatório'),
});
