import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IStates {
  id: number;
  ufCode: string;
  name: string;
}

export interface ICities {
  id: number;
  ufCode: string;
  name: string;
}

export interface IDeliveryRegionNeighborhood {
  id?: number;
  name: string;
  city: string;
  state: string;
  region?: string;
}

export interface IDeliveryRegion {
  id?: number;
  name: string;
  companyDocument: number | '';
  neighborhoods: IDeliveryRegionNeighborhood[];
}

export interface ILoadDeliveryRegion {
  id: number;
  name: string;
  companyDocument: number | '';
  neighborhoods: IDeliveryRegionNeighborhood[];
}

interface IDeliveryRegionContext {
  loadStatesList(): Promise<IStates[]>;
  loadCitiesList(ufCode: string): Promise<ICities[]>;
  loadNeighborhoodsList(
    ufCode: string,
    city: string
  ): Promise<IDeliveryRegionNeighborhood[]>;
  loadDeliveryRegionById(id: number): Promise<ILoadDeliveryRegion | undefined>;
  loadDeliveryRegionList(): Promise<ILoadDeliveryRegion[]>;
  updateDeliveryRegion(id: number, data: IDeliveryRegion): Promise<void>;
  createDeliveryRegion(data: IDeliveryRegion): Promise<void>;
  deleteDeliveryRegion(id: number): Promise<void>;
}

const DeliveryRegionContext = createContext<IDeliveryRegionContext>(
  {} as IDeliveryRegionContext
);

const DeliveryRegionProvider: React.FC = ({ children }) => {
  const loadStatesList = useCallback(async () => {
    const response = await api.get('/delivery-region/list/states');

    return response.data;
  }, []);

  const loadCitiesList = useCallback(async (ufCode: string) => {
    const response = await api.get(`/delivery-region/list/cities/${ufCode}`);

    return response.data;
  }, []);

  const loadNeighborhoodsList = useCallback(
    async (ufCode: string, city: string) => {
      const response = await api.get(
        `/delivery-region/list/neighborhoods/${ufCode}/${city}`
      );

      return response.data;
    },
    []
  );

  const loadDeliveryRegionById = useCallback(async (id: number) => {
    const response = await api.get(`/delivery-region/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadDeliveryRegionList = useCallback(async () => {
    const response = await api.get('/delivery-region');

    return response.data;
  }, []);

  const createDeliveryRegion = useCallback(async (data: IDeliveryRegion) => {
    await api.post('/delivery-region', data);
  }, []);

  const updateDeliveryRegion = useCallback(
    async (id: number, data: IDeliveryRegion) => {
      await api.put(`/delivery-region/${id}`, data);
    },
    []
  );

  const deleteDeliveryRegion = useCallback(async (id: number) => {
    await api.delete(`/delivery-region/${id}`);
  }, []);

  return (
    <DeliveryRegionContext.Provider
      value={{
        loadStatesList,
        loadCitiesList,
        loadNeighborhoodsList,
        loadDeliveryRegionById,
        loadDeliveryRegionList,
        updateDeliveryRegion,
        createDeliveryRegion,
        deleteDeliveryRegion,
      }}
    >
      {children}
    </DeliveryRegionContext.Provider>
  );
};

function useDeliveryRegion(): IDeliveryRegionContext {
  const context = useContext(DeliveryRegionContext);

  if (!context) {
    throw new Error(
      'useDeliveryRegion must be used within a DeliveryRegionProvider'
    );
  }

  return context;
}

export { DeliveryRegionProvider, useDeliveryRegion };
