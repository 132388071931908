import { useMemo, useCallback } from 'react';
import { Grid, Box, Typography, Link } from '@material-ui/core';

import { SignIn } from '../../components/SignIn';
import { SignUp } from '../../components/SignUp';
import { ForgotPassword } from '../../components/ForgotPassword';

import { useSignUp } from '../../hooks/signUp';
import { useForgotPassword } from '../../hooks/forgotPassword';

import logo from '../../../../assets/logo.png';

import { Card, BoxLogo } from './styles';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        4Innovation
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export const Auth = () => {
  const { setActiveStep, isRegistering, setIsRegistering } = useSignUp();
  const { isForgotPassword, setIsForgotPassword } = useForgotPassword();

  const handleClickRegister = useCallback(() => {
    setIsRegistering(!isRegistering);
    setActiveStep(0);
    setIsForgotPassword(false);
  }, [isRegistering, setActiveStep, setIsForgotPassword, setIsRegistering]);

  const Register = useMemo(() => {
    return (
      <Typography variant="body2" align="center">
        {isRegistering
          ? 'Já tem uma conta cadastrada? '
          : 'Não tem uma conta ainda? '}
        <Link component="button" variant="body2" onClick={handleClickRegister}>
          {isRegistering ? 'Entre com sua conta!' : 'Cadastre-se agora!'}
        </Link>
      </Typography>
    );
  }, [handleClickRegister, isRegistering]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', width: 'auto' }}
    >
      <Grid item xs={12} style={{ margin: '20px' }}>
        <Card>
          <BoxLogo>
            <img src={logo} alt="Logo 4Innovation" />
          </BoxLogo>

          {isRegistering ? (
            <SignUp />
          ) : isForgotPassword ? (
            <ForgotPassword />
          ) : (
            <SignIn />
          )}
        </Card>

        <Box mt={3}>{Register}</Box>

        <Box mt={2}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};
