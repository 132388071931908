import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
  CircularProgress,
  Box,
} from '@material-ui/core';
import {
  ILoadDistributionCenter,
  useDistributionCenter,
} from '../../hooks/distribution-center';

interface IProps {
  open: boolean;
  distributionCenterId: number | null;
  onClose: () => void;
}

export const DistributionCenterInfo: React.FC<IProps> = (props) => {
  const { loadDistributionCenterById } = useDistributionCenter();
  const [distributionCenter, setDistributionCenter] =
    useState<ILoadDistributionCenter>({} as ILoadDistributionCenter);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      setDistributionCenter({} as ILoadDistributionCenter);
      setLoading(true);
    }
  }, [props.open]);

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      if (props.distributionCenterId) {
        try {
          const response = await loadDistributionCenterById(
            props.distributionCenterId
          );

          if (!response) return;

          setDistributionCenter(response);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadDistributionCenter();
  }, [loadDistributionCenterById, props.distributionCenterId]);

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Informações</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 32 }}>
          Centro de distribuição cadastrado:
        </DialogContentText>
        {loading ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Código"
                value={distributionCenter.id ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Nome"
                value={distributionCenter.name ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="CEP"
                value={distributionCenter.address.postalcode ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Rua"
                value={distributionCenter.address.street ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Bairro"
                value={distributionCenter.address.neighborhood ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Número"
                value={distributionCenter.address.number ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Cidade"
                value={distributionCenter.address.city ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Estado"
                value={distributionCenter.address.state ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Complemento"
                value={distributionCenter.address.complement ?? ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
