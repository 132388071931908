import React, { useState, useCallback, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from '@material-ui/core';

import { Loading } from '../../../../../components/Loading';
import { useAuth } from '../../../../../hooks/auth';
import { usePlanning } from '../../../hooks/planning';
import { useOrder } from '../../../hooks/order';
import { OrderRow } from '../Row';
import {
  ICompanyParams,
  useCompanyParams,
} from '../../../../company-params/hooks/company-params';
import { whichClientCompanyIsTheDocument } from '../../../../../utils/customerCompanies';

interface IProps {
  date: string;
  distributionCenterId?: number;
}

export const OrderTable: React.FC<IProps> = ({
  date,
  distributionCenterId = '',
}) => {
  const {
    data: { user },
  } = useAuth();
  const { getCompanyParams } = useCompanyParams();
  const { orderList, setOrderList, loadOrderList, filter } = useOrder();
  const {
    handleSelectOrder,
    handleSelectAllOrders,
    isTableChecked,
    isTableIndeterminate,
    isOrderSelected,
  } = usePlanning();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyParams, setCompanyParams] = useState<ICompanyParams>();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orderList.total - page * rowsPerPage);

  useEffect(() => {
    async function loadCompanyParams(): Promise<void> {
      try {
        const response = await getCompanyParams();

        if (!response) return;

        setCompanyParams(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadCompanyParams();
  }, [user.companyDocument, getCompanyParams]);

  const availableOrders = orderList.orders
    .filter((order) =>
      ['new', 'undelivered', 'occurrence'].includes(order.statusCode)
    )
    .map((order) => order);

  useEffect(() => {
    async function loadOrders() {
      setLoading(true);

      const {
        number,
        customerName,
        region,
        shippingStatus,
        statusCode,
        shippingOption,
        operationType,
        product,
        isOccurrence,
        npsType,
      } = filter;

      const query =
        `${number && `&orderNumber=${number}`}` +
        `${customerName && `&customerName=${customerName}`}` +
        `${region && `&region=${region}`}` +
        `${statusCode && `&statusCode=${statusCode}`}` +
        `${shippingStatus && `&shippingStatus=${shippingStatus}`}` +
        `${shippingOption && `&shippingOption=${shippingOption}`}` +
        `${operationType && `&operationType=${operationType}`}` +
        `${product && `&product=${product}`}` +
        `${npsType && `&npsType=${npsType}`}` +
        `${
          distributionCenterId &&
          `&distributionCenterId=${distributionCenterId}`
        }`;

      try {
        const splitdate = date.split('/');

        const DAY = splitdate[0];
        const MONTH = splitdate[1];
        const YEAR = splitdate[2];
        const dateRefactored = `${YEAR}-${MONTH}-${DAY}`;

        const response = await loadOrderList(
          user.companyDocument,
          dateRefactored,
          page,
          rowsPerPage,
          query
        );

        if (!response) return;

        setOrderList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    distributionCenterId,
    loadOrderList,
    page,
    rowsPerPage,
    setOrderList,
    user.companyDocument,
  ]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function companyMustShowMarketplace(companyDocument: number): boolean {
    return whichClientCompanyIsTheDocument(companyDocument+'') === 'ativa';
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="order table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={isTableIndeterminate(availableOrders)}
                  checked={isTableChecked(availableOrders)}
                  onChange={(e, checked) =>
                    handleSelectAllOrders(checked, availableOrders)
                  }
                  inputProps={{ 'aria-label': 'select all orders' }}
                />
              </TableCell>
              <TableCell>Pedido</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>
                {companyMustShowMarketplace(user.companyDocument)
                  ? 'Origem'
                  : 'Zona'}
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Status Envio</TableCell>
              <TableCell>Planejado</TableCell>
              <TableCell align="right">Qtde. Itens</TableCell>
              {/* {user.type === 'ADMIN' && (
                <TableCell align="right">Valor</TableCell>
              )} */}
              {companyParams && companyParams.sendNps ? (
                <TableCell>NPS</TableCell>
              ) : (
                user.type === 'ADMIN' && (
                  <TableCell align="right">Valor</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loading
                columns={user.type === 'ADMIN' ? 11 : 10}
                rows={rowsPerPage}
              />
            ) : (
              orderList.orders.map((order) => {
                const isItemSelected = isOrderSelected(order);

                return (
                  <OrderRow
                    shouldDisplayMarketplace={companyMustShowMarketplace(
                      user.companyDocument
                    )}
                    key={order.id}
                    hover
                    order={order}
                    onCheck={() => handleSelectOrder(order)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    tabIndex={-1}
                    sendNps={companyParams?.sendNps || false}
                  />
                );
              })
            )}
            {!loading && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={11} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={orderList.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
