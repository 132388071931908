import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
  },
  button: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
}));
