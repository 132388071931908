import { format, parseISO } from 'date-fns';
import { createContext, FC, useCallback, useContext } from 'react';
import api from '../../../services/api';

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export interface Dock {
  id: number;
  companyDocument: string;
  name: string;
  typeOperation: string;
  startTime: string;
  endTime: string;
  color: string;
}

export enum ScheduleStatusEnum {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  NOT_ACCEPT = 'not-accept',
  CHANGED = 'changed',
  START_PROCESS = 'start-process',
  COMPLETED = 'completed',
}

export const SCHEDULE_STATUS = {
  [ScheduleStatusEnum.PENDING]: {
    label: 'Pendente',
    color: '#808080',
    contrastColor: '#fff',
  },
  [ScheduleStatusEnum.ACCEPTED]: {
    label: 'Aceito',
    color: undefined,
    contrastColor: '#fff',
  },
  [ScheduleStatusEnum.NOT_ACCEPT]: {
    label: 'Recusado',
    color: '#DD4124',
    contrastColor: '#fff',
  },
  [ScheduleStatusEnum.CHANGED]: {
    label: 'Alterado',
    color: '#EFC050',
    contrastColor: '#333',
  },
  [ScheduleStatusEnum.START_PROCESS]: {
    label: 'Iniciado',
    color: '#278701',
    contrastColor: '#fff',
  },
  [ScheduleStatusEnum.COMPLETED]: {
    label: 'Finalizado',
    color: '#000',
    contrastColor: '#fff',
  },
};

export interface ScheduleEvent {
  id: number;
  companyDocument: string;
  createdBy: string;
  data: {
    orderId?: number;
    orderNumber?: string;
    userId?: number;
    userName?: string;
  } | null;
  dock: Dock | null;
  title: string;
  description: string | null;
  dockId: number;
  dateEvent: string;
  startTime: string;
  endTime: string;
  status: ScheduleStatusEnum;
  userNameSuggestion?: string | null;
  dateEvenSuggestion?: string | null;
  startTimeSuggestion?: string | null;
  endTimeSuggestion?: string | null;
}

export type ScheduleEventData = Omit<
  ScheduleEvent,
  'id' | 'startTime' | 'endTime'
> & {
  startTime: Date;
  endTime: Date;
};

export type ScheduleComponentData = Omit<
  ScheduleEvent,
  'startTime' | 'endTime' | 'dockId'
> & {
  EventId: string;
  dockId?: number;
  startTime?: Date;
  endTime?: Date;
};

type ScheduleEventParams = {
  orderId: number;
};

interface IScheduleContext {
  getDocks(): Promise<Dock[] | undefined>;
  getScheduleEvents(
    params?: ScheduleEventParams
  ): Promise<ScheduleComponentData[] | undefined>;
  createScheduleEvent(data: ScheduleEventData): Promise<ScheduleComponentData>;
  editScheduleEvent(data: ScheduleComponentData): Promise<void>;
  deleteScheduleEvent(scheduleEventId: number): Promise<void>;
}

const ScheduleContext = createContext<IScheduleContext>({} as IScheduleContext);

const ScheduleProvider: FC = ({ children }) => {
  const getDocks = useCallback(async () => {
    const response = await api.get<Dock[]>('/dock');

    if (response) {
      return response.data;
    }

    return undefined;
  }, []);

  const getScheduleEvents = useCallback(
    async (params?: ScheduleEventParams) => {
      const response = await api.get<ScheduleEvent[]>('/schedule-event', {
        params,
      });

      if (response) {
        const events: ScheduleComponentData[] = response.data.map(
          (event, index) => {
            const startTime = parseISO(`${event.dateEvent}T${event.startTime}`);
            const endTime = parseISO(`${event.dateEvent}T${event.endTime}`);

            return {
              ...event,
              startTime,
              endTime,
              EventId: (index + 1).toString(),
            };
          }
        );

        return events;
      }

      return undefined;
    },
    []
  );

  const createScheduleEvent = useCallback(async (data: ScheduleEventData) => {
    let { startTime, endTime, dateEvent, ...rest } = data;

    dateEvent = format(new Date(startTime), 'yyyy-MM-dd');
    const startTimeFormatted = format(new Date(startTime), 'HH:mm');
    const endTimeFormatted = format(new Date(endTime), 'HH:mm');

    const response = await api.post<ScheduleEvent>('/schedule-event', {
      ...rest,
      dateEvent,
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
      data: rest.data ?? null,
      description: rest.description ?? null,
    });

    const createdScheduleEvent: ScheduleComponentData = {
      ...response.data,
      startTime: parseISO(
        `${response.data.dateEvent}T${response.data.startTime}`
      ),
      endTime: parseISO(`${response.data.dateEvent}T${response.data.endTime}`),
      dockId: response.data.dock!.id,
      EventId: '',
    };

    return createdScheduleEvent;
  }, []);

  const editScheduleEvent = useCallback(
    async (
      data: WithRequiredProperty<
        ScheduleComponentData,
        'startTime' | 'endTime' | 'dockId'
      >
    ) => {
      let { EventId: _EventId, startTime, endTime, ...rest } = data;

      const startTimeFormatted = format(new Date(startTime), 'HH:mm');
      const endTimeFormatted = format(new Date(endTime), 'HH:mm');

      await api.patch(`/schedule-event/${data.id}`, {
        ...rest,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
      });
    },
    []
  );

  const deleteScheduleEvent = useCallback(async (scheduleEventId: number) => {
    await api.delete(`/schedule-event/${scheduleEventId}`);
  }, []);

  return (
    <ScheduleContext.Provider
      value={{
        getDocks,
        getScheduleEvents,
        createScheduleEvent,
        editScheduleEvent,
        deleteScheduleEvent,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

function useSchedule() {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error('useSchedule must be used within a ScheduleProvider');
  }

  return context;
}

export { ScheduleProvider, useSchedule };
