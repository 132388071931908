import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
  Paper,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IDistributionCenterLoad, useOrder } from '../../../hooks/order';
import { DeliveredTable } from '../../Delivered/Table';
import { OccurrenceTable } from '../../Occurrence/Table';
import { OrderTable } from '../Table';

import { useStyles } from './styles';

interface IProps {
  statusCode?: string;
  date: string;
}

export const DistributionCenter: React.FC<IProps> = ({ statusCode, date }) => {
  const { loadDistributionCenter, filter } = useOrder();
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [distributionCenters, setDistributionCenters] =
    useState<IDistributionCenterLoad>({
      result: [],
    });
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    async function loadDistributionCenters(): Promise<void> {
      const { number, customerName, region, shippingStatus, shippingOption } =
        filter;

      const splitdate = date.split('/');

      const DAY = splitdate[0];
      const MONTH = splitdate[1];
      const YEAR = splitdate[2];
      const dateRefactored = `${YEAR}-${MONTH}-${DAY}`;

      const query =
        `?orderNumber${number && `=${number}`}` +
        `${customerName && `&customerName=${customerName}`}` +
        `${region && `&region=${region}`}` +
        `${
          statusCode
            ? `&statusCode=${statusCode}`
            : `&statusCode=${filter.statusCode}`
        }` +
        `${shippingStatus && `&shippingStatus=${shippingStatus}`}` +
        `${shippingOption && `&shippingOption=${shippingOption}`}` +
        `&initialDate=${dateRefactored}` +
        `&finalDate=${dateRefactored}`;

      try {
        const response = await loadDistributionCenter(query);

        if (!response) return;

        setDistributionCenters(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDistributionCenters();
  }, [date, filter, loadDistributionCenter, statusCode]);

  useEffect(() => {
    if (distributionCenters.result.length === 1) setExpanded('panel0');
  }, [distributionCenters.result]);

  const handleChange = useCallback(
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const Table = useCallback(
    (day: string, id: number) => {
      if (statusCode === 'delivered') return <DeliveredTable date={day} />;
      else if (statusCode === 'occurrence')
        return <OccurrenceTable date={day} />;
      else return <OrderTable date={day} distributionCenterId={id} />;
    },
    [statusCode]
  );

  return (
    <Paper className={classes.root}>
      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress
            style={{
              color: clsx(
                !statusCode && theme.palette.info.main,
                statusCode === 'delivered' && theme.palette.success.main,
                statusCode === 'occurrence' && theme.palette.error.main
              ),
            }}
          />
        </Typography>
      ) : (
        <>
          {distributionCenters.result.length > 0 ? (
            distributionCenters.result.map((dc, index) => (
              <Accordion
                key={`panel${String(index)}`}
                expanded={expanded === `panel${String(index)}`}
                onChange={handleChange(`panel${String(index)}`)}
              >
                <AccordionSummary
                  id={`panel${String(index)}-header`}
                  aria-controls={`panel${String(index)}-content`}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {`${dc.name ?? 'Sem centro'} - Pedidos: ${dc.quantity}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {expanded === `panel${String(index)}` &&
                    Table(date, dc.distributionCenterId ?? '')}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box pb={4} pt={4}>
              <Typography variant="body1" component="div" align="center">
                Nenhuma informação encontrada.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};
