import React from 'react';

interface T {
  id: number;
  [key: string]: any;
}

export const selectItem = (
  item: T,
  selectedItems: T[],
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>
): void => {
  const selectedIndex = selectedItems.findIndex(
    (sItem) => sItem.id === item.id
  );
  let newItemsSelected: T[] = [];

  if (selectedIndex === -1) {
    newItemsSelected = newItemsSelected.concat(selectedItems, item);
  } else if (selectedIndex === 0) {
    newItemsSelected = newItemsSelected.concat(selectedItems.slice(1));
  } else if (selectedIndex === selectedItems.length - 1) {
    newItemsSelected = newItemsSelected.concat(selectedItems.slice(0, -1));
  } else if (selectedIndex > 0) {
    newItemsSelected = newItemsSelected.concat(
      selectedItems.slice(0, selectedIndex),
      selectedItems.slice(selectedIndex + 1)
    );
  }

  setSelectedItems(newItemsSelected);
};

export const selectAllItems = (
  checked: boolean,
  availableItems: T[],
  selectedItems: T[],
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>
): void => {
  if (checked) {
    const newSelecteds = availableItems.filter(
      (select) => !selectedItems.some((batch) => select.id === batch.id)
    );

    return setSelectedItems((prevState) => [...prevState, ...newSelecteds]);
  }

  const result = selectedItems.filter(
    (select) => !availableItems.some((order) => select.id === order.id)
  );

  return setSelectedItems(result);
};

export const isItemSelected = (batch: T, selectedItems: T[]): boolean =>
  selectedItems
    .map((e) => {
      return e.id;
    })
    .indexOf(batch.id) !== -1;

export const isCheckboxChecked = (
  availableItems: T[],
  selectedItems: T[]
): boolean => {
  const result = availableItems.filter((select) =>
    selectedItems.some((batch) => select.id === batch.id)
  );

  return availableItems.length > 0 && result.length === availableItems.length;
};

export const isCheckboxIndeterminate = (
  availableItems: T[],
  selectedItems: T[]
): boolean => {
  const result = availableItems.filter((select) =>
    selectedItems.some((batch) => select.id === batch.id)
  );

  return result.length > 0 && result.length < availableItems.length;
};
