import { useState, useCallback } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalShippingRounded from '@material-ui/icons/LocalShippingRounded';

import { VehicleProvider } from '../../../../vehicle/hooks/vehicle';
import { IRouteLight, useRoute } from '../../../hooks/route';
import { useActivity } from '../../../hooks/activity';
import { ChangeDriver } from '../ChangeDriver';
import { MoveActivity } from '../MoveActivity';
import { FinishRoute } from '../FinishRoute';
import { CancelRoute } from '../CancelRoute';
import { DeleteRoute } from '../DeleteRoute';

import { Container } from './styles';

import { useAuth } from '../../../../../hooks/auth';
import { PERMISSIONS } from '../../../../../constants';
import { ReleaseRoute } from '../releaseRoute';

interface IProps {
  route: IRouteLight;
}

export const RowActionButtons = ({ route }: IProps) => {
  const {
    data: { permissions, user:{type: userType} },
  } = useAuth();
  const { deleteRoute, setRouteList, routeList, hasToConfirm } = useRoute();
  const { selectedActivity } = useActivity();

  const [openChangeDriver, setOpenChangeDriver] = useState(false);
  const [openMoveActivity, setOpenMoveActivity] = useState(false);
  const [openFinishRoute, setOpenFinishRoute] = useState(false);
  const [openCancelRoute, setOpenCancelRoute] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openReleaseRoute, setOpenReleaseRoute] = useState(false);
  const [routeId, setRouteId] = useState<number | null>(null);
  const [routeName, setRouteName] = useState<string | null>(null);

  const handleToggleChangeDriver = useCallback(() => {
    setOpenChangeDriver((prevState) => !prevState);
  }, []);

  const handleToggleMoveActivity = useCallback(() => {
    setOpenMoveActivity((prevState) => !prevState);
  }, []);

  const handleToggleFinishRoute = useCallback(() => {
    setOpenFinishRoute((prevState) => !prevState);
  }, []);

  const handleToggleReleaseRoute = useCallback(() => {
    setOpenReleaseRoute((prevState) => !prevState);
  }, []);

  const handleToggleCancelRoute = useCallback(() => {
    setOpenCancelRoute((prevState) => !prevState);
  }, []);

  const handleOpenDialogDelete = useCallback((id: number, name: string) => {
    setOpenDialogDelete(true);
    setRouteId(id);
    setRouteName(name);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setRouteId(null);
    setRouteName(null);
  }, []);

  const handleDeleteRoute = useCallback(async () => {
    if (routeId) {
      await deleteRoute(routeId);
      setRouteId(null);
      setRouteName(null);
      setOpenDialogDelete(false);
      const newRoutes = routeList.routes;
      const index = newRoutes.findIndex((route) => route.id === routeId);
      newRoutes.splice(index, 1);
      setRouteList((prevState) => ({
        ...prevState,
        routes: newRoutes,
      }));
    }
  }, [deleteRoute, routeId, routeList, setRouteList]);

  const isNotDisabledRouteChangeDriver = useCallback(() => {
    if (
      (permissions.includes(PERMISSIONS.ROUTE_CHANGE_DRIVER) &&
        route.status === 'pending') ||
      route.status === 'on-route'
    )
      return false;

    return true;
  }, [permissions, route.status]);

  const isNotDisabledRouteFinish = useCallback(() => {
    if (
      (permissions.includes(PERMISSIONS.ROUTE_FINISH) &&
        route.status === 'pending') ||
      route.status === 'on-route'
    )
      return false;

    return true;
  }, [permissions, route.status]);

  const isNotDisabledRouteCancel = useCallback(() => {
    if (
      (permissions.includes(PERMISSIONS.ROUTE_CANCEL) &&
        route.status === 'pending') ||
      route.status === 'on-route'
    )
      return false;

    return true;
  }, [permissions, route.status]);

  const isNotDisabledMoveOrders = useCallback(() => {
    if (
      permissions.includes(PERMISSIONS.ROUTE_MOVE_ORDERS) &&
      selectedActivity.length > 0 &&
      (route.status === 'pending' || route.status === 'on-route')
    ) {
      return false;
    }

    return true;
  }, [permissions, route.status, selectedActivity.length]);

  return (
    <>
      <VehicleProvider>
        <ChangeDriver
          open={openChangeDriver}
          onClose={handleToggleChangeDriver}
          routeId={route.id}
          routeName={route.routerName}
          driver={route.driver}
        />
      </VehicleProvider>

      <MoveActivity
        open={openMoveActivity}
        onClose={handleToggleMoveActivity}
        route={route}
      />

      <FinishRoute
        open={openFinishRoute}
        onClose={handleToggleFinishRoute}
        routeId={route.id}
        routeName={route.routerName}
      />

      <CancelRoute
        open={openCancelRoute}
        onClose={handleToggleCancelRoute}
        routeId={route.id}
        routeName={route.routerName}
      />

      <DeleteRoute
        open={openDialogDelete}
        title={`Excluir ${routeName}`}
        description="Deseja realmente excluir esta rota?"
        onSubmit={handleDeleteRoute}
        onClose={handleCloseDialogDelete}
      />

      <ReleaseRoute
      open={openReleaseRoute}
      onClose={handleToggleReleaseRoute}
      routeId={route.id}
      routeName={route.routerName}
      />

      <Container>
        <Tooltip title="Trocar Motorista da Rota" placement="top">
          <span>
            <IconButton
              color="inherit"
              aria-label="Trocar Motorista da Rota"
              onClick={handleToggleChangeDriver}
              disabled={isNotDisabledRouteChangeDriver()}
            >
              <SwapHorizontalCircleIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Mover Pedidos" placement="top">
          <span>
            <IconButton
              color="inherit"
              aria-label="Mover Pedidos"
              onClick={handleToggleMoveActivity}
              disabled={isNotDisabledMoveOrders()}
            >
              <OpenWithIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Finalizar Rota" placement="top">
          <span>
            <IconButton
              color="inherit"
              aria-label="Finalizar Rota"
              onClick={handleToggleFinishRoute}
              disabled={isNotDisabledRouteFinish()}
            >
              <CheckCircleIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Cancelar Rota" placement="top">
          <span>
            <IconButton
              color="inherit"
              aria-label="Cancelar Rota"
              onClick={handleToggleCancelRoute}
              disabled={isNotDisabledRouteCancel()}
            >
              <BlockIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Excluir Rota" placement="top">
          <span>
            <IconButton
              color="inherit"
              aria-label="Excluir Rota"
              onClick={() => handleOpenDialogDelete(route.id, route.routerName)}
              disabled={route.activities?.some(
                (activity) => activity.status === 'delivered'
              )}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Liberar Rota" placement="top">
          <span>
            <IconButton
              color="inherit"
              aria-label="Liberar Rota"
              onClick={() => handleToggleReleaseRoute()}
              disabled={!(route.status === 'pending' && userType === 'ADMIN' && hasToConfirm)}
            >
              <LocalShippingRounded />
            </IconButton>
          </span>
        </Tooltip>
      </Container>
    </>
  );
};
