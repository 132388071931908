import { Badge, Fab, Zoom } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';

import agcoLogo from '../../../assets/agco-logo.png';
import { useAgco } from '../hooks/agco';
// import { useAuth } from '../../../hooks/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
      backgroundColor: yellow[600],
      '&:hover': {
        backgroundColor: yellow[700],
      },
    },
  })
);

export interface IFabButtonProps {
  isEnabled: boolean;
}

export const FabButton = ({ isEnabled }: IFabButtonProps) => {
  // const { data: { permissions }, } = useAuth();
  const classes = useStyles();
  const { selected, handleToggleAgcoModalForm } = useAgco();
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <>
      <Zoom
        in={isEnabled}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${isEnabled ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          aria-label="create route"
          className={classes.fab}
          color="inherit"
          onClick={handleToggleAgcoModalForm}
          // disabled={!permissions.includes(PERMISSIONS.ROUTE_CREATE)}
        >
          <div style={{ position: 'relative' }}>
            <Badge badgeContent={selected.length} color="secondary">
              <img
                src={agcoLogo}
                alt=""
                width="60%"
                height="auto"
                style={{ margin: 'auto' }}
              />
            </Badge>
          </div>
        </Fab>
      </Zoom>
    </>
  );
};
