import { ITrip } from './';

interface IOrderTipProps {
  trip: ITrip;
  activityIndex: number;
}

export const OrderTip = ({ trip, activityIndex }: IOrderTipProps) => {
  return (
    <>
      <p>{`Pedido: ${trip.activities[activityIndex].number}`}</p>
      <p>{`Cliente: ${trip.activities[activityIndex].customer.name}`}</p>
      <p>
        {`Endereço: ${trip.activities[activityIndex].customer.address.street}, ${trip.activities[activityIndex].customer.address.number} - ${trip.activities[activityIndex].customer.address.city}, ${trip.activities[activityIndex].customer.address.state}`}
      </p>
      <p>{`Motorista: ${trip.route.driver.driverName}`}</p>
    </>
  );
};
