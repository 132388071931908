import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { IOrderRfidReport } from '../pages/OrderRFID';

const docDefinition = (
  img: string,
  data: IOrderRfidReport[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Pedidos RFID',
    author: '4Innovation',
    creationDate: new Date(),
  },
  pageOrientation: 'landscape',
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Pedidos RFID',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    data.map((order) => {
      const itemsData = order.items.map((item) => {
        return [
          {
            text: item.description,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: item.code,
            fontSize: 8,
            alignment: 'center',
          },
          {
            text: item.codeTranslate,
            fontSize: 8,
            alignment: 'center',
          },
        ];
      });

      return [
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: `Pedido: ${order.number}`,
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, true, true, false],
                },
              ],
            ],
          },
        },

        {
          table: {
            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              '*',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'Data do Pedido',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.orderDate,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Data Previsão de Entrega',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.deliveryPrevisionDate,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Cliente',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.customer.name,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Data Hora lib. Antena',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.datePortalRelease,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Data de Conclusão',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.dateMarkAsDelivered,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Status',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.statusCode,
                  fontSize: 8,
                  alignment: 'center',
                },
              ],
            ],
          },
        },

        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: 'Dados de Entrega',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                  border: [true, true, true, false],
                },
              ],
            ],
          },
        },

        {
          table: {
            widths: [
              'auto',
              'auto',
              'auto',
              '*',
              'auto',
              '*',
              'auto',
              '*',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'CEP',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.shippingAddress.postalcode,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Rua',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.shippingAddress.street,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Bairro',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.shippingAddress.neighborhood,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Cidade',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.shippingAddress.city,
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: 'Estado',
                  fillColor: '#CCC',
                  fontSize: 8,
                  alignment: 'center',
                },
                {
                  text: order.shippingAddress.state,
                  fontSize: 8,
                  alignment: 'center',
                },
              ],
            ],
          },
        },

        !!itemsData && itemsData.length > 0
          ? [
              {
                margin: [0, 10, 0, 0],
                table: {
                  widths: ['*'],
                  body: [
                    [
                      {
                        text: 'Itens do Pedido',
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                      },
                    ],
                  ],
                },
              },

              {
                table: {
                  widths: ['*', 'auto', 'auto'],
                  body: [
                    [
                      {
                        text: 'Descrição',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Número TAG',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                      {
                        text: 'Número TAG Traduzido',
                        fillColor: '#f1f0f3',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, false, true, true],
                      },
                    ],
                    ...itemsData,
                  ],
                },
              },
            ]
          : [],
      ];
    }),
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

export async function generateOrderRfidPDF(data: IOrderRfidReport[]) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
