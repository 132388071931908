import Dialog from '@material-ui/core/Dialog';
import { useState, useEffect } from 'react';
import { ICustomEventProps, useModal } from '../../hooks/modal';

interface IModalRoot extends ICustomEventProps {
  close(): void;
}

export default function ModalRoot() {
  const { on } = useModal();
  const [modal, setModal] = useState<IModalRoot | null>(null);
  const [hasModal, setHasModal] = useState(false);
  const [maxWidth, setMaxWidth] = useState<
    false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined
  >('sm');

  useEffect(() => {
    on('open', ({ component, props }) => {
      setModal({
        component,
        props,
        close: () => {
          setHasModal(false);
        },
      });

      if (props.maxWidth !== undefined) {
        setMaxWidth(props.maxWidth);
      }
      setHasModal(true);
    });
  }, [on]);

  useEffect(() => {
    if (!hasModal) {
      setTimeout(() => {
        setModal(null);
      }, 300);
    }
  }, [hasModal]);

  const ModalComponent = modal?.component ? modal.component : null;

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={hasModal}
      onClose={() => setHasModal(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      {ModalComponent && (
        <ModalComponent {...modal!.props} close={modal!.close} />
      )}
    </Dialog>
  );
}
