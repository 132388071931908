import { useCallback, useEffect, useMemo } from 'react';
import {
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

import { TypeEnum, useSignUp } from '../../hooks/signUp';

import { CompanyForm } from '../CompanyForm';
import { UserForm } from '../UserForm';
//import { ConfirmPhone } from '../ConfirmPhone';

import { Container } from './styles';

export const ProfileForm = () => {
  const {
    profileType,
    formActive,
    documentType,
    setFormActive,
    setDocumentType,
  } = useSignUp();

  const FIRST_FORM = 0;
  const TYPE_DOCUMENT_ONLY = [
    TypeEnum.SHIPPING_COMPANY,
    TypeEnum.SHIPPER,
    TypeEnum.MOTOBOY,
  ];

  useEffect(() => {
    setFormActive(0);
    if (profileType === TypeEnum.MOTOBOY) {
      setDocumentType('cpf');
    } else {
      setDocumentType('cnpj');
    }
  }, [profileType, setDocumentType, setFormActive]);

  const getFormContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return documentType === 'cnpj' ? <CompanyForm /> : <UserForm />;
        case 1:
          return <UserForm />;
        //case 2:
        //return <ConfirmPhone />;
        default:
          throw new Error('Desconhecido');
      }
    },
    [documentType]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;

      setDocumentType(value);
    },
    [setDocumentType]
  );

  const nameProfile = useMemo(() => {
    if (profileType === TypeEnum.AUTONOMOUS) {
      return 'Autônomo';
    } else if (profileType === TypeEnum.MOTOBOY) {
      return 'Motoboy';
    } else if (profileType === TypeEnum.SHIPPER) {
      return 'Embarcador';
    } else if (profileType === TypeEnum.SHIPPING_COMPANY) {
      return 'Transportadora';
    } else return 'Perfil de Demonstração';
  }, [profileType]);

  return (
    <Container>
      {formActive === FIRST_FORM && (
        <>
          <Typography
            variant="h4"
            align="left"
            style={{ textTransform: 'uppercase' }}
          >
            <strong>Olá {nameProfile}</strong>
          </Typography>

          <Typography variant="body1" align="left">
            <strong>Bem vindo à 4INNOVATION</strong>
          </Typography>

          {!TYPE_DOCUMENT_ONLY.includes(profileType) && (
            <Grid container alignItems="center" justifyContent="center">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="documentType"
                  name="documentType"
                  value={documentType}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="cnpj"
                    control={<Radio color="primary" />}
                    label="Pessoa Jurídica"
                  />

                  <FormControlLabel
                    value="cpf"
                    control={<Radio color="primary" />}
                    label="Pessoa Física"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </>
      )}

      {getFormContent(formActive)}
    </Container>
  );
};
