import { Main } from '../../../components/Main';
import { companyLogo } from '../../../utils/logoUtils';

export const Home = () => {
  return (
    <Main>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 200px)',
        }}
      >
        <img
          style={{ maxWidth: 600, width: '100%', height: 'auto' }}
          src={companyLogo().black}
          alt="4Innovation Logo"
        />
      </div>
    </Main>
  );
};
