import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { CellActions } from '../../../../components/Table/CellActions';
import { TableCell } from '../../../../components/Table/TableCell';
import { Loading } from '../../../../components/Loading';
import { DistributionCenterInfo } from '../../components/DistributionCenterInfo';
import { useAuth } from '../../../../hooks/auth';
import {
  useDistributionCenter,
  ILoadDistributionCenter,
} from '../../hooks/distribution-center';

import { useStyles } from './styles';

export const DistributionCenterList = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadDistributionCenterList, deleteDistributionCenter } =
    useDistributionCenter();
  const classes = useStyles();
  const [distributionCenters, setDistributionCenters] = useState<
    ILoadDistributionCenter[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [distributionCenterId, setDistributionCenterId] = useState<
    number | null
  >(null);
  const [search, setSearch] = useState('');

  const distributionCenterFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return distributionCenters.filter(
      (distributionCenter) =>
        distributionCenter.id.toString().toLowerCase().includes(lowerSearch) ||
        distributionCenter.name.toLowerCase().includes(lowerSearch) ||
        distributionCenter.isPrincipal
          .toString()
          .toLowerCase()
          .includes(lowerSearch) ||
        distributionCenter.address.street.toLowerCase().includes(lowerSearch) ||
        distributionCenter.address.number
          .toString()
          .toLowerCase()
          .includes(lowerSearch) ||
        distributionCenter.address.neighborhood
          .toLowerCase()
          .includes(lowerSearch)
    );
  }, [search, distributionCenters]);

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      try {
        const response = await loadDistributionCenterList(user.companyDocument);

        setDistributionCenters(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDistributionCenter();
  }, [loadDistributionCenterList, user.companyDocument]);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setDistributionCenterId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setDistributionCenterId(null);
  }, []);

  const handleOpenDialogDelete = useCallback((id: number) => {
    setOpenDialogDelete(true);
    setDistributionCenterId(id);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setDistributionCenterId(null);
  }, []);

  const handleDeleteDistributionCenter = useCallback(async () => {
    if (distributionCenterId) {
      await deleteDistributionCenter(distributionCenterId);
      setDistributionCenters((prevState) =>
        prevState.filter((rest) => rest.id !== distributionCenterId)
      );
      setDistributionCenterId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteDistributionCenter, distributionCenterId]);

  return (
    <Main
      title="Centros de Distribuição"
      button="add"
      buttonName="Novo Centro"
      to={ROUTES.createDistributionCenter}
      hasPermission="DISTRIBUTION_CENTER_CREATE"
    >
      <Grid container spacing={1}>
        <DistributionCenterInfo
          open={openView}
          distributionCenterId={distributionCenterId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar centro de distribuição"
          description="Deseja realmente deletar este centro de distribuição?"
          onSubmit={handleDeleteDistributionCenter}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Principal</TableCell>
                  <TableCell>Endereço</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <Loading columns={5} />
                ) : (
                  distributionCenterFilter.map((dCenter) => (
                    <TableRow key={dCenter.id} hover>
                      <TableCell component="th" scope="row">
                        {dCenter.id}
                      </TableCell>
                      <TableCell component="th">{dCenter.name}</TableCell>
                      <TableCell component="th">
                        {dCenter.isPrincipal ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell>
                        {`${dCenter.address.street}, ${dCenter.address.number}, ${dCenter.address.neighborhood}`}
                      </TableCell>
                      <TableCell align="center">
                        <CellActions
                          id={dCenter.id}
                          sendTo="distributionCenterList"
                          handleOpen={() => handleOpenView(dCenter.id)}
                          handleDelete={() =>
                            handleOpenDialogDelete(dCenter.id)
                          }
                          onEditPermission="DISTRIBUTION_CENTER_EDIT"
                          onRemovePermission="DISTRIBUTION_CENTER_REMOVE"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Main>
  );
};
