import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import logoImg from '../../../assets/logo-black.png';

import { IRouteActivityReport } from '../../route/hooks/activity';
import { activityStatusLabel, maskPostalcode } from '../../../utils';
import { QuestionType } from '../../form/hooks/form';

function codeHexToText(code: string) {
  try {
    let hex = code.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  } catch (error) {
    return code;
  }
}

const latLongToFixed = (coordinate: string | null) =>
  coordinate &&
  coordinate.split('.')[0] + '.' + coordinate.split('.')[1].slice(0, 6);

const docDefinition = (
  img: string,
  data: IRouteActivityReport,
  geoFenceMaxDistance?: number
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Rotas',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      margin: [0, 0, 0, 20],
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Atividade',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },
    {
      text: `Pedido: ${data.number}`,
      margin: [0, 3, 3, 10],
      fontSize: 16,
      bold: true,
    },
    {
      columns: [
        [
          { text: `Cliente: `, margin: [0, 0, 0, 3], fontSize: 16, bold: true },
          { text: data.customer.name, margin: 3, bold: true },
          {
            text: `${data.customer.address.street}, ${
              data.customer.address.number
            } - ${data.customer.address.neighborhood}
        ${maskPostalcode(data.customer.address.postalcode)} - ${
              data.customer.address.city
            } - ${data.customer.address.state}
            (lat: ${latLongToFixed(
              data.customer.address.lat
            )}, long: ${latLongToFixed(data.customer.address.long)})`,
            alignment: 'left',
            margin: 3,
          },
        ],
        [
          {
            text: `Execução: `,
            margin: [0, 0, 0, 3],
            fontSize: 16,
            bold: true,
          },
          {
            text: `Geolocalização:
            (lat: ${latLongToFixed(data.latFinishing) || 'n/a'}, long: ${
              latLongToFixed(data.longFinishing) || 'n/a'
            }) `,
            margin: 3,
          },
          {
            text: `Distância da cerca eletrônica ${
              geoFenceMaxDistance ? `(max ${geoFenceMaxDistance}m)` : ''
            }:
            ${
              data?.order.deliveredDistance
                ? data.order.deliveredDistance > 1000
                  ? `${(data.order.deliveredDistance / 1000).toFixed(
                      2
                    )} quilômetros`
                  : `${data.order.deliveredDistance} metros`
                : 'Não informado'
            }`,
            margin: 3,
          },
          {
            text: `Status: ${activityStatusLabel(data.status)}`,
            margin: 3,
            bold: true,
          },
          data.status === 'occurrence'
            ? {
                text:
                  data.status === 'occurrence' ? `Razão: ${data.reason}` : '',
                margin: 3,
                bold: true,
              }
            : [],
          {
            layout: 'noBorders',
            table: {
              widths: ['auto', '*'],
              body: [
                [
                  { text: `Início: ${data.startHour || 'n/a'}`, margin: 3 },
                  { text: `Término: ${data.endHour || 'n/a'}`, margin: 3 },
                ],
              ],
            },
          },
          {
            text: `Data: ${data.prevArriveDate}`,
            margin: 3,
            bold: true,
          },
        ],
      ],
    },
    { text: `Produtos: `, margin: [0, 10, 0, 0], fontSize: 16, bold: true },
    {
      margin: 3,
      table: {
        widths: ['*', '*', 'auto'],
        body: [
          [
            { text: 'Código', fontSize: 12, fillColor: '#CCC', bold: true },
            { text: 'Descrição', fontSize: 12, fillColor: '#CCC', bold: true },
            {
              text: 'Sku',
              fontSize: 12,
              fillColor: '#CCC',
              bold: true,
              alignment: 'center',
            },
          ],
          ...data.customer.parcels.map(({ code, sku, description }) => [
            {
              text: code.split('2E').length === 3 ? codeHexToText(code) : code,
            },
            { text: description },
            { text: sku },
          ]),
        ],
      },
    },
    ...(data.filledForm ? (questionsTable(data.filledForm) as any) : []),
    {
      margin: [0, 10, 0, 0],
      columns: [
        [
          {
            text: `Responsável:\n${data.responsibleName || 'n/a'}\n${
              data.responseDocument
            }`,
            margin: 3,
            fontSize: 16,
            bold: true,
          },
        ],
        [
          {
            text: `Assinatura: ${data.responseSignature ? '' : 'Não Assinado'}`,
            margin: 3,
            fontSize: 16,
            bold: true,
          },
          data.responseSignature
            ? {
                image: data.responseSignature,
                width: 170,
                margin: [20, 0, 0, 0],
              }
            : [],
        ],
      ],
    },
    data.images.find((img) => !!img)
      ? {
          pageBreak: 'before',
          text: 'Anexos:',
          margin: [0, 0, 0, 5],
          fontSize: 16,
          bold: true,
        }
      : [],
    {
      alignment: 'center',
      columns: [
        data.images
          .filter((_, index) => index % 3 === 0)
          .map(({ base64, filename }) => ({
            image: `data:image/${filename.slice(-3) || 'jpg'};base64,${base64}`,
            width: 170,
            margin: [0, 2, 0, 0],
          })),
        data.images
          .filter((_, index) => index % 3 === 1)
          .map(({ base64, filename }) => ({
            image: `data:image/${filename.slice(-3) || 'jpg'};base64,${base64}`,
            width: 170,
            margin: [0, 2, 0, 0],
          })),
        data.images
          .filter((_, index) => index % 3 === 2)
          .map(({ base64, filename }) => ({
            image: `data:image/${filename.slice(-3) || 'jpg'};base64,${base64}`,
            width: 170,
            margin: [0, 2, 0, 0],
          })),
      ],
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
  defaultStyle: {
    fontSize: 12,
  },
});

function questionsTable(form: Required<IRouteActivityReport>['filledForm']) {
  return [
    {
      text: `Formulário: ${form.formConfig.title}`,
      margin: [0, 10, 0, 0],
      fontSize: 16,
      bold: true,
    },
    {
      margin: 3,
      table: {
        widths: [20, '*', '*'],
        body: [
          [
            {
              text: '',
              alignment: 'center',
              fillColor: '#CCC',
            },
            {
              text: 'Perguntas',
              alignment: 'center',
              fontSize: 12,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Respostas',
              alignment: 'center',
              fontSize: 12,
              bold: true,
              fillColor: '#CCC',
            },
          ],
          ...form.data.map((question, index) => {
            return [
              {
                text: (++index).toString().padStart(2, '0'),
                alignment: 'center',
                fontSize: 12,
                bold: true,
              },
              {
                text: question.subject,
                alignment: 'justify',
                fontSize: 12,
              },
              [QuestionType.SIGNATURE, QuestionType.IMAGE].includes(
                question.type
              ) && question.value
                ? {
                    image: 'data:image/gif;base64,' + question.value,
                    fit: [100, 100],
                  }
                : {
                    text:
                      question.type === QuestionType.CHECKBOX
                        ? question.valueCheckbox?.join(', ')
                        : question.value ?? '',
                    fontSize: 12,
                  },
            ];
          }),
        ],
      },
    },
  ];
}

export async function generateRouteActivityReportPDF(
  report: IRouteActivityReport,
  geoFenceMaxDistance?: number
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake
      .createPdf(docDefinition(base64, report, geoFenceMaxDistance))
      .open();
  }
}
