import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import { ActionMeta } from 'react-select';

import { ModalContent, Title } from '../';
import { AsyncSelect, IOption } from '../../../../../components/AsyncSelect';
import { useAuth } from '../../../../../hooks/auth';
import { getAddress } from '../../../../../services/viaCep';
import {
  maskCnpj,
  maskCpf,
  maskPostalcode,
  maskTelephone,
  unmask,
} from '../../../../../utils';
import { IManualOrder, useManualOrder } from '../../../hooks/manual-order';
import { ModalActions } from '../ModalActions';
import schema from './schema';

type ISender = Pick<IManualOrder, 'sender'>;

export const SenderForm = () => {
  const {
    data: { user },
  } = useAuth();
  const {
    order,
    nextOrBack,
    setOrder,
    loadSenderList,
    handleNext,
    handleBack,
  } = useManualOrder();
  const [data] = useState<ISender>(order);

  const formik = useFormik({
    initialValues: {
      sender: {
        ...data.sender,
        documentNumber:
          data.sender.documentNumber === ''
            ? user.type === 'FINAL_USER'
              ? user.cpf.toString()
              : ''
            : data.sender.documentNumber,
        addresses: {
          ...data.sender.addresses,
          complement: data.sender.addresses.complement
            ? data.sender.addresses.complement
            : '',
        },
      },
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setOrder((prevState) => ({ ...prevState, ...values }));
      nextOrBack === 1 ? handleNext() : handleBack();
    },
  });

  useEffect(() => {
    async function loadFinalUserData(): Promise<void> {
      try {
        const response = await loadSenderList(user.cpf.toString());

        if (response) {
          fillSenderFields(response[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (user.type === 'FINAL_USER') {
      loadFinalUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSenderList, user.cpf, user.type]);

  const handleChangePostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);

      const postalcodeLength = 8;
      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf, complemento } = address;

          if (logradouro) {
            formik.setFieldValue('sender.addresses.street', logradouro);
          }

          if (bairro) {
            formik.setFieldValue('sender.addresses.neighborhood', bairro);
          }

          if (localidade) {
            formik.setFieldValue('sender.addresses.city', localidade);
          }

          if (uf) {
            formik.setFieldValue('sender.addresses.state', uf);
          }

          if (complemento) {
            formik.setFieldValue('sender.addresses.complement', complemento);
          }
        }
      }
    },
    [formik]
  );

  const handleChangeWithMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (name === 'sender.documentNumber' && value.length > 11) {
        formik.setFieldValue(name, unmask(maskCnpj(value)));
      } else if (name === 'sender.documentNumber') {
        formik.setFieldValue(name, unmask(maskCpf(value)));
      }

      if (name === 'sender.cellphone') {
        formik.setFieldValue(name, unmask(maskTelephone(value)));
      }

      if (name === 'sender.addresses.postalcode') {
        formik.setFieldValue(name, unmask(maskPostalcode(value)));
      }
    },
    [formik]
  );

  const actionOnChange = (
    option: IOption | null,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (option) {
      const { value, otherProps } = option;

      if (otherProps) {
        if (actionMeta.action === 'select-option') {
          formik.setFieldValue('sender.documentNumber', value);
          formik.setFieldValue('sender.name', otherProps.name);
          formik.setFieldValue('sender.email', otherProps.email);
          formik.setFieldValue('sender.cellphone', otherProps.cellphone);
          formik.setFieldValue(
            'sender.addresses.postalcode',
            otherProps.addresses[0].postalcode
          );
          formik.setFieldValue(
            'sender.addresses.street',
            otherProps.addresses[0].street
          );
          formik.setFieldValue(
            'sender.addresses.number',
            otherProps.addresses[0].number
          );
          formik.setFieldValue(
            'sender.addresses.neighborhood',
            otherProps.addresses[0].neighborhood
          );
          formik.setFieldValue(
            'sender.addresses.city',
            otherProps.addresses[0].city
          );
          formik.setFieldValue(
            'sender.addresses.state',
            otherProps.addresses[0].state
          );
          formik.setFieldValue(
            'sender.addresses.complement',
            otherProps.addresses[0].complement || ''
          );
        }
      } else if (actionMeta.action === 'create-option') {
        formik.resetForm();
      }
    } else {
      formik.resetForm();
    }
  };

  function fillSenderFields(sender: any) {
    formik.setFieldValue('sender.name', sender.name);
    formik.setFieldValue('sender.email', sender.email);
    formik.setFieldValue('sender.cellphone', sender.cellphone);
    formik.setFieldValue(
      'sender.addresses.postalcode',
      sender.addresses[0].postalcode
    );
    formik.setFieldValue('sender.addresses.street', sender.addresses[0].street);
    formik.setFieldValue('sender.addresses.number', sender.addresses[0].number);
    formik.setFieldValue(
      'sender.addresses.neighborhood',
      sender.addresses[0].neighborhood
    );
    formik.setFieldValue('sender.addresses.city', sender.addresses[0].city);
    formik.setFieldValue('sender.addresses.state', sender.addresses[0].state);
    formik.setFieldValue(
      'sender.addresses.complement',
      sender.addresses[0].complement
    );
  }

  return (
    <>
      <ModalContent>
        <Title />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} style={{ zIndex: 2 }}>
            <AsyncSelect
              searchFunction={loadSenderList}
              name="sender.documentNumber"
              value={{
                label: formik.values.sender.documentNumber,
                value: formik.values.sender.documentNumber,
              }}
              actionOnChange={actionOnChange}
              setValue={formik.setFieldValue}
              error={formik.errors.sender?.documentNumber}
              isDisabled={user.type === 'FINAL_USER'}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Nome do Remetente"
              id="sender.name"
              name="sender.name"
              value={formik.values.sender.name}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.name &&
                Boolean(formik.errors.sender?.name)
              }
              helperText={
                formik.touched.sender?.name && formik.errors.sender?.name
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Email"
              id="sender.email"
              name="sender.email"
              value={formik.values.sender.email}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.email &&
                Boolean(formik.errors.sender?.email)
              }
              helperText={
                formik.touched.sender?.email && formik.errors.sender?.email
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Celular"
              id="sender.cellphone"
              name="sender.cellphone"
              value={maskTelephone(formik.values.sender.cellphone)}
              onChange={handleChangeWithMask}
              error={
                formik.touched.sender?.cellphone &&
                Boolean(formik.errors.sender?.cellphone)
              }
              helperText={
                formik.touched.sender?.cellphone &&
                formik.errors.sender?.cellphone
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="CEP"
              id="sender.addresses.postalcode"
              name="sender.addresses.postalcode"
              value={maskPostalcode(formik.values.sender.addresses.postalcode)}
              onChange={handleChangePostalcodeValue}
              error={
                formik.touched.sender?.addresses?.postalcode &&
                Boolean(formik.errors.sender?.addresses?.postalcode)
              }
              helperText={
                formik.touched.sender?.addresses?.postalcode &&
                formik.errors.sender?.addresses?.postalcode
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Rua"
              id="sender.addresses.street"
              name="sender.addresses.street"
              value={formik.values.sender.addresses.street}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.addresses?.street &&
                Boolean(formik.errors.sender?.addresses?.street)
              }
              helperText={
                formik.touched.sender?.addresses?.street &&
                formik.errors.sender?.addresses?.street
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Número"
              id="sender.addresses.number"
              name="sender.addresses.number"
              value={formik.values.sender.addresses.number}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.addresses?.number &&
                Boolean(formik.errors.sender?.addresses?.number)
              }
              helperText={
                formik.touched.sender?.addresses?.number &&
                formik.errors.sender?.addresses?.number
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Bairro"
              id="sender.addresses.neighborhood"
              name="sender.addresses.neighborhood"
              value={formik.values.sender.addresses.neighborhood}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.addresses?.neighborhood &&
                Boolean(formik.errors.sender?.addresses?.neighborhood)
              }
              helperText={
                formik.touched.sender?.addresses?.neighborhood &&
                formik.errors.sender?.addresses?.neighborhood
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Cidade"
              id="sender.addresses.city"
              name="sender.addresses.city"
              value={formik.values.sender.addresses.city}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.addresses?.city &&
                Boolean(formik.errors.sender?.addresses?.city)
              }
              helperText={
                formik.touched.sender?.addresses?.city &&
                formik.errors.sender?.addresses?.city
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              size="small"
              required
              fullWidth
              label="Estado"
              id="sender.addresses.state"
              name="sender.addresses.state"
              value={formik.values.sender.addresses.state}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.addresses?.state &&
                Boolean(formik.errors.sender?.addresses?.state)
              }
              helperText={
                formik.touched.sender?.addresses?.state &&
                formik.errors.sender?.addresses?.state
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label="Complemeto"
              id="sender.addresses.complement"
              name="sender.addresses.complement"
              value={formik.values.sender.addresses.complement}
              onChange={formik.handleChange}
              error={
                formik.touched.sender?.addresses?.complement &&
                Boolean(formik.errors.sender?.addresses?.complement)
              }
              helperText={
                formik.touched.sender?.addresses?.complement &&
                formik.errors.sender?.addresses?.complement
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>

      <ModalActions validate={formik.handleSubmit} />
    </>
  );
};
