import { useSnackbar } from 'notistack';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { IFullOrder, useOrder } from '../../hooks/order';
import { useCallback, useState } from 'react';

interface IProps {
  order: IFullOrder;
  type: 'nfe' | 'cte';
}

export const DownloadButton = ({ order, type }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { downloadNFeOrCTe } = useOrder();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    try {
      setIsDownloading(true);
      await downloadNFeOrCTe(type, order.id, order.number);
    } catch (error) {
      console.log(error);
      const message = 'Erro ao efetuar download do anexo ' + type.toUpperCase();
      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setIsDownloading(false);
    }
  }, [downloadNFeOrCTe, enqueueSnackbar, order.id, order.number, type]);

  return (
    <Tooltip
      title={`Download ${type === 'nfe' ? 'NF-e' : 'CT-e'}`}
      placement="top"
    >
      <span>
        <IconButton
          color="inherit"
          size="small"
          onClick={handleDownload}
          disabled={
            isDownloading || type === 'nfe'
              ? !order.hasDanfeNFe
              : !order.hasDanfeCTe
          }
        >
          {isDownloading ? (
            <CircularProgress size={15} />
          ) : (
            <GetAppIcon fontSize="small" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};
