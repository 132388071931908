import React from 'react';
import { Switch } from 'react-router-dom';

import { PERMISSIONS, ROUTES } from '../../../constants';
import PrivateRoute from '../../../routes/PrivateRoute';
import { DistributionCenterProvider } from '../../distribution-center/hooks/distribution-center';
import { ManualOrderProvider } from '../../order/hooks/manual-order';
import { ProductProvider } from '../../product/hooks/product';
import { RouteProvider } from '../../route/hooks/route';
import { StoragePositionProvider } from '../../storage-position/hooks/storage-position';
import { UserManagementProvider } from '../../user-management/hooks/user-management';
import { VehicleProvider } from '../../vehicle/hooks/vehicle';
import { DisposalProvider } from '../hooks/disposal';
import { FormProvider } from '../hooks/form';
import { InventoryProvider } from '../hooks/inventory';
import { OcorenProvider } from '../hooks/ocoren';
import { OrderReportProvider } from '../hooks/order';
import { OrderRFIDProvider } from '../hooks/orderRFID';
import { RfidProvider } from '../hooks/rfid';
import { DisposalReport } from '../pages/Disposal';
import { DistributionCenterReport } from '../pages/DistributionCenter';
import { DriverRouteReport } from '../pages/DriverRoute';
import { FormsReport } from '../pages/Forms';
import { InventoryReport } from '../pages/Inventory';
import { Ocoren } from '../pages/Ocoren';
import { OrderReport } from '../pages/Order';
import { OrderRFIDReport } from '../pages/OrderRFID';
import { ProductReport } from '../pages/Product';
import { RFIDReport } from '../pages/RFID';
import { RouteReport } from '../pages/Route';
import { StoragePositionReport } from '../pages/StoragePosition';
import { UserManagementReport } from '../pages/UserManagement';
import { VehicleReport } from '../pages/Vehicle';
import { DriverRouteSummaryReport } from '../pages/DriverRouteSummarized';

export const ReportRoute: React.FC = () => (
  <StoragePositionProvider>
    <ProductProvider>
      <VehicleProvider>
        <DistributionCenterProvider>
          <UserManagementProvider>
            <RouteProvider>
              <ManualOrderProvider>
                <OrderReportProvider>
                  <OrderRFIDProvider>
                    <RfidProvider>
                      <OcorenProvider>
                        <InventoryProvider>
                          <DisposalProvider>
                            <FormProvider>
                              <Switch>
                                <PrivateRoute
                                  path={ROUTES.reportInventory}
                                  exact
                                  permission={PERMISSIONS.REPORTS_INVENTORY}
                                  component={InventoryReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.ocoren}
                                  exact
                                  permission={
                                    PERMISSIONS.REPORTS_PROCEDA_OCOREN
                                  }
                                  component={Ocoren}
                                />
                                <PrivateRoute
                                  path={ROUTES.rfid}
                                  exact
                                  permission={
                                    PERMISSIONS.REPORTS_RFID_CONFERENCE
                                  }
                                  component={RFIDReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportOrder}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_ORDER}
                                  component={OrderReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportOrderRFID}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_RFID_ORDER}
                                  component={OrderRFIDReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportRoute}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_ROUTE}
                                  component={RouteReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportDriverRoute}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_ROUTE}
                                  component={DriverRouteReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportDriverRouteSummarized}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_ROUTE}
                                  component={DriverRouteSummaryReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportUserManagement}
                                  exact
                                  permission={PERMISSIONS.REPORTS_USER}
                                  component={UserManagementReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportDistributionCenter}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_DISTRIBUTION_CENTER}
                                  component={DistributionCenterReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportVehicle}
                                  exact
                                  permission={PERMISSIONS.REPORTS_VEHICLE}
                                  component={VehicleReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportProduct}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_PRODUCT}
                                  component={ProductReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportStoragePosition}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_STORE_POSITION}
                                  component={StoragePositionReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportDisposal}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_STORE_POSITION}
                                  component={DisposalReport}
                                />
                                <PrivateRoute
                                  path={ROUTES.reportForms}
                                  exact
                                  //permission={PERMISSIONS.REPORTS_STORE_POSITION}
                                  component={FormsReport}
                                />
                              </Switch>
                            </FormProvider>
                          </DisposalProvider>
                        </InventoryProvider>
                      </OcorenProvider>
                    </RfidProvider>
                  </OrderRFIDProvider>
                </OrderReportProvider>
              </ManualOrderProvider>
            </RouteProvider>
          </UserManagementProvider>
        </DistributionCenterProvider>
      </VehicleProvider>
    </ProductProvider>
  </StoragePositionProvider>
);
