import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { theme } from '../styles/global';
import React, { useEffect, useState } from 'react';
import { useAuth } from './auth';

export const AppThemeProvider: React.FC = ({ children }) => {
  const { data } = useAuth();

  const [themeCompanyDocument, setThemeCompanyDocument] = useState('');

  useEffect(() => {
    setThemeCompanyDocument(`${data?.user?.companyDocument}`);
  }, [data?.user]);

  return (
    <ThemeProvider
      theme={theme({
        setCompanyTheme: (value) => {
          setThemeCompanyDocument(value);
        },
        companyDocument: themeCompanyDocument,
      })}
    >
      {children}
    </ThemeProvider>
  );
};
