import { ITrip } from '../modules/route/components/Route/MainMap';

export type ViewMode = 'kilometers' | 'hours';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 3600;

function getInitialValueForSeconds(totalSeconds: number) {
  const TEN_THOUSAND_SECONDS = 10000;
  const ONE_HUNDRED_THOUSAND_SECONDS = 100000;

  if (totalSeconds < TEN_THOUSAND_SECONDS) {
    return 20 * MINUTE_IN_SECONDS;
  } else if (
    totalSeconds > TEN_THOUSAND_SECONDS &&
    totalSeconds < ONE_HUNDRED_THOUSAND_SECONDS
  ) {
    return 40 * MINUTE_IN_SECONDS;
  } else return 60 * MINUTE_IN_SECONDS;
}

function ruleOfThree(a: number, b: number, c: number): number {
  return +((a * b) / c).toFixed(2);
}

export function calcWidthPercent(
  number: number,
  greaterNumber: number
): string {
  const width = ruleOfThree(100, number, greaterNumber);

  return width + '%';
}

export function calcKilometers(
  greaterDistance: number,
  divider: number = 10,
  kmArray: string[] = []
): string[] {
  const initialValue = greaterDistance / divider;
  const lastPosition = +kmArray[kmArray.length - 1];

  if (lastPosition && lastPosition < greaterDistance) {
    kmArray.push((lastPosition + initialValue).toFixed(0));

    return calcKilometers(greaterDistance, divider, kmArray);
  } else if (kmArray.length === 0) {
    kmArray.push(initialValue.toFixed(0));

    return calcKilometers(greaterDistance, divider, kmArray);
  } else if (lastPosition > greaterDistance) {
    kmArray.pop();
  }

  return kmArray;
}

export function calcSeconds(
  longestTimeInSeconds: number,
  secondsArray: number[] = []
): number[] {
  const initialValue = getInitialValueForSeconds(longestTimeInSeconds);
  const lastPosition = secondsArray[secondsArray.length - 1];

  if (lastPosition && lastPosition < longestTimeInSeconds) {
    secondsArray.push(lastPosition + initialValue);

    return calcSeconds(longestTimeInSeconds, secondsArray);
  } else if (secondsArray.length === 0) {
    secondsArray.push(initialValue);

    return calcSeconds(longestTimeInSeconds, secondsArray);
  } else if (lastPosition > longestTimeInSeconds) {
    secondsArray.pop();
  }

  return secondsArray;
}

export function getHoursAndMinutesBySeconds(timeInSeconds: number) {
  const h = Math.floor(timeInSeconds / HOUR_IN_SECONDS);
  const m = Math.floor((timeInSeconds % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS);
  const s = Math.floor((timeInSeconds % HOUR_IN_SECONDS) % MINUTE_IN_SECONDS);

  var hDisplay = h > 0 ? h + 'h' : '';
  var mDisplay = m > 0 ? m + 'm' : '';

  return { hours: h, minutes: m, seconds: s, fullTime: hDisplay + mDisplay };
}

export function calcProgressPercent(trip: ITrip, type: ViewMode) {
  const { activities, map } = trip;

  const activitiesCompleted = activities.reduce((total, activity) => {
    if (!['on-route', 'pending'].includes(activity.status)) return total + 1;

    return total;
  }, 0);

  switch (type) {
    case 'hours':
      const secondsSpent = map.legs.reduce((seconds, leg, index) => {
        if (index < activitiesCompleted) {
          return seconds + leg.nominalDuration;
        } else if (activitiesCompleted === activities.length) {
          return map.totalNominalDuration;
        }

        return seconds;
      }, 0);

      const percentageOfSecondsSpent = ruleOfThree(
        100,
        secondsSpent,
        map.totalNominalDuration
      );

      return percentageOfSecondsSpent;
    case 'kilometers':
      const kilometersTraveled = map.legs.reduce((kilometers, leg, index) => {
        if (
          index < activitiesCompleted ||
          activitiesCompleted === activities.length
        ) {
          return kilometers + leg.distance;
        }
        return kilometers;
      }, 0);

      const percentageOfKilometersTraveled = ruleOfThree(
        100,
        kilometersTraveled / 1000,
        map.totalDistance / 1000
      );

      return percentageOfKilometersTraveled;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

// export function calcItemsToDeliver(
//   greaterItems: number,
//   divider: number = 4,
//   items: string[] = []
// ): string[] {
//   const initialValue = greaterItems / greaterItems;
//   const lastPosition = +items[items.length - 1];

//   if (lastPosition && lastPosition < greaterItems) {
//     items.push((lastPosition + initialValue).toFixed(0));

//     return calcItemsToDeliver(greaterItems, divider, items);
//   } else if (items.length === 0) {
//     items.push(initialValue.toFixed(0));

//     return calcItemsToDeliver(greaterItems, divider, items);
//   }

//   return items;
// }

// export function calcActivitiesDeliveredPercent(activities: IActivity[]) {
//   const totalActivities = activities.length;
//   const activitiesCompleted = activities.reduce((total, activity) => {
//     if (!['on-route', 'pending'].includes(activity.status)) return total + 1;

//     return total;
//   }, 0);

//   const percentageOfActivitiesCompleted = ruleOfThree(
//     100,
//     activitiesCompleted,
//     totalActivities
//   );

//   return percentageOfActivitiesCompleted;
// }
