import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { Button } from '../../../../components/Button';

export interface IConfirmationOptions {
  catchOnCancel?: boolean;
  title: string | undefined;
  description: string | undefined;
}

interface IProps extends IConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const DeleteNeighborhood: React.FC<IProps> = ({
  open,
  title,
  description,
  onSubmit,
  onClose,
}) => {
  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoadingButton(true);
    try {
      await onSubmit();
    } catch (error) {
      console.log(error);
    }
    setLoadingButton(false);
  }, [onSubmit]);

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Fechar
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          loading={loadingButton}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
