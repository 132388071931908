import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    overflow: 'none',
    flexDirection: 'column',
  },
  form: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));
