import React from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useStyles } from './styles';

import { companyLogo } from '../../../utils/logoUtils';

import { RatingField } from '../components/RatingField';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { IoPaperPlane } from 'react-icons/io5';
import { useFormik } from 'formik';

import { ISurvey, ISurveyRequest, useSurvey } from '../hooks/survey';
import { BiXCircle } from 'react-icons/bi';
import { CheckCircleOutline } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

interface IParams {
  code: string;
  rate: string;
}

export const Survey = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const { loadNpsByCode, sendNpsResponse } = useSurvey();

  const [isLoading, setIsLoading] = useState(true);
  const [npsData, setNpsData] = useState<ISurvey | null>();
  const [formSended, setFormSended] = useState(false);

  const { code, rate } = useParams<IParams>();

  const validationSchema = yup.object({
    rating: yup.number().min(1),
  });

  useEffect(() => {
    async function loadNps() {
      const nps = await loadNpsByCode(`${code}`);

      if (nps) {
        setNpsData(nps);
        setIsLoading(false);
        theme.setCompanyTheme(nps.company.documentNumber);
      }
    }

    if (code) loadNps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const formik = useFormik({
    initialValues: {
      rating: Number(rate) || 0,
      reason: '',
    },
    validationSchema,
    onSubmit: async (values: ISurveyRequest) => {
      const response = await sendNpsResponse(`${code}`, values);

      enqueueSnackbar(response.data.message, {
        variant: response.data.status === 200 ? 'success' : 'error',
      });

      if (response.data.status === 200) {
        setFormSended(true);
      }
    },
  });

  return (
    <Box className={classes.container}>
      <Box
        component={Paper}
        sx={{
          overflow: 'auto',
          width: '100%',
          maxWidth: 600,
          height: '100%',
          [theme.breakpoints.up('sm')]: {
            maxHeight: 600,
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isLoading ? (
          <Box display="flex" height="100%" alignItems="center">
            <CircularProgress
              size={40}
              style={{ color: `${theme.palette.grey[600]}` }}
            />
          </Box>
        ) : (
          <React.Fragment>
            <Container className={classes.header}>
              <Grid container justifyContent="space-around" alignItems="center">
                <img
                  className={classes.logo}
                  src={companyLogo(npsData?.company.documentNumber).white}
                  alt="4Innovation Tecnologia"
                />
              </Grid>
            </Container>

            {!npsData?.answered && !formSended ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    paddingX: 4,

                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography style={{ fontSize: 15 }} variant="subtitle1">
                      &nbsp;&nbsp;&nbsp;&nbsp;Sua opinião é fundamental para
                      aprimorar nossos produtos e serviços. Por isso, saber se
                      você nos indicaria como fornecedor(a) a amigos, familiares
                      ou outras empresas é essencial.
                      <br />
                      <b>
                        Em uma escala de 1 a 10, qual a probabilidade de você
                        recomendar a {npsData?.company.companyLegalName} a
                        outras pessoas?
                      </b>
                    </Typography>
                  </Box>

                  <RatingField
                    name="rating"
                    value={formik.values.rating}
                    onChange={(e: any) => {
                      formik.setFieldValue('rating', e.target.value);
                    }}
                    defaultValue={Number(rate) || 1}
                  />

                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Comentário"
                    multiline
                    minRows={5}
                    name="reason"
                    margin="normal"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                  />
                </Box>

                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Enviar
                  <IoPaperPlane style={{ marginLeft: 5 }} />
                </Button>
              </>
            ) : (
              <>
                <Typography style={{ textAlign: 'center' }} variant="h4">
                  {formSended ? (
                    <>
                      <b>Obrigado</b> por responder nossa pesquisa!
                      <br />
                    </>
                  ) : (
                    <>
                      Esta pesquisa já foi respondida,
                      <b>obrigado!</b>
                      <br />
                    </>
                  )}
                  <CheckCircleOutline
                    style={{ fontSize: 60 }}
                    color="primary"
                  />
                </Typography>

                <Button
                  color="primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    window.close();
                  }}
                  endIcon={<BiXCircle />}
                >
                  Fechar
                </Button>
              </>
            )}
          </React.Fragment>
        )}
      </Box>
      <Typography variant="subtitle2" style={{ margin: 3 }}>
        Powered by{' '}
        <a href="https://4innovation.com.br/" target="_blank" rel="noreferrer">
          4INNOVATION TECNOLOGIA LTDA
        </a>
      </Typography>
    </Box>
  );
};
