import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { IStoragePosition } from '../../storage-position/hooks/storage-position';

const docDefinition = (
  img: string,
  data: IStoragePosition[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Posições de Armazenagem',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Posições de Armazenagem',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    {
      margin: [0, 10, 0, 0],
      table: {
        widths: [70, 70, 70, 70, 70, 110],
        body: [
          [
            {
              text: 'CD',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Rua',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Coluna',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Nível',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Apartamento',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
            {
              text: 'Código da posição',
              alignment: 'center',
              fontSize: 10,
              bold: true,
              fillColor: '#CCC',
            },
          ],
          ...generateTable(data),
        ],
      },
    },
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: IStoragePosition[]) {
  const rows = data.map((position) => {
    return [
      {
        text: position.distributionCenterCode,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: position.street,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: position.column,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: position.level,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: position.apartment,
        alignment: 'center',
        fontSize: 8,
      },
      {
        text: position.code,
        alignment: 'center',
        fontSize: 8,
      },
    ];
  });

  return rows;
}

export async function generateStoragePositionReportPDF(
  data: IStoragePosition[]
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
