import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { useStyles, Form } from './styles';
import { useAuth } from '../../../../hooks/auth';
import { IBsoft, useBsoft } from '../../hooks/bsoft';

import { Button } from '../../../../components/Button';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const Bsoft: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const {
    data: { user },
  } = useAuth();
  const { loadBsoft, saveBsoft } = useBsoft();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingBsoft, setLoadingBsoft] = useState(true);
  const [data, setData] = useState<IBsoft>({
    apiKey: '',
    days: 0,
  });

  useEffect(() => {
    async function loadIntegration(): Promise<void> {
      try {
        const response = await loadBsoft(user.companyDocument);

        if (!response) return;

        setData({
          ...response,
        });
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoadingBsoft(false);
      }
    }
    if (props.open) loadIntegration();
  }, [loadBsoft, props.open, user.companyDocument]);

  const validationSchema = yup.object().shape({
    apiKey: yup.string(),
    days: yup
      .number()
      .required('campo obrigatório!')
      .test('days-is-validate', 'Dias deve ser maior que zero!', (value) =>
        value ? value > 0 : false
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        await saveBsoft(values);
        props.onClose();
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleClose = useCallback(() => {
    props.onClose();
    formik.resetForm();
    setLoadingBsoft(true);
  }, [props, formik]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">BSOFT</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.form}
        >
          <Grid container spacing={3}>
            {loadingBsoft ? (
              <Grid item xs={12}>
                <Typography component="div" align="center">
                  <CircularProgress />
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="ApiKey"
                    id="apiKey"
                    name="apiKey"
                    value={formik.values.apiKey}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.apiKey && Boolean(formik.errors.apiKey)
                    }
                    helperText={formik.touched.apiKey && formik.errors.apiKey}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Dias"
                    id="days"
                    name="days"
                    type="number"
                    value={formik.values.days}
                    onChange={formik.handleChange}
                    error={formik.touched.days && Boolean(formik.errors.days)}
                    helperText={formik.touched.days && formik.errors.days}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Fechar
        </Button>

        <Button
          onClick={() => formik.handleSubmit()}
          color="secondary"
          variant="contained"
          loading={loadingButton}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
