import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { PERMISSIONS, ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import {
  useCompanyParams,
  DefaultOperationEnum,
} from '../../hooks/company-params';

import { Form } from './styles';

export const CompanyParamsForm = () => {
  const { getCompanyParams, updateCompanyParams } = useCompanyParams();
  const {
    data: { user, permissions },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [companyParamsId, setCompanyParamsId] = useState<number>(0);
  const [data, setData] = useState({
    id: companyParamsId,
    companyDocument: user.companyDocument.toString(),
    defaultOperation: DefaultOperationEnum.DROP_OFF,
    geolocationUpdateTime: 0,
    hasToConfirmRoute: false,
    skipToEndOfActivity: false,
    skipSignature: false,
    convertRfidCodeHexToText: false,
    splitItemByUnit: false,
    requiredToBeepCorrectItem: false,
    appDriverFollowTheOrderOfActivities: false,
    geoFenceEnable: false,
    geoFenceDistance: 0,
    appCompareWithSkuCodes: false,
    filterAndDisplayByDeliveryDate: false,
    createCollectionOrderFromManualDeliveryOrder: false,
    useStatusNotExecutedInOrder: false,
    doNotLinkCodeWith: false,
    codeNotAccepted: '',
    appSynchronizeActivitiesManually: false,
    useGeolocationLastExecution: false,
    useManualRouteSequencing: false,
    appRequiredCompletionChecklist: false,
    urlApi: '',
    urlPlatform: '',
    orderOperationTime: 0,
    sendNps: false,
  });

  useEffect(() => {
    async function loadCompany(): Promise<void> {
      try {
        const response = await getCompanyParams();

        if (!response) return;

        setCompanyParamsId(response.id);

        setData({
          id: response.id,
          companyDocument: response.companyDocument,
          geolocationUpdateTime: response.geolocationUpdateTime,
          defaultOperation: response.defaultOperation,
          hasToConfirmRoute: response.hasToConfirmRoute,
          skipToEndOfActivity: response.skipToEndOfActivity,
          skipSignature: response.skipSignature,
          convertRfidCodeHexToText: response.convertRfidCodeHexToText,
          splitItemByUnit: response.splitItemByUnit,
          requiredToBeepCorrectItem: response.requiredToBeepCorrectItem,
          appDriverFollowTheOrderOfActivities:
            response.appDriverFollowTheOrderOfActivities,
          geoFenceDistance: response.geoFenceDistance,
          geoFenceEnable: response.geoFenceEnable,
          appCompareWithSkuCodes: response.appCompareWithSkuCodes,
          filterAndDisplayByDeliveryDate:
            response.filterAndDisplayByDeliveryDate,
          createCollectionOrderFromManualDeliveryOrder:
            response.createCollectionOrderFromManualDeliveryOrder,
          useStatusNotExecutedInOrder: response.useStatusNotExecutedInOrder,
          doNotLinkCodeWith: response.doNotLinkCodeWith,
          codeNotAccepted: response.codeNotAccepted,
          appSynchronizeActivitiesManually:
            response.appSynchronizeActivitiesManually,
          useGeolocationLastExecution: response.useGeolocationLastExecution,
          useManualRouteSequencing: response.useManualRouteSequencing,
          appRequiredCompletionChecklist:
            response.appRequiredCompletionChecklist,
          urlApi: response.urlApi,
          urlPlatform: response.urlPlatform,
          orderOperationTime: response.orderOperationTime ?? 0,
          sendNps: response.sendNps ?? false,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadCompany();
  }, [getCompanyParams, user.companyDocument]);

  const validationSchema = yup.object({
    defaultOperation: yup.string().required('Selecione uma operação padrão'),
    geolocationUpdateTime: yup.number(),
    hasToConfirmRoute: yup.boolean(),
    skipToEndOfActivity: yup.boolean(),
    skipSignature: yup.boolean(),
    convertRfidCodeHexToText: yup.boolean(),
    splitItemByUnit: yup.boolean(),
    requiredToBeepCorrectItem: yup.boolean(),
    appDriverFollowTheOrderOfActivities: yup.boolean(),
    appCompareWithSkuCodes: yup.boolean(),
    useStatusNotExecutedInOrder: yup.boolean(),
    doNotLinkCodeWith: yup.boolean(),
    orderOperationTime: yup.number(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);
      try {
        await updateCompanyParams(companyParamsId, { ...values });
        const message = 'Atualizado com sucesso!';

        enqueueSnackbar(message, {
          variant: 'success',
        });
      } catch (error) {
        console.log(error);
      }
      setLoadingButton(false);
    },
  });

  return (
    <Main title="Parâmetros" maxWidth="md" to={ROUTES.companyParams}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form noValidate>
            <Grid container spacing={3}>
              {loading ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Operação Padrão"
                      id="defaultOperation"
                      name="defaultOperation"
                      value={formik.values.defaultOperation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.defaultOperation &&
                        Boolean(formik.errors.defaultOperation)
                      }
                      helperText={
                        formik.touched.defaultOperation &&
                        formik.errors.defaultOperation
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="pick-up">COLETA</MenuItem>
                      <MenuItem value="drop-off">ENTREGA</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Tempo de operação (pedido / entrega)"
                      id="orderOperationTime"
                      name="orderOperationTime"
                      value={formik.values.orderOperationTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.orderOperationTime &&
                        Boolean(formik.errors.orderOperationTime)
                      }
                      helperText={
                        formik.touched.orderOperationTime &&
                        formik.errors.orderOperationTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        type: 'number',
                        endAdornment: (
                          <InputAdornment position="end">
                            minutos
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Tempo de atualização geolocalização (motorista)"
                      id="geolocationUpdateTime"
                      name="geolocationUpdateTime"
                      value={formik.values.geolocationUpdateTime}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.geolocationUpdateTime &&
                        Boolean(formik.errors.geolocationUpdateTime)
                      }
                      helperText={
                        formik.touched.geolocationUpdateTime &&
                        formik.errors.geolocationUpdateTime
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        type: 'number',
                        endAdornment: (
                          <InputAdornment position="end">
                            minutos
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="hasToConfirmRoute"
                            name="hasToConfirmRoute"
                            value={formik.values.hasToConfirmRoute}
                            onChange={formik.handleChange}
                            checked={formik.values.hasToConfirmRoute}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Obrigatório confirmaçao da rota
                          </Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="skipToEndOfActivity"
                            name="skipToEndOfActivity"
                            value={formik.values.skipToEndOfActivity}
                            onChange={formik.handleChange}
                            checked={formik.values.skipToEndOfActivity}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Pular listagem de atividades (pedidos) - aplicativo
                          </Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="skipSignature"
                            name="skipSignature"
                            value={formik.values.skipSignature}
                            onChange={formik.handleChange}
                            checked={formik.values.skipSignature}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>Pular assinatura - aplicativo</Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="convertRfidCodeHexToText"
                            name="convertRfidCodeHexToText"
                            value={formik.values.convertRfidCodeHexToText}
                            onChange={formik.handleChange}
                            checked={formik.values.convertRfidCodeHexToText}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Converter código RFID de hexa para texto na exibição
                            - aplicativo
                          </Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="splitItemByUnit"
                            name="splitItemByUnit"
                            value={formik.values.splitItemByUnit}
                            onChange={formik.handleChange}
                            checked={formik.values.splitItemByUnit}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Fracionar item do pedido por unidade
                          </Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="requiredToBeepCorrectItem"
                            name="requiredToBeepCorrectItem"
                            value={formik.values.requiredToBeepCorrectItem}
                            onChange={formik.handleChange}
                            checked={formik.values.requiredToBeepCorrectItem}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Obrigatório bipar item correto - aplicativo
                          </Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="appDriverFollowTheOrderOfActivities"
                            name="appDriverFollowTheOrderOfActivities"
                            value={
                              formik.values.appDriverFollowTheOrderOfActivities
                            }
                            onChange={formik.handleChange}
                            checked={
                              formik.values.appDriverFollowTheOrderOfActivities
                            }
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Seguir ordem das atividades - aplicativo
                          </Typography>
                        }
                      />
                    </FormControl>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="appCompareWithSkuCodes"
                          name="appCompareWithSkuCodes"
                          value={formik.values.appCompareWithSkuCodes}
                          onChange={formik.handleChange}
                          checked={formik.values.appCompareWithSkuCodes}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Comparar código lido com códigos do SKU - aplicativo
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="filterAndDisplayByDeliveryDate"
                          name="filterAndDisplayByDeliveryDate"
                          value={formik.values.filterAndDisplayByDeliveryDate}
                          onChange={formik.handleChange}
                          checked={formik.values.filterAndDisplayByDeliveryDate}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Filtrar e exibir pela data de entrega
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="createCollectionOrderFromManualDeliveryOrder"
                          name="createCollectionOrderFromManualDeliveryOrder"
                          value={
                            formik.values
                              .createCollectionOrderFromManualDeliveryOrder
                          }
                          onChange={formik.handleChange}
                          checked={
                            formik.values
                              .createCollectionOrderFromManualDeliveryOrder
                          }
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Criar pedido de coleta a partir de pedido de entrega
                          manual
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="useStatusNotExecutedInOrder"
                          name="useStatusNotExecutedInOrder"
                          value={formik.values.useStatusNotExecutedInOrder}
                          onChange={formik.handleChange}
                          checked={formik.values.useStatusNotExecutedInOrder}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Usar status NÃO EXECUTADO no pedido
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="appSynchronizeActivitiesManually"
                          name="appSynchronizeActivitiesManually"
                          value={formik.values.appSynchronizeActivitiesManually}
                          onChange={formik.handleChange}
                          checked={
                            formik.values.appSynchronizeActivitiesManually
                          }
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Sincronizar atividades manualmente - aplicativo
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="appRequiredCompletionChecklist"
                          name="appRequiredCompletionChecklist"
                          value={formik.values.appRequiredCompletionChecklist}
                          onChange={formik.handleChange}
                          checked={formik.values.appRequiredCompletionChecklist}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Obrigatório preenchimento checklist - aplicativo
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="useGeolocationLastExecution"
                          name="useGeolocationLastExecution"
                          value={formik.values.useGeolocationLastExecution}
                          onChange={formik.handleChange}
                          checked={formik.values.useGeolocationLastExecution}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Usar geolocalização da última execução
                        </Typography>
                      }
                    />
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="useManualRouteSequencing"
                          name="useManualRouteSequencing"
                          value={formik.values.useManualRouteSequencing}
                          onChange={formik.handleChange}
                          checked={formik.values.useManualRouteSequencing}
                          color="primary"
                        />
                      }
                      label={
                        <Typography>
                          Priorizar entrega na roteirização
                        </Typography>
                      }
                    />

                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="geoFenceEnable"
                            name="geoFenceEnable"
                            value={formik.values.geoFenceEnable}
                            onChange={formik.handleChange}
                            checked={formik.values.geoFenceEnable}
                            color="primary"
                          />
                        }
                        label={<Typography>Cerca eletrônica</Typography>}
                      />
                      <TextField
                        select
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="Raio da Cerca eletrônica"
                        id="geoFenceDistance"
                        name="geoFenceDistance"
                        value={formik.values.geoFenceDistance}
                        onChange={formik.handleChange}
                        disabled={!formik.values.geoFenceEnable}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem value={50}>50m</MenuItem>
                        <MenuItem value={100}>100m</MenuItem>
                        <MenuItem value={500}>500m</MenuItem>
                        <MenuItem value={1000}>1km</MenuItem>
                        <MenuItem value={3000}>3km</MenuItem>
                        <MenuItem value={5000}>5km</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="doNotLinkCodeWith"
                            name="doNotLinkCodeWith"
                            value={formik.values.doNotLinkCodeWith}
                            onChange={formik.handleChange}
                            checked={formik.values.doNotLinkCodeWith}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>Não associar código com: </Typography>
                        }
                      />
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        label="Código não aceito"
                        id="codeNotAccepted"
                        name="codeNotAccepted"
                        value={formik.values.codeNotAccepted}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="URL API"
                        id="urlApi"
                        name="urlApi"
                        value={formik.values.urlApi}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="URL Plataforma"
                        id="urlPlatform"
                        name="urlPlatform"
                        value={formik.values.urlPlatform}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => formik.handleSubmit()}
                    loading={loadingButton}
                    disabled={
                      !permissions.includes(PERMISSIONS.COMPANY_PARAMS_EDIT)
                    }
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
