import React, { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Tooltip,
} from 'react-leaflet';
import { LatLngTuple, divIcon } from 'leaflet';

import { ReactComponent as Car } from '../../../../../assets/icons/car.svg';
import { returnColor } from '../../../../../utils/statusColor';
import { ActivityStatus } from '../../../../../constants';
import { IRouteLight, useRoute } from '../../../hooks/route';
import { IActivity } from '../../../hooks/activity';

import { useStyles } from './styles';

import { useAuth } from '../../../../../hooks/auth';
import { PERMISSIONS } from '../../../../../constants';

interface IMapLink {
  id: string;
  clientId: string;
  legs: {
    averageSpeed: number;
    distance: number;
    nominalDuration: number;
    points: {
      latitude: number;
      longitude: number;
    }[];
    speedProfilePenalty: number;
  }[];
  source: string;
  averageSpeed: number;
  totalDistance: number;
  totalNominalDuration: number;
  totalSpeedProfilePenalty: number;
}

export const MapLeaflet = ({ route }: { route: IRouteLight }) => {
  const {
    data: { permissions },
  } = useAuth();
  const classes = useStyles();
  const { routeList } = useRoute();
  const { map, mapLineColor, activities, vehicle } = route;
  const [trip, setTrip] = useState<any[]>([]);
  const [routeActivities, setRouteActivities] = useState<IActivity[]>([]);

  const markerIcon = (status: ActivityStatus, index: number) =>
    divIcon({
      className: classes.customMarker,
      html: `
        <div
          style="background-color:${returnColor(status)};"
          class="marker-pin"
        ></div>
        <b>${index}</b>
      `,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });

  const carIcon = () =>
    divIcon({
      className: classes.customMarker,
      html: `
        <div class="marker-pin"></div>
        <i>${renderToStaticMarkup(<Car />)}</i>
      `,
      iconSize: [30, 30],
      iconAnchor: [15, 38],
      tooltipAnchor: [1, 0],
    });

  useEffect(() => {
    const tripSolution: IMapLink = JSON.parse(map.mapRoute);

    if (map && map.mapRoute) {
      let route: any = [];
      tripSolution.legs.forEach((leg) => {
        let points: LatLngTuple[] = [];

        leg.points.forEach((point) => {
          points.push([point.latitude, point.longitude]);
        });
        route.push(points);
      });

      setTrip(route);
    }
  }, [map]);

  useEffect(() => {
    if (activities) {
      const newRouteActivities = activities
        .sort((a, b) => a.ordem - b.ordem)
        .map((activity) => activity);

      setRouteActivities(newRouteActivities);
    }
  }, [activities, routeList]);

  return (
    <>
      {permissions.includes(PERMISSIONS.ROUTE_VIEW_MAP) && trip.length > 0 && (
        <MapContainer
          center={trip[0][0]}
          zoom={13}
          scrollWheelZoom
          style={{ width: '100%', height: '500px' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://4innovation.co/">4INNOVATION TECNOLOGIA</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {trip.map((position, index) => {
            return (
              <React.Fragment key={`${index}`}>
                <Polyline
                  pathOptions={{ color: mapLineColor }}
                  positions={position}
                />

                {index === 0 ? (
                  <Marker position={position[0]}>
                    <Tooltip direction="top" offset={[-15, -15]} opacity={1}>
                      <strong>Partida</strong>
                    </Tooltip>
                  </Marker>
                ) : (
                  <>
                    {activities ? (
                      <Marker
                        position={position[0]}
                        icon={markerIcon(
                          routeActivities[index - 1].status,
                          index
                        )}
                      >
                        <Tooltip direction="top" offset={[1, -35]} opacity={1}>
                          <p>Pedido: {routeActivities[index - 1].number}</p>
                          <p>
                            Cliente: {routeActivities[index - 1].customer.name}
                          </p>
                          <p>
                            Endereço:{' '}
                            {routeActivities[index - 1].customer.address.street}
                            ,{' '}
                            {routeActivities[index - 1].customer.address.number}
                          </p>
                        </Tooltip>
                      </Marker>
                    ) : (
                      <Marker position={position[0]}>
                        <Tooltip
                          direction="bottom"
                          offset={[-15, 23]}
                          opacity={1}
                        >
                          <strong>Entrega: {index}</strong>
                        </Tooltip>
                      </Marker>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
          {vehicle && vehicle.lat && vehicle.long && (
            <Marker
              icon={carIcon()}
              position={[parseFloat(vehicle.lat), parseFloat(vehicle.long)]}
            >
              <Tooltip direction="bottom" opacity={1}>
                <strong>{vehicle.vehicleName}</strong>
              </Tooltip>
            </Marker>
          )}

          <Marker
            position={trip[trip.length - 1][trip[trip.length - 1].length - 1]}
          >
            <Tooltip direction="bottom" offset={[-15, 23]} opacity={1}>
              <strong>Chegada</strong>
            </Tooltip>
          </Marker>
        </MapContainer>
      )}
    </>
  );
};
