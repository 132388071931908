import { Position } from './styles';

interface IItemPositionProps {
  currentPosition: string;
  backgroundColor: string;
  showPercentage?: boolean;
  children?: React.ReactChild;
}

export const ItemPosition = ({
  currentPosition,
  showPercentage = false,
  children,
  ...props
}: IItemPositionProps) => {
  return (
    <Position
      {...props}
      style={{
        left: currentPosition,
      }}
    >
      {showPercentage && <span>{currentPosition}</span>}
      {children}
    </Position>
  );
};
