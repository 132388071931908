import { useEffect, useState } from 'react';
import { TableRow, Typography, Checkbox } from '@material-ui/core';

import { TableCell } from '../../../../components/Table/TableCell';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { CollapseRow } from '../../../../components/Table/CollapseRow';
import { ISelectedOrder, usePlanning } from '../../../order/hooks/planning';
import { IBatch, useRoute } from '../../hooks/route';
import { maskPostalcode } from '../../../../utils';

interface IProps {
  batch: string;
  open: boolean;
  colSpan: number;
}

export const BatchCollapseRow = ({ batch, open, colSpan }: IProps) => {
  const {
    handleSelectOrder,
    handleSelectAllOrders,
    isTableChecked,
    isTableIndeterminate,
    isOrderSelected,
  } = usePlanning();
  const { loadBatch } = useRoute();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [batches, setBacthes] = useState<IBatch[]>([]);
  const availableOrders: ISelectedOrder[] = batches
    .filter((batch) => batch.routeId === null)
    .map((batch) => ({
      id: batch.orderId,
      number: batch.orderNumber,
      shippingAddress: batch.shippingAddress,
    }));

  const columns: IBasicColumn[] = [
    {
      padding: 'checkbox',
      content: (
        <Checkbox
          indeterminate={isTableIndeterminate(availableOrders)}
          checked={isTableChecked(availableOrders)}
          onChange={(e, checked) =>
            handleSelectAllOrders(checked, availableOrders)
          }
          inputProps={{ 'aria-label': 'select all orders' }}
        />
      ),
    },
    { name: 'Pedido' },
    { name: 'Endereço' },
    { name: 'Sequência' },
    { name: 'Serviço' },
    { name: 'Motorista' },
    { name: 'Rota' },
  ];

  useEffect(() => {
    async function asyncLoadBatch(): Promise<void> {
      setLoading(true);

      try {
        const response = await loadBatch(batch);
        if (!response) return;

        setBacthes(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (open) asyncLoadBatch();
  }, [batch, loadBatch, open]);

  const labelMapService = (value: string) => {
    let label = value;

    if (value === 'openmaps') label = 'Open Maps';
    else if (value === 'maplink') label = 'Maplink';

    return label;
  };

  return (
    <CollapseRow open={open} colSpan={colSpan}>
      <Typography variant="h6" gutterBottom component="div">
        Pedidos:
      </Typography>
      <BasicTable
        size="small"
        headerWithNoColor
        columns={columns}
        loading={loading}
        total={batches.length}
        pagination={[page, setPage]}
        rowsPerPageState={[rowsPerPage, setRowsPerPage]}
      >
        {batches
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((batch) => {
            const order: ISelectedOrder = {
              id: batch.orderId,
              number: batch.orderNumber,
              shippingAddress: batch.shippingAddress,
            };

            return (
              <TableRow key={batch.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isOrderSelected(order)}
                    onClick={() => handleSelectOrder(order)}
                    disabled={!!batch.routeId}
                    inputProps={{
                      'aria-labelledby': batch.id.toString(),
                    }}
                  />
                </TableCell>
                <TableCell>{batch.orderNumber}</TableCell>
                <TableCell>
                  {`${batch.shippingAddress.street},
                    ${batch.shippingAddress.number} -
                    ${maskPostalcode(batch.shippingAddress.postalcode)},
                    ${batch.shippingAddress.city}
                  `}
                </TableCell>
                <TableCell>{batch.sequence}</TableCell>
                <TableCell>{labelMapService(batch.mapsService)}</TableCell>
                <TableCell>{batch.driverName}</TableCell>
                <TableCell>{batch.routeName}</TableCell>
              </TableRow>
            );
          })}
      </BasicTable>
    </CollapseRow>
  );
};
