import { format, subDays } from 'date-fns';
import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import api from '../../../services/api';

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

export interface IOperationLog {
  id: number;
  createdAt: string;
  codesRead: string;
  codesTranslated: string;
  correctConference: string;
  driverName: string;
  inconsistency: string;
}

export interface PaginatedRfidOperationLogs {
  rfidOperationLogs: IOperationLog[];
  total: number;
}

interface IRfidFilter {
  initialDate: string;
  finalDate: string;
  driverId?: string;
}

interface IRfidContext {
  loadRfid(options?: IRfidFilter): Promise<PaginatedRfidOperationLogs>;
  filter: IRfidFilter;
  setFilter: React.Dispatch<React.SetStateAction<IRfidFilter>>;
}

const RfidContext = createContext<IRfidContext>({} as IRfidContext);

const RfidProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<IRfidFilter>({
    initialDate: formatDate(subDays(new Date(), 6)),
    finalDate: formatDate(new Date()),
    driverId: '',
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: formatDate(subDays(new Date(), 6)),
      finalDate: formatDate(new Date()),
      driverId: '',
    }));
  }, []);

  const loadRfid = useCallback(async (options: IRfidFilter) => {
    let query = '';

    const { initialDate, finalDate, driverId } = options;

    query =
      `${initialDate && `?initialDate=${initialDate}`}` +
      `${finalDate && `&finalDate=${finalDate}`}` +
      `${driverId && `&driverId=${driverId}`}`;

    const response = await api.get(`/rfid/list-log${query}`);

    return response.data;
  }, []);

  return (
    <RfidContext.Provider
      value={{
        loadRfid,
        filter,
        setFilter,
      }}
    >
      {children}
    </RfidContext.Provider>
  );
};

function useRfid(): IRfidContext {
  const context = useContext(RfidContext);

  if (!context) {
    throw new Error('useRfid must be used with in a RfidProvider');
  }

  return context;
}

export { RfidProvider, useRfid };
