import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { ManualOrderParamsForm } from '../pages/ManualOrderParamsForm';
import { ManualOrderParamsProvider } from '../hooks/manual-order-params';
import { ManualOrderProvider } from '../../order/hooks/manual-order';

export const ManualOrderParamsRoute = () => (
  <ManualOrderProvider>
    <ManualOrderParamsProvider>
      <Switch>
        <PrivateRoute
          path={ROUTES.manualOrderParams}
          exact
          permission={PERMISSIONS.MANUAL_ORDER_PARAMS_VIEW}
          component={ManualOrderParamsForm}
        />
      </Switch>
    </ManualOrderParamsProvider>
  </ManualOrderProvider>
);
