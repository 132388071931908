import React, { useCallback, useEffect, useState } from 'react';
import { ILoadNps, INps, useNps } from '../../hooks/nps';
import { Main } from '../../../../components/Main';

import * as yup from 'yup';

import {
  Button,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { format, subDays } from 'date-fns';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { NpsForm } from '../NpsForm';
import { generateNpsReportPDF } from '../../../report/templates/npsReport';

import XLSX from 'xlsx';
import { useFormik } from 'formik';
import { NpsCard } from '../../components/NpsCard';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';

export const NpsList = () => {
  const { loadNpsList, filter, setFilter } = useNps();

  const { initialDate, finalDate } = filter;

  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [npsList, setNpsList] = useState<ILoadNps>({
    total: 1,
    nps: [],
  });
  const [npsId, setNpsId] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columnsTable: IBasicColumn[] = [
    { name: 'Criado Em' },
    { name: 'Respondido' },
    { name: 'Pedido' },
    { name: 'Cliente' },
    { name: 'Nota' },
    { name: 'Descrição' },
    { name: 'Data' },
    { name: 'Ações' },
  ];

  const validationSchema = yup.object({
    initialDate: yup.date().required('Campo obrigatório!'),
    finalDate: yup
      .date()
      .required('Campo obrigatório!')
      .min(
        yup.ref('initialDate'),
        'Data fim não pode ser menor que a data início'
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filter,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  useEffect(() => {
    const fetchNpsList = async () => {
      const data = await loadNpsList({
        initialDate,
        finalDate,
        page: page + 1,
        take: rowsPerPage,
      });

      setNpsList(data);

      setLoading(false);
    };

    fetchNpsList();
  }, [loadNpsList, initialDate, finalDate, page, rowsPerPage]);

  const downloadExcel = useCallback((data: INps[]) => {
    const newData = data.map((npsItem) => {
      return {
        createdAt: npsItem.createdAt
          ? format(new Date(npsItem.createdAt), 'dd/MM/yyyy HH:mm:ss')
          : '',
        answered: npsItem.answered ? 'Sim' : 'Não',
        orderNumber: npsItem.order?.number,
        customer: npsItem.order?.customer.name,
        rating: npsItem.rating,
        reason: npsItem.reason,
        date: npsItem.dateAnswer
          ? format(new Date(npsItem.dateAnswer), 'dd/MM/yyyy HH:mm:ss')
          : '',
        treated: npsItem?.treated ? 'Sim' : 'Não',
        resolution: npsItem?.resolution,
        resolutionDate: npsItem?.dateResolution
          ? format(new Date(npsItem?.dateResolution), 'dd/MM/yyyy HH:mm:ss')
          : '',
      };
    });

    const header = [
      [
        'Criado Em',
        'Respondido',
        'Pedido',
        'Cliente',
        'Nota',
        'Descrição',
        'Data',
        'Tratado',
        'Resolução',
        'Data Resolução',
      ],
    ];

    const workSheetCols = [
      { width: 20 },
      { width: 10 },
      { width: 20 },
      { width: 40 },
      { width: 10 },
      { width: 40 },
      { width: 20 },
      { width: 10 },
      { width: 40 },
      { width: 20 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'nps');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Nps.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setNpsId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setNpsId(null);
  }, []);

  const handleReset = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleReset(e);
      setFilter({
        initialDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
        finalDate: format(new Date(), 'yyyy-MM-dd'),
      });
    },
    [formik, setFilter]
  );

  return (
    <Main title="NPS">
      <Grid container spacing={1}>
        <NpsForm open={openView} npsId={npsId} onClose={handleCloseView} />

        <NpsCard.Provider>
          <NpsCard name="notAnswered" />
          <NpsCard name="promoter" />
          <NpsCard name="neutral" />
          <NpsCard name="detrator" />
          <NpsCard name="average" />
        </NpsCard.Provider>

        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                const { nps } = await loadNpsList({
                  initialDate,
                  finalDate,
                  isReport: true,
                });
                generateNpsReportPDF(nps);
              }}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={async () => {
                const { nps } = await loadNpsList({
                  initialDate,
                  finalDate,
                  isReport: true,
                });
                downloadExcel(nps);
              }}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1" id="filter">
              Filtros
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data início"
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={formik.values.initialDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.initialDate &&
                    Boolean(formik.errors.initialDate)
                  }
                  helperText={
                    formik.touched.initialDate && formik.errors.initialDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data fim"
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={formik.values.finalDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.finalDate && Boolean(formik.errors.finalDate)
                  }
                  helperText={
                    formik.touched.finalDate && formik.errors.finalDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => formik.handleSubmit()}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleReset}>Limpar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={npsList.total}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {npsList.nps.map((nps: INps) => (
              <TableRow key={nps.id} hover>
                <TableCell>
                  {nps.createdAt
                    ? format(new Date(nps.createdAt), 'dd/MM/yyyy HH:mm:ss')
                    : ''}
                </TableCell>
                <TableCell>{nps.answered ? 'SIM' : 'NÃO'}</TableCell>
                <TableCell>{nps.order?.number}</TableCell>
                <TableCell>{nps.order?.customer.name}</TableCell>
                <TableCell component="th" scope="row">
                  {nps.rating}
                </TableCell>
                <TableCell>{nps.reason}</TableCell>
                <TableCell>
                  {nps.dateAnswer
                    ? format(new Date(nps.dateAnswer), 'dd/MM/yyyy HH:mm:ss')
                    : ''}
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    color="inherit"
                    aria-label="show object"
                    disabled={nps.answered ? false : true}
                    onClick={() => handleOpenView(nps.id)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
