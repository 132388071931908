import XLSX from 'xlsx';

export const sheetToJson = (file: File): Promise<unknown[]> => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();

      reject(new DOMException('Problem parsing input file.'));
    };

    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary', raw: false });
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonArr = XLSX.utils.sheet_to_json(first_worksheet, { defval: '' });

      resolve(jsonArr);
    };

    reader.readAsBinaryString(file);
  });
};
