import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IVehicleTypeRequest {
  name: string;
  maxVolume: string;
  maxWeight: string;
  size: string;
  maxSitesNumber: string;
  companyDocument: number;
}

export interface ILoadVehicleType {
  id: number;
  name: string;
  maxVolume: string;
  maxWeight: string;
  size: string;
  maxSitesNumber: string;
  companyDocument: number;
}

interface IVehicleTypeContext {
  loadVehicleTypeById(id: number): Promise<ILoadVehicleType | undefined>;
  loadVehicleTypeList(companyDocument: number): Promise<ILoadVehicleType[]>;
  updateVehicleType(id: number, data: IVehicleTypeRequest): Promise<void>;
  createVehicleType(data: IVehicleTypeRequest): Promise<void>;
  deleteVehicleType(id: number): Promise<void>;
}

const VehicleTypeContext = createContext<IVehicleTypeContext>(
  {} as IVehicleTypeContext
);

const VehicleTypeProvider: React.FC = ({ children }) => {
  const loadVehicleTypeById = useCallback(async (id: number) => {
    const response = await api.get(`/vehicle-type/${id}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const loadVehicleTypeList = useCallback(async (companyDocument: number) => {
    const response = await api.get(
      `/vehicle-type?companyDocumment=${companyDocument}`
    );

    return response.data;
  }, []);

  const createVehicleType = useCallback(async (data: IVehicleTypeRequest) => {
    await api.post('/vehicle-type', data);
  }, []);

  const updateVehicleType = useCallback(
    async (id: number, data: IVehicleTypeRequest) => {
      await api.put(`/vehicle-type/${id}`, data);
    },
    []
  );

  const deleteVehicleType = useCallback(async (id: number) => {
    await api.delete(`/vehicle-type/${id}`);
  }, []);

  return (
    <VehicleTypeContext.Provider
      value={{
        loadVehicleTypeById,
        loadVehicleTypeList,
        createVehicleType,
        updateVehicleType,
        deleteVehicleType,
      }}
    >
      {children}
    </VehicleTypeContext.Provider>
  );
};

function useVehicleType(): IVehicleTypeContext {
  const context = useContext(VehicleTypeContext);

  if (!context) {
    throw new Error('useVehicleType must be used within a VehicleTypeProvider');
  }

  return context;
}

export { VehicleTypeProvider, useVehicleType };
