import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface ISascar {
  user: string;
  password: string;
}

interface ISascarContext {
  loadSascar(companyDocument: number): Promise<ISascar | undefined>;
  saveSascar(data: ISascar): Promise<void>;
}

const SascarContext = createContext<ISascarContext>({} as ISascarContext);

const SascarProvider: React.FC = ({ children }) => {
  const loadSascar = useCallback(async (companyDocument: number) => {
    const response = await api.get(
      `/integrations/sascar/config/${companyDocument}`
    );

    return response.data;
  }, []);

  const saveSascar = useCallback(async (data: ISascar) => {
    await api.post('/integrations/sascar/config', data);
  }, []);

  return (
    <SascarContext.Provider
      value={{
        loadSascar,
        saveSascar,
      }}
    >
      {children}
    </SascarContext.Provider>
  );
};

function useSascar(): ISascarContext {
  const context = useContext(SascarContext);

  if (!context) {
    throw new Error('useSascar must be used within a SascarProvider');
  }

  return context;
}

export { SascarProvider, useSascar };
