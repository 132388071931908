import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Toolbar,
  Typography,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { formatDateToDayMonthYear } from '../../../../utils';
import { Accordion } from '../../../../components/Accordion';
import { useLocalStorage } from '../../../../hooks/localStorage';
import { IResult, useSeparation } from '../../hooks/separation';
import { SeparationFilter } from '../SeparationFilter';
import { DistributionCenterAccordion } from './DistributionCenterAccordion';

export const OrderSeparation = () => {
  const { separationFilter, loadDaysWithSeparation } = useSeparation();
  const [loading, setLoading] = useState(true);
  const [daysWithOrders, setDaysWithOrders] = useState<IResult>({
    total: 0,
    result: [],
  });
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isFilterOpen, setIsFilterOpen] = useLocalStorage<boolean>(
    'separationFilter',
    false
  );

  useEffect(() => {
    async function asyncLoadDaysWithSeparation(): Promise<void> {
      try {
        setLoading(true);
        const response = await loadDaysWithSeparation({ ...separationFilter });
        if (!response) return;

        setDaysWithOrders(response);
        setExpanded(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    asyncLoadDaysWithSeparation();
  }, [loadDaysWithSeparation, separationFilter]);

  useEffect(() => {
    if (daysWithOrders.result.length === 1) {
      setExpanded('panel0');
    }
  }, [daysWithOrders]);

  const title = useMemo(() => {
    const { conferenceStatus } = separationFilter;

    if (conferenceStatus === 'unchecked') return 'Não conferido';
    else if (conferenceStatus === 'conferred') return 'Conferido';
    else if (conferenceStatus === 'partially-conferred')
      return 'Parcialmente conferido';
  }, [separationFilter]);

  return (
    <>
      <Toolbar style={{ padding: '0 8px 0 16px' }}>
        <Typography
          style={{ flex: '1 1 100%' }}
          variant="h6"
          id="accordionTitle"
          component="div"
        >
          {title}
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          startIcon={<FilterListIcon />}
        >
          Filtros
        </Button>
      </Toolbar>

      <Collapse
        in={isFilterOpen}
        timeout="auto"
        unmountOnExit
        style={{ marginBottom: 16 }}
      >
        <Toolbar style={{ padding: '0 8px 0 16px' }}>
          <SeparationFilter />
        </Toolbar>
      </Collapse>

      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress color="inherit" />
        </Typography>
      ) : daysWithOrders.result.length > 0 ? (
        daysWithOrders.result.map((day, index) => (
          <Accordion
            key={index}
            index={index}
            summary={`${formatDateToDayMonthYear(day.date)} - Quantidade: ${
              day.quantity
            }`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <DistributionCenterAccordion date={day.date} />
          </Accordion>
        ))
      ) : (
        <Box pb={4} pt={4}>
          <Typography variant="body1" component="div" align="center">
            Nenhuma informação encontrada.
          </Typography>
        </Box>
      )}
    </>
  );
};
