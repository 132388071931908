import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAuth } from '../../hooks/auth';
import { PERMISSIONS, ROUTES } from '../../constants';

export interface ICellActions {
  id: number;
  sendTo: keyof typeof ROUTES;
  handleOpen?: () => void;
  handleDelete: () => void;
  onEditPermission?: keyof typeof PERMISSIONS;
  onRemovePermission?: keyof typeof PERMISSIONS;
}

export const CellActions = ({
  id,
  sendTo,
  handleOpen,
  handleDelete,
  onEditPermission,
  onRemovePermission,
}: ICellActions) => {
  const {
    data: { permissions },
  } = useAuth();
  const history = useHistory();

  const handleClickEdit = (route: keyof typeof ROUTES, id: number) => {
    const path = `${ROUTES[route]}/${id}`;
    history.push(path);
  };

  return (
    <div style={{ width: '145px', margin: 'auto' }}>
      {handleOpen && (
        <IconButton
          color="inherit"
          aria-label="show object"
          onClick={handleOpen}
        >
          <VisibilityIcon />
        </IconButton>
      )}

      <IconButton
        color="inherit"
        aria-label="edit object"
        onClick={() => handleClickEdit(sendTo, id)}
        disabled={
          onEditPermission &&
          !permissions.includes(PERMISSIONS[onEditPermission])
        }
      >
        <EditIcon />
      </IconButton>

      <IconButton
        color="inherit"
        aria-label="delete object"
        onClick={handleDelete}
        disabled={
          onRemovePermission &&
          !permissions.includes(PERMISSIONS[onRemovePermission])
        }
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
