import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { ROUTES } from '../../../constants';

import { Home } from '../pages/Home';

export const HomeRoute: React.FC = () => (
  <Switch>
    <PrivateRoute path={ROUTES.home} component={Home} />
  </Switch>
);
