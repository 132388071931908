import React from 'react';
import { Switch } from 'react-router-dom';

import { PERMISSIONS, ROUTES } from '../../../constants';
import PrivateRoute from '../../../routes/PrivateRoute';
import { OrderProvider } from '../../order/hooks/order';
import { RouteProvider } from '../../route/hooks/route';
import { Dashboard } from '../pages/Dashboard';

export const DashboardRoute: React.FC = () => (
  <OrderProvider>
    <RouteProvider>
      <Switch>
        <PrivateRoute
          path={ROUTES.dashboard}
          permission={PERMISSIONS.DASHBOARD_VIEW}
          component={Dashboard}
        />
      </Switch>
    </RouteProvider>
  </OrderProvider>
);
