import { Button, Popover, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { PeriodEnum, useSettings } from '../../../hooks/settings';
import { IconButton } from './IconButton';

const validationSchema = yup.object({
  from: yup.date().required('Campo obrigatório'),
  to: yup
    .date()
    .required('Campo obrigatório')
    .min(yup.ref('from'), function (value) {
      return 'Data menor que ' + format(new Date(value.min), 'dd/MM/yyyy');
    }),
});

export const PopoverFilterButton = () => {
  const { selectedPeriod, getDatePeriod, changePeriod } = useSettings();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getDatePeriod(selectedPeriod),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changePeriod(PeriodEnum.CUSTOM, values);
      handleClose();
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <FilterListIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="De"
            type="date"
            id="from"
            name="from"
            value={formik.values.from}
            onChange={formik.handleChange}
            error={formik.touched.from && Boolean(formik.errors.from)}
            helperText={formik.touched.from && formik.errors.from}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            variant="outlined"
            size="small"
            label="Até"
            type="date"
            id="to"
            name="to"
            value={formik.values.to}
            onChange={formik.handleChange}
            error={formik.touched.to && Boolean(formik.errors.to)}
            helperText={formik.touched.to && formik.errors.to}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Button type="submit" variant="contained" color="secondary">
            Salvar
          </Button>
        </form>
      </Popover>
    </>
  );
};
