import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowX: 'hidden',
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  header: {
    borderRadius: 4,
    maxWidth: 700,
    maxHeight: 83,
    height: '100%',
    background: theme.gradient.company,
    opacity: 1,
  },

  logo: {
    height: 83,
  },

  button: {
    background: theme.gradient.company,
  },
}));
