import { Button, TextField } from '@material-ui/core';
import {
  ModalActions,
  ModalTitle,
  ModalContent,
} from '../../../../../components/Modal';
import { useState } from 'react';

interface IObservationModal {
  name: string;
  observation: string | null;
  close: () => void;
  onSubmit: (observation: string) => void;
}

export const ObservationModal = (props: IObservationModal) => {
  const [observationField, setObservationField] = useState(
    props.observation || ''
  );
  const handleSubmit = (observation: string) => {
    if (!observation) return;
    props.onSubmit(observation);
    props.close();
  };

  return (
    <>
      <ModalTitle>{`Observação da Rota ${props.name}:`}</ModalTitle>
      <ModalContent>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Observação"
          multiline
          id="observation"
          name="observation"
          value={observationField}
          onChange={(event) => setObservationField(event.target.value)}
          error={
            observationField !== (props.observation || '') && !observationField
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={props.close} color="inherit">
          Fechar
        </Button>

        <Button
          onClick={() => handleSubmit(observationField)}
          color="secondary"
        >
          Salvar
        </Button>
      </ModalActions>
    </>
  );
};
