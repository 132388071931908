import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IMaplinkRequest {
  clientId: string;
  clientSecret: string;
}

export interface ILoadMaplink {
  clientId: string;
  clientSecret: string;
}

interface IMaplinkContext {
  loadMaplink(): Promise<ILoadMaplink | undefined>;
  saveMaplink(data: IMaplinkRequest): Promise<void>;
}

const MaplinkContext = createContext<IMaplinkContext>({} as IMaplinkContext);

const MaplinkProvider: React.FC = ({ children }) => {
  const loadMaplink = useCallback(async () => {
    const response = await api.get('/integrations/maplink/config');

    return response.data;
  }, []);

  const saveMaplink = useCallback(async (data: IMaplinkRequest) => {
    await api.post('/integrations/maplink/config', data);
  }, []);

  return (
    <MaplinkContext.Provider
      value={{
        loadMaplink,
        saveMaplink,
      }}
    >
      {children}
    </MaplinkContext.Provider>
  );
};

function useMaplink(): IMaplinkContext {
  const context = useContext(MaplinkContext);

  if (!context) {
    throw new Error('useMaplink must be used within a MaplinkProvider');
  }

  return context;
}

export { MaplinkProvider, useMaplink };
