import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Typography,
  IconButton,
  Box,
  Paper,
  Divider,
  Button,
  Toolbar,
  Container,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from './styles';

import { useAuth } from '../../hooks/auth';
import { PERMISSIONS } from '../../constants';

interface IProps {
  title?: string;
  to?: string | undefined;
  button?: 'add' | 'back';
  buttonName?: string;
  maxWidth?: 'lg' | 'xs' | 'sm' | 'md' | 'xl';
  hasPermission?: keyof typeof PERMISSIONS;
}

export const Main: React.FC<IProps> = ({
  title,
  to,
  button,
  buttonName,
  maxWidth = 'lg',
  children,
  hasPermission,
}) => {
  const {
    data: { permissions },
  } = useAuth();
  const history = useHistory();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (to) history.push(to);
  }, [history, to]);

  return (
    <Container maxWidth={maxWidth} className={classes.container}>
      <Paper className={classes.paper}>
        {title && (
          <>
            <Toolbar>
              <IconButton
                size="small"
                className={clsx(
                  button !== 'back' && classes.hide,
                  classes.button
                )}
                onClick={handleClick}
              >
                <ArrowBackIcon />
              </IconButton>

              <Typography variant="h6" align="left" className={classes.title}>
                {title}
              </Typography>

              <Button
                variant="contained"
                className={clsx(button !== 'add' && classes.hide)}
                color="secondary"
                onClick={handleClick}
                startIcon={<AddIcon />}
                disabled={
                  hasPermission &&
                  !permissions.includes(PERMISSIONS[hasPermission])
                }
              >
                {buttonName}
              </Button>
            </Toolbar>

            <Divider />
          </>
        )}

        <Box p={2}>{children}</Box>
      </Paper>
    </Container>
  );
};
