import React from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { StepIconProps } from '@material-ui/core';

interface IProps {
  icon: React.ComponentType<IconBaseProps>;
  propsIcon: StepIconProps;
}

export const IconWrapper: React.FC<IProps> = ({
  icon: Icon,
  propsIcon,
}: IProps) => {
  const { active, completed } = propsIcon;

  return active || completed ? (
    <Icon size={25} />
  ) : (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="#417DB1" />
          <stop offset={1} stopColor="#763982" />
        </linearGradient>
      </svg>

      <Icon size={25} style={{ fill: 'url(#linearColors)' }} />
    </>
  );
};
