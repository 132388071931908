import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';
import { useOrder, IFullOrder } from '../../hooks/order';
import { PERMISSIONS } from '../../../../constants';
import { useAuth } from '../../../../hooks/auth';

export const TagCodeInput = (props: { order: IFullOrder }) => {
  const {
    data: { permissions },
  } = useAuth();
  const { loadOrderTag, updateOrderTag } = useOrder();

  const [isDisabledTagCode, setIsDisabledTagCode] = useState(true);
  const [loadingTagCode, setLoadingTagCode] = useState(false);

  const validationSchemaTagCode = yup.object({
    tagCode: yup.string().required('Informe o código de rastreio'),
  });

  const formikTagCode = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaTagCode,
    initialValues: {
      tagCode: '',
    },
    onSubmit: async (values) => {
      setLoadingTagCode(true);
      let data: any;

      data = {
        Etiqueta: values.tagCode,
      };

      try {
        if (props.order.id) {
          await updateOrderTag(props.order.id, data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsDisabledTagCode(true);
      }

      setLoadingTagCode(false);
    },
  });

  useEffect(() => {
    async function loadTag(): Promise<void> {
      try {
        let responseTagCode;
        responseTagCode = await loadOrderTag(props.order.id!);
        if (!responseTagCode) return;
        formikTagCode.setFieldValue('tagCode', responseTagCode.tagCode);
      } catch (error) {
        console.log(error);
      }
    }

    if (props.order.id) {
      loadTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.order.id, loadOrderTag,]);

  const handleClickTagCode = useCallback(() => {
    setIsDisabledTagCode(!isDisabledTagCode);
  }, [isDisabledTagCode]);

  const isNotDisabled = useCallback(() => {
    if (permissions.includes(PERMISSIONS.ORDER_EDIT)) return false;

    return true;
  }, [permissions]);

  return (
    <TextField
      variant="outlined"
      size="small"
      required
      fullWidth
      label="Código de Rastreio"
      id="tagCode"
      name="tagCode"
      value={formikTagCode.values.tagCode}
      onChange={formikTagCode.handleChange}
      error={
        formikTagCode.touched.tagCode && Boolean(formikTagCode.errors.tagCode)
      }
      helperText={formikTagCode.touched.tagCode && formikTagCode.errors.tagCode}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: isDisabledTagCode,
        endAdornment: (
          <InputAdornment position="end">
            {isDisabledTagCode ? (
              <IconButton
                onClick={handleClickTagCode}
                edge="end"
                color="inherit"
                disabled={isNotDisabled()}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                {loadingTagCode ? (
                  <CircularProgress size={25} style={{ color: 'inherit' }} />
                ) : (
                  <>
                    <IconButton
                      onClick={() => {
                        handleClickTagCode();
                      }}
                      edge="end"
                      color="inherit"
                    >
                      <CloseIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => formikTagCode.handleSubmit()}
                      edge="end"
                      color="inherit"
                    >
                      <CheckIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </InputAdornment>
        ),
      }}
      autoComplete="off"
    />
  );
};
