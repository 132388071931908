import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';

import { PERMISSIONS } from '../../../../../constants';
import { useAuth } from '../../../../../hooks/auth';
import { useLocalStorage } from '../../../../../hooks/localStorage';
import { IRouteDatesLoad, useRoute } from '../../../hooks/route';
import { ImportRoute } from '../ImportRoute';
import { MainMap } from '../MainMap';
import { RouteTable } from '../Table';
import { RouteFilter } from '../../RouteFilter';

import { useStyles } from './styles';
import { useCompanyParams } from '../../../../company-params/hooks/company-params';

export const RouteAccordion = () => {
  const {
    data: { user, permissions },
  } = useAuth();
  const { filter, loadDaysWithRoutes, setHasToConfirm } = useRoute();
  const { getCompanyParams } = useCompanyParams();
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [openImportRoute, setOpenImportRoute] = useState(false);
  const [days, setDays] = useState<IRouteDatesLoad>({
    total: 0,
    result: [],
  });
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isFilterOpen, setIsFilterOpen] = useLocalStorage<boolean>(
    'routeFilter',
    false
  );

  const loadRoutes = useCallback(async (): Promise<void> => {
    try {
      const response = await loadDaysWithRoutes(user.companyDocument, filter);

      if (!response) return;

      for (let i = 0; i < response.result.length; i++) {
        const [year, month, day] = response.result[i].date.split('-');

        const dateRefactored = `${day}/${month}/${year}`;
        response.result[i].date = dateRefactored;
      }

      setDays(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [loadDaysWithRoutes, user.companyDocument, filter]);

  useEffect(() => {
    if (loading || filter) {
      loadRoutes();
    }
  }, [loadRoutes, loading, filter]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleToggleImportRoute = useCallback(() => {
    setOpenImportRoute((prevState) => !prevState);
  }, []);

  useEffect(() => {
    async function loadCompany(): Promise<any> {
      try {
        const response = await getCompanyParams();

        if (!response) return;

        setHasToConfirm(response.hasToConfirmRoute);
      } catch (error) {
        console.log(error);
      }
    }

    loadCompany();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyParams]);

  return (
    <Paper className={classes.root}>
      <ImportRoute open={openImportRoute} onClose={handleToggleImportRoute} />

      <Toolbar className={classes.toolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          id="accordionTitle"
          component="div"
        >
          Rotas
        </Typography>

        <Button
          color="secondary"
          variant="contained"
          onClick={handleToggleImportRoute}
          startIcon={<PublishIcon style={{ transform: 'rotate(-180deg)' }} />}
          style={{ marginRight: 10 }}
        >
          Importar
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          startIcon={<FilterListIcon />}
        >
          Filtros
        </Button>

        <IconButton
          color="inherit"
          onClick={() => setLoading(true)}
          disabled={loading}
        >
          <RefreshIcon
            className={clsx({ [classes.refreshAnimation]: loading })}
          />
        </IconButton>
      </Toolbar>

      <Collapse in={isFilterOpen} timeout="auto" unmountOnExit>
        <Toolbar className={classes.toolbar}>
          <RouteFilter />
        </Toolbar>
      </Collapse>

      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress style={{ color: theme.palette.warning.main }} />
        </Typography>
      ) : (
        <>
          {days.result.length > 0 ? (
            <>
              {permissions.includes(PERMISSIONS.ROUTE_VIEW_MAP) && (
                <Box m={2} mt={0}>
                  <MainMap
                    todaysDate={(() => {
                      const [day, month, year] = days.result[0].date.split('/');

                      return `${year}-${month}-${day}`;
                    })()}
                  />
                </Box>
              )}

              {days.result.map((day, index) => (
                <Accordion
                  key={`panel${String(index)}`}
                  expanded={expanded === `panel${String(index)}`}
                  onChange={handleChange(`panel${String(index)}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${String(index)}-content`}
                    id={`panel${String(index)}-header`}
                  >
                    <Typography className={classes.heading}>
                      {`${day.date} - Rotas: ${day.quantity}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    {expanded === `panel${String(index)}` && (
                      <RouteTable date={day.date} />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          ) : (
            <Box pb={4} pt={4}>
              <Typography variant="body1" component="div" align="center">
                Nenhuma informação encontrada.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};
