import * as yup from 'yup';

yup.addMethod(
  yup.array,
  'unique',
  function (mapper = (a: any) => a, message: string) {
    return this.test('unique', message, function (a: any) {
      const array: any[] = a;

      const uniqueData: any[] = Array.from(new Set(array.map(mapper)));

      const isUnique = array.length === uniqueData.length;

      if (isUnique) {
        return true;
      }

      let index = array.findIndex(
        (value: string, i: number) =>
          value.toLowerCase() !== uniqueData[i]?.toLowerCase()
      );

      if (index === -1) {
        return true;
      }

      return this.createError({
        path: `${this.path}[${index}]`,
        message,
      });
    });
  }
);
