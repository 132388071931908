import { VehicleProvider } from '../../modules/vehicle/hooks/vehicle';
import { FabButton, IFabButtonProps } from './FabButton';
import { PlanningForm } from './PlanningForm';

interface IProps extends IFabButtonProps {}

export const CreatePlanning = (props: IProps) => {
  return (
    <>
      <FabButton {...props} />

      <VehicleProvider>
        <PlanningForm />
      </VehicleProvider>
    </>
  );
};
