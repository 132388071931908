import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { VehicleProvider } from '../hooks/vehicle';
import { VehicleList } from '../pages/VehicleList';
import { VehicleForm } from '../pages/VehicleForm';
import { VehicleTypeProvider } from '../../vehicle-type/hooks/vehicle-type';
import { UserManagementProvider } from '../../user-management/hooks/user-management';
import { DeliveryRegionProvider } from '../../delivery-region/hooks/delivery-region';

export const VehicleRoute = () => (
  <VehicleTypeProvider>
    <UserManagementProvider>
      <DeliveryRegionProvider>
        <VehicleProvider>
          <Switch>
            <PrivateRoute
              path={ROUTES.vehicleList}
              exact
              permission={PERMISSIONS.VEHICLE_VIEW}
              component={VehicleList}
            />
            <PrivateRoute path={ROUTES.createVehicle} component={VehicleForm} />
            <PrivateRoute path={ROUTES.updateVehicle} component={VehicleForm} />
          </Switch>
        </VehicleProvider>
      </DeliveryRegionProvider>
    </UserManagementProvider>
  </VehicleTypeProvider>
);
