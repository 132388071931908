import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  customMarker: {
    '& div.marker-pin': {
      width: 30,
      height: 30,
      border: '1px #686868 solid',
      borderRadius: '50% 50% 50% 0',
      background: '#0088d7',
      position: 'absolute',
      transform: 'rotate(-45deg)',
      left: '50%',
      top: '50%',
      margin: '-15px 0 0 -15px',
    },

    '& div.marker-pin::after': {
      content: '""',
      width: 24,
      height: 24,
      margin: '2px 0 0 2px',
      background: '#F5F5F5',
      border: '1px #686868 solid',
      position: 'absolute',
      borderRadius: '50%',
    },

    '& b': {
      position: 'absolute',
      width: 22,
      fontSize: 16,
      left: 0,
      right: 0,
      margin: '9px auto',
      textAlign: 'center',
    },

    '& i': {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: '5.5px auto',
      textAlign: 'center',
      '& svg': {
        width: 12,
        height: 'auto',
        transform: 'rotate(60deg)',
      },
    },
  },
});
