import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { AsyncSelect } from '../../../../components/AsyncSelect';
import { Button } from '../../../../components/Button';
import { Main } from '../../../../components/Main';
import { ROUTES } from '../../../../constants';
import { useAuth } from '../../../../hooks/auth';
import { useReasonOccurrence } from '../../hooks/reason-occurrence';
import { Form, useStyles } from './styles';

interface IParams {
  occurrenceId?: string;
}

export const ReasonOccurrenceForm = () => {
  const history = useHistory();

  const classes = useStyles();
  const { occurrenceId } = useParams<IParams>();
  const {
    loadReasonOccurrenceById,
    createReasonOccurrence,
    updateReasonOccurrence,
  } = useReasonOccurrence();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingOccurrence, setLoadingOccurrence] = useState(true);
  const [data, setData] = useState({
    reason: '',
    description: '',
  });

  useEffect(() => {
    async function loadOccurrence(): Promise<void> {
      try {
        if (occurrenceId) {
          const response = await loadReasonOccurrenceById(
            parseInt(occurrenceId)
          );

          if (!response) return;

          setData(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingOccurrence(false);
      }
    }

    loadOccurrence();
  }, [loadReasonOccurrenceById, occurrenceId]);

  const validationSchema = yup.object({
    reason: yup.string().required('Motivo é obrigatório'),
    description: yup.string().required('Descrição é obrigatória'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        if (occurrenceId) {
          await updateReasonOccurrence(parseInt(occurrenceId), values);
        } else {
          await createReasonOccurrence(values);
        }
        history.push(ROUTES.reasonOccurrenceList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const subtitle = useMemo(() => {
    if (occurrenceId) {
      return 'Alterar Ocorrência de Razão';
    } else {
      return 'Cadastrar Ocorrência de Razão';
    }
  }, [occurrenceId]);

  const submitButtonTitle = useMemo(() => {
    if (occurrenceId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [occurrenceId]);

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.reasonOccurrenceList}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {occurrenceId && loadingOccurrence ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Motivo"
                      id="reason"
                      name="reason"
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.reason && Boolean(formik.errors.reason)
                      }
                      helperText={formik.touched.reason && formik.errors.reason}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={5}
                      label="Descrição"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    {submitButtonTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
