import {
  AppBar as AppBarMaterial,
  Toolbar,
  IconButton,
  Typography,
  Hidden,
} from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useSideBar } from '../../hooks/sideBar';

import { useStyles } from './styles';
import { useAuth } from '../../hooks/auth';
import { useEffect, useState } from 'react';

export const AppBar = () => {
  const {
    mediaUp600px,
    isSideBarDesktopOpen,
    handleSideBarToggleDesktop,
    handleSideBarToggleMobile,
  } = useSideBar();
  const classes = useStyles();
  const {
    signOut,
    data: { user },
  } = useAuth();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const name = user.name.split(' ')[0];

    setUserName(name.toUpperCase());
  }, [user.name]);

  return (
    <AppBarMaterial
      position="absolute"
      className={clsx(
        classes.appBar,
        mediaUp600px && isSideBarDesktopOpen && classes.appBarShift
      )}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden smUp implementation="css">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleSideBarToggleMobile}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Hidden xsDown implementation="css">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="toggle drawer"
            onClick={handleSideBarToggleDesktop}
            className={clsx(
              classes.menuButton,
              isSideBarDesktopOpen && classes.hide
            )}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="toggle drawer"
            onClick={handleSideBarToggleDesktop}
            className={clsx(
              classes.menuButton,
              !isSideBarDesktopOpen && classes.hide
            )}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Hidden>

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          align="right"
          noWrap
          className={classes.title}
        >
          Olá, {userName}
        </Typography>

        <IconButton
          edge="end"
          color="inherit"
          aria-label="toggle drawer"
          onClick={signOut}
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBarMaterial>
  );
};
