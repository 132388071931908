import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';

import { cnpjValidation, maskCnpj, unmask } from '../../../../utils';
import { useSignUp, ILoadCompany } from '../../hooks/signUp';

import { Form, Container } from './styles';

export const WorkCompanyForm = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const { setActiveStep, getCompany, setDataProfile } = useSignUp();
  const [companyData, setCompanyData] = useState<ILoadCompany>({
    documentNumber: '',
    companyLegalName: '',
  });

  const COMPANY_4INNOVATION = '37229719000109';

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setCompanyData({
      documentNumber: '',
      companyLegalName: '',
    });
    setOpenDialog(false);
  }, []);

  const validationSchema = yup.object({
    documentNumberWorkCompany: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      documentNumberWorkCompany: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleOpenDialog();
    },
  });

  const handleChangeCompanyDocumentValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const unmakedValue = unmask(maskCnpj(value));

      formik.setFieldValue(name, unmakedValue);

      const documentNumberLenght = 14;
      let isCnpjValid = false;

      if (unmakedValue.length === documentNumberLenght) {
        isCnpjValid = cnpjValidation(unmakedValue);
      } else {
        isCnpjValid = false;
      }

      if (isCnpjValid) {
        const companyInfo = await getCompany(parseInt(unmakedValue));

        try {
          if (companyInfo) {
            setCompanyData(companyInfo);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [formik, getCompany]
  );

  return (
    <Container>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle>
          {cnpjValidation(formik.values.documentNumberWorkCompany) ? (
            companyData.companyLegalName ? (
              <div>
                <span>
                  Deseja se cadastrar na empresa{' '}
                  <strong>{companyData.companyLegalName}</strong> ?
                </span>
              </div>
            ) : (
              <div>
                <strong>Empresa não cadastrada no sistema.</strong>
              </div>
            )
          ) : (
            <span>Informe um CNPJ válido!</span>
          )}
        </DialogTitle>
        <DialogActions>
          {companyData.companyLegalName ? (
            <>
              <Button onClick={handleCloseDialog} color="inherit">
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setActiveStep(2);
                  setDataProfile((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      companyDocument: companyData.documentNumber,
                    },
                  }));
                }}
              >
                Sim
              </Button>
            </>
          ) : (
            <Button onClick={handleCloseDialog} color="inherit">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Form onSubmit={formik.handleSubmit}>
        <Typography variant="body1" align="left" style={{ marginTop: '12px' }}>
          <strong>Dados da Empresa Contratante</strong>
        </Typography>

        <Typography
          variant="subtitle1"
          align="left"
          style={{ marginBottom: '8px' }}
        >
          Digite o CNPJ da empresa que deseja se cadastrar.
        </Typography>

        <Grid item xs={12} sm={5}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="CNPJ"
            id="documentNumberWorkCompany"
            name="documentNumberWorkCompany"
            autoFocus
            value={maskCnpj(formik.values.documentNumberWorkCompany)}
            onChange={handleChangeCompanyDocumentValue}
            error={
              formik.touched.documentNumberWorkCompany &&
              Boolean(formik.errors.documentNumberWorkCompany)
            }
            helperText={
              formik.touched.documentNumberWorkCompany &&
              formik.errors.documentNumberWorkCompany
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 18,
          }}
        >
          <Button
            color="inherit"
            onClick={() => setActiveStep((prevState) => prevState - 1)}
          >
            Voltar
          </Button>

          <Grid>
            <Button
              color="secondary"
              onClick={() => {
                setActiveStep(2);
                setDataProfile((prevState) => ({
                  ...prevState,
                  user: {
                    ...prevState.user,
                    companyDocument: COMPANY_4INNOVATION,
                  },
                }));
              }}
            >
              Pular
            </Button>

            <Button type="submit" variant="contained" color="secondary">
              Avançar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};
