import clsx from 'clsx';
import { useManualOrder } from '../../../hooks/manual-order';
import { useStyles } from './styles';

const OptionButtons = () => {
  const classes = useStyles();
  const { option, setOption } = useManualOrder();

  return (
    <div className={classes.buttons}>
      <div
        className={clsx(option === 0 && 'active')}
        onClick={() => setOption(0)}
      >
        Existente
      </div>
      <div
        className={clsx(option === 1 && 'active')}
        onClick={() => setOption(1)}
      >
        Cadastrar
      </div>
    </div>
  );
};

export default OptionButtons;
