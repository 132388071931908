import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { UserManagementList } from '../pages/UserManagementList';
import { UserManagementForm } from '../pages/UserManagementForm';
import { UserManagementProvider } from '../hooks/user-management';

export const UserManagementRoute = () => (
  <UserManagementProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.userManagementList}
        exact
        permission={PERMISSIONS.USER_VIEW}
        component={UserManagementList}
      />
      <PrivateRoute path={ROUTES.createUser} component={UserManagementForm} />
      <PrivateRoute path={ROUTES.updateUser} component={UserManagementForm} />
    </Switch>
  </UserManagementProvider>
);
