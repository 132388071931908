import React from 'react';
import Slider, { Settings } from 'react-slick';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

import { IActivity } from '../../../hooks/order';

import { useStyles } from './styles';

interface IProps {
  open: boolean;
  activity: Pick<IActivity, 'reason' | 'images'> & {route: {routerName: string}} | null;
  onClose: () => void;
}

const Arrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <div
      id="arrow"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

export const ImageList: React.FC<IProps> = ({ open, activity, onClose }) => {
  const classes = useStyles();
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: classes.slider,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="image-list"
    >
      {activity && (
        <>
          <DialogTitle id="image-list">
            Fotos da Ocorrência - {activity.route.routerName}
          </DialogTitle>
          <DialogContent>
            {activity.reason && (
              <Typography variant="body2">
                <strong>Motivo:</strong> {activity.reason}
              </Typography>
            )}

            <Slider {...settings}>
              {activity.images.map((value, index) => {
                const img = new Image();
                img.src = value.image;
                const imgIsVertical = img.height > img.width;

                return (
                  <div key={value.id}>
                    <img
                      src={value.image}
                      alt={`imagem-${index}`}
                      className={
                        imgIsVertical
                          ? classes.imgVertical
                          : classes.imgHorizontal
                      }
                    />
                  </div>
                );
              })}
            </Slider>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
