import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Button } from '../../../../components/Button';
import { Main } from '../../../../components/Main';
import { ROUTES } from '../../../../constants';
import { useAuth } from '../../../../hooks/auth';
import { getAddress } from '../../../../services/viaCep';
import {
  getPermissionsProfileLabel,
  maskCnpj,
  maskCpf,
  maskPostalcode,
  maskTelephone,
  unmask,
} from '../../../../utils';
import {
  IPermission,
  IPermissionProfile,
  IUserPermission,
  IUserRequest,
  useUserManagement,
} from '../../hooks/user-management';
import { getValidationSchema } from './schema';
import { Form, useStyles } from './styles';

function not(a: IPermission[], b: IPermission[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: IPermission[], b: IPermission[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: IPermission[], b: IPermission[]) {
  return [...a, ...not(b, a)];
}

export const UserManagementForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { userId } = useParams<{
    userId?: string;
  }>();
  const typeValidationSchema = userId ? 'update' : 'create';
  const {
    data: { user },
  } = useAuth();
  const {
    userManagementStatus,
    loadUserById,
    createUser,
    updateUser,
    loadPermissions,
    loadPermissionsProfile,
  } = useUserManagement();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [allPermissions, setAllPermissions] = useState<IPermission[]>([]);
  const [profileTypes, setProfileTypes] = useState<IPermissionProfile[]>([]);
  const [userPermissions, setUserPermissions] = useState<IUserPermission[]>([]);
  const [data, setData] = useState<IUserRequest>({
    email: '',
    name: '',
    cpf: '',
    type: 'USER',
    postalcode: '',
    number: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
    country: 'BR',
    complement: '',
    phone: '',
    status: 'active',
    isNotified: false,
    companyDocument: user.companyDocument,
    permissions: [],
  });
  const [checked, setChecked] = useState<IPermission[]>([]);
  const [left, setLeft] = useState<IPermission[]>([]);
  const [right, setRight] = useState<IPermission[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
      emailConfirmation: data.email,
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: getValidationSchema(typeValidationSchema),
    onSubmit: async (values) => {
      setLoadingButton(true);

      const formData = {
        email: values.email,
        name: values.name,
        cpf: values.cpf,
        type: values.type,
        postalcode: values.postalcode,
        number: values.number,
        street: values.street,
        neighborhood: values.neighborhood,
        city: values.city,
        state: values.state,
        country: values.country,
        complement: values.complement,
        phone: values.phone,
        companyDocument: values.companyDocument,
        status: values.status,
        isNotified: values.isNotified,
        permissions: right.map((p) => {
          return {
            permission: p.code,
          };
        }),
      };

      try {
        if (userId) {
          await updateUser(parseInt(userId), formData);
        } else {
          await createUser({
            ...formData,
            password: values.password,
          });
        }

        history.push(ROUTES.userManagementList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  useEffect(() => {
    async function loadUser(): Promise<void> {
      try {
        if (userId) {
          const response = await loadUserById(parseInt(userId));

          if (!response) return;

          setData({
            ...response,
            permissions: [],
          });

          setUserPermissions(
            response.permissions.map((p) => ({
              permission: p.permission,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUser(false);
      }
    }

    loadUser();
  }, [loadUserById, userId]);

  useEffect(() => {
    async function loadPermissionsOfUser(): Promise<void> {
      try {
        const response = await loadPermissions();

        if (!response) return;

        setAllPermissions(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadPermissionsOfUser();
  }, [loadPermissions]);

  useEffect(() => {
    async function loadPermissionsOfProfile(): Promise<void> {
      try {
        const response = await loadPermissionsProfile();

        if (!response) return;

        setProfileTypes(response);

        const rights: IPermission[] = [];

        if (userPermissions.length > 0) {
          userPermissions.forEach((p) => {
            const permission = allPermissions.find(
              (r) => r.code === p.permission
            );

            if (permission && permission.code) {
              rights.push({
                code: permission.code,
                description: permission?.description,
              });
            }
          });
        } else {
          response
            .find((profile) => profile.type === 'USER')!
            .permissions.forEach((permission) => {
              rights.push(permission);
            });
        }
        setRight(rights);

        const lefts = allPermissions.filter(
          (l) => !rights.some((r) => l.code === r.code)
        );
        setLeft(lefts);
      } catch (error) {
        console.log(error);
      }
    }

    if (allPermissions.length > 0) loadPermissionsOfProfile();
  }, [allPermissions, loadPermissionsProfile, userId, userPermissions]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleClickShowPasswordConfirmation = useCallback(() => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  }, [showPasswordConfirmation]);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleChangePostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);

      const postalcodeLength = 8;

      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf } = address;

          if (logradouro) {
            formik.setFieldValue('street', logradouro);
          }

          if (bairro) {
            formik.setFieldValue('neighborhood', bairro);
          }

          if (localidade) {
            formik.setFieldValue('city', localidade);
          }

          if (uf) {
            formik.setFieldValue('state', uf);
          }
        }
      }
    },
    [formik]
  );

  const handleChangeValueWithMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      let unmaskedValue = '';

      if (name === 'cpf') {
        if (value.length <= 11) {
          unmaskedValue = unmask(maskCpf(value));
        } else unmaskedValue = unmask(maskCnpj(value));
      } else if (name === 'phone') unmaskedValue = unmask(maskTelephone(value));

      formik.setFieldValue(name, unmaskedValue);
    },
    [formik]
  );

  const handleChangeProfile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      formik.setFieldValue(name, value);

      const profileSelected = profileTypes.find((p) => p.type === value)!;

      const permissionsSelected = profileSelected.permissions.map(
        (permission) => ({
          ...permission,
        })
      );
      setRight(permissionsSelected);

      const permissionsNotSelected = allPermissions.filter(
        (left) => !permissionsSelected.some((r) => left.code === r.code)
      );
      setLeft(permissionsNotSelected);
    },
    [allPermissions, formik, profileTypes]
  );

  const handleToggle = useCallback(
    (value: IPermission) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked]
  );

  const numberOfChecked = useCallback(
    (items: IPermission[]) => intersection(checked, items).length,
    [checked]
  );

  const handleToggleAll = useCallback(
    (items: IPermission[]) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    },
    [checked, numberOfChecked]
  );

  const handleCheckedRight = useCallback(() => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  }, [checked, leftChecked, right, left]);

  const handleCheckedLeft = useCallback(() => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  }, [checked, rightChecked, right, left]);

  const subtitle = useMemo(() => {
    if (userId) {
      return 'Alterar usuário';
    } else {
      return 'Cadastrar usuário';
    }
  }, [userId]);

  const submitButtonTitle = useMemo(() => {
    if (userId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [userId]);

  const documentNumberInputLabel = useMemo(() => {
    if (formik.values.type !== 'FINAL_USER') {
      if (formik.values.cpf.length < 11) {
        return 'CPF / CNPJ';
      } else if (formik.values.cpf.length === 11) {
        return 'CPF';
      }
    }

    return 'CNPJ';
  }, [formik.values]);

  const customList = (title: React.ReactNode, items: IPermission[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || formik.values.type === 'FINAL_USER'}
            inputProps={{ 'aria-label': 'all items selected' }}
            color="secondary"
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: IPermission, index) => {
          const labelId = `transfer-list-all-item-${value.code}-label`;

          return (
            <ListItem
              key={`${value.code}-${index}`}
              role="listitem"
              button
              onClick={handleToggle(value)}
              disabled={formik.values.type === 'FINAL_USER'}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${value.description}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.userManagementList}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {userId && loadingUser ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      autoFocus
                      label="Nome completo"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Status"
                      id="status"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      helperText={formik.touched.status && formik.errors.status}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {userManagementStatus.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Celular"
                      id="phone"
                      name="phone"
                      value={maskTelephone(formik.values.phone)}
                      onChange={handleChangeValueWithMask}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label={documentNumberInputLabel}
                      id="cpf"
                      name="cpf"
                      autoFocus
                      value={
                        formik.values.cpf.length <= 11
                          ? maskCpf(formik.values.cpf)
                          : maskCnpj(formik.values.cpf)
                      }
                      onChange={handleChangeValueWithMask}
                      error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                      helperText={formik.touched.cpf && formik.errors.cpf}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="CEP"
                      id="postalcode"
                      name="postalcode"
                      value={maskPostalcode(formik.values.postalcode)}
                      onChange={handleChangePostalcodeValue}
                      error={
                        formik.touched.postalcode &&
                        Boolean(formik.errors.postalcode)
                      }
                      helperText={
                        formik.touched.postalcode && formik.errors.postalcode
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Rua"
                      id="street"
                      name="street"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.street && Boolean(formik.errors.street)
                      }
                      helperText={formik.touched.street && formik.errors.street}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Bairro"
                      id="neighborhood"
                      name="neighborhood"
                      value={formik.values.neighborhood}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.neighborhood &&
                        Boolean(formik.errors.neighborhood)
                      }
                      helperText={
                        formik.touched.neighborhood &&
                        formik.errors.neighborhood
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Número"
                      id="number"
                      name="number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Cidade"
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Estado"
                      id="state"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={formik.touched.state && formik.errors.state}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Complemento"
                      id="complement"
                      name="complement"
                      value={formik.values.complement}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.complement &&
                        Boolean(formik.errors.complement)
                      }
                      helperText={
                        formik.touched.complement && formik.errors.complement
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Email"
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      autoComplete="off"
                      label="Confirmação do Email"
                      id="emailConfirmation"
                      name="emailConfirmation"
                      value={formik.values.emailConfirmation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.emailConfirmation &&
                        Boolean(formik.errors.emailConfirmation)
                      }
                      helperText={
                        formik.touched.emailConfirmation &&
                        formik.errors.emailConfirmation
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  {!userId && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          label="Senha"
                          id="password"
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          autoComplete="off"
                          label="Confirmação da Senha"
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          type={showPasswordConfirmation ? 'text' : 'password'}
                          value={formik.values.passwordConfirmation}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.passwordConfirmation &&
                            Boolean(formik.errors.passwordConfirmation)
                          }
                          helperText={
                            formik.touched.passwordConfirmation &&
                            formik.errors.passwordConfirmation
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordConfirmation}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPasswordConfirmation ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Tipo"
                      id="type"
                      name="type"
                      value={formik.values.type}
                      onChange={handleChangeProfile}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Selecione o tipo</em>
                      </MenuItem>
                      {profileTypes.map((permission) => (
                        <MenuItem key={permission.type} value={permission.type}>
                          {getPermissionsProfileLabel(permission.type)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={2}/>
                  <Grid item xs={12} sm={6}>
                  <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="isNotified"
                            name="isNotified"
                            value={formik.values.isNotified}
                            onChange={formik.handleChange}
                            checked={formik.values.isNotified}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                             Notificar
                          </Typography>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        marginBottom: 20,
                        fontSize: 20,
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',
                      }}
                    >
                      Suas Permissões
                    </Typography>
                    <Grid
                      container
                      spacing={5}
                      justifyContent="center"
                      alignItems="center"
                      className={classes.root}
                    >
                      <Grid item>{customList('Permissões', left)}</Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                            color="inherit"
                          >
                            &gt;
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                            color="inherit"
                          >
                            &lt;
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {customList('Permissões Adicionadas', right)}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        loading={loadingButton}
                      >
                        {submitButtonTitle}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
