import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  @media only screen and (min-width: 690px) {
    width: 600px;
  }
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 12px;
`;
