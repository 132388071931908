import {
  Container as MuiContainer,
  createStyles,
  makeStyles,
  Paper as MuiPaper,
  Theme,
} from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(MuiContainer)`
  padding: 32px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Paper = styled(MuiPaper)`
  position: relative;
  padding: 16px;
`;

export const AddButton = styled.button`
  position: relative;
  width: 100%;
  padding: 8px;
  border: 0 solid;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;

  line-height: 0;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;

  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #fff;
    box-shadow: inset 60em 0 0 0 #570864;

    @media screen and (max-width: 992px) {
      box-shadow: inset 55em 0 0 0 #570864;
    }

    @media screen and (max-width: 600px) {
      box-shadow: inset 40em 0 0 0 #570864;
    }
  }
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      // justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);
