import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import logoImg from '../../../assets/logo-black.png';
import { IOrderReport } from '../pages/Order';

function hexToAscii(code: string) {
  try {
    let hex = code.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  } catch (error) {
    return code;
  }
}

function minutesToTime(allMinutes: number) {
  const hours = Math.floor(allMinutes / 60);
  const minutes = allMinutes % 60;

  return `${hours ? hours + 'h ' : ''}${minutes + 'm'}`;
}

const docDefinition = (
  img: string,
  data: IOrderReport[],
  shouldShowProducts: boolean,
  geocodeChanged: boolean
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Pedidos',
    author: '4Innovation',
    creationDate: new Date(),
  },
  pageOrientation: 'portrait',
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              border: [false, false, false, false],
              columns: [
                [
                  {
                    text: 'Relatório de Pedidos',
                    bold: true,
                    alignment: 'right',
                    fontSize: 14,
                    margin: [0, 17, 0, 0],
                  },
                  {
                    text: `Total: ${data.length}`,
                    bold: true,
                    alignment: 'right',
                    fontSize: 14,
                    margin: [0, 8, 0, 8],
                  },
                ],
              ],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },

    data.map((order, index) => {
      const orderInfo = [
        {
          margin: [0, 15, 0, 0],
          text: order.number,
          fontSize: 8,
          alignment: 'center',
          border: [true, true, true, false],
        },
        {
          margin: [0, 15, 0, 0],
          text: order.orderDate,
          fontSize: 8,
          alignment: 'center',
          border: [true, true, true, false],
        },
        {
          margin: [0, -3, 0, -3],
          border: [true, true, true, false],
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                [
                  {
                    text: order.customer.name,
                    fontSize: 8,
                    alignment: 'left',
                    bold: true,
                  },
                ],
              ],
              [
                [
                  {
                    text: (({ shippingAddress }) =>
                      `${shippingAddress.street}, ${shippingAddress.number}, ${shippingAddress.complement} - ${shippingAddress.neighborhood} - CEP: ${shippingAddress.postalcode}`.toUpperCase())(
                      order
                    ),
                    fontSize: 8,
                    alignment: 'left',
                  },
                ],
              ],
              [
                [
                  {
                    text: (({ shippingAddress }) =>
                      `${shippingAddress.city} - ${shippingAddress.state}`.toUpperCase())(
                      order
                    ),
                    fontSize: 8,
                    alignment: 'left',
                  },
                ],
              ],
            ],
          },
        },
        {
          margin: [-5, -3, -5, -4],
          border: [true, true, true, false],
          table: {
            widths: ['*'],
            body: [
              [
                {
                  margin: [-5, -3, -5, -3],
                  border: [true, true, true, false],
                  table: {
                    widths: [64, 44, 52],
                    body: [
                      [
                        {
                          text: order.statusCode,
                          fontSize: 8,
                          alignment: 'center',
                          border: [true, false, true, false],
                        },

                        {
                          text: order.items.length,
                          fontSize: 8,
                          alignment: 'center',
                          border: [true, false, true, false],
                        },

                        {
                          text: order.riskArea ? 'Sim' : 'Não',
                          fontSize: 8,
                          alignment: 'center',
                          border: [true, false, true, false],
                        },
                      ],
                    ],
                  },
                },
              ],
              [
                {
                  margin: [-5, 0, -5, 0],
                  table: {
                    widths: ['*'],
                    body: [
                      [
                        {
                          text: `Tempo de Operação`,
                          fillColor: '#f1f0f3',
                          fontSize: 8,
                          alignment: 'center',
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],
                          columns: [
                            {
                              text: `Operação:
                              ${minutesToTime(order.operationTime)}`,
                              fontSize: 8,
                              alignment: 'center',
                            },
                            {
                              text: `Entrega:
                              ${minutesToTime(order.deliveryTime)}`,
                              fontSize: 8,
                              alignment: 'center',
                            },
                            {
                              text: `Total:
                              ${minutesToTime(order.totalOperatingTime)}`,
                              fontSize: 8,
                              alignment: 'center',
                            },
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
        },
      ];

      return [
        {
          table: {
            widths: [56, 56, '*', 178],
            body:
              index === 0 || shouldShowProducts
                ? [
                    [
                      {
                        text: `Pedido`,
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, true, true, true],
                      },
                      {
                        text: 'Data do Pedido',
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, true, true, true],
                      },
                      {
                        text: 'Cliente',
                        fillColor: '#CCC',
                        fontSize: 8,
                        alignment: 'center',
                        border: [true, true, true, true],
                      },
                      {
                        margin: [-5, -3, -5, -3],
                        table: {
                          widths: [64, 44, 52],
                          body: [
                            [
                              {
                                text: `Status`,
                                fillColor: '#CCC',
                                fontSize: 8,
                                alignment: 'center',
                                border: [true, true, true, false],
                              },
                              {
                                text: `Quantidade`,
                                fillColor: '#CCC',
                                fontSize: 8,
                                alignment: 'center',
                                border: [true, true, true, false],
                              },
                              {
                                text: `Área de Risco`,
                                fillColor: '#CCC',
                                fontSize: 8,
                                alignment: 'center',
                                border: [true, true, true, false],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                    orderInfo,
                  ]
                : [orderInfo],
            dontBreakRows: true,
          },
        },
        // geocodeChanged
        //   ? {
        //       margin: [0, 0, 0, 0],
        //       table: {
        //         widths: ['auto'],
        //         body: [
        //           [
        //             {
        //               text: { text: '', bold: true },
        //               fontSize: 8,
        //               alignment: 'center',
        //             },
        //           ],
        //           [order.changedToLastExecutionDescription],
        //         ],
        //       },
        //     }
        //   : [],
        shouldShowProducts
          ? {
              margin: [0, 0, 0, 5],
              table: {
                widths: ['auto', '*', 'auto', 'auto', 'auto'],
                body: [
                  [
                    {
                      text: { text: 'Sku', bold: true },
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: { text: 'Descrição', bold: true },
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: { text: 'Barcode', bold: true },
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: { text: 'Código Lido', bold: true },
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: { text: 'Qtd.', bold: true },
                      fontSize: 8,
                      alignment: 'center',
                    },
                  ],
                  ...order.items.map((item) => [
                    {
                      text: item.sku,
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: item.description,
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: item.barcode,
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: hexToAscii(item.tagRead?.codeRead || ''),
                      fontSize: 8,
                      alignment: 'center',
                    },
                    {
                      text: item.quantity,
                      fontSize: 8,
                      alignment: 'center',
                    },
                  ]),
                ],
              },
            }
          : [],
      ];
    }),
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

export async function generateOrderPDF(
  data: IOrderReport[],
  shouldShowProducts: boolean,
  geocodeChanged: boolean
) {
  const image = document.createElement('img');

  image.src = logoImg;

  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake
      .createPdf(
        docDefinition(base64, data, shouldShowProducts, geocodeChanged)
      )
      .open();
  }
}
