import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { StoragePositionProvider } from '../hooks/storage-position';
import { StoragePositionList } from '../pages/StoragePositionList';
import { StoragePositionForm } from '../pages/StoragePositionForm';

export const StoragePositionRoute: React.FC = () => (
  <StoragePositionProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.storagePositionList}
        exact
        permission={PERMISSIONS.STORE_POSITION_VIEW}
        component={StoragePositionList}
      />
      <PrivateRoute
        path={ROUTES.createStoragePosition}
        component={StoragePositionForm}
      />
      <PrivateRoute
        path={ROUTES.updateStoragePosition}
        component={StoragePositionForm}
      />
    </Switch>
  </StoragePositionProvider>
);
