import { useCallback } from 'react';
import { Typography, SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { lighten } from 'polished';

import LocalShipping from '@material-ui/icons/LocalShipping';
import TwoWheeler from '@material-ui/icons/TwoWheeler';

import { TypeEnum, useSignUp } from '../../hooks/signUp';

import { Container, StyledButton } from './styles';

export const SelectProfileType = () => {
  const theme = useTheme();
  const { setActiveStep, setProfileType } = useSignUp();

  const handleSelectProfile = useCallback(
    (profile: TypeEnum) => {
      if (profile === TypeEnum.AUTONOMOUS || profile === TypeEnum.MOTOBOY) {
        setActiveStep(1);
        setProfileType(profile);
      } else {
        setActiveStep(2);
        setProfileType(profile);
      }
    },
    [setActiveStep, setProfileType]
  );

  return (
    <Container>
      <Typography variant="h4" align="left">
        <strong>CADASTRO</strong>
      </Typography>

      <Typography variant="body1" align="left">
        <strong>Selecione seu perfil</strong>
      </Typography>

      <StyledButton
        variant="contained"
        size="large"
        onClick={() => handleSelectProfile(TypeEnum.AUTONOMOUS)}
        bgcolor={lighten(0.05, theme.palette.secondary.main)}
        fullWidth
        style={{ marginTop: '16px' }}
      >
        <LocalShipping fontSize="large" style={{ marginRight: '8px' }} />
        <strong>Sou Autônomo</strong>
      </StyledButton>

      <StyledButton
        variant="contained"
        size="large"
        onClick={() => handleSelectProfile(TypeEnum.MOTOBOY)}
        bgcolor={lighten(0.1, theme.palette.secondary.main)}
        fullWidth
      >
        <TwoWheeler fontSize="large" style={{ marginRight: '8px' }} />
        <strong>Sou Motoboy</strong>
      </StyledButton>

      <StyledButton
        variant="contained"
        size="large"
        onClick={() => handleSelectProfile(TypeEnum.SHIPPER)}
        bgcolor={lighten(0.15, theme.palette.secondary.main)}
        fullWidth
      >
        <SvgIcon
          viewBox="0 0 43.054 43"
          fontSize="large"
          style={{ marginRight: '8px' }}
        >
          <path
            d="M.009,0V5.375h43V0Zm0,10.75V42.516A.482.482,0,0,0,.493,43H42.471a.482.482,0,0,0,.484-.484V10.75H26.991v5.536H16.08V10.75H-.045Z"
            transform="translate(0.045)"
          />
        </SvgIcon>
        <strong>Sou Embarcador</strong>
      </StyledButton>

      <StyledButton
        variant="contained"
        size="large"
        onClick={() => handleSelectProfile(TypeEnum.SHIPPING_COMPANY)}
        bgcolor={lighten(0.2, theme.palette.secondary.main)}
        fullWidth
      >
        <SvgIcon
          viewBox="0 0 55.276 44.221"
          fontSize="large"
          style={{ marginRight: '8px' }}
        >
          <path d="M4.336,32.44a1.385,1.385,0,0,0,1.693.976l18.69-5.009a1.385,1.385,0,0,0,.976-1.693L21.4,10.692a1.385,1.385,0,0,0-1.693-.976l-6.668,1.8,2.142,8.006L9.846,20.944,7.7,12.938,1.028,14.726a1.385,1.385,0,0,0-.976,1.693ZM33.166,0A2.761,2.761,0,0,0,30.4,2.764V30.713L.51,38.866a.687.687,0,0,0-.484.846l1.088,4a.687.687,0,0,0,.846.484l34-9.276a9.659,9.659,0,0,0,19.312-.371V0ZM45.6,38.693a4.146,4.146,0,1,1,4.146-4.146A4.147,4.147,0,0,1,45.6,38.693Z" />
        </SvgIcon>
        <strong>Sou Transportadora</strong>
      </StyledButton>
    </Container>
  );
};
