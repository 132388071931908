import { format, parseISO } from 'date-fns';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import logoImg from '../../../assets/logo-black.png';
import { QuestionType } from '../../form/hooks/form';
import { FilledForm } from '../hooks/form';

const imageGridGenerator = (arr: any[], size: number) => {
  const imgGridArr = [];
  for (let i = 0; i < size; i++) {
    imgGridArr.push(arr.filter((_, index) => index % size === i));
  }
  return imgGridArr;
};

const docDefinition = (
  img: string,
  data: FilledForm[]
): TDocumentDefinitions => ({
  info: {
    title: 'Relatório de Formulários Preenchidos',
    author: '4Innovation',
    creationDate: new Date(),
  },
  content: [
    {
      style: 'header',
      table: {
        widths: ['auto', '*'],
        body: [
          [
            {
              image: img,
              width: 150,
              height: 50,
              border: [false, false, false, false],
            },
            {
              text: 'Relatório de Formulários Preenchidos',
              bold: true,
              alignment: 'right',
              fontSize: 14,
              margin: [0, 17, 0, 0],
              border: [false, false, false, false],
            },
          ],
        ],
        layout: 'noBorders',
      },
    },
    {},
    ...(data.map(generateTable) as any),
  ],
  styles: {
    header: {
      fontSize: 32,
      bold: true,
      alignment: 'right',
      italics: true,
    },
  },
});

function generateTable(data: FilledForm) {
  const formTable = {
    margin: [0, 10, 0, 0],
    table: {
      widths: ['*', 'auto', 100, 100],
      body: [
        [
          {
            text: 'Usuário',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            fillColor: '#CCC',
          },
          {
            text: 'Formulário',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            fillColor: '#CCC',
          },
          {
            text: 'Total Questões',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            fillColor: '#CCC',
          },
          {
            text: 'Preenchido em',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            fillColor: '#CCC',
          },
        ],
        [
          {
            text: data.user.name,
            alignment: 'center',
            fontSize: 10,
          },
          {
            text: data.formConfig.title,
            alignment: 'center',
            fontSize: 10,
          },
          {
            text: data.data.length,
            alignment: 'center',
            fontSize: 10,
          },
          {
            text: format(parseISO(data.dateFilled), 'dd/MM/yyyy hh:mm'),
            alignment: 'center',
            fontSize: 8,
          },
        ],
      ],
    },
  };

  let teste = {};

  if (data.formConfig.process === 'activity' && data.activity) {
    teste = {
      margin: [0, 10, 0, 0],
      text: `Atividade: ${data.activity.number}`,
      alignment: 'justify',
      fontSize: 12,
    };
  } else if (data.formConfig.process === 'activity-item' && data.activityItem) {
    teste = {
      margin: [0, 10, 0, 0],
      text: `Atividade Item:  ${data.activityItem.code}`,
      alignment: 'justify',
      fontSize: 12,
    };
  } else if (data.formConfig.process === 'vehicle' && data.vehicle) {
    teste = {
      margin: [0, 10, 0, 0],
      text: `Veículo: ${data.vehicle.plate}`,
      alignment: 'justify',
      fontSize: 12,
    };
  }

  const questionsTable = {
    margin: [0, 10, 0, 0],
    table: {
      widths: [20, '*', '*'],
      body: [
        [
          {
            text: '',
            alignment: 'center',
            fillColor: '#CCC',
          },
          {
            text: 'Perguntas',
            alignment: 'center',
            fontSize: 12,
            bold: true,
            fillColor: '#CCC',
          },
          {
            text: 'Respostas',
            alignment: 'center',
            fontSize: 12,
            bold: true,
            fillColor: '#CCC',
          },
        ],
        ...data.data.map((question, index) => {
          return [
            {
              text: (++index).toString().padStart(2, '0'),
              alignment: 'center',
              fontSize: 12,
              bold: true,
            },
            {
              text: question.subject,
              alignment: 'justify',
              fontSize: 12,
            },
            [QuestionType.SIGNATURE, QuestionType.IMAGE].includes(question.type)
              ? question.value
                ? {
                    image: 'data:image/gif;base64,' + question.value,
                    fit: [100, 100],
                  }
                : {
                    columns: imageGridGenerator(
                      data.images
                        .filter((item) => question.id === item.questionId)
                        .map((img) => ({
                          alignment: 'center',
                          width: 70,
                          margin: 3,
                          image: img.image,
                          fit: [65, 65],
                        })),
                      3
                    ),
                  }
              : {
                  text:
                    question.type === QuestionType.CHECKBOX
                      ? question.valueCheckbox?.join(', ')
                      : question.value ?? '',
                  fontSize: 12,
                },
          ];
        }),
      ],
    },
  };

  return [formTable, teste, questionsTable];
}

export async function generateFormReportPDF(data: FilledForm[]) {
  const image = document.createElement('img');
  image.src = logoImg;
  const imgBlob = await fetch(image.src).then((res) => res.blob());

  const base64: string | ArrayBuffer | null = await new Promise(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(imgBlob);
    }
  );

  if (typeof base64 === 'string') {
    pdfMake.createPdf(docDefinition(base64, data)).open();
  }
}
