import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { ProductForm } from '../pages/ProductForm';
import { ProductList } from '../pages/ProductList';
import { ProductProvider } from '../hooks/product';
import { ManualOrderProvider } from '../../order/hooks/manual-order';

export const ProductRoute: React.FC = () => (
  <ProductProvider>
    <ManualOrderProvider>
      <Switch>
        <PrivateRoute
          path={ROUTES.productList}
          exact
          permission={PERMISSIONS.PRODUCT_VIEW}
          component={ProductList}
        />
        <PrivateRoute path={ROUTES.createProduct} component={ProductForm} />
        <PrivateRoute path={ROUTES.updateProduct} component={ProductForm} />
      </Switch>
    </ManualOrderProvider>
  </ProductProvider>
);
