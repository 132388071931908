import { format, subDays } from 'date-fns';
import React, {
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { OrderStatusCode } from '../../../constants';

import api from '../../../services/api';

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

export interface IOrder {
  id: number;
  orderDate: string;
  number: string;
  sender: {
    name: string;
  };
  deliveryPrevisionDate: string;
  items: {
    description: string;
    code: string;
    codeTranslate: string;
    price: string;
    barcode: string;
    sku: string;
    quantity: number;
    tagRead: {
      id: number;
      itemId: number;
      orderId: number;
      codeOrigin: string;
      codeRead: string;
    } | null;
  }[];
  customer: {
    name: string;
  };
  shippingAddress: {
    street: string;
    neighborhood: string;
    city: string;
    state: string;
    postalcode: string;
    number: number;
    complement: string;
  };
  shippingStatus: string;
  datePortalRelease: string;
  dateMarkAsDelivered: string;
  statusCode: OrderStatusCode;
  riskArea: boolean;
  changedToLastExecutionDescription: string;
  operationTime: number;
  deliveryTime: number;
  totalOperatingTime: number;
}

interface IOrderFilter {
  initialDate: string;
  finalDate: string;
  orderNumber?: string;
  statusCode?: string;
  operationType?: string;
  customerName?: string;
  riskArea?: boolean;
  geocodeChanged?: boolean;
}

interface IOrderContext {
  loadOrder(options?: IOrderFilter): Promise<IOrder[]>;
  filter: IOrderFilter;
  setFilter: React.Dispatch<React.SetStateAction<IOrderFilter>>;
}

const OrderContext = createContext<IOrderContext>({} as IOrderContext);

const OrderReportProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<IOrderFilter>({
    initialDate: formatDate(subDays(new Date(), 6)),
    finalDate: formatDate(new Date()),
    orderNumber: '',
    statusCode: '',
    operationType: '',
    customerName: '',
    riskArea: false,
    geocodeChanged: false,
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: formatDate(subDays(new Date(), 6)),
      finalDate: formatDate(new Date()),
      orderNumber: '',
      statusCode: '',
      operationType: '',
      customerName: '',
      riskArea: false,
      geocodeChanged: false,
    }));
  }, []);

  const loadOrder = useCallback(async (options: IOrderFilter) => {
    let query = '';

    const {
      initialDate,
      finalDate,
      orderNumber,
      statusCode,
      operationType,
      customerName,
      riskArea,
      geocodeChanged,
    } = options;

    query = `${initialDate && `?initialDate=${initialDate}`}${
      finalDate && `&finalDate=${finalDate}`
    }${orderNumber ? `&orderNumber=${orderNumber}` : ''}${
      statusCode ? `&statusCode=${statusCode}` : ''
    }${operationType ? `&operationType=${operationType}` : ''}${
      customerName ? `&customerName=${customerName}` : ''
    }${riskArea ? `&riskArea=${riskArea}` : ''}${
      geocodeChanged ? `&geocodeChanged=${geocodeChanged}` : ''
    }`;

    const response = await api.get(`/reports/orders${query}`);
    return response.data;
  }, []);

  return (
    <OrderContext.Provider
      value={{
        loadOrder,
        filter,
        setFilter,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

function useOrderReport(): IOrderContext {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used with in a OrderProvider');
  }

  return context;
}

export { OrderReportProvider, useOrderReport };
