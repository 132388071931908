import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,

    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      borderRadius: '15px 0 0 15px',
      '&:last-child': {
        borderRadius: '0 15px 15px 0',
      },
      border: '1.5px solid black',

      height: 35,
      width: 95,
      background: 'white',
      color: '#000',
      fontWeight: '500',
      fontSize: 14,
      textTransform: 'uppercase',
      transition: 'all 0.2s',

      '&:hover': {
        cursor: 'pointer',
      },
      '&:not(.active):hover': {
        filter: 'brightness(85%)',
      },
      '&.active': {
        background: '#01579b',
        color: '#fff',
      },
    },
  },
}));
