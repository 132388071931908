import { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { IColumn, Table } from '../../../../components/Table';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { useAuth } from '../../../../hooks/auth';
import { RoutePlanningInfo } from '../../components/RoutePlanningInfo';
import {
  useRoutePlanning,
  ILoadRoutePlanning,
} from '../../hooks/route-planning';

export const RoutePlanningList = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadRoutePlanningList, deleteRoutePlanning } = useRoutePlanning();
  const [routesPlanning, setRoutesPlanning] = useState<ILoadRoutePlanning[]>(
    []
  );
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [routePlanningId, setRoutePlanningId] = useState<number | null>(null);

  const columns: IColumn<ILoadRoutePlanning>[] = [
    { hide: true, field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome' },
    {
      field: 'planningStartTime',
      headerName: 'Hora de Início',
      align: 'right',
    },
    {
      field: 'deliveryStartTime',
      headerName: 'Início da Rota',
      align: 'right',
    },
    { field: 'deliveryEndTime', headerName: 'Término da Rota', align: 'right' },
    {
      field: 'mapsService',
      headerName: 'Serviço de Mapa',
      mask: (mapsService) =>
        mapsService === 'maplink' ? 'Maplink' : 'Open Maps',
    },
    {
      field: 'isDefault',
      headerName: 'Configuração Padrão',
      mask: (isDefault) => (isDefault ? 'Sim' : 'Não'),
    },
    {
      headerName: 'Ações',
      align: 'center',
      actions: {
        sendTo: 'routePlanningList',
        actionOpen: handleOpenView,
        actionDelete: handleOpenDialogDelete,
      },
    },
  ];

  useEffect(() => {
    async function loadRoutePlanning(): Promise<void> {
      try {
        const response = await loadRoutePlanningList(user.companyDocument);

        if (!response) return;

        setRoutesPlanning(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadRoutePlanning();
  }, [loadRoutePlanningList, user.companyDocument]);

  function handleOpenView(id: number) {
    setOpenView(true);
    setRoutePlanningId(id);
  }

  function handleOpenDialogDelete(id: number) {
    setOpenDialogDelete(true);
    setRoutePlanningId(id);
  }

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setRoutePlanningId(null);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setRoutePlanningId(null);
  }, []);

  const handleDeleteRoutePlanning = useCallback(async () => {
    if (routePlanningId) {
      await deleteRoutePlanning(routePlanningId);
      setRoutesPlanning((prevState) =>
        prevState.filter((rest) => rest.id !== routePlanningId)
      );
      setRoutePlanningId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteRoutePlanning, routePlanningId]);

  return (
    <Main
      title="Planejamento de Rotas"
      button="add"
      buttonName="Nova configuração"
      to={ROUTES.createRoutePlanning}
      hasPermission="PLANNING_CREATE"
    >
      <Grid container spacing={1}>
        <RoutePlanningInfo
          open={openView}
          routePlanningId={routePlanningId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar o planejamento de rota"
          description="Deseja realmente deletar este planejamento de rota?"
          onSubmit={handleDeleteRoutePlanning}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Table
            columns={columns}
            rows={routesPlanning}
            totalOfRows={routesPlanning.length}
            currentPage={page}
            handlePageChange={handlePageChange}
            loading={loading}
            hasEditPermission="PLANNING_EDIT"
            hasRemovePermission="PLANNING_REMOVE"
          />
        </Grid>
      </Grid>
    </Main>
  );
};
