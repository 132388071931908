import React from 'react';

import { AuthProvider } from './auth';
import { ModalProvider } from './modal';
import { SettingsProvider } from './settings';
import { SideBarProvider } from './sideBar';

export const AppProvider: React.FC = ({ children }) => {
  return (
    <ModalProvider>
      <SideBarProvider>
        <AuthProvider>
          <SettingsProvider>{children}</SettingsProvider>
        </AuthProvider>
      </SideBarProvider>
    </ModalProvider>
  );
};
