import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { lighten } from 'polished';

import { MdHistory } from 'react-icons/md';
import { Card } from '../../../../components/Card';
import { useSettings } from '../../../../hooks/settings';
import { useRoute } from '../../hooks/route';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const RouteHistoryCard = ({ onClick }: IProps) => {
  const { selectedPeriod, getDatePeriod } = useSettings();
  const { loadDaysWithBatches } = useRoute();
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [totalHistory, setTotalHistory] = useState(0);

  useEffect(() => {
    async function loadTotalHistory() {
      try {
        const { from, to } = getDatePeriod(selectedPeriod);
        const response = await loadDaysWithBatches({
          initialDate: from,
          finalDate: to,
        });

        if (!response) return;

        const total = response.reduce(
          (acc, cur) => parseInt(cur.quantity) + acc,
          0
        );

        setTotalHistory(total);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadTotalHistory();
  }, [getDatePeriod, loadDaysWithBatches, selectedPeriod]);

  return (
    <Card
      title="Histórico"
      content={totalHistory}
      icon={<MdHistory />}
      cardColor={lighten(0.325, theme.palette.secondary.main)}
      loading={loading}
      onClick={onClick}
    />
  );
};
