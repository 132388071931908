import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { whichClientCompanyIsTheDocument } from '../utils/customerCompanies';

declare module '@material-ui/core/styles' {
  interface Theme {
    gradient: { company: React.CSSProperties['background'] };
    setCompanyTheme: (company: string) => void;
  }
  interface ThemeOptions {
    gradient?: { company: React.CSSProperties['background'] };
    setCompanyTheme?: (company: string) => void;
  }
}

const companiesCustomTheme = {
  default: {
    primary: '#570864',
    secondary: '#01579b',
    gradient:
      'transparent linear-gradient(90deg, #01579BBF 0%, #3B2276CC 49%, #570864CC 100%) 0% 0% no-repeat padding-box',
  },

  ativa: {
    primary: '#0E52A0',
    secondary: '#48AE3D',
    gradient:
      'transparent linear-gradient(90deg, #0D52A0 0%, #0D52A0 100%) 0% 0% no-repeat padding-box',
    //'transparent linear-gradient(90deg, #48AE3D 0%, #0E52A0 49%, #7DA1C8 100%) 0% 0% no-repeat padding-box',
  },
};

export const theme = ({
  companyDocument,
  setCompanyTheme,
}: {
  companyDocument?: string;
  setCompanyTheme: (company: string) => void;
}) => {
  const companyCustomTheme =
    companiesCustomTheme[whichClientCompanyIsTheDocument(companyDocument)];

  return createTheme(
    {
      palette: {
        primary: {
          main: companyCustomTheme.primary,
          contrastText: '#FFF',
        },
        secondary: {
          main: companyCustomTheme.secondary,
          contrastText: '#FFF',
        },
      },

      setCompanyTheme,

      gradient: {
        company: companyCustomTheme.gradient,
      },

      typography: {
        fontFamily: 'Maven Pro, Arial, sans-serif',
      },
    },
    ptBR
  );
};
