/* eslint-disable eqeqeq */
export function cnpjValidation(cnpj: any) {
  // Verifica se a variável cnpj é igua a "undefined", exibindo uma msg de erro
  if (cnpj === undefined) {
    return false;
  }

  // Esta função retira os caracteres . / - da string do cnpj, deixando apenas os números
  var strCNPJ = cnpj
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '');

  // Testa as sequencias que possuem todos os dígitos iguais e se o cnpj não tem 14 dígitos, retonando falso e exibindo uma msg de erro
  if (
    strCNPJ === '00000000000000' ||
    strCNPJ === '11111111111111' ||
    strCNPJ === '22222222222222' ||
    strCNPJ === '33333333333333' ||
    strCNPJ === '44444444444444' ||
    strCNPJ === '55555555555555' ||
    strCNPJ === '66666666666666' ||
    strCNPJ === '77777777777777' ||
    strCNPJ === '88888888888888' ||
    strCNPJ === '99999999999999' ||
    strCNPJ.length !== 14
  ) {
    return false;
  }

  // A variável numbers pega o bloco com os números sem o DV, a variavel digitos pega apenas os dois ultimos numeros (Digito Verificador).
  var size = strCNPJ.length - 2;
  var numbers = strCNPJ.substring(0, size);
  var digitos = strCNPJ.substring(size);
  var sum = 0;
  var pos = size - 7;

  // Os quatro blocos seguintes de funções irá reaizar a validação do CNPJ propriamente dito, conferindo se o DV bate. Caso alguma das
  // funções não consiga verificar o DV corretamente, mostrará uma mensagem de erro ao usuário e retornará falso, para que o usário
  // possa digitar novamente um número
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  var resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado != digitos.charAt(0)) {
    return false;
  }

  size = size + 1;
  numbers = strCNPJ.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let k = size; k >= 1; k--) {
    sum += numbers.charAt(size - k) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado != digitos.charAt(1)) {
    return false;
  }

  return true;
}
