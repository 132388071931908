import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IBsoft {
  apiKey: string;
  days: number;
}

interface IBsoftContext {
  loadBsoft(companyDocument: number): Promise<IBsoft | undefined>;
  saveBsoft(data: IBsoft): Promise<void>;
}

const BsoftContext = createContext<IBsoftContext>({} as IBsoftContext);

const BsoftProvider: React.FC = ({ children }) => {
  const loadBsoft = useCallback(async (companyDocument: number) => {
    const response = await api.get(`/integrations/bsoft/config/${companyDocument}`);

    return response.data;
  }, []);

  const saveBsoft = useCallback(async (data: IBsoft) => {
    await api.post('/integrations/bsoft/config', data);
  }, []);

  return (
    <BsoftContext.Provider
      value={{
        loadBsoft,
        saveBsoft,
      }}
    >
      {children}
    </BsoftContext.Provider>
  );
};

function useBsoft(): IBsoftContext {
  const context = useContext(BsoftContext);

  if (!context) {
    throw new Error('useBsoft must be used within a BsoftProvider');
  }

  return context;
}

export { BsoftProvider, useBsoft };
