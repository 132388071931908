import * as yup from 'yup';

export default yup.object().shape({
  items: yup
    .array()
    .min(1, 'É necessário adicionar pelo menos um item ')
    .required('Campo obrigatório')
    .test(
      'quantity-is-validate',
      'Quantidade do item não pode ser vazio ou inferior a 1',
      (value) => {
        if (value) {
          let validate = true;

          value.forEach((item) => {
            if (isNaN(item.quantity) || item.quantity < 1) validate = false;
          });

          return validate;
        }
        return false;
      }
    ),
});
