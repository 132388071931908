import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { VehicleTypeList } from '../pages/VehicleTypeList';
import { VehicleTypeForm } from '../pages/VehicleTypeForm';
import { VehicleTypeProvider } from '../hooks/vehicle-type';

export const VehicleTypeRoute = () => (
  <VehicleTypeProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.vehicleTypeList}
        exact
        permission={PERMISSIONS.VEHICLE_TYPE_VIEW}
        component={VehicleTypeList}
      />
      <PrivateRoute
        path={ROUTES.createVehicleType}
        component={VehicleTypeForm}
      />
      <PrivateRoute
        path={ROUTES.updateVehicleType}
        component={VehicleTypeForm}
      />
    </Switch>
  </VehicleTypeProvider>
);
