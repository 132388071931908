import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AppBar } from '../../components/AppBar';
import { SideBar } from '../../components/SideBar';
import { CompanyDefault } from '../../components/CompanyDefault';
import { ROUTES } from '../../constants';
import { useAuth } from '../../hooks/auth';

import { useStyles } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const { companyDefault } = useAuth();
  const location = useLocation();
  const [openDialogCompanyDefault, setOpenDialogCompanyDefault] =
    useState(false);
  const [pathsname] = useState([
    ROUTES.createVehicle,
    ROUTES.createVehicleType,
    ROUTES.createRoutePlanning,
    ROUTES.createDistributionCenter,
  ]);

  useEffect(() => {
    if (!pathsname.includes(location.pathname)) {
      if (
        !companyDefault.hasVehicle ||
        !companyDefault.hasVehicleType ||
        !companyDefault.hasDistributionCenter ||
        !companyDefault.hasPlanningConfig
      ) {
        setOpenDialogCompanyDefault(true);
      }
    }
  }, [companyDefault, location.pathname, pathsname]);

  return (
    <div className={classes.main}>
      <AppBar />
      <SideBar />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.childrenContainer}>{children}</div>

        <CompanyDefault
          open={openDialogCompanyDefault}
          companyDefault={companyDefault}
          onClose={() => setOpenDialogCompanyDefault(false)}
        />
      </main>
    </div>
  );
};

export default DefaultLayout;
