import React, { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { differenceInSeconds, format, subDays } from 'date-fns';
import {
  Button,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { CollapseRow } from '../../../../components/Table/CollapseRow';
import { getHoursAndMinutesBySeconds } from '../../../../utils';

import { useAuth } from '../../../../hooks/auth';
import { ILoadInventory, useInventory } from '../../hooks/inventory';

import { generateInventoryReportPDF } from '../../templates/inventoryReport';
import XLSX from 'xlsx';

export interface IInventoryData extends ILoadInventory {
  scanTime: string;
}

export const InventoryReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { filter, setFilter, loadInventory } = useInventory();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState<number[]>([]);
  const [inventoryData, setInventoryData] = useState<IInventoryData[]>([]);

  const tableHeader: IBasicColumn[] = [
    { name: '', padding: 'checkbox' },
    { name: 'Master' },
    { name: 'Quantidade' },
    { name: 'Conferente' },
  ];

  const subtableHeader: IBasicColumn[] = [
    { name: 'Item' },
    { name: 'Data/Hora Scan' },
  ];

  const validationSchema = yup.object({
    initialDate: yup.date(),
    finalDate: yup
      .date()
      .min(
        yup.ref('initialDate'),
        'Data fim não pode ser menor que a data início'
      ),
    master: yup.string(),
    user: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filter,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  useEffect(() => {
    async function loadInventoryReport(): Promise<void> {
      setLoading(true);
      try {
        const response = await loadInventory(user.companyDocument, filter);

        if (!response) return;

        const data = response.map((inventory) => {
          let scanTime = '0 segundos';

          if (inventory.items.length > 0) {
            const scanTimeInSeconds = differenceInSeconds(
              new Date(inventory.items[inventory.items.length - 1].scanDate),
              new Date(inventory.items[0].scanDate)
            );

            const { minutes, seconds } =
              getHoursAndMinutesBySeconds(scanTimeInSeconds);

            scanTime =
              minutes > 0
                ? `${minutes.toString().padStart(2, '0')} minuto(s) e ${seconds
                    .toString()
                    .padStart(2, '0')} segundo(s)`
                : `${seconds.toString().padStart(2, '0')} segundo(s)`;
          }

          return {
            ...inventory,
            scanTime,
            items: inventory.items.map((item) => {
              const dt = new Date(item.scanDate);
              const dtDateOnly = new Date(
                dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
              );

              return {
                ...item,
                scanDate: format(dtDateOnly, 'dd/MM/yyyy HH:mm:ss'),
              };
            }),
          };
        });

        setInventoryData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadInventoryReport();
  }, [filter, loadInventory, user.companyDocument]);

  const handleReset = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleReset(e);
      setFilter({
        initialDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
        finalDate: format(new Date(), 'yyyy-MM-dd'),
        master: '',
        user: '',
      });
    },
    [formik, setFilter]
  );

  const handleCollapse = useCallback((id: number) => {
    setIsOpen((prevState) => {
      const idIndex = prevState.findIndex((prev) => prev === id);
      if (idIndex > -1) prevState.splice(idIndex, 1);

      const newArray = idIndex > -1 ? [...prevState] : [...prevState, id];

      return newArray;
    });
  }, []);

  const downloadExcel = useCallback(() => {
    let tableHeader: any;

    const tableBody = inventoryData.flatMap((inventory, index) => {
      const userName: any = inventory.userName ? inventory.userName : '';

      const header: any = [
        `MASTER: ${inventory.master}  QUANTIDADE: ${inventory.quantity}`,
        `CONFERENTE: ${userName}`,
      ];

      const subHeader = ['ITENS', 'DATA/HORA SCAN'];

      const body = inventory.items.map((item) => {
        return [item.awb, item.scanDate];
      });

      const footer = [
        `Total Itens Lidos: ${inventory.items.length}`,
        `Tempo: ${inventory.scanTime}`,
      ];

      if (index === 0) {
        tableHeader = [header];
        return [subHeader, ...body, footer];
      } else {
        return [header, subHeader, ...body, footer];
      }
    });

    const workSheetCols = [{ width: 39 }, { width: 32 }];

    const workSheet = XLSX.utils.json_to_sheet(tableBody);
    XLSX.utils.sheet_add_aoa(workSheet, tableHeader);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'INVENTÁRIO');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Inventário.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryData]);

  return (
    <Main title="Relatório de Inventário">
      <Grid container spacing={1}>
        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => generateInventoryReportPDF(inventoryData)}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={downloadExcel}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1" id="filter">
              Filtros
            </Typography>
          </Grid>

          <Grid item xs={10}>
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data início"
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={formik.values.initialDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.initialDate &&
                    Boolean(formik.errors.initialDate)
                  }
                  helperText={
                    formik.touched.initialDate && formik.errors.initialDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data fim"
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={formik.values.finalDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.finalDate && Boolean(formik.errors.finalDate)
                  }
                  helperText={
                    formik.touched.finalDate && formik.errors.finalDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Master"
                  id="master"
                  name="master"
                  value={formik.values.master}
                  onChange={formik.handleChange}
                  error={formik.touched.master && Boolean(formik.errors.master)}
                  helperText={formik.touched.master && formik.errors.master}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Conferente"
                  id="user"
                  name="user"
                  value={formik.values.user}
                  onChange={formik.handleChange}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                  helperText={formik.touched.user && formik.errors.user}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => formik.handleSubmit()}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleReset}>Limpar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={tableHeader}
            loading={loading}
            total={inventoryData.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {inventoryData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data) => {
                const isCollapseRowOpen = isOpen.includes(data.id);

                return (
                  <React.Fragment key={data.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => handleCollapse(data.id)}
                          aria-label="expand collapse row"
                        >
                          {isCollapseRowOpen ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {data.master}
                      </TableCell>
                      <TableCell>{data.quantity}</TableCell>
                      <TableCell>{data.userName}</TableCell>
                    </TableRow>

                    <CollapseRow
                      open={isCollapseRowOpen}
                      colSpan={tableHeader.length}
                      noPadding
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginLeft: 16 }}
                      >
                        Itens:
                      </Typography>
                      {data.items.length > 0 ? (
                        <>
                          <BasicTable
                            size="small"
                            headerWithNoColor
                            columns={subtableHeader}
                          >
                            {data.items.map((item) => {
                              return (
                                <TableRow key={item.id}>
                                  <TableCell>{item.awb}</TableCell>
                                  <TableCell>{item.scanDate}</TableCell>
                                </TableRow>
                              );
                            })}

                            <TableRow>
                              <TableCell>
                                <strong>
                                  Total Itens Lidos: {data.items.length}
                                </strong>
                              </TableCell>
                              <TableCell>
                                <strong>Tempo: {data.scanTime}</strong>
                              </TableCell>
                            </TableRow>
                          </BasicTable>
                        </>
                      ) : (
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="h6" gutterBottom>
                            Não há itens a serem exibidos
                          </Typography>
                        </Grid>
                      )}
                    </CollapseRow>
                  </React.Fragment>
                );
              })}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
