import React from 'react';
import {
  Card as MUICard,
  CardActionArea,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from 'react-icons/hi';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 0 16px',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.25,
  },
  content: {
    margin: '0 0 12px',
    fontSize: '1.625rem',
    fontWeight: 500,
    lineHeight: 1.25,
  },
  subtitle: {
    position: 'absolute',
    left: 16,
    bottom: 4,
    margin: 0,
    fontSize: '0.8125rem',
    fontWeight: 400,
    lineHeight: 1.57,
  },
  span: {
    fontWeight: 600,
    padding: 2,
    borderRadius: 3,
  },
  positive: {
    color: '#4caf50',
    // background: 'rgba(76, 175, 80, 0.1)',
  },
  negative: {
    color: '#f44336',
    // background: 'rgba(244, 67, 54, 0.1)',
  },
  neutral: {
    color: '#1e1e1e',
    background: 'rgba(30, 30, 30, 0.1)',
  },
  svgIcon: {
    position: 'absolute',
    top: 0,
    right: -5,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontSize: 100,
    textAlign: 'center',
    marginLeft: 15,
    color: '#000',
    opacity: 0.1,
  },
}));

export interface ICardsProps {
  title: string;
  content: string | number;
  invertPercentageColors?: boolean;
  percentage?: string | number;
  icon?: JSX.Element;
  cardColor?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Card = ({
  title,
  content,
  invertPercentageColors = false,
  percentage,
  icon,
  cardColor = '#fff',
  loading,
  onClick,
}: ICardsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const percentageConvertedToNumber = percentage
    ? parseFloat(percentage.toString())
    : undefined;
  const percentageBiggerThanZero = percentageConvertedToNumber
    ? percentageConvertedToNumber > 0
    : undefined;
  const percentageLessThanZero = percentageConvertedToNumber
    ? percentageConvertedToNumber < 0
    : undefined;
  const percentageEqualZero = percentageConvertedToNumber
    ? percentageConvertedToNumber === 0
    : undefined;

  return (
    <MUICard
      style={{
        position: 'relative',
        backgroundColor: cardColor,
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>

          <Typography className={classes.content} variant="h3">
            {loading ? (
              <CircularProgress
                size={25}
                style={{ color: theme.palette.getContrastText(cardColor) }}
              />
            ) : (
              content
            )}
          </Typography>

          {!loading && percentage && (
            <Typography
              className={classes.subtitle}
              variant="subtitle2"
              component="h6"
            >
              <span
                className={clsx(classes.span, {
                  [classes.positive]: invertPercentageColors
                    ? percentageLessThanZero
                    : percentageBiggerThanZero,
                  [classes.negative]: invertPercentageColors
                    ? percentageBiggerThanZero
                    : percentageLessThanZero,
                  [classes.neutral]: percentageEqualZero,
                })}
              >
                {percentageBiggerThanZero && <HiOutlineArrowSmUp />}
                {percentageLessThanZero && <HiOutlineArrowSmDown />}
                {percentage.toString().replace('-', '')}%
              </span>
            </Typography>
          )}

          {icon && <div className={classes.svgIcon}>{icon}</div>}
        </CardContent>
      </CardActionArea>
    </MUICard>
  );
};
