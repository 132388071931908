import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Typography } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';

import { Main } from '../../../../components/Main';
import { ROUTES } from '../../../../constants';
import { useAuth } from '../../../../hooks/auth';
import { useLocalStorage } from '../../../../hooks/localStorage';
import { useSettings } from '../../../../hooks/settings';
import { LateOrdersCard } from '../../components/Cards/LateOrdersCard';
import { OrdersDeliveredCard } from '../../components/Cards/OrdersDeliveredCard';
import { OrdersWithOccurrenceCard } from '../../components/Cards/OrdersWithOccurrenceCard';
import { PendingOrdersCard } from '../../components/Cards/PendingOrdersCard';
import { TotalOrdersCard } from '../../components/Cards/TotalOrdersCard';
import { TotalRoutesCard } from '../../components/Cards/TotalRoutesCard';
import { IconButton } from '../../components/IconButton';
import { PopoverFilterButton } from '../../components/PopoverFilterButton';
import { PopoverListButton } from '../../components/PopoverListButton';

export type OrderCardClick =
  | 'order'
  | 'occurrence'
  | 'delivered'
  | 'backorders'
  | 'pending';

export const Dashboard = () => {
  const {
    data: { user },
  } = useAuth();
  const history = useHistory();
  const [, setOrderCardClick] = useLocalStorage<OrderCardClick>(
    'orderCardClick',
    'order'
  );
  const {
    selectedPeriod,
    cardsInfo,
    cardsLoading,
    setCardsInfo,
    setCardsLoading,
    getDashboardCardsInfo,
    getDatePeriod,
  } = useSettings();

  const loadCardsInfo = useCallback(async () => {
    try {
      setCardsLoading(true);
      const { from, to } = getDatePeriod(selectedPeriod);

      const response = await getDashboardCardsInfo({
        initialDate: from,
        finalDate: to,
      });

      if (!response) return;

      setCardsInfo(response);
    } catch (error) {
      console.error(error);
    } finally {
      setCardsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDashboardCardsInfo, getDatePeriod, selectedPeriod]);

  const handleOrderCardClick = useCallback(
    (type: OrderCardClick) => {
      setOrderCardClick(type);
      history.push(ROUTES.order);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return (
    <Main>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle1">Bem-vindo à</Typography>
          <Typography variant="h5" style={{ fontWeight: 500 }}>
            4LOG
          </Typography>
        </Grid>

        <Grid item>
          <IconButton onClick={() => loadCardsInfo()} disabled={cardsLoading}>
            <CachedIcon />
          </IconButton>

          <PopoverFilterButton />

          <PopoverListButton />
        </Grid>
      </Grid>

      <Divider style={{ margin: '24px 0' }} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Pedidos</Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <TotalOrdersCard
            content={cardsInfo.totalOrders}
            percentage={cardsInfo.percentageOrdersVariation}
            onClick={() => handleOrderCardClick('order')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <PendingOrdersCard
            content={cardsInfo.totalPendingOrders}
            percentage={cardsInfo.percentagePendingOrdersVariation}
            onClick={() => handleOrderCardClick('pending')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <OrdersDeliveredCard
            content={cardsInfo.totalOrdersDelivered}
            percentage={cardsInfo.percentageOrdersDeliveredVariation}
            onClick={() => handleOrderCardClick('delivered')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <LateOrdersCard
            content={cardsInfo.totalBackorders}
            percentage={cardsInfo.percentageBackordersVariation}
            onClick={() => handleOrderCardClick('backorders')}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <OrdersWithOccurrenceCard
            content={cardsInfo.totalOrdersWithOccurrence}
            percentage={cardsInfo.percentageOrdersWithOccurrenceVariation}
            onClick={() => handleOrderCardClick('occurrence')}
          />
        </Grid>

        {user.type !== 'FINAL_USER' && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Rotas</Typography>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <TotalRoutesCard
                content={cardsInfo.totalRoutes}
                percentage={cardsInfo.percentageRoutesVariation}
                onClick={() => history.push(ROUTES.route)}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} style={{ minHeight: 'calc(100vh - 350px)' }}>
          {/* <Paper style={{ minHeight: 'calc(100vh - 392px)' }}></Paper> */}
        </Grid>
      </Grid>
    </Main>
  );
};
