import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import PublishIcon from '@material-ui/icons/Publish';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { Favorite, ThumbDown } from '@material-ui/icons';

import { useAuth } from '../../../../../hooks/auth';
import { useLocalStorage } from '../../../../../hooks/localStorage';
import { useDistributionCenter } from '../../../../distribution-center/hooks/distribution-center';
import { useManualOrder } from '../../../hooks/manual-order';
import { IOrderDatesLoad, useOrder } from '../../../hooks/order';
import { DeliveredTable } from '../../Delivered/Table';
import { ImportOrder } from '../../ImportOrder';
import { OccurrenceTable } from '../../Occurrence/Table';
import { OrderFilter } from '../../OrderFilter';
import { DistributionCenter } from '../DistributionCenter';
import { OrderTable } from '../Table';
import { useStyles } from './styles';

import { PERMISSIONS } from '../../../../../constants';
import {
  ICompanyParams,
  useCompanyParams,
} from '../../../../company-params/hooks/company-params';

interface IProps {
  statusCode?: 'delivered' | 'occurrence';
}

export const OrderAccordion: React.FC<IProps> = ({ statusCode = '' }) => {
  const {
    data: { user, permissions },
  } = useAuth();
  const { getCompanyParams } = useCompanyParams();
  const { filter, loadDaysWithOrders } = useOrder();
  const { loadDistributionCenterList } = useDistributionCenter();
  const { handleToggleManualOrder } = useManualOrder();
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [moreThanOneDistributionCenter, setMoreThanOneDistributionCenter] =
    useState(false);
  const [openImportOrder, setOpenImportOrder] = useState(false);
  const [days, setDays] = useState<IOrderDatesLoad>({
    total: 0,
    result: [],
  });
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isFilterOpen, setIsFilterOpen] = useLocalStorage<boolean>(
    'orderFilter',
    false
  );
  const [companyParams, setCompanyParams] = useState<ICompanyParams>();

  useEffect(() => {
    async function loadOrders() {
      setLoading(true);

      try {
        const response = await loadDaysWithOrders(user.companyDocument, {
          ...filter,
          statusCode: statusCode === '' ? filter.statusCode : statusCode,
        });

        if (!response) return;

        for (let i = 0; i < response.result.length; i++) {
          const splitdate = response.result[i].date.split('-');
          const DAY = splitdate[2];
          const MONTH = splitdate[1];
          const YEAR = splitdate[0];

          const dateRefactored = `${DAY}/${MONTH}/${YEAR}`;
          response.result[i].date = dateRefactored;
        }

        setDays(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadOrders();
  }, [loadDaysWithOrders, user.companyDocument, filter, statusCode]);

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      try {
        const response = await loadDistributionCenterList(user.companyDocument);

        if (!response) return;

        response.length > 1 && setMoreThanOneDistributionCenter(true);
      } catch (error) {
        console.log(error);
      }
    }

    loadDistributionCenter();
  }, [loadDistributionCenterList, user.companyDocument]);

  useEffect(() => {
    if (days.result.length === 1) setExpanded('panel0');
  }, [days.result]);

  useEffect(() => {
    async function loadCompanyParams(): Promise<void> {
      try {
        const response = await getCompanyParams();

        if (!response) return;

        setCompanyParams(response);
      } catch (error) {
        console.log(error);
      }
    }

    loadCompanyParams();
  }, [openImportOrder, getCompanyParams]);

  const handleChange = useCallback(
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  const handleToggleImportOrder = useCallback(() => {
    setOpenImportOrder((prevState) => !prevState);
  }, []);

  const title = useMemo(() => {
    if (statusCode === 'delivered') {
      return 'Pedidos Entregues';
    } else if (statusCode === 'occurrence') {
      return 'Ocorrências';
    } else {
      return 'Pedidos';
    }
  }, [statusCode]);

  const Table = useCallback(
    (day: string) => {
      if (statusCode === 'delivered') return <DeliveredTable date={day} />;
      else if (statusCode === 'occurrence')
        return <OccurrenceTable date={day} />;
      else return <OrderTable date={day} />;
    },
    [statusCode]
  );

  return (
    <Paper className={classes.root}>
      <ImportOrder open={openImportOrder} onClose={handleToggleImportOrder} />

      <Toolbar className={classes.toolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          id="accordionTitle"
          component="div"
        >
          {title}
        </Typography>

        {!statusCode && (
          <>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleToggleManualOrder}
              startIcon={<AddIcon />}
              style={{ marginRight: 10 }}
              disabled={!permissions.includes(PERMISSIONS.ORDER_CREATE)}
            >
              Novo Pedido
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={handleToggleImportOrder}
              startIcon={
                <PublishIcon style={{ transform: 'rotate(-180deg)' }} />
              }
              style={{ marginRight: 10 }}
            >
              Importar
            </Button>
          </>
        )}

        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          startIcon={<FilterListIcon />}
        >
          Filtros
        </Button>
      </Toolbar>

      <Collapse in={isFilterOpen} timeout="auto" unmountOnExit>
        <Toolbar className={classes.toolbar}>
          <OrderFilter statusCode={statusCode} />
        </Toolbar>
      </Collapse>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginLeft: 10,
            marginBottom: 8,
          }}
        >
          <StopRoundedIcon style={{ color: 'rgb(2,189,2)' }} />
          <strong>Entrega |</strong>
          <StopRoundedIcon style={{ color: '#ff6961' }} />
          <strong>Coleta/Retirada |</strong>
          <StopRoundedIcon style={{ color: '#0099ad' }} />
          <strong>Devolução |</strong>
          <StopRoundedIcon style={{ color: '#6e6e6e' }} />
          <strong>Higienização |</strong>
          <StopRoundedIcon style={{ color: '#a38c31' }} />
          <strong>Reparo</strong>
        </div>

        {companyParams && companyParams.sendNps && (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-around',
              marginLeft: 10,
              marginBottom: 8,
            }}
          >
            <Favorite fontSize="small" style={{ color: 'grey' }} />
            <strong> Não respondido |</strong>
            <Favorite fontSize="small" style={{ color: 'red' }} />
            <strong> Promotor |</strong>
            <Favorite fontSize="small" style={{ color: 'orange' }} />
            <strong> Neutro |</strong>
            <ThumbDown fontSize="small" />
            <strong> Detrator</strong>
          </div>
        )}
      </div>

      {loading ? (
        <Typography component="div" align="center">
          <CircularProgress
            style={{
              color: clsx(
                !statusCode && theme.palette.info.main,
                statusCode === 'delivered' && theme.palette.success.main,
                statusCode === 'occurrence' && theme.palette.error.main
              ),
            }}
          />
        </Typography>
      ) : (
        <>
          {days.result.length > 0 ? (
            days.result.map((day, index) => (
              <Accordion
                key={`panel${String(index)}`}
                expanded={expanded === `panel${String(index)}`}
                onChange={handleChange(`panel${String(index)}`)}
              >
                <AccordionSummary
                  id={`panel${String(index)}-header`}
                  aria-controls={`panel${String(index)}-content`}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {`${day.date} - Pedidos: ${day.quantity}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {expanded === `panel${String(index)}` &&
                    (moreThanOneDistributionCenter ? (
                      <DistributionCenter
                        date={day.date}
                        statusCode={statusCode}
                      />
                    ) : (
                      Table(day.date)
                    ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box pb={4} pt={4}>
              <Typography variant="body1" component="div" align="center">
                Nenhuma informação encontrada.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Paper>
  );
};
