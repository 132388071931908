import { Box, Typography, styled } from '@material-ui/core';
import { Rating, RatingProps } from '@material-ui/lab';

const StyledRating = styled(Rating)(({ theme }) => ({
  alignItems: 'end',
  width: 'fit-content',
  color: theme.palette.primary.main,
  borderBottom: 'solid  3px',

  '& .empty .MuiBox-root': {
    fontSize: 25,
    color: theme.palette.action.disabled,
  },

  '& .filled .MuiBox-root': {
    fontSize: 30,
    color: theme.palette.primary.main,
  },

  '& .MuiBox-root': {
    paddingInline: 10,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    width: 35,
  },
}));

const numbers = [...Array(10)].map((_, i) => ({
  label: () => <Box key={`label-${i}`}>{i + 1}</Box>,
}));

const NumberContainer = (props: any) => {
  const { value, rateValue, ...other } = props;
  const NumberLabel = numbers[value - 1].label;

  return (
    <span
      {...other}
      style={{ width: 35 }}
      className={`
      ${other.className}
      ${Number(rateValue) === value ? 'filled' : 'empty'}
          `}
    >
      {<NumberLabel />}
    </span>
  );
};

export const RatingField = (props: RatingProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <StyledRating
        {...props}
        name="rating"
        max={10}
        IconContainerComponent={(iconProps) => (
          <NumberContainer {...iconProps} rateValue={props?.value} />
        )}
        getLabelText={(label: number) => `${label}`}
      />

      <Box display="flex" justifyContent="space-between">
        <Typography style={{fontWeight: (props?.value || 0) < 5 ? 'bold' : 'normal'}} variant="caption" color="primary">
          Totalmente improvável
        </Typography>
        <Typography style={{fontWeight: (props?.value || 0) > 5 ? 'bold' : 'normal'}} variant="caption" color="primary">
          Extremamente provável
        </Typography>
      </Box>
    </Box>
  );
};
