import { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Grid, Box, TextField } from '@material-ui/core';

import { Button } from '../../../../../components/Button';
import { useAuth } from '../../../../../hooks/auth';
import { useOrder } from '../../../hooks/order';

export const Manual = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: {
      user: { companyDocument },
    },
  } = useAuth();
  const { importOrder } = useOrder();
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    orderCode: yup.string().required('Insira um código'),
  });

  const formik = useFormik({
    initialValues: {
      orderCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formik) => {
      setLoading(true);

      await importOrder({
        orderCode: values.orderCode,
        companyDocument,
      });

      enqueueSnackbar('Importação de pedido enviada para marketplace!', {
        variant: 'success',
      });

      formik.resetForm();
      setLoading(false);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
      style={{ minHeight: 125 }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box textAlign="center">
            <TextField
              variant="outlined"
              size="small"
              autoFocus
              label="Código do pedido"
              placeholder="Insira o código do pedido"
              id="orderCode"
              name="orderCode"
              value={formik.values.orderCode}
              onChange={formik.handleChange}
              error={
                formik.touched.orderCode && Boolean(formik.errors.orderCode)
              }
              helperText={formik.touched.orderCode && formik.errors.orderCode}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              loading={loading}
            >
              Importar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
