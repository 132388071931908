import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Grid,
  TextField,
  Button,
  TableRow,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { TableCell } from '../../../../components/Table/TableCell';

import { useAuth } from '../../../../hooks/auth';
import { useDistributionCenter } from '../../../distribution-center/hooks/distribution-center';

import { generateDistributionCenterReportPDF } from '../../templates/distributionCenterReport';
import XLSX from 'xlsx';

export type TypeDistributionCenterReport = {
  id: number;
  name: string;
  isPrincipal: string;
  address: string;
};

export const DistributionCenterReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadDistributionCenterList } = useDistributionCenter();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchField, setSearchField] = useState('');
  const [distributionCenters, setDistributionCenters] = useState<
    TypeDistributionCenterReport[]
  >([]);

  const columnsTable: IBasicColumn[] = [
    { name: 'Código' },
    { name: 'Nome' },
    { name: 'Principal' },
    { name: 'Endereço' },
  ];

  useEffect(() => {
    async function loadDistributionCenterReport(): Promise<void> {
      try {
        const response = await loadDistributionCenterList(user.companyDocument);

        if (!response) return;

        const data: any = response.map((distributionCenter) => {
          return {
            ...distributionCenter,
            isPrincipal: distributionCenter.isPrincipal ? 'Sim' : 'Não',
            address: `${distributionCenter.address.street}, ${distributionCenter.address.number}, ${distributionCenter.address.neighborhood}`,
          };
        });

        setDistributionCenters(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadDistributionCenterReport();
  }, [loadDistributionCenterList, user.companyDocument]);

  const distributionCenterFilter =
    useMemo((): TypeDistributionCenterReport[] => {
      const fields = ['id', 'name', 'isPrincipal', 'address'];
      const matchedSearches = distributionCenters.filter(
        (distributionCenter: TypeDistributionCenterReport) => {
          const includesOneOf = !!fields.find((field) =>
            distributionCenter[field as keyof TypeDistributionCenterReport]
              .toString()
              .toLowerCase()
              .includes(searchField.toLowerCase())
          );
          return includesOneOf;
        }
      );

      return matchedSearches;
    }, [searchField, distributionCenters]);

  const downloadExcel = useCallback((data: TypeDistributionCenterReport[]) => {
    const newData = data.map((distributionCenter) => {
      return {
        id: distributionCenter.id,
        name: distributionCenter.name,
        isPrincipal: distributionCenter.isPrincipal,
        address: distributionCenter.address,
      };
    });

    const header = [['Código', 'Nome', 'Principal', 'Endereço']];

    const workSheetCols = [
      { width: 7 },
      { width: 35 },
      { width: 8 },
      { width: 58 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      'CENTROS DE DISTRIBUIÇÃO'
    );

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Centros de Distribuição.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Centros de Distribuição">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={searchField}
                onChange={(ev) => setSearchField(ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() =>
                  generateDistributionCenterReportPDF(distributionCenterFilter)
                }
              >
                Exportar PDF
              </Button>

              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginBottom: 5, marginRight: 5 }}
                onClick={() => downloadExcel(distributionCenterFilter)}
              >
                Exportar EXCEL
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={distributionCenters.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {distributionCenterFilter
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((distributionCenter) => (
                <TableRow key={distributionCenter.id} hover>
                  <TableCell component="th" scope="row">
                    {distributionCenter.id}
                  </TableCell>
                  <TableCell>{distributionCenter.name}</TableCell>
                  <TableCell>{distributionCenter.isPrincipal}</TableCell>
                  <TableCell>{distributionCenter.address}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
