import React, { useCallback, useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { getCnpj } from '../../../../services/getCnpj';
import {
  cnpjValidation,
  maskCnpj,
  maskTelephone,
  maskPostalcode,
  unmask,
} from '../../../../utils';
import { useSignUp } from '../../hooks/signUp';

import { Buttons } from '../Buttons';

import { Form } from './styles';

export const CompanyForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setFormActive, setDataProfile, profileType, getCompany } = useSignUp();
  const [documentNumberValid, setDocumentNumberValid] = useState(false);
  const [companyValid, setCompanyValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data] = useState({
    documentNumber: '',
    companyLegalName: '',
    tradingName: '',
    contacts: {
      telephone: '',
      email: '',
    },
    addresses: {
      postalcode: '',
      street: '',
      neighborhood: '',
      number: '',
      city: '',
      state: '',
      complement: '',
    },
    type: profileType,
  });

  const validationSchema = yup.object({
    documentNumber: yup
      .string()
      .required('Insira seu cnpj')
      .test(
        'cnpj-is-validate',
        'CNPJ Inválido!',
        (value) => {
          setDocumentNumberValid(false);
          const cnpjValid = cnpjValidation(value);
          if (cnpjValid === false) {setCompanyValid(false)}
          setDocumentNumberValid(cnpjValid);
          return cnpjValid
        }
      )
  });

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (companyValid) {
        setFormActive(1);

        setDataProfile((prevState) => ({
          ...prevState,
          company: {
            ...prevState.company,
            documentNumber: values.documentNumber,
            companyLegalName: values.companyLegalName,
            tradingName: values.tradingName,
            contacts: [
              {
                type: 'telephone',
                contact: values.contacts.telephone,
                contactName: 'Telefone',
              },
              {
                type: 'email',
                contact: values.contacts.email,
                contactName: 'E-mail',
              },
            ],
            addresses: [
              {
                postalcode: values.addresses.postalcode,
                street: values.addresses.street,
                neighborhood: values.addresses.neighborhood,
                number: values.addresses.number,
                city: values.addresses.city,
                state: values.addresses.state,
                complement: values.addresses.complement,
              },
            ],
            type: values.type,
          },
        }));
      }
    },
  });

  const handleChangeDocumentNumberValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const unmakedValue = unmask(maskCnpj(value));

      formik.setFieldValue(name, unmakedValue);

    },
    [formik]
  );

  const handleChangeValueWithMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const unmakedValue =
        name === 'contacts.telephone'
          ? unmask(maskTelephone(value))
          : unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmakedValue);
    },
    [formik]
  );

  useEffect(() => {
    async function checkCompanyValid(): Promise<void> {
      try {
        if (documentNumberValid) {
          const response = await getCompany(parseInt(formik.values.documentNumber));

          if (response) {
            setCompanyValid(false);
            const message = 'Empresa já cadastrada no sistema!'

            enqueueSnackbar(message, {
              variant: 'error',
            });
          } else setCompanyValid(true);
        }
      } catch (error) {
        console.log(error);
      }
    }

    checkCompanyValid();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[documentNumberValid, formik.handleSubmit]);

  useEffect(() => {
    async function loadCompany(): Promise<void> {
      try {
        if (companyValid) {
          const companyInfo = await getCnpj(formik.values.documentNumber);

          if (companyInfo) {
            const {
              nome,
              fantasia,
              cep,
              logradouro,
              bairro,
              numero,
              municipio,
              uf,
              complemento,
              telefone,
              email,
            } = companyInfo;

            if (nome) {
              formik.setFieldValue('companyLegalName', nome);
            }

            if (fantasia === '') {
              formik.setFieldValue('tradingName', nome);
            } else {
              formik.setFieldValue('tradingName', fantasia);
            }

            if (cep) {
              formik.setFieldValue(
                'addresses.postalcode',
                cep.replace(/[^0-9]/g, '')
              );
            }

            if (logradouro) {
              formik.setFieldValue('addresses.street', logradouro);
            }

            if (bairro) {
              formik.setFieldValue('addresses.neighborhood', bairro);
            }

            if (numero) {
              formik.setFieldValue('addresses.number', numero);
            }

            if (municipio) {
              formik.setFieldValue('addresses.city', municipio);
            }

            if (uf) {
              formik.setFieldValue('addresses.state', uf);
            }

            if (complemento) {
              formik.setFieldValue('addresses.complement', complemento);
            }

            if (telefone) {
              formik.setFieldValue(
                'contacts.telephone',
                telefone.split('/')[0].replace(/[^0-9]/g, '')
              );
            }

            if (email) {
              formik.setFieldValue('contacts.email', email);
            }
          }
          setLoading(false);
        } else {
          setLoading(true)
        }
      } catch (error) {
        console.log(error);
      }
    }

    loadCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyValid]);

  return (
    <Form onSubmit={formik.handleSubmit} noValidate>
      <Typography variant="body1" align="left" style={{ marginTop: '12px' }}>
        <strong>Dados da Empresa</strong>
      </Typography>

      <Typography
        variant="subtitle2"
        align="left"
        style={{ marginBottom: '8px' }}
      >
        Campos marcados com * são necessários para cadastro na aplicação.
      </Typography>

      <Grid container spacing={1}>

        <Grid item xs={12} sm={5}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="CNPJ"
            id="documentNumber"
            name="documentNumber"
            autoFocus
            value={maskCnpj(formik.values.documentNumber)}
            onChange={handleChangeDocumentNumberValue}
            error={
              formik.touched.documentNumber &&
              Boolean(formik.errors.documentNumber)
            }
            helperText={
              formik.touched.documentNumber && formik.errors.documentNumber
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {companyValid &&
          (loading ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={7}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Nome da Empresa"
                  id="companyLegalName"
                  name="companyLegalName"
                  value={formik.values.companyLegalName}
                  onChange={handleChangeValueWithMask}
                  error={
                    formik.touched.companyLegalName &&
                    Boolean(formik.errors.companyLegalName)
                  }
                  helperText={
                    formik.touched.companyLegalName &&
                    formik.errors.companyLegalName
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Nome Fantasia"
                  id="tradingName"
                  name="tradingName"
                  value={formik.values.tradingName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tradingName &&
                    Boolean(formik.errors.tradingName)
                  }
                  helperText={
                    formik.touched.tradingName && formik.errors.tradingName
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Telefone"
                  id="contacts.telephone"
                  name="contacts.telephone"
                  value={maskTelephone(formik.values.contacts.telephone)}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contacts?.telephone &&
                    Boolean(formik.errors.contacts?.telephone)
                  }
                  helperText={
                    formik.touched.contacts?.telephone &&
                    formik.errors.contacts?.telephone
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="E-mail"
                  id="contacts.email"
                  name="contacts.email"
                  value={formik.values.contacts.email}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contacts?.email &&
                    Boolean(formik.errors.contacts?.email)
                  }
                  helperText={
                    formik.touched.contacts?.email &&
                    formik.errors.contacts?.email
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="CEP"
                  id="addresses.postalcode"
                  name="addresses.postalcode"
                  value={maskPostalcode(formik.values.addresses.postalcode)}
                  onChange={handleChangeValueWithMask}
                  error={
                    formik.touched.addresses?.postalcode &&
                    Boolean(formik.errors.addresses?.postalcode)
                  }
                  helperText={
                    formik.touched.addresses?.postalcode &&
                    formik.errors.addresses?.postalcode
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Rua"
                  id="addresses.street"
                  name="addresses.street"
                  value={formik.values.addresses.street}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresses?.street &&
                    Boolean(formik.errors.addresses?.street)
                  }
                  helperText={
                    formik.touched.addresses?.street &&
                    formik.errors.addresses?.street
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Bairro"
                  id="addresses.neighborhood"
                  name="addresses.neighborhood"
                  value={formik.values.addresses.neighborhood}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresses?.neighborhood &&
                    Boolean(formik.errors.addresses?.neighborhood)
                  }
                  helperText={
                    formik.touched.addresses?.neighborhood &&
                    formik.errors.addresses?.neighborhood
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Número"
                  id="addresses.number"
                  name="addresses.number"
                  value={formik.values.addresses.number}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresses?.number &&
                    Boolean(formik.errors.addresses?.number)
                  }
                  helperText={
                    formik.touched.addresses?.number &&
                    formik.errors.addresses?.number
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Cidade"
                  id="addresses.city"
                  name="addresses.city"
                  value={formik.values.addresses.city}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresses?.city &&
                    Boolean(formik.errors.addresses?.city)
                  }
                  helperText={
                    formik.touched.addresses?.city &&
                    formik.errors.addresses?.city
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Estado"
                  id="addresses.state"
                  name="addresses.state"
                  value={formik.values.addresses.state}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresses?.state &&
                    Boolean(formik.errors.addresses?.state)
                  }
                  helperText={
                    formik.touched.addresses?.state &&
                    formik.errors.addresses?.state
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Complemento"
                  id="addresses.complement"
                  name="addresses.complement"
                  value={formik.values.addresses.complement}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.addresses?.complement &&
                    Boolean(formik.errors.addresses?.complement)
                  }
                  helperText={
                    formik.touched.addresses?.complement &&
                    formik.errors.addresses?.complement
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </>
          ))}
      </Grid>

      <Buttons />
    </Form>
  );
};
