import { format, subDays } from 'date-fns';
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import api from '../../../services/api';
import type { IForm, IForms, QuestionType } from '../../form/hooks/form';
import type { ILoadUser } from '../../user-management/hooks/user-management';

const formatDate = (arg: Date) => format(arg, 'yyyy-MM-dd');

interface FormData {
  id: number;
  order: number;
  required: boolean;
  subject: string;
  type: QuestionType;
  options: string[];
  value?: string;
  observation?: string;
  valueCheckbox?: string[];
}

export interface FilledForm {
  id: number;
  dateFilled: string;
  companyDocument: string;
  formConfigId: number;
  userId: number;
  identifierId: number;
  data: FormData[];
  images: {
    id: number;
    image: string;
    questionId: number;
  }[];
  formConfig: IForm;
  user: ILoadUser;
  activity: { number: string } | null;
  activityItem: { code: string } | null;
  vehicle: { plate: string } | null;
}

interface IFilterForm {
  initialDate?: string;
  finalDate?: string;
  userId?: string;
  formConfigIdTitle?: [string, string];
  process?: string;
}

export interface LoadForm {
  total: number;
  filledForms: FilledForm[];
}

interface FormContextData {
  loadForm(page: number, take: number, options: IFilterForm): Promise<LoadForm>;
  loadFormsConfig(page: number, take: number): Promise<IForms>;
  filter: IFilterForm;
  setFilter: React.Dispatch<React.SetStateAction<IFilterForm>>;
}

const FormContext = createContext<FormContextData>({} as FormContextData);

const FormProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<IFilterForm>({
    initialDate: formatDate(subDays(new Date(), 6)),
    finalDate: formatDate(new Date()),
    formConfigIdTitle: ['', ''],
    userId: '',
    process: '',
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      initialDate: formatDate(subDays(new Date(), 6)),
      finalDate: formatDate(new Date()),
      formConfigIdTitle: ['', ''],
      userId: '',
      process: '',
    }));
  }, []);

  const loadForm = useCallback(
    async (page: number, take: number, options: IFilterForm) => {
      let query = '';

      const { initialDate, finalDate, userId, process, formConfigIdTitle } =
        options;

      query =
        `${initialDate && `&initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}` +
        `${userId && `&userId=${userId}`}` +
        `${process && `&process=${process}`}` +
        `${formConfigIdTitle?.[0] && `&formConfigId=${formConfigIdTitle[0]}`}`;

      const response = await api.get<LoadForm>(
        `/filled-form?page=${page + 1}&take=${take}&${query}`
      );

      return response.data;
    },
    []
  );

  const loadFormsConfig = useCallback(async (page: number, take: number) => {
    const response = await api.get<IForms>(
      `/forms-config?page=${page + 1}&take=${take}`
    );

    return response.data;
  }, []);

  return (
    <FormContext.Provider
      value={{
        filter,
        setFilter,
        loadForm,
        loadFormsConfig,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

function useForm(): FormContextData {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useForm must be used with in a FormProvider');
  }

  return context;
}

export { FormProvider, useForm };
