import { makeStyles } from '@material-ui/core/styles';

import { SIDE_BAR } from '../../constants';

export const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    width: SIDE_BAR.drawerWidth,
    height: 64,
    ...theme.mixins.toolbar,
  },
  logo: {
    maxWidth: '90%',
    width: 'auto',
    height: 'auto',
  },
  drawer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: SIDE_BAR.drawerWidth,
    },
  },
  drawerPaperMobile: {
    width: SIDE_BAR.drawerWidth,
  },
  drawerPaperDesktop: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: SIDE_BAR.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: SIDE_BAR.drawerWidthClose,
  },
  nested: {
    paddingLeft: theme.spacing(3),
    fontSize: 14,
  },
}));
