import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 45%;
    height: 45%;
    margin-bottom: 15px;
  }

  .Typography {
    margin-bottom: 20px;
  }
`;
