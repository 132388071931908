import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface ITomtom {
  apiKey: string;
}

interface ITomtomContext {
  loadTomtom(companyDocument: number): Promise<ITomtom | undefined>;
  saveTomtom(data: ITomtom): Promise<void>;
}

const TomtomContext = createContext<ITomtomContext>({} as ITomtomContext);

const TomtomProvider: React.FC = ({ children }) => {
  const loadTomtom = useCallback(async (companyDocument: number) => {
    const response = await api.get(`/integrations/tomtom/config/${companyDocument}`)
    .then(response => response.data)
    .catch(() => {return null});

    return response;
  }, []);

  const saveTomtom = useCallback(async (data: ITomtom) => {
    await api.post('/integrations/tomtom/config', data);
  }, []);

  return (
    <TomtomContext.Provider
      value={{
        loadTomtom,
        saveTomtom,
      }}
    >
      {children}
    </TomtomContext.Provider>
  );
};

function useTomtom(): ITomtomContext {
  const context = useContext(TomtomContext);

  if (!context) {
    throw new Error('useTomtom must be used within a TomtomProvider');
  }

  return context;
}

export { TomtomProvider, useTomtom };
