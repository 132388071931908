import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { CellActions } from '../../../../components/Table/CellActions';
import { TableCell } from '../../../../components/Table/TableCell';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { Loading } from '../../../../components/Loading';
import { StoragePositionInfo } from '../../components/StoragePositionInfo';
import {
  useStoragePosition,
  ILoadStoragePosition,
} from '../../hooks/storage-position';

import { useStyles } from './styles';

export const StoragePositionList = () => {
  const { loadStoragePositionList, deleteStoragePosition } =
    useStoragePosition();
  const classes = useStyles();
  const [storagePositionList, setStoragePositionList] =
    useState<ILoadStoragePosition>({
      total: 0,
      storagePositions: [],
    });
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [storagePositionId, setStoragePositionId] = useState<number | null>(
    null
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, storagePositionList.total - page * rowsPerPage);

  const storagePositionFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return storagePositionList.storagePositions.filter(
      (storagePosition) =>
        storagePosition.distributionCenterCode
          .toLowerCase()
          .includes(lowerSearch) ||
        storagePosition.street.toLowerCase().includes(lowerSearch) ||
        storagePosition.column.toLowerCase().includes(lowerSearch) ||
        storagePosition.level.toLowerCase().includes(lowerSearch) ||
        storagePosition.apartment.toLowerCase().includes(lowerSearch) ||
        storagePosition.code.toLowerCase().includes(lowerSearch)
    );
  }, [search, storagePositionList.storagePositions]);

  useEffect(() => {
    async function loadStoragePosition(): Promise<void> {
      try {
        const response = await loadStoragePositionList();

        if (!response) return;

        setStoragePositionList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadStoragePosition();
  }, [loadStoragePositionList]);

  const handleOpenView = useCallback((id: number) => {
    setOpenView(true);
    setStoragePositionId(id);
  }, []);

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setStoragePositionId(null);
  }, []);

  const handleOpenDialogDelete = useCallback((id: number) => {
    setOpenDialogDelete(true);
    setStoragePositionId(id);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setStoragePositionId(null);
  }, []);

  const handleDeleteStoragePosition = useCallback(async () => {
    if (storagePositionId) {
      await deleteStoragePosition(storagePositionId);
      setStoragePositionList((prevState) => ({
        ...prevState,
        storagePositions: prevState.storagePositions.filter(
          (rest) => rest.id !== storagePositionId
        ),
      }));
      setStoragePositionId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteStoragePosition, storagePositionId]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Main
      title="Posições de Armazenagem"
      button="add"
      buttonName="Nova Posição"
      to={ROUTES.createStoragePosition}
      hasPermission="STORE_POSITION_CREATE"
    >
      <Grid container spacing={1}>
        <StoragePositionInfo
          open={openView}
          storagePositionId={storagePositionId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar a posição"
          description="Deseja realmente deletar esta posição de armazenagem?"
          onSubmit={handleDeleteStoragePosition}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Box mb={1}>
            <Grid item>
              <TextField
                variant="outlined"
                id="search"
                name="search"
                size="small"
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>CD</TableCell>
                  <TableCell>Rua</TableCell>
                  <TableCell>Coluna</TableCell>
                  <TableCell>Nivel</TableCell>
                  <TableCell>Apartamento</TableCell>
                  <TableCell>Código da posição</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <Loading columns={7} rows={rowsPerPage} />
                ) : (
                  storagePositionFilter.map((storagePosition) => (
                    <TableRow key={storagePosition.id} hover>
                      <TableCell component="th" scope="row">
                        {storagePosition.distributionCenterCode}
                      </TableCell>
                      <TableCell>{storagePosition.street}</TableCell>
                      <TableCell>{storagePosition.column}</TableCell>
                      <TableCell>{storagePosition.level}</TableCell>
                      <TableCell>{storagePosition.apartment}</TableCell>
                      <TableCell>{storagePosition.code}</TableCell>
                      <TableCell align="center">
                        <CellActions
                          id={storagePosition.id}
                          sendTo="storagePositionList"
                          handleOpen={() => handleOpenView(storagePosition.id)}
                          handleDelete={() =>
                            handleOpenDialogDelete(storagePosition.id)
                          }
                          onEditPermission="STORE_POSITION_EDIT"
                          onRemovePermission="STORE_POSITION_REMOVE"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
                {!loading && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={storagePositionFilter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Main>
  );
};
