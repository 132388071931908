import { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { IColumn, Table } from '../../../../components/Table';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { useAuth } from '../../../../hooks/auth';
import { VehicleTypeInfo } from '../../components/VehicleTypeInfo';
import { useVehicleType, ILoadVehicleType } from '../../hooks/vehicle-type';

export const VehicleTypeList = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadVehicleTypeList, deleteVehicleType } = useVehicleType();
  const [vehicleTypes, setVehicleTypes] = useState<ILoadVehicleType[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openView, setOpenView] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [vehicleTypeId, setVehicleTypeId] = useState<number | null>(null);

  const columns: IColumn<ILoadVehicleType>[] = [
    { hide: true, field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome' },
    { field: 'maxVolume', headerName: 'Volume máximo', align: 'right' },
    { field: 'maxWeight', headerName: 'Peso máximo', align: 'right' },
    { field: 'size', headerName: 'Tamanho', align: 'right' },
    {
      field: 'maxSitesNumber',
      headerName: 'Capacidade de entregas',
      align: 'right',
    },
    {
      headerName: 'Ações',
      align: 'center',
      actions: {
        sendTo: 'vehicleTypeList',
        actionOpen: handleOpenView,
        actionDelete: handleOpenDialogDelete,
      },
    },
  ];

  useEffect(() => {
    async function loadVehicleType(): Promise<void> {
      try {
        const response = await loadVehicleTypeList(user.companyDocument);

        if (!response) return;

        setVehicleTypes(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadVehicleType();
  }, [loadVehicleTypeList, user.companyDocument]);

  function handleOpenView(id: number) {
    setOpenView(true);
    setVehicleTypeId(id);
  }

  function handleOpenDialogDelete(id: number) {
    setOpenDialogDelete(true);
    setVehicleTypeId(id);
  }

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleCloseView = useCallback(() => {
    setOpenView(false);
    setVehicleTypeId(null);
  }, []);

  const handleCloseDialogDelete = useCallback(() => {
    setOpenDialogDelete(false);
    setVehicleTypeId(null);
  }, []);

  const handleDeleteVehicleType = useCallback(async () => {
    if (vehicleTypeId) {
      await deleteVehicleType(vehicleTypeId);
      setVehicleTypes((prevState) =>
        prevState.filter((rest) => rest.id !== vehicleTypeId)
      );
      setVehicleTypeId(null);
      setOpenDialogDelete(false);
    }
  }, [deleteVehicleType, vehicleTypeId]);

  return (
    <Main
      title="Tipos de Veículos"
      button="add"
      buttonName="Novo tipo"
      to={ROUTES.createVehicleType}
      hasPermission="VEHICLE_TYPE_CREATE"
    >
      <Grid container spacing={1}>
        <VehicleTypeInfo
          open={openView}
          vehicleTypeId={vehicleTypeId}
          onClose={handleCloseView}
        />

        <ConfirmationDialog
          open={openDialogDelete}
          title="Deletar tipo do veículo"
          description="Deseja realmente deletar este tipo?"
          onSubmit={handleDeleteVehicleType}
          onClose={handleCloseDialogDelete}
        />

        <Grid item xs={12}>
          <Table
            columns={columns}
            rows={vehicleTypes}
            totalOfRows={vehicleTypes.length}
            currentPage={page}
            handlePageChange={handlePageChange}
            loading={loading}
            hasEditPermission="VEHICLE_TYPE_EDIT"
            hasRemovePermission="VEHICLE_TYPE_REMOVE"
          />
        </Grid>
      </Grid>
    </Main>
  );
};
