import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format, subDays } from 'date-fns';
import {
  Grid,
  TableRow,
  TableCell,
  Button,
  TextField,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';

import {
  useUserManagement,
  ILoadUser,
} from '../../../user-management/hooks/user-management';
import { useAuth } from '../../../../hooks/auth';
import { useRfid, PaginatedRfidOperationLogs } from '../../hooks/rfid';

import { generateRfidReportPDF } from '../../templates/rfidReport';
import XLSX from 'xlsx';

export const RFIDReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadUserList } = useUserManagement();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { filter, setFilter, loadRfid } = useRfid();
  const [drivers, setDrivers] = useState<ILoadUser[]>([]);
  const [rfidList, setRfidList] = useState<PaginatedRfidOperationLogs>({
    rfidOperationLogs: [],
    total: 0,
  });

  const columnsTable: IBasicColumn[] = [
    { name: 'Data' },
    { name: 'Códigos Lidos' },
    { name: 'Códigos Traduzidos' },
    { name: 'Motorista' },
    { name: 'Status' },
    { name: 'Informação' },
  ];

  const validationSchema = yup.object({
    initialDate: yup.date().required('Campo obrigatório!'),
    finalDate: yup
      .date()
      .required('Campo obrigatório!')
      .min(
        yup.ref('initialDate'),
        'Data fim não pode ser menor que a data início'
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filter,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  const formatDate = useCallback((date: string) => {
    return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
  }, []);

  useEffect(() => {
    async function loadDrivers(): Promise<void> {
      try {
        const response = await loadUserList(user.companyDocument);

        setDrivers(response.filter((user) => user.type === 'DRIVER'));
      } catch (error) {
        console.log(error);
      }
    }

    loadDrivers();
  }, [loadUserList, user.companyDocument]);

  useEffect(() => {
    async function loadRfidOperationLogs(): Promise<void> {
      setLoading(true);
      try {
        const response = await loadRfid(filter);

        if (!response) return;

        const data: any = {
          total: response.total,
          rfidOperationLogs: response.rfidOperationLogs.map((rfid) => {
            return {
              ...rfid,
              createdAt: formatDate(rfid.createdAt),
            };
          }),
        };

        setRfidList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadRfidOperationLogs();
  }, [filter, formatDate, loadRfid]);

  const handleReset = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleReset(e);
      setFilter({
        initialDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
        finalDate: format(new Date(), 'yyyy-MM-dd'),
        driverId: '',
      });
    },
    [formik, setFilter]
  );

  const downloadExcel = useCallback(() => {
    const newData = rfidList.rfidOperationLogs.map((rfid) => {
      return {
        createdAt: rfid.createdAt,
        codesRead: rfid.codesRead,
        codesTranslated: rfid.codesTranslated,
        driverName: rfid.driverName,
        correctConference: rfid.correctConference,
        inconsistency: rfid.inconsistency,
      };
    });

    const header = [
      [
        'Data',
        'Códigos Lidos',
        'Códigos Traduzidos',
        'Motorista',
        'Carga OK',
        'Informação',
      ],
    ];

    const workSheetCols = [
      { width: 12 },
      { width: 30 },
      { width: 30 },
      { width: 12 },
      { width: 12 },
      { width: 30 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      'Conferência Portal RFID'
    );

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Conferência Portal RFID.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfidList.rfidOperationLogs]);

  return (
    <Main title="Conferência Portal RFID">
      <Grid container spacing={1}>
        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => generateRfidReportPDF(rfidList.rfidOperationLogs)}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={downloadExcel}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1" id="filter">
              Filtros
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data início"
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={formik.values.initialDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.initialDate &&
                    Boolean(formik.errors.initialDate)
                  }
                  helperText={
                    formik.touched.initialDate && formik.errors.initialDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data fim"
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={formik.values.finalDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.finalDate && Boolean(formik.errors.finalDate)
                  }
                  helperText={
                    formik.touched.finalDate && formik.errors.finalDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Motorista"
                  id="driverId"
                  name="driverId"
                  value={formik.values.driverId}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">
                    <em>Vazio</em>
                  </MenuItem>
                  {drivers.map((driver) => (
                    <MenuItem value={driver.id}>{driver.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => formik.handleSubmit()}
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleReset}>Limpar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={rfidList.total}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {rfidList.rfidOperationLogs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rfid) => (
                <TableRow key={rfid.id}>
                  <TableCell component="th" scope="row">
                    {rfid.createdAt}
                  </TableCell>
                  <TableCell width={300}>
                    {rfid.codesRead.split(',').join(`,\n`)}
                  </TableCell>
                  <TableCell width={300}>
                    {rfid.codesTranslated
                      ? rfid.codesTranslated.split(',').join(`,\n`)
                      : ''}
                  </TableCell>
                  <TableCell width={200}>{rfid.driverName}</TableCell>
                  <TableCell>{rfid.correctConference}</TableCell>
                  <TableCell>{rfid.inconsistency}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
