import { useState } from 'react';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Loading } from '../Loading';
import { TableCell } from './TableCell';

const useStyles = makeStyles({
  head: {
    backgroundColor: '#fff',
  },
});

export interface IBasicColumn {
  name?: string;
  numeric?: true;
  content?: JSX.Element;
  padding?: 'checkbox';
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

interface IProps {
  columns: IBasicColumn[];
  loading?: boolean;
  total?: number;
  pagination?: [number, React.Dispatch<React.SetStateAction<number>>];
  rowsPerPageState?: [number, React.Dispatch<React.SetStateAction<number>>];
  size?: 'small' | undefined;
  children: React.ReactNode;
  headerWithNoColor?: boolean;
  style?: React.CSSProperties;
  innerRef?: React.Ref<any>;
  title?: string;
}

export const BasicTable = ({
  columns,
  loading,
  total,
  pagination,
  rowsPerPageState,
  size,
  children,
  headerWithNoColor,
  style,
  innerRef,
  title,
}: IProps) => {
  const classes = useStyles();
  const [page, setPage] = pagination || [undefined, undefined];
  const [rowsPerPage, setRowsPerPage] = rowsPerPageState || [
    undefined,
    undefined,
  ];
  const [rowsPerPageOptions] = useState([5, 10, 25, 50, 100]);
  const hasPagination =
    rowsPerPage !== undefined && page !== undefined && total !== undefined;
  const emptyRows = hasPagination
    ? rowsPerPage - Math.min(rowsPerPage, total - page * rowsPerPage)
    : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (setPage) setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (setPage && setRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };

  return (
    <>
      {title && (
        <Toolbar style={{ paddingLeft: 16, height: 'auto', minHeight: 0 }}>
          <Typography
            style={{ flex: '1 1 100%' }}
            variant="subtitle1"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        </Toolbar>
      )}
      <TableContainer innerRef={innerRef} style={style}>
        <Table aria-label="table" size={size}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  className={clsx(headerWithNoColor && classes.head)}
                  key={index}
                  align={column.numeric ? 'right' : column.align}
                  padding={column.padding}
                >
                  {column.name} {column.content}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loading columns={columns.length} rows={rowsPerPage} />
            ) : (
              children
            )}

            {!loading && emptyRows > 0 && (
              <TableRow
                style={{ height: (size === 'small' ? 33 : 53) * emptyRows }}
              >
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
