import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { useStyles, Form } from './styles';
import { INuvemshopRequest, useNuvemshop } from '../../hooks/nuvemshop';

import { Button } from '../../../../components/Button';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const Nuvemshop: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { loadNuvemshop, saveNuvemshop } = useNuvemshop();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingNuvemshop, setLoadingNuvemshop] = useState(true);
  const [data, setData] = useState<INuvemshopRequest>({
    sendNotification: false,
    markAsPackaged: false,
    statusForIntegration: '',
    accessToken: '',
  });

  useEffect(() => {
    async function loadIntegration(): Promise<void> {
      try {
        const response = await loadNuvemshop();

        if (!response) return;

        setData({
          ...response,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingNuvemshop(false);
      }
    }
    if (props.open) loadIntegration();
  }, [loadNuvemshop, props.open]);

  const validationSchema = yup.object().shape({
    sendNotification: yup.boolean(),
    markAsPackaged: yup.boolean(),
    statusForIntegration: yup.string(),
    accessToken: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        await saveNuvemshop(values);
        props.onClose();
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const handleClose = useCallback(() => {
    props.onClose();
    formik.resetForm();
    setLoadingNuvemshop(true);
  }, [props, formik]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">NUVEMSHOP</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={formik.handleSubmit}
          noValidate
          className={classes.form}
        >
          <Grid container spacing={3}>
            {loadingNuvemshop ? (
              <Grid item xs={12}>
                <Typography component="div" align="center">
                  <CircularProgress />
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Token de Acesso"
                    id="accessToken"
                    name="accessToken"
                    value={formik.values.accessToken}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.accessToken &&
                      Boolean(formik.errors.accessToken)
                    }
                    helperText={
                      formik.touched.accessToken && formik.errors.accessToken
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="markAsPackaged"
                          name="markAsPackaged"
                          value={formik.values.markAsPackaged}
                          onChange={formik.handleChange}
                          checked={formik.values.markAsPackaged}
                          color="primary"
                        />
                      }
                      label={<Typography>Marcar como Embalado</Typography>}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="sendNotification"
                          name="sendNotification"
                          value={formik.values.sendNotification}
                          onChange={formik.handleChange}
                          checked={formik.values.sendNotification}
                          color="primary"
                        />
                      }
                      label={<Typography>Enviar Notificação</Typography>}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    required
                    fullWidth
                    label="Status para Integração"
                    id="statusForIntegration"
                    name="statusForIntegration"
                    value={formik.values.statusForIntegration}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.statusForIntegration &&
                      Boolean(formik.errors.statusForIntegration)
                    }
                    helperText={
                      formik.touched.statusForIntegration &&
                      formik.errors.statusForIntegration
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Selecione o status</em>
                    </MenuItem>
                    <MenuItem value="all">Todos</MenuItem>
                    <MenuItem value="paid">Pago</MenuItem>
                    <MenuItem value="pending">Aguardando Pagamento</MenuItem>
                  </TextField>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Fechar
        </Button>

        <Button
          onClick={() => formik.handleSubmit()}
          variant="contained"
          color="secondary"
          loading={loadingButton}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
