import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { PERMISSIONS, ROUTES } from '../../../constants';

import { Inventory } from '../pages/Inventory';
import { UserManagementProvider } from '../../user-management/hooks/user-management';

export const InventoryRoute: React.FC = () => (
  <UserManagementProvider>
    <Switch>
      <PrivateRoute
        path={ROUTES.inventory}
        permission={PERMISSIONS.INVENTORY_VIEW}
        component={Inventory}
      />
    </Switch>
  </UserManagementProvider>
);
