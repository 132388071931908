import {
  Avatar,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { addDays, format, lastDayOfMonth } from 'date-fns';
import { useEffect, useRef, useState } from 'react';

import { FrequencyEnum, Recurrence } from '../../modules/form/hooks/form';
import { useStyles } from './styles';

type DeadlineType = 'never' | 'in';

type DayOrPeriodType = 'day' | 'period';

type FrequencyEnumWithoutNoAndCustom = Exclude<
  FrequencyEnum,
  FrequencyEnum.NO | FrequencyEnum.CUSTOM
>;

const frequencyEnumValues = [
  FrequencyEnum.DAILY,
  FrequencyEnum.WEEKLY,
  FrequencyEnum.MONTHLY,
  FrequencyEnum.YEARLY,
] as FrequencyEnumWithoutNoAndCustom[];

const frequencyLabel = {
  [FrequencyEnum.DAILY]: {
    singular: 'dia',
    plural: 'dias',
  },
  [FrequencyEnum.WEEKLY]: {
    singular: 'semana',
    plural: 'semanas',
  },
  [FrequencyEnum.MONTHLY]: {
    singular: 'mês',
    plural: 'meses',
  },
  [FrequencyEnum.YEARLY]: {
    singular: 'ano',
    plural: 'anos',
  },
};

const daysOfWeek = [
  { label: 'Domingo', name: 'Sunday', shortName: 'sun' },
  { label: 'Segunda-Feira', name: 'Monday', shortName: 'mon' },
  { label: 'Terça-Feira', name: 'Tuesday', shortName: 'tue' },
  { label: 'Quarta-Feira', name: 'Wednesday', shortName: 'wed' },
  { label: 'Quinta-Feira', name: 'Thursday', shortName: 'thu' },
  { label: 'Sexta-Feira', name: 'Friday', shortName: 'fri' },
  { label: 'Sábado', name: 'Saturday', shortName: 'sat' },
];

const daysOfMonth = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const periods = [
  { label: 'Primeiro(a)', value: 1 },
  { label: 'Segundo(a)', value: 2 },
  { label: 'Terceiro(a)', value: 3 },
  { label: 'Quarto(a)', value: 4 },
];

const months = {
  January: {
    label: 'Janeiro',
    value: 1,
  },
  February: {
    label: 'Fevereiro',
    value: 2,
  },
  March: {
    label: 'Março',
    value: 3,
  },
  April: {
    label: 'Abril',
    value: 4,
  },
  May: {
    label: 'Maio',
    value: 5,
  },
  June: {
    label: 'Junho',
    value: 6,
  },
  July: {
    label: 'Julho',
    value: 7,
  },
  August: {
    label: 'Agosto',
    value: 8,
  },
  September: {
    label: 'Setembro',
    value: 9,
  },
  October: {
    label: 'Outubro',
    value: 10,
  },
  November: {
    label: 'Novembro',
    value: 11,
  },
  December: {
    label: 'Dezembro',
    value: 12,
  },
};

type MonthKeyType = keyof typeof months;

const monthsKeyArray = Object.keys(months) as MonthKeyType[];

interface RecurrenceModalProps {
  frequency: FrequencyEnum | null;
  onSave(recurrence: Recurrence): void;
  onClose(): void;
}

export const RecurrenceModal = ({
  frequency,
  onSave,
  onClose,
}: RecurrenceModalProps) => {
  const classes = useStyles();

  const firstRender = useRef(true);
  const [open, setOpen] = useState(false);
  const [repeatTime, setRepeatTime] = useState(1);
  const [selectedFrequency, setSelectedFrequency] =
    useState<FrequencyEnum | null>(null);
  const [selectedWeekDays, setSelectedWeekDays] = useState<string[]>([]);
  const [deadline, setDeadline] = useState<DeadlineType>('never');
  const [expireIn, setExpireIn] = useState(
    format(addDays(new Date(), 7), 'yyyy-MM-dd')
  );
  const [dayOrPeriod, setDayOrPeriod] = useState<DayOrPeriodType>('day');
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState<number>(1);
  const [selectedPeriod, setSelectedPeriod] = useState<number>(1);
  const [selectedDayOfWeekPeriod, setSelectedDayOfWeekPeriod] =
    useState<string>('sun');
  const [selectedMonth, setSelectedMonth] = useState<MonthKeyType>(
    format(new Date(), 'LLLL') as MonthKeyType
  );
  const lastDayOfSelectedMonth = lastDayOfMonth(
    new Date(new Date().getFullYear(), months[selectedMonth].value - 1)
  ).getDate();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (
        frequency &&
        frequency !== FrequencyEnum.NO &&
        frequency !== FrequencyEnum.DAILY
      ) {
        setOpen(true);

        if (frequency === FrequencyEnum.CUSTOM) {
          setSelectedFrequency(FrequencyEnum.WEEKLY);
        } else {
          setSelectedFrequency(frequency);
        }
      }
    }
  }, [frequency]);

  function handleSelectDay(shortName: string) {
    setSelectedWeekDays((prevState) => {
      const newArray = [...prevState];

      const shortNameIndex = newArray.indexOf(shortName);

      if (shortNameIndex > -1) {
        newArray.splice(shortNameIndex, 1);
      } else {
        newArray.push(shortName);
      }

      return newArray;
    });
  }

  function handleClose() {
    setOpen(false);
    onClose();
    setRepeatTime(1);
    setSelectedFrequency(FrequencyEnum.WEEKLY);
    setSelectedWeekDays([]);
    setDeadline('never');
    setExpireIn(format(addDays(new Date(), 7), 'yyyy-MM-dd'));
    setDayOrPeriod('day');
    setSelectedDayOfMonth(1);
    setSelectedPeriod(1);
    setSelectedDayOfWeekPeriod('sun');
    setSelectedMonth(format(new Date(), 'LLLL') as MonthKeyType);
  }

  function handleSave() {
    onSave({
      frequency: repeatTime !== null ? selectedFrequency! : frequency!,
      expireIn: deadline === 'in' ? expireIn : null,
      config: {
        repeatTime: frequency === FrequencyEnum.CUSTOM ? repeatTime : null,
        weekDays:
          selectedFrequency === FrequencyEnum.WEEKLY
            ? selectedWeekDays
            : (selectedFrequency === FrequencyEnum.MONTHLY ||
                selectedFrequency === FrequencyEnum.YEARLY) &&
              dayOrPeriod === 'period'
            ? [selectedDayOfWeekPeriod]
            : null,
        day:
          (selectedFrequency === FrequencyEnum.MONTHLY ||
            selectedFrequency === FrequencyEnum.YEARLY) &&
          dayOrPeriod === 'day'
            ? selectedDayOfMonth
            : null,
        month:
          selectedFrequency === FrequencyEnum.YEARLY
            ? months[selectedMonth].value
            : null,
        monthWeek:
          (selectedFrequency === FrequencyEnum.MONTHLY ||
            selectedFrequency === FrequencyEnum.YEARLY) &&
          dayOrPeriod === 'period'
            ? selectedPeriod
            : null,
      },
    });

    handleClose();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="recurrence-title"
      aria-describedby="recurrence-content"
    >
      <DialogTitle id="recurrence-title">Recorrência</DialogTitle>
      <DialogContent id="recurrence-content">
        {frequency === FrequencyEnum.CUSTOM && (
          <Toolbar
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
              padding: 0,
              marginBottom: '1rem',
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Repetir a cada:</Typography>

            <TextField
              size="small"
              variant="outlined"
              type="number"
              value={repeatTime}
              onChange={(event) => setRepeatTime(parseInt(event.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 1,
              }}
              style={{ maxWidth: '70px' }}
            />

            <TextField
              select
              size="small"
              variant="outlined"
              value={selectedFrequency}
              disabled={frequency !== FrequencyEnum.CUSTOM}
              onChange={(event) =>
                setSelectedFrequency(event.target.value as FrequencyEnum)
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              {frequencyEnumValues.map((value) => {
                const { singular, plural } = frequencyLabel[value];

                return (
                  <MenuItem key={value} value={value}>
                    {repeatTime > 1 ? plural : singular}
                  </MenuItem>
                );
              })}
            </TextField>
          </Toolbar>
        )}

        <Collapse
          in={selectedFrequency === FrequencyEnum.WEEKLY}
          timeout="auto"
          unmountOnExit
        >
          <Toolbar style={{ display: 'flex', gap: 8, marginBottom: 8 }}>
            <Typography style={{ fontWeight: 500 }}>Repetir:</Typography>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {daysOfWeek.map((day) => {
                const firstLetter = day.label.charAt(0);

                return (
                  <Tooltip key={day.name} placement="top" title={day.label}>
                    <Avatar
                      className={
                        selectedWeekDays.includes(day.shortName)
                          ? classes.active
                          : classes.root
                      }
                      onClick={() => handleSelectDay(day.shortName)}
                    >
                      {firstLetter}
                    </Avatar>
                  </Tooltip>
                );
              })}
            </div>
          </Toolbar>
        </Collapse>

        <Collapse
          in={selectedFrequency === FrequencyEnum.YEARLY}
          timeout="auto"
          unmountOnExit
        >
          <Toolbar
            style={{
              display: 'flex',
              gap: 8,
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Selecionar mês</Typography>

            <TextField
              select
              size="small"
              variant="outlined"
              value={selectedMonth}
              onChange={(event) =>
                setSelectedMonth(event.target.value as MonthKeyType)
              }
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                MenuProps: { style: { maxHeight: '400px' } },
              }}
            >
              {monthsKeyArray.map((month) => (
                <MenuItem key={month} value={month}>
                  {months[month].label}
                </MenuItem>
              ))}
            </TextField>
          </Toolbar>
        </Collapse>

        <Collapse
          in={
            selectedFrequency === FrequencyEnum.MONTHLY ||
            selectedFrequency === FrequencyEnum.YEARLY
          }
          timeout="auto"
          unmountOnExit
        >
          <Toolbar
            style={{
              display: 'flex',
              gap: 8,
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginBottom: 8,
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Selecionar por</Typography>

            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                value={dayOrPeriod}
                onChange={(event) => {
                  setDayOrPeriod(event.target.value as DayOrPeriodType);
                }}
              >
                <div>
                  <FormControlLabel
                    value="day"
                    control={<Radio />}
                    label="Dia"
                    style={{ marginRight: 50 }}
                  />

                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    value={selectedDayOfMonth}
                    onChange={(event) =>
                      setSelectedDayOfMonth(parseInt(event.target.value))
                    }
                    disabled={dayOrPeriod === 'period'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      MenuProps: { style: { maxHeight: '400px' } },
                    }}
                  >
                    {selectedFrequency === FrequencyEnum.YEARLY
                      ? daysOfMonth.map((value) => {
                          if (value <= lastDayOfSelectedMonth) {
                            return (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            );
                          }
                          return null;
                        })
                      : daysOfMonth.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                  </TextField>
                </div>

                <div>
                  <FormControlLabel
                    value="period"
                    control={<Radio />}
                    label="Período"
                  />

                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    value={selectedPeriod}
                    onChange={(event) =>
                      setSelectedPeriod(parseInt(event.target.value))
                    }
                    disabled={dayOrPeriod === 'day'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginRight: 8 }}
                  >
                    {periods.map((period) => (
                      <MenuItem key={period.value} value={period.value}>
                        {period.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    value={selectedDayOfWeekPeriod}
                    onChange={(event) =>
                      setSelectedDayOfWeekPeriod(event.target.value)
                    }
                    disabled={dayOrPeriod === 'day'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {daysOfWeek.map((value) => (
                      <MenuItem key={value.name} value={value.shortName}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </RadioGroup>
            </FormControl>
          </Toolbar>
        </Collapse>

        <Toolbar
          style={{
            display: 'flex',
            gap: 8,
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography style={{ fontWeight: 500 }}>Termina em</Typography>

          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={deadline}
              onChange={(event) => {
                setDeadline(event.target.value as DeadlineType);
              }}
            >
              <FormControlLabel
                value="never"
                control={<Radio />}
                label="Nunca"
              />

              <div>
                <FormControlLabel value="in" control={<Radio />} label="Em" />

                <TextField
                  size="small"
                  variant="outlined"
                  type="date"
                  value={expireIn}
                  onChange={(event) => setExpireIn(event.target.value)}
                  disabled={deadline === 'never'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </Toolbar>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Fechar
        </Button>

        <Button color="secondary" onClick={handleSave}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
