import { useTheme } from '@material-ui/core/styles';
// import NearMeIcon from '@material-ui/icons/NearMe';
import { FaRoute } from 'react-icons/fa';
import { lighten } from 'polished';

import { Card, ICardsProps } from '../../../../components/Card';
import { useSettings } from '../../../../hooks/settings';

export const TotalRoutesCard = (
  props: Pick<ICardsProps, 'content' | 'percentage' | 'loading' | 'onClick'>
) => {
  const theme = useTheme();
  const { cardsLoading } = useSettings();

  return (
    <Card
      title="Total"
      icon={<FaRoute />}
      cardColor={lighten(0.325, theme.palette.warning.main)}
      loading={cardsLoading}
      {...props}
    />
  );
};
