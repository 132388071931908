import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import XLSX from 'xlsx';
import {
  Grid,
  Button as MUIButton,
  Typography,
  Box,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { useFormik } from 'formik';

import exampleSpreadsheet from '../../../../../assets/docs/example-spreadsheet-order.xlsx';
import { sheetToJson } from '../../../../../utils';
import { Button } from '../../../../../components/Button';
import { useOrder } from '../../../hooks/order';

export const Excel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { importSpreadsheet } = useOrder();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('Nenhum arquivo selecionado');
  const [spreadsheetProperties] = useState([
    // { value: 'distributionCenter', label: 'Centro de Distribuição' },
    // { value: 'orderCode', label: 'Código Pedido' },
    { value: 'orderNumber', label: 'Número Pedido' },
    // { value: 'orderDate', label: 'Data Pedido' },
    // { value: 'paymentStatus', label: 'Status Pagamento' },
    // { value: 'shippingStatus', label: 'Status Entrega' },
    // { value: 'shippingCost', label: 'Valor Frete' },
    // { value: 'discount', label: 'Valor Desconto' },
    { value: 'value', label: 'Valor Total' },
    // { value: 'orderWeight', label: 'Peso Pedido' },
    // { value: 'shippingType', label: 'Tipo Frete' },
    { value: 'documentNumber', label: 'Documento Cliente' },
    { value: 'name', label: 'Nome Cliente' },
    // { value: 'email', label: 'Email' },
    // { value: 'phone', label: 'Telefone' },
    // { value: 'cellphone', label: 'Celular' },
    // { value: 'street', label: 'Rua' },
    { value: 'number', label: 'Número' },
    // { value: 'complement', label: 'Complemento' },
    // { value: 'neighborhood', label: 'Bairro' },
    // { value: 'city', label: 'Cidade' },
    // { value: 'state', label: 'Estado' },
    { value: 'postalcode', label: 'CEP' },
    // { value: 'region', label: 'Região' },
    // { value: 'productId', label: 'Id Produto' },
    // { value: 'variantId', label: 'Id Variacao' },
    { value: 'sku', label: 'SKU' },
    { value: 'barcode', label: 'Barcode' },
    { value: 'description', label: 'Descricao' },
    { value: 'price', label: 'Valor Unitário' },
    { value: 'quantity', label: 'Quantidade' },
    // { value: 'weight', label: 'Peso' },
    // { value: 'depth', label: 'Profundidade' },
    // { value: 'height', label: 'Altura' },
    // { value: 'width', label: 'Largura' },
  ]);

  const validationSchema = yup.object({
    file: yup
      .array()
      .min(1, 'Faça upload de uma planilha')
      .test(
        'validateSpreadsheetColumns',
        'Insira uma planilha válida',
        (value: any) => {
          if (value && value.length > 0) {
            const firstObjectToValidate = value[0];

            return validateSpreadsheetColumns(firstObjectToValidate);
          } else {
            return true;
          }
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      file: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const newJsonArr = values.file.map((x: any) => {
          var obj: { [k: string]: any } = {};

          spreadsheetProperties.map((property) => {
            if (property.value === 'orderDate')
              return (obj[property.value] = XLSX.SSF.format(
                'yyyy-mm-dd HH:MM',
                x[property.label]
              ));
            else return (obj[property.value] = x[property.label]);
          });

          return obj;
        });

        await importSpreadsheet(newJsonArr);

        enqueueSnackbar('Planilha de pedido importada!', {
          variant: 'success',
        });
      } catch (error: any) {
        const errors: string[] = error.errors[0];
        const orderNumbersRegEx = errors.map((value) => /\d+/g.exec(value));
        let orderNumbers = '';

        orderNumbersRegEx.forEach((number, index) => {
          index < orderNumbersRegEx.length - 1
            ? (orderNumbers += `${number}, `)
            : (orderNumbers += `${number}.`);
        });

        enqueueSnackbar(
          `Não foi possível importar os pedidos: ${orderNumbers}`,
          {
            variant: 'error',
          }
        );
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const validateSpreadsheetColumns = useCallback(
    (obj) => {
      let isValid = true;

      const keys = Object.keys(obj);

      spreadsheetProperties.forEach((property) => {
        if (isValid && !keys.includes(property.label)) {
          isValid = false;
        }
      });

      return isValid;
    },
    [spreadsheetProperties]
  );

  const uploadFile = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = event.target;

      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files[0]
      ) {
        const file = event.target.files[0];
        const spreadsheet = await sheetToJson(file);

        setFileName(file.name);
        formik.setFieldValue(name, spreadsheet);
      } else {
        setFileName('Nenhum arquivo selecionado');
        formik.setFieldValue(name, []);
      }
    },
    [formik]
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
      style={{ minHeight: 125 }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <input
              id="file"
              name="file"
              type="file"
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <label htmlFor="file">
              <MUIButton
                variant="outlined"
                component="span"
                color="default"
                size="small"
              >
                Upload do arquivo
              </MUIButton>
            </label>
          </Grid>

          <Grid item>
            <Typography component="span">{fileName}</Typography>
          </Grid>

          <Grid item xs={12}>
            {formik.touched.file && (
              <FormHelperText error style={{ textAlign: 'center' }}>
                {formik.errors.file}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs>
          <Box mt={1} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              loading={loading}
            >
              Enviar
            </Button>
          </Box>
        </Grid>

        <Grid item xs>
          <Link
            color="secondary"
            href={exampleSpreadsheet}
            target="_blank"
            download="planilha_exemplo_pedido"
          >
            Baixar planilha modelo
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
