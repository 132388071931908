import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Main } from '../../../../components/Main';
import { RouteFilter } from '../../../route/components/RouteFilter';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';
import { CollapseRow } from '../../../../components/Table/CollapseRow';
import { routeStatusLabel, activityStatusLabel } from '../../../../utils';

import { useAuth } from '../../../../hooks/auth';
import { useRoute, IRouteLight } from '../../../route/hooks/route';
import { ActivityProvider } from '../../../route/hooks/activity';

import { generateRouteReportPDF } from '../../templates/routeReport';
import XLSX from 'xlsx';

export const RouteReport = () => {
  const {
    data: { user },
  } = useAuth();
  const { loadRouteList, filter } = useRoute();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState<number[]>([]);
  const [routes, setRoutes] = useState<IRouteLight[]>([]);

  const tableHeader: IBasicColumn[] = [
    { name: '', padding: 'checkbox' },
    { name: 'Rota' },
    { name: 'Data' },
    { name: 'Motorista' },
    { name: 'Status' },
  ];

  const subtableHeader: IBasicColumn[] = [
    { name: 'Ordem', align: 'right' },
    { name: 'Pedido', align: 'right' },
    { name: 'Cliente' },
    { name: 'Status' },
  ];

  const formatDate = useCallback((date: string) => {
    return date.split('T')[0].split('-').reverse().join('/');
  }, []);

  useEffect(() => {
    async function loadRouteReport(): Promise<void> {
      setLoading(true);

      const { initialDate, finalDate, status, driverId } = filter;

      const query =
        `?companyDocument=${user.companyDocument}` +
        `&page=${page + 1}` +
        `${initialDate && `&initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}` +
        `${status && `&status=${status}`}` +
        `${driverId && `&driverId=${driverId}`}`;

      try {
        const response = await loadRouteList(query);

        if (!response) return;

        const data: any = response.routes.map((route) => {
          return {
            ...route,
            startRouteDate: formatDate(route.startRouteDate),
            status: routeStatusLabel(route.status),
            activities: route.activities.map((activity) => {
              return {
                ...activity,
                status: activityStatusLabel(activity.status),
              };
            }),
          };
        });

        setRoutes(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadRouteReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, loadRouteList, setRoutes, user.companyDocument]);

  const handleCollapse = useCallback((id: number) => {
    setIsOpen((prevState) => {
      const idIndex = prevState.findIndex((prev) => prev === id);
      if (idIndex > -1) prevState.splice(idIndex, 1);

      const newArray = idIndex > -1 ? [...prevState] : [...prevState, id];

      return newArray;
    });
  }, []);

  const downloadExcel = useCallback(() => {
    const tableBody = routes.flatMap((route) => {
      const header: any = [
        `ROTA: ${route.routerName}`,
        `DATA: ${route.startRouteDate}`,
        `MOTORISTA: ${route.driver.driverName}`,
        `STATUS: ${route.status}`,
      ];

      const subHeader = ['ORDEM', 'PEDIDO', 'CLIENTE', 'STATUS'];

      if (route.activities && route.activities.length > 0) {
        const body = route.activities.map((activity) => {
          return [
            activity.ordem,
            activity.number,
            activity.customer.name,
            activity.status,
          ];
        });

        return [header, subHeader, ...body];
      } else {
        return [header, subHeader];
      }
    });

    const workSheetCols = [
      { width: 18 },
      { width: 16 },
      { width: 45 },
      { width: 28 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(tableBody);
    XLSX.utils.sheet_add_aoa(workSheet, tableBody);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'ROTAS');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Rotas.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  return (
    <Main title="Relatório de Rotas">
      <Grid container spacing={1}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => generateRouteReportPDF(routes)}
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={downloadExcel}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <RouteFilter />

        <Grid item xs={12}>
          <BasicTable
            columns={tableHeader}
            loading={loading}
            total={routes.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {routes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((route) => {
                const isCollapseRowOpen = isOpen.includes(route.id);

                return (
                  <ActivityProvider key={route.id}>
                    <React.Fragment>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => handleCollapse(route.id)}
                            aria-label="expand collapse row"
                          >
                            {isCollapseRowOpen ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {route.routerName}
                        </TableCell>
                        <TableCell>{route.startRouteDate}</TableCell>
                        <TableCell>{route.driver.driverName}</TableCell>
                        <TableCell>{route.status}</TableCell>
                      </TableRow>

                      <CollapseRow
                        open={isCollapseRowOpen}
                        colSpan={tableHeader.length}
                        noPadding
                      >
                        {!!route.activities && route.activities.length > 0 ? (
                          <>
                            <BasicTable
                              size="small"
                              headerWithNoColor
                              columns={subtableHeader}
                            >
                              {route.activities.map((activity) => {
                                return (
                                  <TableRow key={activity.id}>
                                    <TableCell align="right">
                                      {activity.ordem}
                                    </TableCell>
                                    <TableCell align="right">
                                      {activity.number}
                                    </TableCell>
                                    <TableCell>
                                      {activity.customer.name}
                                    </TableCell>
                                    <TableCell>{activity.status}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </BasicTable>
                          </>
                        ) : (
                          <Grid
                            item
                            container
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography variant="h6" gutterBottom>
                              Não há itens a serem exibidos
                            </Typography>
                          </Grid>
                        )}
                      </CollapseRow>
                    </React.Fragment>
                  </ActivityProvider>
                );
              })}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
