import { Button } from '@material-ui/core';
import { useMemo } from 'react';

import { useSignUp, TypeEnum } from '../../hooks/signUp';

import { Container } from './styles';

export const Buttons = () => {
  const {
    documentType,
    profileType,
    formActive,
    setFormActive,
    setActiveStep,
  } = useSignUp();

  const FIRST_FORM = 0;
  const TYPE_CNPJ_ONLY = [TypeEnum.SHIPPER, TypeEnum.SHIPPING_COMPANY];

  const title = useMemo(() => {
    if (formActive === 0) {
      if (documentType === 'cnpj') {
        return 'Próximo';
      } else {
        return 'Finalizar';
      }
    } else {
      return 'Finalizar';
    }
  }, [documentType, formActive]);

  return (
    <Container>
      {formActive === FIRST_FORM ? (
        TYPE_CNPJ_ONLY.includes(profileType) ? (
          <Button color="inherit" onClick={() => setActiveStep(0)}>
            Voltar
          </Button>
        ) : (
          <Button
            color="inherit"
            onClick={() => setActiveStep((prevState) => prevState - 1)}
          >
            Voltar
          </Button>
        )
      ) : (
        <Button
          color="inherit"
          onClick={() => setFormActive((prevState) => prevState - 1)}
        >
          Voltar
        </Button>
      )}

      <Button type="submit" variant="contained" >
        {title}
      </Button>
    </Container>
  );
};
