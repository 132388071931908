import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { ObservationModal } from '../ObservationModal';

import { Loading } from '../../../../../components/Loading';
import { useAuth } from '../../../../../hooks/auth';
import { useRoute } from '../../../hooks/route';
import { ActivityProvider } from '../../../hooks/activity';
import { RouteRow } from '../Row';
import { useModal } from '../../../../../hooks/modal';
import { useSnackbar } from 'notistack';

interface IProps {
  date: string;
}

export const RouteTable = ({ date }: IProps) => {
  const {
    data: { user },
  } = useAuth();
  const { open } = useModal();
  const {
    routeList,
    setRouteList,
    loadRouteListLight,
    routeObservation,
    reprocessRouteMap,
    filter,
  } = useRoute();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, routeList.total - page * rowsPerPage);

  useEffect(() => {
    async function loadOrders() {
      setLoading(true);

      const { status, driverId, orderNumber, routerName } = filter;

      const query =
        `${status && `&status=${status}`}` +
        `${orderNumber && `&orderNumber=${orderNumber}`}` +
        `${driverId && `&driverId=${driverId}`}` +
        `${routerName && `&routerName=${routerName}`}`;

      try {
        const splitdate = date.split('/');

        const DAY = splitdate[0];
        const MONTH = splitdate[1];
        const YEAR = splitdate[2];
        const dateRefactored = `${YEAR}-${MONTH}-${DAY}`;

        const response = await loadRouteListLight(
          user.companyDocument,
          dateRefactored,
          page,
          rowsPerPage,
          query
        );

        if (!response) return;

        setRouteList(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadOrders();
  }, [
    date,
    filter,
    loadRouteListLight,
    page,
    rowsPerPage,
    setRouteList,
    user.companyDocument,
  ]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="route table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Código</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Motorista</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Previsão</TableCell>
              <TableCell align="right">Distância</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <Loading columns={8} rows={rowsPerPage} />
            ) : (
              routeList.routes.map((route) => (
                <ActivityProvider key={route.id}>
                  <RouteRow
                    onReprocessRouteMapClick={async (routeId) => {
                      reprocessRouteMap(routeId).then(() => {
                        enqueueSnackbar(
                          'Mapa da Rota Reprocessada com Sucesso!',
                          { variant: 'success' }
                        );
                      });
                    }}
                    onAddObservationClick={(id, rowObservation) =>
                      open(ObservationModal, {
                        name: route.routerName,
                        observation: rowObservation,
                        onSubmit: (observation: string) =>
                          routeObservation(id, observation),
                      })
                    }
                    route={route}
                  />
                </ActivityProvider>
              ))
            )}

            {!loading && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={routeList.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
