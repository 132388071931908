import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  img: {
    width: '60%',
    height: 'auto',
    filter: 'grayscale(100%)',
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 10,

    '&:hover': {
      filter: 'grayscale(0%)',
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 35,
  },
}));
