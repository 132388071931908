import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  FormControlLabel,
  TextField,
  Checkbox,
  Link,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { cnpjValidation, maskCnpj, unmask } from '../../../../utils';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { ROUTES } from '../../../../constants';
import { Button } from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import { useForgotPassword } from '../../hooks/forgotPassword';

import { useStyles } from './styles';
import { useModal } from '../../../../hooks/modal';
import { RedirectModal } from '../RedirectModal';

export const SignIn = () => {
  const history = useHistory();
  const classes = useStyles();
  const { signIn } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCnpj, setIsCnpj] = useState(false);
  const [platformUrl, setPlatformUrl] = useState<string | null>(null);

  const { setIsForgotPassword, isForgotPassword, setActiveStepForgotPassword } =
    useForgotPassword();

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleClickForgotPassword = useCallback(() => {
    setIsForgotPassword(!isForgotPassword);
    setActiveStepForgotPassword(0);
  }, [isForgotPassword, setActiveStepForgotPassword, setIsForgotPassword]);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  useEffect(() => {
    if (platformUrl) {
      open(RedirectModal, { url: platformUrl });
    }
  }, [open, platformUrl]);

  const validationSchema = yup.object({
    username: yup
      .string()
      .required('Usuário é obrigatório')
      .email('E-mail Inválido!'),
    password: yup.string().required('Senha é obrigatória'),
  });

  const validationSchemaIsCnpj = yup.object({
    username: yup
      .string()
      .required('Usuário é obrigatório')
      .test('cnpj-is-validate', 'CNPJ Inválido!', (value) =>
        cnpjValidation(value)
      ),
    password: yup.string().required('Senha é obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: isCnpj ? validationSchemaIsCnpj : validationSchema,
    onSubmit: async (values): Promise<any> => {
      setLoading(true);
      setPlatformUrl(null);

      try {
        const { error: userUnauthorization, urlPlatform } = await signIn(
          values
        );

        setPlatformUrl(urlPlatform || null);

        if (userUnauthorization || urlPlatform) {
          const message = 'Usuário não tem permissão para acessar';

          enqueueSnackbar(message, {
            variant: userUnauthorization ? 'error' : 'info',
          });
        } else {
          history.push(ROUTES.dashboard);
        }
      } catch (error: any) {
        if (error.status === 401) {
          const message = 'Usuário ou senha incorretos';

          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleChangeValueWithMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      let newValue;
      let unmaskedValue = unmask(value);
      const onlyNumbers = !unmaskedValue.match(/[^0-9]/g);

      if (onlyNumbers) {
        setIsCnpj(true);
        newValue = unmask(maskCnpj(value));
      } else {
        setIsCnpj(false);
        newValue = isCnpj ? unmask(value) : value;
      }

      formik.setFieldValue(name, newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik]
  );

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      style={{ width: '100%', padding: '1rem' }}
    >
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Usuário"
        placeholder="Digite seu usuário (CNPJ/e-mail)"
        id="username"
        name="username"
        autoFocus
        value={
          isCnpj ? maskCnpj(formik.values.username) : formik.values.username
        }
        onChange={handleChangeValueWithMask}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Senha"
        placeholder="Digite sua senha"
        id="password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Grid container alignItems="center" alignContent="center">
        <Grid item xs>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar minha senha"
          />
        </Grid>

        <Grid item>
          <Link
            component="button"
            type="button"
            variant="body2"
            onClick={handleClickForgotPassword}
          >
            Esqueceu sua senha?
          </Link>
        </Grid>
      </Grid>

      <Button
        style={{backgroundColor: '#570864'}}
        type="submit"
        fullWidth
        variant="contained"
        loading={loading}
        colorLoading={undefined}
        className={classes.button}
      >
        Entrar
      </Button>
    </form>
  );
};
