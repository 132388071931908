import React, { useContext, createContext, useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import api from '../../../services/api';
import { ActivityStatus, RegionStatus } from '../../../constants';
import { useRoute } from './route';
import { IOrder, IOrderImage } from '../../order/hooks/order';
import { IForm, QuestionType } from '../../form/hooks/form';

export type IRouteActivityReport = Omit<IActivity, 'images'> & {
  images: { filename: string; type: string; base64: string }[];
  order: Omit<IOrder, 'items' | 'activity' | 'customer' | 'shippingAddress'>;
  filledForm?: {
    id: number;
    dateFilled: string;
    companyDocument: string;
    formConfigId: number;
    formConfig: IForm
    data: {
      id: number;
      order: number;
      required: boolean;
      subject: string;
      type: QuestionType;
      options: string[];
      value?: string;
      observation?: string;
      valueCheckbox?: string[];
    }[];
    identifierId: number;
    images: [];
    userId: number;
  };
};

interface IParcels {
  id: number;
  bipado: boolean;
  cancel: boolean;
  code: string;
  quantity: number;
  online: boolean;
  dateTimeCapture: string;
  reason: string;
  sku: string;
  description: string;
}

export interface IActivity {
  id: number;
  orderCode: string;
  orderDate: string;
  number: string;
  ordem: number;
  open: boolean;
  type: number;
  infoType: number;
  quantityBips: number;
  quantityItens: number;
  prevArriveDate: string;
  prevArriveHour: string;
  prevDepartureDate: string;
  prevDepartureHour: string;
  status: ActivityStatus;
  responsibleName: string;
  responseDocument: string;
  responseSignature: string;
  reason: string;
  latFinishing: string;
  longFinishing: string;
  startHour: string;
  endHour: string;
  customer: {
    id: number;
    name: string;
    address: {
      id: number;
      street: string;
      number: string;
      complement: string;
      neighborhood: string;
      postalcode: string;
      city: string;
      state: string;
      country: string;
      lat: string;
      long: string;
      type: string;
      region: RegionStatus;
    };
    parcels: IParcels[];
  };
  images: Array<{
    id: number;
    image: string;
  }>;
}

export interface ISelectedActivity {
  id: number;
  number: string;
  customer: {
    name: string;
    address: {
      region: RegionStatus;
    };
  };
}

interface IActivityContext {
  selectedActivity: ISelectedActivity[];
  setSelectedActivity: React.Dispatch<
    React.SetStateAction<ISelectedActivity[]>
  >;
  handleSelect(activity: ISelectedActivity): void;
  activityDelivered(
    routeId: number,
    activityId: number,
    data: {
      images: IOrderImage[];
    }
  ): Promise<AxiosResponse>;
  activitySequence(
    routeId: number,
    sortedActivities: { id: number; ordem: number; ordemOrigin: number }[]
  ): Promise<void>;
  activityCanceled(
    routeId: number,
    activityId: number,
    data: {
      images: IOrderImage[];
    }
  ): Promise<AxiosResponse>;
  getRouteActivityReportById(
    routeId: number,
    activityId: number
  ): Promise<IRouteActivityReport>;
}

const ActivityContext = createContext<IActivityContext>({} as IActivityContext);

const ActivityProvider: React.FC = ({ children }) => {
  const { routeList, setRouteList, todayRoutes, setTodayRoutes } = useRoute();
  const [selectedActivity, setSelectedActivity] = useState<ISelectedActivity[]>(
    []
  );

  const handleSelect = useCallback(
    (activity: ISelectedActivity) => {
      const selectedIndex = selectedActivity.indexOf(activity);
      let newSelected: ISelectedActivity[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedActivity, activity);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedActivity.slice(1));
      } else if (selectedIndex === selectedActivity.length - 1) {
        newSelected = newSelected.concat(selectedActivity.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedActivity.slice(0, selectedIndex),
          selectedActivity.slice(selectedIndex + 1)
        );
      }

      setSelectedActivity(newSelected);
    },
    [selectedActivity]
  );

  const updateActivity = useCallback(
    (routeId: number, activityId: number, data: any) => {
      const routes = routeList.routes.map((route) => {
        if (route.id === routeId) {
          route.status = data.route.status;

          if (route.activities) {
            route.activities.map((activity) => {
              if (activity.id === activityId) {
                activity.status = data.status;
                activity.responsibleName = data.responsibleName;
                activity.responseDocument = data.responseDocument;
              }

              return activity;
            });
          }
        }

        return route;
      });

      const hasRoute = todayRoutes.reduce((acc, curr) => {
        if (curr.id === routeId) {
          return acc + 1;
        }
        return acc;
      }, 0);

      if (hasRoute > 0) {
        let newTodaysRoutes = todayRoutes;
        newTodaysRoutes.map((route) => {
          if (route.id === routeId) {
            route.status = data.route.status;

            if (route.activities) {
              route.activities.map((activity) => {
                if (activity.id === activityId) {
                  activity.status = data.status;
                  activity.responsibleName = data.responsibleName;
                  activity.responseDocument = data.responseDocument;
                }

                return activity;
              });
            }
          }

          return route;
        });

        setTodayRoutes([...newTodaysRoutes]);
      }

      setRouteList((prevState) => ({ total: prevState.total, routes }));
    },
    [routeList.routes, setRouteList, setTodayRoutes, todayRoutes]
  );

  const activityDelivered = useCallback(
    async (
      routeId: number,
      activityId: number,
      data: {
        images: IOrderImage[];
      }
    ) => {
      const response = await api.patch(
        `/route/delivered-activity/${activityId}`,
        data
      );

      if (response.status === 200) {
        updateActivity(routeId, activityId, response.data);
      }

      return response;
    },
    [updateActivity]
  );

  const activitySequence = async (
    routeId: number,
    sortedActivities: { id: number; ordem: number; ordemOrigin: number }[]
  ) => {
    await api.post(`/route-sequence/${routeId}`, {
      activities: sortedActivities,
    });
  };

  const activityCanceled = useCallback(
    async (
      routeId: number,
      activityId: number,
      data: {
        images: IOrderImage[];
      }
    ) => {
      const response = await api.patch(
        `/route/cancel-activity/${activityId}`,
        data
      );

      if (response.status === 200) {
        updateActivity(routeId, activityId, response.data);
      }

      return response;
    },
    [updateActivity]
  );

  const getRouteActivityReportById = useCallback(
    async (routeId: number, activityId: number) => {
      const response = await api.get(`/reports/route/activity`, {
        params: { routeId, activityId },
      });

      return response.data;
    },
    []
  );

  return (
    <ActivityContext.Provider
      value={{
        selectedActivity,
        setSelectedActivity,
        handleSelect,
        activityDelivered,
        activityCanceled,
        activitySequence,
        getRouteActivityReportById,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

function useActivity(): IActivityContext {
  const context = useContext(ActivityContext);

  if (!context) {
    throw new Error('useActivity must be used within a ActivityProvider');
  }

  return context;
}

export { ActivityProvider, useActivity };
