import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ConferenceStatus } from '../../../constants';
import { useSettings } from '../../../hooks/settings';
import api from '../../../services/api';
import {
  IOrder,
  IOrderPaginatedLoad,
  IShippingAddress,
} from '../../order/hooks/order';

export interface IResult {
  total: number;
  result: {
    date: string;
    quantity: string;
  }[];
}

export interface ILoadConferenceStatus {
  countConferred: number;
  countUnchecked: number;
  countPartiallyConferred: number;
}

export interface IDistributionCenterResult {
  result: {
    distributionCenterId: number;
    name: string;
    quantity: string;
  }[];
}

export interface ISeparationFilter {
  orderNumber: string;
  distributionCenterId: string;
  initialDate: string;
  finalDate: string;
  conferenceStatus: ConferenceStatus;
  shippingStatus: string;
}

export type SplitShippingAddress = Omit<
  IShippingAddress,
  'id' | 'region' | 'lat' | 'long' | 'country'
>;

export interface ISplitOrderItems {
  orderId: number;
  operation?: 'collect';
  itemsId: number[];
  previsionDate: string | null;
  observation?: string | null;
  shippingAddress: SplitShippingAddress;
}

type FilterSeparateOrders = ISeparationFilter & {
  take: number;
  page: number;
};

export type PaginatedSeparation = IOrderPaginatedLoad;

interface ISeparationContext {
  separationFilter: ISeparationFilter;
  setSeparationFilter: React.Dispatch<React.SetStateAction<ISeparationFilter>>;
  loadConferenceStatus(query: {
    initialDate: string;
    finalDate: string;
  }): Promise<ILoadConferenceStatus>;
  loadDaysWithSeparation(filter: ISeparationFilter): Promise<IResult>;
  loadDistributionCenterWithSeparation(
    filter: ISeparationFilter
  ): Promise<IDistributionCenterResult>;
  loadSeparateOrders(
    filter: FilterSeparateOrders
  ): Promise<PaginatedSeparation>;
  cancelSeparatedOrders(orderId: number): Promise<void>;
  splitOrderItems(data: ISplitOrderItems): Promise<void>;
  loadItemsFractioned(orderCode: string): Promise<IOrder[] | undefined>;
}

const SeparationContext = createContext<ISeparationContext>(
  {} as ISeparationContext
);

const SeparationProvider: React.FC = ({ children }) => {
  const { selectedPeriod, getDatePeriod } = useSettings();
  const { from, to } = getDatePeriod(selectedPeriod);

  const [separationFilter, setSeparationFilter] = useState<ISeparationFilter>({
    orderNumber: '',
    distributionCenterId: '',
    initialDate: from,
    finalDate: to,
    conferenceStatus: 'unchecked',
    shippingStatus: '',
  });

  useEffect(() => {
    setSeparationFilter((prevState) => ({
      ...prevState,
      initialDate: from,
      finalDate: to,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  function instanceOf<T>(object: any, instanceProp: string): object is T {
    return instanceProp in object;
  }

  const returnQuery = useCallback(
    (filter: ISeparationFilter | FilterSeparateOrders) => {
      const {
        orderNumber,
        distributionCenterId,
        initialDate,
        finalDate,
        conferenceStatus,
        shippingStatus,
      } = filter;

      let query =
        `?conferenceStatus=${conferenceStatus}` +
        `${orderNumber && `&orderNumber=${orderNumber}`}` +
        `${
          distributionCenterId &&
          `&distributionCenterId=${distributionCenterId}`
        }` +
        `${initialDate && `&initialDate=${initialDate}`}` +
        `${finalDate && `&finalDate=${finalDate}`}` +
        `${shippingStatus && `&shippingStatus=${shippingStatus}`}`;

      if (instanceOf<FilterSeparateOrders>(filter, 'page')) {
        const { page, take } = filter;
        query += `&page=${page}&take=${take}`;
      }

      return query;
    },
    []
  );

  const loadConferenceStatus = useCallback(
    async (query: { initialDate: string; finalDate: string }) => {
      const { initialDate, finalDate } = query;
      const response = await api.get(
        `/order/count/conference/status?initialDate=${initialDate}&finalDate=${finalDate}`
      );

      if (response.data) {
        return response.data;
      }

      return undefined;
    },
    []
  );

  const loadDaysWithSeparation = useCallback(
    async (filter: ISeparationFilter) => {
      const query = returnQuery(filter);

      const response = await api.get(
        `/order/separation/filter/order-dates${query}`
      );

      if (response.data) {
        return response.data;
      }

      return undefined;
    },
    [returnQuery]
  );

  const loadDistributionCenterWithSeparation = useCallback(
    async (filter: ISeparationFilter) => {
      const query = returnQuery(filter);

      const response = await api.get(
        `/order/separation/filter/distribution-center/quantity${query}`
      );

      if (response.data) {
        return response.data;
      }

      return undefined;
    },
    [returnQuery]
  );

  const loadSeparateOrders = useCallback(
    async (filter: FilterSeparateOrders) => {
      const query = returnQuery(filter);

      const response = await api.get(`/order/separation/paginated${query}`);

      if (response.data) {
        return response.data;
      }

      return undefined;
    },
    [returnQuery]
  );

  const cancelSeparatedOrders = useCallback(async (orderId: number) => {
    await api.patch(`/order/cancel/conference/${orderId}`);
  }, []);

  const splitOrderItems = useCallback(async (data: ISplitOrderItems) => {
    await api.post('/order/split-order-items', data);
  }, []);

  const loadItemsFractioned = useCallback(async (orderCode: string) => {
    const response = await api.get(
      `/order/list/by-reference-code/${orderCode}`
    );

    if (response) {
      return response.data;
    }
  }, []);

  return (
    <SeparationContext.Provider
      value={{
        separationFilter,
        setSeparationFilter,
        loadConferenceStatus,
        loadDaysWithSeparation,
        loadDistributionCenterWithSeparation,
        loadSeparateOrders,
        cancelSeparatedOrders,
        splitOrderItems,
        loadItemsFractioned,
      }}
    >
      {children}
    </SeparationContext.Provider>
  );
};

function useSeparation(): ISeparationContext {
  const context = useContext(SeparationContext);

  if (!context) {
    throw new Error('useSeparation must be used within a SeparationProvider');
  }

  return context;
}

export { SeparationProvider, useSeparation };
