import { Button, ButtonProps } from '@material-ui/core';

export const IconButton = ({ children, ...props }: ButtonProps) => (
  <Button
    variant="text"
    size="small"
    style={{ minWidth: 0, marginRight: 8 }}
    color="primary"
    {...props}
  >
    {children}
  </Button>
);
