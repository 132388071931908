import { useEffect, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { format, subDays } from 'date-fns';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { Main } from '../../../../components/Main';
import {
  BasicTable,
  IBasicColumn,
} from '../../../../components/Table/BasicTable';

import {
  orderOperationsType,
  orderStatusCode,
  orderStatusCodeLabel,
  shippingStatusLabel,
} from '../../../../utils';

import { generateOrderPDF } from '../../templates/orderReport';
import XLSX from 'xlsx';
import { useOrderReport, IOrder } from '../../hooks/order';

export interface IOrderReport extends Omit<IOrder, 'statusCode'> {
  statusCode: string;
}

export const OrderReport = () => {
  const { filter, setFilter, loadOrder } = useOrderReport();
  const [loading, setLoading] = useState(true);
  const [exportWithProducts, setExportWithProducts] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<IOrderReport[]>([]);

  const columnsTable: IBasicColumn[] = [
    { name: 'Dt. Pedido' },
    { name: 'Núm. Pedido' },
    { name: 'Nome Cliente' },
    { name: 'Status Ped.' },
    { name: 'Qtd.' },
  ];

  const validationSchema = yup.object({
    initialDate: yup.date().required('Campo obrigatório!'),
    finalDate: yup
      .date()
      .required('Campo obrigatório!')
      .min(
        yup.ref('initialDate'),
        'Data fim não pode ser menor que a data início'
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filter,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  useEffect(() => {
    async function loadOrderReport(): Promise<void> {
      setLoading(true);
      try {
        const response = await loadOrder(filter);

        if (!response) return;

        const newData = response.map((data) => {
          return {
            ...data,
            orderDate: format(new Date(data.orderDate), 'dd/MM/yyyy'),
            statusCode: orderStatusCodeLabel(data.statusCode),
            shippingStatus: shippingStatusLabel(data.shippingStatus as any),
          };
        });

        setOrder(newData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadOrderReport();
  }, [filter, loadOrder]);

  const handleReset = useCallback(
    (e) => {
      e.preventDefault();
      formik.handleReset(e);
      setFilter({
        initialDate: format(subDays(new Date(), 6), 'yyyy-MM-dd'),
        finalDate: format(new Date(), 'yyyy-MM-dd'),
        orderNumber: '',
      });
    },
    [formik, setFilter]
  );

  const downloadExcel = useCallback((order: IOrderReport[]) => {
    // eslint-disable-next-line array-callback-return
    const newData = order.flatMap((data) =>
      data.items.map(() => {
        return {
          orderDate: data.orderDate,
          number: data.number,
          customerName: data.customer.name,
          statusCode: data.statusCode,
          quantity: data.items.length + '',
        };
      })
    );

    const header = [
      ['Dt. Pedido', 'Núm. Pedido', 'Nome Cliente', 'Status Ped.', 'Qtd.'],
    ];

    const workSheetCols = [
      { width: 12 },
      { width: 32 },
      { width: 52 },
      { width: 14 },
      { width: 10 },
    ];

    const workSheet = XLSX.utils.json_to_sheet(newData);
    XLSX.utils.sheet_add_aoa(workSheet, header);
    workSheet['!cols'] = workSheetCols;

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'PEDIDOS');

    //Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    //Download
    XLSX.writeFile(workBook, 'Relatório de Pedidos.xlsx');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main title="Relatório de Pedidos">
      <Grid container spacing={1}>
        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() =>
                generateOrderPDF(
                  order,
                  exportWithProducts,
                  formik.values.geocodeChanged || false
                )
              }
            >
              Exportar PDF
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => downloadExcel(order)}
            >
              Exportar EXCEL
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="body1" id="filter">
              Filtros
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data início"
                  type="date"
                  id="initialDate"
                  name="initialDate"
                  value={formik.values.initialDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.initialDate &&
                    Boolean(formik.errors.initialDate)
                  }
                  helperText={
                    formik.touched.initialDate && formik.errors.initialDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Data fim"
                  type="date"
                  id="finalDate"
                  name="finalDate"
                  value={formik.values.finalDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.finalDate && Boolean(formik.errors.finalDate)
                  }
                  helperText={
                    formik.touched.finalDate && formik.errors.finalDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Número do pedido"
                  id="orderNumber"
                  name="orderNumber"
                  value={formik.values.orderNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.orderNumber &&
                    Boolean(formik.errors.orderNumber)
                  }
                  helperText={
                    formik.touched.orderNumber && formik.errors.orderNumber
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Cliente"
                  id="customerName"
                  name="customerName"
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Status"
                  id="statusCode"
                  name="statusCode"
                  value={formik.values.statusCode}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">
                    <em>Vazio</em>
                  </MenuItem>
                  {orderStatusCode.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Operação"
                  id="operationType"
                  name="operationType"
                  value={formik.values.operationType}
                  onChange={formik.handleChange}
                >
                  <MenuItem value="">
                    <em>Vazio</em>
                  </MenuItem>
                  {orderOperationsType.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="riskArea"
                        name="riskArea"
                        value={formik.values.riskArea}
                        onChange={formik.handleChange}
                        checked={formik.values.riskArea}
                        color="secondary"
                      />
                    }
                    label="Área de Risco"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="geocodeChanged"
                        name="geocodeChanged"
                        value={formik.values.geocodeChanged}
                        onChange={formik.handleChange}
                        checked={formik.values.geocodeChanged}
                        color="secondary"
                      />
                    }
                    label="Geocode Alterado"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="exportItems"
                      name="exportItems"
                      onChange={(event) =>
                        setExportWithProducts(event.target.checked)
                      }
                      color="secondary"
                    />
                  }
                  label="Exportar com Itens"
                />
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={4} sm={12}>
                <Grid item container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => formik.handleSubmit()}
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleReset}>Limpar</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <BasicTable
            columns={columnsTable}
            loading={loading}
            total={order.length}
            pagination={[page, setPage]}
            rowsPerPageState={[rowsPerPage, setRowsPerPage]}
          >
            {order
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data) => (
                <TableRow key={data.id}>
                  <TableCell>{data.orderDate}</TableCell>
                  <TableCell>{data.number}</TableCell>
                  <TableCell>{data.customer.name}</TableCell>
                  <TableCell>{data.statusCode}</TableCell>
                  <TableCell>{data.items.length}</TableCell>
                </TableRow>
              ))}
          </BasicTable>
        </Grid>
      </Grid>
    </Main>
  );
};
