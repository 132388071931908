import { useCallback, useMemo, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';

import { ROUTES } from '../../../../constants';
import { Main } from '../../../../components/Main';
import { Button } from '../../../../components/Button';
import { maskPostalcode, unmask } from '../../../../utils';
import { getAddress } from '../../../../services/viaCep';
import { useAuth } from '../../../../hooks/auth';
import {
  useDistributionCenter,
  IDistributionCenterRequest,
} from '../../hooks/distribution-center';

import { useStyles, Form } from './styles';

interface IParams {
  distributionCenterId?: string;
}

export const DistributionCenterForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const { distributionCenterId } = useParams<IParams>();
  const {
    data: { user },
    setCompanyDefault,
    companyDefault,
  } = useAuth();
  const {
    loadDistributionCenterById,
    createDistributionCenter,
    updateDistributionCenter,
  } = useDistributionCenter();
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingDistributionCenterId, setLoadingDistributionCenterId] =
    useState(true);
  const [data, setData] = useState<IDistributionCenterRequest>({
    name: '',
    isPrincipal: false,
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      postalcode: '',
      city: '',
      state: '',
      country: 'BR',
      lat: '',
      long: '',
    },
    companyDocument: user.companyDocument,
  });

  useEffect(() => {
    async function loadDistributionCenter(): Promise<void> {
      try {
        if (distributionCenterId) {
          const response = await loadDistributionCenterById(
            parseInt(distributionCenterId)
          );

          if (!response) return;

          setData({
            ...response,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDistributionCenterId(false);
      }
    }

    loadDistributionCenter();
  }, [loadDistributionCenterById, distributionCenterId]);

  const validationSchema = yup.object({
    name: yup.string().required('Insira o nome'),
    address: yup.object().shape({
      postalcode: yup.string().required('Por favor, insira um CEP'),
      street: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      neighborhood: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      number: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string().required('Insira um número'),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      city: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      state: yup.string().when('postalcode', {
        is: (val: any) => !!val,
        then: yup.string(),
        otherwise: yup.string().required('Insira o CEP'),
      }),
      complement: yup.string(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadingButton(true);

      try {
        if (distributionCenterId) {
          await updateDistributionCenter(parseInt(distributionCenterId), {
            ...values,
          });
        } else {
          await createDistributionCenter(values);
        }

        let newCompanyDefault: any = {};

        newCompanyDefault = {
          ...companyDefault,
          hasDistributionCenter: true,
        };

        setCompanyDefault(newCompanyDefault);
        localStorage.setItem(
          '@4log:companyDefault',
          JSON.stringify(newCompanyDefault)
        );

        history.push(ROUTES.distributionCenterList);
      } catch (error) {
        console.log(error);
      }

      setLoadingButton(false);
    },
  });

  const subtitle = useMemo(() => {
    if (distributionCenterId) {
      return 'Alterar centro de distribuição';
    } else {
      return 'Cadastrar centro de distribuição';
    }
  }, [distributionCenterId]);

  const submitButtonTitle = useMemo(() => {
    if (distributionCenterId) {
      return 'Alterar';
    } else {
      return 'Cadastrar';
    }
  }, [distributionCenterId]);

  const handleChangeIsPrincipalValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target;

      formik.setFieldValue(name, checked);
    },
    [formik]
  );

  const handleChangepostalcodeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      const unmaskedValue = unmask(maskPostalcode(value));

      formik.setFieldValue(name, unmaskedValue);

      const postalcodeLength = 8;

      if (unmaskedValue.length === postalcodeLength) {
        const address = await getAddress(unmaskedValue);

        if (address) {
          const { logradouro, bairro, localidade, uf } = address;

          if (logradouro) {
            formik.setFieldValue('address.street', logradouro);
          }

          if (bairro) {
            formik.setFieldValue('address.neighborhood', bairro);
          }

          if (localidade) {
            formik.setFieldValue('address.city', localidade);
          }

          if (uf) {
            formik.setFieldValue('address.state', uf);
          }
        }
      }
    },
    [formik]
  );

  return (
    <Main
      title={subtitle}
      maxWidth="md"
      button="back"
      to={ROUTES.distributionCenterList}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={3}>
              {distributionCenterId && loadingDistributionCenterId ? (
                <Grid item xs={12}>
                  <Typography component="div" align="center">
                    <CircularProgress />
                  </Typography>
                </Grid>
              ) : (
                <>
                  {distributionCenterId && (
                    <Grid item xs={4} sm={2}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Código"
                        value={distributionCenterId}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={distributionCenterId ? 8 : 12}
                    sm={distributionCenterId ? 6 : 8}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      autoFocus
                      label="Nome"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="CEP"
                      id="address.postacode"
                      name="address.postalcode"
                      value={maskPostalcode(formik.values.address.postalcode)}
                      onChange={handleChangepostalcodeValue}
                      error={
                        formik.touched.address?.postalcode &&
                        Boolean(formik.errors.address?.postalcode)
                      }
                      helperText={
                        formik.touched.address?.postalcode &&
                        formik.errors.address?.postalcode
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Rua"
                      id="address.street"
                      name="address.street"
                      value={formik.values.address.street}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.street &&
                        Boolean(formik.errors.address?.street)
                      }
                      helperText={
                        formik.touched.address?.street &&
                        formik.errors.address?.street
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Bairro"
                      id="address.neighborhood"
                      name="address.neighborhood"
                      value={formik.values.address.neighborhood}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.neighborhood &&
                        Boolean(formik.errors.address?.neighborhood)
                      }
                      helperText={
                        formik.touched.address?.neighborhood &&
                        formik.errors.address?.neighborhood
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Número"
                      id="address.number"
                      name="address.number"
                      value={formik.values.address.number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.number &&
                        Boolean(formik.errors.address?.number)
                      }
                      helperText={
                        formik.touched.address?.number &&
                        formik.errors.address?.number
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Cidade"
                      id="address.city"
                      name="address.city"
                      value={formik.values.address.city}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.city &&
                        Boolean(formik.errors.address?.city)
                      }
                      helperText={
                        formik.touched.address?.city &&
                        formik.errors.address?.city
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      label="Estado"
                      id="address.state"
                      name="address.state"
                      value={formik.values.address.state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.state &&
                        Boolean(formik.errors.address?.state)
                      }
                      helperText={
                        formik.touched.address?.state &&
                        formik.errors.address?.state
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Complemento"
                      id="address.complement"
                      name="address.complement"
                      value={formik.values.address.complement}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.address?.complement &&
                        Boolean(formik.errors.address?.complement)
                      }
                      helperText={
                        formik.touched.address?.complement &&
                        formik.errors.address?.complement
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="isPrincipal"
                            checked={formik.values.isPrincipal}
                            onChange={handleChangeIsPrincipalValue}
                          />
                        }
                        label={<Typography>Principal</Typography>}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Button
                    variant="contained"
                    type="submit"
                    loading={loadingButton}
                  >
                    {submitButtonTitle}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Main>
  );
};
