import React, { createContext, useCallback, useContext } from 'react';
import api from '../../../services/api';
import { ICompany } from '../../tracking/hooks/tracking';

export interface ISurvey {
  rating: number;
  reason: string;
  answered: boolean;
  company: ICompany;
}

export interface ISurveyRequest {
  rating: number;
  reason: string;
}

interface ISurveyContext {
  loadNpsByCode(code: string): Promise<ISurvey>;
  sendNpsResponse(code: string, data: ISurveyRequest): Promise<any>;
}

const SurveyContext = createContext<ISurveyContext>({} as ISurveyContext);

const SurveyProvider: React.FC = ({ children }) => {
  const loadNpsByCode = useCallback(async (code: string) => {
    const response = await api.get(`/nps/verify/${code}`);

    if (response.data) {
      return response.data;
    }

    return undefined;
  }, []);

  const sendNpsResponse = useCallback(
    async (code: string, data: ISurveyRequest) => {
      return await api.put(`/nps/${code}`, data);
    },
    []
  );

  return (
    <SurveyContext.Provider
      value={{
        loadNpsByCode,
        sendNpsResponse,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

function useSurvey(): ISurveyContext {
  const context = useContext(SurveyContext);

  if (!context) {
    throw new Error('useSurvey must be used within a SurveyProvider');
  }

  return context;
}

export { SurveyProvider, useSurvey };
