import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import * as yup from 'yup';

import { Button } from '../../../components/Button';
import { useAuth } from '../../../hooks/auth';
import { ILoadVehicle, useVehicle } from '../../vehicle/hooks/vehicle';
import { generateRequestPdf } from '../../report/templates/requestReport';
import { generateSeparationPdf } from '../../report/templates/separationReport';
import { useAgco } from '../hooks/agco';

const validationSchema = yup.object({
  vehicleId: yup.string().required('Campo obrigatório'),
  load: yup.string().required('Campo obrigatório'),
});

export const AgcoModalForm = () => {
  const {
    data: { user },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { loadVehicleList } = useVehicle();
  const {
    selected,
    isAgcoModalFormOpen,
    clearSelected,
    handleToggleAgcoModalForm,
    handleSelect,
    createOutboundControl,
  } = useAgco();
  const [vehicles, setVehicles] = useState<ILoadVehicle[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(true);
  const [submtingForm, setSubmtingForm] = useState(false);
  const [loadingPrints, setLoadingPrints] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [prints, setPrints] = useState({
    orderReport: true,
    separationReport: false,
  });

  const { orderReport, separationReport } = prints;

  const checkboxFormControlError =
    [orderReport, separationReport].filter((v) => v).length < 1;

  const handlePrintsCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setPrints((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const formik = useFormik({
    initialValues: {
      orders: [],
      vehicleId: '',
      load: '',
    },
    validationSchema,
    validate: () => {
      let errors: any;

      if (selected.length < 1) {
        errors = {
          ...errors,
          orders: 'Você deve selecionar pelo menos um pedido',
        };
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setSubmtingForm(true);

        const data = {
          load: values.load,
          driverId: user.id,
          driverName: user.name,
          vehicleId: parseInt(values.vehicleId, 10),
          vehicleName: vehicles.find(
            (vehicle) => vehicle.id === parseInt(values.vehicleId, 10)
          )!.name,
          orders: selected.map((order) => order.id),
        };

        await createOutboundControl(data);

        setFormSubmitted(true);
        clearSelected();
        resetForm();
        enqueueSnackbar('Ordem criada com sucesso', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('Erro em criar ordem', {
          variant: 'error',
        });
      } finally {
        setSubmtingForm(false);
      }
    },
  });

  const { values, touched, errors, handleChange, handleSubmit } = formik;

  useEffect(() => {
    async function getVehicles(): Promise<void> {
      try {
        const vehiclesResponse = await loadVehicleList(user.companyDocument);

        if (!vehiclesResponse) return;

        setVehicles(
          vehiclesResponse.filter((vehicle) => vehicle.driverId !== null)
        );
      } catch (error) {
        enqueueSnackbar(
          'Tivemos algum problema para carregar a lista de transportadoras',
          {
            variant: 'error',
          }
        );
      } finally {
        setLoadingVehicles(false);
      }
    }

    getVehicles();
  }, [user.companyDocument, loadVehicleList, enqueueSnackbar]);

  const handlePrint = async () => {
    try {
      setLoadingPrints(true);

      if (orderReport) await generateRequestPdf();
      if (separationReport) await generateSeparationPdf();

      handleToggleAgcoModalForm();
      setFormSubmitted(false);
      setPrints({
        orderReport: true,
        separationReport: false,
      });
    } catch (error) {
      enqueueSnackbar('Houve algum erro para gerar o/os pdf', {
        variant: 'error',
      });
    } finally {
      setLoadingPrints(false);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={isAgcoModalFormOpen}
      onClose={handleToggleAgcoModalForm}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {formSubmitted ? 'Imprimir Relatório' : 'Criar ordem'}
      </DialogTitle>
      <DialogContent>
        {formSubmitted ? (
          <FormControl
            component="fieldset"
            required
            error={checkboxFormControlError}
            disabled={loadingPrints}
          >
            <FormLabel component="legend">
              Escolha pelo menos um relatório
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderReport}
                    onChange={handlePrintsCheckbox}
                    name="orderReport"
                  />
                }
                label="Relatório de Ordem"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={separationReport}
                    onChange={handlePrintsCheckbox}
                    name="separationReport"
                  />
                }
                label="Relatório de Separação"
              />
            </FormGroup>
            {checkboxFormControlError && (
              <FormHelperText>
                Escolha pelo menos um relatório para impressão
              </FormHelperText>
            )}
          </FormControl>
        ) : (
          <Grid
            component="form"
            id="agco-form"
            container
            spacing={2}
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid item xs={9}>
              <TextField
                select
                variant="outlined"
                size="small"
                required
                fullWidth
                label="Transportadora"
                id="vehicleId"
                name="vehicleId"
                value={values.vehicleId}
                onChange={handleChange}
                error={touched.vehicleId && Boolean(errors.vehicleId)}
                helperText={touched.vehicleId && errors.vehicleId}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: loadingVehicles && <CircularProgress />,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" disabled>
                  <em>Selecione uma transportadora</em>
                </MenuItem>
                {vehicles.map((vehicle) => (
                  <MenuItem key={vehicle.id} value={vehicle.id}>
                    {`${vehicle.name} - (${vehicle.user.name} - ${vehicle.plate})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                variant="outlined"
                size="small"
                required
                fullWidth
                label="Load"
                id="load"
                name="load"
                value={values.load}
                onChange={handleChange}
                error={touched.load && Boolean(errors.load)}
                helperText={touched.load && errors.load}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography align="center">NFs: {selected.length}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography align="center">
                Volume:{' '}
                {selected.reduce((acc, curr: any) => {
                  return acc + curr.items.length;
                }, 0)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="orders table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Pedido</TableCell>
                      <TableCell>TSP</TableCell>
                      <TableCell>UF</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selected.map((row) => (
                      <TableRow hover key={row.id}>
                        <TableCell padding="checkbox">
                          <IconButton
                            size="small"
                            onClick={() => handleSelect(row)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.number}
                        </TableCell>
                        <TableCell>
                          {vehicles.find(
                            (vehicle) =>
                              vehicle.id === parseInt(values.vehicleId, 10)
                          )?.user.name ?? 'Nenhuma transportadora selecionada'}
                        </TableCell>
                        <TableCell>{row.shippingAddress.state}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {touched.orders && (
                <FormHelperText error>{errors.orders}</FormHelperText>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <MUIButton color="inherit" onClick={handleToggleAgcoModalForm}>
          Fechar
        </MUIButton>
        {formSubmitted ? (
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={handlePrint}
            loading={loadingPrints}
            disabled={checkboxFormControlError}
          >
            Imprimir
          </Button>
        ) : (
          <Button
            form="agco-form"
            variant="contained"
            color="secondary"
            type="submit"
            loading={submtingForm}
          >
            Salvar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
