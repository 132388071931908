import React, { useContext, createContext, useCallback } from 'react';
import api from '../../../services/api';

export interface IGeoapify {
  apiKey: string;
}

interface IGeoapifyContext {
  loadGeoapify(companyDocument: number): Promise<IGeoapify | undefined>;
  saveGeoapify(data: IGeoapify): Promise<void>;
}

const GeoapifyContext = createContext<IGeoapifyContext>({} as IGeoapifyContext);

const GeoapifyProvider: React.FC = ({ children }) => {
  const loadGeoapify = useCallback(async (companyDocument: number) => {
    const response = await api
      .get(`/integrations/geoapify/config/${companyDocument}`)
      .then((response) => response.data)
      .catch(() => {
        return null;
      });

    return response;
  }, []);

  const saveGeoapify = useCallback(async (data: IGeoapify) => {
    await api.post('/integrations/geoapify/config', data);
  }, []);

  return (
    <GeoapifyContext.Provider
      value={{
        loadGeoapify,
        saveGeoapify,
      }}
    >
      {children}
    </GeoapifyContext.Provider>
  );
};

function useGeoapify(): IGeoapifyContext {
  const context = useContext(GeoapifyContext);

  if (!context) {
    throw new Error('useGeoapify must be used within a GeoapifyProvider');
  }

  return context;
}

export { GeoapifyProvider, useGeoapify };
