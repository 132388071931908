import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  MenuItem,
  Grid,
  Button as MUIButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { regionStatusLabel } from '../../../../../utils';
import { Button } from '../../../../../components/Button';
import { useAuth } from '../../../../../hooks/auth';
import {
  useRoute,
  IRouteLight,
  IRouteListByStatus,
  IMoveActivityRequest,
} from '../../../hooks/route';
import { IActivity, useActivity } from '../../../hooks/activity';

interface IProps {
  open: boolean;
  route: IRouteLight;
  activity?: IActivity;
  onClose: () => void;
}

export const MoveActivity = ({ open, route, activity, onClose }: IProps) => {
  const {
    data: { user },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { moveActivity, loadRoutesByStatus } = useRoute();
  const { selectedActivity, setSelectedActivity, handleSelect } = useActivity();
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState<IRouteListByStatus[]>([]);
  const [data, setData] = useState<IMoveActivityRequest>({
    companyDocument: user.companyDocument,
    orders: [],
    routeIdOrigin: route.id,
    routeIdDestiny: '',
  });

  const validationSchema = yup.object({
    orders: yup
      .array()
      .test('orders', 'Selecione pelo menos um pedido', () =>
        !!activity ? true : selectedActivity.length > 0
      ),
    routeIdDestiny: yup
      .number()
      .required('Você deve selecionar uma rota destino'),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formik) => {
      setLoading(true);
      const orders: any[] = activity
        ? [
            {
              number: activity.number,
              activityId: activity.id,
            },
          ]
        : selectedActivity.map((activity) => {
            return { number: activity.number, activityId: activity.id };
          });

      values = {
        ...values,
        orders,
      };

      try {
        await moveActivity(values).then(() => {
          const message =
            'Pedido(s) movimentado(s) com sucesso! Aguarde o replanejamento das rotas';

          enqueueSnackbar(message, {
            variant: 'success',
          });

          setSelectedActivity([]);
          setData({} as IMoveActivityRequest);
          formik.resetForm();
          onClose();
        });
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    async function loadRoutes(): Promise<void> {
      try {
        const STATUS = 'pending|on-route';

        const response = await loadRoutesByStatus(user.companyDocument, STATUS);

        if (!response) return;

        setRoutes(response);
      } catch (error) {
        console.log(error);
      }
    }

    if (open) loadRoutes();
  }, [loadRoutesByStatus, open, user.companyDocument]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Mover Pedidos</DialogTitle>
      <DialogContent>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="orders table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Número</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Zona</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activity ? (
                      <TableRow hover key={activity.id}>
                        <TableCell padding="checkbox" />
                        <TableCell component="th" scope="row">
                          {activity.number}
                        </TableCell>
                        <TableCell>{activity.customer.name}</TableCell>
                        <TableCell>
                          {regionStatusLabel(activity.customer.address.region)}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {selectedActivity.map((row) => (
                          <TableRow hover key={row.id}>
                            <TableCell padding="checkbox">
                              <IconButton
                                size="small"
                                onClick={() => handleSelect(row)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.number}
                            </TableCell>
                            <TableCell>{row.customer.name}</TableCell>
                            <TableCell>
                              {regionStatusLabel(row.customer.address.region)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {formik.touched.orders && (
                <FormHelperText error>{formik.errors.orders}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={5}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="De"
                value={`${route.driver.driverName} - ${route.routerName}`}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Grid container alignItems="center" justifyContent="center">
                <SwapHorizIcon fontSize="large" />
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <TextField
                select
                variant="outlined"
                size="small"
                required
                fullWidth
                label="Para"
                id="routeIdDestiny"
                name="routeIdDestiny"
                value={formik.values.routeIdDestiny}
                onChange={formik.handleChange}
                error={
                  formik.touched.routeIdDestiny &&
                  Boolean(formik.errors.routeIdDestiny)
                }
                helperText={
                  formik.touched.routeIdDestiny && formik.errors.routeIdDestiny
                }
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" disabled>
                  <em>Selecione um motorista</em>
                </MenuItem>

                {routes
                  .filter((r) => {
                    return r.id !== route.id;
                  })
                  .map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                      {`${r.driver.driverName} - ${r.routerName}`}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <MUIButton color="inherit" onClick={onClose}>
          Fechar
        </MUIButton>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          loading={loading}
          onClick={() => formik.handleSubmit()}
        >
          Mover
        </Button>
      </DialogActions>
    </Dialog>
  );
};
